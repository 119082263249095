import { type ComponentProps } from 'react';
import { ActivityIndicator } from 'react-native';
import tinycolor from 'tinycolor2';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

import { Colors } from '../../../theme';
import { TouchableOpacity, FontAwesomeIcon } from '../../../base-components';

interface PillXButtonProps extends Omit<ComponentProps<typeof FontAwesomeIcon>, 'icon'> {
  onPress: () => unknown;
  disabled?: boolean;
  loading?: boolean;
}

const PillXButton = ({ onPress, disabled, loading, ...props }: PillXButtonProps) => {
  if (loading) return <ActivityIndicator />;

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress}>
      <FontAwesomeIcon
        size={5}
        // @ts-ignore
        color={disabled ? tinycolor(Colors.error).lighten(35).toRgbString() : Colors.error}
        icon={faXmark}
        {...props}
      />
    </TouchableOpacity>
  );
};

export default PillXButton;
