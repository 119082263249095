import type { IBoxProps } from '@rivallapp/native-base';

export const baseSelectorHeight = 55;
export const baseSelectorWidth = 70;

// base the slots off of the `base selector` for consistency
export const slotSelectorWidth = baseSelectorWidth + 5;
export const slotSelectorHeight = baseSelectorHeight + 5;

/**
 * used to space out the days and give ability to 'highlight' the range of days without having space between them
 */
export const slotMargin = 10;

/**
 * Used to fill up the space between the slots & give the full 'selected' highlighted UI effect (no spaces and starts at the edge of selector item)
 */
export const outerSlotContainerWidth = slotSelectorWidth + slotMargin;
export const outerSlotContainerHeight = slotSelectorHeight + slotMargin;

/* utilizes util props */
export const baseBorderRadius: IBoxProps['borderRadius'] = 'md';
export const basePadding: IBoxProps['padding'] = 0;
export const baseBorderWidth: IBoxProps['borderWidth'] = 1;

// colors for the UI
export const selectedColorScheme: IBoxProps['colorScheme'] = 'rentalBlue';

interface ThemedColors {
  light: IBoxProps['color'];
  dark: IBoxProps['color'];
}

export const inRangeBackgroundColor: ThemedColors = {
  light: 'rentalBlue.100',
  dark: 'rentalBlue.700',
};

export const selectedTextColor: IBoxProps['color'] = 'white.600';

export const inRangeTextColor: ThemedColors = {
  light: 'black',
  dark: 'white.600',
};
