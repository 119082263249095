import { type ComponentProps } from 'react';
import { Animated, useWindowDimensions } from 'react-native';

import { useTheme } from '../../../theme';
import { useFadeIn } from '../../../animations';
import { Pressable } from '../../../base-components';
import useMobileSizing from '../helpers/useMobileSizing';

interface DiscoverCardWrapper extends ComponentProps<typeof Pressable> {
  /** Type of Discover card */
  type?: 'PROGRAM' | 'RENTAL';
  /** used for programs */
  programId?: string;
  /** used for programs */
  gameId?: string;
  /** used for rentals */
  venueId?: string;
  /** function to take user to card detail page */
  onCardPress: () => void;
  /** ProgramCard or RentalCard */
  children: React.ReactNode;
}

const DiscoverCardWrapper = ({ onCardPress, children, ...props }: DiscoverCardWrapper) => {
  const opacity = useFadeIn();
  const layout = useWindowDimensions();
  const { colors } = useTheme();
  const mobileSizing = useMobileSizing();

  return (
    <Animated.View
      style={{
        opacity,
        marginVertical: mobileSizing ? 10 : 15,
        marginHorizontal: mobileSizing ? 0 : 15,
        alignItems: 'center',
      }}
    >
      <Pressable
        maxWidth={layout.width > 695 ? 675 : 400}
        width={layout.width - 20}
        minWidth={300}
        backgroundColor={colors.background}
        _hover={{ backgroundColor: colors.accents.hover }}
        _pressed={{ opacity: 0.5 }}
        _web={{ shadow: 2 }}
        onPress={() => onCardPress()}
        {...props}
      >
        {children}
      </Pressable>
    </Animated.View>
  );
};

export default DiscoverCardWrapper;
