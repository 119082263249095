import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { TouchableOpacity, ActivityIndicator } from 'react-native';
import { faCommentDots } from '@fortawesome/pro-light-svg-icons/faCommentDots';

import { useTheme } from '../../../theme';
import { type PopupType } from '../../GameRoster/GameRoster';
import { Image, Text, View, FontAwesomeIcon } from '../../../base-components';
import { ConfirmCancelDropin } from '../../index';
import PillXButton from '../PillXButton';
import REMOVE_REGISTRANT_FROM_DROPIN from '../../GameRoster/graphql/REMOVE_REGISTRANT_FROM_DROPIN';
import { GET_USER_NAME } from '../../GameRoster/graphql/GET_USER_DETAILS';
import { TeamRoleEnum, TeamRoles, ErrorMessageEnum } from '../../../constants/enums';
import styles from '../styles';

export interface RosterPillProps {
  userId?: string;
  gender?: string;
  displayName: string | undefined;
  teamRole: string | undefined;
  isPickup?: boolean;
  isDropin?: boolean;
  /**
   * If current user is a captain or co-captain of the team
   */
  hasCaptainPermissions?: boolean;
  /**
   * If current user is an admin
   */
  isAdmin?: boolean;
  isPast?: boolean;
  showChatbubble?: boolean;
  rosterNumber?: number;
  teamId?: string;
  gameId?: string;
  registrantId?: string;
  picture?: string;
  updateRoster?: () => unknown;
  setSuccess?: (arg: string) => unknown;
  setError?: (arg: string) => unknown;
  setSelectedPlayerId?: (arg: string) => unknown;
  setPopup?: (arg: PopupType) => unknown;
  onContactBubblePress?: (playerId: string, isDropin?: boolean) => void;
}

const RosterPill = ({
  userId = '',
  gender = '',
  displayName,
  teamRole = TeamRoles[TeamRoleEnum.TEAM_MEMBER].label,
  isPickup = false,
  isDropin = false,
  hasCaptainPermissions = false,
  isAdmin = false,
  isPast = false,
  showChatbubble = false,
  rosterNumber,
  gameId,
  teamId = '',
  registrantId,
  picture,
  updateRoster = () => {},
  setSuccess = () => {},
  setError = () => {},
  setSelectedPlayerId = () => {},
  setPopup = () => {},
  /**
   * Overrides the default chat bubble press action which opens a contact info popup
   * Used in player-app which has a custom contact info bottom sheet modal
   */
  onContactBubblePress,
}: RosterPillProps) => {
  const { colors, dark } = useTheme();
  const [showCancelPopup, setShowCancelPopup] = useState(false);

  const handlePress = () => {
    if (onContactBubblePress) {
      onContactBubblePress(userId, isDropin);
    } else {
      setSelectedPlayerId(userId);
      setPopup('selectedPlayer');
    }
  };

  const [removeRegistrantFromDropin, { loading }] = useMutation(REMOVE_REGISTRANT_FROM_DROPIN);

  // Add user initials if no profile photo
  const [userInitials, setUserInitials] = useState('');
  const input = {
    userId,
    teamId,
    isDropIn: isDropin,
  };
  const { loading: userDataLoading, error: userDataError } = useQuery(GET_USER_NAME, {
    fetchPolicy: 'cache-and-network',
    skip: !!picture || !userId || (!teamId && !isDropin),
    variables: {
      input,
    },
    onCompleted: ({ getUserDetails }) => {
      const { firstName, lastName } = getUserDetails;
      const firstInitial = firstName ? firstName[0]!.toUpperCase() : '';
      const lastInitial = lastName ? lastName[0]!.toUpperCase() : '';
      setUserInitials(`${firstInitial}${lastInitial}`);
    },
  });

  const handleRemoveDropin = () => {
    removeRegistrantFromDropin({
      variables: {
        input: {
          gameId,
          registrantId,
          teamId,
        },
      },
      onCompleted: async ({ removeRegistrantFromDropin: removeRegistrantResponse }) => {
        setShowCancelPopup(false);
        const { playerName } = removeRegistrantResponse;
        await updateRoster();
        setSuccess(`Removed ${playerName} from drop-in slot.`);
      },
      onError: err => {
        setError(ErrorMessageEnum.DROPIN_REMOVE_PLAYER);
        console.error(err);
      },
    });
  };

  const handlePressX = () => {
    if (isAdmin) handleRemoveDropin();
    else setShowCancelPopup(true);
  };

  const renderIcon = () => (
    <>
      {showChatbubble && (
        <TouchableOpacity onPress={handlePress}>
          <FontAwesomeIcon icon={faCommentDots} size={5} color={colors.text.toString()} />
        </TouchableOpacity>
      )}
      {(hasCaptainPermissions || isAdmin) && isDropin && !isPast && (
        <PillXButton onPress={handlePressX} disabled={loading} marginX={2} />
      )}
    </>
  );

  const rosterLabelColor = dark ? colors.text : colors.accents.rsvpGreen;

  if (userDataLoading || loading) return <ActivityIndicator />;
  if (userDataError) {
    return (
      <View>
        <Text>{`[userDataError]: ${userDataError}`}</Text>
      </View>
    );
  }

  return (
    <>
      <View style={styles.pillNumberWrapper}>
        {!isPickup ? <Text style={styles.gameRosterNumber}>{rosterNumber}</Text> : null}
        <View style={[styles.pillContainer, styles.rosterWrapper]} key={userId}>
          <View style={styles.rosterPictureContainer}>
            {picture ? (
              <Image
                src={picture}
                style={styles.rosterPicture}
                alt={`${displayName} profile picture`}
              />
            ) : (
              <Text style={styles.userInitials}>{userInitials}</Text>
            )}
          </View>
          <View style={styles.labelContainer} flex={1}>
            <View flex={1}>
              <Text color={rosterLabelColor} style={[styles.label]} numberOfLines={1}>
                {displayName} ({gender.toUpperCase()})
              </Text>
            </View>
            {isDropin ? (
              <Text color={rosterLabelColor} style={[styles.subLabel]}>
                Drop-In
              </Text>
            ) : (
              <Text color={rosterLabelColor} style={[styles.subLabel]}>
                {teamRole}
              </Text>
            )}
          </View>
          <View style={styles.sideLabelContainer}>{renderIcon()}</View>
        </View>
      </View>
      <ConfirmCancelDropin
        isOpen={showCancelPopup}
        onConfirm={handleRemoveDropin}
        onClose={() => setShowCancelPopup(false)}
      />
    </>
  );
};

export default RosterPill;
