import type { IStackProps } from '@rivallapp/native-base';
import RentalTitleWithCaption from './RentalTitleWithCaption';
import RentalTitleWithPopup from './RentalTitleWithPopup';

type RentalTitleBaseProps = {
  containerStyle?: IStackProps;
};

type Props = RentalTitleBaseProps &
  (
    | {
        variant: 'caption';
        rentalId: string;
        venueId?: never;
      }
    | {
        variant: 'popup';
        rentalId?: string;
        venueId?: string;
      }
  );

/**
 * Rental title with `caption` or `popup` variant.
 * - `caption`: shows venue, neighborhood, and capacity for a rental. Only takes `rentalId`.
 * - `popup`: shows venue name and the "What's a Rental?" popup link. Can take either
 * `rentalId` or `venueId` to get the venue name
 */
const RentalTitle = ({ variant, rentalId, venueId, containerStyle = {} }: Props) => {
  return variant === 'caption' ? (
    <RentalTitleWithCaption rentalId={rentalId} containerStyle={containerStyle} />
  ) : (
    <RentalTitleWithPopup rentalId={rentalId} venueId={venueId} containerStyle={containerStyle} />
  );
};
export default RentalTitle;
