import { useColorModeValue, useTheme as useNativeBaseTheme } from '@rivallapp/native-base';

import { Colors } from '../../../theme';
import { type HaloChipProps } from '../HaloChip';

/**
 * Hook to match up the `colorScheme` prop to the hex value of the color to be used for the shadow
 * - shadowColor prop in base `style` object from RN does not support the colorScheme string values
 *
 * @important In my testing, only certain colors will work with this hook, so be sure to test the colors you want to use (will default to greys.dark/veryLight if no color is passed in)
 * - Not all of the colorScheme values worked when matching the hex value.
 * Some of the more custom colors that did not work were custom named ones like `rose`, `emerald`, etc.
 * - This could be due to the underlying implementation of RN's shadowColor prop
 */
const useShadowColorValue = (shadow: HaloChipProps['shadow']) => {
  const theme = useNativeBaseTheme();

  // this is a type guard to check if shadow is an object and if it has a shadowColor property
  // since shadow can be default T/F for just default styling
  const _shadowColor =
    shadow && typeof shadow === 'object' && 'shadowColor' in shadow && shadow.shadowColor;

  // okay for default shadowColor to be undefined, but if it is defined, it should be a string
  // @ts-ignore
  const colorObject = _shadowColor ? theme.colors[_shadowColor as string] : {};

  // if the colorObject is not found, default to the dark and very light greys for light/dark mode
  return useColorModeValue(
    colorObject?.[500] || Colors.greys.dark,
    colorObject?.[300] || Colors.greys.veryLight
  );
};

const useShadowStyles = (shadow: HaloChipProps['shadow']) => {
  const shadowColor = useShadowColorValue(shadow);

  return shadow
    ? {
        style: {
          shadowColor,
          shadowOffset: {
            width: 1,
            height: 2,
          },
          shadowOpacity: 0.35,
          shadowRadius: 3.84,
          elevation: 5,
          ...(typeof shadow === 'object' ? { ...shadow } : {}),
        },
      }
    : {};
};

export default useShadowStyles;
