export {
  /**
   * Only use this component if you really need to!
   * If you only need a text input, TextInput is preferred.
   */
  Input,
  InputLeftAddon,
  InputRightAddon,
  InputGroup,
} from '@rivallapp/native-base';
