import { type TypedDocumentNode, gql } from '@apollo/client';
import { SportSurfaceEnum, type TRentalActivityName } from '../../../../constants';

type GetRentalDetailsBySportData = {
  rental: {
    _id: string;
    activity_name: TRentalActivityName;
    capacity: number;
    courts: string[];
    images: string[];
    prime_price_cents: number;
    standard_price_cents: number;
    venue: {
      location: {
        lat: number;
        lng: number;
      };
      formatted_address?: string;
      place_id: string;
      neighborhood: string;
      shorthandName: string;
      _id: string;
      image: {
        url: string;
      };
    };
    surface_type: SportSurfaceEnum;
    banner_message?: string;
  };
};

type GetRentalDetailsBySportInput = {
  id: string;
};

const GET_RENTAL_DETAILS_BY_SPORT: TypedDocumentNode<
  GetRentalDetailsBySportData,
  GetRentalDetailsBySportInput
> = gql`
  query Rental($id: ID!) {
    rental(_id: $id) {
      _id
      activity_name
      capacity
      courts
      images
      prime_price_cents
      standard_price_cents
      venue {
        location {
          lat
          lng
        }
        formatted_address
        place_id
        neighborhood
        shorthandName
        _id
        image {
          url
        }
      }
      surface_type
      banner_message
    }
  }
`;

export default GET_RENTAL_DETAILS_BY_SPORT;
