import { type TypedDocumentNode, gql } from '@apollo/client';
import type { TRentalActivityName } from '../../../../../constants';

type GetOpenRentalsData = {
  discoverRentals: {
    rentals: {
      _id: string;
      activity_name: TRentalActivityName;
    }[];
  };
};

type GetOpenRentalsInput = {
  input: {
    venueIds: string[];
    dateLow: string;
  };
};

const GET_OPEN_RENTALS: TypedDocumentNode<GetOpenRentalsData, GetOpenRentalsInput> = gql`
  query getOpenRentals($input: DiscoverRentalsInput!) {
    discoverRentals(input: $input) {
      rentals {
        _id
        activity_name
      }
    }
  }
`;

export default GET_OPEN_RENTALS;
