import { useMemo } from 'react';

import { View, Pressable } from '../../../base-components';
import { type GridItemProps } from '../types';

const GridItem = ({ id, flex = 1, onPress = undefined, disabled, ...rest }: GridItemProps) => {
  const WrapperView = onPress ? Pressable : View;
  const WrapperProps = useMemo(
    () =>
      onPress
        ? {
            onPress: () => onPress({ id, flex, onPress, ...rest }),
            isDisabled: disabled,
          }
        : {},
    [id, onPress, disabled, flex, rest]
  );

  return (
    <WrapperView style={{ flex }} {...WrapperProps}>
      {rest.children}
    </WrapperView>
  );
};

export default GridItem;
