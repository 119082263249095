import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgLicensedReferee = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Path
      stroke="#103b5d"
      d="M45.159 24.185c0 5.541-3.724 9.87-8.135 9.87s-8.134-4.329-8.134-9.87 3.724-9.87 8.134-9.87 8.134 4.33 8.134 9.87ZM14.507 55.796c.265-9.31 7.896-16.777 17.27-16.777h11.454c9.375 0 17.006 7.466 17.27 16.777z"
    />
    <Path
      stroke="#103b5d"
      strokeWidth={2}
      d="M37.065 39.506v15.803M41.862 39.506v15.803M46.659 39.506v15.803M32.269 39.506v15.803M27.472 39.506v15.803M22.675 41.481v13.828"
    />
    <Path
      stroke="#103b5d"
      d="M18.337 45.432v9.877M51.915 41.481v13.828M56.711 45.432v9.877M17.378 46.42v8.889M50.956 40.494v14.815M55.752 44.444v10.865"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="m57.24 53.143-4.454-2.572L47 60.593l3.513-.942.94 3.513z"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="m59.168 50.571-4.454 2.572L60.5 63.164l.941-3.513 3.513.941z"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M54.439 39.647 56 39.502l1.562.145 1.508.429 1.404.699 1.251.945 1.056 1.159.826 1.333.567 1.462.288 1.542v1.568l-.288 1.541-.567 1.463-.826 1.333-1.056 1.159-1.251.945-1.404.699-1.508.43-1.562.144-1.562-.145-1.508-.429-1.404-.699-1.251-.945-1.056-1.159-.826-1.333-.566-1.462-.289-1.542v-1.568l.289-1.541.566-1.463.826-1.333 1.056-1.159 1.252-.945 1.403-.699z"
    />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
  </Svg>
);
export default SvgLicensedReferee;
