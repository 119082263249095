import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgStreetParking = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      d="m29 48.135-3-12L53 36l-3 12c2.809 5.744.796 14.438-8.5 15.522q-.95.112-2 .113c-11 0-13.5-9.5-10.5-15.5"
    />
    <Path fill="#103b5d" d="M36 63h7v16h-7z" />
    <Path
      stroke="#103b5d"
      d="m26 36 3.5-16.5c2.5-1.267 7-2.5 10-2.5s7.5 1.099 10 2.366l3.5 16.5-3 12c2.809 5.743.796 14.437-8.5 15.522q-.95.11-2 .112C28.5 63.5 26 54 29 48z"
    />
    <Path
      stroke="#103b5d"
      d="M39.495 21c-1.865 0-4.583.613-6.335 1.295a.95.95 0 0 0-.577.691l-.952 4.408a.5.5 0 0 0 .489.606H46.88a.5.5 0 0 0 .489-.605l-.964-4.488a.94.94 0 0 0-.577-.69c-1.753-.67-4.47-1.217-6.334-1.217ZM53 36H26"
    />
    <Circle cx={37.5} cy={32} r={1} fill="#103b5d" />
    <Circle cx={45.5} cy={32} r={1} fill="#103b5d" />
    <Circle cx={33.5} cy={32} r={1} fill="#103b5d" />
    <Circle cx={41.5} cy={32} r={1} fill="#103b5d" />
    <Path stroke="#fff" strokeLinecap="round" d="M45 41.5H34M39.5 51.5v-4" />
    <Path stroke="#103b5d" d="M42.5 63.5v15.767m-6-15.767v15.767" />
  </Svg>
);
export default SvgStreetParking;
