import { gql, type TypedDocumentNode } from '@apollo/client';
import { ProgramTypeEnum } from '../../../constants';

type LeagueInput = {
  id: string;
};

export type LeagueResult = {
  league: {
    _id: string;
    programType: ProgramTypeEnum;
  };
};

export const GET_LEAGUE_INFO: TypedDocumentNode<LeagueResult, LeagueInput> = gql`
  query League($id: ID!) {
    league(_id: $id) {
      _id
      programType
    }
  }
`;
