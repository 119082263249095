import { gql, type TypedDocumentNode } from '@apollo/client';
import type { RentalMessageInput } from '.';

interface SendRentalEmailsData {
  sendRentalEmails: {
    emailCount: number;
  };
}

interface SendRentalEmailsVars {
  input: RentalMessageInput;
}

const SEND_RENTAL_EMAILS: TypedDocumentNode<SendRentalEmailsData, SendRentalEmailsVars> = gql`
  mutation SendRentalEmails($input: RentalMessageInput!) {
    sendRentalEmails(input: $input) {
      emailCount
    }
  }
`;

export default SEND_RENTAL_EMAILS;
