import { openMap } from '../../../../../utilities';
import { type GetRentalGeneralInfoData } from '../graphql/GET_RENTAL_GENERAL_INFO';
import { type GetVenueGeneralInfoData } from '../graphql/GET_VENUE_GENERAL_INFO';

/**
 * Returns a function to either open the venue in mobile maps or link to Google Maps on web
 */
const getInfoGridVenueLink = (
  rentalData?: GetRentalGeneralInfoData,
  venueData?: GetVenueGeneralInfoData
) => {
  const venueAddress =
    rentalData?.rental.venue.formatted_address || venueData?.venue.formatted_address || '';
  const place_id = rentalData?.rental.venue.place_id || venueData?.venue.place_id;

  const onPressVenue = {
    onPress: () =>
      openMap({
        venueAddress,
        placeId: place_id!,
      }),
  };

  return onPressVenue;
};

export default getInfoGridVenueLink;
