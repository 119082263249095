import { type TypedDocumentNode, gql } from '@apollo/client';
import { VenuePerksEnum } from '../../../../../constants/enums/venue-features-enum';

type GetRentalPerksFromVenueData = {
  venue: {
    _id: string;
    rentals: {
      _id: string;
      perks: VenuePerksEnum[];
      custom_perks?: string;
    }[];
  };
};

type GetRentalPerksFromVenueInput = {
  venueId: string;
};

const GET_RENTAL_PERKS_FROM_VENUE: TypedDocumentNode<
  GetRentalPerksFromVenueData,
  GetRentalPerksFromVenueInput
> = gql`
  query venue($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      rentals {
        _id
        perks
        custom_perks
      }
    }
  }
`;

export default GET_RENTAL_PERKS_FROM_VENUE;
