import { ApolloLink } from '@apollo/client';
import createErrorSimulationLink from './createErrorSimulationLink.js';
import createAuthLink from './createAuthLink.js';
import createSplitWsHttpLink from './createSplitWsHttpLink.js';
/**
 * `createErrorSimulationLink` custom function that intercepts the request and throws an error if the custom error context variables are passed.
 * - This is used for testing purposes in `__DEV__` mode only.
 *
 * `createAuthLink` function from Apollo to add the `authToken` to the headers of
 *  the request and create a link to the API.
 * - This is used to connect to the provided API link with headers set.
 *
 * `createSplitWsHttpLink` sets up subscriptions over websockets and queries/mutations over HTTP.
 * - https://www.apollographql.com/docs/react/data/subscriptions#2-initialize-a-graphqlwslink
 *
 * `onError` links are handled per-platform as Sentry SDKs are platform-specific.
 *
 * @important the order of the links is important, as the request travels down the link chain, and then back up the chain
 */
const createLink = (clientConfig) => {
    return ApolloLink.from([
        createErrorSimulationLink(), // this is undefined in release, will be filtered out
        createAuthLink(clientConfig),
        createSplitWsHttpLink(clientConfig),
    ].filter(Boolean));
};
export default createLink;
