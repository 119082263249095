// step validation constants
export const minGroupSize = 1;
export const minGroupPasswordLength = 5;
export const minCourtsSelected = 1;
export const minTimeSlotsSelected = 2;
export const gracePeriod = 15;

// time slot format constants

/**
 * The format of the start_time_str and end_time_str from the API
 * - 24 hr format
 */
export const timeSlotApiFormat = 'HH:mm';

/**
 * The format of the time slots label in the UI
 * - 12 hr format, sheds the leading 0 so '07:00' -> '7:00'
 */
export const timeSlotLabelFormat = 'h:mm';

/**
 * The full format of the time slots
 * - 12 hr format, gets the AM/PM
 */
export const timeSlotFormat = 'h:mm A';

/**
 * The format the API expects for the start date
 */
export const startDateApiFormat = 'MM/DD/YYYY';
