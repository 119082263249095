import { useState, useEffect } from 'react';
import { Skeleton, Text, View } from '../../../base-components';
import styles from '../styles';

interface HoldTimerProps {
  expirationDate: string;
  onlyTime?: boolean;
}

const HoldTimer = ({ expirationDate, onlyTime = false }: HoldTimerProps) => {
  const [loading, setLoading] = useState(true);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [holdExpired, setHoldExpired] = useState(false);

  useEffect(() => {
    const expirationTime = new Date(expirationDate).getTime();
    const currentTime = new Date().getTime();
    const timeUntilExpiration = expirationTime - currentTime;

    if (timeUntilExpiration > 0) {
      const intervalId = setInterval(() => {
        const newTimeUntilExpiration = expirationTime - new Date().getTime();
        if (newTimeUntilExpiration > 0) {
          setSecondsRemaining(Math.ceil(newTimeUntilExpiration / 1000));
          setLoading(false);
        } else {
          setHoldExpired(true);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
    return () => {
      setHoldExpired(true);
    };
  }, [expirationDate]);

  if (loading)
    return (
      <View style={styles.loadingContainer}>
        <Skeleton style={styles.timerSkeleton} />
      </View>
    );

  if (!holdExpired) {
    const minutes = Math.floor(secondsRemaining / 60);
    const seconds = secondsRemaining % 60;
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return (
      <Text style={[styles.holdText, styles.dropInLabel]}>
        {onlyTime ? '' : 'Hold:\n'}
        {minutes}:{formattedSeconds}
      </Text>
    );
  }

  if (holdExpired) {
    return onlyTime ? null : (
      <Text style={[styles.holdText, styles.dropInLabel]}>hold{'\n'}expired</Text>
    );
  }

  return null;
};

export default HoldTimer;
