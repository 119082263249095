import { GET_CURRENT_USER } from '../hooks/useCurrentUser';

/**
 * @description Global location for getCurrentUser. Variations can be created to test different types of users.
 * Try to add fields to existing variants before creating a brand new one.
 * Adding fields shouldn't affect existing uses, but don't delete unless you're **sure** the fields are unused.
 * Add notes when updating this doc.
 */

/** Returns owen.
 * Home org is set to Super Sportz
 *
 * `shouldSeePricesWithFees` is false
 *
 * `notificationPreferences` are all true
 * */
// #region owen
export const getCurrentUserOwen = [
  {
    request: {
      query: GET_CURRENT_USER,
    },
    result: {
      data: {
        currentUser: {
          _id: '5cd91a7033db650050758e36',
          username: 'owen',
          fullName: 'Owen :) Ollivander!',
          lastName: 'Ollivander!',
          firstName: 'Owen :)',
          picture: '',
          gender: 'o',
          shirt_size: 'm',
          homeOrganization: '5cd91a7033db650050758e2f',
          email: 'owen@kenter.com',
          shouldSeePricesWithFees: false,
          notificationPreferences: {
            marketing: {
              email: true,
              sms: true,
              push: true,
            },
            team: {
              email: true,
              sms: true,
              push: true,
            },
          },
          paymentSources: [
            {
              last4: 4242,
              isDefault: true,
              isExpired: false,
              exp_month: 4,
              exp_year: 2042,
              brand: 'Visa',
              id: 'card_1Q7N48CyzbeSckFnVb9afZ4y',
            },
          ],
          availableCredits: [],
        },
      },
    },
  },
];
// #endregion

// #region Player - Doggone Fun
/** Returns a non-admin user with Doggone Fun as home org
 *
 * `shouldSeePricesWithFees` is true
 *
 * `notificationPreferences` are all false
 */
export const getCurrentUserDoggoneFun = [
  {
    request: {
      query: GET_CURRENT_USER,
    },
    result: {
      data: {
        currentUser: {
          _id: '63e125aabe5e71340c22faa5',
          fullName: 'Artemis Goddess of the Hunt',
          username: 'artemis',
          lastName: 'Goddess of the Hunt',
          firstName: 'Artemis',
          picture: '',
          gender: 'f',
          shirt_size: 'm',
          homeOrganization: '5cddbbd052fb9e00500024a6',
          email: 'artemis@mtolympus.gov',
          shouldSeePricesWithFees: true,
          notificationPreferences: {
            marketing: {
              email: false,
              sms: false,
              push: false,
            },
            team: {
              email: false,
              sms: false,
              push: false,
            },
          },
          paymentSources: [
            {
              last4: 4242,
              isDefault: true,
              isExpired: false,
              exp_month: 4,
              exp_year: 2042,
              brand: 'Visa',
              id: 'card_1Q7N48CyzbeSckFnVb9afZ4y',
            },
          ],
          availableCredits: [],
        },
      },
    },
  },
];
// #endregion
