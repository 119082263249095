import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgNewFacility = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M17 33.429 40 17l23 16.429v4.517h-6.953V63H23.954V37.946H17z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="m41.24 44.143-4.454-2.572L31 51.593l3.513-.942.94 3.513z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="m43.168 41.571-4.454 2.572L44.5 54.164l.941-3.513 3.513.941z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="M38.439 30.647 40 30.502l1.562.145 1.508.429 1.404.699 1.251.945 1.056 1.159.826 1.333.566 1.462.289 1.542v1.568l-.289 1.541-.566 1.463-.826 1.333-1.056 1.159-1.251.945-1.404.699-1.508.43-1.562.144-1.562-.145-1.508-.429-1.404-.699-1.251-.945-1.056-1.159-.826-1.333-.567-1.462-.288-1.542v-1.568l.288-1.541.567-1.463.826-1.333 1.056-1.159 1.252-.945 1.403-.699z"
    />
  </Svg>
);
export default SvgNewFacility;
