import React, { useState } from 'react';
import { skipToken, useSuspenseQuery } from '@apollo/client';
import type { IStackProps } from '@rivallapp/native-base';
import { Stack, Pressable, Text, Modal } from '../../../../base-components';
import GET_VENUE_NAME from './graphql/GET_VENUE_NAME';
import GET_RENTAL_TITLE_INFO from './graphql/GET_RENTAL_TITLE_INFO';
import { RentalHeading, RentalText } from '../RentalCommonText';

type Props = {
  rentalId?: string;
  venueId?: string;
  containerStyle?: IStackProps;
};

/**
 * Fetches venue name for the title and renders an info popup about rentals.
 * @important Make sure wrap this component with Suspense and ErrorBoundary!
 */
const RentalTitleWithPopup = ({ rentalId, venueId, containerStyle = {} }: Props) => {
  const [showModal, setShowModal] = useState(false);

  const { data: venueData } = useSuspenseQuery(
    GET_VENUE_NAME,
    venueId
      ? {
          variables: {
            venueId,
          },
        }
      : skipToken
  );

  const { data: rentalData } = useSuspenseQuery(
    GET_RENTAL_TITLE_INFO, // We don't need everything here. Reusing this query just to save a file.
    rentalId
      ? {
          variables: {
            rentalId,
          },
        }
      : skipToken
  );

  const venueName = venueData?.venue.shorthandName || rentalData?.rental.venue.shorthandName;

  const activityName = rentalData?.rental.activity_name
    ? `${rentalData?.rental.activity_name} `
    : '';

  return (
    <Stack {...containerStyle}>
      <RentalHeading>
        {activityName}Rentals at {venueName}
      </RentalHeading>
      <Pressable onPress={() => setShowModal(true)}>
        <RentalText isLink>What&apos;s a Rental?</RentalText>
      </Pressable>
      <Modal size="lg" isOpen={showModal} onClose={() => setShowModal(false)} useRNModal>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>What&apos;s a Rental?</Modal.Header>
          <Modal.Body>
            <Text>
              A rental is a private field or court booking for you and your friends! You can book in
              60-min increments and reserve Volo space for practice or friendly competition! You
              will check in with the host upon arrival and must bring your own equipment.
            </Text>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Stack>
  );
};

export default RentalTitleWithPopup;
