const CARD_ICONS_PATH = 'assets/icons/cards';

const cardIcons = {
  Visa: `${CARD_ICONS_PATH}visa.png`,
  MasterCard: `${CARD_ICONS_PATH}mastercard.png`,
  Discover: `${CARD_ICONS_PATH}discover.png`,
  Amex: `${CARD_ICONS_PATH}amex.png`,
  Generic: `${CARD_ICONS_PATH}generic.png`,
} as const;

export type CardIcon = keyof typeof cardIcons;

const WHITE_ICONS_PATH = 'assets/icons/White';

const whiteIcons = {
  'Axe Throwing': `${WHITE_ICONS_PATH}AxeThrowing.png`,
  // Baseball: `${WHITE_ICONS_PATH}Baseball.png`,
  Basketball: `${WHITE_ICONS_PATH}Basketball.png`,
  Badminton: `${WHITE_ICONS_PATH}Badminton.png`,
  Bingo: `${WHITE_ICONS_PATH}Bingo.png`,
  Bowling: `${WHITE_ICONS_PATH}Bowling.png`,
  Bocce: `${WHITE_ICONS_PATH}Bocce.png`,
  Bootcamp: `${WHITE_ICONS_PATH}Bootcamp.png`,
  'Board Games': `${WHITE_ICONS_PATH}BoardGames.png`,
  'Bar Games': `${WHITE_ICONS_PATH}BarGames.png`,
  'Call of Duty': `${WHITE_ICONS_PATH}CallofDuty.png`,
  Cornhole: `${WHITE_ICONS_PATH}Cornhole.png`,
  // Cricket: `${WHITE_ICONS_PATH}Cricket.png`,
  // Credit: `${WHITE_ICONS_PATH}Credit.png`,
  // Cycling: `${WHITE_ICONS_PATH}Cycling.png`,
  Darts: `${WHITE_ICONS_PATH}Darts.png`,
  Default: `${WHITE_ICONS_PATH}Bingo.png`,
  Dodgeball: `${WHITE_ICONS_PATH}Dodgeball.png`,
  Event: `${WHITE_ICONS_PATH}Events.png`,
  'E-Sports': `${WHITE_ICONS_PATH}eSports.png`,
  FIFA: `${WHITE_ICONS_PATH}Fifa.png`,
  Frisbee: `${WHITE_ICONS_PATH}Frisbee.png`,
  Futsal: `${WHITE_ICONS_PATH}Futsal.png`,
  'Flip Cup': `${WHITE_ICONS_PATH}FlipCup.png`,
  'Field Hockey': `${WHITE_ICONS_PATH}FieldHockey.png`,
  // Food: `${WHITE_ICONS_PATH}Food.png`,
  Football: `${WHITE_ICONS_PATH}Football.png`,
  'Flag Football': `${WHITE_ICONS_PATH}Football.png`,
  'Game Show': `${WHITE_ICONS_PATH}GameShow.png`,
  Golf: `${WHITE_ICONS_PATH}Golf.png`,
  // 'Ice Hockey': `${WHITE_ICONS_PATH}IceHockey.png`,
  Jackbox: `${WHITE_ICONS_PATH}JackBox.png`,
  Kickball: `${WHITE_ICONS_PATH}Kickball.png`,
  Lacrosse: `${WHITE_ICONS_PATH}Lacrosse.png`,
  'Laser Tag': `${WHITE_ICONS_PATH}LaserTag.png`,
  // 'Lawn Bowling': `${WHITE_ICONS_PATH}LawnBowling.png`,
  Madden: `${WHITE_ICONS_PATH}Madden.png`,
  'Mario Kart': `${WHITE_ICONS_PATH}MarioKart.png`,
  // Medical: `${WHITE_ICONS_PATH}Medical.png`,
  'Mini Golf': `${WHITE_ICONS_PATH}MiniGolf.png`,
  NHL: `${WHITE_ICONS_PATH}NHL.png`,
  'Ping Pong': `${WHITE_ICONS_PATH}Pingpong.png`,
  Pickleball: `${WHITE_ICONS_PATH}Pickleball.png`,
  'Rocket League': `${WHITE_ICONS_PATH}RocketLeague.png`,
  // Rugby: `${WHITE_ICONS_PATH}Rugby.png`,
  // Running: `${WHITE_ICONS_PATH}Running.png`,
  Sailing: `${WHITE_ICONS_PATH}Sailing.png`,
  'Shuffle Board': `${WHITE_ICONS_PATH}ShuffleBoard.png`,
  Skeeball: `${WHITE_ICONS_PATH}Skeeball.png`,
  Soccer: `${WHITE_ICONS_PATH}Soccer.png`,
  Softball: `${WHITE_ICONS_PATH}Softball.png`,
  Spin: `${WHITE_ICONS_PATH}Spin.png`,
  'Street Hockey': `${WHITE_ICONS_PATH}StreetHockey.png`,
  'Super Smash Bros': `${WHITE_ICONS_PATH}SuperSmashBros.png`,
  // Spikeball: `${WHITE_ICONS_PATH}Spikeball.png`,
  Tennis: `${WHITE_ICONS_PATH}Tennis.png`,
  // Testing: `${WHITE_ICONS_PATH}testing.png`,
  // Transportation: `${WHITE_ICONS_PATH}transportation.png`,
  Trip: `${WHITE_ICONS_PATH}Trip.png`,
  Trivia: `${WHITE_ICONS_PATH}Trivia.png`,
  Volleyball: `${WHITE_ICONS_PATH}Volleyball.png`,
  Volunteer: `${WHITE_ICONS_PATH}Volunteer.png`,
  Wiffleball: `${WHITE_ICONS_PATH}WiffleBall.png`,
  Yoga: `${WHITE_ICONS_PATH}Yoga.png`,
} as const;

export type WhiteIcon = keyof typeof whiteIcons;

const PIN_ICON_PATH = 'assets/icons/Pins/';

const activePinIcons = {
  'Axe Throwing': `${PIN_ICON_PATH}AxeThrowing_Blue.png`,
  Badminton: `${PIN_ICON_PATH}Badminton_Blue.png`,
  'Bar Games': `${PIN_ICON_PATH}BarGames_Blue.png`,
  'Bar Olympics': `${PIN_ICON_PATH}BarOlympics_Blue.png`,
  // Baseball: `${PIN_ICON_PATH}Baseball_Blue.png`,
  Basketball: `${PIN_ICON_PATH}Basketball_Blue.png`,
  Bingo: `${PIN_ICON_PATH}Bingo_Blue.png`,
  'Board Games': `${PIN_ICON_PATH}BoardGames_Blue.png`,
  Bocce: `${PIN_ICON_PATH}Bocce_Blue.png`,
  Bootcamp: `${PIN_ICON_PATH}Bootcamp_Blue.png`,
  Bowling: `${PIN_ICON_PATH}Bowling_Blue.png`,
  'Call of Duty': `${PIN_ICON_PATH}CallofDuty_Blue.png`,
  Cornhole: `${PIN_ICON_PATH}Cornhole_Blue.png`,
  // Cricket: `${PIN_ICON_PATH}Cricket_Blue.png`,
  // Credit: `${PIN_ICON_PATH}Credit_Blue.png`,
  // Cycling: `${PIN_ICON_PATH}Cycling_Blue.png`,
  Darts: `${PIN_ICON_PATH}Darts_Blue.png`,
  Default: `${PIN_ICON_PATH}Default_Blue.png`,
  Dodgeball: `${PIN_ICON_PATH}Dodgeball_Blue.png`,
  Duckpin: `${PIN_ICON_PATH}Duckpin_Blue.png`,
  'E-Sports': `${PIN_ICON_PATH}eSports_Blue.png`,
  Event: `${PIN_ICON_PATH}Events_Blue.png`,
  'Field Hockey': `${PIN_ICON_PATH}FieldHockey_Blue.png`,
  FIFA: `${PIN_ICON_PATH}Fifa_Blue.png`,
  'Flag Football': `${PIN_ICON_PATH}FlagFootball_Blue.png`,
  'Flip Cup': `${PIN_ICON_PATH}FlipCup_Blue.png`,
  Football: `${PIN_ICON_PATH}FlagFootball_Blue.png`,
  Frisbee: `${PIN_ICON_PATH}Frisbee_Blue.png`,
  Futsal: `${PIN_ICON_PATH}Futsal_Blue.png`,
  // Food: `${PIN_ICON_PATH}Food_Blue.png`,
  'Game Show': `${PIN_ICON_PATH}GameShow_Blue.png`,
  Golf: `${PIN_ICON_PATH}Golf_Blue.png`,
  Hiking: `${PIN_ICON_PATH}Hiking_Blue.png`,
  Hockey: `${PIN_ICON_PATH}Hockey_Blue.png`,
  'Ice Hockey': `${PIN_ICON_PATH}Hockey_Blue.png`,
  Jackbox: `${PIN_ICON_PATH}JackBox_Blue.png`,
  KanJam: `${PIN_ICON_PATH}KanJam_Blue.png`,
  Kanjam: `${PIN_ICON_PATH}KanJam_Blue.png`,
  Kickball: `${PIN_ICON_PATH}Kickball_Blue.png`,
  Lacrosse: `${PIN_ICON_PATH}Lacrosse_Blue.png`,
  'Laser Tag': `${PIN_ICON_PATH}LaserTag_Blue.png`,
  // 'Lawn Bowling': `${PIN_ICON_PATH}LawnBowling_Blue.png`,
  Madden: `${PIN_ICON_PATH}Madden_Blue.png`,
  'Mario Kart': `${PIN_ICON_PATH}MarioKart_Blue.png`,
  // Medical: `${PIN_ICON_PATH}Medical_Blue.png`,
  'Mini Golf': `${PIN_ICON_PATH}MiniGolf_Blue.png`,
  NHL: `${PIN_ICON_PATH}NHL_Blue.png`,
  Pickleball: `${PIN_ICON_PATH}PickleBall_Blue.png`,
  'Ping Pong': `${PIN_ICON_PATH}Pingpong_Blue.png`,
  'Rocket League': `${PIN_ICON_PATH}RocketLeague_Blue.png`,
  // Rugby: `${PIN_ICON_PATH}Rugby_Blue.png`,
  // Running: `${PIN_ICON_PATH}Running_Blue.png`,
  Sailing: `${PIN_ICON_PATH}Sailing_Blue.png`,
  'Shuffle Board': `${PIN_ICON_PATH}ShuffleBoard_Blue.png`,
  Skeeball: `${PIN_ICON_PATH}Skeeball_Blue.png`,
  Soccer: `${PIN_ICON_PATH}Soccer_Blue.png`,
  Softball: `${PIN_ICON_PATH}Softball_Blue.png`,
  Spin: `${PIN_ICON_PATH}Spin_Blue.png`,
  'Street Hockey': `${PIN_ICON_PATH}StreetHockey_Blue.png`,
  'Super Smash Bros': `${PIN_ICON_PATH}SuperSmashBros_Blue.png`,
  // Spikeball: `${PIN_ICON_PATH}Spikeball_Blue.png`,
  Tennis: `${PIN_ICON_PATH}Tennis_Blue.png`,
  // Testing: `${PIN_ICON_PATH}testing_Blue.png`,
  // Transportation: `${PIN_ICON_PATH}transportation_Blue.png`,
  Trip: `${PIN_ICON_PATH}Trip_Blue.png`,
  Trivia: `${PIN_ICON_PATH}Trivia_Blue.png`,
  Volleyball: `${PIN_ICON_PATH}Volleyball_Blue.png`,
  Volunteer: `${PIN_ICON_PATH}Volunteer_Blue.png`,
  Yoga: `${PIN_ICON_PATH}Yoga_Blue.png`,
} as const;

export type ActivePinIcon = keyof typeof activePinIcons;

const pinIcons = {
  'Axe Throwing': `${PIN_ICON_PATH}AxeThrowing_White.png`,
  Badminton: `${PIN_ICON_PATH}Badminton_White.png`,
  'Bar Games': `${PIN_ICON_PATH}BarGames_White.png`,
  'Bar Olympics': `${PIN_ICON_PATH}BarOlympics_White.png`,
  // Baseball: `${PIN_ICON_PATH}Baseball_White.png`,
  Basketball: `${PIN_ICON_PATH}Basketball_White.png`,
  Bingo: `${PIN_ICON_PATH}Bingo_White.png`,
  'Board Games': `${PIN_ICON_PATH}BoardGames_White.png`,
  Bocce: `${PIN_ICON_PATH}Bocce_White.png`,
  Bootcamp: `${PIN_ICON_PATH}Bootcamp_White.png`,
  Bowling: `${PIN_ICON_PATH}Bowling_White.png`,
  'Call of Duty': `${PIN_ICON_PATH}CallofDuty_White.png`,
  Cornhole: `${PIN_ICON_PATH}Cornhole_White.png`,
  // Cricket: `${PIN_ICON_PATH}Cricket_White.png`,
  // Credit: `${PIN_ICON_PATH}Credit_White.png`,
  // Cycling: `${PIN_ICON_PATH}Cycling_White.png`,
  Darts: `${PIN_ICON_PATH}Darts_White.png`,
  Default: `${PIN_ICON_PATH}Default_White.png`,
  Dodgeball: `${PIN_ICON_PATH}Dodgeball_White.png`,
  Duckpin: `${PIN_ICON_PATH}Duckpin_White.png`,
  'E-Sports': `${PIN_ICON_PATH}eSports_White.png`,
  Event: `${PIN_ICON_PATH}Events_White.png`,
  'Field Hockey': `${PIN_ICON_PATH}FieldHockey_White.png`,
  FIFA: `${PIN_ICON_PATH}Fifa_White.png`,
  'Flag Football': `${PIN_ICON_PATH}FlagFootball_White.png`,
  'Flip Cup': `${PIN_ICON_PATH}FlipCup_White.png`,
  Football: `${PIN_ICON_PATH}FlagFootball_White.png`,
  Frisbee: `${PIN_ICON_PATH}Frisbee_White.png`,
  Futsal: `${PIN_ICON_PATH}Futsal_White.png`,
  // Food: `${PIN_ICON_PATH}Food_White.png`,
  'Game Show': `${PIN_ICON_PATH}GameShow_White.png`,
  Golf: `${PIN_ICON_PATH}Golf_White.png`,
  Hiking: `${PIN_ICON_PATH}Hiking_White.png`,
  Hockey: `${PIN_ICON_PATH}Hockey_White.png`,
  'Ice Hockey': `${PIN_ICON_PATH}Hockey_White.png`,
  Jackbox: `${PIN_ICON_PATH}JackBox_White.png`,
  KanJam: `${PIN_ICON_PATH}KanJam_White.png`,
  Kanjam: `${PIN_ICON_PATH}KanJam_White.png`,
  Kickball: `${PIN_ICON_PATH}Kickball_White.png`,
  Lacrosse: `${PIN_ICON_PATH}Lacrosse_White.png`,
  'Laser Tag': `${PIN_ICON_PATH}LaserTag_White.png`,
  // 'Lawn Bowling': `${PIN_ICON_PATH}LawnBowling_White.png`,
  Madden: `${PIN_ICON_PATH}Madden_White.png`,
  'Mario Kart': `${PIN_ICON_PATH}MarioKart_White.png`,
  // Medical: `${PIN_ICON_PATH}Medical_White.png`,
  'Mini Golf': `${PIN_ICON_PATH}MiniGolf_White.png`,
  NHL: `${PIN_ICON_PATH}NHL_White.png`,
  Pickleball: `${PIN_ICON_PATH}PickleBall_White.png`,
  'Ping Pong': `${PIN_ICON_PATH}Pingpong_White.png`,
  'Rocket League': `${PIN_ICON_PATH}RocketLeague_White.png`,
  // Rugby: `${PIN_ICON_PATH}Rugby_White.png`,
  // Running: `${PIN_ICON_PATH}Running_White.png`,
  Sailing: `${PIN_ICON_PATH}Sailing_White.png`,
  'Shuffle Board': `${PIN_ICON_PATH}ShuffleBoard_White.png`,
  Skeeball: `${PIN_ICON_PATH}Skeeball_White.png`,
  Soccer: `${PIN_ICON_PATH}Soccer_White.png`,
  Softball: `${PIN_ICON_PATH}Softball_White.png`,
  Spin: `${PIN_ICON_PATH}Spin_White.png`,
  'Street Hockey': `${PIN_ICON_PATH}StreetHockey_White.png`,
  'Super Smash Bros': `${PIN_ICON_PATH}SuperSmashBros_White.png`,
  // Spikeball: `${PIN_ICON_PATH}Spikeball_White.png`,
  Tennis: `${PIN_ICON_PATH}Tennis_White.png`,
  // Testing: `${PIN_ICON_PATH}testing_White.png`,
  // Transportation: `${PIN_ICON_PATH}transportation_White.png`,
  Trip: `${PIN_ICON_PATH}Trip_White.png`,
  Trivia: `${PIN_ICON_PATH}Trivia_White.png`,
  Volleyball: `${PIN_ICON_PATH}Volleyball_White.png`,
  Volunteer: `${PIN_ICON_PATH}Volunteer_White.png`,
  Yoga: `${PIN_ICON_PATH}Yoga_White.png`,
} as const;

export type PinIcon = keyof typeof pinIcons;

const BLUE_ICON_PATH = '/assets/images/Volo-Sports-Icons/blue-sport-icons/';

const blueIcons = {
  'Axe Throwing': `${BLUE_ICON_PATH}AxeThrowing.png?as=webp`,
  Badminton: `${BLUE_ICON_PATH}Badminton.png?as=webp`,
  'Bar Games': `${BLUE_ICON_PATH}BarGames.png?as=webp`,
  'Bar Olympics': `${BLUE_ICON_PATH}BarOlympics.png?as=webp`,
  // Baseball: `${BLUE_ICON_PATH}Baseball.png?as=webp`,
  Basketball: `${BLUE_ICON_PATH}Basketball.png?as=webp`,
  Bingo: `${BLUE_ICON_PATH}Bingo.png?as=webp`,
  'Board Games': `${BLUE_ICON_PATH}${BLUE_ICON_PATH}BoardGames.png?as=webp`,
  Bocce: `${BLUE_ICON_PATH}Bocce.png?as=webp`,
  Bootcamp: `${BLUE_ICON_PATH}Bootcamp.png?as=webp`,
  Bowling: `${BLUE_ICON_PATH}Bowling.png?as=webp`,
  Broomball: `${BLUE_ICON_PATH}Broomball.png?as=webp`,
  'Call of Duty': `${BLUE_ICON_PATH}CallofDuty.png?as=webp`,
  Climbing: `${BLUE_ICON_PATH}Climbing.png?as=webp`,
  Cornhole: `${BLUE_ICON_PATH}Cornhole.png?as=webp`,
  // Cricket: `${BLUE_ICON_PATH}Cricket.png?as=webp`,
  // Credit: `${BLUE_ICON_PATH}Credit.png?as=webp`,
  // Cycling: `${BLUE_ICON_PATH}Cycling.png?as=webp`,
  Darts: `${BLUE_ICON_PATH}Darts.png?as=webp`,
  Default: `${BLUE_ICON_PATH}Default.png?as=webp`,
  Dodgeball: `${BLUE_ICON_PATH}Dodgeball.png?as=webp`,
  // Duckpin: `${BLUE_ICON_PATH}Duckpin.png?as=webp`,
  'E-Sports': `${BLUE_ICON_PATH}eSports.png?as=webp`,
  Event: `${BLUE_ICON_PATH}Events.png?as=webp`,
  'Field Hockey': `${BLUE_ICON_PATH}FieldHockey.png?as=webp`,
  FIFA: `${BLUE_ICON_PATH}Fifa.png?as=webp`,
  'Flag Football': `${BLUE_ICON_PATH}FlagFootball.png?as=webp`,
  'Flip Cup': `${BLUE_ICON_PATH}FlipCup.png?as=webp`,
  // Football: `${BLUE_ICON_PATH}FlagFootball.png?as=webp`,
  Frisbee: `../images/Volo-Sports-Icons/blue-sport-icons/Frisbee.png?as=webp`,
  Futsal: `${BLUE_ICON_PATH}Futsal.png?as=webp`,
  // Food: `${BLUE_ICON_PATH}Food.png?as=webp`,
  'Game Show': `${BLUE_ICON_PATH}GameShow.png?as=webp`,
  Golf: `${BLUE_ICON_PATH}Golf.png?as=webp`,
  'Happy Hour': `../images/Volo-Sports-Icons/blue-sport-icons/HappyHour.png?as=webp`,
  Hiking: `${BLUE_ICON_PATH}Hiking.png?as=webp`,
  Hockey: `${BLUE_ICON_PATH}Hockey.png?as=webp`,
  // 'Ice Hockey': `${BLUE_ICON_PATH}Hockey.png?as=webp`,
  Jackbox: `${BLUE_ICON_PATH}JackBox.png?as=webp`,
  KanJam: `${BLUE_ICON_PATH}KanJam.png?as=webp`,
  Kickball: `${BLUE_ICON_PATH}Kickball.png?as=webp`,
  Lacrosse: `${BLUE_ICON_PATH}Lacrosse.png?as=webp`,
  'Laser Tag': `${BLUE_ICON_PATH}LaserTag.png?as=webp`,
  // 'Lawn Bowling': return `${BLUE_ICON_PATH}LawnBowling.png?as=webp`,
  Madden: `${BLUE_ICON_PATH}Madden.png?as=webp`,
  'Mario Kart': `${BLUE_ICON_PATH}MarioKart.png?as=webp`,
  Medical: `${BLUE_ICON_PATH}Medical.png?as=webp`,
  'Mini Golf': `${BLUE_ICON_PATH}MiniGolf.png?as=webp`,
  NHL: `${BLUE_ICON_PATH}NHL.png?as=webp`,
  Pickleball: `${BLUE_ICON_PATH}PickleBall.png?as=webp`,
  'Ping Pong': `${BLUE_ICON_PATH}Pingpong.png?as=webp`,
  'Rocket League': `${BLUE_ICON_PATH}RocketLeague.png?as=webp`,
  // Rugby: return `${BLUE_ICON_PATH}Rugby.png?as=webp`,
  // Running: return `${BLUE_ICON_PATH}Running.png?as=webp`,
  Sailing: `${BLUE_ICON_PATH}Sailing.png?as=webp`,
  'Shuffle Board': `${BLUE_ICON_PATH}ShuffleBoard.png?as=webp`,
  Skeeball: `${BLUE_ICON_PATH}Skeeball.png?as=webp`,
  Skiing: `${BLUE_ICON_PATH}Skiing.png?as=webp`,
  Soccer: `${BLUE_ICON_PATH}Soccer.png?as=webp`,
  Softball: `${BLUE_ICON_PATH}Softball.png?as=webp`,
  Spin: `${BLUE_ICON_PATH}Spin.png?as=webp`,
  Squash: `${BLUE_ICON_PATH}Squash.png?as=webp`,
  'Street Hockey': `${BLUE_ICON_PATH}StreetHockey.png?as=webp`,
  'Super Smash Bros': `${BLUE_ICON_PATH}Smash.png?as=webp`,
  // Spikeball: return `${BLUE_ICON_PATH}Spikeball.png?as=webp`,
  Tennis: `${BLUE_ICON_PATH}Tennis.png?as=webp`,
  // Testing: return `${BLUE_ICON_PATH}testing.png?as=webp`,
  Transportation: `${BLUE_ICON_PATH}Transportation.png?as=webp`,
  Trip: `${BLUE_ICON_PATH}Trip.png?as=webp`,
  Trivia: `${BLUE_ICON_PATH}Trivia.png?as=webp`,
  'Ultimate Frisbee': `${BLUE_ICON_PATH}UltimateFrisbee.png?as=webp`,
  Volleyball: `${BLUE_ICON_PATH}Volleyball.png?as=webp`,
  VoloPass: `${BLUE_ICON_PATH}VoloPass.png?as=webp`,
  Volunteer: `${BLUE_ICON_PATH}Volunteer.png?as=webp`,
  Yoga: `${BLUE_ICON_PATH}Yoga.png?as=webp`,
} as const;

export type BlueIcon = keyof typeof blueIcons;

export const getCardIcon = (brand: CardIcon): string => cardIcons[brand] ?? cardIcons.Generic;
export const getWhiteSportIcon = (sport: WhiteIcon): string =>
  whiteIcons[sport] ?? whiteIcons.Default;
export const getPinSportIcon = (sport: PinIcon): string => pinIcons[sport] ?? pinIcons.Default;
export const getActivePinSportIcon = (sport: ActivePinIcon): string =>
  activePinIcons[sport] ?? activePinIcons.Default;
export const getBlueSportIcon = (sport: BlueIcon): string => blueIcons[sport] ?? blueIcons.Default;
