import ReactNativeQRCode, { type QRCodeProps } from 'react-native-qrcode-svg';
import { Colors, useTheme } from '../../theme';

export { type QRCodeProps } from 'react-native-qrcode-svg';

const QRCode = (props: QRCodeProps) => {
  const { dark, colors } = useTheme();

  const backgroundColor = colors.background.toString();
  const color = String(dark ? Colors.white : Colors.primary);

  return (
    <ReactNativeQRCode size={240} color={color} backgroundColor={backgroundColor} {...props} />
  );
};

export default QRCode;
