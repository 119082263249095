import type { FC, ComponentProps } from 'react';
import StarRating, { StarRatingDisplay } from 'react-native-star-rating-widget';
import { useTheme, VoloNativeBaseShades } from '../../theme';

/**
 * Star Rating component with interactive and read-only variations
 * Web-only for now until mobile errors are fixed
 */

type StarRatingComponentProps = ComponentProps<typeof StarRating> & {
  rating: number;
  /**
   * onChange is not used for readOnly variation
   */
  onChange?: (arg: number) => void;
  readOnly?: boolean;
  /**
   * variant default is teal accent color
   */
  variant?: 'gray' | 'yellow';
};

const StarRatingComponent: FC<StarRatingComponentProps> = ({
  rating,
  onChange = () => {},
  readOnly = false,
  variant,
  ...props
}) => {
  // Currently dark mode compatibility is only needed in player-app; default color will cover it
  const { gray } = VoloNativeBaseShades;
  const { colors } = useTheme();

  const switchColor = () => {
    switch (variant) {
      case 'yellow':
        return colors.accents.maybe.toString(); // our other yellows were painful against white background
      case 'gray':
        return gray[500].toString();
      default:
        return colors.accents.select.bg.toString();
    }
  };

  const starColor = switchColor();

  if (readOnly) return <StarRatingDisplay rating={rating} color={starColor} {...props} />;

  return (
    <StarRating
      rating={rating}
      onChange={onChange}
      color={starColor}
      enableHalfStar={false}
      {...props}
    />
  );
};

export default StarRatingComponent;
