import { gql, type TypedDocumentNode } from '@apollo/client';

export type PlayerStrikeCountResponse = {
  nextPlayerStrikeReset: string;
  currentUser: {
    _id: string;
    isVoloPassMember: boolean;
    player_strikes: {
      count: number;
      nextStrikeWillTriggerFee: boolean;
    };
  };
};

export const PLAYER_STRIKE_COUNT: TypedDocumentNode<PlayerStrikeCountResponse> = gql`
  query PlayerStrikeCount {
    nextPlayerStrikeReset
    currentUser {
      _id
      isVoloPassMember
      player_strikes {
        count
        nextStrikeWillTriggerFee
      }
    }
  }
`;

export type AdminPlayerStrikeCountInput = {
  id: string;
};

export type AdminPlayerStrikeCountResponse = {
  nextPlayerStrikeReset: string;
  user: {
    _id: string;
    isVoloPassMember: boolean;
    player_strikes: {
      count: number;
      nextStrikeWillTriggerFee: boolean;
    };
  };
};

export const ADMIN_PLAYER_STRIKE_COUNT: TypedDocumentNode<
  AdminPlayerStrikeCountResponse,
  AdminPlayerStrikeCountInput
> = gql`
  query AdminPlayerStrikeCount($id: ID!) {
    nextPlayerStrikeReset
    user(_id: $id) {
      _id
      isVoloPassMember
      player_strikes {
        count
        nextStrikeWillTriggerFee
      }
    }
  }
`;
