import { gql, useQuery, type TypedDocumentNode } from '@apollo/client';

import { useEffect, useState } from 'react';
import { Colors } from '../../../../theme';
import {
  RentalSectionTitle,
  useRentalBookingStore,
  type RentalBooking,
} from '../../../RentalsRegistration';
import { ApplyPromoCodeModal, ApplyUserCreditsModal } from '../../../../custom-components';
import { Stack, Text, View } from '../../../../base-components';
import { PriceFormatterEnum, PricingMemberTypeEnum } from '../../types';
import { getActivityNameFromValue } from '../../../../constants';
import LineItem from './components/LineItem';

export interface RentalCheckoutData {
  getRentalCheckoutPricing: {
    _id: string;
    priceCentsAfterDiscounts: number;
    maxCreditCents: number;
    formattedPromoDiscountPriceString: string;
    formattedCreditsAppliedString: string;
    formattedFeePriceString: string;
    formattedDonationPriceString: string;
    formattedTotalPriceString: string;
    overflowCreditCents: number;
    bookingPricingBreakdown: {
      _id: string;
      formattedPriceString: string;
      totalPriceCents: number;
    }[];
    promoCode: {
      _id: string;
      message: string;
      valid: boolean;
    };
  };
}

export interface RentalCheckoutVariables {
  input: {
    reservationId?: string;
    donationCents: number;
    creditCents: number;
    promoCodeString: string;
    bookings: Array<{
      rentalId: string;
      bookingId: string;
      selectedTimeSlotIds: string[];
      pricingMemberType?: PricingMemberTypeEnum;
      priceFormatterType?: PriceFormatterEnum;
    }>;
  };
}

export const GET_RENTAL_CHECKOUT_PRICING: TypedDocumentNode<
  RentalCheckoutData,
  RentalCheckoutVariables
> = gql`
  query Query($input: RentalCheckoutInput!) {
    getRentalCheckoutPricing(input: $input) {
      _id
      priceCentsAfterDiscounts
      maxCreditCents
      formattedPromoDiscountPriceString
      formattedCreditsAppliedString
      formattedFeePriceString
      formattedDonationPriceString
      formattedTotalPriceString
      overflowCreditCents
      bookingPricingBreakdown {
        _id
        formattedPriceString
        totalPriceCents
      }
      promoCode {
        _id
        message
        valid
      }
    }
  }
`;

const setCreditCents = (c: number | ((prev: number) => number)) =>
  typeof c === 'function'
    ? useRentalBookingStore.setState(store => ({ creditCents: c(store.creditCents) }))
    : useRentalBookingStore.setState({ creditCents: c });

const setAppliedPromoCodeStr = (p: string) =>
  useRentalBookingStore.setState({ promoCodeString: p });

const setCartTotalAfterDiscountCents = (c: number) =>
  useRentalBookingStore.setState({ cartTotalAfterDiscountCents: c });

/**
 * Adds a '-' to the beginning of a string if it is not empty
 */
const formatMinusString = (value?: string) => (value ? `-${value}` : undefined);

const RentalPaymentSummary = ({
  confirmedBookings,
  onPriceLoading,
}: {
  confirmedBookings: RentalBooking[];
  onPriceLoading: (isLoading: boolean) => void;
}) => {
  /*
    state used for confirmed booking prices & fees for UI purposes when inputs change
    - since inputs change (credits, promo, dono, etc), adding this to state will allow for the UI to update
    those values through the query - but still show the booking prices always since they 'should' not change
  */
  const [confirmedRentalBookingPrices, setConfirmedRentalBookingPrices] = useState<
    Array<{
      bookingId: string;
      formattedPriceString: string;
      activityType: RentalBooking['rentalType'];
    }>
  >(
    confirmedBookings.map(booking => ({
      bookingId: booking.bookingId,
      formattedPriceString: booking.price.formattedSubtotal,
      activityType: booking.rentalType,
    }))
  );
  const [formattedFeePriceString, setFormattedFeePriceString] = useState<string | undefined>();

  const {
    appliedPromoCodeStr,
    creditCents,
    donationCents,
    cartTotalAfterDiscountCents,
    reservationId,
  } = useRentalBookingStore(store => ({
    creditCents: store.creditCents,
    donationCents: store.donation?.value ?? 0,
    appliedPromoCodeStr: store.promoCodeString,
    cartTotalAfterDiscountCents: store.cartTotalAfterDiscountCents,
    reservationId: store.reservationId,
  }));
  const hidePriceModifiers = Boolean(reservationId);

  const { data, loading } = useQuery(GET_RENTAL_CHECKOUT_PRICING, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        reservationId,
        donationCents,
        creditCents,
        promoCodeString: appliedPromoCodeStr,
        bookings: confirmedBookings.map(booking => ({
          rentalId: booking.rentalId,
          bookingId: booking.bookingId,
          selectedTimeSlotIds: booking.steps.courtsSelection.selectedCourts.flatMap(
            court => court.timeSlotIds
          ),
          priceFormatterType: PriceFormatterEnum.PRICE,
        })),
      },
    },
    onCompleted: _data => {
      if (!_data || !_data?.getRentalCheckoutPricing) return;

      const { getRentalCheckoutPricing } = _data;
      const {
        overflowCreditCents,
        formattedFeePriceString: _formattedFeePriceString,
        bookingPricingBreakdown,
        priceCentsAfterDiscounts,
      } = getRentalCheckoutPricing;

      if (priceCentsAfterDiscounts !== cartTotalAfterDiscountCents) {
        setCartTotalAfterDiscountCents(priceCentsAfterDiscounts);
      }

      if (overflowCreditCents) {
        setCreditCents(c => c - overflowCreditCents);
      }

      setConfirmedRentalBookingPrices(
        bookingPricingBreakdown.map(booking => ({
          bookingId: booking._id,
          formattedPriceString: booking.formattedPriceString,
          activityType: confirmedBookings.find(b => b.bookingId === booking._id)?.rentalType!,
        })) || []
      );

      setFormattedFeePriceString(_formattedFeePriceString);
    },
  });
  const {
    formattedTotalPriceString,
    maxCreditCents,
    formattedPromoDiscountPriceString,
    formattedCreditsAppliedString,
    formattedDonationPriceString,
    promoCode: promoData,
  } = data?.getRentalCheckoutPricing || {};

  useEffect(
    function syncExternalLoading() {
      onPriceLoading(loading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading] // only want this to run when loading changes
  );

  return (
    <>
      <RentalSectionTitle>Payment</RentalSectionTitle>
      <View my={2}>
        {!hidePriceModifiers && (
          <ApplyUserCreditsModal
            maxCreditCents={maxCreditCents || 0}
            appliedCreditCents={creditCents}
            onApplyCredits={setCreditCents}
          />
        )}
      </View>
      <Stack flex={1} space={0.5}>
        {/* Rental Bookings from Cart */}
        {confirmedRentalBookingPrices.map(booking => (
          <LineItem
            key={booking.bookingId}
            label={`${getActivityNameFromValue(booking.activityType)} Rental`}
            value={booking.formattedPriceString}
          />
        ))}

        {/* FEES */}
        <LineItem label="Fees" value={formattedFeePriceString} />

        {/* DONATION */}
        {Boolean(donationCents) && (
          <LineItem label="Donation" value={formattedDonationPriceString} loading={loading} />
        )}

        {/* CREDITS */}
        {Boolean(creditCents) && (
          <LineItem
            label="Credits Applied"
            value={formatMinusString(formattedCreditsAppliedString)}
            loading={loading}
            onRemove={() => setCreditCents(0)}
          />
        )}

        {/* PROMO CODE */}
        {!hidePriceModifiers &&
          (!appliedPromoCodeStr ? (
            <ApplyPromoCodeModal
              appliedPromoCodeString={appliedPromoCodeStr}
              onApplyPromoCode={promo => setAppliedPromoCodeStr(promo)}
            />
          ) : (
            <>
              <LineItem
                label={`Promo Code: ${appliedPromoCodeStr}${
                  promoData?.valid || loading ? '' : ' 🚫'
                }`}
                value={
                  promoData?.valid
                    ? formatMinusString(formattedPromoDiscountPriceString)
                    : undefined
                }
                loading={loading}
                onRemove={() => setAppliedPromoCodeStr('')}
                removeLabelStyle={{
                  color: promoData?.valid || loading ? Colors.link : 'error.600',
                }}
              />
              {promoData?.valid === false && (
                <Text italic color="error.600" fontSize="xs" pl={2}>
                  {promoData?.message || 'Invalid promo code'}
                </Text>
              )}
            </>
          ))}

        {/* TOTAL DUE */}
        <LineItem
          label="Total Due"
          labelStyle={{ bold: true, fontSize: 16 }}
          value={formattedTotalPriceString}
          valueStyle={{ bold: true, fontSize: 16 }}
          loading={loading}
        />
      </Stack>
    </>
  );
};

export default RentalPaymentSummary;
