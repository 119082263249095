import { View as NView } from '@rivallapp/native-base';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { type IViewProps } from './types';
import matchSafeAreaInsets from './helpers/matchSafeAreaInsets';

/**
 * A custom view component that utilizes the `native-base` `View` component
 * - utilizes the `useSafeAreaInsets` hook to dynamically add padding to account for safe area insets (e.g., the notch on iPhone X)
 * - This is a fix as utilizing just the SafeAreaView from react-native-safe-area-context does not work as expected (first load of each tab screen flickers as it adjusts for some reason? on version 4.5.0)
 */
const View = ({ withSafeArea = false, style = {}, ...rest }: IViewProps) => {
  const insets = useSafeAreaInsets();
  const safeStyle = withSafeArea ? matchSafeAreaInsets(insets, withSafeArea) : {};

  return <NView style={[safeStyle, style]} {...rest} />;
};

export default View;
