import { gql, type TypedDocumentNode } from '@apollo/client';

export enum PlayerStrikeInvalidationType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export type PlayerStrikeHistoryInput = {
  id: string;
};

export type PlayerStrike = {
  _id: string;
  createdAt: string;
  name: string;
  fee_applied: number;
  invalidated: boolean;
  invalidation_type?: null | PlayerStrikeInvalidationType;
  associated_game?: null | {
    _id: string;
    name: string;
    leagueId: string;
  };
};

export type PlayerStrikeHistoryResponse = {
  user: {
    _id: string;
    player_strikes: {
      history: PlayerStrike[];
    };
  };
};

const PLAYER_STRIKE_HISTORY: TypedDocumentNode<
  PlayerStrikeHistoryResponse,
  PlayerStrikeHistoryInput
> = gql`
  query PlayerStrikeHistory($id: ID!) {
    user(_id: $id) {
      _id
      player_strikes(showInvalidatedStrikes: true) {
        history {
          _id
          createdAt
          name
          fee_applied
          invalidated
          invalidation_type
          associated_game {
            _id
            name
            leagueId
          }
        }
      }
    }
  }
`;

export default PLAYER_STRIKE_HISTORY;
