import { gql, type TypedDocumentNode } from '@apollo/client';
import { type DiscoverFilterVariables } from './types';

// --- Programs ---

type DiscoverNeighborhoodsData = {
  discoverNeighborhoods: {
    neighborhoods: {
      _id: string;
      name: string;
    }[];
  };
};

export const DISCOVER_NEIGHBORHOODS: TypedDocumentNode<
  DiscoverNeighborhoodsData,
  DiscoverFilterVariables
> = gql`
  query discoverNeighborhoods($input: DiscoverInput!) {
    discoverNeighborhoods(input: $input) {
      neighborhoods {
        _id
        name
      }
    }
  }
`;

// --- Rentals ---

type DiscoverRentalNeighborhoodsData = {
  discoverRentalNeighborhoods: {
    _id: string;
    name: string;
  }[];
};

export const DISCOVER_RENTAL_NEIGHBORHOODS: TypedDocumentNode<
  DiscoverRentalNeighborhoodsData,
  DiscoverFilterVariables
> = gql`
  query DiscoverRentalNeighborhoods($input: DiscoverRentalsInput!) {
    discoverRentalNeighborhoods(input: $input) {
      _id
      name
    }
  }
`;
