import { gql, type TypedDocumentNode } from '@apollo/client';

type IsInProgramInput = {
  input: {
    leagueId: string;
  };
};

export type IsInProgramResults = {
  currentUserIsInProgram: {
    isInProgram: boolean;
  };
};

export const IS_IN_PROGRAM: TypedDocumentNode<IsInProgramResults, IsInProgramInput> = gql`
  query currentUserIsInProgram($input: CurrentUserIsInProgramInput!) {
    currentUserIsInProgram(input: $input) {
      isInProgram
    }
  }
`;
