import { type FC, useState } from 'react';
import { FormControl } from '@rivallapp/native-base';

import { DaysOfWeek } from '../../../constants/enums/days-of-week';
import { DayFilter } from '../../../feature-components';
import { Text, Box } from '../../../base-components';
import { type CollapseFormInputProps } from './types';
import { useCollapseFormContext } from '../CollapseFormContext';

export interface DayOfWeekSelectProps extends CollapseFormInputProps {
  onChange?: (arg: string) => void;
  selectedDay?: string;
}

const days = Object.values(DaysOfWeek).map(({ shortName, name }) => ({
  label: name,
  value: shortName,
}));

const DayOfWeekSelect: FC<DayOfWeekSelectProps> = ({
  isRequired,
  label,
  onChange = () => {},
  selectedDay = '',
}) => {
  const { view } = useCollapseFormContext();
  const [currentValue, setCurrentValue] = useState(selectedDay);

  const handleSelectDay = (newVal?: string) => {
    if (!newVal) return;
    if (newVal === currentValue) setCurrentValue('');
    else setCurrentValue(newVal);

    onChange(newVal);
  };

  const selectedDayName = days
    .filter(({ value }) => currentValue === value)
    .map(({ label }) => label);

  switch (view) {
    case 'write':
      return (
        <FormControl isRequired={isRequired}>
          <FormControl.Label>{label}</FormControl.Label>
          <DayFilter onPress={handleSelectDay} selectedDays={[currentValue]} />
        </FormControl>
      );
    case 'read':
      return (
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Text fontWeight="bold">{label} </Text>
          <Text>{selectedDayName}</Text>
        </Box>
      );
    default:
      return null;
  }
};

export default DayOfWeekSelect;
