import { gql, type TypedDocumentNode } from '@apollo/client';

const ORGANIZATION_LIST_QUERY: TypedDocumentNode<{
  organizationList: { _id: string; name: string }[];
}> = gql`
  query organizationList {
    organizationList {
      _id
      name
    }
  }
`;

export default ORGANIZATION_LIST_QUERY;
