import { type TypedDocumentNode, gql } from '@apollo/client';

type Data = {
  venue: {
    _id: string;
    lowest_vp_rental_price?: number;
    lowest_non_vp_rental_price?: number;
    highest_vp_rental_price?: number;
    highest_non_vp_rental_price?: number;
  };
};

type Input = {
  venueId: string;
};

const GET_VENUE_PRICING: TypedDocumentNode<Data, Input> = gql`
  query venue($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      lowest_vp_rental_price
      lowest_non_vp_rental_price
      highest_vp_rental_price
      highest_non_vp_rental_price
    }
  }
`;

export default GET_VENUE_PRICING;
