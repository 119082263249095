/**
 * This was brought over from web and needs refactoring.
 * The needs of the components using it were distinct that the standard native-base Checkbox wasn't sufficient
 * A future version of this checkbox would ideally branch from or be combined with our base-component
 */

import React, { useCallback, useState } from 'react';
import { type ViewStyle } from 'react-native';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { Colors, useTheme } from '../../theme';
import {
  Chevron,
  FontAwesomeIcon,
  Pressable,
  type PressableProps,
  Text,
  type ITextProps,
  View,
} from '../../base-components';

import styles from './styles';

interface CheckboxProps {
  label?: string | React.ReactNode;
  value?: string;
  checked?: boolean;
  expanded?: boolean;
  showChevron?: boolean;
  isDisabled?: boolean;
  onPress: (arg: string) => void;
  onBoxPress?: (arg: string) => unknown;
  onLabelPress?: (arg: string) => unknown;
  _text?: ITextProps;
  _checkbox?: PressableProps;
  _container?: ViewStyle;
}

/**
 * @deprecated April 03 2024. Developers should instead try using `base-components/checkbox` if possible.
 */
const CustomCheckbox = ({
  label,
  value = '',
  checked = false,
  expanded = false, // for chevron
  showChevron = false,
  isDisabled = false,
  onPress,
  onBoxPress, // overrides onPress if passed
  onLabelPress, // overrides onPress if passed
  _text,
  _checkbox,
  _container,
}: CheckboxProps) => {
  const [hovered, setHovered] = useState(false);
  const { dark, colors } = useTheme();

  const pressBox = useCallback(() => {
    if (onBoxPress) {
      onBoxPress(value);
      return;
    }
    onPress(value);
  }, [value, onPress, onBoxPress]);

  const pressLabel = useCallback(() => {
    if (onLabelPress) {
      onLabelPress(value);
      return;
    }
    onPress(value);
  }, [value, onPress, onLabelPress]);

  const onHoverIn = useCallback(() => {
    setHovered(true);
  }, []);

  const onHoverOut = useCallback(() => {
    setHovered(false);
  }, []);

  return (
    <View flex="1" style={[styles.container, _container]}>
      <Pressable
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        accessibilityState={{ checked }}
        accessibilityRole="checkbox"
        borderColor={dark ? colors.border : Colors.black}
        borderWidth={1}
        borderRadius={5}
        onPress={pressBox}
        {..._checkbox}
        isDisabled={isDisabled}
        style={[
          styles.checkbox,
          { backgroundColor: checked ? colors.accents.select.bg : undefined },
          hovered && styles.isHovered,
          ...(_checkbox?.style ? [_checkbox.style] : []),
        ]}
      >
        {checked ? <FontAwesomeIcon icon={faCheck} colorScheme="primary" /> : null}
      </Pressable>
      <Pressable
        flex="1"
        style={[styles.pressable]}
        onPress={pressLabel}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        isDisabled={isDisabled}
      >
        <Text {..._text} style={[styles.label, ...(_text?.style ? [_text.style] : [])]}>
          {label}
        </Text>
        {showChevron ? <Chevron clicked={expanded} /> : null}
      </Pressable>
    </View>
  );
};

export default CustomCheckbox;
