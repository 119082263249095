import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import initialAuthStore, { AUTH_STORE_VERSION } from './initialAuthStore';
import type { AuthStore } from './initialAuthStore';
import { isProduction } from '../../environment';

const useAuthStore = create<AuthStore>()(
  devtools(
    persist(() => initialAuthStore, {
      name: 'auth-store',
      version: AUTH_STORE_VERSION,
      partialize: ({ isAuthenticated, roles, authToken, isAuthenticatedWithHasura = false }) => ({
        roles,
        authToken,
        isAuthenticated,
        isAuthenticatedWithHasura,
      }),
      onRehydrateStorage: () => {
        return (state, error) => {
          if (error) {
            console.error('[AuthStore] Error rehydrating state', error);
          } else if (!isProduction && state?.isAuthenticated) {
            console.log('[AuthStore] User previously authenticated');
          }
        };
      },
    }),
    { enabled: !isProduction }
  )
);

export default useAuthStore;
