import {
  type TypedDocumentNode,
  gql,
  useQuery,
  ApolloError,
  type ApolloQueryResult,
  NetworkStatus,
} from '@apollo/client';
import { useRentalBookingStore } from '../store';

export type PriceFormatterType = 'whole_dollar' | 'price';
export type PricingMemberType = 'member' | 'non_member';

type DefaultRentalPricing = {
  _id: string;
  standard_price_cents: number;
  prime_price_cents: number | null;
  vp_standard_price_cents: number;
  vp_prime_price_cents: number | null;
  member: {
    _id: string;
    standard: number;
    prime: number | null;
  };
  non_member: {
    _id: string;
    standard: number;
    prime: number | null;
  };
};

export interface EstimatedRentalPricingResponse {
  userIsActiveMember: boolean;
  getRentalEstimatedPricing: {
    _id: string;
    formattedCurrentUserPricingString: string;
    formattedCurrentUserPrimeTimePricingString: string | null;
    formattedNonMemberStandardPriceString: string;
    formattedMemberStandardPriceString: string;
    formattedNonMemberPrimeTimePriceString: string | null;
    formattedMemberPrimeTimePriceString: string | null;
    _metadata?: {
      _id: string;
      defaultRentalPricing: DefaultRentalPricing;
      pricingType: PricingMemberType;
      minNonMemberPricingCents: number;
      maxNonMemberPricingCents: number;
      minMemberPricingCents: number;
      maxMemberPricingCents: number;
      minNonMemberPrimeTimePricingCents?: number | null;
      maxNonMemberPrimeTimePricingCents?: number | null;
      minMemberPrimeTimePricingCents?: number | null;
      maxMemberPrimeTimePricingCents?: number | null;
      standardPricingRange: {
        _id: string;
        min: {
          _id: string;
          nonMember: number;
          member: number;
        };
        max: {
          _id: string;
          nonMember: number;
          member: number;
        };
      };
      primeTimePricingRange: {
        _id: string;
        min: {
          _id: string;
          nonMember?: number | null;
          member?: number | null;
        };
        max: {
          _id: string;
          nonMember?: number | null;
          member?: number | null;
        };
      };
    };
  };
}

export interface EstimatedRentalPricingInput {
  input: {
    rentalId: string;
    priceFormatterType?: PriceFormatterType;
    pricingMemberType?: PricingMemberType;
    /**
     * Format: "MM/DD/YYYY"
     */
    selectedDate: string;
  };
}

export const GET_RENTAL_PRICING: TypedDocumentNode<
  EstimatedRentalPricingResponse,
  EstimatedRentalPricingInput
> = gql`
  query GetRentalEstimatedPricing($input: RentalEstimatedPricingInput!) {
    userIsActiveMember
    getRentalEstimatedPricing(input: $input) {
      _id
      formattedCurrentUserPricingString
      formattedCurrentUserPrimeTimePricingString
      formattedMemberPrimeTimePriceString
      formattedMemberStandardPriceString
      formattedNonMemberPrimeTimePriceString
      formattedNonMemberStandardPriceString
      # _debug_metadata {
      #   pricingType
      #   rentalPricing {
      #     _id
      #     prime_price_cents
      #     standard_price_cents
      #     vp_prime_price_cents
      #     vp_standard_price_cents
      #   }
      #   maxMemberPricingCents
      #   maxMemberPrimeTimePricingCents
      #   maxNonMemberPricingCents
      #   maxNonMemberPrimeTimePricingCents
      #   minMemberPricingCents
      #   minMemberPrimeTimePricingCents
      #   minNonMemberPricingCents
      #   minNonMemberPrimeTimePricingCents
      #   primeTimePricingRange {
      #     _id
      #     max {
      #       _id
      #       nonMember
      #       member
      #     }
      #     min {
      #       _id
      #       nonMember
      #       member
      #     }
      #   }
      #   standardPricingRange {
      #     _id
      #     max {
      #       _id
      #       nonMember
      #       member
      #     }
      #     min {
      #       _id
      #       member
      #       nonMember
      #     }
      #   }
      # }
    }
  }
`;

export interface SelectedTimeSlotPricingInput {
  input: {
    rentalId: string;
    selectedTimeSlotIds: Array<string>;
    priceFormatterType?: PriceFormatterType;
    pricingMemberType?: PricingMemberType;
  };
}

export interface SelectedTimeSlotPricingResponse {
  userIsActiveMember: boolean;
  getRentalSelectedTimeSlotPricing: {
    _id: string;
    formattedPriceString: string;
    totalPriceCents: number;
    memberSavingsInCents: number;
    formattedMemberSavingsString: string;
    _metadata: {
      __typename: string;
      _id: string;
      rentalId: string;
      priceFormatterTypeUsed: PriceFormatterType;
      pricingMemberTypeUsed: PricingMemberType;
      defaultRentalPricing: {
        _id: string;
        prime_price_cents: number;
        standard_price_cents: number;
        vp_prime_price_cents: number | null;
        vp_standard_price_cents: number | null;
      };
      timeSlotPricing: {
        _id: string;
        is_prime_time: boolean;
        price_cents: number | null;
        vp_price_cents: number | null;
      }[];
    };
  };
}

export const GET_SELECTED_SLOT_PRICING: TypedDocumentNode<
  SelectedTimeSlotPricingResponse,
  SelectedTimeSlotPricingInput
> = gql`
  query GetSelectedTimeSlotPricing($input: SelectedTimeSlotPricingInput!) {
    userIsActiveMember
    getRentalSelectedTimeSlotPricing(input: $input) {
      _id
      formattedPriceString
      totalPriceCents
      memberSavingsInCents
      formattedMemberSavingsString
      # _metadata {
      #   __typename
      #   _id
      #   rentalId
      #   priceFormatterTypeUsed
      #   pricingMemberTypeUsed
      #   defaultRentalPricing {
      #     _id
      #     prime_price_cents
      #     standard_price_cents
      #     vp_prime_price_cents
      #     vp_standard_price_cents
      #   }
      #   timeSlotPricing {
      #     _id
      #     price_cents
      #     vp_price_cents
      #   }
      # }
    }
  }
`;

export interface RentalEstimatedTimeFramePricingInput {
  input: {
    rentalId: string;
    /**
     * Format: "MM/DD/YYYY"
     */
    selectedDate: string;
    /**
     * Format: "HH:mm"
     */
    startTime: string;
    /**
     * Format: "HH:mm"
     */
    endTime: string;
    priceFormatterType?: PriceFormatterType;
    pricingMemberType?: PricingMemberType;
  };
}

export interface RentalEstimatedTimeFramePricingResponse {
  userIsActiveMember: boolean;
  getRentalEstimatedTimeFramePricing: {
    _id: string;
    totalMaxPriceCents: number;
    totalMinPriceCents: number;
    formattedPricingString: string;
    totalMinMemberSavingsInCents: number;
    totalMaxMemberSavingsInCents: number;
    formattedMemberSavingsString: string;
    _metadata: {
      _id: string;
      rentalId: string;
      defaultRentalPricing: {
        _id: string;
        member: {
          _id: string;
          standard: number;
          prime: number | null;
        };
        non_member: {
          _id: string;
          standard: number;
          prime: number | null;
        };
      };
      pricingMemberTypeUsed: PricingMemberType;
      priceFormatterTypeUsed: PriceFormatterType;
      groupedPricing: {
        _id: string;
        max: number;
        min: number;
      }[];
    };
  };
}

export const GET_TIME_FRAME_PRICING: TypedDocumentNode<
  RentalEstimatedTimeFramePricingResponse,
  RentalEstimatedTimeFramePricingInput
> = gql`
  query TimeFramePricing($input: RentalEstimatedTimeFramePricingInput!) {
    userIsActiveMember
    getRentalEstimatedTimeFramePricing(input: $input) {
      _id
      totalMaxPriceCents
      totalMinPriceCents
      formattedPricingString
      totalMinMemberSavingsInCents
      totalMaxMemberSavingsInCents
      formattedMemberSavingsString
      # _metadata {
      #   _id
      #   rentalId
      #   defaultRentalPricing {
      #     _id
      #     member {
      #       _id
      #       standard
      #       prime
      #     }
      #     non_member {
      #       _id
      #       standard
      #       prime
      #     }
      #   }
      #   pricingMemberTypeUsed
      #   priceFormatterTypeUsed
      #   groupedPricing {
      #     _id
      #     max
      #     min
      #   }
      # }
    }
  }
`;

/**
 * Pricing type to use for the query/hook in formatting the pricing to display
 * - `estimated` used during the beginning of the rental registration process before any slots are selected
 * - `selected` used when courts (slots) are selected and we need to display exact pricing moving forward
 */
type HookVariant =
  | 'estimated-rental-pricing'
  | 'selected-slot-pricing'
  | 'estimated-time-frame-pricing';

interface PricingOptions {
  rentalId: string | undefined;
  selectedDate: Date | null;
  /**
   * - `whole` for whole dollar formatting (WholeFormatter)
   * - `price` for price formatting (PriceFormatter)
   */
  formatterType?: PriceFormatterType;
  /**
   * Used to override the pricing type for the current user
   * - needed in regards to default pricing as we should always show `non_member` pricing
   */
  pricingMemberType?: PricingMemberType;
  /**
   * Used for the estimated-time-frame pricing type
   * - HH:mm format
   */
  startTime?: string;
  /**
   * Used for the estimated-time-frame pricing type
   * - HH:mm format
   */
  endTime?: string;
  /**
   * Used for the selected pricing type
   * - array of the exact time slots to calculate the pricing for
   */
  selectedTimeSlotIds?: Array<string>;
}

interface EstimatedPricingInput {
  rentalId: PricingOptions['rentalId'];
  selectedDate: PricingOptions['selectedDate'];
  formatterType: PricingOptions['formatterType'];
  pricingMemberType?: PricingOptions['pricingMemberType'];
}

interface EstimatedPricingReturn {
  rentalPricing: EstimatedRentalPricingResponse['getRentalEstimatedPricing'];
  estimatedPricing: {
    formattedCurrentUserPricingString: string;
    formattedCurrentUserPrimeTimePricingString: string | null;
  };
  error: ApolloError | undefined;
  loading: boolean;
  refetch: (
    variables?: Partial<EstimatedRentalPricingInput> | undefined
  ) => Promise<ApolloQueryResult<EstimatedRentalPricingResponse>>;
  networkStatus: NetworkStatus;
}

interface TimeFramePricingInput {
  rentalId: PricingOptions['rentalId'];
  selectedDate: PricingOptions['selectedDate'];
  startTime: PricingOptions['startTime'];
  endTime: PricingOptions['endTime'];
  formatterType: PricingOptions['formatterType'];
  pricingMemberType?: PricingOptions['pricingMemberType'];
}

interface TimeFramePricingReturn {
  userIsActiveMember: boolean;
  timeFramePricing: {
    formattedPriceString: string;
    formattedMemberSavingsString: string;
  };
  error: ApolloError | undefined;
  loading: boolean;
  refetch: (
    variables?: Partial<RentalEstimatedTimeFramePricingInput> | undefined
  ) => Promise<ApolloQueryResult<RentalEstimatedTimeFramePricingResponse>>;
  networkStatus: NetworkStatus;
}

interface SelectedPricingInput {
  rentalId: PricingOptions['rentalId'];
  selectedTimeSlotIds: PricingOptions['selectedTimeSlotIds'];
  formatterType: PricingOptions['formatterType'];
  pricingMemberType?: PricingOptions['pricingMemberType'];
}

interface RentalSelectedPricingReturn {
  userIsActiveMember: boolean;
  selectedSlotPricing: {
    formattedPriceString: string;
    formattedMemberSavingsString: string;
  };
  error: ApolloError | undefined;
  loading: boolean;
  refetch: (
    variables?: Partial<SelectedTimeSlotPricingInput> | undefined
  ) => Promise<ApolloQueryResult<SelectedTimeSlotPricingResponse>>;
  networkStatus: NetworkStatus;
}

type InputTypes = {
  'estimated-rental-pricing': EstimatedPricingInput;
  'selected-slot-pricing': SelectedPricingInput;
  'estimated-time-frame-pricing': TimeFramePricingInput;
};

type OutputTypes = {
  'estimated-rental-pricing': EstimatedPricingReturn;
  'selected-slot-pricing': RentalSelectedPricingReturn;
  'estimated-time-frame-pricing': TimeFramePricingReturn;
};

function formatDate(date: Date) {
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

/**
 * useRentalPricing
 *
 * ---
 *
 * Hook to fetch the rental pricing for a rental based of the `variant` provided
 * - `estimated-rental-pricing` - fetches the estimated pricing for the rental based on the selected date &rental Id. This is used mainly before having any time or slots selected
 * - `selected-slot-pricing` - fetches the exact pricing for the selected time slots (once courts are selected and the actual slots are known)
 * - `estimated-time-frame-pricing` - fetches the estimated pricing for a time frame (start & end time) for the rental before any slots are selected
 */
function useRentalPricing<T extends HookVariant>(variant: T, input: InputTypes[T]): OutputTypes[T] {
  const {
    rentalId,
    selectedDate,
    selectedTimeSlotIds,
    formatterType,
    pricingMemberType,
    startTime,
    endTime,
  } = input as PricingOptions;

  // ====================================
  // Estimated Rental Pricing
  // ====================================

  const skipEstimatedPricing = !rentalId || !selectedDate || variant !== 'estimated-rental-pricing';
  const {
    data: estimatedPricingData,
    error: estimatedPricingError,
    refetch: estimatedPricingRefetch,
    loading: estimatedPricingLoading,
    networkStatus: estimatedPricingNetworkStatus,
  } = useQuery(GET_RENTAL_PRICING, {
    skip: skipEstimatedPricing,
    variables: {
      input: {
        rentalId: rentalId!,
        selectedDate: selectedDate ? formatDate(selectedDate) : '',
        priceFormatterType: formatterType,
        pricingMemberType,
      },
    },
  });
  const rentalPricing = estimatedPricingData?.getRentalEstimatedPricing;
  const { formattedCurrentUserPricingString, formattedCurrentUserPrimeTimePricingString } =
    rentalPricing || {};

  // ====================================
  // Selected Slot Pricing
  // ====================================

  const skipSelectedPricing =
    !rentalId ||
    variant !== 'selected-slot-pricing' ||
    !selectedTimeSlotIds ||
    !selectedTimeSlotIds.length;
  const {
    data: selectedPricingData,
    error: selectedPricingError,
    loading: selectedPricingLoading,
    refetch: selectedPricingRefetch,
    networkStatus: selectedPricingNetworkStatus,
  } = useQuery(GET_SELECTED_SLOT_PRICING, {
    skip: skipSelectedPricing,
    variables: {
      input: {
        rentalId: rentalId!,
        selectedTimeSlotIds: selectedTimeSlotIds!,
        priceFormatterType: formatterType,
        pricingMemberType,
      },
    },
    onCompleted: data => {
      const selectedPricing = data.getRentalSelectedTimeSlotPricing;
      const { totalPriceCents, formattedPriceString } = selectedPricing;
      // set the actual price for the selected slots on the store
      useRentalBookingStore.setState(store =>
        store?.currentBooking
          ? {
              currentBooking: {
                ...store.currentBooking,
                price: {
                  subtotal: totalPriceCents,
                  formattedSubtotal: formattedPriceString,
                },
              },
            }
          : store
      );
    },
  });
  const selectedPricing = selectedPricingData?.getRentalSelectedTimeSlotPricing;

  // ====================================
  // Estimated Time Frame Pricing
  // ====================================

  const skipTimeFramePricing =
    variant !== 'estimated-time-frame-pricing' ||
    !rentalId ||
    !selectedDate ||
    // if the start time is the same as the end time, we don't need to fetch the pricing
    // this should also hit when only 1 slot is selected
    startTime === endTime;
  const {
    data: timeFramePricingData,
    error: timeFramePricingError,
    loading: timeFramePricingLoading,
    refetch: timeFramePricingRefetch,
    networkStatus: timeFramePricingNetworkStatus,
  } = useQuery(GET_TIME_FRAME_PRICING, {
    skip: skipTimeFramePricing,
    variables: {
      input: {
        rentalId: rentalId!,
        selectedDate: selectedDate ? formatDate(selectedDate) : '',
        startTime: startTime!,
        endTime: endTime!,
        priceFormatterType: formatterType,
        pricingMemberType,
      },
    },
  });
  const timeFramePricing = timeFramePricingData?.getRentalEstimatedTimeFramePricing;

  switch (variant) {
    case 'estimated-time-frame-pricing':
      return {
        userIsActiveMember: timeFramePricingData?.userIsActiveMember,
        timeFramePricing: {
          formattedPriceString: timeFramePricing?.formattedPricingString,
          formattedMemberSavingsString: timeFramePricing?.formattedMemberSavingsString,
        },
        error: timeFramePricingError,
        loading: timeFramePricingLoading,
        refetch: timeFramePricingRefetch,
        networkStatus: timeFramePricingNetworkStatus,
      } as OutputTypes[T];
    case 'selected-slot-pricing':
      return {
        userIsActiveMember: selectedPricingData?.userIsActiveMember,
        selectedSlotPricing: {
          formattedPriceString: selectedPricing?.formattedPriceString,
          formattedMemberSavingsString: selectedPricing?.formattedMemberSavingsString,
        },
        error: selectedPricingError,
        loading: selectedPricingLoading,
        refetch: selectedPricingRefetch,
        networkStatus: selectedPricingNetworkStatus,
      } as OutputTypes[T];
    case 'estimated-rental-pricing':
    default:
      return {
        rentalPricing,
        estimatedPricing: {
          formattedCurrentUserPricingString,
          formattedCurrentUserPrimeTimePricingString,
        },
        error: estimatedPricingError,
        loading: estimatedPricingLoading,
        refetch: estimatedPricingRefetch,
        networkStatus: estimatedPricingNetworkStatus,
      } as OutputTypes[T];
  }
}

export default useRentalPricing;
