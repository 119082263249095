import { gql } from '@apollo/client';

export default gql`
  mutation removeRegistrantFromDropin($input: RemoveRegistrantFromDropinInput!) {
    removeRegistrantFromDropin(input: $input) {
      dropInSlotId
      playerName
    }
  }
`;
