import moment from 'moment-timezone';
import { type GetRentalGeneralInfoData } from '../graphql/GET_RENTAL_GENERAL_INFO';
import { type GetVenueGeneralInfoData } from '../graphql/GET_VENUE_GENERAL_INFO';

/**
 * Returns a string representing the time range of the venue's first and last timeslots
 */
const getInfoGridTimeRange = (
  rentalData?: GetRentalGeneralInfoData,
  venueData?: GetVenueGeneralInfoData
) => {
  const firstTimeslotStart =
    rentalData?.rental.first_timeslot_by_time.start_time_str ||
    venueData?.venue.first_timeslot_by_time.start_time_str ||
    '';

  const lastTimeslotEnd =
    rentalData?.rental.last_timeslot_by_time.end_time_str ||
    venueData?.venue.last_timeslot_by_time.end_time_str ||
    '';

  const timeRange = `${moment(firstTimeslotStart, 'HH:mm').format('h:mma')}-${moment(
    lastTimeslotEnd,
    'HH:mm'
  ).format('h:mma')}`;

  return timeRange;
};

export default getInfoGridTimeRange;
