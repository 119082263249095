import { type TypedDocumentNode, gql } from '@apollo/client';

type GetRentalGistData = {
  rental: {
    _id: string;
    gist: string;
    venue: {
      _id: string;
      shorthandName: string;
    };
  };
};

type GetRentalGistInput = {
  rentalId: string;
};

const GET_RENTAL_GIST: TypedDocumentNode<GetRentalGistData, GetRentalGistInput> = gql`
  query rental($rentalId: ID!) {
    rental(_id: $rentalId) {
      _id
      gist
      venue {
        _id
        shorthandName
      }
    }
  }
`;

export default GET_RENTAL_GIST;
