import { ProgramTypeEnum, SubTabEnum, MainTabEnum } from '../../../constants/enums';

export const ProgramTypesMapFromTabs = {
  [MainTabEnum.SPORTS]: {
    [SubTabEnum.DAILY]: [
      ProgramTypeEnum.PICKUP,
      ProgramTypeEnum.PRACTICE,
      ProgramTypeEnum.DROPIN,
      ProgramTypeEnum.CLINIC,
    ],
    [SubTabEnum.LEAGUE]: [ProgramTypeEnum.LEAGUE],
    [SubTabEnum.TOURNAMENT]: [ProgramTypeEnum.TOURNAMENT],
  },
  [MainTabEnum.EVENTS]: {
    [SubTabEnum.LIVE]: [ProgramTypeEnum.EVENT],
    [SubTabEnum.VIRTUAL]: [ProgramTypeEnum.VIRTUAL],
  },
  [MainTabEnum.CLASSES]: {
    [SubTabEnum.DAILY]: [ProgramTypeEnum.CLASS, ProgramTypeEnum.CLINIC, ProgramTypeEnum.SERIES],
    [SubTabEnum.SERIES]: [ProgramTypeEnum.SERIES],
  },
  [MainTabEnum.VOLUNTEER]: {
    [SubTabEnum.VOLUNTEER]: [ProgramTypeEnum.VOLUNTEER_LEAGUE, ProgramTypeEnum.VOLUNTEER_EVENT],
  },
} as const;

type MainTab = (typeof MainTabEnum)[keyof typeof MainTabEnum];
type SubTab = (typeof SubTabEnum)[keyof typeof SubTabEnum];

const getProgramTypesFromTabs = (
  mainTab: MainTab | undefined | null,
  subTab: SubTab | undefined | null
): ProgramTypeEnum[] => {
  if (!mainTab || !subTab || !(mainTab in ProgramTypesMapFromTabs)) return [];

  const subToMainMap = ProgramTypesMapFromTabs[mainTab as keyof typeof ProgramTypesMapFromTabs];

  if (!(subTab in subToMainMap)) return [];

  return subToMainMap[subTab as keyof typeof subToMainMap];
};

export default getProgramTypesFromTabs;
