import { faGem } from '@fortawesome/pro-solid-svg-icons/faGem';
import type { IHStackProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Stack/HStack';
import {
  Row,
  Button,
  FontAwesomeIcon,
  type ButtonProps,
  type IFontAwesomeIconProps,
} from '../../base-components';

type Props = {
  _iconProps?: Omit<IFontAwesomeIconProps, 'icon'>;
  _containerProps?: IHStackProps;
  _buttonProps?: ButtonProps;
};

const PremierButton = ({ _iconProps, _containerProps, _buttonProps }: Props) => {
  return (
    <Row alignItems="center" {..._containerProps}>
      <FontAwesomeIcon icon={faGem} color="gray.300" {..._iconProps} />
      <Button isDisabled _disabled={{ opacity: 1 }} variant="link" {..._buttonProps}>
        Premier
      </Button>
    </Row>
  );
};
export default PremierButton;
