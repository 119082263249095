import { type FC, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '../../../theme';
import {
  FontAwesomeIcon,
  IconButton,
  Popover,
  Text,
  Stack,
  Heading,
  type IFontAwesomeIconProps,
} from '../../../base-components';
import { getPricePhaseName } from '../../../constants';
import { formatCents, getRegistrationDateRange } from '../../../utilities';

type PhaseProps = {
  phase: string;
  individualVoloPassMemberPrice: number | null;
  individualVoloPassMemberPriceWithFees: number | null;
  startDateStr: string;
  endDateStr: string;
  allow: boolean;
};

type MembershipPricingInfoProps = {
  phasePricing: PhaseProps[];
  showPricesWithFees: boolean;
  iconProps?: Partial<IFontAwesomeIconProps>;
};

const MembershipPricingInfo: FC<MembershipPricingInfoProps> = props => {
  const { phasePricing, showPricesWithFees } = props;
  const { dark } = useTheme();

  const formattedPricing = phasePricing
    .map(
      ({
        endDateStr,
        phase,
        startDateStr,
        individualVoloPassMemberPrice: playerPrice,
        individualVoloPassMemberPriceWithFees: playerPriceWithFees,
      }) => ({
        phaseName: getPricePhaseName(phase),
        registrationDateRange: getRegistrationDateRange({
          start: startDateStr,
          end: endDateStr,
        }),
        price: formatCents(showPricesWithFees ? playerPriceWithFees : playerPrice),
      })
    )
    .filter(({ price }) => Boolean(price)); // Ensure all pricing options are valid

  const ChildComponent = useCallback(
    (triggerProps: any) => {
      return (
        <IconButton
          icon={
            <FontAwesomeIcon icon={faCircleInfo} color={dark ? 'secondary.600' : 'primary.600'} />
          }
          p="0"
          marginLeft="2"
          variant="link"
          {...triggerProps}
          // eslint-disable-next-line react/destructuring-assignment
          {...('iconProps' in props ? props.iconProps : {})}
        />
      );
    },
    [dark, props]
  );

  return (
    <Popover trigger={ChildComponent} useRNModal>
      <Popover.Content maxWidth={600}>
        <Popover.Arrow />
        <Popover.CloseButton />
        <Popover.Header>Membership Pricing</Popover.Header>
        <Popover.Body>
          <Stack space="3">
            <Text color={dark ? 'text.300' : 'text.500'} fontSize={14}>
              Pricing is often dynamic and increases with each phase. Early Bird, Regular, and Last
              Chance prices listed here are special rates for Volo Pass members. Membership pricing
              does not apply to prepaid teams.
            </Text>
            {formattedPricing.map(({ phaseName, registrationDateRange, price }) => (
              <Stack space="1" key={`${phaseName}-info`}>
                <Heading fontSize={14}>
                  {phaseName} Membership Pricing: {price}
                </Heading>
                <Text fontSize={10}>{registrationDateRange}</Text>
              </Stack>
            ))}
          </Stack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

// There's no data loading in this component, but if this throws an error for whatever reason it's
// best just not to render anything than show a broken screen.
const MembershipPricingInfoWrapper = (props: MembershipPricingInfoProps) => (
  <ErrorBoundary fallback={null}>
    <MembershipPricingInfo {...props} />
  </ErrorBoundary>
);

export default MembershipPricingInfoWrapper;
