import React, { type ComponentProps } from 'react';
import { faPersonWalking } from '@fortawesome/pro-light-svg-icons/faPersonWalking';
import { faCircleSmall } from '@fortawesome/pro-regular-svg-icons/faCircleSmall';
import { FontAwesomeIcon, View } from '../../../base-components';

type PersonKickingBallProps = Omit<ComponentProps<typeof FontAwesomeIcon>, 'icon'>;

/**
 * Composite icon forming a person that looks like they are kicking a soccer ball. Accepts all the
 * props that a normal `FontAwesomeIcon` would.
 */
const PersonKickingBall: React.FC<PersonKickingBallProps> = ({ size, ...props }) => {
  const ballSize = '3.5';

  return (
    // The ball is normally positioned below the person walking, but because we are positioning it
    // using transforms, we need to remove the empty space left behind by the ball.
    <View w={size} marginBottom={`-${ballSize}`}>
      <View style={{ transform: [{ rotate: '-10deg' }] }}>
        <FontAwesomeIcon {...{ ...props, size }} icon={faPersonWalking} />
      </View>
      <View style={{ transform: [{ translateY: -15 }, { translateX: 30 }] }}>
        {/* @ts-ignore */}
        <FontAwesomeIcon {...props} icon={faCircleSmall} size={ballSize} />
      </View>
    </View>
  );
};

export default PersonKickingBall;
