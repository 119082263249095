import { type IIconProps } from '@rivallapp/native-base';

// Based off of this https://github.com/GeekyAnts/NativeBase/blob/master/src/theme/components/icon.ts

const baseStyle = ({ colorScheme = 'gray' }: IIconProps) => {
  if (colorScheme === 'primary') {
    return {
      color: 'primary.600',
      _dark: {
        color: 'primary.400',
      },
    };
  }

  if (colorScheme === 'text') {
    return {
      color: 'text.900',
      _dark: {
        color: 'text.50',
      },
    };
  }

  // Default

  return {
    color: `${colorScheme}.500`,
    _dark: {
      color: `${colorScheme}.400`,
    },
  };
};

const sizes = {
  '2xs': 2,
  xs: 3,
  sm: 4,
  md: 5,
  lg: 6,
  xl: 7,
  '2xl': 8,
  '3xl': 9,
  '4xl': 10,
  '5xl': 12,
  '6xl': 14,
};

const defaultProps = { size: 'md' };

export default { baseStyle, sizes, defaultProps };
