import { useState, type FC } from 'react';
import { Calendar, type DateObject } from 'react-multi-date-picker';
import moment from 'moment-timezone';
import { useMessageCenterStore, type DateSelectionType } from '../../store';
import {
  Column,
  FormControl,
  Heading,
  Radio,
  Row,
  Text,
  TextInput,
  VStack,
} from '../../../../base-components';

const DateFilter: FC = () => {
  const { filters } = useMessageCenterStore(store => ({
    filters: store.filters,
  }));

  const { dateSelectionType, startDate = '', endDate = '', selectedDates = [] } = filters;

  const [rangeError, setRangeError] = useState('');

  const onChangeDateRangeStart = (value: string) => {
    const start_date = moment(value, 'M/D/YY');
    const end_date = moment(endDate, 'M/D/YY');
    if (!start_date.isValid()) {
      setRangeError('Please enter a valid date.');
    } else if (start_date.isAfter(end_date)) {
      setRangeError('Start date must be before end date.');
    } else {
      setRangeError('');
    }
    useMessageCenterStore.setState(partial => ({
      filters: {
        ...partial.filters,
        startDate: value,
      },
    }));
  };

  const onChangeDateRangeEnd = (value: string) => {
    const start_date = moment(startDate, 'M/D/YY');
    const end_date = moment(value, 'M/D/YY');
    if (!end_date.isValid()) {
      setRangeError('Please enter a valid date.');
    } else if (end_date.isBefore(start_date)) {
      setRangeError('End date must be after start date.');
    } else {
      setRangeError('');
    }
    useMessageCenterStore.setState(partial => ({
      filters: {
        ...partial.filters,
        endDate: value,
      },
    }));
  };

  const onChangeCalendarDates = (dates: DateObject[]) => {
    useMessageCenterStore.setState(partial => ({
      filters: {
        ...partial.filters,
        selectedDates: dates.map(date => date.toDate()),
      },
    }));
  };

  return (
    <VStack bg="white.600" rounded="sm" p="4">
      {/* This is what it is in the spec, even though it's really a date filter */}
      <Heading fontSize="md" color="gray.700">
        Time Filter
      </Heading>
      <Radio.Group
        name="rental_date_type"
        accessibilityLabel="Rental date picker type"
        value={dateSelectionType}
        onChange={value =>
          useMessageCenterStore.setState(partial => ({
            filters: {
              ...partial.filters,
              dateSelectionType: value as DateSelectionType,
            },
          }))
        }
      >
        <Row space="4" marginBottom="4" marginTop="2">
          <Radio value="range">Range</Radio>
          <Radio value="manual">Calendar</Radio>
        </Row>
      </Radio.Group>
      {dateSelectionType === 'range' ? (
        <Column>
          <Row justifyContent="space-between">
            <FormControl maxW="1/3" accessibilityLabel="date range start" isInvalid={!!rangeError}>
              <TextInput
                placeholder="1/11/24"
                bg="#fff"
                onChangeText={onChangeDateRangeStart}
                value={startDate}
              />
            </FormControl>
            <Text>to</Text>
            <FormControl maxW="1/3" accessibilityLabel="date range end" isInvalid={!!rangeError}>
              <TextInput
                placeholder="3/11/24"
                bg="#fff"
                onChangeText={onChangeDateRangeEnd}
                value={endDate}
              />
            </FormControl>
          </Row>
          <Text color="red.400">{rangeError}</Text>
        </Column>
      ) : (
        <Calendar
          multiple
          sort
          numberOfMonths={2}
          onChange={onChangeCalendarDates}
          value={selectedDates}
        />
      )}
    </VStack>
  );
};

export default DateFilter;
