import React from 'react';
import { Path, Polygon, G, Rect } from 'react-native-svg';

import { Icon } from '../../../base-components';
import { useTheme, type LogoColorVariant } from '../../../theme';
import { type VoloLogoProps, DEFAULT_COLOR } from '../types';

const VoloFitnessLogoBarbell: React.FC<VoloLogoProps> = ({ colorScheme, ...props }) => {
  const { colors } = useTheme();
  const logoColor = colorScheme
    ? (colors.logoColors[colorScheme as LogoColorVariant] ?? DEFAULT_COLOR)
    : DEFAULT_COLOR;
  return (
    <Icon {...props} color={logoColor} viewBox="0 0 240 257">
      <Polygon points="170.36,57.01 158.29,82.71 186.48,82.71 198.55,57.01 " />
      <Polygon points="214.08,23.95 185.89,23.95 173.82,49.65 202.01,49.65 " />
      <Path
        d="M133.14,136.26v-20.49v-25.7h-25.7H81.68c0,14.19,11.51,25.7,25.7,25.7h0.06v75.21h28.2l47.39-100.91h-28.2
	L133.14,136.26z"
      />
      <Path d="M133.14,49.65c0-14.19-12.75-25.7-26.95-25.7H17.97c0,14.19,11.51,25.7,25.7,25.7H133.14z" />
      <Path d="M133.14,57.01H49.2c0,14.19,11.51,25.7,25.7,25.7h58.24V57.01z" />
      <G>
        <Path
          d="M65.34,143.96h-7.95c-3.44,0-6.23,2.79-6.23,6.23v80.58c0,3.44,2.79,6.23,6.23,6.23h7.95
		c3.44,0,6.23-2.79,6.23-6.23v-80.58C71.36,146.75,68.56,143.96,65.34,143.96z"
        />
        <Rect x="76.51" y="180.27" width="101.21" height="20.2" />
        <Path
          d="M39.55,161.58H31.6c-3.44,0-6.23,2.79-6.23,6.23v45.34c0,3.44,2.79,6.23,6.23,6.23h7.95
		c3.44,0,6.23-2.79,6.23-6.23v-45.34C45.57,164.37,42.99,161.58,39.55,161.58z"
        />
        <Path
          d="M197.49,143.96h-7.95c-3.44,0-6.23,2.79-6.23,6.23v80.58c0,3.44,2.79,6.23,6.23,6.23h7.95
		c3.44,0,6.23-2.79,6.23-6.23v-80.58C203.72,146.75,200.93,143.96,197.49,143.96z"
        />
        <Path
          d="M223.28,161.58h-7.95c-3.44,0-6.23,2.79-6.23,6.23v45.34c0,3.44,2.79,6.23,6.23,6.23h7.95
		c3.44,0,6.23-2.79,6.23-6.23v-45.34C229.51,164.37,226.72,161.58,223.28,161.58z"
        />
      </G>
    </Icon>
  );
};

export default VoloFitnessLogoBarbell;
