import { TextInput, type CTextInputProps } from '../../../../../base-components';
import { useTheme } from '../../../../../theme';

/**
 * RentalTextInput
 * - Custom TextInput component for the Rentals Registration flow with preset border colors
 */
const RentalTextInput = (props: CTextInputProps) => {
  const { colors } = useTheme();
  return (
    <TextInput
      variant="unstyled"
      borderWidth={1.5}
      borderColor={colors.border}
      _hover={{
        _light: {
          borderColor: 'rentalBlue.900',
        },
        _dark: {
          borderColor: 'rentalBlue.600',
        },
      }}
      _focus={{
        _light: {
          borderColor: 'rentalBlue.900',
        },
        _dark: {
          borderColor: 'rentalBlue.600',
        },
      }}
      {...props}
    />
  );
};

export default RentalTextInput;
