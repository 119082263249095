import React from 'react';
import { FlatList, type FlatListProps } from 'react-native';
import { Row, Skeleton } from '../../../base-components';
import { useTheme } from '../../../theme';

type Props = Omit<FlatListProps<any>, 'renderItem' | 'data'> & {
  numberOfSkeletonRows?: number;
};

const LeagueTeamRosterLoading: React.FC<Props> = ({ numberOfSkeletonRows = 6, ...props }) => {
  const { colors } = useTheme();

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={Array.from({ length: numberOfSkeletonRows })}
      keyExtractor={(__, index) => index.toString()}
      renderItem={() => (
        <Row
          h={60}
          px={2}
          my={1}
          borderBottomWidth={1}
          alignItems="center"
          justifyContent="space-between"
          borderBottomColor={colors.border}
        >
          <Skeleton.Text lines={2} w={130} />
          <Skeleton size="8" rounded="full" />
        </Row>
      )}
      {...props}
    />
  );
};

export default LeagueTeamRosterLoading;
