import { type FC } from 'react';
import { ApolloError } from '@apollo/client';

import { useTheme } from '../../../theme';
import { View, Image, Text } from '../../../base-components';
import { Banner } from '../../../custom-components';
import generateModule from '../modules/generateModule';
import type {
  RentalPricingModule,
  DisplayNameModule,
  NeighborhoodVenueModule,
  DetailModule,
} from './types';
import { DiscoverCardWrapper, DiscoverLoading, DiscoverError } from '../components';
import useMobileSizing from '../helpers/useMobileSizing';

type RentalCardProps = {
  /**
   * id of either the rental (sportView) or venue (venueView)
   */
  id: string;
  rentalActivity: string;
  /**
   * url for the header image
   */
  imageUrl: string;
  /**
   * @param id rental or venue id
   * @returns will bring user to venue description page
   * WIP: Will be updated when we know our Discover queries
   */
  onCardPress: (id: string, rentalActivity: string) => void;
  /**
   * Displays $lowPrice-highPrice/hr
   */
  pricingModule: RentalPricingModule;
  /**
   * sport-view display name or venue-view display name
   */
  displayNameModule: DisplayNameModule;
  /**
   * combination of NeighborhoodModule and VenueModule
   */
  neighborhoodVenueModule: NeighborhoodVenueModule;
  /**
   * Courts & Capacity (sport-view) || avaliable sport list (venue-view)
   */
  detailModule: DetailModule;
  /** Only used in sportView */
  bannerMessage?: string;

  loading?: boolean;
  error?: ApolloError | string;
};

const RentalCard: FC<RentalCardProps> = ({
  id,
  rentalActivity,
  imageUrl,
  onCardPress,
  pricingModule,
  displayNameModule,
  neighborhoodVenueModule,
  detailModule,
  bannerMessage,
  loading = false,
  error = undefined,
}) => {
  const { colors } = useTheme();
  const mobileSizing = useMobileSizing();

  if (loading) {
    return <DiscoverLoading height={mobileSizing ? 130 : 300} />;
  }

  if (error) {
    return <DiscoverError error={error} height={mobileSizing ? 130 : 300} />;
  }

  /* module generation */
  const pricing = generateModule(pricingModule);
  const displayName = generateModule(displayNameModule);
  const neighborhoodVenue = generateModule(neighborhoodVenueModule);
  const details = generateModule(detailModule);

  return (
    <DiscoverCardWrapper
      type="RENTAL"
      onCardPress={() => onCardPress(id, rentalActivity)}
      borderTopLeftRadius={10}
      borderTopRightRadius={10}
    >
      <View>
        {imageUrl ? (
          <Image
            resizeMode="cover"
            source={{ uri: imageUrl }}
            alt="Rental Cover Image"
            height={mobileSizing ? 130 : 281}
            borderRadius={10}
          />
        ) : (
          /**
           * This is a fallback component that should never appear on production.
           * It's up to the city teams to ensure that all venues with rentals have images.
           */
          <View justifyContent="center" height={mobileSizing ? 130 : 281} borderRadius={10}>
            <Text alignSelf="center">This venue has no image!</Text>
          </View>
        )}
        {bannerMessage ? (
          // View wrapper to overwrite mobile defaults
          <View
            backgroundColor={colors.background}
            position="absolute"
            top={1}
            right={1}
            zIndex={999}
            borderRadius={5}
            paddingX={1}
            shadow={5}
          >
            <Banner
              background={colors.background.toString()}
              bold
              fontSize={12}
              paddingX={0}
              paddingY={0}
            >
              {bannerMessage}
            </Banner>
          </View>
        ) : null}
        <View
          backgroundColor={colors.background}
          position="absolute"
          bottom={1}
          left={1}
          zIndex={999}
          borderRadius={5}
          paddingX={1}
          shadow={5}
        >
          {pricing}
        </View>
      </View>

      <View paddingX={2} paddingTop={1} paddingBottom={2}>
        {displayName}
        {neighborhoodVenue}
        {details}
      </View>
    </DiscoverCardWrapper>
  );
};

export default RentalCard;
