import { type TypedDocumentNode, gql } from '@apollo/client';
import { RentalActivityNamesEnum } from '../../../../constants';

type GetRentalDetailsByVenueData = {
  venue: {
    _id: string;
    image: {
      url: string;
    };
    location: {
      lat: number;
      lng: number;
    };
    formatted_address?: string;
    place_id: string;
    neighborhood: string;
    shorthandName: string;
    availableActivities: typeof RentalActivityNamesEnum;
    lowest_overall_rental_price: number;
    highest_overall_rental_price: number;
  };
};

type GetRentalDetailsByVenueInput = {
  id: string;
};

const GET_RENTAL_DETAILS_BY_VENUE: TypedDocumentNode<
  GetRentalDetailsByVenueData,
  GetRentalDetailsByVenueInput
> = gql`
  query venue($id: ID!) {
    venue(_id: $id) {
      _id
      image {
        url
      }
      location {
        lat
        lng
      }
      formatted_address
      place_id
      neighborhood
      shorthandName
      availableActivities
      lowest_overall_rental_price
      highest_overall_rental_price
    }
  }
`;

export default GET_RENTAL_DETAILS_BY_VENUE;
