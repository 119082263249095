import { useMemo, type ComponentProps } from 'react';
import { Linking, useWindowDimensions } from 'react-native';
import RNRenderHTML, {
  type TRenderEngineConfig,
  type RenderHTMLProps,
} from 'react-native-render-html';
import { useTheme, type Color } from '../../theme';

const generateTagStyles = (color: Color | string) => ({ color });

type RenderersProp = ComponentProps<typeof RNRenderHTML>['renderersProps'];

const aRenderProp: RenderersProp = {
  a: {
    onPress: async (evt, href) => {
      const supported = await Linking.canOpenURL(href);
      if (supported) await Linking.openURL(href);
    },
  },
};

/**
 * Wrapper around `react-native-render-html` to provide a consistent and easy component across App when needed
 * - uses `useTheme` to generate the styles for the tags
 * - uses `aRenderProp` to handle the `onPress` for the `a` tag
 */
const RenderHTMLMobile = (props: Pick<RenderHTMLProps, 'source'>) => {
  const { colors } = useTheme();
  const { width } = useWindowDimensions();

  /*
    Original style of body + p tag pulled straight from mobile4
    - would think we would need to update colors for all other tags as well
    - if you see one missing, add JIC
  */
  const tagsStyles: TRenderEngineConfig['tagsStyles'] = useMemo(
    () => ({
      body: { fontSize: 14, backgroundColor: 'transparent' },
      h1: generateTagStyles(colors.text),
      h2: generateTagStyles(colors.text),
      h3: generateTagStyles(colors.text),
      h4: generateTagStyles(colors.text),
      h5: generateTagStyles(colors.text),
      h6: generateTagStyles(colors.text),
      p: generateTagStyles(colors.text),
      blockquote: generateTagStyles(colors.text),
      strong: generateTagStyles(colors.text),
      em: generateTagStyles(colors.text),
      u: generateTagStyles(colors.text),
      s: generateTagStyles(colors.text),
      code: generateTagStyles(colors.text),
      li: generateTagStyles(colors.text),
      ol: generateTagStyles(colors.text),
      ul: generateTagStyles(colors.text),
      a: generateTagStyles(colors.text),
      abbr: generateTagStyles(colors.text),
      cite: generateTagStyles(colors.text),
      q: generateTagStyles(colors.text),
      span: generateTagStyles(colors.text),
      div: generateTagStyles(colors.text),
    }),
    [colors.text]
  );

  return (
    <RNRenderHTML
      {...props}
      contentWidth={width}
      tagsStyles={tagsStyles}
      renderersProps={aRenderProp}
      enableExperimentalMarginCollapsing
      enableExperimentalBRCollapsing
      enableExperimentalGhostLinesPrevention
    />
  );
};

export default RenderHTMLMobile;
