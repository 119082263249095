import React from 'react';
import { Text } from '../../../base-components';
import styles from '../styles';

interface DiscoverClearProps {
  onClear: () => void;
  title?: string;
}

const DiscoverClear = ({ onClear, title = 'clear' }: DiscoverClearProps) => {
  return (
    <Text isLink style={styles.link} onPress={onClear}>
      {title}
    </Text>
  );
};

export default DiscoverClear;
