import { type ListRenderItem } from 'react-native';
import { Box, FlatList } from '@rivallapp/native-base';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import React, { Suspense, useCallback } from 'react';
import PLAYER_STRIKE_HISTORY, {
  PlayerStrikeInvalidationType,
  type PlayerStrike,
} from '../gql/PLAYER_STRIKE_HISTORY';
import { Text, Row, Divider, Link, Checkbox, Spinner, Tooltip } from '../../../base-components';
import { WholeDollarFormatter } from '../../../utilities/formatters';
import EDIT_PLAYER_STRIKE from '../gql/EDIT_PLAYER_STRIKE';
import { ADMIN_PLAYER_STRIKE_COUNT, PLAYER_STRIKE_COUNT } from '../gql/PLAYER_STRIKE_COUNT';

const TimeFormatter = new Intl.DateTimeFormat('en-US', {
  timeStyle: 'short',
});

const DateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
});

type StrikeHistoryProps = {
  userId: string;
};

const canStrikeBeReinstated = (strike: PlayerStrike) => {
  // If the strike was automatically reset, admins cannot bring it back
  if (strike.invalidated && strike.invalidation_type === PlayerStrikeInvalidationType.AUTOMATIC) {
    return false;
  }
  return true;
};

const StrikeHistory: React.FC<StrikeHistoryProps> = ({ userId }) => {
  const [editStrike, { loading: editStrikeLoading }] = useMutation(EDIT_PLAYER_STRIKE, {
    refetchQueries: [PLAYER_STRIKE_COUNT, ADMIN_PLAYER_STRIKE_COUNT],
  });
  const { data } = useSuspenseQuery(PLAYER_STRIKE_HISTORY, {
    variables: {
      id: userId,
    },
  });

  const handleStrikeChange = useCallback(
    async (playerStrike: PlayerStrike, invalidated: boolean) => {
      if (!canStrikeBeReinstated(playerStrike)) {
        return;
      }

      await editStrike({
        variables: {
          input: {
            _id: playerStrike._id,
            invalidated,
          },
        },
      });
    },
    [editStrike]
  );

  const renderItem = useCallback<ListRenderItem<PlayerStrike>>(
    ({ item: playerStrike }) => (
      <Row py="4">
        <Text strikeThrough={playerStrike.invalidated} flex={1}>
          {DateFormatter.format(new Date(playerStrike.createdAt))}
        </Text>
        <Text strikeThrough={playerStrike.invalidated} flex={1}>
          {TimeFormatter.format(new Date(playerStrike.createdAt))}
        </Text>
        <Text strikeThrough={playerStrike.invalidated} flex={4}>
          {playerStrike.associated_game ? (
            <Link
              _text={{ strikeThrough: playerStrike.invalidated }}
              isUnderlined={false}
              _hover={{ isUnderlined: true }}
              color="lightBlue.600"
              href={`/rfo/league/${playerStrike.associated_game.leagueId}/view`}
            >
              (P) {playerStrike.associated_game.name}
            </Link>
          ) : (
            playerStrike.name
          )}
        </Text>
        <Text strikeThrough={playerStrike.invalidated} flex={1} textAlign="center">
          {WholeDollarFormatter.format(playerStrike.fee_applied / 100)}
        </Text>
        <Row flex={1} justifyContent="center">
          <Tooltip
            zIndex={999}
            openDelay={0}
            label={
              canStrikeBeReinstated(playerStrike)
                ? ''
                : 'This strike was reset automatically. Once a strike is invalidated automatically, it cannot be reinstated.'
            }
          >
            <Checkbox
              zIndex={999}
              colorScheme="lightBlue"
              value="invalidated"
              isChecked={playerStrike.invalidated}
              isDisabled={!canStrikeBeReinstated(playerStrike)}
              onChange={invalidated => handleStrikeChange(playerStrike, invalidated)}
            />
          </Tooltip>
        </Row>
      </Row>
    ),
    [handleStrikeChange]
  );

  return (
    <FlatList
      minW={580}
      overflow="scroll"
      data={data.user.player_strikes.history}
      keyExtractor={playerStrike => playerStrike._id}
      ItemSeparatorComponent={Divider}
      stickyHeaderIndices={[0]} // Make the header scroll along with the user
      renderItem={renderItem}
      zIndex={-1}
      ListHeaderComponent={
        <Box bg="muted.50" marginBottom="4">
          <Text fontSize="sm" background="muted.50">
            Note: Marking charge as invalid does{' '}
            <Text fontWeight="bold" fontSize="sm">
              not
            </Text>{' '}
            refund fees charged. Refunds must be done via Stripe.
          </Text>
          <Row minH="10">
            <Text bold flex={1}>
              Date
            </Text>
            <Text bold flex={1}>
              Time
            </Text>
            <Text bold flex={4}>
              Program
            </Text>
            <Text bold flex={1} textAlign="center">
              Fees Applied
            </Text>
            <Text bold flex={1} textAlign="center">
              Invalid? {editStrikeLoading && <Spinner size="sm" />}
            </Text>
          </Row>
          <Divider />
        </Box>
      }
    />
  );
};

const WrappedStrikeHistory = (props: StrikeHistoryProps) => (
  <Suspense fallback={<Spinner />}>
    <StrikeHistory {...props} />
  </Suspense>
);

export default WrappedStrikeHistory;
