// ============================================================
// -------------- SELECTORS --------------
// ============================================================

import {
  RentalRegistrationSteps,
  type RentalDateSelection,
  type RentalCourtSelection,
  type RentalGroupSettings,
  type RentalGroupSettingsWithPassword,
  type RentalGroupSettingsWithoutPassword,
} from '../RegistrationFlowSections';
import { type RentalBookingStore } from './rentalBookingStore.types';

export interface BaseStepSelector {
  /**
   * if the entire current step is valid
   */
  isStepValid: boolean;
  rentalId: string;
}

export interface DateStepSelector extends RentalDateSelection, BaseStepSelector {}

/**
 * selector function to get the date selection values from the current booking
 */
export const dateStepSelector = (state: RentalBookingStore): DateStepSelector => {
  const { currentBooking } = state;
  if (!currentBooking) {
    return {
      rentalId: '',
      startDate: null,
      timeSlots: [],
      isStepValid: false,
    };
  }

  const dateSelectionValues = currentBooking.steps.dateSelection;
  const { rentalId } = currentBooking;

  return {
    rentalId,
    startDate: dateSelectionValues.startDate,
    timeSlots: dateSelectionValues.timeSlots,
    isStepValid: RentalRegistrationSteps.dateSelection.validation(dateSelectionValues),
  };
};

export interface CourtStepSelector extends RentalCourtSelection, BaseStepSelector {}

export const courtStepSelector = (state: RentalBookingStore): CourtStepSelector => {
  const { currentBooking } = state;
  if (!currentBooking) {
    return {
      rentalId: '',
      selectedCourts: [],
      isStepValid: false,
    };
  }

  const courtSelectionValues = currentBooking.steps.courtsSelection;
  const { rentalId } = currentBooking;

  return {
    rentalId,
    selectedCourts: courtSelectionValues.selectedCourts,
    isStepValid: RentalRegistrationSteps.courtsSelection.validation(courtSelectionValues),
  };
};

export type GroupStepSelector = RentalGroupSettings & BaseStepSelector;

export const groupStepSelector = (state: RentalBookingStore): GroupStepSelector => {
  const { currentBooking } = state;
  if (!currentBooking) {
    return {
      rentalId: '',
      groupSize: 0,
      groupPasswordEnabled: false,
      groupPassword: undefined,
      isStepValid: false,
    };
  }

  const groupSelectionValues = currentBooking.steps.groupSettings;
  const { rentalId } = currentBooking;

  if (groupSelectionValues.groupPasswordEnabled) {
    return {
      rentalId,
      groupSize: groupSelectionValues.groupSize,
      groupPasswordEnabled: groupSelectionValues.groupPasswordEnabled,
      groupPassword: groupSelectionValues.groupPassword,
      isStepValid: RentalRegistrationSteps.groupSettings.validation(groupSelectionValues),
    } as RentalGroupSettingsWithPassword & BaseStepSelector;
  }

  return {
    rentalId,
    groupSize: groupSelectionValues.groupSize,
    groupPasswordEnabled: groupSelectionValues.groupPasswordEnabled,
    groupPassword: groupSelectionValues.groupPassword,
    isStepValid: RentalRegistrationSteps.groupSettings.validation(groupSelectionValues),
  } as RentalGroupSettingsWithoutPassword & BaseStepSelector;
};
