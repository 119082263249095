import { type FC } from 'react';
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock';
import moment from 'moment-timezone';

import { Text, View, FontAwesomeIcon } from '../../../../base-components';
import type { TimeModule } from '../../ProgramCard/types';
import useMobileSizing from '../../helpers/useMobileSizing';

const StartTimeSection: FC<Omit<TimeModule, 'type'>> = ({ startTimeDate, endTimeDate }) => {
  const mobileSizing = useMobileSizing();

  const formatTime = () => {
    const startTime =
      typeof startTimeDate === 'object' ? moment(startTimeDate).format('h:mma') : startTimeDate;
    if (endTimeDate) {
      if (typeof endTimeDate === 'object')
        return `${startTime}-${moment(endTimeDate).format('h:mma')}`;
      return `${startTime}-${endTimeDate}`;
    }
    return startTime;
  };
  return (
    <View
      flexDirection="row"
      flex={1}
      alignItems="center"
      maxWidth={mobileSizing ? 170 : 250}
      minHeight={mobileSizing ? 18 : 26}
    >
      {!mobileSizing ? <FontAwesomeIcon icon={faAlarmClock} size={4} /> : null}
      <View overflow="hidden">
        <Text
          marginLeft={2.5}
          numberOfLines={1}
          fontSize={14}
          letterSpacing={-0.5}
          maxWidth={mobileSizing ? 160 : 220}
        >
          {formatTime()}
        </Text>
      </View>
    </View>
  );
};

export default StartTimeSection;
