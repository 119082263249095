import { Linking } from 'react-native';
import { ModalAlert } from '../custom-components';

const openLink = (url: string) => {
  Linking.canOpenURL(url).then(supported => {
    if (supported) {
      Linking.openURL(url);
    } else {
      ModalAlert.alert({
        title: 'Error',
        message: 'This link is not supported on your device.',
      });
    }
  });
};

export default openLink;
