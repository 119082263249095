import { useMemo } from 'react';
import {
  findNextStep,
  findPreviousStep,
  findRentalRouteIndex,
  isFirstStepInRentalFlow,
  isLastStepInRentalFlow,
} from '../../RegistrationFlowSections';
import useRentalBookingStore from '../useRentalBookingStore';
import useRentalBookingCurrentStepValidation from './useRentalBookingCurrentStepValidation';

const { actions } = useRentalBookingStore.getState();

/**
 * Hook that provides stable access to the current booking navigation state metadata & methods
 */
const useCurrentBookingNavigation = () => {
  const isStepValid = useRentalBookingCurrentStepValidation();
  const { currentStep, isEditMode } = useRentalBookingStore(state => ({
    currentStep: state.currentBooking?.currentStep,
    isEditMode: state.currentBooking?.isEditMode,
  }));

  return useMemo(
    () => ({
      currentState: {
        isEditMode,
        currentStep,
        index: currentStep ? findRentalRouteIndex(currentStep) : -1,

        /*
          assert the currentStep as these methods are typed for needing the key to be passed in
          - in this case, if the current booking is undefined, the return signature of these methods would return undefined/false and be typed as such in the return signature of the hook
          */
        isFirstStep: isFirstStepInRentalFlow(currentStep!),
        isLastStep: isLastStepInRentalFlow(currentStep!),
        previousRouteInfo: findPreviousStep(currentStep!),
        nextRouteInfo: findNextStep(currentStep!),
        isStepValid,
      },
      navigateNextStep: actions.navigateNextStep,
      navigatePreviousStep: actions.navigatePreviousStep,
      updateCurrentStepByRouteName: actions.updateCurrentStepByRouteName,
    }),
    [isStepValid, currentStep, isEditMode]
  );
};

export default useCurrentBookingNavigation;
