import { getMainDefinition } from '@apollo/client/utilities/index.js';
import { HttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions/index.js';
import { createClient } from 'graphql-ws';
/**
 * Removes the protocol, www, and trailing slash from a URL string.
 *
 * @example
 * cleanUrl('https://www.example.com/') // 'example.com'
 */
const cleanUrl = (url) => {
    let newUrl = url.replace(/^(https?:\/\/|wss?:\/\/)/, '');
    if (newUrl.startsWith('www.')) {
        newUrl = newUrl.slice(4);
    }
    if (newUrl.endsWith('/')) {
        newUrl = newUrl.slice(0, -1);
    }
    return newUrl;
};
const createWsLink = (graphqlURL) => new GraphQLWsLink(createClient({
    url: `wss://${cleanUrl(graphqlURL)}/hapi/v1/websockets`,
}));
const createHttpLink = (graphqlURL) => new HttpLink({ uri: `https://${cleanUrl(graphqlURL)}/hapi/v1/graphql` });
const createSplitWsHttpLink = ({ graphqlURL }) => split(({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}, createWsLink(graphqlURL), createHttpLink(graphqlURL));
export default createSplitWsHttpLink;
