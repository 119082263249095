import type { Day } from '../types';
import DateSelectorItem, { type DateSelectorItemProps } from '../../DateSelectorItem';

interface DaySelectorItemProps extends Day, Omit<DateSelectorItemProps, 'onPress'> {
  isSelected: boolean;
  /**
   * callback for the `SelectorItem` press event
   * - returns the `date` of the day that was pressed
   */
  onPress: (date: Day['date']) => void;
}

const DaySelectorItem = ({
  dayOfMonth,
  dayOfWeek,
  date,
  isSelected,
  onPress,
  ...rest
}: DaySelectorItemProps) => {
  return (
    <DateSelectorItem isSelected={isSelected} onPress={() => onPress(date)} {...rest}>
      <DateSelectorItem.Text isSelected={isSelected} fontSize={14}>
        {dayOfWeek.toUpperCase()}
      </DateSelectorItem.Text>
      <DateSelectorItem.Text isSelected={isSelected} fontWeight="semibold">
        {dayOfMonth}
      </DateSelectorItem.Text>
    </DateSelectorItem>
  );
};

export default DaySelectorItem;
