import { gql, type TypedDocumentNode } from '@apollo/client';

type DropinGameInfoInput = {
  gameId: string;
};

export type DropinGameInfoResult = {
  game: {
    _id: string;
    start_time: Date;
    end_time: Date;
  };
};

export const DROPIN_GAME_INFO: TypedDocumentNode<DropinGameInfoResult, DropinGameInfoInput> = gql`
  query dropinGameInfo($gameId: ID!) {
    game(_id: $gameId) {
      _id
      start_time
      end_time
    }
  }
`;
