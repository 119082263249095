import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface PulseCheckProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  shiftRating: number;
  pulseCheckComments: string;
}

const PulseCheck: React.FC<PulseCheckProps> = ({ shiftRating, pulseCheckComments, ...props }) => {
  return (
    <CollapseForm title="Pulse Check" view="read" {...props}>
      <CollapseForm.StarRating
        isRequired
        label="How was your experience working today?"
        defaultValue={shiftRating}
      />
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Comments/Notes:" response={pulseCheckComments} />
      </Box>
    </CollapseForm>
  );
};

export default PulseCheck;
