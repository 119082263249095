import { useQuery } from '@apollo/client';
import { Linking, ActivityIndicator } from 'react-native';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { Button, FontAwesomeIcon, Image, Text, View } from '../../base-components';
import GET_USER_DETAILS from '../GameRoster/graphql/GET_USER_DETAILS';
import { ErrorMessageEnum } from '../../constants/enums';
import styles from './styles';

export interface PlayerContactInfoProps {
  userId: string;
  teamId?: string | undefined;
  groupId?: string | undefined;
  reservationId?: string | undefined;
  isDropIn?: boolean;
}

const PlayerContactInfo = ({
  userId,
  teamId,
  groupId,
  reservationId,
  isDropIn = false,
}: PlayerContactInfoProps) => {
  const { data, loading, error } = useQuery(GET_USER_DETAILS, {
    skip: !userId,
    variables: {
      input: {
        userId,
        ...(teamId ? { teamId } : {}),
        ...(groupId ? { groupId } : {}),
        ...(isDropIn ? { isDropIn } : {}),
        ...(reservationId ? { reservationId } : {}),
      },
    },
  });

  if (error) {
    return (
      <View>
        <Text>{ErrorMessageEnum.PLAYER_CONTACT_INFO}</Text>
      </View>
    );
  }
  if (loading) return <ActivityIndicator />;

  const user = data?.getUserDetails;

  const {
    email = '',
    phone = '',
    picture = '',
    firstName = '',
    lastName = '',
    displayNameTeams = '',
  } = user || {};

  const firstInitial = firstName?.[0]?.toUpperCase() || '';
  const lastInitial = lastName?.[0]?.toUpperCase() || '';

  const userInitials = `${firstInitial}${lastInitial}`;

  const handleEmail = () => {
    if (email) Linking.openURL(`mailto:${email}`);
  };
  const handlePhone = () => {
    if (phone) Linking.openURL(`tel:${phone}`);
  };

  const name = displayNameTeams || `${firstName ?? ''} ${lastName[0] ?? ''}`;

  return (
    <View style={styles.container}>
      <View style={styles.rosterPictureContainer}>
        {picture ? (
          <Image src={picture} style={styles.rosterPicture} alt={`${name} profile picture`} />
        ) : (
          <Text style={styles.userInitials}>{userInitials}</Text>
        )}
      </View>
      <View style={styles.content} flex={1}>
        <Text style={styles.name}>{name}</Text>
        <View style={styles.buttons}>
          <Button
            variant="outline"
            isDisabled={!email}
            onPress={handleEmail}
            style={styles.button}
            leftIcon={<FontAwesomeIcon icon={faEnvelope} size={25} mr={1} />}
          >
            <Text style={styles.buttonText}>Email</Text>
          </Button>
          <Button
            variant="outline"
            isDisabled={!phone}
            onPress={handlePhone}
            style={styles.button}
            leftIcon={<FontAwesomeIcon icon={faPhone} size={25} mr={1} />}
          >
            <Text style={styles.buttonText}>Call</Text>
          </Button>
        </View>
        {!email || !phone ? (
          <Text type="caption" style={styles.hiddenText}>
            This user has elected to hide some or all of their contact information.
          </Text>
        ) : null}
      </View>
    </View>
  );
};

export default PlayerContactInfo;
