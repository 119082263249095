import { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { useActionStates } from '../../../hooks';
import openNativeSettingsApp from '../openNativeSettingsApp';
import {
  GET_USER_NOTIFICATION_PREFERENCES,
  UPDATE_NOTIFICATIONS_PREFERENCES,
  UPDATE_TEAM_NOTIFICATION_PREFERENCES,
} from '../graphql/ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import { Alert, Heading, Skeleton, Switch, Text, View } from '../../../base-components';
import { ErrorMessageEnum } from '../../../constants/enums';
import styles from '../styles';

// ** Note to future devs: we're using state to prevent deprecated fields from overwriting new preferences
// eg: if notifications.recommendations.email is set to true, emailPreference can never be false
// const emailPreference = currentUser?.notificationPreferences?.email || currentUser?.notifications?.recommendations?.email || false;

const isWeb = Platform.OS === 'web';

const Notifications = ({ pushEnabled }: { pushEnabled?: boolean }) => {
  const [marketingNotificationPreferences, setMarketingNotificationPreferences] = useState({
    push: pushEnabled || false,
    email: false,
    sms: false,
  });
  const [teamNotificationPreferences, setTeamNotificationPreferences] = useState({
    push: pushEnabled || false,
    email: false,
    sms: false,
  });

  const [showEmailAlert, setShowEmailAlert] = useState(true);

  // sync with device settings
  useEffect(() => {
    setMarketingNotificationPreferences(previous => ({ ...previous, push: !!pushEnabled }));
    setTeamNotificationPreferences(previous => ({ ...previous, push: !!pushEnabled }));
  }, [pushEnabled]);

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({ withAlerts: true });

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER_NOTIFICATION_PREFERENCES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ currentUser }) => {
      setMarketingNotificationPreferences({
        email: currentUser.notificationPreferences.marketing.email ?? false,
        push: currentUser.notificationPreferences.marketing.push ?? false,
        sms: currentUser.notificationPreferences.marketing.sms ?? false,
      });
      setTeamNotificationPreferences({
        email: currentUser.notificationPreferences.team.email ?? false,
        push: currentUser.notificationPreferences.team.push ?? false,
        sms: currentUser.notificationPreferences.team.sms ?? false,
      });
    },
  });

  const { currentUser } = userData || {};

  const [updateNotificationPreferences, { loading }] = useMutation(
    UPDATE_NOTIFICATIONS_PREFERENCES
  );

  const [updateTeamNotificationPreferences, { loading: teamLoading }] = useMutation(
    UPDATE_TEAM_NOTIFICATION_PREFERENCES
  );

  const updateUserNotificationPreferences = async (update: any) => {
    try {
      await updateNotificationPreferences({
        variables: {
          input: {
            _id: currentUser!._id,
            notificationPreferences: {
              // update all fields for data consistency
              push: marketingNotificationPreferences.push,
              email: marketingNotificationPreferences.email,
              sms: marketingNotificationPreferences.sms,
              ...update,
            },
          },
        },
      });
      setSuccess('Notification preferences updated!');
    } catch (error) {
      setError(ErrorMessageEnum.UPDATE_FAILED);
      console.error(error);
    }
  };

  const updateUserTeamNotificationPreferences = async (update: any) => {
    try {
      await updateTeamNotificationPreferences({
        variables: {
          input: {
            _id: currentUser!._id,
            notificationPreferences: {
              // update all fields for data consistency
              push: marketingNotificationPreferences.push,
              email: marketingNotificationPreferences.email,
              sms: marketingNotificationPreferences.sms,
              ...update,
            },
          },
        },
      });
    } catch (error) {
      setError(ErrorMessageEnum.UPDATE_FAILED);
      console.error(error);
    }
  };

  const handleMarketingToggle = (update: any) => {
    setMarketingNotificationPreferences({ ...marketingNotificationPreferences, ...update });
    updateUserNotificationPreferences(update);
  };

  // only update push notifications on mobile devices
  const handlePushToggle = () => openNativeSettingsApp();

  const handleMarketingEmailToggle = () =>
    handleMarketingToggle({ email: !marketingNotificationPreferences.email });
  const handleMarketingSmsToggle = () =>
    handleMarketingToggle({ sms: !marketingNotificationPreferences.sms });

  const handleTeamToggle = (update: any) => {
    setTeamNotificationPreferences({ ...teamNotificationPreferences, ...update });
    updateUserTeamNotificationPreferences(update);
  };

  const handleTeamEmailToggle = () =>
    handleTeamToggle({ email: !teamNotificationPreferences.email });

  if (userError) {
    setError(ErrorMessageEnum.USER_INFO_LOAD_FAILED);
    console.error(userError);
  }

  if (userLoading)
    return (
      <View style={styles.skeletonContainer}>
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
      </View>
    );

  return (
    <View>
      <Alert
        message="NOTE: You cannot unsubscribe from program emails from Volo as they may contain important details such as weather updates, league information, etc."
        showAlert={showEmailAlert}
        status="warning"
        setShowAlert={setShowEmailAlert}
        autoDismiss={false}
      />
      {showAlert ? (
        <Alert
          status={alertType}
          message={alertMessage}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      ) : null}

      <Heading size="xs" marginTop={6}>
        Marketing Notifications
      </Heading>
      {/* Push Notification settings sync with mobile device preferences and currently have no purpose on web */}
      {!isWeb ? (
        <View style={styles.containerRow}>
          <View flex={1}>
            <Text style={styles.label}>
              Push Notifications (important league updates, announcements, etc.)
            </Text>
          </View>
          <Switch
            isDisabled={loading}
            value={marketingNotificationPreferences.push}
            onToggle={handlePushToggle}
          />
        </View>
      ) : null}
      <View style={styles.containerRow}>
        <Text style={styles.label}>Marketing Emails</Text>
        <Switch
          isDisabled={loading}
          value={marketingNotificationPreferences.email}
          onToggle={handleMarketingEmailToggle}
        />
      </View>
      <View style={styles.containerRow}>
        <Text style={styles.label}>Marketing Text Messages</Text>
        <Switch
          isDisabled={loading}
          value={marketingNotificationPreferences.sms}
          onToggle={handleMarketingSmsToggle}
        />
      </View>

      <Heading size="xs" marginTop={6}>
        Team Notifications
      </Heading>
      {/* Push Notification settings sync with mobile device preferences and currently have no purpose on web */}
      {!isWeb ? (
        <View style={styles.containerRow}>
          <View flex={1}>
            <Text style={styles.label}>Team Push Notifications</Text>
          </View>
          <Switch
            isDisabled={teamLoading}
            value={teamNotificationPreferences.push}
            onToggle={handlePushToggle}
          />
        </View>
      ) : null}
      <View style={styles.containerRow}>
        <Text style={styles.label}>Team Emails</Text>
        <Switch
          isDisabled={teamLoading}
          value={teamNotificationPreferences.email}
          onToggle={handleTeamEmailToggle}
        />
      </View>
    </View>
  );
};

export default Notifications;
