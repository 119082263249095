import { skipToken, useSuspenseQuery } from '@apollo/client';
import { type IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faGem } from '@fortawesome/pro-light-svg-icons/faGem';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot';
import { faMap } from '@fortawesome/pro-light-svg-icons/faMap';
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons/faMoneyBill';
import { faWhistle } from '@fortawesome/pro-light-svg-icons/faWhistle';
import type { IHStackProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Stack/HStack';
import { Suspense } from 'react';
import { useTheme } from '../../../../theme';
import { type RentalVenueExclusiveProps } from '../../types';
import {
  FontAwesomeIcon,
  Row,
  Skeleton,
  Stack,
  TouchableOpacity,
} from '../../../../base-components';
import GET_RENTAL_GENERAL_INFO from './graphql/GET_RENTAL_GENERAL_INFO';
import GET_VENUE_GENERAL_INFO from './graphql/GET_VENUE_GENERAL_INFO';
import getInfoGridPricing from './helpers/getInfoGridPricing';
import getInfoGridTimeRange from './helpers/getInfoGridTimeRange';
import getInfoGridVenueLink from './helpers/getInfoGridVenueLink';
import RentalPolicies from '../RentalPolicies';
import { RentalTextSm } from '../RentalCommonText';

const IconRow = ({
  icon,
  children,
}: {
  icon: IconDefinition;
  children: string | React.ReactNode;
}) => {
  const { colors } = useTheme();

  /**
   * flexShrink is for the text to truncate properly and for the icon to stay the same size
   */
  return (
    <Row alignItems="center" space={2}>
      <FontAwesomeIcon flexShrink={0} icon={icon} color={colors.text} />
      {typeof children === 'string' ? (
        <RentalTextSm flexShrink={1}>{children}</RentalTextSm>
      ) : (
        children
      )}
    </Row>
  );
};

type Props = {
  containerStyle?: IHStackProps;
} & RentalVenueExclusiveProps;

/**
 * Fetches general rental information for the grid display section.
 *
 * @important Make sure wrap this component with Suspense and ErrorBoundary!
 */
const RentalInfoGrid = ({ rentalId, venueId, containerStyle = {} }: Props) => {
  const { data: rentalData } = useSuspenseQuery(
    GET_RENTAL_GENERAL_INFO,
    rentalId
      ? {
          variables: {
            rentalId,
          },
        }
      : skipToken
  );

  const { data: venueData } = useSuspenseQuery(
    GET_VENUE_GENERAL_INFO,
    venueId
      ? {
          variables: {
            venueId,
          },
        }
      : skipToken
  );

  const neighborhood = rentalData?.rental.venue.neighborhood || venueData?.venue.neighborhood || '';
  const venueName = rentalData?.rental.venue.shorthandName || venueData?.venue.shorthandName || '';

  const { standardPriceText, primePriceText } = getInfoGridPricing(rentalData, venueData);
  const timeRange = getInfoGridTimeRange(rentalData, venueData);
  const onPressVenue = getInfoGridVenueLink(rentalData, venueData);

  const policyProps = {
    ...(rentalId ? { rentalId } : {}),
    ...(venueId ? { venueId } : {}),
  } as RentalVenueExclusiveProps;

  return (
    <Row justifyContent="space-between" space={2} {...containerStyle}>
      <Stack space={2} flexShrink={1}>
        <IconRow icon={faMap}>{neighborhood}</IconRow>
        <IconRow icon={faLocationDot}>
          <TouchableOpacity style={{ flexShrink: 1 }} {...onPressVenue}>
            <RentalTextSm isLink>{venueName}</RentalTextSm>
          </TouchableOpacity>
        </IconRow>
        <IconRow icon={faWhistle}>
          <RentalPolicies
            {...policyProps}
            variant="modal"
            modalTouchableOpacityStyle={{ flexShrink: 1 }}
          />
        </IconRow>
      </Stack>
      <Stack space={2}>
        <IconRow icon={faClock}>{timeRange}</IconRow>
        <IconRow icon={faMoneyBill}>{standardPriceText}</IconRow>
        <IconRow icon={faGem}>{primePriceText}</IconRow>
      </Stack>
    </Row>
  );
};

const TextSkeleton = () => <Skeleton.Text lines={1} width="80%" />;

const Loading = () => {
  return (
    <Row justifyContent="space-between" space={2}>
      <Stack space={2} flex={1}>
        <IconRow icon={faMap}>
          <TextSkeleton />
        </IconRow>
        <IconRow icon={faLocationDot}>
          <TextSkeleton />
        </IconRow>
        <IconRow icon={faWhistle}>
          <TextSkeleton />
        </IconRow>
      </Stack>
      <Stack space={2} flex={1}>
        <IconRow icon={faClock}>
          <TextSkeleton />
        </IconRow>
        <IconRow icon={faMoneyBill}>
          <TextSkeleton />
        </IconRow>
        <IconRow icon={faGem}>
          <TextSkeleton />
        </IconRow>
      </Stack>
    </Row>
  );
};

const SuspendedRentalInfoGrid = (props: Props) => (
  <Suspense fallback={<Loading />}>
    <RentalInfoGrid {...props} />
  </Suspense>
);

export default SuspendedRentalInfoGrid;
