import React from 'react';
import { Platform } from 'react-native';
import { Colors, useTheme } from '../../../theme';
import { Table } from '../../../custom-components';
import { Text } from '../../../base-components';

const isWeb = Platform.OS === 'web';

// HACK - intentional until we have web theming
const webFonts = {
  heading: 12,
  label: 10.5,
  sublabel: 10,
  voloPass: 14,
};

type LeaguePricingHeaderProps = {
  label?: string;
  sublabel?: string;
  isEmpty?: boolean;
  noBorder?: boolean;
  grayText?: boolean;
  isColumnLabel?: boolean;
  position?: 'topLeft' | 'topRight';
};

const LeaguePricingHeader = ({
  label,
  sublabel,
  isEmpty = false,
  noBorder = false,
  grayText = false,
  isColumnLabel = false,
  position,
}: LeaguePricingHeaderProps) => {
  const { colors, dark } = useTheme();

  /**
   * - If this is a column label (Individual, Pre-paid team), use the default text color
   * - If the row is a current phase, use the opposite default text color for contrast
   * - If the row is not a current phase, use gray text
   */
  const selectedTextColor = dark ? Colors.black : Colors.white;
  const bodyTextColor = grayText ? Colors.greys.medium : selectedTextColor;
  const textColor = isColumnLabel ? colors.text : bodyTextColor;

  if (isEmpty) return <Table.Header borderWidth={0} />;

  return (
    <Table.Header
      flexDirection="column"
      alignItems="flex-start"
      borderBottomWidth={0}
      borderRightWidth={position === 'topLeft' ? 0 : 1}
      borderTopLeftRadius={position === 'topLeft' ? 5 : null}
      borderTopRightRadius={position === 'topRight' ? 5 : null}
      borderWidth={noBorder ? 0 : 1}
    >
      <Text
        bold
        marginRight={2}
        marginLeft={2}
        marginTop={2}
        fontSize={isWeb ? webFonts.label : null}
        color={textColor}
      >
        {label}
      </Text>
      <Text
        marginRight={2}
        marginLeft={2}
        marginBottom={2}
        fontSize={isWeb ? webFonts.sublabel : 12}
        color={textColor}
      >
        {sublabel}
      </Text>
    </Table.Header>
  );
};

export default LeaguePricingHeader;
