import type { FC } from 'react';
import { Popup, Button, LoginForm } from '.';

type Props = {
  showLogin?: boolean;
  setShowLogin: (arg: boolean) => void;
};

const LoginModal: FC<Props> = ({ showLogin, setShowLogin }) => {
  const handleClosePopup = () => setShowLogin(false);

  return (
    <Popup hidden={!showLogin} close={handleClosePopup}>
      <div>
        <h3 className="mb-3">New to Volo?</h3>
        <Button
          primary
          className="mb-2"
          style={{ fontSize: '18px', width: '260px' }}
          to="/signup/app/personal"
          newWindow
        >
          Sign Up
        </Button>
        <h3 className="my-2">Or, log in here:</h3>
        <LoginForm preventRedirect />
      </div>
    </Popup>
  );
};

export default LoginModal;
