import { useCallback, useEffect } from 'react';

import { graphql } from 'graphql-schema';
import { useLazyQuery } from '@apollo/client';
import type { RoleEnum } from '@rivallapp/volosports-components';
import { sessionAPI } from '../utils/api';
import { useAuthStore } from '../zustand-stores';
import { Auth } from '../zustand-stores/AuthStore';
import hasuraClient from '../apollo/hasuraClient';

const GET_LOGGED_IN_USER = graphql(`
  query GetLoggedInUser {
    currentUser {
      _id
      email
      external_id
      roles {
        _id
        role
      }
    }
  }
`);

/**
 * This currently runs one time when the `App` component mounts.
 *
 * If the user was previously authenticated, it will call the
 * sessions API to silently reauthenticate.
 *
 * If the user's authentication is still valid, it updates the auth state
 * and provides their user context to Braze and Sentry.
 *
 * If it fails, the auth state will be reset and the backend will clear
 * their authentication (no need for a subsequent logout call)
 */
const useSilentLogin = () => {
  const [getLoggedInUser] = useLazyQuery(GET_LOGGED_IN_USER, { client: hasuraClient });

  const silentLogin = useCallback(async () => {
    const { isAuthenticated, isAuthenticatedWithHasura } = useAuthStore.getState();

    if (isAuthenticatedWithHasura) {
      try {
        const { data } = await getLoggedInUser();
        if (!data?.currentUser) throw new Error();
        useAuthStore.setState({
          userId: data.currentUser._id,
          isAuthenticated: true,
          isAuthenticatedWithHasura: true,
          roles: data.currentUser.roles.map(({ role }) => role as RoleEnum),
        });
        Auth.syncUserWithServices({
          userId: data.currentUser._id,
          email: data.currentUser.email,
          externalId: data.currentUser.external_id,
        });
      } catch (err) {
        console.error('Could not load user...');
        Auth.resetState();
      }
    } else if (isAuthenticated) {
      try {
        const { data } = await sessionAPI.getLoggedInUser();
        useAuthStore.setState({
          userId: data?.user_id,
          roles: data?.roles,
          isAuthenticated: true,
          isAuthenticatedWithHasura: false,
        });
        Auth.syncUserWithServices({
          email: data?.email,
          userId: data?.user_id,
        });
      } catch (err) {
        console.error('Could not load user...');
        Auth.resetState();
      }
    }
  }, [getLoggedInUser]);

  useEffect(() => {
    silentLogin();
  }, [silentLogin]);
};

export default useSilentLogin;
