import { StyleSheet } from 'react-native';
import { voloGreys } from '../../theme';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 40,
  },
  flex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowLabel: {
    display: 'flex',
    flex: 2,
    maxWidth: 150,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  rosterRulesHeading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  label: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  midBorder: {
    paddingVertical: 20,
    borderTopColor: voloGreys.light,
    borderTopWidth: 1,
  },
  topRow: {
    paddingVertical: 20,
  },
  note: {
    fontSize: 12,
  },
});
