import { Colors } from '../../theme';
import { Text, View } from '../../base-components';
import styles from './styles';
import { WhatsADropIn } from '../WhatsADropIn';

interface GameInfoBoxProps {
  isMinimumMet: boolean;
  isMinimumWomenMet: boolean;
  isSuggestedMet: boolean;
}

const GameInfoBox = ({ isMinimumMet, isMinimumWomenMet, isSuggestedMet }: GameInfoBoxProps) => {
  const dropInText = 'Consider opening a drop-in spot if applicable.';
  const minWomenNotMetText = 'You have fewer than the minimum required women to play.';
  const minAmountNotMetText = 'You have fewer than the minimum required players to play.';
  const suggestedNotMet = 'You have fewer than the recommended number of players.';

  const allChecksPassed = isMinimumMet && isMinimumWomenMet && isSuggestedMet;
  const allChecksPassedText =
    "You're all set for this game! Check back here for future games where you might need another player!";

  const getText = () => {
    if (!isMinimumWomenMet) return minWomenNotMetText;
    if (!isMinimumMet) return minAmountNotMetText;
    if (!isSuggestedMet) return suggestedNotMet;
    if (isSuggestedMet) return allChecksPassedText;

    return '';
  };

  return (
    <View
      style={[
        styles.container,
        !isSuggestedMet ? styles.suggestedNotMet : styles.checksPassed,
        !isMinimumWomenMet || !isMinimumMet ? styles.minimumNotMet : null,
      ]}
    >
      <View>
        <Text color={Colors.black} style={styles.gameMessage}>
          {getText()} {!allChecksPassed ? dropInText : ''}{' '}
        </Text>
        <WhatsADropIn popupBehavior="modal" variant="text" textProps={{ style: styles.infoLink }} />
      </View>
    </View>
  );
};

export default GameInfoBox;
