import { type TypedDocumentNode, gql } from '@apollo/client';

type OrganizationMembershipDetails = {
  isActive: boolean;
  monthlyPlanId: string;
  annualPlanId: string;
};

type GetOrganizationData = {
  organization: {
    _id: string;
    name: string;
    voloPassTiers?: OrganizationMembershipDetails;
    voloFitnessTiers?: OrganizationMembershipDetails;
  };
};

type GetOrganizationInput = {
  id: string;
};

const GET_ORGANIZATION: TypedDocumentNode<GetOrganizationData, GetOrganizationInput> = gql`
  query Organization($id: ID) {
    organization(_id: $id) {
      _id
      name
      voloPassTiers {
        isActive
        monthlyPlanId
        annualPlanId
      }
      voloFitnessTiers {
        isActive
        monthlyPlanId
        annualPlanId
      }
    }
  }
`;

export default GET_ORGANIZATION;
