import type { IButtonProps, IModalProps } from '@rivallapp/native-base';

export interface AlertButtonOptions {
  text: string;
  onPress?: () => void;
  utilProps?: Omit<IButtonProps, 'color'>;
  textColor?: IButtonProps['color'];
  variant?: 'default' | 'no-dismiss';
}

export interface ModalState {
  isOpen: boolean;
  title: string;
  message: string;
  buttons?: AlertButtonOptions[];
  modalProps?: Omit<IModalProps, 'isOpen'>;
}

export interface AlertStoreState {
  alerts: {
    [id: string]: ModalState;
  };
}

type ModalId = string;
interface AlertAction extends Omit<ModalState, 'isOpen'> {
  /**
   * The id of the `ModalAlert` to be opened
   * - if not id is provided, will default to `RootModalId`
   */
  id?: ModalId;
}

export interface AlertStoreActions {
  /**
   * Method to imperatively open the `ModalAlert` component
   *
   * @important
   * - iOS has a bug where the `ModalAlert` will not render on top of the `Modal` component when both are set to `useRNModal`, instead, use the `id` and `<ModalAlertComponent id="feature-alert" />` within the `Modal` component already, to make sure it appears on top
   * ---
   * @param id - The id of the `ModalAlert` to be opened, if none is provided, will default to the `RootModalId` which is rendered at the root of the app (`ThemeProvider.tsx` within the SCL)
   * @param title - The title of the alert (Modal.Header)
   * @param message - The message of the alert (Modal.Body)
   * @param buttons - The buttons to be displayed in the alert (Modal.Footer)
   * - `onPress` of any button will close the `ModalAlert`
   * - this can be override by setting the `variant` prop on the button to `'no-dismiss'`
   * @param modalProps - The utility props to be passed to the `Modal` component
   */
  alert: (alertConfig: AlertAction) => void;
  /**
   * Method to imperatively close the `ModalAlert` component
   * - resets the `ModalAlertStore` to the initial state
   */
  close: (id?: ModalId) => void;
}

export const RootModalId: ModalId = 'root-modal-alert';

export interface ModalAlertStore extends AlertStoreState, AlertStoreActions {}
