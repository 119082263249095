import { gql, type TypedDocumentNode } from '@apollo/client';
import TeamPlayersFragment from './fragments';

type UpdateTeamRoleData = {
  registrantUpdateTeamRole: {
    _id: string;
    captainId?: string;
    coCaptainIds: string[];
    players: {
      _id: string;
      displayNameTeams?: string;
    }[];
  };
};

type UpdateTeamRoleInput = {
  input: {
    playerId: string;
    teamId: string;
    teamRole: string;
  };
};

const UPDATE_TEAM_ROLE: TypedDocumentNode<UpdateTeamRoleData, UpdateTeamRoleInput> = gql`
  ${TeamPlayersFragment}

  mutation RegistrantUpdateTeamRole($input: RegistrantUpdateTeamRoleInput!) {
    registrantUpdateTeamRole(input: $input) {
      ...TeamPlayersFragment
      captainId
      coCaptainIds
    }
  }
`;

export default UPDATE_TEAM_ROLE;
