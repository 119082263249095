import { type TypedDocumentNode, gql } from '@apollo/client';

type OrganizationMembershipDetails = {
  isActive: boolean;
  monthlyPlanId: string;
  annualPlanId: string;
  monthlyDollarPrice?: number;
};

type GetOrganizationMembershipsData = {
  organization: {
    _id: string;
    voloPassTiers?: OrganizationMembershipDetails;
    voloFitnessTiers?: OrganizationMembershipDetails;
  };
};

type GetOrganizationMembershipsInput = {
  id: string;
};

const GET_ORGANIZATION_MEMBERSHIPS: TypedDocumentNode<
  GetOrganizationMembershipsData,
  GetOrganizationMembershipsInput
> = gql`
  query Organization($id: ID) {
    organization(_id: $id) {
      _id
      voloPassTiers {
        isActive
        monthlyPlanId
        annualPlanId
        monthlyDollarPrice
      }
      voloFitnessTiers {
        isActive
        monthlyPlanId
        annualPlanId
        monthlyDollarPrice
      }
    }
  }
`;

export default GET_ORGANIZATION_MEMBERSHIPS;
