import { gql } from '@apollo/client';

export default gql`
  mutation RsvpForPlayer($input: RsvpForPlayerInput!) {
    rsvpForPlayer(input: $input) {
      games {
        rsvp {
          playerTeam {
            YES
            NO
            MAYBE
          }
        }
        _id
      }
    }
  }
`;
