import { gql, type TypedDocumentNode } from '@apollo/client';

export type GetVenueMapFromRentalData = {
  rental: {
    _id: string;
    venue: {
      _id: string;
      venue_map_url?: string;
    };
  };
};

export type GetVenueMapFromRentalInput = {
  rentalId: string;
};

const GET_VENUE_MAP_FROM_RENTAL: TypedDocumentNode<
  GetVenueMapFromRentalData,
  GetVenueMapFromRentalInput
> = gql`
  query GetVenueMapFromRental($rentalId: ID!) {
    rental(_id: $rentalId) {
      _id
      venue {
        _id
        venue_map_url
      }
    }
  }
`;

export default GET_VENUE_MAP_FROM_RENTAL;
