import { type TypedDocumentNode, gql } from '@apollo/client';
import type { TRentalActivityName } from '../../../../../constants';

type Data = {
  rental: {
    _id: string;
    activity_name: TRentalActivityName;
    standard_price_cents: number;
    prime_price_cents?: number;
    vp_standard_price_cents: number;
    vp_prime_price_cents?: number;
  };
};

type Input = {
  rentalId: string;
};

const GET_RENTAL_PRICING: TypedDocumentNode<Data, Input> = gql`
  query rental($rentalId: ID!) {
    rental(_id: $rentalId) {
      _id
      activity_name
      standard_price_cents
      prime_price_cents
      vp_standard_price_cents
      vp_prime_price_cents
    }
  }
`;

export default GET_RENTAL_PRICING;
