import { useEffect, type ComponentProps } from 'react';
import { Alert, Collapse } from '@rivallapp/native-base';
import { type InterfaceAlertProps } from '@rivallapp/native-base/lib/typescript/components/composites/Alert/types';
import { faX } from '@fortawesome/pro-solid-svg-icons/faX';
import { Colors } from '../../theme';
import { Text, View, IconButton, FontAwesomeIcon } from '../index';
import styles from './styles';

interface AlertProps extends ComponentProps<typeof Alert> {
  status: InterfaceAlertProps['status'];
  message: string;
  showAlert: boolean;
  setShowAlert: (arg: boolean) => unknown | undefined;
  /** Defaults to `true` to preserve existing behavior */
  autoDismiss?: boolean;
}

const CustomAlert = ({
  status,
  message,
  showAlert,
  setShowAlert,
  autoDismiss = true,
  ...props
}: AlertProps) => {
  // Rule disabled because no cleanup necessary if no timeout
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (autoDismiss) {
      const alertTimeout = setTimeout(() => setShowAlert(false), 3000);
      return () => clearTimeout(alertTimeout);
    }
  }, [autoDismiss, setShowAlert]);

  // this overwrites the startingHeight={1} default on mobile. !show needs to be outside the {}
  const mobileProps = !showAlert ? { height: 0 } : { minHeight: 50 };

  return (
    <Collapse isOpen={showAlert} _ios={mobileProps} _android={mobileProps}>
      <Alert
        flexDir="row"
        variant="subtle"
        status={status}
        position="relative"
        justifyContent="space-between"
        {...props}
      >
        <View style={styles.alert} maxW="90%">
          <Alert.Icon />
          <Text color={Colors.black} style={styles.alertMessage}>
            {message}
          </Text>
        </View>
        <IconButton
          rounded="full"
          alignSelf="flex-start"
          colorScheme={status}
          onPress={() => setShowAlert(false)}
          icon={<FontAwesomeIcon icon={faX} size={3} />}
        />
      </Alert>
    </Collapse>
  );
};

export default CustomAlert;

export const NAlert = Alert;
