/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { type IIconProps, createIcon } from '@rivallapp/native-base';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { type ViewStyle } from 'react-native';

export type IFontAwesomeIconProps = IIconProps & {
  /**
   * Directly pass in the `@fortawesome/{icon-package}` icon import
   *
   * @example
   * import { faPlus } from '@fortawesome/pro-light-svg-icons';
   *
   * <FontAwesomeIcon icon={faPlus} />
   * */
  icon: IconDefinition;
};

/**
 * Wrapper for FontAwesome icons that provides full integration with the native-base theme.
 *
 * This removes access to the following `@fortawesome/react-native-fontawesome` features:
 * https://fontawesome.com/docs/web/use-with/react-native#other-features
 *
 * Duotone and Masking will need to directly use the `@fortawesome/react-native-fontawesome` import.
 * Power transforms can be replicated with `transform` prop
 *
 * @example
 * import { faFaceAwesome } from '@fortawesome/pro-light-svg-icons/faFaceAwesome';
 *
 * <FontAwesomeIcon icon={faFaceAwesome} color="primary.500" size={32} />
 */
export const FontAwesomeIcon: React.FC<IFontAwesomeIconProps> = ({
  icon: iconDefinition,
  _web,
  ...props
}) => {
  if (!iconDefinition.icon || !Array.isArray(iconDefinition.icon)) {
    throw new Error(
      'Invalid icon provided. Icon must be a direct import from @fortawesome/{icon-package}'
    );
  }

  const webProps = useMemo(() => {
    // pointerEvents: 'none' is needed here to prevent the SVG from stealing click events
    // on web. It is not a valid property in the StyleSheet API, which is why the assertion is
    // needed here, but _web prop will only be applied on web, where this _is_ valid CSS.
    const pointerEvents = { pointerEvents: 'none' } as ViewStyle;
    return _web ? { ..._web, style: [_web.style, pointerEvents] } : { style: pointerEvents };
  }, [_web]);

  // Using the icon's SVG data to render the icon with `createIcon`
  const Component = useMemo(() => {
    const [width, height, , , svgData] = iconDefinition.icon;
    return createIcon({
      viewBox: `0 0 ${width} ${height}`,
      d: svgData as string,
    });
  }, [iconDefinition.icon]);

  return <Component _web={webProps} {...props} />;
};

export default FontAwesomeIcon;
