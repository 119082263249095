/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable indent */
import { memo } from 'react';
import { Text as NText, type ITextProps as NBITextProps } from '@rivallapp/native-base';

import { colorManipulation } from '../../utilities';
import { type Color, TextTypography, type TText, Colors, useTheme, voloGreys } from '../../theme';

/*
  ! Note to Dev:
  - Text has a defaultProp of allowFontScaling: false passed in the ThemeProvider
  - this is to make sure that any other NB component that uses Text, will not scale the font size
*/

export interface ITextProps extends NBITextProps {
  /**
   * If true, the text will be rendered as a link (lightblue)
   * @default false
   */
  isLink?: boolean;
  /**
   * The type of text to render which matches the HTML tag name:
   * this adds preset fontSizes, fontWeights to make sure the styling is consistent
   *
   * -`p` default paragraph text
   *
   * -`caption` small with text color = voloGreys.medium
   * @default "p"
   * @see TextTypography
   */
  type?: TText;
  /**
   * If true, the text will be grayed out and the onPress disabled (pointerEvents: 'none')
   * - not sure why NB has `disabled` prop for Text, but `isDisabled` for their other components, but this is to make it more consistent
   * @default false
   */
  isDisabled?: boolean;
  /**
   * The background color of the parent component
   * - use this prop when needing a contrast color between the text and the parent component
   * - this is to make sure the text is readable on top of the parent component (mostly needed when it comes to selected items in a list, etc)
   * - if not passed, the color will be inherited from the useTheme() hook
   * @default undefined
   */
  parentBgColor?: Color;
  /**
   * Only the following properties are valid on mobile
   * - auto
   * - center
   * - justify
   * - left
   * - right
   */
  textAlign?: NBITextProps['textAlign'];
}

const BaseText = ({
  isLink = false,
  type = 'p',
  isDisabled,
  disabled,
  parentBgColor,
  onPress,
  ...props
}: ITextProps) => {
  const { colors } = useTheme();
  const linkProps: ITextProps = {
    ...(isLink
      ? {
          color: isDisabled || disabled ? voloGreys.medium : Colors.link, // guess well go with medium for now until shades are added
        }
      : {}),
  };

  const typographyProps = TextTypography[type] || {};

  let contrastTColor = Colors.black; // default to black (wont be used if parentBgColor is passed)
  if (parentBgColor) {
    contrastTColor = colorManipulation.isDark(parentBgColor) ? Colors.white : Colors.black;
  }

  return (
    <NText
      {...(isDisabled || disabled ? {} : { onPress })} // only add onPress if not disabled (remove pointer(web) and touchable behavior(mobile))
      {...(parentBgColor ? { color: contrastTColor } : { color: colors.text })} // add a contrast color if parentBgColor prop is utilized
      {...typographyProps}
      {...linkProps}
      {...props}
    />
  );
};

/**
 * A custom Text component that uses NativeBase with Volo Fonts applied and adds some extra functionality:
 * - If `isLink` is true, the text will be rendered as a link (lightblue)
 * - all the utility props from NativeBase's Text component are available and can still be used to override the default styles
 */
const Text = (props: ITextProps) => <BaseText {...props} />;

export default memo(Text);
