import { type ITextProps, Text } from '../../../../../base-components';

export interface SelectorTextProps extends ITextProps {
  /**
   * Adjust the color of the text if the item is selected
   */
  isSelected: boolean;
}

const SelectorText = ({ isSelected, isDisabled, ...rest }: SelectorTextProps) => {
  return (
    <Text
      {...rest}
      {...(isSelected && { color: 'white.600' })}
      strikeThrough={isDisabled}
      textAlign="center"
    />
  );
};

export default SelectorText;
