import { gql } from '@apollo/client';

const DISCOVER_TAGS = gql`
  query discoverTags($input: DiscoverInput!) {
    discoverProgramTags(input: $input) {
      programTags {
        _id
        name
      }
    }
  }
`;

export default DISCOVER_TAGS;
