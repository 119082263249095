import { type FC, useCallback } from 'react';

import { useTheme, voloBlues, voloGreys } from '../../../../theme';
import { Text, View } from '../../../../base-components';
import { VoloPassBadgeIcon, VoloFitnessLogoBarbell } from '../../../../custom-components';
import { type PricingModule } from '../../ProgramCard/types';
import useMobileSizing from '../../helpers/useMobileSizing';

/** The pricing section is dynamic depending on the current user's membership subscriptions and program's pricing structure
 *
 * Standard/Non-member Price: Highlighted if user does not have a membership subscription OR if program doesn't offer discounted member pricing
 *
 * memberPrice [**TODO: Replace with voloPassPrice - separate PR**]: Highlighted if user has an active Volo Pass subscription AND program offers Volo Pass pricing
 *
 * voloFitnessPrice: Highlighted if user has an active Volo Fitness subscription AND program offers Volo Fitness pricing
 * Volo Fitness pricing is ONLY offered on Class and Series program types
 *
 * If a program offers both Volo Fitness and Volo Pass pricing, only show Volo Fitness pricing IF user has an active Volo Fitness membership
 */

const PricingSection: FC<Omit<PricingModule, 'type'>> = ({
  price,
  memberPrice,
  voloFitnessPrice,
  isVoloPassMember,
  isVoloFitnessMember,
}) => {
  const { colors, dark } = useTheme();
  const mobileSizing = useMobileSizing();

  const membershipPriceDisplay = useCallback(
    () => ({
      membershipPricing:
        voloFitnessPrice !== undefined && isVoloFitnessMember ? voloFitnessPrice : memberPrice,
      membershipLogo:
        voloFitnessPrice !== undefined && isVoloFitnessMember ? (
          <VoloFitnessLogoBarbell
            size={mobileSizing ? 4 : 5}
            colorScheme={dark ? 'white' : 'primary'}
          />
        ) : (
          <VoloPassBadgeIcon size={mobileSizing ? 4 : 5} colorScheme={dark ? 'white' : 'primary'} />
        ),
    }),
    [dark, isVoloFitnessMember, memberPrice, mobileSizing, voloFitnessPrice]
  );

  const membershipPriceObject = membershipPriceDisplay();

  const highlightMembershipPricing = isVoloFitnessMember || isVoloPassMember;

  // This doesn't change in dark mode
  const activePriceBorder = {
    borderWidth: 2,
    borderRadius: 5,
    borderColor: voloBlues.medium,
    padding: mobileSizing ? 2 : 5,
    marginLeft: 5,
  };

  return (
    <View
      flexDirection="row"
      flex={1}
      alignItems="center"
      maxWidth={mobileSizing ? 170 : 250}
      minHeight={mobileSizing ? 18 : 26}
    >
      {memberPrice !== undefined || (voloFitnessPrice !== undefined && isVoloFitnessMember) ? (
        <View flexDirection="row" alignItems="center" justifyContent="center">
          <View style={!highlightMembershipPricing ? activePriceBorder : null}>
            <View alignItems="center" justifyContent="center" textAlign="center">
              <Text
                lineHeight={12}
                fontSize={mobileSizing ? 10 : 12}
                bold={!highlightMembershipPricing}
                color={highlightMembershipPricing ? voloGreys.medium : colors.text}
              >
                ${price}
              </Text>
              <Text
                bold={!highlightMembershipPricing}
                color={highlightMembershipPricing ? voloGreys.medium : colors.text}
                fontSize={mobileSizing ? 6 : 8}
                lineHeight={mobileSizing ? 8 : 10}
              >
                Standard
              </Text>
            </View>
          </View>
          <View
            style={highlightMembershipPricing ? activePriceBorder : null}
            flexDirection="row"
            justifyContent="center"
            textAlign="center"
            marginLeft={mobileSizing ? 1.25 : 2.5}
          >
            <View alignItems="center" justifyContent="center" marginRight={0.5}>
              {membershipPriceObject.membershipLogo}
            </View>
            <View alignItems="center" justifyContent="center" textAlign="center">
              <Text
                lineHeight={12}
                fontSize={mobileSizing ? 10 : 12}
                bold={highlightMembershipPricing}
                color={highlightMembershipPricing ? colors.text : voloGreys.medium}
              >
                ${membershipPriceObject.membershipPricing}
              </Text>
              <Text
                bold={highlightMembershipPricing}
                color={highlightMembershipPricing ? colors.text : voloGreys.medium}
                fontSize={mobileSizing ? 6 : 8}
                lineHeight={mobileSizing ? 8 : 10}
              >
                Member
              </Text>
            </View>
          </View>
        </View>
      ) : (
        <Text
          marginLeft={2.5}
          numberOfLines={1}
          fontSize={14}
          letterSpacing={-0.5}
          maxWidth={mobileSizing ? 160 : 220}
        >
          ${price}
        </Text>
      )}
    </View>
  );
};

export default PricingSection;
