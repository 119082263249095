/* eslint-disable react/jsx-props-no-spreading */

import React, { type ReactNode } from 'react';
import { type ITextProps, Text } from '../../base-components';
import { useTheme } from '../../theme';

type BannerProps = {
  children?: ReactNode;
  background?: string;
} & ITextProps;

const Banner: React.FC<BannerProps> = ({ children, background, ...textProps }) => {
  const { colors } = useTheme();

  const bannerBackground = background || colors.accents.lightTeal;

  return (
    <Text
      textAlign="center"
      paddingX={3}
      paddingY={1}
      borderRadius={2}
      bg={bannerBackground}
      parentBgColor={bannerBackground}
      {...textProps}
    >
      {children}
    </Text>
  );
};

export default Banner;
