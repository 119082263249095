const toggleArrayItem = <T>(array: T[], filterBy: T[] | T) => {
  if (Array.isArray(filterBy)) {
    return array.filter(arrayItem => !filterBy.includes(arrayItem));
  }
  return array.includes(filterBy)
    ? array.filter(currentItem => currentItem !== filterBy)
    : [...new Set([...array, filterBy])];
};

export default toggleArrayItem;
