import { type FC, type ComponentProps } from 'react';

import { ProgramTypeEnum } from '../../../constants';
import { ProgramCard } from '../ProgramCard';
import { useDiscoverProgramModules } from './hooks';

export interface DiscoverProgramCardProps {
  /** programId is used in all program types except drop-ins */
  programId?: string;
  /** gameId is only used for drop-ins */
  gameId?: string;
  onCardPress: ComponentProps<typeof ProgramCard>['onCardPress'];
  /**
   * Should the price shown on the card include fees
   * Related spec: https://docs.google.com/presentation/d/1oWSL9l2qnrp-mx0KkQxjEXpBUn4H20vgmPWKdsz59jU/edit#slide=id.p
   * @default false
   * */
  shouldSeePricesWithFees?: boolean;
}

const DiscoverProgramCard: FC<DiscoverProgramCardProps> = ({
  programId,
  gameId,
  onCardPress,
  shouldSeePricesWithFees = false,
}) => {
  const {
    loading,
    error,
    showVoloFitnessPricing,
    topLeftModule,
    topRightModule,
    bottomLeftModule,
    bottomRightModule,
    displayName = '',
    photoUrl = '',
    banner_text = '',
    isDailyProgram,
    isVoloFitnessDropin,
    isVoloFitnessProgram,
    isPremier,
    isVoloPassExclusive,
    // default for ts error
    programType = ProgramTypeEnum.LEAGUE,
  } = useDiscoverProgramModules({ programId, gameId, shouldSeePricesWithFees });

  return (
    <ProgramCard
      loading={loading}
      error={error}
      displayName={displayName}
      imageUrl={photoUrl}
      programId={programId}
      programType={programType}
      onCardPress={onCardPress}
      showVoloPassIcon={
        isDailyProgram ||
        programType === ProgramTypeEnum.DROPIN ||
        programType === ProgramTypeEnum.TOURNAMENT ||
        ((isVoloFitnessProgram || isVoloFitnessDropin) && !showVoloFitnessPricing)
      }
      isPremier={isPremier}
      isVoloPassExclusive={isVoloPassExclusive}
      showVoloFitnessIcon={(isVoloFitnessProgram || isVoloFitnessDropin) && showVoloFitnessPricing}
      banner_text={banner_text}
      topLeftModule={topLeftModule}
      topRightModule={topRightModule}
      bottomLeftModule={bottomLeftModule}
      bottomRightModule={bottomRightModule}
    />
  );
};

export default DiscoverProgramCard;
