import { useEffect, useState } from 'react';
import Clipboard from './Clipboard';

export type UseClipboardArgs = {
  /**
   * Number of milliseconds before `hasCopied` state resets.
   * Passing undefined will disable the reset.
   * @default 3000
   * */
  copyDuration?: number | undefined;
};

export const useClipboard = ({ copyDuration = 3000 }: UseClipboardArgs = {}) => {
  const [hasCopied, setHasCopied] = useState(false);

  const onCopy = async (text: string) => {
    if (Clipboard.isAvailable) {
      await Clipboard.copy(text);
      setHasCopied(true);
    } else {
      console.error('Clipboard API is not available');
    }
  };

  useEffect(() => {
    if (hasCopied && typeof copyDuration === 'number') {
      const timeoutId = setTimeout(() => {
        setHasCopied(false);
      }, copyDuration);

      return () => clearTimeout(timeoutId);
    }

    return () => {};
  }, [copyDuration, hasCopied]);

  return {
    onCopy,
    hasCopied,
  };
};
