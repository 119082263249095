import Svg, { G, Circle, Path, Defs, ClipPath } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgParkingOnSite = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <G clipPath="url(#parking-on-site_svg__a)">
      <Circle cx={50} cy={50} r={40} fill="#eaff70" />
      <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
      <Path
        fill="#103b5d"
        d="M32.769 45.471V60h-6.794V20.898h14.958q6.553 0 10.393 3.411 3.867 3.411 3.867 9.024 0 5.746-3.786 8.942-3.76 3.196-10.554 3.196zm0-5.451h8.164q3.626 0 5.532-1.692 1.907-1.719 1.907-4.942 0-3.168-1.934-5.049-1.933-1.905-5.317-1.96H32.77z"
      />
    </G>
    <Defs>
      <ClipPath id="parking-on-site_svg__a">
        <Path fill="#fff" d="M0 0h90v90H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgParkingOnSite;
