import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgFlipcupCountStandings = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      d="M57.07 19H23.93a.933.933 0 0 0-.93.93c0 .512.419.93.93.93h33.14c.512 0 .93-.418.93-.93a.933.933 0 0 0-.93-.93M26.543 35.839h27.914l.558-5.769h-29.03zM28.775 56.178h23.263l2.046-19.523H26.916zM24.31 22.81H56.69l.186-1.117h-32.75zM24.962 24.41h31.076l.187-1.116h-31.45zM25.706 29.313h29.402l.558-4.094H25.334zM29.86 62.503c0 .54.45.983 1 .983h19.28c.55 0 1-.443 1-.983l.745-5.504h-22.77z"
    />
  </Svg>
);
export default SvgFlipcupCountStandings;
