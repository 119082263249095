import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

import useKeyboardListener from './useKeyboardListener';

/**
 * Hook that is used to translateY based on the keyboard height
 */
const useKeyboardTranslation = ({ divideByToValue = 3 }: { divideByToValue?: number } = {}) => {
  const { keyboardHeight } = useKeyboardListener();
  const translateY = useRef(new Animated.Value(0)).current;

  useEffect(
    function keyboardAwareTranslation() {
      Animated.timing(translateY, {
        toValue: -keyboardHeight / divideByToValue,
        duration: 250,
        useNativeDriver: true,
      }).start();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keyboardHeight]
  );

  return { animatedTranslationStyle: { transform: [{ translateY }] } };
};

export default useKeyboardTranslation;
