import { gql, type TypedDocumentNode } from '@apollo/client';

export type PlayerProgramTeamSchedule = {
  games: {
    _id: string;
    end_time: Date;
    start_time: Date;
  }[];
};

export type TeamScheduleData = {
  teamSchedule: PlayerProgramTeamSchedule;
};

type TeamScheduleVars = {
  input: {
    teamId: string;
  };
};

export const TEAM_SCHEDULE: TypedDocumentNode<TeamScheduleData, TeamScheduleVars> = gql`
  query teamSchedule($input: TeamScheduleInput!) {
    teamSchedule(input: $input) {
      games {
        _id
        end_time
        start_time
      }
    }
  }
`;
