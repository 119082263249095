import { gql, type TypedDocumentNode } from '@apollo/client';

export const ORGANIZATION_LIST_QUERY: TypedDocumentNode<{
  organizationList: { _id: string; name: string }[];
}> = gql`
  query organizationList {
    organizationList {
      _id
      name
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
    }
  }
`;

export const GET_USER_PROFILE_DETAILS = gql`
  query userProfileDetails {
    currentUser {
      _id
      email
      gender
      zip
      shirt_size
      birthday
      firstName
      lastName
      username
      address
      homeOrganization
    }
  }
`;

type NotificationPreferences = {
  email: boolean;
  push: boolean;
  sms: boolean;
};

type GetUserNotificationPreferencesData = {
  currentUser: {
    _id: string;
    notificationPreferences: {
      marketing: NotificationPreferences;
      team: NotificationPreferences;
    };
  };
};

export const GET_USER_NOTIFICATION_PREFERENCES: TypedDocumentNode<GetUserNotificationPreferencesData> = gql`
  query userNotificationPreferences {
    currentUser {
      _id
      notificationPreferences {
        marketing {
          email
          push
          sms
        }
        team {
          email
          push
          sms
        }
      }
    }
  }
`;

export const GET_USER_PRIVACY_PREFERENCES = gql`
  query userPrivacyPreferences {
    currentUser {
      _id
      firstName
      lastName
      emailHidden
      phoneHidden
      privacyPreferences {
        shareEmail
        sharePhone
        displayNameTeamRoster
        displayNamePickups
      }
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation ($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation ($input: CurrentUserInput) {
    updateCurrentUser(input: $input) {
      currentUser {
        _id
        username
        email
        firstName
        lastName
        gender
        zip
        shirt_size
        birthday
        address
        homeOrganization
        shouldSeePricesWithFees
      }
    }
  }
`;

type UpdateNotificationPreferencesInput = {
  input: {
    _id: string;
    notificationPreferences: NotificationPreferences;
  };
};

type UpdateNotificationPreferencesResponse = {
  currentUser: {
    _id: string;
    notificationPreferences: {
      marketing: NotificationPreferences;
      team: NotificationPreferences;
    };
  };
};

export const UPDATE_NOTIFICATIONS_PREFERENCES: TypedDocumentNode<
  UpdateNotificationPreferencesResponse,
  UpdateNotificationPreferencesInput
> = gql`
  mutation ($input: NotificationPreferencesInput!) {
    updateNotificationPreferences(input: $input) {
      currentUser {
        _id
        notificationPreferences {
          marketing {
            email
            push
            sms
          }
          team {
            email
            push
            sms
          }
        }
      }
    }
  }
`;

export const UPDATE_TEAM_NOTIFICATION_PREFERENCES: TypedDocumentNode<
  UpdateNotificationPreferencesResponse,
  UpdateNotificationPreferencesInput
> = gql`
  mutation updateTeamNotificationPreferences($input: NotificationPreferencesInput!) {
    updateTeamNotificationPreferences(input: $input) {
      currentUser {
        _id
        notificationPreferences {
          marketing {
            email
            push
            sms
          }
          team {
            email
            push
            sms
          }
        }
      }
    }
  }
`;

export const UPDATE_PRIVACY_PREFERENCES = gql`
  mutation ($input: PrivacyPreferencesInput!) {
    updatePrivacyPreferences(input: $input) {
      currentUser {
        _id
        privacyPreferences {
          shareEmail
          sharePhone
          displayNameTeamRoster
          displayNamePickups
        }
      }
    }
  }
`;
