import { gql, type TypedDocumentNode } from '@apollo/client';
import TeamPlayersFragment from './fragments';

type GetTeamPlayersData = {
  team: {
    _id: string;
    players: {
      _id: string;
      displayNameTeams: string;
    }[];
  };
};

type GetTeamPlayersInput = {
  id: string;
};

const GET_TEAM_PLAYERS: TypedDocumentNode<GetTeamPlayersData, GetTeamPlayersInput> = gql`
  ${TeamPlayersFragment}

  query Team($id: ID!) {
    team(_id: $id) {
      ...TeamPlayersFragment
    }
  }
`;

export default GET_TEAM_PLAYERS;
