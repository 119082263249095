import { isMobile } from '../../utilities';
import RenderHTMLMobile from './RenderHTMLMobile';
import RenderHTMLWeb from './RenderHTMLWeb';

/**
 * Renders HTML in different ways based on the platform.
 * Useful for SCL components that have HTML and will be used on both web and mobile,
 * but for more complex situations, it's best to use RenderHTML directly in player-app.
 */
const RenderHTML = ({ html }: { html: string }) => {
  if (isMobile) {
    return <RenderHTMLMobile source={{ html }} />;
  }

  return <RenderHTMLWeb html={html} />;
};
export default RenderHTML;
