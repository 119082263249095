import React from 'react';
import { isWeb } from '../../../utilities';
import { Checkbox as NativeBaseCheckbox, type ICheckboxProps } from '../../../base-components';
import { usePaymentFormContext } from '../PaymentForm';

const Checkbox: React.FC<ICheckboxProps> = props => {
  const {
    theme: { colors },
  } = usePaymentFormContext();

  return (
    <NativeBaseCheckbox
      colorScheme="tertiary"
      style={!isWeb ? { marginTop: 5, marginLeft: 0.5 } : {}} // The NB prop for this doesn't work for some reason but this does
      _stack={!isWeb ? { alignItems: 'flex-start' } : {}}
      _web={{
        marginLeft: '0.5',
      }}
      _icon={{
        colorScheme: 'black',
        color: 'black',
      }}
      _text={{
        fontSize: 12,
        color: colors.text,
        lineHeight: 18,
        flex: 1,
      }}
      {...props}
    />
  );
};

export default Checkbox;
