import { type TypedDocumentNode, gql } from '@apollo/client';

export type PricingBreakdown = {
  memberPrice?: number;
  voloPassPriceWithFees?: number;
  voloFitnessPrice?: number;
  voloFitnessPriceWithFees?: number;
  originalPrice: number;
  originalPriceWithFees: number;
};

export type ProgramPricingInput = {
  input: {
    leagueId: string;
  };
};

export type ProgramPricingResponse = {
  programPricingForRegistration: {
    pricingBreakdown: PricingBreakdown;
  };
};

const GET_PROGRAM_PRICING: TypedDocumentNode<ProgramPricingResponse, ProgramPricingInput> = gql`
  query programPricing($input: ProgramPricingForRegistrationInput!) {
    programPricingForRegistration(input: $input) {
      pricingBreakdown {
        memberPrice
        voloPassPriceWithFees
        originalPrice
        originalPriceWithFees
        voloFitnessPrice
        voloFitnessPriceWithFees
      }
    }
  }
`;

export default GET_PROGRAM_PRICING;
