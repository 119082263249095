import type { PricingBreakdown } from '../gql/PRICING_QUERY';

/**
 * Given a pricing breakdown, determines the amount in cents that can be discounted further from the
 * purchase. This is used to determine if the $1 for no additional cost donation is clickable.
 */
const canDonateDollarFromReg = (
  breakdown: PricingBreakdown['pricingBreakdown'],
  userIsDonatingRegistrationCost: boolean
) => {
  const { totalChargeCents, donationAmount, totalFeeCents, promoDiscountCents, creditAmount } =
    breakdown;

  // Compute the price of registration by itself, as this is what is used by the backend to determine if you can donate
  let programPriceCents =
    totalChargeCents - totalFeeCents - donationAmount + promoDiscountCents + creditAmount;

  // If the user is already donating on this frame, the amount is reflected in the donations, but
  // we want that to count as part of the registration price
  if (userIsDonatingRegistrationCost && donationAmount >= 100) programPriceCents += 100;

  return programPriceCents - promoDiscountCents - creditAmount >= 100;
};

export default canDonateDollarFromReg;
