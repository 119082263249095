import { gql } from '@apollo/client';

const DISCOVER_SKILL_LEVELS = gql`
  query DiscoverSkillLevels($input: DiscoverInput!) {
    discoverSkillLevels(input: $input) {
      skillLevels
    }
  }
`;

export default DISCOVER_SKILL_LEVELS;
