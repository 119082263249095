import { useState } from 'react';

import { Button, Modal, Pressable, Stack, Text, TextInput, View } from '../../base-components';
import { isMobile } from '../../utilities';

const PromoCodeModal = ({
  onApplyPromoCode,
  appliedPromoCodeString,
}: {
  onApplyPromoCode: (promoCode: string) => void;
  appliedPromoCodeString: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [innerPromoCode, setInnerPromoCode] = useState(appliedPromoCodeString);

  return (
    <>
      <Pressable
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        onPress={() => setIsOpen(true)}
        py={1}
      >
        <Text isLink fontSize={13}>
          Apply a Promo Code
        </Text>
      </Pressable>
      <Modal
        useRNModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={isMobile ? undefined : 'lg'}
      >
        <Modal.KeyboardAwareContent>
          <Modal.Header>Apply Promo Code</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body flex={1}>
            <Stack space={2}>
              {/* mobile needs the set width as modal only takes up content width */}
              <View style={{ height: 45, ...(isMobile ? { width: 330 } : {}) }}>
                <TextInput
                  placeholder="Enter promo code"
                  value={innerPromoCode}
                  onChangeText={setInnerPromoCode}
                />
              </View>
              <Button.FooterGroup>
                <Button.ClearCancel
                  onPress={() => {
                    if (appliedPromoCodeString) {
                      setInnerPromoCode('');
                      onApplyPromoCode('');
                      return;
                    }

                    setIsOpen(false);
                  }}
                >
                  {appliedPromoCodeString ? 'Clear' : 'Cancel'}
                </Button.ClearCancel>
                <Button.Apply
                  onPress={() => {
                    onApplyPromoCode(innerPromoCode);
                    setIsOpen(false);
                  }}
                  isDisabled={!innerPromoCode}
                >
                  Apply
                </Button.Apply>
              </Button.FooterGroup>
            </Stack>
          </Modal.Body>
        </Modal.KeyboardAwareContent>
      </Modal>
    </>
  );
};

export default PromoCodeModal;
