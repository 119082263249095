import { type TypedDocumentNode, gql } from '@apollo/client';
import { StripeMembershipIntervalEnum, StripeSubscriptionStatusEnum } from '../../../constants';

type MembershipDetails = {
  stripeSubscriptionId: string;
  stripePlanId: string;
  planStartsAt: string;
  planExpiresAt: string;
  status: StripeMembershipIntervalEnum;
  billingCycleAnchor: string;
  userHomeOrganization: string | undefined;
  plan: {
    id: string;
    interval: StripeSubscriptionStatusEnum;
  };
  metadata: {
    organization?: string;
    organizationId?: string;
  };
};

type UserMembershipStatusResponse = {
  userIsActiveVoloPassMember: boolean;
  userHasPastVoloPassMembership: boolean;
  userIsActiveVoloFitnessMember: boolean;
  userHasPastVoloFitnessMembership: boolean;
};

type VoloPassMembershipStatusData = {
  userHasPastVoloPassMembership: boolean;
  userIsActiveVoloPassMember: boolean;
  activeVoloPassMembership: MembershipDetails;
  pastVoloPassMembership: MembershipDetails;
};

type VoloFitnessMembershipStatusData = {
  userHasPastVoloFitnessMembership: boolean;
  userIsActiveVoloFitnessMember: boolean;
  activeVoloFitnessMembership: MembershipDetails;
  pastVoloFitnessMembership: MembershipDetails;
};

export const GET_USER_MEMBERSHIP_STATUS: TypedDocumentNode<UserMembershipStatusResponse> = gql`
  query getUserMembershipStatus {
    userIsActiveVoloPassMember
    userHasPastVoloPassMembership
    userIsActiveVoloFitnessMember
    userHasPastVoloFitnessMembership
  }
`;

export const GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS: TypedDocumentNode<VoloPassMembershipStatusData> = gql`
  query getVoloPassMembershipStatus {
    userHasPastVoloPassMembership
    userIsActiveVoloPassMember
    activeVoloPassMembership {
      stripeSubscriptionId
      stripePlanId
      planStartsAt
      planExpiresAt
      status
      billingCycleAnchor
      userHomeOrganization
      plan {
        id
        interval
      }
      metadata {
        organization
        organizationId
      }
    }
    pastVoloPassMembership {
      stripeSubscriptionId
      stripePlanId
      planStartsAt
      planExpiresAt
      status
      billingCycleAnchor
      plan {
        id
        interval
      }
      metadata {
        organization
        organizationId
      }
    }
  }
`;

export const GET_USER_VOLO_FITNESS_MEMBERSHIP_DETAILS: TypedDocumentNode<VoloFitnessMembershipStatusData> = gql`
  query getVoloFitnessMembershipStatus {
    userHasPastVoloFitnessMembership
    userIsActiveVoloFitnessMember
    activeVoloFitnessMembership {
      stripeSubscriptionId
      stripePlanId
      planStartsAt
      planExpiresAt
      status
      billingCycleAnchor
      userHomeOrganization
      plan {
        id
        interval
      }
      metadata {
        organization
        organizationId
      }
    }
    pastVoloFitnessMembership {
      stripeSubscriptionId
      stripePlanId
      planStartsAt
      planExpiresAt
      status
      billingCycleAnchor
      plan {
        id
        interval
      }
      metadata {
        organization
        organizationId
      }
    }
  }
`;
