import { gql, type TypedDocumentNode } from '@apollo/client';

type TeamRSVPQueryData = {
  teamRsvpStatus: {
    teamRsvps: {
      fullName: string;
      displayNameTeams: string;
      response?: string;
      picture: string;
      isDropin?: boolean;
      registrantId: string;
      gender: string;
      userId: string;
      _id?: string;
    }[];
    criteria: {
      womenNeeded: number;
      playersNeeded: number;
      womenCriteria: boolean;
      playersCriteria: boolean;
    };
  };
};

type TeamRSVPQueryInput = {
  input: {
    teamId: string;
    gameId: string;
  };
};

export const TEAM_RSVP_QUERY: TypedDocumentNode<TeamRSVPQueryData, TeamRSVPQueryInput> = gql`
  query teamRsvpStatus($input: TeamRsvpStatusInput!) {
    teamRsvpStatus(input: $input) {
      teamRsvps {
        fullName
        displayNameTeams
        response
        picture
        isDropin
        registrantId
        gender
        userId
        _id
      }
      criteria {
        womenNeeded
        playersNeeded
        womenCriteria
        playersCriteria
      }
    }
  }
`;

type GetGameDropInSlotsData = {
  dropInSlotsByGameId: {
    slots: {
      _id: string;
      gender: string;
      holdExpiration?: string; // ISO string
      teamId: string;
      rsvpId?: string;
      closedBy?: string;
      note?: string;
    }[];
  };
};

type GetGameDropInSlotsInput = {
  gameId: string;
};

export const GET_GAME_DROP_IN_SLOTS: TypedDocumentNode<
  GetGameDropInSlotsData,
  GetGameDropInSlotsInput
> = gql`
  query DropInSlotsByGameId($gameId: ID!) {
    dropInSlotsByGameId(gameId: $gameId) {
      slots {
        _id
        gender
        holdExpiration
        teamId
        rsvpId
        closedBy
        note
      }
    }
  }
`;

type GameData = {
  game: {
    _id: string;
    is_tournament?: boolean;
    start_time: string;
    end_time: string;
  };
};

type GameInput = {
  id: string;
};

export const GAME_DATA: TypedDocumentNode<GameData, GameInput> = gql`
  query Game($id: ID!) {
    game(_id: $id) {
      _id
      is_tournament
      start_time
      end_time
    }
  }
`;
