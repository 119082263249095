import { gql, type TypedDocumentNode } from '@apollo/client';

interface AddEmailTemplatesData {
  addTemplateEmail: {
    organization: {
      _id: string;
    };
  };
}

interface AddEmailTemplatesVars {
  input: {
    template: {
      html: string;
      subject: string;
    };
    organizationId: string;
  };
}

const ADD_EMAIL_TEMPLATES: TypedDocumentNode<AddEmailTemplatesData, AddEmailTemplatesVars> = gql`
  mutation addTemplateEmail($input: TemplateInputMutation!) {
    addTemplateEmail(input: $input) {
      organization {
        _id
      }
    }
  }
`;

export default ADD_EMAIL_TEMPLATES;
