import { type ComponentProps, type FC } from 'react';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons/faArrowUpFromBracket';
import { pluralize } from '../../../utilities';
import { useClipboardV2 } from '../../../hooks';
import { Button, Column, FontAwesomeIcon, Row, Text, View, Stack } from '../../../base-components';
import { useTheme } from '../../../theme';

type Props = {
  /** The user's referral code */
  referralCodeStr: string;
  /** The total amount of referral credits the user has earned */
  totalReferralRewards: number;
  /** The maximum number of times a user can use the referral code per month */
  maxNumberOfReferralsPerUserPerMonth: number;
  /** Callback that is executed when the share button is pressed */
  onPressShare: VoidFunction;
  /** Props to pass to the container view */
  _container?: ComponentProps<typeof Column>;
  /** Props to pass to the share button */
  _shareButton?: ComponentProps<typeof Button>;
  /**
   * Override the background color
   * @default `colors.background`
   * */
  backgroundColor?: ComponentProps<typeof Column>['backgroundColor'];
};

const ShareReferralCode: FC<Props> = ({
  _container,
  _shareButton,
  backgroundColor,
  referralCodeStr,
  totalReferralRewards,
  maxNumberOfReferralsPerUserPerMonth,
  onPressShare,
}) => {
  const { colors, dark } = useTheme();

  const { hasCopied, onCopy } = useClipboardV2();

  const handleCopyCode = async () => {
    await onCopy(referralCodeStr);
  };

  const background = backgroundColor ?? colors.background;

  const totalReferralRewardsDollars = Math.round(totalReferralRewards / 100);

  return (
    <Column
      px={8}
      py={12}
      borderColor="gray.100"
      borderWidth={1}
      backgroundColor={background}
      alignItems="center"
      position="relative"
      {..._container}
    >
      {/* How much you've earned text */}
      <Row alignItems="center" backgroundColor={background} position="absolute" top={-12} px={2}>
        <Text fontSize="sm">How much you&apos;ve earned so far</Text>
        <View backgroundColor="primary.200" borderRadius="md" ml={1} px={1}>
          <Text bold color={dark ? 'primary.500' : 'white.600'} fontSize="md">
            ${totalReferralRewardsDollars}
          </Text>
        </View>
      </Row>

      {/* Row with code and copy button */}
      <Row
        backgroundColor={dark ? 'gray.800' : 'gray.100'}
        w="full"
        alignItems="center"
        borderRadius="lg"
      >
        <Text flex={1} textAlign="center">
          {referralCodeStr}
        </Text>
        <Button
          h={8}
          w={32}
          // The stack height is explicitly set to 8 to match the height of the button.
          // Otherwise native-base does not adjust the height of the inner button content, and the text gets cut off.
          _stack={{ h: 8 }}
          colorScheme="lightBlue"
          onPress={handleCopyCode}
          _text={{ fontSize: 'sm', bold: false }}
          leftIcon={<FontAwesomeIcon icon={faCopy} />}
        >
          {hasCopied ? 'Copied!' : 'Copy Code'}
        </Button>
      </Row>

      {/* Helper text */}
      <Stack>
        <Text bold textAlign="center" fontSize="sm" mt={5}>
          Copy and share your unique referral code!
        </Text>
        <Text fontSize="sm" textAlign="center">
          This code may be applied up to {maxNumberOfReferralsPerUserPerMonth}{' '}
          {pluralize('time', maxNumberOfReferralsPerUserPerMonth)} per month.
        </Text>
      </Stack>

      {/* Share button */}
      <Button
        mt={5}
        w="full"
        h={10}
        onPress={onPressShare}
        colorScheme="lightBlue"
        _text={{ fontSize: 'sm', bold: false }}
        leftIcon={<FontAwesomeIcon icon={faArrowUpFromBracket} />}
        // All of this is to center align the text in this button
        _stack={{ flex: 1, flexDir: 'row', justifyContent: 'center' }}
        {..._shareButton}
      >
        Share
      </Button>
    </Column>
  );
};

export default ShareReferralCode;
