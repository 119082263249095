import type { GridItemProps, IGrid } from './types';
import { FlatListGrid, ScrollViewGrid, MapGrid } from './grids';
import { GridItem } from './components';

const Grid = <T extends GridItemProps = GridItemProps>({
  data = [],
  RenderGrid = FlatListGrid,
  RenderItem,
  scrollEnabled = false,
}: IGrid<T>) => <RenderGrid data={data} RenderItem={RenderItem} scrollEnabled={scrollEnabled} />;

Grid.FlatListGrid = FlatListGrid;
Grid.ScrollViewGrid = ScrollViewGrid;
Grid.MapGrid = MapGrid;
Grid.GridItem = GridItem;

export default Grid;
