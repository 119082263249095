import Svg, { Defs, RadialGradient as SVGRadialGradient, Rect, Stop } from 'react-native-svg';

interface Color {
  offset: string;
  color: string;
  opacity: string;
}

const RadialGradient = ({
  id = 'grad',
  colorList,
  x,
  y,
  rx,
  ry,
}: {
  /**
   * Must be unique if multiple RadialGradients are used in the same component, otherwise
   * they'll all have the same colors.
   */
  id?: string;
  colorList: Color[];
  x: string;
  y: string;
  rx: string;
  ry: string;
}) => {
  return (
    <Svg height="100%" width="100%">
      <Defs>
        <SVGRadialGradient id={id} cx={x} cy={y} rx={rx} ry={ry} gradientUnits="userSpaceOnUse">
          {colorList.map((value, index) => (
            <Stop
              // eslint-disable-next-line react/no-array-index-key
              key={`RadialGradientItem_${index}`}
              offset={value.offset}
              stopColor={value.color}
              stopOpacity={value.opacity}
            />
          ))}
        </SVGRadialGradient>
      </Defs>
      <Rect x="0" y="0" width="100%" height="100%" fill={`url(#${id})`} />
    </Svg>
  );
};

export default RadialGradient;
