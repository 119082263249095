import { type ComponentProps } from 'react';
import { ActivityIndicator } from 'react-native';

import { View } from '../../../base-components';
import useMobileSizing from '../helpers/useMobileSizing';

interface DiscoverLoadingProps extends ComponentProps<typeof View> {}

const DiscoverLoading = ({ ...props }: DiscoverLoadingProps) => {
  const mobileSizing = useMobileSizing();

  return (
    <View
      _web={{ shadow: 2 }}
      alignItems="center"
      justifyContent="center"
      borderRadius={mobileSizing ? 13 : 4}
      marginY={mobileSizing ? 2.5 : 15}
      marginX={mobileSizing ? 0 : 15}
      minHeight={mobileSizing ? 70 : 100}
      {...props}
    >
      <ActivityIndicator />
    </View>
  );
};

export default DiscoverLoading;
