import { type TypedDocumentNode, gql } from '@apollo/client';

type Data = {
  venue: {
    _id: string;
    image?: {
      url?: string;
    };
    rentals: {
      _id: string;
      images: string[];
    }[];
  };
};

type Input = {
  venueId: string;
};

const GET_VENUE_IMAGES: TypedDocumentNode<Data, Input> = gql`
  query venue($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      image {
        url
      }
      rentals {
        _id
        images
      }
    }
  }
`;

export default GET_VENUE_IMAGES;
