import { Button, type ButtonProps, Stack } from '../../../../../base-components';
import { useTheme } from '../../../../../theme';
import {
  baseBorderRadius,
  baseBorderWidth,
  basePadding,
  baseSelectorHeight,
  baseSelectorWidth,
  selectedColorScheme,
  selectedTextColor,
} from './DateSelector.constants';
import SelectorText from '../SelectorText';

export interface DateSelectorItemProps extends ButtonProps {
  /**
   * Adjust the styles with lightBlue background
   * - text color is handled by parent component as it just renders the children which could be any component
   */
  isSelected: boolean;
}

export const baseSelectorItemProps: Partial<ButtonProps> = {
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: baseBorderRadius,
  borderWidth: baseBorderWidth,
  height: baseSelectorHeight,
  padding: basePadding,
};

/**
 * Component for rendering a selectable item with preset UI styles to match that needed in the rental registration flow
 */
const DateSelectorItem = ({ children, isSelected, isDisabled, ...rest }: DateSelectorItemProps) => {
  const { colors } = useTheme();

  const normalVariant: ButtonProps['variant'] = 'outline';
  const selectedVariant: ButtonProps['variant'] = 'solid';
  const disabledVariant: ButtonProps['variant'] = 'ghost';

  const inUseVariant = isSelected ? selectedVariant : normalVariant;
  const variant = isDisabled ? disabledVariant : inUseVariant;

  const selectedProps: Partial<ButtonProps> = {
    borderWidth: isSelected ? 0 : undefined,
    variant,
    colorScheme: isSelected ? selectedColorScheme : undefined,
    _text: { color: isSelected ? selectedTextColor : undefined },
  };

  return (
    <Button
      borderColor={colors.border}
      isDisabled={isDisabled}
      {...baseSelectorItemProps}
      w={[
        baseSelectorWidth - 10,
        baseSelectorWidth - 10,
        baseSelectorWidth - 10,
        baseSelectorWidth - 5,
        baseSelectorWidth,
      ]}
      {...selectedProps}
      {...rest}
    >
      <Stack space={1} justifyContent="center" alignItems="center">
        {children}
      </Stack>
    </Button>
  );
};

export default DateSelectorItem;

DateSelectorItem.Text = SelectorText;
