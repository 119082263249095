import { useEffect } from 'react';
import { Button } from '@rivallapp/native-base';

import { Modal, Stack, Text } from '../../base-components';
import useModalAlertStore, { initialModalState } from './store/useModalAlertStore';
import { RootModalId } from './store/modalAlert.types';

interface ModalAlertComponentProps {
  /**
   * A unique identifier for the `ModalAlert` component
   * - used to be able to render more than one `ModalAlertComponent` if needed
   */
  id: string;
}

const { close } = useModalAlertStore.getState();

/**
 * A custom implementation of the `Modal` component to work similarly to the `Alert` component from `react-native`
 * - This component is called imperatively using the `ModalAlert` object containing the `alert` and `close` methods
 */
const ModalAlertComponent = ({ id }: ModalAlertComponentProps) => {
  const {
    isOpen,
    title,
    message,
    buttons = [],
    modalProps,
  } = useModalAlertStore(store => store.alerts[id] || initialModalState);
  const { onClose: onModalCloseCallback, closeOnOverlayClick = true, ...rest } = modalProps || {};

  const handleClose = () => {
    if (onModalCloseCallback) {
      onModalCloseCallback();
    }

    close(id);
  };

  useEffect(
    function initModalAlert() {
      if (id === RootModalId) return;

      if (!useModalAlertStore.getState().alerts[id]) {
        useModalAlertStore.setState(state => ({
          alerts: {
            ...state.alerts,
            [id]: initialModalState,
          },
        }));
      }
    },
    [id]
  );

  return (
    <Modal
      isOpen={isOpen}
      nativeID={id}
      useRNModal
      onClose={handleClose}
      closeOnOverlayClick={closeOnOverlayClick}
      {...rest}
    >
      <Modal.Content>
        <Modal.Header>{title}</Modal.Header>
        {closeOnOverlayClick && <Modal.CloseButton />}
        <Modal.Body>
          <Stack space={4}>
            <Text>{message}</Text>
          </Stack>
        </Modal.Body>
        {Boolean(buttons.length) && (
          <Modal.Footer padding={2}>
            {buttons.map(({ text, onPress, utilProps, textColor, variant = 'default' }, index) => (
              <Button
                // eslint-disable-next-line react/no-array-index-key
                key={`${text}-${index}`}
                minWidth={20}
                height={10}
                variant="link"
                onPress={() => {
                  if (onPress) {
                    onPress();
                  }

                  if (variant !== 'no-dismiss') {
                    close(id);
                  }
                }}
                {...utilProps}
                _text={{ color: textColor }}
              >
                {text}
              </Button>
            ))}
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ModalAlertComponent;
