import { ApolloClient, ApolloLink, InMemoryCache, } from '@apollo/client';
import createLink from './links/createLink.js';
var RoleEnum;
(function (RoleEnum) {
    RoleEnum["ADMIN"] = "ADMIN";
    RoleEnum["OWNER"] = "OWNER";
    RoleEnum["STAFF"] = "STAFF";
    RoleEnum["PLAYER"] = "PLAYER";
    RoleEnum["MEMBER"] = "MEMBER";
})(RoleEnum || (RoleEnum = {}));
/**
 * Caches the client and token to prevent re-creating the client on every call.
 * This is a temporary measure only for host-app and will be removed once the migration
 * is complete.
 */
let cachedClient = null;
let cachedToken = null;
/**
 * Returns an ApolloClient instance with the provided configuration. Fields in
 * `clientConfig` are required. `options` can be passed as well to specify any
 * platform-specific behavior if needed, such as adding a `name` or
 * a custom link to be prepended to the default link chain.
 *
 * Default link chain:
 * - Error simulation link (only in development)
 * - Auth link
 * - Split WS and HTTP to handle subscriptions and queries/mutations
 *
 * Cache is omitted from the options object to enforce only having one cache configuration,
 * but we can revisit this if needed.
 */
const configureClient = (clientConfig, options) => {
    const authToken = clientConfig.getAuthToken();
    if (cachedClient && authToken === cachedToken)
        return cachedClient;
    const splitLink = createLink(clientConfig);
    const { link: customLink, ...rest } = options || {};
    const fullLink = customLink ? ApolloLink.from([customLink, splitLink]) : splitLink;
    const client = new ApolloClient({
        link: fullLink,
        cache: new InMemoryCache(),
        ...rest,
    });
    cachedClient = client;
    cachedToken = authToken;
    return client;
};
export default configureClient;
