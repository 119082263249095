import {
  MainTabEnum,
  ClassesFilterList,
  EventsFilterList,
  EventsAndClasses,
  type TEventClass,
} from '../../../constants';

/* Note to developer: this is a request from the business to filter certain activity types into
the Events & Classes views */

const applyViewFilters = (view: string, unfilteredList: any) => {
  if (view === MainTabEnum.EVENTS) {
    return EventsFilterList;
  }
  if (view === MainTabEnum.CLASSES) {
    return ClassesFilterList;
  }
  return unfilteredList;
};

export const applySportsFilter = (sports: string[]) =>
  sports.filter(sport => !EventsAndClasses.includes(sport as TEventClass));

export default applyViewFilters;
