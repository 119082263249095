export const SizingEnum = Object.freeze({
  SMALL: 'Small',
  MEDIUM: 'Medium',
  LARGE: 'Large',
  X_LARGE: 'X-Large',
  XX_LARGE: 'XX-Large',
});

export const SizingNameEnum = Object.freeze({
  [SizingEnum.SMALL]: 's',
  [SizingEnum.MEDIUM]: 'm',
  [SizingEnum.LARGE]: 'l',
  [SizingEnum.X_LARGE]: 'xl',
  [SizingEnum.XX_LARGE]: 'xxl',
});
