import React, { useMemo, useCallback } from 'react';
import { ActivityIndicator } from 'react-native';
import { useQuery } from '@apollo/client';

import { View, Text } from '../../../base-components';
import { CustomCheckbox } from '../../../custom-components';
import DISCOVER_TAGS from '../graphql/DISCOVER_TAGS';
import styles from '../styles';

type ProgramTagFilterType = {
  onPress: (newTag: string) => void | undefined;
  cityName: string | null;
  selectedTags: string[];
};

type DiscoverTagsInput = {
  input: {
    cityName: string;
  };
};

type ProgramTag = {
  _id: string;
  name: string;
};

type DiscoverTagsResult = {
  discoverProgramTags: {
    programTags: ProgramTag[];
  };
};

const ProgramTagFilter = ({ onPress, cityName, selectedTags = [] }: ProgramTagFilterType) => {
  const { data, loading, error } = useQuery<DiscoverTagsResult, DiscoverTagsInput>(DISCOVER_TAGS, {
    skip: !cityName,
    variables: { input: { cityName: cityName! } },
  });

  const onPressTag = useCallback(
    (newTag: string) => {
      if (onPress) onPress(newTag);
    },
    [onPress]
  );

  const programTagOptions = useMemo(() => {
    const allTags = data?.discoverProgramTags?.programTags || [];
    return allTags.map(tag => (
      <CustomCheckbox
        key={tag._id}
        value={tag._id}
        label={tag.name}
        onPress={onPressTag}
        checked={selectedTags.includes(tag._id)}
      />
    ));
  }, [data?.discoverProgramTags?.programTags, onPressTag, selectedTags]);

  if (!data?.discoverProgramTags?.programTags && loading) return <ActivityIndicator />;
  if (error)
    return (
      <View>
        <Text>{String(error)}</Text>
      </View>
    );

  return <View style={styles.container}>{programTagOptions}</View>;
};

export default ProgramTagFilter;
