/* eslint-disable indent */
import { type EdgeInsets } from 'react-native-safe-area-context';
import type { PaddingStylesType, SafeAreaArrTypes, IViewProps } from '../types';

const defaultPadding = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0,
  paddingLeft: 0,
};

const validValues = ['top', 'bottom', 'left', 'right'];
const checkForSafeValues = (withSafeArea: SafeAreaArrTypes): boolean => {
  if (withSafeArea.every(value => validValues.includes(value))) {
    return true;
  }
  console.warn(
    'wrong value passed to "withSafeArea" prop, dismissing the withSafeArea prop and applying default padding'
  );
  return false;
};
/**
 * Helper function to match the safe area insets to the padding styles.
 * @param insets The safe area insets.
 * @param withSafeArea Whether to add padding to account for safe area insets
 * @returns The padding styles to be applied to the view.
 */
const matchSafeAreaInsets = (
  insets: EdgeInsets,
  withSafeArea: IViewProps['withSafeArea']
): PaddingStylesType | object => {
  if (typeof withSafeArea === 'boolean') {
    return withSafeArea
      ? {
          paddingTop: insets.top || 0,
          paddingBottom: insets.bottom || 0,
          paddingRight: insets.right || 0,
          paddingLeft: insets.left || 0,
        }
      : defaultPadding;
  }
  if (Array.isArray(withSafeArea) && checkForSafeValues(withSafeArea)) {
    return withSafeArea.reduce((padding, area) => {
      const paddingKey = `padding${area.charAt(0).toUpperCase() + area.slice(1)}`;
      return {
        ...padding,
        [paddingKey]: insets[area] || 0,
      };
    }, defaultPadding);
  }

  return defaultPadding;
};

export default matchSafeAreaInsets;
