import { gql, type TypedDocumentNode } from '@apollo/client';
import { type DiscoverFilterVariables } from './types';

type DiscoverVenuesData = {
  discoverVenues: {
    venues: {
      _id: string;
      shorthandName: string;
    }[];
  };
};

export const DISCOVER_VENUES: TypedDocumentNode<DiscoverVenuesData, DiscoverFilterVariables> = gql`
  query discoverVenues($input: DiscoverInput!) {
    discoverVenues(input: $input) {
      venues {
        _id
        shorthandName
      }
    }
  }
`;

type DiscoverRentalVenuesData = {
  discoverRentalVenues: {
    venues: {
      _id: string;
      shorthandName: string;
    }[];
  };
};

export const DISCOVER_RENTAL_VENUES: TypedDocumentNode<
  DiscoverRentalVenuesData,
  DiscoverFilterVariables
> = gql`
  query DiscoverRentalVenues($input: DiscoverRentalsInput!) {
    discoverRentalVenues(input: $input) {
      venues {
        _id
        shorthandName
      }
    }
  }
`;
