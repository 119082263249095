import { type FC, useState } from 'react';

import { Text, Box, Select, FormControl } from '../../../base-components';
import { type CollapseFormInputProps } from './types';
import { useCollapseFormContext } from '../CollapseFormContext';

export interface SingleSelectProps extends CollapseFormInputProps {
  options: ({ value: string; label: string } | string)[];
  defaultValue?: { value: string; label: string } | string;
  onChange?: (arg: { value: string; label: string } | string) => void;
}

const SingleSelect: FC<SingleSelectProps> = ({
  isRequired,
  label,
  onChange = () => {},
  defaultValue,
  options,
}) => {
  const { view } = useCollapseFormContext();
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const handleSelect = (newVal: string) => {
    const selectedValue = options.find(option => {
      if (typeof option === 'object') return option.value === newVal;
      return option === newVal;
    });
    if (!selectedValue) return;

    setCurrentValue(selectedValue);
    onChange(selectedValue);
  };

  switch (view) {
    case 'write':
      return (
        <FormControl isRequired={isRequired}>
          <FormControl.Label>{label}</FormControl.Label>
          <Select
            accessibilityLabel="label"
            defaultValue={typeof currentValue === 'object' ? currentValue?.value : currentValue}
            onValueChange={newVal => handleSelect(newVal)}
          >
            {options.map(option => {
              const isObject = typeof option === 'object';
              return (
                <Select.Item
                  key={isObject ? option.value : option}
                  value={isObject ? option.value : option}
                  label={isObject ? option.label : option}
                />
              );
            })}
          </Select>
        </FormControl>
      );
    case 'read':
      return (
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Text fontWeight="bold">{label} </Text>
          <Text>{typeof currentValue === 'object' ? currentValue?.label : currentValue}</Text>
        </Box>
      );
    default:
      return null;
  }
};

export default SingleSelect;
