import { type FC } from 'react';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';

import { Text, View, FontAwesomeIcon } from '../../../../base-components';
import type { CapacityModule } from '../../ProgramCard/types';
import useMobileSizing from '../../helpers/useMobileSizing';

const CapacitySection: FC<Omit<CapacityModule, 'type'>> = ({ capacity }) => {
  const mobileSizing = useMobileSizing();
  return (
    <View
      flexDirection="row"
      flex={1}
      alignItems="center"
      maxWidth={mobileSizing ? 170 : 250}
      minHeight={mobileSizing ? 18 : 26}
    >
      <Text
        marginLeft={2.5}
        numberOfLines={1}
        fontSize={14}
        letterSpacing={-0.5}
        maxWidth={mobileSizing ? 160 : 220}
      >
        {capacity}
      </Text>
      <FontAwesomeIcon icon={faUser} ml={2} size={4} />
    </View>
  );
};

export default CapacitySection;
