import { graphql } from 'graphql-schema';

// eslint-disable-next-line import/prefer-default-export
export const LEAGUE_SEARCH = graphql(`
  query LeagueSearch(
    $limit: Int = 20
    $offset: Int = 0
    $where: leagues_bool_exp = {}
    $order: [leagues_order_by!] = [{ created_at: desc_nulls_last }]
  ) {
    leagues(limit: $limit, offset: $offset, where: $where, order_by: $order) {
      _id
      name
      archived
      sport
      season
    }
    leagues_aggregate {
      aggregate {
        count
      }
    }
  }
`);
