import React, { useState, type ComponentProps } from 'react';
import { faLockOpen } from '@fortawesome/pro-solid-svg-icons/faLockOpen';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import {
  Row,
  Button,
  FontAwesomeIcon,
  type IFontAwesomeIconProps,
  type ButtonProps,
} from '../../base-components';
import { MemberPerksModal } from '../MembershipCard/components';
import { MembershipEnum } from '../../constants';

export interface MemberExclusiveProps {
  onPress: () => void;
  hasVoloPass: boolean;
  membershipStatus: string;
  _iconProps?: Omit<IFontAwesomeIconProps, 'icon'>;
  _containerProps?: ComponentProps<typeof Row>;
  _buttonProps?: ButtonProps;
}

const MemberExclusive: React.FC<MemberExclusiveProps> = ({
  onPress,
  hasVoloPass,
  membershipStatus,
  _iconProps,
  _buttonProps,
  _containerProps,
}) => {
  const [isPerksVisible, setIsPerksVisible] = useState(false);

  const handleButtonPress = () => {
    if (hasVoloPass === true) {
      setIsPerksVisible(!isPerksVisible); // Toggle the visibility state
    } else {
      onPress();
    }
  };

  return (
    <Row alignItems="center" {..._containerProps}>
      <FontAwesomeIcon icon={hasVoloPass ? faLockOpen : faLock} color="gray.300" {..._iconProps} />
      <Button onPress={handleButtonPress} variant="link" {..._buttonProps}>
        Membership Exclusive
      </Button>
      <MemberPerksModal
        // variant made to be VOLO_PASS only because modal only used for VP
        variant={MembershipEnum.VOLO_PASS}
        onClose={() => setIsPerksVisible(false)}
        isOpen={isPerksVisible}
        membershipStatus={membershipStatus}
      />
    </Row>
  );
};
export default MemberExclusive;
