import { gql, type TypedDocumentNode } from '@apollo/client';
import type { AdminPlayerStrikeCountResponse } from './PLAYER_STRIKE_COUNT';

export type EditStrikeCountInput = {
  input: {
    userId: string;
    desiredStrikeCount: number;
  };
};

export type EditStrikeCountResponse = {
  editStrikeCount: Pick<AdminPlayerStrikeCountResponse['user'], '_id' | 'player_strikes'>;
};

const EDIT_STRIKE_COUNT: TypedDocumentNode<EditStrikeCountResponse, EditStrikeCountInput> = gql`
  mutation EditStrikeCount($input: EditStrikeCountInput!) {
    editStrikeCount(input: $input) {
      _id
      player_strikes {
        count
        nextStrikeWillTriggerFee
      }
    }
  }
`;

export default EDIT_STRIKE_COUNT;
