import { View, type IViewProps } from '../../../../../base-components';

/**
 * Preset container for rental registration sections
 */
const RentalSectionContainer = (props: IViewProps) => {
  return <View my={2} {...props} />;
};

export default RentalSectionContainer;
