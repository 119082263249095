import getProgramTypesFromTabs from './getProgramTypesFromTabs';
import applyViewFilters, { applySportsFilter } from './applyViewFilters';

const discoverHelpers = {
  getProgramTypesFromTabs,
  applyViewFilters,
  applySportsFilter,
};

export default discoverHelpers;
