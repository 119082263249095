/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps } from 'react';
import { type AccessibilityRole, Platform } from 'react-native';
import { Row, Text } from '../../../base-components';
import { useTableContext } from '../context/TableProvider';
import childrenAreRenderable from '../utils/childrenAreRenderable';

/**
 * Custom accessibility role will be passed to the `div` on web, but throws on mobile.
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/cell_role
 * */
const role = Platform.OS === 'web' ? ('cell' as AccessibilityRole) : undefined;

export type TableCellTextProps = ComponentProps<typeof Text>;

export type TableCellProps = TableCellTextProps & {
  /**
   * Props to pass directly to the inner text component.
   *
   * Will only be applied if you provide text as a direct child
   * of the Table.Cell component.
   *
   * @examples
   * This works:
   * ```
   * <Table.Cell textProps={{ fontSize: 36 }}>Hello World</Table.Cell>
   * ```
   *
   * This will not work:
   * ```
   * <Table.Cell fontSize={36}>
   *  Hello World
   * </Table.Cell>
   * ```
   *
   * This will also not work:
   * ```
   * <Table.Cell textProps={{ fontSize: 36 }}>
   *  <Text>Hello World</Text>
   * </Table.Cell>
   * ```
   *
   * To center align cell text:
   * ```
   * <Table.Cell textProps={{ textAlign: 'center' }}>Hello World</Table.Cell>
   * ```
   * */
  textProps?: TableCellTextProps;
};

const TableCell: React.FC<TableCellProps> = ({ children, textProps, ...props }) => {
  const { defaultCellProps, defaultCellTextProps } = useTableContext();

  return (
    <Row accessibilityRole={role} {...defaultCellProps} {...props}>
      {childrenAreRenderable(children) ? (
        children
      ) : (
        <Text {...defaultCellTextProps} {...textProps}>
          {children}
        </Text>
      )}
    </Row>
  );
};

export default TableCell;
