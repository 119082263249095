import { type IModalProps } from '@rivallapp/native-base';
import React, { type ComponentProps } from 'react';
import { Modal, Button, Row } from '../../base-components';
import VoloPassSaleBanner, { type VoloPassSaleBannerProps } from './VoloPassSaleBanner';
import { isMobile, isWeb } from '../../utilities';

export interface VoloPassSaleBannerOverlayProps
  extends Omit<IModalProps, 'children'>,
    VoloPassSaleBannerProps {
  modalContentProps?: ComponentProps<typeof Modal.Content>;
  onPressAddToCart: () => any;
}

/**
 * A wrapper component for `VoloPassSaleBanner` that places it inside of an overlay. Accepts all
 * props that would be needed for `VoloPassSaleBanner` as well as all of the standard `Modal` props.
 */
const VoloPassSaleBannerOverlay: React.FC<VoloPassSaleBannerOverlayProps> = ({
  onPressAddToCart,
  modalContentProps,
  ...props
}) => (
  <Modal colorScheme="primary" size={isMobile ? 'xl' : 'md'} useRNModal={isWeb} {...props}>
    <Modal.Content borderRadius="xl" {...modalContentProps}>
      <Modal.CloseButton />
      <Modal.Body
        p="0"
        // Prevent the overscroll behavior on iOS (this doesn't happen on web)
        overflow="hidden"
        _scrollview={{ bounces: false }}
      >
        <VoloPassSaleBanner {...props}>
          <Row alignItems="center" justifyContent="center">
            <Button
              flex={1}
              mt="2"
              marginX="2"
              onPress={onPressAddToCart}
              type="secondary"
              _text={{ fontSize: 'xs' }}
              minH={30}
              maxH={36}
              maxWidth={325}
              paddingTop="0"
              paddingBottom="0"
            >
              Add to Cart
            </Button>
          </Row>
        </VoloPassSaleBanner>
      </Modal.Body>
    </Modal.Content>
  </Modal>
);

export default VoloPassSaleBannerOverlay;
