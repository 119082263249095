// all discover tab constants
// main
const SPORTS = 'SPORTS';
const EVENTS = 'EVENTS';
const CLASSES = 'CLASSES';
const VOLUNTEER = 'VOLUNTEER';
const COURT_RENTALS = 'COURT RENTALS';
// sub
const DAILY = 'DAILY';
const LEAGUE = 'LEAGUE';
const TOURNAMENT = 'TOURNAMENT';
const SERIES = 'SERIES';
const LIVE = 'LIVE';
const VIRTUAL = 'VIRTUAL';
const BY_SPORT = 'BY SPORT';
const BY_VENUE = 'BY VENUE';

// main enum
export const MainTabEnum = {
  SPORTS,
  EVENTS,
  CLASSES,
  VOLUNTEER,
  COURT_RENTALS,
} as const;

// sub enum
export const SubTabEnum = {
  DAILY,
  LEAGUE,
  TOURNAMENT,
  SERIES,
  VOLUNTEER,
  LIVE,
  VIRTUAL,
  BY_SPORT,
  BY_VENUE,
} as const;

export const DOWSubTabs = [LEAGUE, SERIES];

const DiscoverTabs = {
  SPORTS: {
    tab: 'SPORTS',
    subTabs: [LEAGUE, DAILY, TOURNAMENT],
  },
  EVENTS: {
    tab: 'EVENTS',
    subTabs: [],
  },
  CLASSES: {
    tab: 'CLASSES',
    subTabs: [DAILY, SERIES],
  },
  VOLUNTEER: {
    tab: 'VOLUNTEER',
    subTabs: [],
  },
  'COURT RENTALS': {
    tab: 'COURT RENTALS',
    subTabs: [BY_SPORT, BY_VENUE],
  },
} as const;

type MainTab = keyof typeof DiscoverTabs;
type SubTab = keyof typeof SubTabEnum;

export const isSubTabValid = (
  tab: MainTab | null | undefined,
  subtab: SubTab | null | undefined
) => {
  if (!tab || !subtab) return false;
  // @ts-expect-error - TS is incorrectly inferring the `searchValue` parameter as `never`. Remove if a future version fixes this
  return DiscoverTabs[tab].subTabs.includes(subtab);
};

// all discover tabs
export default DiscoverTabs;
