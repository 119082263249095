import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthStore, { Auth } from '../zustand-stores/AuthStore';
import client from '../apollo/client';
import hasuraClient from '../apollo/hasuraClient';
import { sessionAPI } from '../utils/api';

type Args = {
  /**
   * Path to redirect to after a logging out with `logoutAndRedirect`
   * @default '/'
   */
  redirectPath?: string;
};

/**
 * Provides basic logout functionality through two callbacks:
 *
 * `logoutAndRedirect`
 * logout the current user and redirect them to a different page.
 *
 * `logoutAndRefetch`
 * logout the current user and refetch all active queries.
 */
const useLogout = (args: Args = {}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  /**
   * Logout current user and redirect to a different page
   *
   * Best suited for something like a dashboard where we need to
   * send the user to a different page after logging out.
   * */
  const logoutAndRedirect = useCallback(
    async ({ redirectPath }: Args = {}) => {
      const { isAuthenticatedWithHasura } = useAuthStore.getState();
      try {
        setLoading(true);
        // TODO [MIGRATION]: Remove this once we are fully migrated to Hasura
        if (!isAuthenticatedWithHasura) {
          await sessionAPI.logOut();
        }
        Auth.resetState();
        client.clearStore();
        hasuraClient.clearStore();
        history.push(redirectPath || args.redirectPath || '/');
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [history, args.redirectPath]
  );

  /**
   * Logout current user and refetch all active queries.
   *
   * Best suited for something like discover where we don't need to redirect the
   * user away after logging out.
   *
   * __NOTE: This will throw GraphQL errors if its on an authenticated page since
   * we're re-executing queries that rely on the user being authenticated__
   * */
  const logoutAndRefetch = useCallback(async () => {
    const { isAuthenticatedWithHasura } = useAuthStore.getState();
    try {
      setLoading(true);
      // TODO [MIGRATION]: Remove this once we are fully migrated to Hasura
      if (!isAuthenticatedWithHasura) {
        await sessionAPI.logOut();
      }
      Auth.resetState();
      await client.resetStore();
      await hasuraClient.resetStore();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    logoutAndRedirect,
    logoutAndRefetch,
  };
};

export default useLogout;
