import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgBarOnSite = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M65.5 26.5a9 9 0 0 1-9 9 9 9 0 0 1-3.501-.706A9 9 0 0 1 47.5 26.5a9 9 0 1 1 18 0Z"
    />
    <Path fill="#fff" d="M19 27h37v5l-3.807 3.807L37.5 50.5 22.807 35.807 19 32z" />
    <Path
      stroke="#103b5d"
      d="M37.5 50.5V66m0-15.5L22.807 35.807M37.5 50.5l14.693-14.693M50.5 66H24m-1.193-30.193L19 32v-5h37v5l-3.807 3.807m-29.386 0h29.386"
    />
    <Path fill="#103b5d" d="M23 36h29L37.5 50.5z" />
  </Svg>
);
export default SvgBarOnSite;
