import classNames from 'classnames/bind';
import React, { type PropsWithChildren } from 'react';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface PopupWrapProps {
  close: () => void;
  style?: React.CSSProperties;
  noModal?: boolean;
  className?: string;
  hidden?: boolean;
  removeZIndex?: boolean;
}

const PopupWrap = ({
  close,
  style,
  children,
  noModal = true,
  className = '',
  hidden = true,
  removeZIndex = false,
}: PropsWithChildren<PopupWrapProps>) => {
  if (hidden) return <span />;

  return (
    <span className={cx('popup-wrap')}>
      {noModal ? null : <div className={cx('modal')} onClick={close} role="alertdialog" />}
      <div className={cx('popup-component', !removeZIndex && 'zindex', className)} style={style}>
        <div className={cx('close-x')} onClick={close} role="button" tabIndex={0}>
          𐄂
        </div>
        {children}
      </div>
    </span>
  );
};

export default PopupWrap;
