import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface SponsorBarProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  sponsorBar_response: boolean;
  barOverviewResponses?: string[];
  attendanceEstimate?: string;
  barName?: string;
  barComments?: string;
}

// V2 - enum? maybe all shift log drop-downs?
const attendanceOptions = [
  'No teams came (crickets)',
  'Some teams came (<50%)',
  'Most of the teams came (<75%)',
  'We packed the place (+75%)',
];

const SponsorBar: React.FC<SponsorBarProps> = ({
  sponsorBar_response = false,
  barOverviewResponses = [],
  attendanceEstimate,
  barName,
  barComments,
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Sponsor Bar"
      view="read"
      toggleQuestion="Did you go to the sponsor bar?"
      toggleResponse={sponsorBar_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.MultiSelect label="Bar Overview:" selectedResponses={barOverviewResponses} />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.SingleSelect
          label="Attendance Estimate:"
          options={attendanceOptions}
          defaultValue={attendanceEstimate}
        />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Bar Name:" response={barName} />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Comments/Notes:" response={barComments} />
      </Box>
      {children}
    </CollapseForm>
  );
};

export default SponsorBar;
