import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState, type FC } from 'react';
import { RSVPReminderType, TeamNotificationStatus } from '../../constants';
import { Button, Checkbox, FormControl, Modal, Text, View } from '../../base-components';
import { ModalAlert, ModalAlertComponent } from '../../custom-components';
import { Colors, useTheme } from '../../theme';
import {
  CHECK_TEAM_NOTIFICATIONS_MUTED,
  GET_USER_NOTIFICATION_PREFERENCES,
  SEND_RSVP_REMINDER,
} from './gql';
import CHECK_CAN_SEND_RSVP_REMINDER_TEAM from '../GameRoster/graphql/CHECK_CAN_SEND_RSVP_REMINDER';
import CHECK_CAN_SEND_RSVP_REMINDER_INDIVIDUAL from '../TeamRsvps/graphql/CHECK_CAN_SEND_RSVP_REMINDER';

export enum SupportedNotifications {
  EMAIL = 'email',
  PUSH = 'push',
}

type RSVPReminderProps = {
  /** Required if variant === 'individual' */
  playerId?: string;
  gameId: string;
  teamId: string;
  variant: RSVPReminderType;
  isOpen: boolean;
  onClose: VoidFunction;
};

const RSVPReminderModal: FC<RSVPReminderProps> = ({
  playerId,
  variant,
  isOpen,
  onClose,
  gameId,
  teamId,
}) => {
  const [pushChecked, setPushChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  // Add SMS state here if the business ever decides to support it

  const [headerText, setHeaderText] = useState('Send RSVP Reminder');
  const [confirmText, setConfirmText] = useState('Send');
  const [hideCancel, setHideCancel] = useState(true);

  const [currentStep, setCurrentStep] = useState<0 | 1>(0);

  const { colors } = useTheme();

  const { data: individualData, loading: individualLoading } = useQuery(
    GET_USER_NOTIFICATION_PREFERENCES,
    {
      skip: variant !== RSVPReminderType.INDIVIDUAL,
      variables: {
        input: {
          userId: playerId!,
        },
      },
    }
  );

  const { data: teamData, loading: teamLoading } = useQuery(CHECK_TEAM_NOTIFICATIONS_MUTED, {
    skip: variant !== RSVPReminderType.TEAM || (!pushChecked && !emailChecked),
    variables: {
      input: {
        gameId,
        teamId,
        methods: {
          // Add real variable here if the business ever decides to support SMS RSVP reminders
          sms: false,
          push: pushChecked,
          email: emailChecked,
        },
      },
    },
  });

  const [sendRSVPReminder, { loading: sending }] = useMutation(SEND_RSVP_REMINDER);

  const onSendRSVPReminder = async () => {
    if (variant === RSVPReminderType.INDIVIDUAL) {
      // in spec no confirmation for individual reminders
      await sendRSVPReminder({
        variables: {
          input: {
            gameId,
            teamId,
            methods: {
              sms: false,
              push: pushChecked,
              email: emailChecked,
            },
            reminder_type: RSVPReminderType.INDIVIDUAL,
            recipient_id: playerId,
          },
        },
        refetchQueries: [CHECK_CAN_SEND_RSVP_REMINDER_INDIVIDUAL],
      });
      onClose();
    } else if (variant === 'team' && currentStep === 0) {
      // show next step for team here (IF some / all notifications are muted - if not send mutation)
      if (teamData?.checkTeamNotificationsMuted?.status !== TeamNotificationStatus.NONE) {
        setCurrentStep(1);
        setHideCancel(false);
        setConfirmText('Yes');
        setHeaderText(
          `${
            teamData?.checkTeamNotificationsMuted?.status === TeamNotificationStatus.SOME
              ? 'Some'
              : 'All'
          } Members Are Muted`
        );
      } else {
        await sendRSVPReminder({
          variables: {
            input: {
              gameId,
              teamId,
              methods: {
                sms: false,
                push: pushChecked,
                email: emailChecked,
              },
              reminder_type: RSVPReminderType.TEAM,
            },
          },
          refetchQueries: [CHECK_CAN_SEND_RSVP_REMINDER_TEAM],
        });
        onClose();
      }
    } else if (variant === 'team' && currentStep === 1) {
      await sendRSVPReminder({
        variables: {
          input: {
            gameId,
            teamId,
            methods: {
              sms: false,
              push: pushChecked,
              email: emailChecked,
            },
            reminder_type: RSVPReminderType.TEAM,
          },
        },
      });
      onClose();
    } else {
      console.log('How did you get here??');
    }
  };

  const onToggleReminderType = (values: SupportedNotifications[]) => {
    setEmailChecked(values.includes(SupportedNotifications.EMAIL));
    setPushChecked(values.includes(SupportedNotifications.PUSH));
    // Add SMS state update here if the business ever decides to support it
  };

  const onGoBack = () => {
    if (currentStep !== 1) {
      ModalAlert.alert({
        id: 'rsvp-reminder-alert',
        title: 'Error',
        message:
          'Unexpectedly triggered `onGoBack` function from RSVPReminderModal when not in right step. If this issue persists please reach out to info@volosports.com',
        buttons: [{ text: 'Close', textColor: 'error.400' }],
      });
    }
    setHideCancel(true);
    setConfirmText('Send');
    setHeaderText('Send RSVP Reminder');
    setCurrentStep(0);
  };

  /**
   * If user closes the modal prior to sending a reminder and then reopens,
   * we need to manually reset the state as it's the same component and hasn't unmounted.
   * The purpose of this useEffect is to handle that transition.
   */
  useEffect(() => {
    if (!isOpen) {
      setHideCancel(true);
      setConfirmText('Send');
      setHeaderText('Send RSVP Reminder');
      setCurrentStep(0);
      setEmailChecked(false);
      setPushChecked(false);
    }
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} useRNModal size="lg" onClose={onClose}>
        <Modal.Content>
          <Modal.Header borderBottomWidth={0}>{headerText}</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <View alignItems="center">
              {currentStep === 0 && (
                <>
                  <Text>Select how you would like to send your reminder</Text>
                  <FormControl marginTop="2">
                    <Checkbox.Group
                      flexDir="row"
                      marginX="auto"
                      onChange={onToggleReminderType}
                      accessibilityLabel="RSVP Reminder Notification Type"
                    >
                      <Checkbox
                        value={SupportedNotifications.EMAIL}
                        isChecked={emailChecked}
                        isDisabled={
                          variant === 'individual' &&
                          !individualData?.getUserNotificationPreferences.team.email
                        }
                        _text={{ color: 'gray.700', marginRight: 10 }}
                      >
                        Email
                      </Checkbox>
                      <Checkbox
                        value={SupportedNotifications.PUSH}
                        isChecked={pushChecked}
                        isDisabled={
                          variant === 'individual' &&
                          !individualData?.getUserNotificationPreferences.team.push
                        }
                        _text={{ color: 'gray.700' }}
                      >
                        Push Notification
                      </Checkbox>
                    </Checkbox.Group>
                  </FormControl>
                </>
              )}
              {currentStep === 1 && (
                <Text>
                  {teamData?.checkTeamNotificationsMuted?.status === TeamNotificationStatus.SOME
                    ? '2 or more'
                    : 'All'}{' '}
                  of your team members have their notifications muted and may not see this reminder.
                  Would you still like to send it to the entire team?
                </Text>
              )}
            </View>
          </Modal.Body>
          <Modal.Footer borderTopWidth={0}>
            <View flex={1} minHeight={1} flexDirection="row">
              {!hideCancel && (
                <Button
                  padding={0} // remove NB default
                  margin={0} // remove NB default
                  flex={1}
                  variant="outline"
                  borderColor={colors.primary}
                  borderRadius={8}
                  _text={{
                    fontWeight: 700,
                    fontSize: 18,
                  }}
                  _dark={{
                    _text: {
                      color: Colors.link,
                    },
                  }}
                  paddingTop={2}
                  paddingBottom={2}
                  marginRight={12}
                  marginLeft={0}
                  marginTop={0}
                  onPress={onGoBack}
                >
                  Go Back
                </Button>
              )}
              <Button
                padding={0} // remove NB default
                margin={0} // remove NB default
                flex={1}
                marginRight={0}
                marginLeft={12}
                marginBottom={0}
                {...(hideCancel ? { marginX: 'auto' } : {})}
                paddingTop={2}
                paddingBottom={2}
                onPress={onSendRSVPReminder}
                isDisabled={
                  sending || individualLoading || teamLoading || (!pushChecked && !emailChecked)
                }
              >
                {confirmText}
              </Button>
            </View>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <ModalAlertComponent id="rsvp-reminder-alert" />
    </>
  );
};

export default RSVPReminderModal;
