import { faGem } from '@fortawesome/pro-light-svg-icons/faGem';
import { Row, useColorModeValue } from '@rivallapp/native-base';

import { FontAwesomeIcon } from '../../../../../../base-components';
import { type NavTheme } from '../../../../../../theme';
import DateSelectorItem, { type DateSelectorItemProps } from '../../DateSelectorItem';
import {
  inRangeTextColor,
  selectedTextColor,
  slotSelectorHeight,
  slotSelectorWidth,
} from '../../DateSelectorItem/DateSelector.constants';
import { type RentalTimeSlot } from '../../../steps.types';

interface SlotSelectorItemProps extends Omit<DateSelectorItemProps, 'style'> {
  item: RentalTimeSlot;
  handleSlotSelection: (slot: RentalTimeSlot) => void;
  isBetweenRange: boolean;
  isFirstOrLastSlot: boolean;
  colors: NavTheme['colors'];
}

const SlotSelectorItem = ({
  isSelected,
  handleSlotSelection,
  item,
  isBetweenRange,
  isFirstOrLastSlot,
  colors,
  isDisabled,
  ...rest
}: SlotSelectorItemProps) => {
  const inRangeTColor = useColorModeValue(inRangeTextColor.light, inRangeTextColor.dark);

  const textColor = isSelected ? selectedTextColor : undefined;
  const textColorInRange = isBetweenRange ? inRangeTColor : undefined;
  const iconColor = textColorInRange || textColor || colors.text;

  /**
   * only want to show the base SelectorItem bgColor change for first & last in range
   * - the rest are handled by the inRangeTextColor & the `inner-slot-wrapper`
   */
  const isSelectedAndShowBaseSelectedColors = isSelected && isFirstOrLastSlot;

  return (
    <DateSelectorItem
      onPress={() => handleSlotSelection(item)}
      style={{ width: slotSelectorWidth, height: slotSelectorHeight }}
      isDisabled={isDisabled}
      /*
        only want to show the base SelectorItem bgColor change for first & last in range
        - that allows the inner ranges to be a different color as they are not tagged
        as `selected` in the UI here and handled by the `inner-slot-wrapper`
      */
      isSelected={isSelectedAndShowBaseSelectedColors}
      {...((isSelected || isBetweenRange) && { borderWidth: 0 })}
      {...rest}
    >
      <DateSelectorItem.Text
        isDisabled={isDisabled}
        isSelected={isSelectedAndShowBaseSelectedColors}
        color={isBetweenRange ? inRangeTColor : undefined}
        fontSize={15}
        lineHeight={17}
        fontWeight="semibold"
      >
        {item.label}
      </DateSelectorItem.Text>
      <DateSelectorItem.Text
        isDisabled={isDisabled}
        isSelected={isSelectedAndShowBaseSelectedColors}
        color={isBetweenRange ? inRangeTColor : undefined}
        fontSize={12}
        lineHeight={14}
      >
        {item.ampm}
      </DateSelectorItem.Text>
      {item.isPrimeTime && (
        <Row alignItems="center" space={0.5}>
          <FontAwesomeIcon icon={faGem} color={iconColor} size={2} />
          <DateSelectorItem.Text
            isSelected={isSelectedAndShowBaseSelectedColors}
            color={isBetweenRange ? inRangeTColor : undefined}
            fontSize={8}
            fontWeight={600}
          >
            PRIME TIME
          </DateSelectorItem.Text>
        </Row>
      )}
    </DateSelectorItem>
  );
};

export default SlotSelectorItem;
