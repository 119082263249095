import { type FC } from 'react';

import { openMap } from '../../../utilities';
import { RentalModuleTypes } from '../../../constants';
import { RentalCard } from '../RentalCard';
import useDiscoverRentalDetails from './useDiscoverRentalDetails';

interface DiscoverRentalCardProps {
  id: string;
  viewType: 'sportView' | 'venueView';
  onCardPress: () => void;
  /**
   * Optional message to override the banner message,
   * otherwise the message is fetched from the rental
   */
  overrideBannerMessage?: string;
}

/** Wrapped Rental component. Runs the graphql queries and populates the modules */

const DiscoverRentalCard: FC<DiscoverRentalCardProps> = ({
  id,
  viewType = 'sportView',
  onCardPress,
  overrideBannerMessage,
}) => {
  const { data, loading, error } = useDiscoverRentalDetails({
    id,
    viewType,
  });

  const {
    image,
    neighborhoodName,
    venueName,
    venueAddress,
    placeId,
    lowPrice,
    highPrice,
    rentalActivity,
    guestCapacity,
    availableCourts,
    surfaceType,
    bannerMessage,
    availableSportTypes,
  } = data;

  const formattedLowPrice = lowPrice / 100;

  // valid high price needs to be defined and not equal to the low price
  const isValidHighPrice =
    typeof highPrice === 'number' && highPrice !== 0 && highPrice !== lowPrice;
  const formattedHighPrice = (() => {
    if (isValidHighPrice) {
      return highPrice / 100;
    }

    return null;
  })();

  return (
    <RentalCard
      id={id}
      rentalActivity={rentalActivity}
      onCardPress={onCardPress}
      imageUrl={image}
      neighborhoodVenueModule={{
        type: RentalModuleTypes.NEIGHBORHOOD_VENUE,
        neighborhood: neighborhoodName,
        venue: {
          venueName,
          onPressVenue: () => openMap({ venueAddress, placeId }),
        },
      }}
      pricingModule={{
        type: RentalModuleTypes.RENTAL_PRICING,
        lowPrice: formattedLowPrice,
        highPrice: formattedHighPrice,
      }}
      displayNameModule={
        // For now we only have two options
        viewType === 'sportView'
          ? {
              type: RentalModuleTypes.SPORT_DISPLAY_NAME,
              rentalActivity: {
                name: rentalActivity,
                value: rentalActivity,
                surface: surfaceType,
              },
              venueName,
            }
          : { type: RentalModuleTypes.VENUE_DISPLAY_NAME, venueName }
      }
      detailModule={
        viewType === 'sportView'
          ? {
              type: RentalModuleTypes.CAPACITY_COURTS_NUMBER,
              guestCapacity,
              availableCourts,
              surfaceType,
            }
          : {
              type: RentalModuleTypes.RENTAL_SPORTS,
              availableSportTypes,
            }
      }
      bannerMessage={overrideBannerMessage || bannerMessage}
      loading={loading}
      error={error}
    />
  );
};

export default DiscoverRentalCard;
