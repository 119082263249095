import React, { type ComponentProps } from 'react';
import { Box, FormControl, Radio, Stack, Text } from '../../../base-components';
import LineItem, { type LineItemProps } from './LineItem';
import { usePaymentFormContext } from '../PaymentForm';
import Checkbox from './Checkbox';

interface LineItemGroupItem extends LineItemProps {
  /**
   * The value given here is passed via the `onConfirmationMessagePressed` callback
   */
  value: string;

  /**
   * If true, item will not be selectable.
   */
  isDisabled?: boolean;

  /**
   * If specified, selecting this item will show an additional confirmation checkbox below its
   * corresponding radio button. If this checkbox is not pressed, then the form will be considered
   * invalid. This prop accepts anything that can normally be rendered by React, including strings.
   */
  confirmationMessage?: string | React.ReactNode;

  /**
   * Fires when the confirmation checkbox is pressed
   */
  onConfirmationMessagePress?: (isChecked: boolean) => any;

  /**
   * The current state of the confirmation checkbox
   */
  isConfirmationMessageChecked?: boolean;

  /**
   * Optional message if the form state becomes invalid.
   */
  messageIfFormInvalid?: string;
}

export interface LineItemGroupProps {
  items: LineItemGroupItem[];

  value: LineItemGroupItem['value'];

  /**
   * Specify an item to be selected by default when the component is mounted.
   */
  defaultValue?: LineItemGroupItem['value'];

  /**
   * Function that fires whenever a radio group item is selected.
   */
  onChange?: ComponentProps<typeof Radio.Group>['onChange'];
}

const LineItemGroup: React.FC<LineItemGroupProps> = ({
  items,
  value,
  defaultValue,
  onChange = () => {},
}) => {
  const {
    theme: { colors, dark },
  } = usePaymentFormContext();

  const selectedItem = items.find(item => item.value === value);
  const isFormInvalid =
    !selectedItem ||
    selectedItem.isDisabled ||
    Boolean(selectedItem.confirmationMessage && !selectedItem.isConfirmationMessageChecked);

  return (
    <Box marginBottom={2}>
      <FormControl isRequired isInvalid={isFormInvalid}>
        <Radio.Group
          name="line_item_group"
          accessibilityLabel="Select pricing option."
          defaultValue={defaultValue}
          value={value}
          space="2"
          onChange={onChange}
          // This is necessary to get the items to stretch correctly inside of a radio button.
          alignItems={undefined}
        >
          {items.map(item => (
            <Stack key={item.value} direction="column">
              <Radio
                value={item.value}
                isDisabled={item.isDisabled}
                _stack={{ alignItems: 'flex-start' }}
                colorScheme={dark ? 'white' : 'primary'}
                bg="unset"
              >
                <LineItem {...item} />
              </Radio>
              {!!item.confirmationMessage && value === item.value && (
                <>
                  <Checkbox
                    value={`${item.value} terms`}
                    isChecked={item.isConfirmationMessageChecked}
                    onChange={isSelected => item.onConfirmationMessagePress?.(isSelected)}
                    alignItems="flex-start"
                    marginTop="2"
                  >
                    <Text flex={1} fontSize={12} color={colors.text} lineHeight={18} marginLeft={2}>
                      {item.confirmationMessage}
                    </Text>
                  </Checkbox>
                  {!!item.messageIfFormInvalid && (
                    <FormControl.ErrorMessage>{item.messageIfFormInvalid}</FormControl.ErrorMessage>
                  )}
                </>
              )}
            </Stack>
          ))}
        </Radio.Group>
      </FormControl>
    </Box>
  );
};

export default LineItemGroup;
