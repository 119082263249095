import { type TypedDocumentNode, gql } from '@apollo/client';

type GetRentalPoliciesData = {
  rental: {
    _id: string;
    venue_policies: string;
  };
};

type GetRentalPoliciesInput = {
  rentalId: string;
};

const GET_RENTAL_POLICIES: TypedDocumentNode<GetRentalPoliciesData, GetRentalPoliciesInput> = gql`
  query rental($rentalId: ID!) {
    rental(_id: $rentalId) {
      _id
      venue_policies
    }
  }
`;

export default GET_RENTAL_POLICIES;
