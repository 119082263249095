import { Card } from '../../../../base-components';
import { DailyDonationCTA } from '../../../DailyDonationCTA';
import type { DonationOptionType } from '../../../DonationTiers';
import { useRentalBookingStore } from '../../../RentalsRegistration';

const setDonation = (d: DonationOptionType) => {
  useRentalBookingStore.setState(store => ({
    donation: store?.donation?.id === d.id ? null : d,
  }));
};

const RentalDonation = () => {
  const donation = useRentalBookingStore(store => store.donation);

  return (
    <Card padding={0}>
      <DailyDonationCTA onPressOption={setDonation} selectedOptionId={donation?.id} />
    </Card>
  );
};

export default RentalDonation;
