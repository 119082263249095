/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps } from 'react';
import { type AccessibilityRole, Platform } from 'react-native';
import { Row } from '../../../base-components';

/**
 * Custom accessibility role will be passed to the `div` on web, but throws on mobile.
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/row_role
 * */
const role = Platform.OS === 'web' ? ('row' as AccessibilityRole) : undefined;

export type TableRowProps = ComponentProps<typeof Row>;

const TableRow: React.FC<TableRowProps> = ({ children, ...props }) => (
  <Row accessibilityRole={role} {...props}>
    {children}
  </Row>
);

export default TableRow;
