export const rsvpEnum = Object.freeze({
  YES: 'YES',
  NO: 'NO',
  // 'Maybe' is being deprecated
  MAYBE: 'MAYBE',
});

export const rsvp = {
  [rsvpEnum.YES]: {
    value: 'YES',
    label: 'Yes',
  },
  [rsvpEnum.NO]: {
    value: 'NO',
    label: 'No',
  },
  // 'Maybe' is being deprecated
  [rsvpEnum.MAYBE]: {
    value: 'MAYBE',
    label: 'Maybe',
  },
};

// deprecating 'Maybe' response
export const teamRsvp = Object.freeze({
  YES: 'YES',
  NO: 'NO',
});

export enum TeamRsvpEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum RSVPReminderSenderType {
  ADMIN = 'admin',
  CAPTAIN = 'captain',
  CO_CAPTAIN = 'co_captain',
}

export enum RSVPReminderType {
  INDIVIDUAL = 'individual',
  TEAM = 'team',
}

export default rsvpEnum;
