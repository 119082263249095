import { Suspense, type FC } from 'react';
import { graphql } from 'graphql-schema';
import { useSuspenseQuery } from '@apollo/client';
import { FilterSelect, GenericErrorBoundary, Loading } from '../../components';
import hasuraClient from '../../apollo/hasuraClient';
import type { FilterSelectProps } from '../../components/FilterSelect/types';

const CITY_FILTER_QUERY = graphql(`
  query CityFilterQuery {
    organizations {
      _id
      name
    }
  }
`);

type Props = Pick<FilterSelectProps, 'onChange' | 'miscStyles'> &
  (
    | { cityId?: string; cityName?: never; value?: never }
    | { cityId?: never; cityName?: string; value?: never }
    | { cityId?: never; cityName?: never; value?: { label: string; value: string } }
  );

const CityFilter: FC<Props> = props => {
  const { cityId, cityName, value: valueProp, ...rest } = props;

  const { data } = useSuspenseQuery(CITY_FILTER_QUERY, { client: hasuraClient });

  const cityOptions = data.organizations
    .toSorted((a, b) => a.name.localeCompare(b.name))
    .map(org => ({ value: org._id, label: org.name }));

  let value: FilterSelectProps['value'] | undefined = valueProp;
  if (cityId) value = cityOptions.find(({ value }) => value === cityId);
  if (cityName) value = cityOptions.find(({ label }) => label === cityName);

  return (
    <FilterSelect
      short
      shadow
      icon="pin"
      value={value}
      labelSingular="City"
      options={cityOptions}
      placeholder="Select Location"
      {...rest}
    />
  );
};

export default (props: Props) => (
  <GenericErrorBoundary>
    <Suspense fallback={<Loading />}>
      <CityFilter {...props} />
    </Suspense>
  </GenericErrorBoundary>
);
