import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgReservedTables = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      d="M18.807 26v-.5h-.416l-.076.409zm42.286 0 .493-.086-.073-.414h-.42zm4.29 24.482.493-.086zM18.807 26.5h42.286v-1H18.807zm41.794-.414 4.29 24.483.985-.173-4.29-24.482zM62.429 53.5H25.833v1h36.596zM18.315 25.909 14.17 48.172l.983.183 4.146-22.263zm5.416 25.819-4.432-25.813-.985.17 4.431 25.812zm-6.12.584h5.627v-1H17.61zm-3.442-4.14a3.5 3.5 0 0 0 3.441 4.14v-1a2.5 2.5 0 0 1-2.457-2.957zM25.833 53.5a2.13 2.13 0 0 1-2.102-1.772l-.986.17a3.13 3.13 0 0 0 3.088 2.602zm39.058-2.931a2.5 2.5 0 0 1-2.462 2.931v1a3.5 3.5 0 0 0 3.447-4.104z"
    />
    <Path
      fill="#103b5d"
      d="M61.287 26H19l4.431 25.813A2.63 2.63 0 0 0 26.026 54h36.596a3 3 0 0 0 2.955-3.518z"
    />
    <Path
      fill="#103b5d"
      d="M19 26v-.5h-.593l.1.585zm42.287 0 .492-.086-.073-.414h-.42zm4.29 24.482.492-.086zm-42.146 1.33-.493.085zM19 26.5h42.287v-1H19zm41.794-.414 4.29 24.483.985-.173-4.29-24.482zM62.622 53.5H26.026v1h36.596zm-38.698-1.772-4.431-25.813-.986.17 4.431 25.812zm2.102 1.772a2.13 2.13 0 0 1-2.102-1.772l-.986.17a3.13 3.13 0 0 0 3.088 2.602zm39.058-2.931a2.5 2.5 0 0 1-2.462 2.931v1a3.5 3.5 0 0 0 3.447-4.104z"
    />
    <Path fill="#fff" d="M30 38.5a.5.5 0 0 0 0 1zm25.344 1a.5.5 0 1 0 0-1zM30 39.5h25.344v-1H30z" />
  </Svg>
);
export default SvgReservedTables;
