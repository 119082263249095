import {
  SvgAllTeamsMakePlayoffs,
  SvgBarOnSite,
  SvgBogoPitchers,
  SvgBringYourOwnCase,
  SvgCityWideTournamentFeeder,
  SvgDedicatedSocialHosts,
  SvgDiscountedVoloEvents,
  SvgDjOnSite,
  SvgPetsWelcome,
  SvgFlipcupCountStandings,
  SvgIndoorSpace,
  SvgLicensedReferee,
  SvgLights,
  SvgNearPublicTransportation,
  SvgNewFacility,
  SvgNewPerk,
  SvgOutdoorSpace,
  SvgParkingOnSite,
  SvgPopularVenue,
  SvgReferee,
  SvgReservedTables,
  SvgSponsorBar,
  SvgSponsoredPerks,
  SvgStreetParking,
  SvgTShirts,
  SvgTurf,
  SvgVoloExclusiveBarDeals,
  SvgWalkingDistanceToBar,
  SvgWaterfrontViewToVenue,
} from '..';

export const orderBy = ({ collection, key = '' }: { collection: Object; key?: string }) => {
  const entries = Object.values(collection);
  return entries.sort((a, b) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (b[key] > a[key]) {
      return -1;
    }
    return 0;
  });
};

export enum VenuePerksEnum {
  all_teams_make_playoffs = 'all_teams_make_playoffs',
  bar_on_site = 'bar_on_site',
  bogo_pitchers = 'bogo_pitchers',
  bring_your_own_case = 'bring_your_own_case',
  city_wide_tournament_feeder = 'city_wide_tournament_feeder',
  dedicated_social_host = 'dedicated_social_host',
  discounted_volo_events = 'discounted_volo_events',
  dj_on_site = 'dj_on_site',
  dogs_welcome = 'dogs_welcome',
  flip_cup_count_in_standings = 'flip_cup_count_in_standings',
  free_street_parking = 'free_street_parking',
  indoor_space = 'indoor_space',
  licensed_referee = 'licensed_referee',
  lights = 'lights',
  near_public_transportation = 'near_public_transportation',
  new_facility = 'new_facility',
  new_perk = 'new_perk',
  outdoor_space = 'outdoor_space',
  parking_on_site = 'parking_on_site',
  popular_venue = 'popular_venue',
  referees = 'referees',
  reserved_tables = 'reserved_tables',
  sponsor_bar = 'sponsor_bar',
  sponsor_perks = 'sponsor_perks',
  street_parking = 'street_parking',
  t_shirt = 't_shirt',
  turf = 'turf',
  volo_exclusive_bar_deals = 'volo_exclusive_bar_deals',
  walking_distance_to_bar = 'walking_distance_to_bar',
  waterfront_view = 'waterfront_view',
}

export const VenuePerks = Object.freeze({
  [VenuePerksEnum.all_teams_make_playoffs]: {
    key: VenuePerksEnum.all_teams_make_playoffs,
    featureName: 'All Teams Make Playoffs',
    Icon: SvgAllTeamsMakePlayoffs,
  },
  [VenuePerksEnum.bar_on_site]: {
    key: VenuePerksEnum.bar_on_site,
    featureName: 'Bar On Site',
    Icon: SvgBarOnSite,
  },
  [VenuePerksEnum.bogo_pitchers]: {
    key: VenuePerksEnum.bogo_pitchers,
    featureName: 'BOGO Pitchers',
    Icon: SvgBogoPitchers,
  },
  [VenuePerksEnum.bring_your_own_case]: {
    key: VenuePerksEnum.bring_your_own_case,
    featureName: 'Bring Your Own Case (no glass)',
    Icon: SvgBringYourOwnCase,
  },
  [VenuePerksEnum.city_wide_tournament_feeder]: {
    key: VenuePerksEnum.city_wide_tournament_feeder,
    featureName: 'City Wide Tournament Feeder',
    Icon: SvgCityWideTournamentFeeder,
  },
  [VenuePerksEnum.dedicated_social_host]: {
    key: VenuePerksEnum.dedicated_social_host,
    featureName: 'Dedicated Social Host',
    Icon: SvgDedicatedSocialHosts,
  },
  [VenuePerksEnum.discounted_volo_events]: {
    key: VenuePerksEnum.discounted_volo_events,
    featureName: 'Discounted Volo Events',
    Icon: SvgDiscountedVoloEvents,
  },
  [VenuePerksEnum.dj_on_site]: {
    key: VenuePerksEnum.dj_on_site,
    featureName: 'DJ on Site',
    Icon: SvgDjOnSite,
  },
  [VenuePerksEnum.dogs_welcome]: {
    key: VenuePerksEnum.dogs_welcome,
    featureName: 'Dogs Welcome',
    Icon: SvgPetsWelcome,
  },
  [VenuePerksEnum.flip_cup_count_in_standings]: {
    key: VenuePerksEnum.flip_cup_count_in_standings,
    featureName: 'Flip Cup Count in Standings',
    Icon: SvgFlipcupCountStandings,
  },
  [VenuePerksEnum.free_street_parking]: {
    key: VenuePerksEnum.free_street_parking,
    featureName: 'Free Street Parking',
    Icon: SvgStreetParking,
  },
  [VenuePerksEnum.indoor_space]: {
    key: VenuePerksEnum.indoor_space,
    featureName: 'Indoor Space',
    Icon: SvgIndoorSpace,
  },
  [VenuePerksEnum.licensed_referee]: {
    key: VenuePerksEnum.licensed_referee,
    featureName: 'Licensed Referee',
    Icon: SvgLicensedReferee,
  },
  [VenuePerksEnum.lights]: {
    key: VenuePerksEnum.lights,
    featureName: 'Lights',
    Icon: SvgLights,
  },
  [VenuePerksEnum.near_public_transportation]: {
    key: VenuePerksEnum.near_public_transportation,
    featureName: 'Near Public Transportation',
    Icon: SvgNearPublicTransportation,
  },
  [VenuePerksEnum.new_facility]: {
    key: VenuePerksEnum.new_facility,
    featureName: 'New Facility',
    Icon: SvgNewFacility,
  },
  [VenuePerksEnum.new_perk]: {
    key: VenuePerksEnum.new_perk,
    featureName: 'New Perk',
    Icon: SvgNewPerk,
  },
  [VenuePerksEnum.outdoor_space]: {
    key: VenuePerksEnum.outdoor_space,
    featureName: 'Outdoor Space',
    Icon: SvgOutdoorSpace,
  },
  [VenuePerksEnum.parking_on_site]: {
    key: VenuePerksEnum.parking_on_site,
    featureName: 'Parking on Site',
    Icon: SvgParkingOnSite,
  },
  [VenuePerksEnum.popular_venue]: {
    key: VenuePerksEnum.popular_venue,
    featureName: 'Popular Venue',
    Icon: SvgPopularVenue,
  },
  [VenuePerksEnum.referees]: {
    key: VenuePerksEnum.referees,
    featureName: 'Referees',
    Icon: SvgReferee,
  },
  [VenuePerksEnum.reserved_tables]: {
    key: VenuePerksEnum.reserved_tables,
    featureName: 'Reserved Tables',
    Icon: SvgReservedTables,
  },
  [VenuePerksEnum.sponsor_bar]: {
    key: VenuePerksEnum.sponsor_bar,
    featureName: 'Sponsor Bar',
    Icon: SvgSponsorBar,
  },
  [VenuePerksEnum.sponsor_perks]: {
    key: VenuePerksEnum.sponsor_perks,
    featureName: 'Sponsor Perks',
    Icon: SvgSponsoredPerks,
  },
  [VenuePerksEnum.street_parking]: {
    key: VenuePerksEnum.street_parking,
    featureName: 'Street Parking',
    Icon: SvgStreetParking,
  },
  [VenuePerksEnum.t_shirt]: {
    key: VenuePerksEnum.t_shirt,
    featureName: 'T-Shirt',
    Icon: SvgTShirts,
  },
  [VenuePerksEnum.turf]: {
    key: VenuePerksEnum.turf,
    featureName: 'Turf',
    Icon: SvgTurf,
  },
  [VenuePerksEnum.volo_exclusive_bar_deals]: {
    key: VenuePerksEnum.volo_exclusive_bar_deals,
    featureName: 'Volo Exclusive Bar Deals',
    Icon: SvgVoloExclusiveBarDeals,
  },
  [VenuePerksEnum.walking_distance_to_bar]: {
    key: VenuePerksEnum.walking_distance_to_bar,
    featureName: 'Walking Distance to Bar',
    Icon: SvgWalkingDistanceToBar,
  },
  [VenuePerksEnum.waterfront_view]: {
    key: VenuePerksEnum.waterfront_view,
    featureName: 'Waterfront View',
    Icon: SvgWaterfrontViewToVenue,
  },
});

export const PerkPriorityEnum = Object.freeze({
  flip_cup_count_in_standings: 0,
  dj_on_site: 1,
  bar_on_site: 2,
  turf: 3,
  licensed_referee: 4,
  parking_on_site: 5,
  waterfront_view: 6,
});

export default orderBy({
  collection: VenuePerks,
  key: 'featureName',
});

export enum VenueTypeEnum {
  BAR = 'bar',
  CLUB = 'club',
  GYM = 'gym',
  PARK = 'park',
  POOL = 'pool',
  RESTAURANT = 'restaurant',
  SCHOOL = 'school',
  WATERFRONT = 'waterfront',
  OTHER_PRIVATE = 'other (private)',
  OTHER_PUBLIC = 'other (public)',
}
