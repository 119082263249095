import { graphql } from 'graphql-schema';
import { RoleEnum } from '@rivallapp/volosports-components';
import hasuraClient from '../../../apollo/hasuraClient';
import { sessionAPI } from '../../../utils/api';
import useAuthStore from '../AuthStore';
import syncUserWithServices from '../helpers/syncUserWithServices';

const isPhone = (credential: string) =>
  /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/.test(credential);

export type LoginArgs = {
  /** Credential can be either a phone number, email or username */
  credential: string;
  password: string;
};

/**
 * @deprecated
 * ### This calls the old login API. Remove this once we are migrated over to Hasura.
 * ---
 * Action to call the login endpoint with the provided credentials.
 *
 * If successful, it will set the user's state and provide context to Braze and Sentry.
 *
 * If unsuccessful, an error will be thrown.
 */
export const login = async ({ credential, password }: LoginArgs) => {
  if (!credential) throw new Error('Email, username, or phone number required.');
  if (!password) throw new Error('Password required.');

  const response = await sessionAPI.logIn({
    password,
    [isPhone(credential) ? 'phone' : 'email']: credential,
  });

  console.log('[AuthStore] Login success');

  useAuthStore.setState({
    isAuthenticated: true,
    isAuthenticatedWithHasura: false,
    userId: response.data?.user_id,
    roles: response.data?.roles || [],
    authToken: response.data?.authObj?.token,
  });

  syncUserWithServices({
    userId: response.data?.user_id,
    email: response.data?.user?.email,
  });

  return response.data;
};

/**
 * Action to call the login endpoint with the provided credentials.
 *
 * If successful, it will set the user's state and provide context to Braze and Sentry.
 *
 * If unsuccessful, an error will be thrown.
 */
export const loginWithHasura = async ({ credential, password }: LoginArgs) => {
  const { data } = await hasuraClient.mutate({
    mutation: graphql(`
      mutation Login($email: String, $phone: String, $password: String!) {
        login(email: $email, phone: $phone, password: $password) {
          _id
          email
          external_id
          roles
          token
        }
      }
    `),
    variables: {
      ...(isPhone(credential) ? { phone: credential } : { email: credential }),
      password,
    },
  });

  if (!data?.login) throw new Error('Authentication failed.');

  console.log('[AuthStore] Login success');

  const { _id, external_id, email, roles, token } = data.login;

  useAuthStore.setState({
    userId: _id,
    authToken: token,
    isAuthenticated: true,
    isAuthenticatedWithHasura: true,
    roles: roles as RoleEnum[],
  });

  syncUserWithServices({
    email,
    userId: _id,
    externalId: external_id,
  });

  return data.login;
};
