import { gql, type TypedDocumentNode } from '@apollo/client';

/** This query supports other options, but in this instance only `userId` will ever be used */
interface GetUserNotificationPreferencesInput {
  input: {
    userId: string;
  };
}

interface GetUserNotificationPreferencesData {
  getUserNotificationPreferences: {
    marketing: {
      email: boolean;
      push: boolean;
      sms: boolean;
    };
    team: {
      email: boolean;
      push: boolean;
      sms: boolean;
    };
  };
}

const GET_USER_NOTIFICATION_PREFERENCES: TypedDocumentNode<
  GetUserNotificationPreferencesData,
  GetUserNotificationPreferencesInput
> = gql`
  query getUserNotificationPreferences($input: UserDetailsInput!) {
    getUserNotificationPreferences(input: $input) {
      marketing {
        email
        push
        sms
      }
      team {
        email
        push
        sms
      }
    }
  }
`;

export default GET_USER_NOTIFICATION_PREFERENCES;
