import { gql, useQuery, type TypedDocumentNode } from '@apollo/client';

import type { TRentalActivityValue } from '../../../../constants';
import { Stack, Skeleton } from '../../../../base-components';
import {
  RentalSectionContainer,
  RentalSectionLabel,
  RentalSubtext,
} from '../../../RentalsRegistration';
import { TextTypography } from '../../../../theme';
import { openMap } from '../../../../utilities';

interface RentalLocationProps {
  /**
   * Rental ID to fetch location for
   */
  rentalId: string;
}

interface RentalLocationQuery {
  rental: {
    _id: string;
    activity_name: TRentalActivityValue;
    venue: {
      _id: string;
      name?: string;
      formatted_address?: string;
      location: {
        lat: number;
        lng: number;
      };
      place_id: string;
    };
  };
}

interface RentalLocationQueryVariables {
  id: string;
}

export const RENTAL_LOCATION_QUERY: TypedDocumentNode<
  RentalLocationQuery,
  RentalLocationQueryVariables
> = gql`
  query Rental($id: ID!) {
    rental(_id: $id) {
      _id
      activity_name
      venue {
        _id
        name
        formatted_address
        location {
          lat
          lng
        }
        place_id
      }
    }
  }
`;

const RentalLocation = ({ rentalId }: RentalLocationProps) => {
  const { data, loading } = useQuery(RENTAL_LOCATION_QUERY, {
    variables: { id: rentalId },
  });
  const placeId = data?.rental?.venue?.place_id || '';
  const venueName = data?.rental?.venue?.name || '';
  const venueAddress = data?.rental?.venue?.formatted_address || '';

  return (
    <RentalSectionContainer>
      <RentalSectionLabel>Location</RentalSectionLabel>
      <Stack space={1}>
        {loading && !data ? (
          <>
            <Skeleton.Text
              fontSize={TextTypography.p.fontSize}
              lines={1}
              width={40}
              borderRadius="md"
            />
            <Skeleton.Text
              fontSize={TextTypography.p.fontSize}
              lines={1}
              width={80}
              borderRadius="md"
            />
          </>
        ) : (
          <>
            <RentalSubtext type={undefined}>{venueName}</RentalSubtext>
            <RentalSubtext
              type={undefined}
              isLink
              onPress={() => openMap({ venueAddress, placeId })}
            >
              {venueAddress}
            </RentalSubtext>
          </>
        )}
      </Stack>
    </RentalSectionContainer>
  );
};

export default RentalLocation;
