export const ErrorCodes = Object.freeze({
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UPDATE_FAILED: 'UPDATE_FAILED',
  NOT_IMPLEMENTED: 'NOT_IMPLEMENTED',
  USER_INFO_LOAD_FAILED: 'USER_INFO_LOAD_FAILED',
  AUTHORIZATION_FAILED: 'AUTHORIZATION_FAILED',
  UNAUTHORIZED_ACTION: 'UNAUTHORIZED_ACTION',
  INVALID_OBJECT_ID: 'INVALID_OBJECT_ID',
  INVALID_TEAM_ID: 'INVALID_TEAM_ID',
  USERNAME_TAKEN: 'USERNAME_TAKEN',
  EMAIL_TAKEN: 'EMAIL_TAKEN',
  DEEPLINK_ERROR: 'DEEPLINK_ERROR',
  CANT_DELETE_TEAM_WITH_GAMES: 'CANT_DELETE_TEAM_WITH_GAMES',
  PLAYER_ALREADY_IN_LEAGUE: 'PLAYER_ALREADY_IN_LEAGUE',
  GROUP_ALREADY_IN_LEAGUE: 'GROUP_ALREADY_IN_LEAGUE',
  DUPLICATE_TEAM_NAME: 'DUPLICATE_TEAM_NAME',
  ACTIVITY_TYPE_NOT_FOUND: 'ACTIVITY_TYPE_NOT_FOUND',
  SEASON_NOT_FOUND: 'SEASON_NOT_FOUND',
  ORGANIZATION_NOT_FOUND: 'ORGANIZATION_NOT_FOUND',
  VENUE_NOT_FOUND: 'VENUE_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  LEAGUE_NOT_FOUND: 'LEAGUE_NOT_FOUND',
  DAY_NOT_FOUND: 'DAY_NOT_FOUND',
  WEEK_NOT_FOUND: 'WEEK_NOT_FOUND',
  GAME_NOT_FOUND: 'GAME_NOT_FOUND',
  DROPIN_NOT_FOUND: 'DROPIN_NOT_FOUND',
  REGISTRANT_NOT_FOUND: 'REGISTRANT_NOT_FOUND',
  RSVP__PLAYER_NOT_FOUND: 'RSVP__PLAYER_NOT_FOUND',
  CANT_DELETE_PAST_GAME: 'CANT_DELETE_PAST_GAME',
  SCHEDULE_NOT_FOUND: 'SCHEDULE_NOT_FOUND',
  TOURNAMENT_NOT_FOUND: 'TOURNAMENT_NOT_FOUND',
  WAIVER_NOT_FOUND: 'WAIVER_NOT_FOUND',
  INVALID_TIMESLOT_TIME_RANGE: 'INVALID_TIMESLOT_TIME_RANGE',
  INVALID_DATE_STRING_FORMAT: 'INVALID_DATE_STRING_FORMAT',
  INVALID_TIME_STRING_FORMAT: 'INVALID_TIME_STRING_FORMAT',
  INVALID_PRICE_FORMAT: 'INVALID_PRICE_FORMAT',
  INVALID_QUESTION_TITLE: 'INVALID_QUESTION_TITLE',
  INVALID_DROPDOWN_OPTION: 'INVALID_DROPDOWN_OPTION',

  STRIPE_CUSTOMER_NOT_FOUND: 'STRIPE_CUSTOMER_NOT_FOUND',
  STRIPE_ADD_PAYMENT_SOURCE_ERROR: 'STRIPE_ADD_PAYMENT_SOURCE_ERROR',
  STRIPE_DELETE_PAYMENT_SOURCE_ERROR: 'STRIPE_DELETE_PAYMENT_SOURCE_ERROR',
  STRIPE_DEFAULT_PAYMENT_SOURCE_ERROR: 'STRIPE_DEFAULT_PAYMENT_SOURCE_ERROR',
  STRIPE_SUBSCRIPTION_NOT_FOUND: 'STRIPE_SUBSCRIPTION_NOT_FOUND',
  STRIPE_COUPON_NOT_FOUND: 'STRIPE_COUPON_NOT_FOUND',

  INVALID_NUM_TEAMS: 'INVALID_NUM_TEAMS',
  INVALID_NUM_WEEKS: 'INVALID_NUM_WEEKS',
  INVALID_TEAM_SIZE: 'INVALID_TEAM_SIZE',
  INVALID_CONTACT_INFO: 'INVALID_CONTACT_INFO',
  IDENTICAL_TEAMS_PROVIDED: 'IDENTICAL_TEAMS_PROVIDED',
  TEAM_NOT_IN_LEAGUE: 'TEAM_NOT_IN_LEAGUE',
  INVALID_SCHEDULE_GAME_LENGTH: 'INVALID_SCHEDULE_GAME_LENGTH',
  INVALID_SCHEDULE_START_DATE: 'INVALID_SCHEDULE_START_DATE',
  TIMESLOT_VENUES_EMPTY: 'TIMESLOT_VENUES_EMPTY',
  INVALID_GAMES_PER_TEAM_PER_WEEK: 'INVALID_GAMES_PER_TEAM_PER_WEEK',
  CANNOT_OPEN_DROPIN_ON_DRAFT_GAME: 'CANNOT_OPEN_DROPIN_ON_DRAFT_GAME',
  CANNOT_OPEN_DROPIN_ON_UNAPPROVED_SCHEDULE: 'CANNOT_OPEN_DROPIN_ON_UNAPPROVED_SCHEDULE',

  STANDINGS_RULESET_NOT_FOUND: 'STANDINGS_RULESET_NOT_FOUND',
  STANDINGS_RULESET_LEAGUE_DUPLICATE: 'STANDINGS_RULESET_LEAGUE_DUPLICATE',
  CANNOT_DELETE_LEAGUE_STANDINGS_RULESET: 'CANNOT_DELETE_LEAGUE_STANDINGS_RULESET',
  STANDINGS_RULE_INVALID_INPUT: 'STANDINGS_RULE_INVALID_INPUT',

  DUPLICATE_REGISTRANT: 'DUPLICATE_REGISTRANT',
  DUPLICATE_DROPIN: 'DUPLICATE_DROPIN',
  DROPIN_CLAIMED: 'DROPIN_CLAIMED',
  DROPIN_CLOSED: 'DROPIN_CLOSED',
  DROPIN_COPY: 'DROPIN_COPY',
  DROPIN_SHARE: 'DROPIN_SHARE',

  NO_REGISTRANT_FOUND: 'NO_REGISTRANT_FOUND',
  NO_DROPIN_REGISTRANT_FOUND: 'NO_DROPIN_REGISTRANT_FOUND',

  DROPIN_CLOSING: 'DROPIN_CLOSING',
  DROPIN_HOLD: 'DROPIN_HOLD',
  DROPIN_MAX: 'DROPIN_MAX',
  DROPIN_OPENING: 'DROPIN_OPENING',
  DROPIN_CHANGE_GENDER: 'DROPIN_CHANGE_GENDER',
  DROPIN_REMOVE_PLAYER: 'DROPIN_REMOVE_PLAYER',
  DROPIN_UPDATE: 'DROPIN_UPDATE',

  PLAYER_CONTACT_INFO: 'PLAYER_CONTACT_INFO',

  UPDATE_TEAM_ROLE: 'UPDATE_TEAM_ROLE',
  RSVP_FOR_PLAYER: 'RSVP_FOR_PLAYER',

  NO_TOURNAMENT_DROPINS: 'NO_TOURNAMENT_DROPINS',
  INVALID_DROPIN_GENDER: 'INVALID_DROPIN_GENDER',
  INVALID_PROGRAM_GENDER: 'INVALID_PROGRAM_GENDER',
  NO_VOLO_PASS: 'NO_VOLO_PASS',
  MULTIPLE_REGISTRATION_TYPES_SUBMITTED: 'MULTIPLE_REGISTRATION_TYPES_SUBMITTED',

  UNKNOWN_CHARGE_ERROR: 'UNKNOWN_CHARGE_ERROR',
  CARD_DECLINED__AUTHENTICATION_REQUIRED: 'CARD_DECLINED__AUTHENTICATION_REQUIRED',
  CARD_DECLINED__REASON_UNKNOWN: 'CARD_DECLINED__REASON_UNKNOWN',
  CARD_DECLINED__CONTACT_ISSUER: 'CARD_DECLINED__CONTACT_ISSUER',
  CARD_DECLINED__TRY_AGAIN: 'CARD_DECLINED__TRY_AGAIN',
  CARD_DECLINED__TRY_AGAIN_LATER: 'CARD_DECLINED__TRY_AGAIN_LATER',
  CARD_DECLINED__DUPLICATE_CHARGE: 'CARD_DECLINED__DUPLICATE_CHARGE',
  CARD_DECLINED__EXPIRED: 'CARD_DECLINED__EXPIRED',
  CARD_DECLINED__SUSPICIOUS: 'CARD_DECLINED__SUSPICIOUS',
  CARD_DECLINED__BAD_CVC: 'CARD_DECLINED__BAD_CVC',
  CARD_DECLINED__BAD_PIN: 'CARD_DECLINED__BAD_PIN',
  CARD_DECLINED__BAD_ZIP: 'CARD_DECLINED__BAD_ZIP',
  CARD_DECLINED__INSUFFICIENT_FUNDS: 'CARD_DECLINED__INSUFFICIENT_FUNDS',
  CARD_DECLINED__BAD_EXP: 'CARD_DECLINED__BAD_EXP',
  CARD_DECLINED__BAD_NUMBER: 'CARD_DECLINED__BAD_NUMBER',

  GROUP_NAME_ALREADY_TAKEN: 'GROUP_NAME_ALREADY_TAKEN',
  GROUP_FULL: 'GROUP_FULL',
  GROUP_LOCKED: 'GROUP_LOCKED',
  INVALID_PREPAID_TEAM_PASSWORD: 'INVALID_PREPAID_TEAM_PASSWORD',

  ISSUE_REFUND__MISSING_GROUP_FOR_TYPE: 'ISSUE_REFUND__MISSING_GROUP_FOR_TYPE',
  ISSUE_REFUND__PARTIAL_REFUND_EXCEEDS_MAXIMUM: 'ISSUE_REFUND__PARTIAL_REFUND_EXCEEDS_MAXIMUM',
  ISSUE_REFUND__INSUFFICIENT_FUNDS: 'ISSUE_REFUND__INSUFFICIENT_FUNDS',

  PHONE_NUMBER_TAKEN: 'PHONE_NUMBER_TAKEN',
  MISSING_AUTHY_TOKEN_OR_ID: 'MISSING_AUTHY_TOKEN_OR_ID',
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  INVALID_VERIFICATION_TOKEN: 'INVALID_VERIFICATION_TOKEN',
  AUHTY__UNKNOWN_SEND_TOKEN_ERROR: 'AUHTY__UNKNOWN_SEND_TOKEN_ERROR',

  USER_SIGNUP_MISSING_REQUIRED: 'USER_SIGNUP_MISSING_REQUIRED',

  ORGANIZATION_ADD_HOST__EMAIL_NOT_FOUND: 'ORGANIZATION_ADD_HOST__EMAIL_NOT_FOUND',
  ORGANIZATION_ADD_HOST__ALREADY_IS_HOST: 'ORGANIZATION_ADD_HOST__ALREADY_IS_HOST',

  PERMISSION_DENIED__REQUIRES_ORG_ADMIN: 'PERMISSION_DENIED__REQUIRES_ORG_ADMIN',

  RSVP__USER_NOT_FOUND: 'RSVP__USER_NOT_FOUND',

  DONATION__USER_HAS_ACTIVE_DONATION: 'DONATION__USER_HAS_ACTIVE_DONATION',
  DONATION__NO_ACTIVE_DONATION: 'DONATION__NO_ACTIVE_DONATION',

  SUBSCRIBE__USER_HAS_ACTIVE_SUBSCRIPTION: 'SUBSCRIBE__USER_HAS_ACTIVE_SUBSCRIPTION',
  SUBSCRIBE__NO_STRIPE_CUSTOMER: 'SUBSCRIBE__NO_STRIPE_CUSTOMER',
  SUBSCRIBE__UNKNOWN_ERROR: 'SUBSCRIBE__UNKNOWN_ERROR',
  SUBSCRIBE__HAS_PAST_MEMBERSHIP: 'SUBSCRIBE__HAS_PAST_MEMBERSHIP',
  SUBSCRIBE__UNSUPPORTED_TRIAL_TYPE: 'SUBSCRIBE__UNSUPPORTED_TRIAL_TYPE',
  RESUBSCRIBE__NO_ACTIVE_SUBSCRIPTION: 'RESUBSCRIBE__NO_ACTIVE_SUBSCRIPTION',
  RESUBSCRIBE__NO_ACTIVE_CANCELLED_SUBSCRIPTION: 'RESUBSCRIBE__NO_ACTIVE_CANCELLED_SUBSCRIPTION',
  RESUBSCRIBE__SUBSCRIPTION_IS_INACTIVE: 'RESUBSCRIBE__SUBSCRIPTION_IS_INACTIVE',
  RESUBSCRIBE__SUBSCRIPTION_NOT_CANCELED: 'RESUBSCRIBE__SUBSCRIPTION_NOT_CANCELED',
  UNSUBSCRIBE__USER_HAS_NO_ACTIVE_MEMBERSHIP: 'UNSUBSCRIBE__USER_HAS_NO_ACTIVE_MEMBERSHIP',
  UNSUBSCRIBE__UNKNOWN_ERROR: 'UNSUBSCRIBE__UNKNOWN_ERROR',

  MEMBER_REGISTER__NOT_DROPIN_PROGRAM: 'MEMBER_REGISTER__NOT_DROPIN_PROGRAM',
  MEMBER_REGISTER__NO_ACTIVE_SUBSCRIPTION: 'MEMBER_REGISTER__NO_ACTIVE_SUBSCRIPTION',
  MEMBER_REGISTER__LAST_CHARGE_FAILED: 'MEMBER_REGISTER__LAST_CHARGE_FAILED',

  EMAIL_FILTER_INPUT_ERROR: 'EMAIL_FILTER_INPUT_ERROR',

  USER_NOT_SIGNED_IN: 'USER_NOT_SIGNED_IN',

  INVALID_FILE_PROVIDED: 'INVALID_FILE_PROVIDED',

  NOTE_EXCEEDS_MAX_CHARACTER_LENGTH: 'NOTE_EXCEEDS_MAX_CHARACTER_LENGTH',

  RENTAL_NOT_FOUND: 'RENTAL_NOT_FOUND',
  RENTAL_CHECKIN_NOT_FOUND: 'RENTAL_CHECKIN_NOT_FOUND',
  RENTAL_REGISTRANT_NOT_FOUND: 'RENTAL_REGISTRANT_NOT_FOUND',
  RENTAL_REGISTRATION_NOT_FOUND: 'RENTAL_REGISTRATION_NOT_FOUND',
  RENTAL_RSVP_NOT_FOUND: 'RENTAL_RSVP_NOT_FOUND',
  RENTAL_TIMESLOT_NOT_FOUND: 'RENTAL_TIMESLOT_NOT_FOUND',
  RENTAL_IMAGES_REQUIRED: 'RENTAL_IMAGES_REQUIRED',
  RENTAL_CREATION_ERROR: 'RENTAL_CREATION_ERROR',
  RENTAL_DISCOVER_ERROR: 'RENTAL_DISCOVER_ERROR',
  DUPLICATE_RENTAL: 'DUPLICATE_RENTAL',
  RENTAL_INVALID_START_END_TIME_INPUT: 'RENTAL_INVALID_START_END_TIME_INPUT',
  RENTAL_INVALID_TIME_RANGE: 'RENTAL_INVALID_TIME_RANGE',
  RENTAL_RESERVATION_SHARE_ERROR: 'RENTAL_RESERVATION_SHARE_ERROR',

  VOLO_PASS_ONLY_UNAUTHORIZED: 'VOLO_PASS_ONLY_UNAUTHORIZED',
});

export const ErrorMessageEnum = Object.freeze({
  [ErrorCodes.UNKNOWN_ERROR]:
    'An unknown error has occurred. Please contact info@volosports.com if the issue persists',
  [ErrorCodes.UPDATE_FAILED]: 'An error occurred while trying to update',
  [ErrorCodes.NOT_IMPLEMENTED]:
    "This feature isn't implemented yet! Please reach out to a Volo team member for help.",

  [ErrorCodes.USER_INFO_LOAD_FAILED]: 'An error occurred while trying to load the user data',

  [ErrorCodes.AUTHORIZATION_FAILED]: "You don't have permission to make this request.",
  [ErrorCodes.UNAUTHORIZED_ACTION]: 'You are not authorized to take this action.',

  [ErrorCodes.INVALID_OBJECT_ID]:
    "Whoops, we were unable to find what you're looking for! Please double check the URL.",
  [ErrorCodes.INVALID_TEAM_ID]: 'No teamId found!',

  [ErrorCodes.USERNAME_TAKEN]: '🧐 That username is already in use.',
  [ErrorCodes.EMAIL_TAKEN]: '🧐 That email is already associated with a Volo account.',

  [ErrorCodes.DEEPLINK_ERROR]: 'Error creating deep link',

  [ErrorCodes.CANT_DELETE_TEAM_WITH_GAMES]: "Can't delete a team that has games.",
  [ErrorCodes.PLAYER_ALREADY_IN_LEAGUE]:
    'This player is already registered in the league you are trying to move them to.',
  [ErrorCodes.GROUP_ALREADY_IN_LEAGUE]: 'New program already includes at least one group player.',
  [ErrorCodes.DUPLICATE_TEAM_NAME]: 'Two teams cannot have the same name.',

  [ErrorCodes.ACTIVITY_TYPE_NOT_FOUND]: 'Unrecognized activity type.',
  [ErrorCodes.SEASON_NOT_FOUND]: 'Invalid season.',
  [ErrorCodes.ORGANIZATION_NOT_FOUND]: 'Organization not found.',
  [ErrorCodes.USER_NOT_FOUND]: 'User not found.',
  [ErrorCodes.LEAGUE_NOT_FOUND]: 'League not found.',
  [ErrorCodes.WAIVER_NOT_FOUND]: 'Waiver not found.',
  [ErrorCodes.DAY_NOT_FOUND]: 'Day not found.',
  [ErrorCodes.WEEK_NOT_FOUND]: 'Week not found.',
  [ErrorCodes.GAME_NOT_FOUND]: 'Game not found.',
  [ErrorCodes.DROPIN_NOT_FOUND]: 'Drop-in not found.',
  [ErrorCodes.REGISTRANT_NOT_FOUND]: 'Registrant not found.',
  [ErrorCodes.RSVP__PLAYER_NOT_FOUND]: 'RSVP for player not found.',
  [ErrorCodes.CANT_DELETE_PAST_GAME]: "Can't delete a game in the past.",
  [ErrorCodes.SCHEDULE_NOT_FOUND]: 'Schedule not found.',
  [ErrorCodes.TOURNAMENT_NOT_FOUND]: 'Tournament not found',

  [ErrorCodes.INVALID_TIMESLOT_TIME_RANGE]: 'Invalid time range provided',
  [ErrorCodes.INVALID_DATE_STRING_FORMAT]: 'Invalid date. Expected YY/MM/DD',
  [ErrorCodes.INVALID_TIME_STRING_FORMAT]: 'Invalid time. Expected HH:mm',
  [ErrorCodes.INVALID_PRICE_FORMAT]: 'Invalid price. Expected price in cents.',

  [ErrorCodes.STRIPE_ADD_PAYMENT_SOURCE_ERROR]: 'Unable to add payment.',

  [ErrorCodes.DUPLICATE_REGISTRANT]: "Looks like you're already registered for this program.",
  [ErrorCodes.DUPLICATE_DROPIN]: "Looks like you've already registered for this game.",
  [ErrorCodes.DROPIN_CLAIMED]: 'Sorry, this drop in has already been claimed.',
  [ErrorCodes.DROPIN_CLOSED]:
    'This drop in was closed. If you think this was an error, please contact your captain or support.',
  [ErrorCodes.DROPIN_COPY]: 'Error: Unable to copy drop-in link',
  [ErrorCodes.DROPIN_SHARE]: 'There was an error sharing the drop-in link',

  [ErrorCodes.NO_REGISTRANT_FOUND]: 'Unable to find a registrant for this drop in slot.',
  [ErrorCodes.NO_DROPIN_REGISTRANT_FOUND]: 'No registration found for this drop in.',

  [ErrorCodes.DROPIN_CLOSING]: 'Error closing drop-in slot',
  [ErrorCodes.DROPIN_HOLD]:
    'This slot is currently held by a player. Please wait for them to release it.',
  [ErrorCodes.DROPIN_MAX]: 'Max number of drop-in slots already reached for this game.',
  [ErrorCodes.DROPIN_OPENING]: 'Error opening drop-in slot',
  [ErrorCodes.DROPIN_CHANGE_GENDER]: 'Error changing gender of drop-in slot',
  [ErrorCodes.DROPIN_REMOVE_PLAYER]: 'Error removing player from drop-in slot',
  [ErrorCodes.DROPIN_UPDATE]: 'An error occurred while updating this drop-in',

  [ErrorCodes.PLAYER_CONTACT_INFO]: "An error occurred while loading the player's contact info",

  [ErrorCodes.UPDATE_TEAM_ROLE]: 'Error: Unable to update team role',
  [ErrorCodes.RSVP_FOR_PLAYER]: 'Error: Unable to RSVP for player',

  [ErrorCodes.NO_TOURNAMENT_DROPINS]: "Sorry, tournaments don't currently allow drop ins.",
  [ErrorCodes.INVALID_DROPIN_GENDER]:
    'This drop in is limited to players of a certain gender. If you think this error is a mistake contact info@volosports.com',
  [ErrorCodes.INVALID_PROGRAM_GENDER]:
    'This program is limited to players of a certain gender. If you think this error is a mistake, please contact info@volosports.com',
  [ErrorCodes.NO_VOLO_PASS]:
    'Sorry, this event is not accepting players not enrolled in Volo Pass.',

  [ErrorCodes.INVALID_NUM_WEEKS]: 'Invalid number of weeks. Expected at least 1.',
  [ErrorCodes.INVALID_NUM_TEAMS]: 'Invalid number of teams provided.',
  [ErrorCodes.INVALID_TEAM_SIZE]:
    'The number of player per team and teams per league should be between 1 and 100',
  [ErrorCodes.IDENTICAL_TEAMS_PROVIDED]: 'The provided team IDs cannot be the same.',
  [ErrorCodes.TEAM_NOT_IN_LEAGUE]: 'Invalid team provided for this league.',
  [ErrorCodes.VENUE_NOT_FOUND]: 'This league does not have a location 🗺',
  [ErrorCodes.INVALID_CONTACT_INFO]:
    'This league needs a point of contact with a valid email and phone number',

  [ErrorCodes.STANDINGS_RULESET_NOT_FOUND]: 'Standings ruleset not found.',
  [ErrorCodes.STANDINGS_RULESET_LEAGUE_DUPLICATE]:
    'A standings ruleset already exists for the provided league.',
  [ErrorCodes.CANNOT_DELETE_LEAGUE_STANDINGS_RULESET]:
    'Deleting a league standings ruleset is not allowed.',
  [ErrorCodes.STANDINGS_RULE_INVALID_INPUT]: 'Invalid input provided for standings rule.',

  [ErrorCodes.CARD_DECLINED__AUTHENTICATION_REQUIRED]:
    'Your card required authentication. Please add a different payment source and try again.',
  [ErrorCodes.CARD_DECLINED__CONTACT_ISSUER]:
    'There was a problem with your card. Please reach out to your card issuer or use a different payment method.',
  [ErrorCodes.CARD_DECLINED__TRY_AGAIN]:
    'There was a problem with your card. Please try again. If the issue persists, you may need to reach out to your card issuer.',
  [ErrorCodes.CARD_DECLINED__DUPLICATE_CHARGE]:
    "A duplicate charge was detected, so we didn't complete the charge. If this is in error, wait a moment and try again.",
  [ErrorCodes.CARD_DECLINED__EXPIRED]: 'Your card is expired.',
  [ErrorCodes.CARD_DECLINED__SUSPICIOUS]:
    'Your card has been flagged as suspicious. Please contact your card issuer or use a different payment method.',
  [ErrorCodes.CARD_DECLINED__BAD_CVC]: 'Your CVC is invalid.',
  [ErrorCodes.CARD_DECLINED__BAD_PIN]: 'Your pin is invalid.',
  [ErrorCodes.CARD_DECLINED__BAD_ZIP]: 'Your zip code is incorrect.',
  [ErrorCodes.CARD_DECLINED__INSUFFICIENT_FUNDS]: 'Your card has insufficient funds.',
  [ErrorCodes.CARD_DECLINED__BAD_EXP]: 'Your expiration date is invalid.',
  [ErrorCodes.CARD_DECLINED__BAD_NUMBER]: 'Your card number is invalid.',
  [ErrorCodes.CARD_DECLINED__REASON_UNKNOWN]:
    'There was an unknown problem with your card. Please try a different payment method.',
  [ErrorCodes.CARD_DECLINED__TRY_AGAIN_LATER]:
    'Your card has exceeded its velocity limit. Please try again later or use a different payment method.',
  [ErrorCodes.UNKNOWN_CHARGE_ERROR]:
    'There was an unknown problem completing the charge. Please try again, use an alternate payment method, or contact info@volosports.com for help.',

  [ErrorCodes.GROUP_FULL]: 'Sorry, that group is full! Please choose another.',
  [ErrorCodes.GROUP_LOCKED]:
    'This group is locked. Please reach out to your team captain and have them reach out to Customer Service',
  [ErrorCodes.GROUP_NAME_ALREADY_TAKEN]:
    'Another group/team in the league already has this name! Please pick a different name.',
  [ErrorCodes.INVALID_PREPAID_TEAM_PASSWORD]: 'Incorrect password.',

  [ErrorCodes.ISSUE_REFUND__PARTIAL_REFUND_EXCEEDS_MAXIMUM]: 'Refund amount exceeds maximum.',
  [ErrorCodes.ISSUE_REFUND__INSUFFICIENT_FUNDS]:
    'Your account has insufficient funds to issue this refund.',

  [ErrorCodes.PHONE_NUMBER_TAKEN]: 'Phone number is already associated with a Volo account',
  [ErrorCodes.MISSING_AUTHY_TOKEN_OR_ID]: 'Missing authy ID or token from verify token mutation',
  [ErrorCodes.INVALID_PHONE_NUMBER]: 'Unrecognized phone number.',
  [ErrorCodes.INVALID_VERIFICATION_TOKEN]: 'Invalid verification token.',
  [ErrorCodes.AUHTY__UNKNOWN_SEND_TOKEN_ERROR]:
    'Sorry, there was an unknown issue sending a verification text. Please double check you entered your phone number correctly. If you continue experiencing issues, please reach out to info@volosports.com.',

  [ErrorCodes.USER_SIGNUP_MISSING_REQUIRED]: 'Missing required information to create an account.',

  [ErrorCodes.ORGANIZATION_ADD_HOST__EMAIL_NOT_FOUND]:
    'No Volo account was found with the provided email.',
  [ErrorCodes.ORGANIZATION_ADD_HOST__ALREADY_IS_HOST]:
    'The user is already a host for another organization on Volo. Hosts can only have one organization.',

  [ErrorCodes.PERMISSION_DENIED__REQUIRES_ORG_ADMIN]:
    'You must be an organization admin to take this action.',

  [ErrorCodes.DONATION__USER_HAS_ACTIVE_DONATION]: 'You already have an active recurring donation.',
  [ErrorCodes.DONATION__NO_ACTIVE_DONATION]: 'No active recurring donation found',

  [ErrorCodes.RSVP__USER_NOT_FOUND]: "Unable to find user's RSVP associated with this event.",

  [ErrorCodes.SUBSCRIBE__USER_HAS_ACTIVE_SUBSCRIPTION]: 'You already have an active membership.',
  [ErrorCodes.SUBSCRIBE__NO_STRIPE_CUSTOMER]: 'Memberships require a valid payment method.',
  [ErrorCodes.SUBSCRIBE__HAS_PAST_MEMBERSHIP]:
    'You are unable to start a trial because you have a past membership.',
  [ErrorCodes.SUBSCRIBE__UNSUPPORTED_TRIAL_TYPE]:
    'The specified trial type is not supported. Reach out for help getting your trial started!',
  [ErrorCodes.SUBSCRIBE__UNKNOWN_ERROR]:
    'Unknown error starting your membership. Please reach out for help!',
  [ErrorCodes.RESUBSCRIBE__NO_ACTIVE_SUBSCRIPTION]: 'No active membership found.',
  [ErrorCodes.RESUBSCRIBE__NO_ACTIVE_CANCELLED_SUBSCRIPTION]: 'No cancelled membership found.',
  [ErrorCodes.RESUBSCRIBE__SUBSCRIPTION_IS_INACTIVE]:
    'Cannot undo cancellation of an inactive membership. Please re-register for membership.',
  [ErrorCodes.RESUBSCRIBE__SUBSCRIPTION_NOT_CANCELED]:
    'Cannot reinstate a membership that is active.',
  [ErrorCodes.UNSUBSCRIBE__USER_HAS_NO_ACTIVE_MEMBERSHIP]: 'No active membership found',
  [ErrorCodes.UNSUBSCRIBE__UNKNOWN_ERROR]:
    'Unknown error cancelling your membership. Please reach out for help!',

  [ErrorCodes.MEMBER_REGISTER__NOT_DROPIN_PROGRAM]:
    'Program is not valid for free member registration.',
  [ErrorCodes.MEMBER_REGISTER__NO_ACTIVE_SUBSCRIPTION]: 'No active membership.',
  [ErrorCodes.MEMBER_REGISTER__LAST_CHARGE_FAILED]:
    'We were unable to complete your last membership charge. Please check the payment method on file. If this is in error, contact support for assistance.',

  [ErrorCodes.USER_NOT_SIGNED_IN]: 'The user is not signed in.',

  [ErrorCodes.INVALID_FILE_PROVIDED]: 'Could not parse the file provided.',

  [ErrorCodes.NOTE_EXCEEDS_MAX_CHARACTER_LENGTH]: 'Note exceeds max character length.',

  [ErrorCodes.STRIPE_CUSTOMER_NOT_FOUND]: 'Could not find a Stripe customer for that user.',
  [ErrorCodes.STRIPE_SUBSCRIPTION_NOT_FOUND]: 'Could not find that subscription in Stripe.',
  [ErrorCodes.STRIPE_COUPON_NOT_FOUND]: 'Could not find the specified Stripe coupon.',

  [ErrorCodes.RENTAL_NOT_FOUND]: 'Rental not found.',
  [ErrorCodes.RENTAL_CHECKIN_NOT_FOUND]: 'Rental check-in not found.',
  [ErrorCodes.RENTAL_REGISTRANT_NOT_FOUND]: 'Rental registrant not found.',
  [ErrorCodes.RENTAL_REGISTRATION_NOT_FOUND]: 'Rental registration not found.',
  [ErrorCodes.RENTAL_RSVP_NOT_FOUND]: 'Rental RSVP not found.',
  [ErrorCodes.RENTAL_TIMESLOT_NOT_FOUND]: 'Rental timeslot not found.',
  [ErrorCodes.RENTAL_IMAGES_REQUIRED]: 'An image is required for rentals.',
  [ErrorCodes.RENTAL_CREATION_ERROR]: 'An error occurred while creating the rentals.',
  [ErrorCodes.RENTAL_DISCOVER_ERROR]: 'An error occurred while fetching available rentals.',
  [ErrorCodes.DUPLICATE_RENTAL]:
    'A rental has already been created for the passed in sport/venue combo, please edit instead.',
  [ErrorCodes.RENTAL_INVALID_START_END_TIME_INPUT]:
    'Invalid rental input. Check the start and end time inputs to make sure they are valid.',
  [ErrorCodes.RENTAL_INVALID_TIME_RANGE]:
    'Invalid time range! There are missing time slots within the given range',
  [ErrorCodes.RENTAL_RESERVATION_SHARE_ERROR]:
    'There was an error copying the link to share this reservation',

  [ErrorCodes.VOLO_PASS_ONLY_UNAUTHORIZED]: 'Only Volo Pass members can access this feature.',
});

export default ErrorCodes;
