import type { IHStackProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Stack/HStack';
import { type ButtonProps } from '../../../../base-components';
import PricingSportView from './PricingSportView';
import PricingVenueView from './PricingVenueView';
import type { TRentalActivityName } from '../../../../constants';

type RentalPricingBaseProps = {
  buttonStyle?: ButtonProps;
  containerStyle?: IHStackProps;
};

type Props = RentalPricingBaseProps &
  (
    | {
        rentalId: string;
        venueId?: never;
        onPress: (_id: string, activity_name: TRentalActivityName) => void;
      }
    | {
        rentalId?: never;
        venueId: string;
        onPress?: never;
      }
  );

/**
 * Displays the pricing information for a rental or a venue. This component can take either
 * `rentalId` or `venueId`, but not both. These correspond to sport view and venue view, respectively.
 *
 * If `rentalId` is provided, also includes a button to open up the registration flow.
 *
 * @important Make sure wrap this component with Suspense and ErrorBoundary!
 */
const RentalPricing = ({
  rentalId,
  venueId,
  onPress,
  containerStyle = {},
  buttonStyle = {},
}: Props) => {
  if (rentalId) {
    return (
      <PricingSportView
        rentalId={rentalId}
        onPress={onPress}
        containerStyle={containerStyle}
        buttonStyle={buttonStyle}
      />
    );
  }

  if (venueId) {
    return <PricingVenueView venueId={venueId} containerStyle={containerStyle} />;
  }

  // from the types - this should never happen but JIC
  return null;
};

export default RentalPricing;
