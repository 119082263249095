import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getAppName, getPageName, noHeader } from '../pages/page-options';
import useOrganizations from './useOrganizations';

const usePageOptions = () => {
  const { pathname } = useLocation();
  const { organizations: organizationsList } = useOrganizations();

  return useMemo(() => {
    const pageName = getPageName({ pathname, organizationsList });
    return {
      pageName,
      noHeader: noHeader(pageName),
      isRfo: pathname.includes('rfo'),
      appName: getAppName({ pathname, organizationsList }),
    };
  }, [pathname, organizationsList]);
};

export default usePageOptions;
