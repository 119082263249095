import { useColorModeValue } from '@rivallapp/native-base';

import { type IViewProps, View } from '../../../../../../base-components';
import {
  baseBorderRadius,
  inRangeBackgroundColor,
  slotMargin,
} from '../../DateSelectorItem/DateSelector.constants';

interface SlotWrapperProps extends IViewProps {
  /**
   * Whether the slot is selected
   */
  isSelected: boolean;
  /**
   * Whether the slot is the first selected slot
   * - used to determine the border radius & adjust padding/margin to fill the space correctly
   */
  isFirstSelectedSlot: boolean;
  /**
   * Whether the slot is the last selected slot
   * - used to determine the border radius & adjust padding/margin to fill the space correctly
   */
  isLastSelectedSlot: boolean;
}

/**
 * Inner slot wrapper to apply the inRangeColor to the selected slots along with the correct padding/margin adjustments to get desired `in range` UI
 */
const SlotWrapper = ({
  isFirstSelectedSlot,
  isLastSelectedSlot,
  isSelected,
  ...rest
}: SlotWrapperProps) => {
  const inRangeBgColor = useColorModeValue(
    inRangeBackgroundColor.light,
    inRangeBackgroundColor.dark
  );

  const borderLeftRadius = isFirstSelectedSlot ? baseBorderRadius : undefined;
  const borderRightRadius = isLastSelectedSlot ? baseBorderRadius : undefined;

  return (
    <View
      nativeID="slot-wrapper"
      /* make sure that UI does not overflow into the 'space' between last slot selected */
      style={{
        ...(isFirstSelectedSlot
          ? {
              paddingLeft: 0,
              marginLeft: slotMargin,
            }
          : { paddingHorizontal: slotMargin }),
        ...(isLastSelectedSlot
          ? {
              paddingRight: 0,
              marginRight: slotMargin,
            }
          : { paddingHorizontal: slotMargin }),
      }}
      backgroundColor={isSelected ? inRangeBgColor : undefined}
      borderTopLeftRadius={borderLeftRadius}
      borderBottomLeftRadius={borderLeftRadius}
      borderTopRightRadius={borderRightRadius}
      borderBottomRightRadius={borderRightRadius}
      justifyContent="center"
      alignItems="center"
      {...rest}
    />
  );
};

export default SlotWrapper;
