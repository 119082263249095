import { View as NView, type StyledProps } from '@rivallapp/native-base';
import { type ViewProps } from 'react-native';
import { memo } from 'react';

import { useTheme } from '../../theme';

/**
 * A container component that applies the card color of the current theme.
 * - this should be used to create contrast on the screen.
 * - utilize the `Container` component if you need a view with the background color of the theme.
 * @defaults `bgColor={card}` | `padding={1}` | `borderRadius={5}` | `overflow="hidden"`
 */
const Card = (props: ViewProps & StyledProps) => {
  const {
    colors: { card },
  } = useTheme();

  return <NView bgColor={card} padding={1} borderRadius={5} overflow="hidden" {...props} />;
};

export default memo(Card);
