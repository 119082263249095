import { type FC } from 'react';
import { Text } from '../../../../base-components';
import type { CapacityCourtNumberModule } from '../../RentalCard/types';

const CapacityCourtsSection: FC<Omit<CapacityCourtNumberModule, 'type'>> = ({
  guestCapacity,
  availableCourts,
  surfaceType,
}) => (
  <Text fontSize={14}>
    {availableCourts} {surfaceType}s · Up to {guestCapacity} guests per {surfaceType}
  </Text>
);

export default CapacityCourtsSection;
