import { type TypedDocumentNode, gql } from '@apollo/client';

export type GetVenueGeneralInfoData = {
  venue: {
    _id: string;
    shorthandName: string;
    neighborhood: string;
    maps_url_query?: string;
    formatted_address?: string;
    place_id: string;
    location: {
      lat: number;
      lng: number;
    };
    first_timeslot_by_time: {
      _id: string;
      start_time_str: string;
    };
    last_timeslot_by_time: {
      _id: string;
      end_time_str: string;
    };
    lowest_standard_rental_price: number;
    highest_standard_rental_price: number;
    lowest_prime_rental_price?: number;
    highest_prime_rental_price?: number;
  };
};

type GetVenueGeneralInfo = {
  venueId: string;
};

const GET_VENUE_GENERAL_INFO: TypedDocumentNode<GetVenueGeneralInfoData, GetVenueGeneralInfo> = gql`
  query venue($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      shorthandName
      neighborhood
      maps_url_query
      formatted_address
      place_id
      location {
        lat
        lng
      }
      first_timeslot_by_time {
        _id
        start_time_str
      }
      last_timeslot_by_time {
        _id
        end_time_str
      }
      lowest_standard_rental_price
      highest_standard_rental_price
      lowest_prime_rental_price
      highest_prime_rental_price
    }
  }
`;

export default GET_VENUE_GENERAL_INFO;
