import { useState } from 'react';
import { type IHStackProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Stack/HStack';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';

import { Text, Stack, Row, Pressable, FontAwesomeIcon } from '../../../../../base-components';
import { type RentalBooking } from '../../../store';
import {
  createPasswordMask,
  formatCourtNames,
  formatFullTimeRange,
  groupPricingRanges,
  formatRentalActivityLabel,
  formatStartDate,
} from './helpers';
import RentalSectionLabel from '../RentalSectionLabel';
import RentalEditChip from '../RentalEditChip';
import { type ConfirmSectionProps } from '../../../RegistrationFlowSections/ConfirmSection/ConfirmSection';
import BulletedItem from '../BulletedItem';
import RentalSectionContainer from '../RentalSectionContainer';

interface RentalBookingSummaryProps {
  booking: RentalBooking;
  /**
   * If provided, edit chip will show up for each section with the callback to handle navigation
   */
  onEditPress?: ConfirmSectionProps['onEditPress'];
}

const LabelRow = (props: IHStackProps) => (
  <Row space={1} justifyContent="space-between" alignItems="center" {...props} />
);

const errorProps = { _text: { color: 'error.600' } };

/**
 * RentalBookingSummary
 *
 * ---
 * - Displays the summary of a the booking passed in
 */
const RentalBookingSummary = ({ booking, onEditPress }: RentalBookingSummaryProps) => {
  const [showPassword, setShowPassword] = useState(false);

  if (!booking)
    return (
      <RentalSectionContainer>
        <Text>Hmm, no booking found, please close and start over</Text>
      </RentalSectionContainer>
    );

  const {
    rentalType,
    steps: { dateSelection, courtsSelection, groupSettings },
  } = booking;

  const formattedStartDate = formatStartDate(dateSelection.startDate);
  const activityLabel = formatRentalActivityLabel(rentalType);
  const fullTimeRange = formatFullTimeRange(dateSelection.timeSlots);
  const { primeTimes, standardTimes } = groupPricingRanges(dateSelection.timeSlots);
  const formattedCourtNames = formatCourtNames(courtsSelection.selectedCourts);
  const maskedPassword = createPasswordMask(groupSettings?.groupPassword || '');

  return (
    <Stack space={3}>
      <RentalSectionLabel>{activityLabel} Rental</RentalSectionLabel>
      {/* Date Summary */}
      <Stack nativeID="date-summary">
        <RentalSectionLabel>Date</RentalSectionLabel>
        <Text>{formattedStartDate}</Text>
      </Stack>

      {/* Rental Type & Time Range Summary */}
      <Stack nativeID="rental-type-and-time-range-summary">
        <LabelRow>
          <RentalSectionLabel>Time</RentalSectionLabel>
          {onEditPress && <RentalEditChip onPress={() => onEditPress('date-selection')} />}
        </LabelRow>
        <Stack nativeID="time-range-and-pricing">
          <Text>{`Time Range: ${fullTimeRange}`}</Text>
          <Stack pl={4}>
            {standardTimes && (
              <BulletedItem _text={{ italic: true }}>
                {`Standard pricing from ${standardTimes}`}
              </BulletedItem>
            )}
            {primeTimes && (
              <BulletedItem _text={{ italic: true }}>
                {`Prime Time pricing from ${primeTimes}`}
              </BulletedItem>
            )}
          </Stack>
        </Stack>
      </Stack>

      {/* Courts Summary */}
      <Stack nativeID="read-courts-section">
        <LabelRow>
          <RentalSectionLabel {...(!formattedCourtNames && { ...errorProps })}>
            Courts
          </RentalSectionLabel>
          {onEditPress && (
            <RentalEditChip
              {...(!formattedCourtNames && { colorScheme: 'error' })}
              onPress={() => onEditPress('courts-selection')}
            />
          )}
        </LabelRow>
        <Stack pl={4}>
          <BulletedItem {...(!formattedCourtNames && { ...errorProps })}>
            {formattedCourtNames || 'Update your court selection!'}
          </BulletedItem>
        </Stack>
      </Stack>

      {/* Group Summary */}
      <Stack nativeID="read-group-section">
        <LabelRow>
          <RentalSectionLabel>Guests</RentalSectionLabel>
          {onEditPress && <RentalEditChip onPress={() => onEditPress('group-settings')} />}
        </LabelRow>
        <Text>
          {groupSettings.groupSize} guest
          {`${groupSettings.groupSize! > 1 ? 's' : ''}`}
        </Text>
        {groupSettings.groupPasswordEnabled && (
          <LabelRow>
            <Stack flex={1} pl={4}>
              <BulletedItem _text={{ isTruncated: true }}>
                {`Password: ${showPassword ? groupSettings.groupPassword : maskedPassword}`}
              </BulletedItem>
            </Stack>
            <Pressable onPress={() => setShowPassword(!showPassword)} width={25}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </Pressable>
          </LabelRow>
        )}
      </Stack>
    </Stack>
  );
};

export default RentalBookingSummary;
