import { type FC, type ReactNode } from 'react';
import { Stack, Text, View } from '../../../base-components';

type HowItWorksListItemProps = {
  title: string;
  number: number;
  children: ReactNode;
};

const HowItWorksListItem: FC<HowItWorksListItemProps> = ({ title, number, children }) => (
  <Stack alignItems="center" px={8}>
    <View
      h={10}
      w={10}
      mb={2}
      alignItems="center"
      borderRadius="full"
      justifyContent="center"
      backgroundColor="primary.200"
    >
      <Text bold fontSize="2xl" color="primary.600">
        {number}
      </Text>
    </View>
    <Text type="p" textAlign="center" bold>
      {title}
    </Text>
    <Text type="p" textAlign="center">
      {children}
    </Text>
  </Stack>
);

export default HowItWorksListItem;
