import { useSuspenseQuery, type SuspenseQueryHookOptions } from '@apollo/client';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { RoleEnum } from '@rivallapp/volosports-components';
import { graphql, type ResultOf } from 'graphql-schema';
import hasuraClient from '../apollo/hasuraClient';
import { useAuthStore } from '../zustand-stores';

/**
 * This hook returns the current user signed in as well as the current organizationId
 * that is being used (from the admin drop down or url params)
 * It also does organization validation to make sure the user has authorization to view the
 * selected organization
 */

const CURRENT_USER_QUERY = graphql(`
  query CurrentUser {
    currentUser {
      _id
      organization_admins {
        _id
        organizationByOrganization {
          _id
          name
          logo
          icon
        }
      }
    }
  }
`);

type CurrentUserData = ResultOf<typeof CURRENT_USER_QUERY>;

const useCurrentUserData = (data: CurrentUserData | undefined) => {
  const { search } = useLocation();

  const roles = useAuthStore(store => store.roles);

  if (!data || !data?.currentUser?._id) {
    return { error: 'No logged in user found.' };
  }

  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const organizations = data.currentUser.organization_admins.map(
    o => o.organizationByOrganization._id
  );

  let organizationId = organizations[0];

  const queryOrgId = (query?.organization || organizationId) as string | undefined;

  // No permission check needed if no ord
  if (!queryOrgId || queryOrgId === organizationId) {
    return {
      organizationId,
      currentUser: {
        ...data.currentUser,
        organizationId,
      },
    };
  }

  // city admin
  if (roles.includes(RoleEnum.OWNER) && organizations.includes(queryOrgId)) {
    organizationId = queryOrgId;
  }
  // super admin
  if (roles.includes(RoleEnum.ADMIN)) {
    organizationId = queryOrgId;
  }

  return {
    organizationId,
    currentUser: {
      ...data.currentUser,
      organizationId,
    },
  };
};

const useCurrentUserV2 = (options?: SuspenseQueryHookOptions<CurrentUserData>) => {
  const { data, ...rest } = useSuspenseQuery(CURRENT_USER_QUERY, {
    client: hasuraClient,
    ...options,
  });

  const currentUserData = useCurrentUserData(data);

  return { ...rest, ...currentUserData };
};

export default useCurrentUserV2;
