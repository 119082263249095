/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps } from 'react';
import { type AccessibilityRole, Platform } from 'react-native';
import { Row, Text, Tooltip } from '../../../base-components';
import { type TableCellProps } from './TableCell';
import { useTableContext } from '../context/TableProvider';
import childrenAreRenderable from '../utils/childrenAreRenderable';

const isWeb = Platform.OS === 'web';

type TooltipProps = ComponentProps<typeof Tooltip>;

type TableHeaderProps = Omit<TableCellProps, 'accessibilityRole'> & {
  /**
   * Overrides the default `accessibilityRole` prop with the two relevant
   * header cell options.
   *
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/columnheader_role
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/rowheader_role
   *
   * @default 'columnheader'
   */
  accessibilityRole?: 'columnheader' | 'rowheader';
  /**
   * Optionally wrap the header cell in a tooltip. If a valid string is provided
   * through this prop, the tooltip will be enabled. This will only render on web.
   */
  tooltip?: TooltipProps['label'];
  /**
   * Positioning of the tooltip relative to the cell.
   */
  tooltipPlacement?: TooltipProps['placement'];
};

const TableHeader: React.FC<TableHeaderProps> = ({
  children,
  textProps,
  tooltip,
  tooltipPlacement = 'bottom',
  accessibilityRole = 'columnheader',
  ...props
}) => {
  const role = isWeb ? (accessibilityRole as AccessibilityRole) : undefined;

  const { defaultHeaderCellProps, defaultHeaderTextProps } = useTableContext();

  const component = (
    <Row accessibilityRole={role} {...defaultHeaderCellProps} {...props}>
      {childrenAreRenderable(children) ? (
        children
      ) : (
        <Text {...defaultHeaderTextProps} {...textProps}>
          {children}
        </Text>
      )}
    </Row>
  );

  if (tooltip && isWeb) {
    return (
      <Tooltip label={tooltip} placement={tooltipPlacement}>
        {component}
      </Tooltip>
    );
  }

  return component;
};

export default TableHeader;
