import moment from 'moment-timezone';

import { type Court, type RentalCourtSelection } from '../../RegistrationFlowSections';
import { courtStepSelector } from '../rentalBookingStore.selectors';
import useRentalBookingStore from '../useRentalBookingStore';
import { timeSlotApiFormat } from '../../RegistrationFlowSections/steps.constants';

function parseTimeStr(time: string) {
  return moment(time, timeSlotApiFormat);
}

const updateSelectedCourts = (
  newCourts:
    | RentalCourtSelection['selectedCourts']
    | ((
        currentCourts: RentalCourtSelection['selectedCourts']
      ) => RentalCourtSelection['selectedCourts'])
) => {
  useRentalBookingStore.setState(state => {
    const { currentBooking } = state;
    if (!currentBooking) {
      return state;
    }

    const { steps } = currentBooking;
    const { courtsSelection: currentCourtsSelection } = steps;

    return {
      currentBooking: {
        ...currentBooking,
        steps: {
          ...steps,
          courtsSelection: {
            selectedCourts:
              typeof newCourts === 'function'
                ? newCourts(currentCourtsSelection.selectedCourts)
                : newCourts,
          },
        },
      },
    };
  });
};

const findOverlappingCourts = () => {
  const { confirmedBookings, inProgressBookings, currentBooking } =
    useRentalBookingStore.getState();
  if (!currentBooking) {
    return [];
  }

  // grab current booking data
  const {
    rentalId,
    steps: {
      dateSelection: { startDate: currentStartDate, timeSlots: currentTimeSlots },
    },
  } = currentBooking;

  // we don't want to check against bookings that are in edit mode OR in progress either
  // - for edit mode, thats self explanatory, if we should pending in edit mode, they wouldn't be able to change what they already selected
  // - for in progress, we don't want them to be able to go to edit mode, while they have pending selections in another pending booking, as that would allow them to try and reserve the same court in multiple bookings
  // which would not work as it currently needs to have the same court available across the entire time range
  // for each booking
  const nonEditModeBookings = [
    ...Object.values(confirmedBookings).filter(
      booking => !booking.isEditMode || booking.bookingId !== currentBooking.bookingId
    ),
    ...Object.values(inProgressBookings).filter(
      booking => booking.bookingId !== currentBooking.bookingId
    ),
  ];

  // check if there are any other inProgress bookings that are for the same date
  const otherBookingsOnSameDate = Object.values(nonEditModeBookings).filter(booking => {
    return (
      booking.rentalId === rentalId &&
      moment(booking.steps.dateSelection.startDate).isSame(moment(currentStartDate), 'day')
    );
  });

  if (otherBookingsOnSameDate.length === 0) {
    return [];
  }

  // check to see if any courts are overlapping in other bookings
  // by checking if the start and end times overlap in the current booking
  const overlappedCourts = otherBookingsOnSameDate.flatMap(otherBooking => {
    const {
      steps: {
        courtsSelection: { selectedCourts: otherSelectedCourts },
        dateSelection: { timeSlots: otherTimeSlots },
      },
    } = otherBooking;

    return otherSelectedCourts.filter(() => {
      return currentTimeSlots.some(currentTimeSlot => {
        const currentStartTime = parseTimeStr(currentTimeSlot.rentalBlock.start_time_str);
        const currentEndTime = parseTimeStr(currentTimeSlot.rentalBlock.end_time_str);
        const bookingStartTime = parseTimeStr(otherTimeSlots[0]!.rentalBlock.end_time_str);
        const bookingEndTime = parseTimeStr(
          otherTimeSlots[otherTimeSlots.length - 1]!.rentalBlock.end_time_str
        );

        return (
          moment(currentStartTime).isBefore(bookingEndTime) &&
          moment(currentEndTime).isAfter(bookingStartTime)
        );
      });
    });
  });

  return overlappedCourts;
};

const unSelectBookedCourts = (courts: Court[], onCourtsAreNowNotAvailable: () => void) => {
  const mySelectedCourts =
    useRentalBookingStore.getState().currentBooking?.steps.courtsSelection.selectedCourts;

  if (!mySelectedCourts?.length) return;

  const courtsAreNowBooked = mySelectedCourts.some(selectedCourt =>
    courts.some(court => court._id === selectedCourt._id && !court.is_available)
  );
  const courtsHaveBeenDeleted = mySelectedCourts.some(
    selectedCourt => !courts.some(court => court._id === selectedCourt._id)
  );

  if (courtsAreNowBooked || courtsHaveBeenDeleted) {
    useRentalBookingStore.setState(state => {
      if (!state.currentBooking) return state;

      const newSelectedCourts = state.currentBooking.steps.courtsSelection.selectedCourts.filter(
        selectedCourt => courts.some(court => court._id === selectedCourt._id && court.is_available)
      );

      return {
        currentBooking: {
          ...state.currentBooking,
          steps: {
            ...state.currentBooking.steps,
            courtsSelection: {
              ...state.currentBooking.steps.courtsSelection,
              selectedCourts: newSelectedCourts,
            },
          },
        },
      };
    });

    onCourtsAreNowNotAvailable();
  }
};

const useRentalBookingCourts = () => {
  const { selectedCourts, isStepValid, rentalId } = useRentalBookingStore(courtStepSelector);

  return {
    rentalId,
    isStepValid,
    selectedCourts,
    updateSelectedCourts,
    /**
     * Find any courts that are pending in another confirmed booking
     * - checks the current booking against all other confirmed bookings with the same `rentalId`
     * - has to be on the same `startDate` and have overlapping `timeSlots`
     */
    findOverlappingCourts,

    /**
     * During the booking process, it is possible to have courts in my booking that somone else books before me.
     * - if that happens, we need to be able to remove those courts from my booking automatically when editing the booking
     */
    unSelectBookedCourts,
  };
};

export default useRentalBookingCourts;
