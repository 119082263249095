import moment, { type MomentInput } from 'moment-timezone';

/**
 * Sort time ranges by start time
 * Check if the end time of the current range is after the start time of the next range.
 *
 * @param timeRanges - Array of time ranges: [start, end] where `start` and `end` are moment-compatible inputs.
 *
 * @example
 * ```
 * const 9am = moment('9:00', 'HH:mm')
 * const 10am = moment('10:00', 'HH:mm')
 * const 11am = moment('11:00', 'HH:mm')
 * const 1230pm = moment('12:30', 'HH:mm')
 * const 1101am = moment('11:01', 'HH:mm')
 * const yesterday = moment().subtract(1, 'day');
 * const tonight = moment().endOf('day');
 * const future = new Date('2030-01-01 08:00:00');
 *
 * const timeRangesA = [ [9am, 10am], [10am, 11am] ];
 * const timeRangesB = [ [9am, 10am], [11am, 1230pm], [10am, 1101am] ];
 * const timeRangesC = [ [tonight, future], [yesterday, 9am] ];
 * const timeRangesD = [ [ yesterday, future ], [ 9am, tonight ] ]
 *
 * checkTimeRangesOverlap(timeRangesA); // false
 * checkTimeRangesOverlap(timeRangesB); // true
 * checkTimeRangesOverlap(timeRangesC); // false
 * checkTimeRangesOverlap(timeRangesD); // true
 * ```
 *
 * @returns `true` if there is an overlap.
 */
/* eslint-disable import/prefer-default-export */
export const checkTimeRangesOverlap = (timeRanges: [MomentInput, MomentInput][]) => {
  const sortedTimeRanges = [...timeRanges].sort((a, b) => {
    const aStart = moment(a[0]);
    const bStart = moment(b[0]);
    return aStart.diff(bStart);
  });

  for (let i = 0; i < sortedTimeRanges.length - 1; i += 1) {
    const currentEnd = moment(sortedTimeRanges[i]?.[1]);
    const nextStart = moment(sortedTimeRanges[i + 1]?.[0]);
    if (currentEnd.isAfter(nextStart)) {
      return true;
    }
  }

  return false;
};
