import { gql, type TypedDocumentNode } from '@apollo/client';

export type GetVenueMapData = {
  venue: {
    _id: string;
    venue_map_url?: string;
  };
};

export type GetVenueMapInput = {
  venueId: string;
};

const GET_VENUE_MAP: TypedDocumentNode<GetVenueMapData, GetVenueMapInput> = gql`
  query GetVenueMap($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      venue_map_url
    }
  }
`;

export default GET_VENUE_MAP;
