import React, { type ComponentProps } from 'react';
import { Text } from '../../../base-components';

const SaleCopyText: React.FC<ComponentProps<typeof Text>> = props => (
  <Text
    color="white.400"
    textTransform="uppercase"
    flex={1}
    fontSize="sm"
    lineHeight="md"
    fontWeight="medium"
    fontStyle="italic"
    {...props}
  />
);

export default SaleCopyText;
