import { StyleSheet } from 'react-native';
import { colors } from '../../constants';

export default StyleSheet.create({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.brandPrimary,
  },
  white: {
    color: colors.white,
  },
  inline: {
    display: 'flex',
    height: 18,
    width: 67,
  },
  loader: {
    height: 30,
    width: 30,
  },
  loadingMessage: {
    fontSize: 16,
    marginLeft: 15,
  },
});
