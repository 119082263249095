/*
  This is the main entrypoint for the client.
  In other words, this is the first thing that is read
  when the client receives the bundle
*/
import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@rivallapp/volosports-components';
import { BrowserRouter } from 'react-router-dom';

import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import apolloClient from './apollo/client';
import {
  isProduction,
  remoteEnv,
  isLocal,
  ROOT_ELEMENT_ID,
  sentryDSN,
  brazeKey,
  brazeEndpoint,
  reCaptchaSiteKey,
} from './environment';
import Braze from './utils/braze';
import { App } from './pages';
import { OrganizationsProvider } from './contexts/OrganizationsContext';
import { RegistrationProvider } from './contexts';
import { PageLoader } from './components';

if (!isLocal) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
    tracesSampleRate: isProduction ? 0.4 : 1,
    environment: remoteEnv,
  });
}

const brazeClient = new Braze();

// TODO: Options to discuss:
// appVersion
// enableSdkAuthentication
brazeClient.initialize(brazeKey, {
  baseUrl: brazeEndpoint,
  enableLogging: isLocal,
});

// TODO: Add dynamic helmet metadata on react router navigation, post page load
const render = () => {
  // Generates required css for react-native-vector-icons
  // info here: https://github.com/oblador/react-native-vector-icons#web-with-webpack
  const iconFontStyles = `@font-face {
    src: url(${iconFont});
    font-family: FontAwesome;
  }`;

  // Create stylesheet
  const style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);

  const Application = (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider>
          <BrowserRouter>
            <OrganizationsProvider>
              <RegistrationProvider>
                <Suspense fallback={<PageLoader />}>
                  <App />
                </Suspense>
              </RegistrationProvider>
            </OrganizationsProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </GoogleReCaptchaProvider>
  );

  const rootElement = document.querySelector(`#${ROOT_ELEMENT_ID}`);
  const root = createRoot(rootElement);
  root.render(Application);
};

render();
