import { Button, Heading, Row, Stack, Text, type IStackProps } from '../../base-components';
import openLink from '../../utilities/openLink';
import { StaticUrls } from '../../constants/enums/static-urls-enum';
import { MembershipEnum, Memberships, type MembershipName } from '../../constants';

interface MembershipCancellationProps {
  /** Semantic name of membership - Volo Pass, Volo Fitness, etc. */
  membershipName: MembershipName;
  /** MM/DD/YYYY */
  expiration: string;
  onCancel: VoidFunction;
  cancelText?: string;
  confirmText?: string;
  _containerProps?: IStackProps;
}

/**
 * Membership cancellation prompt with a confirmation button that opens a link to the cancellation page.
 * Buttons are basically replicated from `ConfirmCancelModal` with different styling.
 * This component will likely be replaced by an actual cancellation flow in the future.
 */
const MembershipCancellation = ({
  membershipName,
  expiration,
  onCancel,
  cancelText = 'Continue Membership',
  confirmText = 'End',
  _containerProps = {},
}: MembershipCancellationProps) => {
  const handleConfirm = () => {
    switch (membershipName) {
      case Memberships[MembershipEnum.VOLO_PASS].name:
        openLink(StaticUrls.CANCEL_VOLO_PASS);
        break;
      case Memberships[MembershipEnum.VOLO_FITNESS].name:
        openLink(StaticUrls.CANCEL_VOLO_FITNESS);
        break;
      default:
        break;
    }
  };

  const headerText = `Are you sure you wish to end your ${membershipName} membership?`;
  const bodyText = `You will still be able to enjoy your benefits until ${expiration}, and you may reinstate your membership at any time. Any active promo pricing will be forfeited unless you reinstate your membership before the scheduled expiration date.`;

  return (
    <Stack space={4} {..._containerProps}>
      <Heading fontSize="md">{headerText}</Heading>
      <Text>{bodyText}</Text>
      <Row space={5}>
        <Button flex={1} onPress={handleConfirm} colorScheme="error">
          {confirmText}
        </Button>
        <Button flex={4} onPress={onCancel}>
          {cancelText}
        </Button>
      </Row>
    </Stack>
  );
};
export default MembershipCancellation;
