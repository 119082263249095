import { MembershipEnum } from '../../../constants';
import {
  type DropInPricingResponse,
  type PricingBreakdown,
  type PricingQueryResponse,
} from '../gql/PRICING_QUERY';
import type { Pricing } from '../PaymentSummary';

/**
 * This function accepts the query response from either PRICING_QUERY or DROP_IN_PRICING_QUERY and
 * returns the data in a common format that can be consumed by the UI regardless of if the program
 * is a Drop-In or non-Drop-In. We should consider refactoring our pricing queries on the backend
 * to make the schema line up more consistently.
 */
const normalizePricingQueryData = (
  data: PricingQueryResponse | DropInPricingResponse
): Record<Pricing, PricingBreakdown['pricingBreakdown'] | null> => {
  // Non-drop-in data, e.g. Leagues, Pickups, Etc.
  if ('regularPricing' in data) {
    return {
      REGULAR: data.regularPricing.pricingBreakdown,
      [MembershipEnum.VOLO_PASS]: data.voloPassPricing.pricingBreakdown,
      [MembershipEnum.VOLO_FITNESS]: data.voloFitnessPricing.pricingBreakdown,
    };
  }

  // Drop-in data
  if ('dropinPricingForRegistration' in data) {
    const {
      nonmemberBreakdown,
      memberBreakdown,
      voloFitnessBreakdown,
      currentUserPaymentCriteria,
    } = data.dropinPricingForRegistration;

    const criteriaToMembershipEnum: Record<
      DropInPricingResponse['dropinPricingForRegistration']['currentUserPaymentCriteria'],
      PricingBreakdown['pricingBreakdown']['membershipDiscountApplied']
    > = {
      nonmemberBreakdown: null,
      memberBreakdown: MembershipEnum.VOLO_PASS,
      voloFitnessBreakdown: MembershipEnum.VOLO_FITNESS,
    };

    const membershipDiscountApplied = criteriaToMembershipEnum[currentUserPaymentCriteria];

    const originalPrice = nonmemberBreakdown?.programPriceCents ?? null;
    const memberPrice = memberBreakdown?.programPriceCents ?? null;
    const voloFitnessPrice = voloFitnessBreakdown?.programPriceCents ?? null;
    return {
      REGULAR: nonmemberBreakdown
        ? {
            ...nonmemberBreakdown,
            originalPrice,
            memberPrice,
            voloFitnessPrice,
            membershipDiscountApplied,
          }
        : null,
      [MembershipEnum.VOLO_PASS]: memberBreakdown
        ? {
            ...memberBreakdown,
            originalPrice,
            memberPrice,
            voloFitnessPrice,
            membershipDiscountApplied,
          }
        : null,
      [MembershipEnum.VOLO_FITNESS]: voloFitnessBreakdown
        ? {
            ...voloFitnessBreakdown,
            originalPrice,
            memberPrice,
            voloFitnessPrice,
            membershipDiscountApplied,
          }
        : null,
    };
  }

  throw new Error('No pricing could be found for this program!');
};

export default normalizePricingQueryData;
