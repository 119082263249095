import { gql, type TypedDocumentNode } from '@apollo/client';
import type { RSVPReminderType } from '../../../constants';

interface SendRSVPReminderInput {
  input: {
    gameId: string;
    teamId: string;
    methods: {
      push: boolean;
      email: boolean;
      /** As of 8/1/24 this will always be false (may change if business decides to support SMS reminders in the future) */
      sms: boolean;
    };
    reminder_type: RSVPReminderType;
    /** REQUIRED if `reminder_type === 'individual'` */
    recipient_id?: string;
  };
}

interface SendRSVPReminderData {
  sendRSVPReminder: {
    status: string;
  };
}

const SEND_RSVP_REMINDER: TypedDocumentNode<SendRSVPReminderData, SendRSVPReminderInput> = gql`
  mutation sendRSVPReminder($input: SendRSVPReminderInput!) {
    sendRSVPReminder(input: $input) {
      status
    }
  }
`;

export default SEND_RSVP_REMINDER;
