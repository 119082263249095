import { useState, useCallback } from 'react';
import CalendarPicker, { type CustomDayHeaderStylesFunc } from 'react-native-calendar-picker';

import { Colors } from '../../../theme';
import { screenWidth } from '../../../constants/globalStyles';
import { Image, View, Overlay, Pressable } from '../../../base-components';
import { CustomButton } from '../../../custom-components';
import styles from '../styles';

type DateFilterType = {
  onClear: () => void;
  onConfirm: (arg1: object, arg2: object) => void;
  dateLow?: {
    isValid: () => void | undefined;
  };
  dateHigh?: {
    isValid: () => void | undefined;
  };
};

const calendarStyle = {
  color: Colors.primary,
  height: 48,
  width: 48,
};

const caretStyle = {
  color: Colors.primary,
  height: 18,
  width: 18,
};

const calendar = require('../../../../assets/icons/calendar-days-voloBlue.png');
const caretBack = require('../../../../assets/icons/caret-left-voloBlue.png');
const caretForward = require('../../../../assets/icons/caret-right-voloBlue.png');

const DateFilter = ({ onClear, onConfirm, dateLow, dateHigh }: DateFilterType) => {
  const [hideCalendar, setHideCalendar] = useState(true);

  const [low, setLow] = useState(dateLow);
  const [high, setHigh] = useState(dateHigh);

  const handleDayHeaderStyle = useCallback<CustomDayHeaderStylesFunc>(
    () => ({ textStyle: { fontWeight: 'bold' } }),
    []
  );

  const handleDayPress = useCallback(
    (newDate: any, which: string) => {
      if (which === 'END_DATE') {
        setHigh(newDate);
      } else {
        setLow(newDate);
      }
    },
    [setLow, setHigh]
  );

  const calendarWidth = screenWidth < 400 ? screenWidth - 32 : 400;

  return (
    <View>
      <Pressable style={styles.calendarIcon} onPress={() => setHideCalendar(false)}>
        <Image alt="Calendar Icon" source={calendar} resizeMode="contain" style={calendarStyle} />
      </Pressable>
      <Overlay
        showX={false}
        modalStyle={{ justifyContent: 'flex-start', marginTop: 200 }}
        contentStyle={{ width: calendarWidth, padding: 10 }}
        show={!hideCalendar}
        close={() => setHideCalendar(true)}
      >
        <CalendarPicker
          allowRangeSelection
          height={400}
          width={calendarWidth}
          todayBackgroundColor={Colors.white as string}
          restrictMonthNavigation
          minDate={new Date()}
          selectedStartDate={low as unknown as Date}
          selectedEndDate={high as unknown as Date}
          selectedDayColor={Colors.accent as string}
          selectedDayTextColor={Colors.black as string}
          customDayHeaderStyles={handleDayHeaderStyle}
          onDateChange={handleDayPress}
          showDayStragglers
          nextComponent={
            <Image alt="Next" source={caretForward} resizeMode="contain" style={caretStyle} />
          }
          previousComponent={
            <Image alt="Back" source={caretBack} resizeMode="contain" style={caretStyle} />
          }
        />
        <View style={[styles.row, styles.padding]}>
          <CustomButton
            variant="outline"
            onPress={() => {
              if (onClear) onClear();
              setLow(undefined);
              setHigh(undefined);
              setHideCalendar(true);
            }}
            style={[styles.button, styles.fullWidth]}
            value={low ? 'Clear' : 'Cancel'}
          />
          <CustomButton
            value="Results"
            style={[styles.button, styles.fullWidth]}
            onPress={() => {
              if (onConfirm && low && high) onConfirm(low, high);
              setHideCalendar(true);
            }}
          />
        </View>
      </Overlay>
    </View>
  );
};

export default DateFilter;
