export const StripeInvoiceStatus = Object.freeze({
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible',
  VOID: 'void',
});

export const StripeDiscountDuration = Object.freeze({
  ONCE: 'once',
  REPEATING: 'repeating',
  FOREVER: 'forever',
});

export enum StripeSubscriptionStatusEnum {
  ACTIVE = 'active',
  TRIALING = 'trialing',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  PAUSED = 'paused',
}

export enum StripePaymentStatus {
  PENDING = 'pending',
  ERROR = 'error',
  OK = 'ok',
  REFUNDED = 'refunded',
  PARTIALLY_REFUNDED = 'partially_refunded',
}

export enum StripeChargeStatus {
  SUCCEEDED = 'succeeded',
  PENDING = 'pending',
  FAILED = 'failed',
}

export enum StripeMembershipIntervalEnum {
  MONTH = 'month',
  YEAR = 'year',
}
