import { StyleSheet } from 'react-native';
import { greys, globalMargin } from '../../constants';

export default StyleSheet.create({
  border: {
    flex: 1,
    height: 0,
    borderBottomWidth: 1,
    borderColor: greys.medium,
    marginHorizontal: globalMargin,
  },
  margin: {
    marginVertical: globalMargin,
  },
  title: {
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
