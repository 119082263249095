import { Text, type ITextProps, Heading, type HeadingProps } from '../../../base-components';

/**
 * Style chosen specifically to match `CollapsibleBase` title style so that all section headings look the same
 */
export const RentalHeading = (props: HeadingProps) => <Heading fontSize={18} {...props} />;

export const RentalText = (props: ITextProps) => <Text fontSize={14} {...props} />;

export const RentalTextSm = (props: ITextProps) => (
  <Text fontSize={13} numberOfLines={1} {...props} />
);
