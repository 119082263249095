import uniq from 'lodash.uniq';
import { useState, type FC } from 'react';
import { Button, Column, Divider, Heading, Row, Switch, Text } from '../../../../base-components';
import {
  ExpandableCheckboxTree,
  type ExpandableCheckBoxTreeProps,
} from '../../../../custom-components';
import {
  GenderEnum_TS,
  Genders,
  RentalRegistrantRoles,
  RentalRegistrantTypes,
} from '../../../../constants';
import { useMessageCenterStore } from '../../store';

const AllowedFiltersGender: ExpandableCheckBoxTreeProps[] = [
  {
    label: 'Gender',
    value: 'genders',
    isMain: true,
    subTree: Object.values(Genders).map(({ value, name }) => ({ label: name, value })),
  },
];

const AllowedFiltersPlayerType: ExpandableCheckBoxTreeProps[] = [
  {
    label: 'Player Type',
    value: 'playerTypes',
    isMain: true,
    subTree: Object.values(RentalRegistrantRoles).map(({ value, label }) => ({
      value,
      label: `${label}s`,
    })),
  },
];

const Filters: FC = () => {
  const { genders, playerTypes, voloPassOnly } = useMessageCenterStore(store => ({
    genders: store.filters.genders,
    playerTypes: store.filters.playerTypes,
    voloPassOnly: store.filters.voloPassOnly,
  }));

  const [genderMainItems, setGenderMainItems] = useState<string[]>([]);
  const [playerTypeMainItems, setPlayerTypeMainItems] = useState<string[]>([]);

  const onPressGenderItem = (main: string[], subArray: string[]) => {
    setGenderMainItems(main);
    useMessageCenterStore.setState(partial => ({
      filters: {
        ...partial.filters,
        genders: uniq(subArray) as GenderEnum_TS[],
      },
    }));
  };

  const onPressPlayerTypeItem = (main: string[], subArray: string[]) => {
    setPlayerTypeMainItems(main);
    useMessageCenterStore.setState(partial => ({
      filters: {
        ...partial.filters,
        playerTypes: uniq(subArray) as RentalRegistrantTypes[],
      },
    }));
  };

  const onPressClearFilters = () => {
    useMessageCenterStore.setState(partial => ({
      filters: {
        ...partial.filters,
        playerTypes: [],
        genders: [],
        voloPassOnly: false,
      },
    }));
    setGenderMainItems([]);
    setPlayerTypeMainItems([]);
  };

  return (
    <Column>
      <Heading fontSize="lg" marginBottom="4" color="gray.700">
        Filter By
      </Heading>
      <ExpandableCheckboxTree
        data={AllowedFiltersGender}
        mainItemsChecked={genderMainItems}
        subItemsChecked={genders}
        onPressItem={onPressGenderItem}
      />
      <Divider orientation="horizontal" my="4" />
      <ExpandableCheckboxTree
        data={AllowedFiltersPlayerType}
        mainItemsChecked={playerTypeMainItems}
        subItemsChecked={playerTypes}
        onPressItem={onPressPlayerTypeItem}
      />
      <Divider orientation="horizontal" my="4" />
      <Row space="2" alignItems="center">
        <Switch
          isChecked={!!voloPassOnly}
          onValueChange={value =>
            useMessageCenterStore.setState(partial => ({
              filters: { ...partial.filters, voloPassOnly: value },
            }))
          }
        />
        <Text>Volo Pass Members</Text>
      </Row>
      <Button marginTop="4" onPress={onPressClearFilters}>
        Clear All Filters
      </Button>
    </Column>
  );
};

export default Filters;
