import { type ITheme, theme } from '@rivallapp/native-base';
import type { CustomSpacing } from './types';

/**
 * The naming conventions in this file are based on NB structures - sizing & spacing share values.
 */

export const CustomSizes: CustomSpacing = {
  '1/8': '12.5%',
  '2/8': '25%',
  '3/8': '37.5%',
  '4/8': '50%',
  '5/8': '62.5%',
  '6/8': '75%',
  '7/8': '87.5%',
};

export const Sizes: ITheme['sizes'] & CustomSpacing = {
  ...theme.sizes,
  ...CustomSizes,
};

export const Spacing: ITheme['space'] & CustomSpacing = {
  ...theme.space,
  ...CustomSizes,
};
