import GET_ORGANIZATION from './gql/GET_ORGANIZATION';

/**
 * @description Global location for organization query.Variations can be added with different org properties
 * Try to add fields to existing variants before creating a brand new one.
 * Adding fields shouldn't affect existing uses, but don't delete unless you're **sure** the fields are unused.
 * Add notes when updating this doc.
 */

/** Returns Super Sportz.
 * Volo Pass and Volo Fitness are active */
// #region Super Sportz
export const getOrganizationSuperSportz = [
  {
    request: {
      query: GET_ORGANIZATION,
      variables: {
        id: '5cd91a7033db650050758e2f',
      },
    },
    result: {
      data: {
        organization: {
          _id: '5cd91a7033db650050758e2f',
          name: 'Super Sportz',
          voloPassTiers: {
            isActive: true,
            monthlyPlanId: 'price_1KbsJbCyzbeSckFnZYCgob8d',
            monthlyDollarPrice: 35,
            monthlyFee: 0.99,
            annualPlanId: 'price_1KbsKWCyzbeSckFnBVjsjgIk',
            annualDollarPrice: 350,
            annualSavings: 70,
            annualFee: 4.99,
          },
          voloFitnessTiers: {
            isActive: true,
            monthlyPlanId: 'price_1PVIKzCyzbeSckFn5q0QJI9X',
            annualPlanId: 'price_1PVINoCyzbeSckFnZIYUPPEc',
            monthlyDollarPrice: 49.99,
          },
        },
      },
    },
  },
];
// #endregion

/** Returns Doggone Fun
 * Volo Pass is active and Volo Fitness is not
 */

// #region Doggone Fun
export const getOrganizationDoggoneFun = [
  {
    request: {
      query: GET_ORGANIZATION,
      variables: {
        id: '5cddbbd052fb9e00500024a6',
      },
    },
    result: {
      data: {
        organization: {
          _id: '5cd91a7033db650050758e2f',
          name: 'Super Sportz',
          voloPassTiers: {
            isActive: true,
            monthlyPlanId: 'price_1P0mDwCyzbeSckFnk1GJb1ho',
            annualPlanId: 'price_1P0mEPCyzbeSckFnL5sYXMmp',
            monthlyDollarPrice: 20,
            annualDollarPrice: 200,
            annualSavings: 40,
          },
          voloFitnessTiers: {
            isActive: false,
            monthlyPlanId: '',
            annualPlanId: '',
            monthlyDollarPrice: null,
          },
        },
      },
    },
  },
];
// #endregion
