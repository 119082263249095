import { graphql } from 'graphql-schema';
import { useSuspenseQuery } from '@apollo/client';
import hasuraClient from '../apollo/hasuraClient';

const ORGANIZATIONS = graphql(`
  query OrganizationsList {
    organizations {
      _id
      name
    }
  }
`);

const useOrganizations = () => {
  const result = useSuspenseQuery(ORGANIZATIONS, { client: hasuraClient });

  return { ...result, organizations: result.data.organizations };
};

export default useOrganizations;
