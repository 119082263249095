import { type FC } from 'react';
import { Text } from '../../../../base-components';
import { type RentalPricingModule } from '../../RentalCard/types';

const RentalPricingSection: FC<Omit<RentalPricingModule, 'type'>> = ({ lowPrice, highPrice }) => {
  return (
    <Text bold fontSize={12}>
      ${lowPrice}
      {highPrice ? `-${highPrice}` : ''}
      <Text fontSize={9}>/HR</Text>
    </Text>
  );
};

export default RentalPricingSection;
