/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps } from 'react';
import { Table } from '../../../custom-components/Table';
import { View, Text } from '../../../base-components';
import { type TeamStandings } from '../graphql/useLeagueStandingsQuery';

type Props = {
  team: TeamStandings['team'];
  hideColorName?: boolean;
} & ComponentProps<typeof View>;

const TeamNameCell: React.FC<Props> = ({ team, hideColorName, ...rest }) => {
  const { color, name } = team;
  return (
    <Table.Cell {...rest}>
      <View
        mx={2}
        width={3}
        height={3}
        borderWidth={1}
        borderRadius="full"
        borderColor="black"
        backgroundColor={color?.hex || 'gray.300'}
      />
      <Text alignItems="baseline" flex={1}>
        <Text fontSize="xs" mr={1}>
          {name}
        </Text>
        {!hideColorName && !!color?.name && (
          <Text fontSize="xs" color="gray.400">
            ({color.name})
          </Text>
        )}
      </Text>
    </Table.Cell>
  );
};

export default TeamNameCell;
