import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { Auth, type LoginArgs } from '../zustand-stores/AuthStore';
import useAlertMessage from './useAlertMessage';

type UseLoginArgs = {
  /**
   * Should login errors display toast messages.
   * @default false
   */
  showToasts?: boolean;
};

type LoginAndRedirectArgs = LoginArgs & {
  /** Override default redirect logic by providing a pathname */
  redirectPath?: string;
};

/**
 * Provides basic login functionality through two callbacks:
 *
 * `loginAndRedirect` Login and redirect the user to a different page.
 *
 * `loginAndRefresh` Login and reload the current page.
 */
const useLogin = ({ showToasts = false }: UseLoginArgs = {}) => {
  const history = useHistory();
  const { showError } = useAlertMessage();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  /** Clear the existing error from state */
  const clearError = () => setError(null);

  /**
   * Login and redirect to a different page. Best suited for the login page.
   * TODO [MIGRATION]: Replace with loginWithHasuraAndRedirect once we're fully migrated
   * */
  const loginAndRedirect = useCallback(
    async ({ redirectPath, ...credentials }: LoginAndRedirectArgs) => {
      try {
        setLoading(true);
        await Auth.login(credentials);
        const url = redirectPath || Auth.getLoginRedirectPath();
        history.replace(url);
      } catch (e) {
        const err = e as AxiosError<{ message: string }>;
        setError(err?.response?.data?.message || 'Error logging in');
        console.error('Error logging in..');
        if (showToasts) showError('Error logging in..');
      } finally {
        setLoading(false);
      }
    },
    [showError, showToasts, history]
  );

  /**
   * Login and reload the window. Best suited for the login modal.
   * TODO [MIGRATION]: Replace with loginWithHasuraAndRefresh once we're fully migrated
   * */
  const loginAndRefresh = useCallback(
    async (credentials: LoginArgs) => {
      try {
        setLoading(true);
        await Auth.login(credentials);
        window.location.reload();
      } catch (e) {
        const err = e as AxiosError<{ message: string }>;
        setError(err?.response?.data?.message || 'Error logging in');
        console.error('Error logging in..');
        if (showToasts) showError('Error logging in..');
      } finally {
        setLoading(false);
      }
    },
    [showToasts, showError]
  );

  /** Login and redirect to a different page. Best suited for the login page. */
  const loginWithHasuraAndRedirect = useCallback(
    async ({ redirectPath, ...credentials }: LoginAndRedirectArgs) => {
      try {
        setLoading(true);
        await Auth.loginWithHasura(credentials);
        const url = redirectPath || Auth.getLoginRedirectPath();
        history.replace(url);
      } catch (e) {
        const err = e as AxiosError<{ message: string }>;
        setError(err?.response?.data?.message || 'Error logging in');
        console.error('Error logging in..');
        if (showToasts) showError('Error logging in..');
      } finally {
        setLoading(false);
      }
    },
    [showError, showToasts, history]
  );

  /** Login and reload the window. Best suited for the login modal. */
  const loginWithHasuraAndRefresh = useCallback(
    async (credentials: LoginArgs) => {
      try {
        setLoading(true);
        await Auth.loginWithHasura(credentials);
        window.location.reload();
      } catch (e) {
        const err = e as AxiosError<{ message: string }>;
        setError(err?.response?.data?.message || 'Error logging in');
        console.error('Error logging in..');
        if (showToasts) showError('Error logging in..');
      } finally {
        setLoading(false);
      }
    },
    [showToasts, showError]
  );

  return {
    error,
    loading,
    clearError,
    loginAndRefresh,
    loginAndRedirect,
    loginWithHasuraAndRefresh,
    loginWithHasuraAndRedirect,
  };
};

export default useLogin;
