import { gql, type TypedDocumentNode } from '@apollo/client';
import type { TeamNotificationStatus } from '../../../constants';

interface CheckTeamNotificationsMutedInput {
  input: {
    gameId: string;
    teamId: string;
    methods: {
      push: boolean;
      /** As of 8/1/24 this will always be false (may change if the business decides to support SMS reminders in the future) */
      sms: boolean;
      email: boolean;
    };
  };
}

interface CheckTeamNotificationsMutedData {
  checkTeamNotificationsMuted: {
    status: TeamNotificationStatus;
    teamId: string;
  };
}

const CHECK_TEAM_NOTIFICATIONS_MUTED: TypedDocumentNode<
  CheckTeamNotificationsMutedData,
  CheckTeamNotificationsMutedInput
> = gql`
  query CheckTeamNotificationsMuted($input: CheckTeamNotificationsMutedInput!) {
    checkTeamNotificationsMuted(input: $input) {
      status
      teamId
    }
  }
`;

export default CHECK_TEAM_NOTIFICATIONS_MUTED;
