import { skipToken, useSuspenseQuery } from '@apollo/client';
import type { IImageProps } from '@rivallapp/native-base';
import { Platform } from 'react-native';
import { Image } from '../../../../base-components';
import GET_VENUE_IMAGES from './graphql/GET_VENUE_IMAGES';
import GET_RENTAL_IMAGES from './graphql/GET_RENTAL_IMAGES';
import type { RentalVenueExclusiveProps } from '../../types';

/**
 * Fetches images for details page. Takes in either `rentalId` or `venueId` but not both.
 * @important Make sure wrap this component with Suspense and ErrorBoundary!
 * ! Will need to be completely rebuilt when we have multiple images
 */
const RentalImageCarousel = ({
  rentalId,
  venueId,
  ...imageProps
}: RentalVenueExclusiveProps & IImageProps) => {
  const { data: rentalData } = useSuspenseQuery(
    GET_RENTAL_IMAGES,
    rentalId
      ? {
          variables: {
            rentalId,
          },
        }
      : skipToken
  );

  const { data: venueData } = useSuspenseQuery(
    GET_VENUE_IMAGES,
    venueId
      ? {
          variables: {
            venueId,
          },
        }
      : skipToken
  );

  const imageUrl =
    rentalData?.rental.images[0] ||
    venueData?.venue?.image?.url ||
    // this is the fallback for a venue with no images since rentals are required to have images
    venueData?.venue?.rentals?.[0]?.images[0] ||
    '';

  return (
    <Image
      resizeMode="cover"
      source={{ uri: imageUrl }}
      alt="Rental Cover Image"
      height={Platform.select({ native: 200, default: 400 })}
      {...imageProps}
    />
  );
};
export default RentalImageCarousel;
