import { gql, useQuery, type TypedDocumentNode } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { type LayoutChangeEvent } from 'react-native';
import moment from 'moment-timezone';

import {
  addSpacers,
  createDefaultRentalBlocks,
  rentalBlocksToSlots,
  transformRentalBlocksToTimeSlots,
} from './helpers';
import { type RentalBlock } from '../../../../steps.types';
import { gracePeriod, startDateApiFormat } from '../../../../steps.constants';

// would be fetched from api on rental model
const startHour = 5;
const endHour = 23;
const timeSlotLength = 60;

const _defaultSlots = transformRentalBlocksToTimeSlots(
  createDefaultRentalBlocks({
    startHour,
    endHour,
    timeSlotLength,
    // default to all slots being available for the UI since we do not have a start date yet
    configureAvailability: () => false,
    // all slots are not prime time by default
    configurePrimeTime: () => false,
  }),
  {
    gracePeriod,
    startDate: null,
  }
);

export interface RentalBlockApiObject extends RentalBlock {
  capacity: number;
  rentalId: string;
  // allow undefined for easily adding back to mocks
  rental_timeslots?: {
    _id: string;
    court: string;
    end_time_str: string;
    start_time_str: string;
    is_prime_time: boolean;
    is_available: boolean;
    price_cents?: number | null;
    vp_price_cents?: number | null;
  }[];
}

export interface RentalBlocksQueryResponse {
  rentalBlocksByDate: RentalBlockApiObject[];
}
export interface RentalBlocksQueryVariables {
  input: {
    /**
     * - formatted as 'MM/DD/YYYY'
     */
    date: string;
    rentalId: string;
  };
}

export const GET_RENTAL_BLOCKS: TypedDocumentNode<
  RentalBlocksQueryResponse,
  RentalBlocksQueryVariables
> = gql`
  query rentalBlocksByDate($input: RentalBlocksByDateInput!) {
    rentalBlocksByDate(input: $input) {
      _id
      capacity
      end_time_str
      has_open_reservations
      rentalId
      is_prime_time
      # uncomment if needed in mocks or in the future - as of v1, timeslots are not needed for the UI
      # rental_timeslots {
      #   _id
      #   court
      #   end_time_str
      #   start_time_str
      #   is_prime_time
      #   is_available
      # }
      start_time_str
      timeslot_length
    }
  }
`;

/**
 * Adds a fake block to the end of each group of rental blocks
 * - this is used for UI purposes to allow the user to select the end time of slots that are grouped together but have overlapping slots in between them
 */
export interface GroupedRentalBlocksWithFake extends RentalBlockApiObject {
  /**
   * Flag to indicate that this is a fake block only used for UI purposes
   */
  isFakeBlock?: boolean;
}

/**
 * Hook that formats the rental blocks into time slots for the UI
 * - also adds spacers to fill the row width for UI purposes
 */
const useRentalTimeSlots = (rentalId: string, startDate: Date | null) => {
  const [defaultSlots] = useState(_defaultSlots);
  const [rowWidth, setRowWidth] = useState<number | undefined>(undefined);
  const updateRowWidth = useCallback(
    (e: LayoutChangeEvent) => setRowWidth(e.nativeEvent.layout.width),
    []
  );

  const { data, error, refetch, networkStatus } = useQuery(GET_RENTAL_BLOCKS, {
    skip: !startDate,
    variables: {
      input: {
        date: moment(startDate!).format(startDateApiFormat),
        rentalId,
      },
    },
  });

  const slots = useMemo(
    () => rentalBlocksToSlots(data?.rentalBlocksByDate, startDate),
    [data, startDate]
  );

  /**
   * Add spacers to fill the row width for UI purposes
   */
  const spacers = useMemo(
    () => addSpacers({ totalSlots: slots.length ? slots.length : defaultSlots.length, rowWidth }),
    [slots, defaultSlots, rowWidth]
  );

  return {
    slots,
    defaultSlots,
    spacers,
    updateRowWidth,
    error,
    refetch,
    networkStatus,
  };
};

export default useRentalTimeSlots;
