import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    // @ts-ignore - `overflow: 'scroll'` may work here but requires testing
    overflowY: 'scroll',
  },
});
