import { VoloNativeBaseShades } from '../../theme';

const { lightSelect, secondary } = VoloNativeBaseShades;

export const voloPassColorList = [
  { offset: '0%', color: secondary[500].toString(), opacity: '1' },
  { offset: '75%', color: secondary[600].toString(), opacity: '1' },
  { offset: '100%', color: secondary[700].toString(), opacity: '1' },
];

export const voloFitnessColorList = [
  { offset: '0%', color: lightSelect[500].toString(), opacity: '1' },
  { offset: '75%', color: lightSelect[600].toString(), opacity: '1' },
  { offset: '100%', color: lightSelect[700].toString(), opacity: '1' },
];
