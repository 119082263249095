import type { InterfaceModalProps } from '@rivallapp/native-base/lib/typescript/components/composites/Modal/types';
import { Modal, Stack, Text, type ITextProps } from '../../../../base-components';

export type RentalCancellationPoliciesModalProps = {
  isOpen: InterfaceModalProps['isOpen'];
  onClose: InterfaceModalProps['onClose'];
  textStyles?: ITextProps;
} & InterfaceModalProps;

/**
 * Content shown for "Private Rental Policies" in the rental checkout flow.
 */
const RentalCancellationPoliciesModal = ({
  isOpen,
  onClose,
  textStyles = {},
  ...modalProps
}: RentalCancellationPoliciesModalProps) => {
  return (
    <Modal useRNModal isOpen={isOpen} onClose={onClose} {...modalProps}>
      <Modal.Content>
        <Modal.Header>
          Private Rental Policies
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack space={3}>
            <Text {...textStyles}>You may cancel your reservation at any time.</Text>
            <Text {...textStyles}>
              If you cancel more than 24 hours before the reservation start time, you will receive a
              full refund.
            </Text>
            <Text {...textStyles}>
              If you cancel within 24 hours of the reservation start time, you will receive credits
              to your Volo account.
            </Text>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
export default RentalCancellationPoliciesModal;
