import { type FC } from 'react';
import { faHouse } from '@fortawesome/pro-light-svg-icons/faHouse';

import { Text, View, FontAwesomeIcon } from '../../../../base-components';
import type { NeighborhoodModule } from '../../ProgramCard/types';
import useMobileSizing from '../../helpers/useMobileSizing';

const NeighborhoodSection: FC<Omit<NeighborhoodModule, 'type'>> = ({ neighborhood }) => {
  const mobileSizing = useMobileSizing();
  return (
    <View flexDirection="row" flex={1} alignItems="center">
      {!mobileSizing ? <FontAwesomeIcon icon={faHouse} size={5} /> : null}
      <View overflow="hidden">
        <Text
          marginLeft={2.5}
          numberOfLines={1}
          fontSize={14}
          letterSpacing={-0.5}
          maxWidth={mobileSizing ? 160 : 220}
        >
          {neighborhood}
        </Text>
      </View>
    </View>
  );
};

export default NeighborhoodSection;
