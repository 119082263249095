import { Text, View } from '../../../../base-components';
import {
  RentalDivider,
  RentalSectionContainer,
  RentalSectionLabel,
  RentalSubtext,
} from '../../common/components';
import { BookingDateSelector, BookingTimeSelector } from '../components';

/**
 * Date and Time selection for the Rental Registration Flow
 */
const DateTimeSection = () => {
  return (
    <View>
      <RentalSectionContainer>
        <RentalSectionLabel>Select a date</RentalSectionLabel>
        <BookingDateSelector />
      </RentalSectionContainer>

      <RentalDivider />

      <RentalSectionContainer>
        <RentalSectionLabel>Select from available times</RentalSectionLabel>
        <Text>1 hour booking minimum</Text>
        <RentalSubtext italic>Bookings must be in 1 hour increments</RentalSubtext>
      </RentalSectionContainer>

      <RentalDivider />

      <RentalSectionContainer>
        <BookingTimeSelector />
      </RentalSectionContainer>
    </View>
  );
};

export default DateTimeSection;
