/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from '../../../custom-components/Table';
import { type TableCellProps } from '../../../custom-components/Table/components/TableCell';
import { type StandingsTableBaseProps, StandingsTableView } from './StandingsTableBase';

const TeamTextProps: TableCellProps['textProps'] = { textAlign: 'left', pl: 2 };

type Props = Pick<StandingsTableBaseProps, 'view'>;

const StandingsTableHeader: React.FC<Props> = ({ view }) => {
  if (view === StandingsTableView.Full) {
    return (
      <Table.Row>
        <Table.Header>Rank</Table.Header>
        <Table.Header textProps={TeamTextProps} flex={5}>
          Team
        </Table.Header>
        <Table.Header>Win</Table.Header>
        <Table.Header>Loss</Table.Header>
        <Table.Header>Tie</Table.Header>
        <Table.Header tooltip="Points for">PF</Table.Header>
        <Table.Header tooltip="Points against">PA</Table.Header>
        <Table.Header tooltip="Points differential">Diff</Table.Header>
      </Table.Row>
    );
  }

  if (view === StandingsTableView.Score) {
    return (
      <Table.Row>
        <Table.Header>Rank</Table.Header>
        <Table.Header textProps={TeamTextProps} flex={4}>
          Team
        </Table.Header>
        <Table.Header tooltip="Points for">PF</Table.Header>
        <Table.Header tooltip="Points against">PA</Table.Header>
        <Table.Header tooltip="Points differential">Diff</Table.Header>
      </Table.Row>
    );
  }

  if (view === StandingsTableView.Record) {
    return (
      <Table.Row>
        <Table.Header>Rank</Table.Header>
        <Table.Header textProps={TeamTextProps} flex={4}>
          Team
        </Table.Header>
        <Table.Header>Win</Table.Header>
        <Table.Header>Loss</Table.Header>
        <Table.Header>Tie</Table.Header>
      </Table.Row>
    );
  }

  return null;
};

export default StandingsTableHeader;
