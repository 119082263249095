import React from 'react';
import { useQuery } from '@apollo/client';
import type { IModalProps } from '@rivallapp/native-base';
import RentalCollapsible from '../RentalCollapsible';
import { Image, Modal, Skeleton, View, type IViewProps } from '../../../../base-components';
import type { RentalVenueExclusiveProps } from '../../types';
import GET_VENUE_MAP_FROM_RENTAL from './graphql/GET_VENUE_MAP_FROM_RENTAL';
import GET_VENUE_MAP from './graphql/GET_VENUE_MAP';
import { RentalText } from '../RentalCommonText';
import { isWeb } from '../../../../utilities';

type VariantProps =
  | {
      variant?: 'collapsible' | 'default';
      isOpen?: never;
      onClose?: never;
    }
  | {
      variant: 'modal';
      isOpen: IModalProps['isOpen'];
      onClose: IModalProps['onClose'];
    };

type Props = {
  /**
   * SCL's Image component does not render SVG urls properly on mobile.
   * We don't have a image/SVG rendering component at this time that works on both platforms.
   * For now, we have to handle the SVG urls per platform.
   *
   * @default
   * ```tsx
   * const DefaultImageComponent = ({ mapUrl }: { mapUrl: string }) => {
   *  return (
   *    <Image // SCL's Image component
   *      src={mapUrl}
   *      alt="Venue Map"
   *      style={{ aspectRatio: 1.5 }}
   *      width="100%"
   *      resizeMode="contain"
   *    />
   *  );
   *};
   * ```
   */
  ImageComponent?: React.ComponentType<{ mapUrl: string }>;
  /**
   * Whether to display the venue map as a collapsible or modal
   *
   * @default 'collapsible'
   */
  containerStyle?: IViewProps;
} & RentalVenueExclusiveProps &
  VariantProps;

const DefaultImageComponent = ({ mapUrl }: { mapUrl: string }) => {
  return (
    <Image
      src={mapUrl}
      alt="Venue Map"
      style={{ aspectRatio: 1.5 }}
      width="100%"
      resizeMode="contain"
    />
  );
};

const RentalVenueMap = ({
  ImageComponent = DefaultImageComponent,
  rentalId,
  venueId,
  variant = 'default',
  isOpen,
  onClose,
  containerStyle = {},
}: Props) => {
  const {
    data: venueData,
    loading: venueLoading,
    error: venueError,
  } = useQuery(GET_VENUE_MAP, {
    skip: !venueId,
    variables: {
      venueId: venueId!,
    },
  });

  const {
    data: rentalData,
    loading: rentalLoading,
    error: rentalError,
  } = useQuery(GET_VENUE_MAP_FROM_RENTAL, {
    skip: !rentalId,
    variables: {
      rentalId: rentalId!,
    },
  });

  const mapUrl = venueData?.venue?.venue_map_url || rentalData?.rental?.venue?.venue_map_url;

  if (!mapUrl && variant !== 'modal') return null;

  const loading = venueLoading || rentalLoading;
  const error = venueError || rentalError;

  const renderVenueMap = () => {
    // Handle loading/error states
    if (loading) {
      return <Skeleton height={isWeb ? 300 : 150} borderRadius="md" />;
    }

    if (error) {
      return (
        <RentalText type="caption" color="red.500">
          Error loading venue map!
        </RentalText>
      );
    }

    // If there's a URL available, render the image
    if (mapUrl) return <ImageComponent mapUrl={mapUrl} />;

    // This is rendered only for modal variants.
    return <RentalText type="caption">No venue map available at this time.</RentalText>;
  };

  switch (variant) {
    case 'modal':
      return (
        <Modal useRNModal size="xl" isOpen={isOpen} onClose={onClose}>
          <Modal.Content>
            <Modal.Header>
              Venue Map
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>{renderVenueMap()}</Modal.Body>
          </Modal.Content>
        </Modal>
      );
    case 'collapsible':
      return (
        <View {...containerStyle}>
          <RentalCollapsible isCollapsed={false} title="Venue Map">
            {renderVenueMap()}
          </RentalCollapsible>
        </View>
      );
    default:
      return <View {...containerStyle}>{renderVenueMap()}</View>;
  }
};

export default RentalVenueMap;
