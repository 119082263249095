import { gql, type TypedDocumentNode } from '@apollo/client';

type TeamRsvpStatusData = {
  teamRsvpStatus: {
    teamRsvps: {
      fullName: string;
      displayNameTeams: string;
      response?: string;
      picture: string;
      isDropin?: boolean;
      userId: string;
      _id?: string;
    }[];
  };
};

type TeamRsvpStatusInput = {
  input: {
    teamId: string;
    gameId: string;
  };
};

const TEAM_RSVP_STATUS: TypedDocumentNode<TeamRsvpStatusData, TeamRsvpStatusInput> = gql`
  query teamRsvpStatus($input: TeamRsvpStatusInput!) {
    teamRsvpStatus(input: $input) {
      teamRsvps {
        fullName
        displayNameTeams
        response
        picture
        isDropin
        userId
        _id
      }
    }
  }
`;

export default TEAM_RSVP_STATUS;
