import { useState } from 'react';
import type { ApolloError, ApolloQueryResult } from '@apollo/client';

import { Text, Button, Stack, type ButtonProps } from '../../../../../base-components';
import { ErrorMessageEnum } from '../../../../../constants';

interface RentalErrorComponentProps extends ButtonProps {
  /**
   * error message to display to the user
   */
  fallBackMessage: string;
  /**
   * function to refetch the data
   * - if you need more fine grain control, utilize the `onRefetch` function instead
   */
  refetch?: (variables?: Partial<unknown>) => Promise<ApolloQueryResult<unknown>>;
  /**
   * Utilize this function to specify what should happen when the user clicks the retry button
   */
  onRefetch?: () => Promise<void>;
  error: ApolloError;
}

/**
 * Generic error component to display when there is an error within the rental registration flow
 */
const RentalErrorComponent = ({
  fallBackMessage = 'An error occurred, please try again',
  refetch,
  error,
  onRefetch,
  isLoading,
  ...buttonProps
}: RentalErrorComponentProps) => {
  const [refetching, setRefetching] = useState(false);

  const handleRefetch = async () => {
    try {
      setRefetching(true);
      await Promise.all([
        refetch ? refetch() : Promise.resolve(),
        onRefetch ? onRefetch() : Promise.resolve(),
      ]);
    } catch (error) {
      console.error('Error refetching data', error);
    } finally {
      setRefetching(false);
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center" space={1}>
      <Text type="caption">
        {error.message in ErrorMessageEnum
          ? ErrorMessageEnum[error.message as keyof typeof ErrorMessageEnum]
          : fallBackMessage}
      </Text>
      <Button
        variant="link"
        onPress={handleRefetch}
        colorScheme="rentalBlue"
        style={{ width: 100, height: 40, padding: 0 }}
        isLoading={refetching || isLoading}
        {...buttonProps}
      >
        RETRY
      </Button>
    </Stack>
  );
};

export default RentalErrorComponent;
