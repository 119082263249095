import { useCallback } from 'react';

import { type RentalDateSelection } from '../../RegistrationFlowSections';
import { dateStepSelector, type DateStepSelector } from '../rentalBookingStore.selectors';
import useRentalBookingStore from '../useRentalBookingStore';

interface RentalBookingDate extends Omit<DateStepSelector, 'timeSlots'> {
  updateDateSelection: (newDateValue: RentalDateSelection['startDate']) => void;
}

/**
 * Hook to interact with the date selection step only of the current booking.
 * - also updates time slots to be empty when the date is changed
 */
const useRentalBookingDate = (): RentalBookingDate => {
  const { startDate, isStepValid, rentalId } = useRentalBookingStore(dateStepSelector);

  const updateDateSelection = useCallback<RentalBookingDate['updateDateSelection']>(
    newDateValue => {
      useRentalBookingStore.setState(state => {
        const { currentBooking } = state;
        if (!currentBooking) {
          return state;
        }

        const { steps } = currentBooking;

        return {
          currentBooking: {
            ...currentBooking,
            steps: {
              ...steps,
              dateSelection: {
                startDate: newDateValue,
                timeSlots: [],
              },
              courtsSelection: {
                selectedCourts: [],
              },
            },
          },
        };
      });
    },
    []
  );

  return {
    rentalId,
    startDate,
    isStepValid,
    updateDateSelection,
  };
};

export default useRentalBookingDate;
