import { View, Text, Button, Stack, Row, Spinner } from '../../../../../base-components';
import { ModalAlert, ModalAlertComponent } from '../../../../../custom-components';
import {
  type RentalNavigationCallback,
  useCurrentBookingNavigation,
  useRentalBookingStore,
  useRentalBookingDate,
  useRentalBookingTimeSlots,
  useRentalBookingCourts,
} from '../../../store';
import useRentalPricing from '../../../hooks/useRentalPricing';
import RentalErrorComponent from '../RentalErrorComponent';
import { useCurrentUser } from '../../../../../hooks';

interface RentalFooterProps {
  onNavigateNextStep: RentalNavigationCallback;
  /**
   * callback to run if the user clicks 'Add to Cart' on the last step & the booking is valid
   */
  onConfirmedBooking?: () => void;
}

const {
  actions: { confirmCurrentBooking },
} = useRentalBookingStore.getState();

const RentalBookingPriceDisplay = () => {
  const { rentalId, startDate } = useRentalBookingDate();
  const { timeSlots } = useRentalBookingTimeSlots();

  const {
    userIsActiveMember: timeFrameUserIsActiveMember,
    timeFramePricing,
    loading: estimatedTimeFramePriceLoading,
    error: estimatedTimeFramePriceError,
    refetch: estimatedTimeFrameRefetch,
  } = useRentalPricing('estimated-time-frame-pricing', {
    rentalId,
    selectedDate: startDate,
    startTime: timeSlots[0]?.time_str,
    endTime: timeSlots[timeSlots.length - 1]?.time_str,
    formatterType: 'price',
  });

  const { selectedCourts } = useRentalBookingCourts();
  const selectedTimeSlotIds = selectedCourts.flatMap(court => court.timeSlotIds.map(id => id));
  const {
    userIsActiveMember: selectedUserIsActiveMember,
    selectedSlotPricing,
    loading: selectedSlotPricingLoading,
    error: selectedPricingError,
    refetch: selectedPricingRefetch,
  } = useRentalPricing('selected-slot-pricing', {
    rentalId,
    selectedTimeSlotIds,
    formatterType: 'price',
  });

  // we are loading only if we don't have the formatted pricing already & we are fetching
  const loading =
    (estimatedTimeFramePriceLoading || selectedSlotPricingLoading) &&
    (!timeFramePricing.formattedPriceString || !selectedSlotPricing.formattedPriceString);
  const error = estimatedTimeFramePriceError || selectedPricingError;
  const userIsActiveMember = timeFrameUserIsActiveMember || selectedUserIsActiveMember;

  if (error) {
    return (
      <RentalErrorComponent
        error={error}
        fallBackMessage="Couldn't fetch price"
        onRefetch={async () => {
          await Promise.all([
            estimatedTimeFramePriceError ? estimatedTimeFrameRefetch() : Promise.resolve(),
            selectedPricingError ? selectedPricingRefetch() : Promise.resolve(),
          ]);
        }}
      />
    );
  }

  const renderCorrectPrice = (priceStr1?: string, priceStr2?: string) => {
    if (priceStr1) {
      return priceStr1;
    }

    return priceStr2 || '--';
  };

  return (
    <Stack>
      <Text type="caption">SUBTOTAL</Text>
      <Row space={1}>
        {loading ? (
          <Spinner ml={2} size="sm" height={6} />
        ) : (
          <Text bold>
            {renderCorrectPrice(
              selectedSlotPricing.formattedPriceString,
              timeFramePricing.formattedPriceString
            )}
          </Text>
        )}
      </Row>
      {/* need to show member savings if user is member */}
      {userIsActiveMember && (
        <Text type="caption">
          MEMBER SAVINGS:{' '}
          {renderCorrectPrice(
            // if loading - fallback to undefined to show `--` instead of the previous value
            loading ? undefined : selectedSlotPricing.formattedMemberSavingsString,
            loading ? undefined : timeFramePricing.formattedMemberSavingsString
          )}
        </Text>
      )}
    </Stack>
  );
};

/**
 * Footer component for the rental registration flow
 * - handles validation for the current step to enable/disable the 'Continue' button
 * - handles the 'Add to Cart' functionality when the user is on the last step
 * ---
 * - `onConfirmedBooking` optional callback for when the user is on the last step and clicks 'Add to Cart'
 * - `onNavigateNextStep` callback to handle the navigation to the next step
 */
const RentalFooter = ({ onNavigateNextStep, onConfirmedBooking }: RentalFooterProps) => {
  const { currentState, navigateNextStep } = useCurrentBookingNavigation();
  const isEditMode = useRentalBookingStore(store => store.currentBooking?.isEditMode);

  const { currentUser } = useCurrentUser({ fetchPolicy: 'cache-first' });

  function handleAddToCart() {
    const confirmedBooking = confirmCurrentBooking();
    if (!confirmedBooking) {
      ModalAlert.alert({
        id: 'invalid-booking-alert',
        title: 'Invalid Booking',
        message: 'Please fill out all required steps before adding to cart',
      });
      return;
    }

    if (typeof onConfirmedBooking === 'function') {
      onConfirmedBooking();
    }
  }

  function getFooterText() {
    if (currentState.isLastStep) {
      return isEditMode ? 'Update cart' : 'Add to Cart';
    }

    return 'Continue';
  }

  return (
    <>
      <Stack direction={['column', 'row']} flex={1} alignItems="center" padding={2}>
        <Stack nativeID="left-half" alignItems="flex-start" flex={1} space={1}>
          <RentalBookingPriceDisplay />
          {/*
            CA-FEE updated doc to show hard code copy for fee adjustment
            @see https://docs.google.com/presentation/d/1oWSL9l2qnrp-mx0KkQxjEXpBUn4H20vgmPWKdsz59jU/edit#slide=id.g31bd4861c72_1_0
          */}
          {currentUser?.shouldSeePricesWithFees ? (
            <Text type="caption" italic>
              A $0.99 cart-level fee will apply
            </Text>
          ) : null}
        </Stack>
        <View nativeID="right-half" alignItems="flex-end" flex={1}>
          <Button
            colorScheme="rentalBlue"
            width={150}
            onPress={() => {
              if (currentState.isLastStep) {
                handleAddToCart();
                return;
              }

              navigateNextStep(onNavigateNextStep);
            }}
            isDisabled={!currentState.isStepValid}
          >
            {getFooterText()}
          </Button>
        </View>
      </Stack>
      <ModalAlertComponent id="invalid-booking-alert" />
    </>
  );
};

export default RentalFooter;
