import { ActivityIndicator } from 'react-native';

import { View, Text } from '../../../base-components';
import { isMobile } from '../../../utilities';
import {
  CancelPolicyButtons,
  CancellationConfirmationModal,
  CancellationNoShowPolicyModal,
} from '../index';
import usePlayerProgramInfo from '../hooks/usePlayerProgramInfo';

interface CancelGameSpotProps {
  /** where to direct the user after spot is cancelled. Should include success message */
  onCompleted: () => void;
  /** Display error message */
  onError: (arg: string) => unknown | undefined;
  /** dropInGameId is only used for drop-in cancellations */
  dropInGameId?: string;
  /** registrantId is only used for non-drop-in cancellations */
  registrantId?: string;
  /** leagueId is used for non-drop-in cancellations */
  leagueId?: string;
  /** used for non-drop-in programs */
  programTeamId?: string;
}

const CancelGameSpot = ({
  onCompleted,
  onError,
  dropInGameId,
  leagueId,
  registrantId = '',
  programTeamId,
}: CancelGameSpotProps) => {
  const {
    loading,
    error,
    programType,
    isPast,
    hoursBeforeStartTime,
    onUnrsvpDropIn,
    onSelfWithdrawDaily,
    showPopup,
    setShowPopup,
    isMember,
    playerStrikes,
  } = usePlayerProgramInfo({
    gameId: dropInGameId,
    leagueId,
    programTeamId,
    onCompleted,
    onError,
  });

  const handlePress = (which: 'confirm' | 'policy' | '') => setShowPopup(which);

  const handleConfirmCancel = async () => {
    if (dropInGameId) await onUnrsvpDropIn();
    else await onSelfWithdrawDaily(registrantId);
  };

  if (isPast) return null;

  if (loading) return <ActivityIndicator />;
  if (error) {
    return (
      <View>
        <Text>{String(error)}</Text>
      </View>
    );
  }

  return (
    <>
      <CancelPolicyButtons
        programType={programType}
        onPressConfirm={() => handlePress('confirm')}
        onPressPolicy={() => handlePress('policy')}
        isLoading={loading}
      />
      <CancellationNoShowPolicyModal
        isOpen={showPopup === 'policy'}
        onClose={() => handlePress('')}
        size={isMobile ? 'full' : 'xl'}
      />
      <CancellationConfirmationModal
        programType={programType}
        isVP={isMember}
        isLessThanSixHours={hoursBeforeStartTime < 6}
        isOpen={showPopup === 'confirm'}
        onClose={() => handlePress('')}
        onConfirm={handleConfirmCancel}
        playerStrikes={playerStrikes}
      />
    </>
  );
};

export default CancelGameSpot;
