import { skipToken, useSuspenseQuery } from '@apollo/client';
import { type RentalVenueExclusiveProps } from '../../types';
import { Stack, View, type IViewProps } from '../../../../base-components';
import { RenderHTML } from '../../../../custom-components';
import GET_RENTAL_GIST from './graphql/GET_RENTAL_GIST';
import RentalCollapsible from '../RentalCollapsible';
import GET_RENTAL_GIST_FROM_VENUE from './graphql/GET_RENTAL_GIST_FROM_VENUE';
import { RentalHeading } from '../RentalCommonText';

type Props = {
  collapsible?: boolean;
  containerStyle?: IViewProps;
} & RentalVenueExclusiveProps;

/**
 * Fetches and renders the rental gist.
 *
 * @important Make sure wrap this component with Suspense and ErrorBoundary!
 */
const RentalGist = ({ rentalId, venueId, collapsible = false, containerStyle = {} }: Props) => {
  const { data: rentalData } = useSuspenseQuery(
    GET_RENTAL_GIST,
    rentalId
      ? {
          variables: {
            rentalId,
          },
        }
      : skipToken
  );
  const { data: venueData } = useSuspenseQuery(
    GET_RENTAL_GIST_FROM_VENUE,
    venueId
      ? {
          variables: {
            venueId,
          },
        }
      : skipToken
  );

  const gist = rentalData?.rental.gist || venueData?.venue.rentals?.[0]?.gist || '';
  const shorthandName =
    rentalData?.rental.venue.shorthandName || venueData?.venue.shorthandName || '';

  if (collapsible)
    return (
      <RentalCollapsible isCollapsed={false} title={`About ${shorthandName}`}>
        <View {...containerStyle}>
          <RenderHTML html={gist} />
        </View>
      </RentalCollapsible>
    );

  return (
    <Stack space={1} {...containerStyle}>
      <RentalHeading>About {shorthandName}</RentalHeading>
      <RenderHTML html={gist} />
    </Stack>
  );
};
export default RentalGist;
