import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { FC } from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import {
  Column,
  Heading,
  type HeadingProps,
  FontAwesomeIcon,
  Pressable,
  type PressableProps,
  Row,
  Text,
} from '../../base-components';

interface IconListItemProps {
  /** Web & mobile compatible - will render Pressable */
  onPress?: VoidFunction;
  /** WEB ONLY PROP - will render Link from react-router-dom */
  href?: string;
  icon: IconDefinition;
  label: string;
  description?: string;
  _container?: PressableProps & LinkProps;
  _label?: HeadingProps;
}

const IconListItem: FC<IconListItemProps> = ({
  href,
  onPress,
  icon,
  label,
  description,
  _container,
  _label,
}) => {
  if (href) {
    return (
      <Link
        to={href}
        component={Pressable}
        bg="white.600"
        p="4"
        _hover={{ bg: 'gray.50' }}
        {..._container}
      >
        <Row alignItems="center">
          <FontAwesomeIcon icon={icon} size="2xl" marginRight="4" />
          <Column>
            <Heading fontSize="md" {..._label}>
              {label}
            </Heading>
            {description && <Text color="gray.500">{description}</Text>}
          </Column>
        </Row>
      </Link>
    );
  }
  return (
    <Pressable bg="white.600" p="4" onPress={onPress} _hover={{ bg: 'gray.50' }} {..._container}>
      <Row alignItems="center">
        <FontAwesomeIcon icon={icon} size="2xl" marginRight="4" />
        <Column>
          <Heading fontSize="md" {..._label}>
            {label}
          </Heading>
          {description && <Text color="gray.500">{description}</Text>}
        </Column>
      </Row>
    </Pressable>
  );
};

export default IconListItem;
