/* eslint-disable indent */
import { type IButtonProps } from '@rivallapp/native-base';

import { type TBrandKeys } from '../../../theme/types';

/**
 * helper function to generate button props based on the type of button passed in
 * - utilize this to have preset button styles `utilProps`
 * @returns {IButtonProps}
 */
export const generateButtonProps = (
  colorScheme: TBrandKeys,
  borderColor: string,
  textColor: string,
  rest: IButtonProps
): IButtonProps => {
  const { variant } = rest;
  const isNotVariant = [undefined, 'solid', 'link'].includes(variant as string);

  const borderWidth = colorScheme === 'white' ? 1 : 0;
  const isLink = variant === 'link';
  const bW = isLink ? 0 : borderWidth;
  const color = isLink ? 'blue.400' : textColor;

  // if a variant is passed in, return only the 'rest' which is handled by native-base
  // this way we still keep most of the benefits of native-base's button, but can also
  // have our own custom button styles easily applied by passing in a `type` prop
  return isNotVariant
    ? {
        colorScheme,
        borderColor,
        borderWidth: bW,
        borderRadius: 8,
        height: 50,
        ...rest,
        _text: {
          bold: !isLink,
          color,
          fontSize: 18,
          ...rest._text,
        },
        _spinner: {
          color,
          ...rest.spinner,
        },
      }
    : rest;
};

/**
 * helper function to generate button props based on the type of button passed in
 * - This is utilized to have preset styles based on the `type` of button
 */
const getButtonUtilityProps = (type: TBrandKeys | undefined, rest: IButtonProps) => {
  switch (type) {
    case 'secondary':
      return generateButtonProps('secondary', 'secondary', 'white.600', rest);
    case 'tertiary':
      return generateButtonProps('tertiary', 'tertiary', 'white.600', rest);
    case 'white':
      return generateButtonProps('white', 'primary', 'primary.600', rest);
    case 'primary':
    default:
      return generateButtonProps('primary', 'primary', 'white.600', rest);
  }
};

export default getButtonUtilityProps;
