import { useState } from 'react';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import moment from 'moment-timezone';

import { CalendarModal } from '../../../../../../custom-components';
import { FontAwesomeIcon, Text, View } from '../../../../../../base-components';
import DateSelectorItem from '../../DateSelectorItem';
import DaySpacer from './DaySpacer';

interface CalendarSelectorItemProps {
  startDate?: Date | null;
  onSelectDay: (date: Date) => void;
}

const CalendarSelectorItem = ({ startDate, onSelectDay }: CalendarSelectorItemProps) => {
  const [showCalendar, setShowCalender] = useState(false);

  return (
    <>
      <View flexDir="row" alignItems="center" justifyContent="center">
        {/* FlatList `ItemSeparatorComponent` unfortunately does not render after last item */}
        <DaySpacer />
        <DateSelectorItem
          isSelected={false}
          /* keep the item pressed while the calendar modal is showing for UI */
          isPressed={showCalendar}
          onPress={() => setShowCalender(true)}
        >
          <Text textAlign="center" fontSize={14}>
            CAL
          </Text>
          <View style={{ marginLeft: 1, transform: [{ translateY: -3 }] }}>
            <FontAwesomeIcon icon={faCalendar} size={6} colorScheme="text" />
          </View>
        </DateSelectorItem>
      </View>
      <CalendarModal
        isOpen={showCalendar}
        onCloseModal={() => setShowCalender(false)}
        modalProps={{ useRNModal: true }}
        initialDate={startDate?.toISOString()}
        markedDates={{ [moment(startDate).format('YYYY-MM-DD')]: { selected: true } }}
        onDayPress={({ dateString }) => {
          onSelectDay(moment(dateString).toDate());
          setShowCalender(false);
        }}
      />
    </>
  );
};

export default CalendarSelectorItem;
