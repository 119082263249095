import { type TypedDocumentNode, gql } from '@apollo/client';

export type GetRentalGeneralInfoData = {
  rental: {
    _id: string;
    standard_price_cents: number;
    prime_price_cents?: number;
    first_timeslot_by_time: {
      _id: string;
      start_time_str: string;
    };
    last_timeslot_by_time: {
      _id: string;
      end_time_str: string;
    };
    venue: {
      _id: string;
      shorthandName: string;
      neighborhood: string;
      maps_url_query?: string;
      formatted_address?: string;
      location: {
        lat: number;
        lng: number;
      };
      place_id: string;
    };
  };
};

type GetRentalGeneralInfoInput = {
  rentalId: string;
};

const GET_RENTAL_GENERAL_INFO: TypedDocumentNode<
  GetRentalGeneralInfoData,
  GetRentalGeneralInfoInput
> = gql`
  query rental($rentalId: ID!) {
    rental(_id: $rentalId) {
      _id
      standard_price_cents
      prime_price_cents
      first_timeslot_by_time {
        _id
        start_time_str
      }
      last_timeslot_by_time {
        _id
        end_time_str
      }
      venue {
        _id
        shorthandName
        neighborhood
        maps_url_query
        formatted_address
        location {
          lat
          lng
        }
        place_id
      }
    }
  }
`;

export default GET_RENTAL_GENERAL_INFO;
