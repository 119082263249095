/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from '../../../custom-components/Table';
import TeamNameCell from './TeamNameCell';
import { type TeamStandings } from '../graphql/useLeagueStandingsQuery';
import { type StandingsTableBaseProps, StandingsTableView } from './StandingsTableBase';

type Props = Pick<StandingsTableBaseProps, 'hideColorNames' | 'highlightedTeamIds' | 'view'> & {
  data: TeamStandings;
};

const StandingsTableRow: React.FC<Props> = ({
  data,
  view,
  hideColorNames,
  highlightedTeamIds = [],
}) => {
  const rowStyle = highlightedTeamIds.includes(data.team._id)
    ? { backgroundColor: 'tertiary.50' }
    : {};

  if (view === StandingsTableView.Full) {
    return (
      <Table.Row {...rowStyle}>
        <Table.Cell>{data.rank}</Table.Cell>
        <TeamNameCell flex={5} overflowX="hidden" team={data.team} hideColorName={hideColorNames} />
        <Table.Cell>{data.WIN}</Table.Cell>
        <Table.Cell>{data.LOSE}</Table.Cell>
        <Table.Cell>{data.TIE}</Table.Cell>
        <Table.Cell>{data.points_for}</Table.Cell>
        <Table.Cell>{data.points_against}</Table.Cell>
        <Table.Cell>{data.points_differential}</Table.Cell>
      </Table.Row>
    );
  }

  if (view === StandingsTableView.Score) {
    return (
      <Table.Row {...rowStyle}>
        <Table.Cell>{data.rank}</Table.Cell>
        <TeamNameCell flex={4} overflowX="hidden" team={data.team} hideColorName={hideColorNames} />
        <Table.Cell>{data.points_for}</Table.Cell>
        <Table.Cell>{data.points_against}</Table.Cell>
        <Table.Cell>{data.points_differential}</Table.Cell>
      </Table.Row>
    );
  }

  if (view === StandingsTableView.Record) {
    return (
      <Table.Row {...rowStyle}>
        <Table.Cell>{data.rank}</Table.Cell>
        <TeamNameCell flex={4} overflowX="hidden" team={data.team} hideColorName={hideColorNames} />
        <Table.Cell>{data.WIN}</Table.Cell>
        <Table.Cell>{data.LOSE}</Table.Cell>
        <Table.Cell>{data.TIE}</Table.Cell>
      </Table.Row>
    );
  }

  return null;
};

export default StandingsTableRow;
