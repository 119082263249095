import { StyleSheet } from 'react-native';
import { Colors } from '../../../theme';

export default StyleSheet.create({
  rosterContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 10,
  },
  roleLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  roleLabel: {
    fontSize: 12,
  },
  button: {
    borderWidth: 1,
    borderColor: Colors.primary,
    borderRadius: 5,
    marginRight: 5,
    paddingVertical: 0,
    paddingHorizontal: 5,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: 5,
  },
});
