import { Platform } from 'react-native';

/**
 * Object that contains booleans for platform checks.
 * - easily check if the platform is web, mobile, ios, or android
 * - use this to conditionally perform platform-specific logic
 */
export const isWeb = Platform.OS === 'web';
export const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';
export const isIos = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';

const PlatformChecks = Object.freeze({
  isWeb,
  isMobile,
  isIos,
  isAndroid,
});

export default PlatformChecks;
