import { type FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { type IBoxProps } from '@rivallapp/native-base';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faX } from '@fortawesome/pro-light-svg-icons/faX';

import { useTheme } from '../../theme';
import { useUserVoloPassMembershipStatus } from '../../hooks';
import { Box, Pressable, Row, FontAwesomeIcon, type PressableProps } from '../../base-components';
import { VoloPassLogo } from '../../custom-components';
import SaleCopyText from './components/SaleCopyText';
import GET_ORGANIZATION_VP_TIERS from './gql/GET_ORGANIZATION_VP_TIERS';
import { isWeb } from '../../utilities';

interface VoloPassAnnualUpgradeBannerProps extends IBoxProps {
  _pressable?: Partial<PressableProps>;
  onPress: (arg: boolean) => void;
  onCompleted?: () => void;
}

const VoloPassAnnualUpgradeBanner: FC<VoloPassAnnualUpgradeBannerProps> = ({
  _pressable = {},
  onPress,
  onCompleted,
  ...props
}) => {
  const { colors } = useTheme();
  const { voloPassMembershipOrganizationId } = useUserVoloPassMembershipStatus();
  const [dismiss, setDismiss] = useState(false);

  const { data, loading, error } = useQuery(GET_ORGANIZATION_VP_TIERS, {
    skip: !voloPassMembershipOrganizationId,
    fetchPolicy: 'cache-first',
    variables: {
      id: voloPassMembershipOrganizationId,
    },
    onCompleted,
  });

  const { organization } = data || {};
  const { voloPassTiers } = organization || {};
  const { annualSavings } = voloPassTiers || {};

  if (loading || error || dismiss) return <Box />;

  return (
    <Box
      borderRadius="md"
      bg={{
        linearGradient: {
          colors: ['primary.500', 'primary.800'],
          start: [0, 1],
          end: [1, 0],
        },
      }}
      {...props}
    >
      {/* Mobile has custom dismiss functionality */}
      {isWeb ? (
        <Pressable position="absolute" top={2} left={2} zIndex={2} onPress={() => setDismiss(true)}>
          <FontAwesomeIcon icon={faX} colorScheme="white" size={3} />
        </Pressable>
      ) : null}
      <Pressable onPress={() => onPress(true)} {..._pressable}>
        <Row
          alignItems="center"
          justifyContent="center"
          space="3"
          py="1.5"
          paddingLeft={isWeb ? 8 : 3}
          paddingRight={3}
        >
          <Box alignItems="center">
            <VoloPassLogo colorScheme="white" size={65} />
          </Box>

          <SaleCopyText fontSize="sm" lineHeight="lg">
            <SaleCopyText
              color={colors.accents.lightTeal}
              fontWeight="semibold"
              fontSize="sm"
            >{`SAVE $${annualSavings} `}</SaleCopyText>
            BY BECOMING AN ANNUAL MEMBER
          </SaleCopyText>
          <FontAwesomeIcon icon={faChevronRight} colorScheme="white" />
        </Row>
      </Pressable>
    </Box>
  );
};

export default VoloPassAnnualUpgradeBanner;
