import { Linking } from 'react-native';
import { View, Text } from '../../base-components';
import { DonationTiers, type DonationOptionType } from '../DonationTiers';
import styles from './styles';

export interface DonationDailyCTAProps {
  donationOptions?: Array<DonationOptionType>;
  website_url?: string;
  onPressOption: (arg: DonationOptionType) => void;
  selectedOptionId?: string;
}

const DailyDonationCTA = ({
  donationOptions,
  website_url = 'https://volokids.org/',
  onPressOption,
  selectedOptionId,
}: DonationDailyCTAProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text bold style={styles.titleMargin}>
          Donation
        </Text>
        <Text style={styles.textMargin}>
          Help us unlock the power of play! Support our Volo Kids Foundation and make a difference
          today. Donate now or{' '}
          <Text isLink onPress={() => Linking.openURL(website_url)}>
            learn how to get involved{' '}
          </Text>
          as a volunteer coach.
        </Text>
      </View>
      <View flex={1}>
        <DonationTiers
          donationOptions={donationOptions}
          onPressOption={onPressOption}
          selectedOptionId={selectedOptionId}
          circular
          showTag
          horizontal
        />
      </View>
    </View>
  );
};

export default DailyDonationCTA;
