import { type Dispatch, type SetStateAction } from 'react';

import { Colors } from '../../../theme';
import {
  StripeSubscriptionStatusEnum,
  MembershipStatus,
  StripeMembershipIntervalEnum,
  MembershipInterval,
} from '../../../constants';
import { View, Button, Text } from '../../../base-components';
import { type MembershipCardProps } from '../MembershipCard';

export interface MemberSectionProps
  extends Pick<MembershipCardProps, 'billingHistory' | 'manageMembership' | 'upgradeAnnual'> {
  membershipStatus?: string;
  membershipType?: string;
  setShowPerks: Dispatch<SetStateAction<boolean>>;
}

const MemberSection = ({
  membershipStatus,
  membershipType,
  setShowPerks,
  billingHistory,
  manageMembership,
  upgradeAnnual,
}: MemberSectionProps) => {
  return (
    <View alignItems="center" marginBottom={3}>
      <Button
        onPress={manageMembership}
        type="white"
        borderColor="transparent"
        padding={0} // clear NB default
        margin={0} // clear NB default
        marginTop={1.5}
        marginBottom={1.5}
        width={320}
        height={39}
        borderRadius={15}
        _text={{ color: Colors.black, fontSize: 15, fontWeight: 400, bold: false }}
      >
        {membershipStatus === MembershipStatus[StripeSubscriptionStatusEnum.ACTIVE].label
          ? 'Manage Membership'
          : 'Reactivate Membership'}
      </Button>
      <Button
        onPress={() => setShowPerks(true)}
        variant="outline"
        borderColor={Colors.white}
        padding={0} // clear NB default
        margin={0} // clear NB default
        marginTop={1.5}
        marginBottom={1.5}
        width={320}
        height={39}
        borderRadius={15}
        _text={{ color: Colors.black, fontSize: 15, fontWeight: 400, bold: false }}
      >
        View Perks
      </Button>
      {membershipStatus === MembershipStatus[StripeSubscriptionStatusEnum.CANCELED].label && (
        <Text
          isLink
          onPress={billingHistory}
          color={Colors.white}
          fontSize={15}
          underline
          marginTop={1}
        >
          Billing History
        </Text>
      )}
      {membershipStatus === MembershipStatus[StripeSubscriptionStatusEnum.ACTIVE].label &&
        membershipType === MembershipInterval[StripeMembershipIntervalEnum.MONTH].label &&
        !!upgradeAnnual && (
          <Text
            isLink
            onPress={upgradeAnnual}
            color={Colors.white}
            fontSize={15}
            underline
            marginTop={1}
          >
            Become Annual Member
          </Text>
        )}
    </View>
  );
};

export default MemberSection;
