import { ProgramTypeEnum, ProgramType } from '../constants';

const formatProgramType = (programType: ProgramTypeEnum) => {
  switch (programType) {
    case ProgramTypeEnum.DROPIN:
      return 'Drop-in';
    case ProgramTypeEnum.VOLUNTEER_EVENT:
    case ProgramTypeEnum.VOLUNTEER_LEAGUE:
      return 'Volunteer';
    default:
      return ProgramType[programType].name;
  }
};

export default formatProgramType;
