import { gql, type TypedDocumentNode } from '@apollo/client';

export type GetCaptainAndUserData = {
  team: {
    _id: string;
    captainId?: string;
    coCaptainIds: string[];
  };
  currentUser: {
    _id: string;
  } | null;
};

export type GetCaptainAndUserInput = {
  id: string;
};

const GET_CAPTAINS_AND_USER: TypedDocumentNode<GetCaptainAndUserData, GetCaptainAndUserInput> = gql`
  query TeamCaptainsAndUser($id: ID!) {
    team(_id: $id) {
      _id
      captainId
      coCaptainIds
    }
    currentUser {
      _id
    }
  }
`;

export default GET_CAPTAINS_AND_USER;
