import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgTShirts = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      stroke="#103b5d"
      d="m10 26 20-8c3 12.5 17 12.5 20 0l20 8-2.5 12-10-2v29.5h-35V36l-10 2zM13 25l2.5 12M64.5 37 67 25M23 62.5h34"
    />
    <Path
      fill="#103b5d"
      d="m13 25 17-7c3 12.5 17 12.5 20 0l17 7-2.5 12.5-7-1.5v26.5h-35V36l-7 1.5z"
    />
    <Path stroke="#103b5d" d="M30 18c5 5 15 5 20 0" />
  </Svg>
);
export default SvgTShirts;
