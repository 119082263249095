import { type FC } from 'react';
import { Text } from '../../../../base-components';
import type { RentalSportsModule } from '../../RentalCard/types';

const RentalSportsSection: FC<Omit<RentalSportsModule, 'type'>> = ({ availableSportTypes }) => {
  const sportList = availableSportTypes.map((sport: string) => sport).join(' · ');
  return (
    <Text fontSize={14} marginTop={1}>
      {sportList}
    </Text>
  );
};

export default RentalSportsSection;
