// ../../node_modules/@0no-co/graphql.web/dist/graphql.web.mjs
var e = {
  NAME: "Name",
  DOCUMENT: "Document",
  OPERATION_DEFINITION: "OperationDefinition",
  VARIABLE_DEFINITION: "VariableDefinition",
  SELECTION_SET: "SelectionSet",
  FIELD: "Field",
  ARGUMENT: "Argument",
  FRAGMENT_SPREAD: "FragmentSpread",
  INLINE_FRAGMENT: "InlineFragment",
  FRAGMENT_DEFINITION: "FragmentDefinition",
  VARIABLE: "Variable",
  INT: "IntValue",
  FLOAT: "FloatValue",
  STRING: "StringValue",
  BOOLEAN: "BooleanValue",
  NULL: "NullValue",
  ENUM: "EnumValue",
  LIST: "ListValue",
  OBJECT: "ObjectValue",
  OBJECT_FIELD: "ObjectField",
  DIRECTIVE: "Directive",
  NAMED_TYPE: "NamedType",
  LIST_TYPE: "ListType",
  NON_NULL_TYPE: "NonNullType"
};
var GraphQLError = class extends Error {
  constructor(e3, r, n2, i2, t3, a3, l2) {
    super(e3);
    this.name = "GraphQLError";
    this.message = e3;
    if (t3) {
      this.path = t3;
    }
    if (r) {
      this.nodes = Array.isArray(r) ? r : [r];
    }
    if (n2) {
      this.source = n2;
    }
    if (i2) {
      this.positions = i2;
    }
    if (a3) {
      this.originalError = a3;
    }
    var o2 = l2;
    if (!o2 && a3) {
      var u2 = a3.extensions;
      if (u2 && "object" == typeof u2) {
        o2 = u2;
      }
    }
    this.extensions = o2 || {};
  }
  toJSON() {
    return {
      ...this,
      message: this.message
    };
  }
  toString() {
    return this.message;
  }
  get [Symbol.toStringTag]() {
    return "GraphQLError";
  }
};
var n;
var i;
function error(e3) {
  return new GraphQLError(`Syntax Error: Unexpected token at ${i} in ${e3}`);
}
function advance(e3) {
  e3.lastIndex = i;
  if (e3.test(n)) {
    return n.slice(i, i = e3.lastIndex);
  }
}
var t = / +(?=[^\s])/y;
function blockString(e3) {
  var r = e3.split("\n");
  var n2 = "";
  var i2 = 0;
  var a3 = 0;
  var l2 = r.length - 1;
  for (var o2 = 0; o2 < r.length; o2++) {
    t.lastIndex = 0;
    if (t.test(r[o2])) {
      if (o2 && (!i2 || t.lastIndex < i2)) {
        i2 = t.lastIndex;
      }
      a3 = a3 || o2;
      l2 = o2;
    }
  }
  for (var u2 = a3; u2 <= l2; u2++) {
    if (u2 !== a3) {
      n2 += "\n";
    }
    n2 += r[u2].slice(i2).replace(/\\"""/g, '"""');
  }
  return n2;
}
function ignored() {
  for (var e3 = 0 | n.charCodeAt(i++); 9 === e3 || 10 === e3 || 13 === e3 || 32 === e3 || 35 === e3 || 44 === e3 || 65279 === e3; e3 = 0 | n.charCodeAt(i++)) {
    if (35 === e3) {
      while (10 !== (e3 = n.charCodeAt(i++)) && 13 !== e3) {
      }
    }
  }
  i--;
}
var a = /[_A-Za-z]\w*/y;
var l = new RegExp("(?:(null|true|false)|\\$(" + a.source + ')|(-?\\d+)((?:\\.\\d+)?[eE][+-]?\\d+|\\.\\d+)?|("""(?:"""|(?:[\\s\\S]*?[^\\\\])"""))|("(?:"|[^\\r\\n]*?[^\\\\]"))|(' + a.source + "))", "y");
var o = function(e3) {
  e3[e3.Const = 1] = "Const";
  e3[e3.Var = 2] = "Var";
  e3[e3.Int = 3] = "Int";
  e3[e3.Float = 4] = "Float";
  e3[e3.BlockString = 5] = "BlockString";
  e3[e3.String = 6] = "String";
  e3[e3.Enum = 7] = "Enum";
  return e3;
}(o || {});
var u = /\\/;
function value(e3) {
  var r;
  var t3;
  l.lastIndex = i;
  if (91 === n.charCodeAt(i)) {
    i++;
    ignored();
    var d2 = [];
    while (93 !== n.charCodeAt(i)) {
      d2.push(value(e3));
    }
    i++;
    ignored();
    return {
      kind: "ListValue",
      values: d2
    };
  } else if (123 === n.charCodeAt(i)) {
    i++;
    ignored();
    var s2 = [];
    while (125 !== n.charCodeAt(i)) {
      if (null == (r = advance(a))) {
        throw error("ObjectField");
      }
      ignored();
      if (58 !== n.charCodeAt(i++)) {
        throw error("ObjectField");
      }
      ignored();
      s2.push({
        kind: "ObjectField",
        name: {
          kind: "Name",
          value: r
        },
        value: value(e3)
      });
    }
    i++;
    ignored();
    return {
      kind: "ObjectValue",
      fields: s2
    };
  } else if (null != (t3 = l.exec(n))) {
    i = l.lastIndex;
    ignored();
    if (null != (r = t3[o.Const])) {
      return "null" === r ? {
        kind: "NullValue"
      } : {
        kind: "BooleanValue",
        value: "true" === r
      };
    } else if (null != (r = t3[o.Var])) {
      if (e3) {
        throw error("Variable");
      } else {
        return {
          kind: "Variable",
          name: {
            kind: "Name",
            value: r
          }
        };
      }
    } else if (null != (r = t3[o.Int])) {
      var v2;
      if (null != (v2 = t3[o.Float])) {
        return {
          kind: "FloatValue",
          value: r + v2
        };
      } else {
        return {
          kind: "IntValue",
          value: r
        };
      }
    } else if (null != (r = t3[o.BlockString])) {
      return {
        kind: "StringValue",
        value: blockString(r.slice(3, -3)),
        block: true
      };
    } else if (null != (r = t3[o.String])) {
      return {
        kind: "StringValue",
        value: u.test(r) ? JSON.parse(r) : r.slice(1, -1),
        block: false
      };
    } else if (null != (r = t3[o.Enum])) {
      return {
        kind: "EnumValue",
        value: r
      };
    }
  }
  throw error("Value");
}
function arguments_(e3) {
  if (40 === n.charCodeAt(i)) {
    var r = [];
    i++;
    ignored();
    var t3;
    do {
      if (null == (t3 = advance(a))) {
        throw error("Argument");
      }
      ignored();
      if (58 !== n.charCodeAt(i++)) {
        throw error("Argument");
      }
      ignored();
      r.push({
        kind: "Argument",
        name: {
          kind: "Name",
          value: t3
        },
        value: value(e3)
      });
    } while (41 !== n.charCodeAt(i));
    i++;
    ignored();
    return r;
  }
}
function directives(e3) {
  if (64 === n.charCodeAt(i)) {
    var r = [];
    var t3;
    do {
      i++;
      if (null == (t3 = advance(a))) {
        throw error("Directive");
      }
      ignored();
      r.push({
        kind: "Directive",
        name: {
          kind: "Name",
          value: t3
        },
        arguments: arguments_(e3)
      });
    } while (64 === n.charCodeAt(i));
    return r;
  }
}
function type() {
  var e3;
  var r = 0;
  while (91 === n.charCodeAt(i)) {
    r++;
    i++;
    ignored();
  }
  if (null == (e3 = advance(a))) {
    throw error("NamedType");
  }
  ignored();
  var t3 = {
    kind: "NamedType",
    name: {
      kind: "Name",
      value: e3
    }
  };
  do {
    if (33 === n.charCodeAt(i)) {
      i++;
      ignored();
      t3 = {
        kind: "NonNullType",
        type: t3
      };
    }
    if (r) {
      if (93 !== n.charCodeAt(i++)) {
        throw error("NamedType");
      }
      ignored();
      t3 = {
        kind: "ListType",
        type: t3
      };
    }
  } while (r--);
  return t3;
}
var d = new RegExp("(?:(\\.{3})|(" + a.source + "))", "y");
var s = function(e3) {
  e3[e3.Spread = 1] = "Spread";
  e3[e3.Name = 2] = "Name";
  return e3;
}(s || {});
function selectionSet() {
  var e3 = [];
  var r;
  var t3;
  do {
    d.lastIndex = i;
    if (null != (t3 = d.exec(n))) {
      i = d.lastIndex;
      if (null != t3[s.Spread]) {
        ignored();
        var l2 = advance(a);
        if (null != l2 && "on" !== l2) {
          ignored();
          e3.push({
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: l2
            },
            directives: directives(false)
          });
        } else {
          ignored();
          if ("on" === l2) {
            if (null == (l2 = advance(a))) {
              throw error("NamedType");
            }
            ignored();
          }
          var o2 = directives(false);
          if (123 !== n.charCodeAt(i++)) {
            throw error("InlineFragment");
          }
          ignored();
          e3.push({
            kind: "InlineFragment",
            typeCondition: l2 ? {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: l2
              }
            } : void 0,
            directives: o2,
            selectionSet: selectionSet()
          });
        }
      } else if (null != (r = t3[s.Name])) {
        var u2 = void 0;
        ignored();
        if (58 === n.charCodeAt(i)) {
          i++;
          ignored();
          u2 = r;
          if (null == (r = advance(a))) {
            throw error("Field");
          }
          ignored();
        }
        var v2 = arguments_(false);
        ignored();
        var c = directives(false);
        var f = void 0;
        if (123 === n.charCodeAt(i)) {
          i++;
          ignored();
          f = selectionSet();
        }
        e3.push({
          kind: "Field",
          alias: u2 ? {
            kind: "Name",
            value: u2
          } : void 0,
          name: {
            kind: "Name",
            value: r
          },
          arguments: v2,
          directives: c,
          selectionSet: f
        });
      }
    } else {
      throw error("SelectionSet");
    }
  } while (125 !== n.charCodeAt(i));
  i++;
  ignored();
  return {
    kind: "SelectionSet",
    selections: e3
  };
}
function fragmentDefinition() {
  var e3;
  var r;
  if (null == (e3 = advance(a))) {
    throw error("FragmentDefinition");
  }
  ignored();
  if ("on" !== advance(a)) {
    throw error("FragmentDefinition");
  }
  ignored();
  if (null == (r = advance(a))) {
    throw error("FragmentDefinition");
  }
  ignored();
  var t3 = directives(false);
  if (123 !== n.charCodeAt(i++)) {
    throw error("FragmentDefinition");
  }
  ignored();
  return {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: e3
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: r
      }
    },
    directives: t3,
    selectionSet: selectionSet()
  };
}
var v = /(?:query|mutation|subscription|fragment)/y;
function operationDefinition(e3) {
  var r;
  var t3;
  var l2;
  if (e3) {
    ignored();
    r = advance(a);
    t3 = function variableDefinitions() {
      ignored();
      if (40 === n.charCodeAt(i)) {
        var e4 = [];
        i++;
        ignored();
        var r2;
        do {
          if (36 !== n.charCodeAt(i++)) {
            throw error("Variable");
          }
          if (null == (r2 = advance(a))) {
            throw error("Variable");
          }
          ignored();
          if (58 !== n.charCodeAt(i++)) {
            throw error("VariableDefinition");
          }
          ignored();
          var t4 = type();
          var l3 = void 0;
          if (61 === n.charCodeAt(i)) {
            i++;
            ignored();
            l3 = value(true);
          }
          ignored();
          e4.push({
            kind: "VariableDefinition",
            variable: {
              kind: "Variable",
              name: {
                kind: "Name",
                value: r2
              }
            },
            type: t4,
            defaultValue: l3,
            directives: directives(true)
          });
        } while (41 !== n.charCodeAt(i));
        i++;
        ignored();
        return e4;
      }
    }();
    l2 = directives(false);
  }
  if (123 === n.charCodeAt(i)) {
    i++;
    ignored();
    return {
      kind: "OperationDefinition",
      operation: e3 || "query",
      name: r ? {
        kind: "Name",
        value: r
      } : void 0,
      variableDefinitions: t3,
      directives: l2,
      selectionSet: selectionSet()
    };
  }
}
function parse(e3, r) {
  i = 0;
  return function document(e4, r2) {
    var n2;
    var t3;
    ignored();
    var a3 = [];
    do {
      if ("fragment" === (n2 = advance(v))) {
        ignored();
        a3.push(fragmentDefinition());
      } else if (null != (t3 = operationDefinition(n2))) {
        a3.push(t3);
      } else {
        throw error("Document");
      }
    } while (i < e4.length);
    if (!r2) {
      var l2;
      return {
        kind: "Document",
        definitions: a3,
        set loc(e5) {
          l2 = e5;
        },
        get loc() {
          if (!l2) {
            l2 = {
              start: 0,
              end: e4.length,
              startToken: void 0,
              endToken: void 0,
              source: {
                body: e4,
                name: "graphql.web",
                locationOffset: {
                  line: 1,
                  column: 1
                }
              }
            };
          }
          return l2;
        }
      };
    }
    return {
      kind: "Document",
      definitions: a3
    };
  }(n = "string" == typeof e3.body ? e3.body : e3, r && r.noLocation);
}

// ../../node_modules/gql.tada/dist/gql-tada.mjs
var a2 = 0;
var e2 = /* @__PURE__ */ new Set();
function initGraphQLTada() {
  function graphql2(t3, i2) {
    var o2 = parse(t3).definitions;
    var s2 = /* @__PURE__ */ new Set();
    for (var f of i2 || []) {
      for (var u2 of f.definitions) {
        if (u2.kind === e.FRAGMENT_DEFINITION && !s2.has(u2)) {
          o2.push(u2);
          s2.add(u2);
        }
      }
    }
    var d2;
    if ((d2 = o2[0].kind === e.FRAGMENT_DEFINITION) && o2[0].directives) {
      o2[0].directives = o2[0].directives.filter((r) => "_unmask" !== r.name.value);
    }
    var c;
    return {
      kind: e.DOCUMENT,
      definitions: o2,
      get loc() {
        if (!c && d2) {
          var r = t3 + function concatLocSources(r2) {
            try {
              a2++;
              var n2 = "";
              for (var t4 of r2) {
                if (!e2.has(t4)) {
                  e2.add(t4);
                  var { loc: i3 } = t4;
                  if (i3) {
                    n2 += i3.source.body;
                  }
                }
              }
              return n2;
            } finally {
              if (0 == --a2) {
                e2.clear();
              }
            }
          }(i2 || []);
          return {
            start: 0,
            end: r.length,
            source: {
              body: r,
              name: "GraphQLTada",
              locationOffset: {
                line: 1,
                column: 1
              }
            }
          };
        }
        return c;
      },
      set loc(r) {
        c = r;
      }
    };
  }
  graphql2.scalar = function scalar(r, n2) {
    return n2;
  };
  graphql2.persisted = function persisted(n2, a3) {
    return {
      kind: e.DOCUMENT,
      definitions: a3 ? a3.definitions : [],
      documentId: n2
    };
  };
  return graphql2;
}
function readFragment(...r) {
  return 2 === r.length ? r[1] : r[0];
}
var t2 = initGraphQLTada();

// src/index.ts
var graphql = initGraphQLTada();
export {
  graphql,
  readFragment
};
