/* eslint-disable react/destructuring-assignment */
import React, { type ComponentProps } from 'react';
import { FontAwesomeIcon, Stack, Text } from '../../../base-components';
import { useTheme } from '../../../theme';

type FontAwesomeIconWithoutIcon = Omit<ComponentProps<typeof FontAwesomeIcon>, 'icon'>;

type VoloPassPerkProps = {
  title?: string;
  description?: string;
} & (
  | {
      icon: ComponentProps<typeof FontAwesomeIcon>['icon'];
    }
  | {
      iconComponent: React.ComponentType<FontAwesomeIconWithoutIcon>;
    }
);

const VoloPassPerk: React.FC<VoloPassPerkProps> = props => {
  const { title, description } = props;

  const { colors } = useTheme();
  return (
    <Stack direction="row" alignItems="center" px="4" space="4" my="2">
      {'iconComponent' in props ? (
        <props.iconComponent color={colors.accents.lightTeal} size="4xl" />
      ) : (
        <FontAwesomeIcon icon={props.icon} color={colors.accents.lightTeal} size="4xl" />
      )}

      <Stack direction="column">
        <Text
          textTransform="uppercase"
          color="white.200"
          fontWeight="semibold"
          fontSize="sm"
          letterSpacing="lg"
        >
          {title}
        </Text>
        <Text type="caption" color="white.400" fontSize="xs" fontWeight="thin" letterSpacing="lg">
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};

export default VoloPassPerk;
