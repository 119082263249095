import { useMutation } from '@apollo/client';

import { Button, type ButtonProps } from '../../../../base-components';
import { ModalAlertComponent, ModalAlert } from '../../../../custom-components';
import { useRentalBookingStore, type RentalBooking } from '../../../RentalsRegistration';
import { getBookingMutationInput, getReservationMutationInput } from './helpers';
import { BOOK_RENTALS, JOIN_RESERVATION } from './gql/mutations';

export interface RentalCheckoutButtonProps extends ButtonProps {
  onCompleteBookingMutation: ({
    reservationIds,
    deepLink,
  }: {
    reservationIds: Array<string>;
    deepLink: string | undefined;
  }) => void;
  /**
   * Callback to run when the user tries to book rentals but they are no longer available
   * - utilize to bring the user back to the cart to update their selections
   */
  onBookingNotAvailable: () => void;
}

const { moveConfirmedBookingToEdit } = useRentalBookingStore.getState().actions;

const onBookingAlreadyReservedRemoveCourts = (bookingId: string) => {
  useRentalBookingStore.setState(store => {
    const { currentBooking } = store;
    if (!currentBooking) {
      return store;
    }

    if (bookingId !== currentBooking.bookingId) {
      return store;
    }

    const updatedConfirmedBooking: RentalBooking = {
      ...currentBooking,
      steps: {
        ...currentBooking.steps,
        courtsSelection: {
          ...currentBooking.steps.courtsSelection,
          selectedCourts: [],
        },
      },
    };

    return {
      currentBooking: updatedConfirmedBooking,
    };
  });
};

const RentalCheckoutButton = ({
  onCompleteBookingMutation,
  onBookingNotAvailable,
  isDisabled: propsIsDisabled,
  ...rest
}: RentalCheckoutButtonProps) => {
  const { isDisabled, donationCents, cartTotalAfterDiscountCents, reservationId } =
    useRentalBookingStore(store => ({
      isDisabled: !store.rentalPolicyAgreed || !store.liabilityTermsAgreed || !store.cardSourceId,
      donationCents: store.donation?.value,
      cartTotalAfterDiscountCents: store.cartTotalAfterDiscountCents,
      reservationId: store.reservationId,
    }));

  function getButtonText() {
    if (!donationCents && cartTotalAfterDiscountCents === 0) {
      return 'Register';
    }
    if (donationCents && cartTotalAfterDiscountCents === 0) {
      return 'Donate and Register';
    }

    return 'Pay and Register';
  }

  const [bookRentals, { loading: isBooking }] = useMutation(BOOK_RENTALS);
  const [joinReservation, { loading: isJoining }] = useMutation(JOIN_RESERVATION);
  const isLoading = isBooking || isJoining;

  async function handleCheckoutMutation() {
    try {
      if (reservationId) {
        await joinReservation({
          variables: { input: getReservationMutationInput(reservationId) },
          onCompleted: _data => {
            onCompleteBookingMutation({
              reservationIds: [_data?.joinReservation._id],
              /* undefined to not show Invite btn in confirmation modal */
              deepLink: undefined,
            });
          },
        });
        return;
      }

      await bookRentals({
        variables: { input: getBookingMutationInput() },
        onCompleted: _data => {
          onCompleteBookingMutation({
            reservationIds: _data?.bookRentals.rentalReservations.map(
              reservation => reservation._id
            ),
            deepLink: _data?.bookRentals.rentalReservations[0]?.deep_link,
          });
        },
      });
    } catch (e) {
      if (!(e instanceof Error)) {
        return;
      }

      if (e.message.includes('reserved') || e.message.includes('deleted')) {
        const [message = '', bookingId = ''] = e.message.split(':');

        ModalAlert.alert({
          id: 'booking-modal-alert',
          title: 'Error',
          message,
          buttons: [
            {
              text: 'Update Cart',
              onPress: () => {
                moveConfirmedBookingToEdit(bookingId);
                onBookingAlreadyReservedRemoveCourts(bookingId);
                onBookingNotAvailable();
              },
            },
          ],
        });
        return;
      }

      ModalAlert.alert({
        id: 'booking-modal-alert',
        title: 'Error',
        message: e.message,
      });
    }
  }

  return (
    <>
      <Button
        width="full"
        isDisabled={propsIsDisabled || isDisabled}
        isLoading={isLoading}
        onPress={async () => handleCheckoutMutation()}
        {...rest}
      >
        {getButtonText()}
      </Button>
      <ModalAlertComponent id="booking-modal-alert" />
    </>
  );
};

export default RentalCheckoutButton;
