import { useState, useEffect } from 'react';
import { Keyboard, Platform, type KeyboardEvent } from 'react-native';

/**
 * Hook to listen for keyboard events (mobile)
 * - gives access to keyboard height and visibility
 */
const useKeyboardListener = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  function onKeyboardShow(e: KeyboardEvent): void {
    setKeyboardHeight(e.endCoordinates.height);
    setIsKeyboardVisible(true);
  }

  function onKeyboardHide(): void {
    setKeyboardHeight(0);
    setIsKeyboardVisible(false);
  }

  useEffect(() => {
    const showEvent = Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow';
    const hideEvent = Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide';

    const showSubscription = Keyboard.addListener(showEvent, onKeyboardShow);
    const hideSubscription = Keyboard.addListener(hideEvent, onKeyboardHide);

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return { keyboardHeight, isKeyboardVisible };
};

export default useKeyboardListener;
