import tinycolor from 'tinycolor2';

import type { Color } from '../../theme/types';

type ColorManipulation = {
  /**
   * Darken a color by a percentage
   * @param color - the color to darken
   * @param amount - the amount to darken by
   * @returns {Color} - the new color
   */
  darken: (color: Color, amount: number) => Color;
  /**
   * Lighten a color by a percentage
   * @param color - the color to lighten
   * @param amount - the amount to lighten by
   * @returns {Color} - the new color
   */
  lighten: (color: Color, amount: number) => Color;
  /**
   * Check if a color is dark
   * @param color - the color to check
   * @returns {boolean} - true if the color is dark
   */
  isDark: (color: Color) => boolean;
  /**
   * Get the complementary color
   * @param color - the color to get the complementary of
   * @returns {Color} - the complementary color
   */
  complementary: (color: Color) => Color;
};

/**
 * Utility functions for manipulating colors
 * - this is a wrapper around tinycolor2
 * - helpful for creating contrast between colors + theming
 */
const colorManipulation: ColorManipulation = {
  // @ts-ignore
  darken: (color, amount) => tinycolor(color).darken(amount).toString(),

  // @ts-ignore
  lighten: (color, amount) => tinycolor(color).lighten(amount).toString(),

  // @ts-ignore
  isDark: color => tinycolor(color).isDark(),

  // @ts-ignore
  complementary: color => tinycolor(color).complement().toString(),
};

export default colorManipulation;
