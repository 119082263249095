import { Modal as BaseModal } from '@rivallapp/native-base';
import type { ComponentProps } from 'react';
import { Animated } from 'react-native';
import { useKeyboardTranslation } from '../../hooks';
import { isMobile } from '../../utilities';

/* baseStyles adjusted in ThemeProvider.tsx */
const Modal = BaseModal as typeof BaseModal & {
  KeyboardAwareContent: React.FC<ComponentProps<typeof BaseModal.Content>>;
};

const KeyboardAwareModalContent = ({
  children,
  ...props
}: ComponentProps<typeof BaseModal.Content>) => {
  const { animatedTranslationStyle } = useKeyboardTranslation();

  return isMobile ? (
    <Animated.View style={animatedTranslationStyle}>
      <BaseModal.Content {...props}>{children}</BaseModal.Content>
    </Animated.View>
  ) : (
    <BaseModal.Content {...props}>{children}</BaseModal.Content>
  );
};

Modal.KeyboardAwareContent = KeyboardAwareModalContent;

export default Modal;
