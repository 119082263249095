/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, type PropsWithChildren } from 'react';
import { gql, useSuspenseQuery, type TypedDocumentNode, skipToken } from '@apollo/client';
import moment from 'moment-timezone';

import { getValueFromActivityName, type TRentalActivityName } from '../../../../constants';
import {
  getCompositeKey,
  rentalBlocksToSlots,
  useRentalBookingStore,
  type RentalBooking,
} from '../../../RentalsRegistration';
import { ModalAlert, ModalAlertComponent } from '../../../../custom-components';

interface JoinReservationProviderProps extends PropsWithChildren {
  /**
   * Rental ID to join a reservation for.
   * @web needs to be provided
   */
  rentalId: string;
  reservationId?: string;
  /**
   * Fallback component to render in case of an error.
   */
  fallback: JSX.Element;
  onReservationPast: { onGoBackPress: () => void };
  /**
   * If the rentalId of the screen does not match the rentalId of the reservation, this callback will be run.
   * @web - useful if the url is manipulated to join a reservation while on a different rental.
   */
  onRentalIdMismatch: (reservationRentalId: string) => void;
}

interface RentalReservationData {
  rentalReservation: {
    _id: string;
    courts: string[];
    reserving_user: {
      _id: string;
      firstName: string;
      lastName: string;
    };
    num_guests_estimate: number;
    password?: string;
    deep_link: string;
    date_str: string;
    /**
     * DateTime string in UTC format
     */
    end_time: string;
    rental_blocks: {
      _id: string;
      capacity: number;
      end_time_str: string;
      has_open_reservations: boolean;
      rentalId: string;
      is_prime_time: boolean;
      start_time_str: string;
      timeslot_length: number;
    }[];
    rental: {
      _id: string;
      activity_name: TRentalActivityName;
      timezone: string;
    };
    selected_courts: {
      _id: string;
      name: string;
      timeSlotIds: string[];
      is_available: boolean;
    }[];
  };
}

const GET_RESERVATION_DETAILS: TypedDocumentNode<RentalReservationData, { id: string }> = gql`
  query RentalReservation($id: ID!) {
    rentalReservation(_id: $id) {
      _id
      courts
      reserving_user {
        _id
        firstName
        lastName
      }
      num_guests_estimate
      password
      deep_link
      date_str
      end_time
      rental_blocks {
        _id
        capacity
        end_time_str
        has_open_reservations
        rentalId
        is_prime_time
        start_time_str
        timeslot_length
      }
      rental {
        _id
        activity_name
        timezone
      }
      selected_courts {
        _id
        name
        timeSlotIds
        is_available
      }
    }
  }
`;

/**
 * Wrapper provider component that sets up the reservation flow for joining a reservation.
 * - This handles querying the reservation details & formatting them into a `RentalBooking` object to be used in the `store.confirmedBookings` dictionary.
 */
const JoinReservationProvider = ({
  children,
  rentalId,
  reservationId,
  fallback,
  onReservationPast,
  onRentalIdMismatch,
}: JoinReservationProviderProps) => {
  const { data, error } = useSuspenseQuery(
    GET_RESERVATION_DETAILS,
    reservationId
      ? {
          variables: { id: reservationId },
        }
      : skipToken
  );

  useEffect(
    function handleReservationFlow() {
      const formatReservationDataToConfirmedBooking = (
        reservationData: RentalReservationData['rentalReservation']
      ) => {
        const now = moment.tz();
        const endTimeOfRes = moment.tz(reservationData.end_time, reservationData.rental.timezone);

        if (now.isAfter(endTimeOfRes)) {
          ModalAlert.alert({
            id: 'join-reservation-provider-alert',
            title: 'Reservation Has Ended',
            message:
              'This reservation has passed, so you can no longer register for it. Please reach out to customer support if you have any questions or concerns.',
            modalProps: { closeOnOverlayClick: false },
            buttons: [
              {
                text: 'Go Back',
                onPress: () => {
                  onReservationPast.onGoBackPress();
                },
              },
            ],
          });

          return;
        }

        const confirmedBooking: RentalBooking = {
          bookingId: getCompositeKey({
            _id: reservationData.rental._id,
            activity_name: getValueFromActivityName(reservationData.rental.activity_name),
          }),
          rentalId: reservationData.rental._id,
          rentalType: getValueFromActivityName(reservationData.rental.activity_name),
          currentStep: 'confirmSelections',
          steps: {
            dateSelection: {
              startDate: moment(reservationData.date_str).toDate(),
              timeSlots: rentalBlocksToSlots(
                reservationData.rental_blocks,
                new Date(reservationData.date_str)
              ),
            },
            courtsSelection: {
              selectedCourts: reservationData.selected_courts,
            },
            groupSettings: {
              groupSize: reservationData.num_guests_estimate,
              groupPasswordEnabled: !!reservationData.password,
              groupPassword: reservationData.password,
            } as RentalBooking['steps']['groupSettings'],
          },
          price: {
            // safe to add 0 here as the reservation 'should' be paid for
            // real check happens though during the checkout process
            subtotal: 0,
            formattedSubtotal: '$0.00',
          },
          isEditMode: false,
        };

        useRentalBookingStore.setState({
          reservationId,
          joiningReservationDetails: {
            createdBy: {
              userId: reservationData.reserving_user._id,
              firstNameLastInitial: `${reservationData.reserving_user.firstName} ${
                reservationData.reserving_user?.lastName[0] || ''
              }`,
            },
            groupPassword: reservationData?.password,
          },
          confirmedBookings: {
            [confirmedBooking.bookingId]: confirmedBooking,
          },
        });
      };

      if (data && reservationId) {
        const reservationData = data.rentalReservation;

        if (reservationData.rental._id !== rentalId) {
          onRentalIdMismatch(reservationData.rental._id);
        }

        // reset store to make sure we are starting clean for this reservation
        useRentalBookingStore.getState().actions.resetRentalBookingStore();
        formatReservationDataToConfirmedBooking(reservationData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, reservationId]
  );

  return (
    <>
      {reservationId && (error || !data) ? fallback : <>{children}</>}
      <ModalAlertComponent id="join-reservation-provider-alert" />
      <ModalAlertComponent id="another-reservation-in-progress-alert" />
    </>
  );
};

export default JoinReservationProvider;
