import React, { forwardRef, memo, useCallback, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { Input, type IInputProps } from '@rivallapp/native-base';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';

import { useTheme } from '../../../theme';
import FontAwesomeIcon from '../../Icon';

export type CTextInputProps = {
  /**
   * Whether or not the text input should be secure (hidden text)
   * - utilized for `password` inputs
   * @default false
   */
  isSecureText?: boolean;
} & IInputProps;

const placeholderColor = 'gray.400';

/**
 * A custom text input component that utilizes the `native-base` `Input` component
 * - utilizes the `useTheme` hook to dynamically change the placeholder text color (light or dark theme)
 */
const TextInput = forwardRef(({ isSecureText = false, ...rest }: CTextInputProps, ref) => {
  const { colors } = useTheme();
  const [isSecureTextField, setIsSecureTextField] = useState<boolean>(isSecureText);
  const toggleSecureText = useCallback(() => setIsSecureTextField(prev => !prev), []);

  return (
    <Input
      ref={ref as React.MutableRefObject<unknown>}
      flex={1}
      type={isSecureTextField ? 'password' : 'text'}
      InputRightElement={
        isSecureText ? (
          <TouchableOpacity
            onPress={toggleSecureText}
            style={{ paddingRight: 10, alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={isSecureTextField ? faEyeSlash : faEye} size={5} />
          </TouchableOpacity>
        ) : undefined
      }
      autoCapitalize={isSecureText ? 'none' : 'sentences'}
      placeholderTextColor={placeholderColor}
      borderColor={placeholderColor}
      height={50}
      _input={{ selectionColor: colors.text }}
      {...rest}
    />
  );
});

export default memo(TextInput);
