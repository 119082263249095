import { Heading, type HeadingProps } from '../../../../../base-components';

/**
 * Heading component for section titles in the rental registration flow
 * - Uses the `subsection` type as default
 */
const RentalSectionTitle = (props: HeadingProps) => {
  return <Heading type="subsection" {...props} />;
};

export default RentalSectionTitle;
