import { View, type IViewProps } from '../../../../../../base-components';
import {
  outerSlotContainerHeight,
  outerSlotContainerWidth,
} from '../../DateSelectorItem/DateSelector.constants';

interface SlotContainerProps extends IViewProps {}

/**
 * Parent container to apply the outerSlotContainerWidth and outerSlotContainerHeight to the slots
 */
const SlotContainer = (props: SlotContainerProps) => {
  return (
    <View
      nativeID="outer-slot-container-row"
      style={{ width: outerSlotContainerWidth, height: outerSlotContainerHeight }}
      justifyContent="center"
      alignItems="center"
      {...props}
    />
  );
};

export default SlotContainer;
