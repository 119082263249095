import { ChevronLeftIcon, ChevronRightIcon } from '@rivallapp/native-base';
import { Calendar, type CalendarProps } from 'react-native-calendars';
import useCalendarTheme from './useCalendarTheme';

const renderChevron = (direction: 'right' | 'left') => {
  if (direction === 'left') {
    return <ChevronLeftIcon />;
  }
  if (direction === 'right') {
    return <ChevronRightIcon />;
  }

  return null;
};

const ThemedCalendar = (props: CalendarProps) => {
  const theme = useCalendarTheme();

  return (
    <Calendar
      theme={theme}
      renderArrow={renderChevron}
      minDate={new Date().toISOString()}
      {...props}
    />
  );
};

export default ThemedCalendar;
