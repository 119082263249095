import type { ComponentProps, Dispatch, SetStateAction } from 'react';

import { useTheme, Colors } from '../../../theme';
import { ProgramTypeEnum } from '../../../constants/enums';
import { formatProgramType } from '../../../utilities';
import { Button, Text } from '../../../base-components';

export interface CancelPolicyButtonsProps extends ComponentProps<typeof Button> {
  programType: ProgramTypeEnum;
  onPressConfirm: Dispatch<SetStateAction<'confirm'>>;
  onPressPolicy: Dispatch<SetStateAction<'policy'>>;
}

const CancelPolicyButtons = ({
  programType,
  onPressPolicy,
  onPressConfirm,
  ...props
}: CancelPolicyButtonsProps) => {
  const { colors } = useTheme();

  const formattedProgramType = formatProgramType(programType);

  return (
    <>
      <Button
        variant="outline"
        borderColor={colors.primary}
        marginBottom={1}
        flex={1}
        onPress={() => onPressPolicy('policy')}
        _dark={{
          _text: {
            color: Colors.link,
          },
        }}
        {...props}
      >
        See Cancellation and No-Show Policy
      </Button>
      <Button
        variant="outline"
        borderColor={colors.accents.no}
        marginTop={1}
        flex={1}
        onPress={() => onPressConfirm('confirm')}
        {...props}
      >
        {/* This wouldn't format correctly without the Text wrapper - _text still formats incorrectly */}
        <Text color={colors.accents.no} fontWeight={700} fontSize={14}>
          Cancel {formattedProgramType} Spot
        </Text>
      </Button>
    </>
  );
};

export default CancelPolicyButtons;
