import { gql } from '@apollo/client';

export default gql`
  query league($leagueId: ID!) {
    league(_id: $leagueId) {
      _id
      isDaily
      gender
      formatType
      max_dropins_per_game
      suggestedWomen
      suggestedPlayers
      minWomen
      minPlayers
      maxPlayersOnField {
        total
      }
    }
  }
`;
