import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgNewPerk = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#0a3355" />
    <Path
      fill="#103b5d"
      d="m61.915 34.391-14.9-1.342-5.918-13.348c-.415-.935-1.78-.935-2.194 0l-5.918 13.348-14.9 1.342c-1.043.094-1.465 1.355-.678 2.026L28.65 46.01l-3.29 14.178c-.23.993.874 1.772 1.775 1.252L40 54.02l12.866 7.42c.901.52 2.005-.26 1.775-1.252l-3.29-14.178 11.242-9.592c.787-.671.365-1.932-.678-2.026"
    />
  </Svg>
);
export default SvgNewPerk;
