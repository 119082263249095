import { useSuspenseQuery } from '@apollo/client';
import type { InterfaceHStackProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Stack/HStack';
import type { ComponentProps, FC } from 'react';
import { Column, type ITextProps, Row, Text } from '../../../base-components';
import { WholeDollarFormatter } from '../../../utilities';
import { RENTAL_OVERVIEW_STATS } from '../gql';

const CustomCol: FC<ComponentProps<typeof Column>> = props => <Column marginX="6" {...props} />;

const Label: FC<ITextProps> = props => <Text color="gray.400" {...props} />;

interface ProgramStatsProps {
  rental_id: string;
  _container?: InterfaceHStackProps;
}

const ProgramStats: FC<ProgramStatsProps> = ({ rental_id, _container }) => {
  const { data } = useSuspenseQuery(RENTAL_OVERVIEW_STATS, {
    variables: {
      _id: rental_id,
    },
  });

  const { rentalOverviewStats } = data;
  const { reservation_holder_count, attendee_count, slots_taken, slots_total, revenue_cents } =
    rentalOverviewStats;

  return (
    <Row justifyContent="center" {..._container}>
      <CustomCol>
        <Text>
          {slots_taken}/{slots_total}
        </Text>
        <Label>Slots Taken</Label>
      </CustomCol>
      <CustomCol>
        <Text>{reservation_holder_count}</Text>
        <Label>Reservation</Label>
        <Label>Holders</Label>
      </CustomCol>
      <CustomCol>
        <Text>{attendee_count}</Text>
        <Label>Attendees</Label>
      </CustomCol>
      <CustomCol>
        <Text>{WholeDollarFormatter.format(revenue_cents / 100)}</Text>
        <Label>Revenue</Label>
      </CustomCol>
    </Row>
  );
};

export default ProgramStats;
