import { type TypedDocumentNode, gql } from '@apollo/client';

type GetVenueNameData = {
  venue: {
    _id: string;
    shorthandName: string;
  };
};

type GetVenueNameInput = {
  venueId: string;
};

const GET_VENUE_NAME: TypedDocumentNode<GetVenueNameData, GetVenueNameInput> = gql`
  query venue($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      shorthandName
    }
  }
`;

export default GET_VENUE_NAME;
