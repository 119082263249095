/**
 * Generate a random string with the inclusion of the current time (timestamp)
 * - returns a string with the current time and a random string of characters
 *
 * example: `'1631530730000-xxxxxx-xxxxxx-xxxxxx'`
 *
 * @param uniqueness - the number of random strings to include in the final string
 */
function generateRandomStr(uniqueness = 3) {
  return `${Date.now()}-${Array.from({ length: uniqueness }, () =>
    Math.random().toString(36).substring(2, 8)
  ).join('-')}`;
}

export default generateRandomStr;
