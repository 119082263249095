import React from 'react';
import { faImage } from '@fortawesome/pro-light-svg-icons/faImage';

import { useTheme } from '../../../theme';
import { Box, Chip, Text } from '../../../base-components';
import type { CollapseFormInputProps } from './types';
import { useCollapseFormContext } from '../CollapseFormContext';

export interface FileUploadProps extends CollapseFormInputProps {
  fileName: string;
  onPress?: () => void;
  // additional props will depend on uploader
}

const FileUpload: React.FC<FileUploadProps> = ({ label, fileName, onPress = () => {} }) => {
  const { view } = useCollapseFormContext();
  const { colors } = useTheme();

  switch (view) {
    case 'write':
      // V2
      return null;
    case 'read':
      return (
        <Box maxWidth={200}>
          <Text fontWeight="bold">{label}</Text>
          <Chip
            label={fileName}
            leftIcon={faImage}
            containerStyle={{ margin: 1 }}
            onPress={onPress}
            _text={{ maxWidth: 150, numberOfLines: 1, color: colors.text }}
          />
        </Box>
      );
    default:
      return null;
  }
};

export default FileUpload;
