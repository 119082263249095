import { type FC, useState } from 'react';

import { Text, Box, FormControl } from '../../../base-components';
import type { CollapseFormInputProps } from './types';
import { useCollapseFormContext } from '../CollapseFormContext';

export interface MultiSelectProps extends CollapseFormInputProps {
  selectedResponses?: string[];
  onChange?: (arg: string[]) => void;
}

const MultiSelect: FC<MultiSelectProps> = ({
  label,
  isRequired,
  selectedResponses = [],
  onChange = () => {},
}) => {
  const { view } = useCollapseFormContext();
  const [currentSelection, setCurrentSelection] = useState(selectedResponses);

  const handleSelect = (newVal: string) => {
    if (!newVal) return;

    if (currentSelection.includes(newVal)) {
      setCurrentSelection(currentSelection.filter(item => item !== newVal));
      onChange(currentSelection.filter(item => item !== newVal));
    } else setCurrentSelection([...currentSelection, newVal]);
    onChange([...currentSelection, newVal]);
  };

  const currentSelectionString = currentSelection.join(', ');

  switch (view) {
    case 'write':
      return (
        <FormControl isRequired={isRequired}>
          <FormControl.Label>{label}</FormControl.Label>
          {/* Will require new component since NB doesn't offer a multi-select */}
        </FormControl>
      );
    case 'read':
      return (
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Text fontWeight="bold">{label} </Text>
          <Text>{currentSelectionString}</Text>
        </Box>
      );
    default:
      return null;
  }
};

export default MultiSelect;
