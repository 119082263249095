import React, { Fragment, useState } from 'react';
import { Box, Button, Divider, Pressable, Row, type PressableProps } from '../../base-components';
import { VoloPassLogo } from '../../custom-components';
import type { VoloPassSaleBannerProps } from './VoloPassSaleBanner';
import useDynamicSaleCopy from './hooks/useDynamicSaleCopy';
import SaleCopyText from './components/SaleCopyText';
import { useTheme } from '../../theme';
import VoloPassSaleBannerOverlay, {
  type VoloPassSaleBannerOverlayProps,
} from './VoloPassSaleBannerOverlay';

interface VoloPassSaleBannerThinProps extends VoloPassSaleBannerProps {
  _pressable?: Partial<PressableProps>;
  onPressAddToCart: VoloPassSaleBannerOverlayProps['onPressAddToCart'];
}

const VoloPassSaleBannerThin: React.FC<VoloPassSaleBannerThinProps> = props => {
  const {
    programType,
    memberPriceInCents,
    nonmemberPriceInCents,
    onPressAddToCart,
    _pressable = {},
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { colors } = useTheme();
  const saleCopy = useDynamicSaleCopy({ programType, memberPriceInCents, nonmemberPriceInCents });

  return (
    <>
      <Box
        borderRadius="xl"
        bg={{
          linearGradient: {
            colors: ['primary.500', 'primary.800'],
            start: [0, 1],
            end: [1, 0],
          },
        }}
      >
        <Pressable onPress={() => setIsOpen(true)} {..._pressable}>
          <Row
            alignItems="center"
            justifyContent="center"
            space="3"
            alignSelf="center"
            py="1.5"
            px={3}
          >
            <Box alignItems="center">
              <VoloPassLogo colorScheme="white" size={65} />
            </Box>

            <Divider orientation="vertical" bg="white.50" thickness="2" h="30px" />

            <SaleCopyText fontSize="sm" lineHeight="lg">
              {saleCopy.map(({ text, isHighlighted }, index) =>
                isHighlighted ? (
                  <SaleCopyText
                    // No real way to key this correctly.
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color={colors.accents.lightTeal}
                    fontWeight="semibold"
                    fontSize="sm"
                  >{`${text} `}</SaleCopyText>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>{`${text} `}</Fragment>
                )
              )}

              <Button
                pointerEvents="none" // We want to style it like a button but not behave like one
                _text={{
                  fontSize: 'sm',
                  lineHeight: 'lg',
                }}
                _ios={{
                  // pointerEvents: none doesn't seem to work on iOS, so the workaround here is to disable the button and overwrite some of the disabled styles
                  pointerEvents: 'auto',
                  isDisabled: true,
                  _disabled: { opacity: undefined, bg: 'secondary.700' },
                  style: {
                    transform: [{ translateY: 4 }],
                  },
                }}
                _android={{
                  style: {
                    transform: [{ translateY: 6 }],
                  },
                }}
                mx="0.5"
                px="2"
                py="0.5"
                bg="secondary.700"
                borderRadius="xl"
                height={undefined} // Sets the height to be automatic based on content
              >
                <SaleCopyText flex={0}>Learn More</SaleCopyText>
              </Button>
            </SaleCopyText>
          </Row>
        </Pressable>
      </Box>

      <VoloPassSaleBannerOverlay
        {...props}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onPressAddToCart={onPressAddToCart}
      />
    </>
  );
};

export default VoloPassSaleBannerThin;
