import { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

import { isMobile } from '../../../../utilities';
import { View, Text, Row, Switch, Stack, FormControl } from '../../../../base-components';
import { HaloChip } from '../../../../custom-components';
import {
  RentalDivider,
  RentalSectionContainer,
  RentalSectionLabel,
  RentalSectionTitle,
  RentalSubtext,
  RentalTextInput,
} from '../../common/components';
import {
  useRentalBookingCourts,
  useRentalBookingGuests,
  useRentalBookingTimeSlots,
} from '../../store';
import { minGroupPasswordLength } from '../steps.constants';

function getColorScheme(isDisabled: boolean) {
  return isDisabled ? 'gray' : 'rentalBlue';
}

/**
 * Formats the group size to be an empty string if the group size is 0
 * - this is to show placeholder text instead of the number 0, leading to a better user experience
 */
function formatGroupSize(groupSize: number | null) {
  const groupSizStr = groupSize?.toString();

  if (groupSizStr === '0') return '';
  return groupSizStr;
}

/**
 * Design the buttons for inc/dec from mocks
 */
const roundedChipProps = {
  justifyContent: 'center',
  alignItems: 'center',
  height: 6,
  width: 6,
  borderRadius: 'full',
  alignSelf: 'center',
};

interface PasswordError {
  spaces: {
    hasError: boolean;
    message: string;
  };
  length: {
    hasError: boolean;
    message: string;
  };
}

const initialPasswordError: PasswordError = {
  spaces: {
    hasError: false,
    message: 'No spaces allowed',
  },
  length: {
    hasError: false,
    message: `Password must be at least ${minGroupPasswordLength} characters long`,
  },
};

const androidPasswordHelperText = `No spaces allowed. Password must be at least ${minGroupPasswordLength} characters long`;

/**
 * Android has an issue where when FromControl updates from being invalid, re renders the whole screen/sheet
 * - re worked to not use FormControl.ErrorMessage for the password input, and opted for a helper text instead which gets rid of the issue
 */
const canUseFormControlMessage = Platform.OS !== 'android';

/**
 * Check if any of the password error states are true
 * - if any of the error states are true, the password input is currently invalid
 */
function hasErrors(passwordError: PasswordError) {
  return Object.values(passwordError).some(({ hasError }) => hasError);
}

const GroupSection = () => {
  // password error state for this UI - validation on store side still happens for the `footer` continue btn when each step is valid
  const passwordInputHasBeenTouched = useRef(false);
  const [passwordError, setPasswordError] = useState<PasswordError>(initialPasswordError);
  const updatePasswordError = (key: keyof PasswordError, hasErrorValue: boolean) => {
    setPasswordError(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        hasError: hasErrorValue,
      },
    }));
  };
  const checkForSpaces = (val: string) => {
    const { spaces } = passwordError;
    if (val.includes(' ') && !spaces.hasError) {
      updatePasswordError('spaces', true);
      return;
    }

    if (!val.includes(' ') && spaces.hasError) {
      updatePasswordError('spaces', false);
    }
  };
  const checkForLength = (val: string | undefined) => {
    if (!val) {
      return;
    }

    const { length } = passwordError;
    if (val.trim().length < minGroupPasswordLength && !length.hasError) {
      updatePasswordError('length', true);
      return;
    }

    if (val.trim().length >= minGroupPasswordLength && length.hasError) {
      updatePasswordError('length', false);
    }
  };

  // ... end password error state region ...

  const { timeSlots } = useRentalBookingTimeSlots();
  const capacity = timeSlots[0]?.rentalBlock?.capacity || 0;
  const { selectedCourts } = useRentalBookingCourts();
  // take rental capacity * number of selected courts
  // - direct from product team - this is how to calculate the max number of guests
  const num_guests = capacity * selectedCourts.length;

  const {
    groupSize,
    groupPasswordEnabled,
    groupPassword,
    updateGroupSize,
    updateGroupPassword,
    toggleGroupPasswordEnabled,
  } = useRentalBookingGuests();
  const decrementDisabled = typeof groupSize !== 'number' || groupSize <= 0;
  const incrementDisabled = (groupSize || 0) >= num_guests;
  const decrementGroupSizeHandler = () => updateGroupSize('decrement', num_guests);
  const incrementGroupSizeHandler = () => updateGroupSize('increment', num_guests);
  const inputGroupSizeHandler = (val: string) => updateGroupSize(val, num_guests);
  /**
   * Toggle the group password enabled state
   * - if the group password is enabled, reset internal password states
   */
  const passwordTogglerHandler = () => {
    toggleGroupPasswordEnabled();
    if (groupPasswordEnabled) {
      // reset password error states
      setPasswordError(initialPasswordError);
      passwordInputHasBeenTouched.current = false;
    }
  };
  /**
   * Check for spaces and length on change for password
   */
  const passwordChangeHandler = (val: string) => {
    checkForSpaces(val);

    if (passwordInputHasBeenTouched.current) {
      checkForLength(val);
    }

    updateGroupPassword(val);
  };
  /**
   * Check for length on blur and set touched state
   */
  const onPasswordBlurHandler = () => {
    passwordInputHasBeenTouched.current = true;
    checkForLength(groupPassword);
  };

  useEffect(function runErrorChecksOnReMount() {
    // if we have a password on mount, means we went to a another step and came back
    // need to check for errors if we have a password already
    if (groupPassword?.length) {
      passwordInputHasBeenTouched.current = true;
      checkForSpaces(groupPassword);
      checkForLength(groupPassword);
    }
    // only want this to check on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      <RentalSectionContainer>
        <RentalSectionTitle>Group Settings</RentalSectionTitle>
        <RentalSubtext>{`Bring up to ${num_guests} friends with you to your private rental! Set estimated group size. You may also set a group password! Guests will need to enter this password in order to RSVP for your rental.`}</RentalSubtext>
      </RentalSectionContainer>
      <RentalDivider />
      <RentalSectionContainer px={4}>
        <RentalSectionLabel isRequired>Group Size</RentalSectionLabel>
        <Row flex={1} space={1} justifyContent="space-between" alignItems="center">
          <Stack flex={1}>
            <Text>Number of guests</Text>
            <Text type="caption">Include yourself!</Text>
          </Stack>
          <Row space={2} alignItems="center" justifyContent="flex-end" nativeID="group-incrementor">
            <HaloChip.Pressable
              {...roundedChipProps}
              onPress={decrementGroupSizeHandler}
              colorScheme={getColorScheme(decrementDisabled)}
              isDisabled={decrementDisabled}
            >
              <HaloChip.Icon icon={faMinus} />
            </HaloChip.Pressable>
            <View height={8} width={10}>
              <RentalTextInput
                justifyContent="center"
                alignItems="center"
                padding={1}
                _input={{ textAlign: 'center' }}
                placeholder="0"
                value={formatGroupSize(groupSize)}
                onChangeText={inputGroupSizeHandler}
                keyboardType="numeric"
              />
            </View>
            <HaloChip.Pressable
              {...roundedChipProps}
              colorScheme={getColorScheme(incrementDisabled)}
              onPress={incrementGroupSizeHandler}
              isDisabled={incrementDisabled}
            >
              <HaloChip.Icon icon={faPlus} />
            </HaloChip.Pressable>
          </Row>
        </Row>
      </RentalSectionContainer>
      <RentalSectionContainer px={4}>
        <Stack space={2}>
          <Row flex={1} justifyContent="space-between" alignItems="center">
            <RentalSectionLabel
              isRequired={groupPasswordEnabled}
              /* width undefined needed as it has a pre set '100%' which does not work for the styling needed */
              _formControl={{ width: undefined }}
            >
              Group Password
            </RentalSectionLabel>
            <Switch
              value={groupPasswordEnabled}
              onToggle={passwordTogglerHandler}
              colorScheme="rentalBlue"
            />
          </Row>
          {groupPasswordEnabled && (
            <FormControl isRequired isInvalid={hasErrors(passwordError)}>
              <RentalTextInput
                isSecureText
                textContentType="oneTimeCode"
                placeholder="Password"
                value={groupPassword}
                onChangeText={passwordChangeHandler}
                onBlur={onPasswordBlurHandler}
              />
              {canUseFormControlMessage ? (
                Object.values(passwordError).map(({ hasError, message }) =>
                  hasError ? (
                    <FormControl.ErrorMessage key={message}>{message}</FormControl.ErrorMessage>
                  ) : null
                )
              ) : (
                // helper text always for Android
                <Text type="caption" fontSize={10}>
                  {androidPasswordHelperText}
                </Text>
              )}
            </FormControl>
          )}
          {/* spacer for mobile flow to give a little space with Android toggle */}
          {isMobile && <View height={4} />}
        </Stack>
      </RentalSectionContainer>
    </View>
  );
};

export default GroupSection;
