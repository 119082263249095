import { useCallback } from 'react';
import {
  FlatList,
  type ListRenderItem,
  type StyleProp,
  type TextStyle,
  type ViewStyle,
} from 'react-native';
// import { FlatList } from 'react-native-gesture-handler';

import { Colors, useTheme } from '../../theme';
import { Text, TouchableOpacity } from '../../base-components';
import { DonationOptions, DonationFrequencyEnum } from '../../constants/enums';
import styles from './styles';
import { isWeb } from '../../utilities';

export type DonationOptionType = {
  amt: string;
  tag: string;
  value: number;
  id: string;
  frequency?: DonationFrequencyEnum;
};

export interface DonationTiersProps {
  donationOptions?: Array<DonationOptionType>;
  onPressOption: (arg: DonationOptionType) => void;
  selectedOptionId?: string;
  circular?: boolean;
  showTag?: boolean;
  horizontal?: boolean;
  monthly?: boolean;
  itemStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

// default that can be overwritten with Stripe data or custom values
const donationDefaultOptions = Object.entries(DonationOptions).map(
  ([option, { amt, tag, value }]) => ({
    amt: `$${amt}`,
    tag,
    value,
    id: option,
    frequency: DonationFrequencyEnum.ONCE,
  })
);

const DonationTiers = ({
  donationOptions = donationDefaultOptions,
  onPressOption,
  selectedOptionId,
  circular = false,
  showTag = false,
  horizontal = false,
  monthly = false,
  itemStyle = {},
  textStyle = {},
}: DonationTiersProps) => {
  const { colors, dark } = useTheme();
  const DonationCircle = useCallback<ListRenderItem<DonationOptionType>>(
    ({ item }) => {
      const isSelected = selectedOptionId === item.id;
      const colorStyle = {
        color: isSelected ? Colors.white : colors.text,
      };
      return (
        <TouchableOpacity
          style={[
            { backgroundColor: dark ? colors.card : Colors.white, borderColor: colors.border },
            horizontal ? styles.horizontal : styles.vertical,
            isSelected ? styles.blueBackground : null,
            circular ? styles.circular : null,
            itemStyle,
          ]}
          onPress={() => onPressOption(item)}
        >
          <Text
            style={[styles.amount, colorStyle, textStyle]}
            {...(horizontal ? { textAlign: 'center' } : {})}
          >
            {item.amt} {monthly ? '/ month' : null}{' '}
          </Text>
          {showTag ? (
            <Text
              style={[styles.tag, colorStyle, circular ? { marginTop: 3 } : null, textStyle]}
              {...(horizontal ? { textAlign: 'center' } : {})}
            >
              {item.tag}
            </Text>
          ) : null}
        </TouchableOpacity>
      );
    },
    [
      selectedOptionId,
      colors.text,
      colors.card,
      colors.border,
      dark,
      horizontal,
      circular,
      itemStyle,
      textStyle,
      monthly,
      showTag,
      onPressOption,
    ]
  );

  return (
    <FlatList
      {...(horizontal ? { horizontal: true, showsHorizontalScrollIndicator: isWeb } : {})}
      data={donationOptions}
      renderItem={DonationCircle}
      keyExtractor={item => item.id}
    />
  );
};

export default DonationTiers;
