import { useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useActionStates } from '../../../hooks';
import {
  GET_USER_PRIVACY_PREFERENCES,
  UPDATE_PRIVACY_PREFERENCES,
} from '../graphql/ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import { Alert, Select, Skeleton, Switch, Text, View } from '../../../base-components';
import { DisplayNameEnum, ErrorMessageEnum } from '../../../constants/enums';
import styles from '../styles';

// ** Note to future devs: we're using state to prevent deprecated fields from overwriting new preferences
// eg: if emailHidden is set to true, emailPreference can never be false
// const emailPreference =currentUser.privacyPreferences.shareEmail || currentUser.emailHidden || false

const InformationSharing = () => {
  const [shareEmail, setShareEmail] = useState(false);
  const [sharePhone, setSharePhone] = useState(false);
  const [displayNameTeamRoster, setDisplayNameTeamRoster] = useState(DisplayNameEnum.LAST_INITIAL);
  const [displayNamePickups, setDisplayNamePickups] = useState(DisplayNameEnum.LAST_INITIAL);

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({ withAlerts: true });

  const [updatePrivacyPreferences, { loading: privacyUpdating }] = useMutation(
    UPDATE_PRIVACY_PREFERENCES
  );

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER_PRIVACY_PREFERENCES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ currentUser }) => {
      if (!currentUser.privacyPreferences) {
        setShareEmail(!currentUser.emailHidden || false);
        setSharePhone(!currentUser.phoneHidden || false);
        setDisplayNameTeamRoster(DisplayNameEnum.LAST_INITIAL);
        setDisplayNamePickups(DisplayNameEnum.LAST_INITIAL);
      } else {
        setShareEmail(currentUser.privacyPreferences.shareEmail || false);
        setSharePhone(currentUser.privacyPreferences.sharePhone || false);
        setDisplayNameTeamRoster(
          currentUser.privacyPreferences.displayNameTeamRoster || DisplayNameEnum.LAST_INITIAL
        );
        setDisplayNamePickups(
          currentUser.privacyPreferences.displayNamePickups || DisplayNameEnum.LAST_INITIAL
        );
      }
    },
  });

  const { currentUser } = userData || {};

  const playerFullName = `${currentUser?.firstName} ${currentUser?.lastName}`;
  const playerLastInitial = `${currentUser?.firstName} ${currentUser?.lastName[0]}.`;

  const displayNameTeamOptions = useMemo(
    () => [
      <Select.Item
        value={DisplayNameEnum.FULL_NAME}
        label={playerFullName}
        key={DisplayNameEnum.FULL_NAME}
      />,
      <Select.Item
        value={DisplayNameEnum.LAST_INITIAL}
        label={playerLastInitial}
        key={DisplayNameEnum.LAST_INITIAL}
      />,
    ],
    [playerFullName, playerLastInitial]
  );

  const displayNamePickupOptions = useMemo(
    () => [
      <Select.Item
        value={DisplayNameEnum.FULL_NAME}
        label={playerFullName}
        key={DisplayNameEnum.FULL_NAME}
      />,
      <Select.Item
        value={DisplayNameEnum.LAST_INITIAL}
        label={playerLastInitial}
        key={DisplayNameEnum.LAST_INITIAL}
      />,
      <Select.Item
        value={DisplayNameEnum.ANONYMOUS}
        label="Anonymous"
        key={DisplayNameEnum.ANONYMOUS}
      />,
    ],
    [playerFullName, playerLastInitial]
  );

  const updateUserPrivacyPreferences = async (update: any) => {
    try {
      await updatePrivacyPreferences({
        variables: {
          input: {
            _id: currentUser._id,
            privacyPreferences: {
              // update all fields for data consistency
              shareEmail,
              sharePhone,
              displayNameTeamRoster,
              displayNamePickups,
              ...update,
            },
          },
        },
      });
      setSuccess('Privacy preferences updated!');
    } catch (error) {
      setError(ErrorMessageEnum.UPDATE_FAILED);
      console.error(error);
    }
  };

  const handleShareEmail = () => {
    setShareEmail(!shareEmail);
    updateUserPrivacyPreferences({ shareEmail: !shareEmail });
  };
  const handleSharePhone = () => {
    setSharePhone(!sharePhone);
    updateUserPrivacyPreferences({ sharePhone: !sharePhone });
  };

  const handleTeamDisplayName = (val: any) => {
    setDisplayNameTeamRoster(val);
    updateUserPrivacyPreferences({ displayNameTeamRoster: val });
  };

  const handlePickupDisplayName = (val: any) => {
    setDisplayNamePickups(val);
    updateUserPrivacyPreferences({ displayNamePickups: val });
  };

  if (userError) {
    setError(ErrorMessageEnum.USER_INFO_LOAD_FAILED);
    console.error(userError);
  }

  if (userLoading)
    return (
      <View style={styles.skeletonContainer}>
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
      </View>
    );

  return (
    <View>
      {showAlert ? (
        <Alert status={alertType} message={alertMessage} showAlert setShowAlert={setShowAlert} />
      ) : null}
      <View style={styles.containerRow}>
        <Text style={styles.label}>Share email with teammates</Text>
        <Switch isDisabled={privacyUpdating} value={shareEmail} onToggle={handleShareEmail} />
      </View>

      <View style={styles.containerRow}>
        <Text style={styles.label}>Share phone number with teammates</Text>
        <Switch isDisabled={privacyUpdating} value={sharePhone} onToggle={handleSharePhone} />
      </View>

      <View style={styles.containerColumn}>
        <Text style={styles.label}>Display Name for Team Rosters</Text>
        <Select
          flex={1}
          selectedValue={displayNameTeamRoster}
          onValueChange={handleTeamDisplayName}
          isDisabled={privacyUpdating}
        >
          {displayNameTeamOptions}
        </Select>
      </View>

      <View style={styles.containerColumn}>
        <Text style={styles.label}>
          Display Name for Pickup Programs (Ex: &quot;Who&apos;s Playing&quot; in pickups)
        </Text>
        <Select
          flex={1}
          selectedValue={displayNamePickups}
          onValueChange={handlePickupDisplayName}
          isDisabled={privacyUpdating}
        >
          {displayNamePickupOptions}
        </Select>
      </View>
    </View>
  );
};

export default InformationSharing;
