/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import StandingsTableBody from './StandingsTableBody';
import StandingsTableHeader from './StandingsTableHeader';
import { type TeamStandings } from '../graphql/useLeagueStandingsQuery';
import { Table, type TableProps } from '../../../custom-components/Table';

const cellStyle: TableProps['defaultCellProps'] = {
  backgroundColor: 'gray.100',
  borderColor: 'gray.50',
};

const textStyle: TableProps['defaultCellTextProps'] = {
  px: 0,
  textAlign: 'center',
};

export enum StandingsTableView {
  Full = 'full',
  Score = 'score',
  Record = 'record',
}

export type StandingsTableBaseProps = TableProps & {
  /**
   * Preset view for the table with columns hidden as needed.
   */
  view: StandingsTableView;
  /**
   * Directly provide the standings data through props
   */
  data?: TeamStandings[];
  /**
   * Display the default error state
   */
  isError?: boolean;
  /**
   * When true, do not show the color name in the team name cell.
   *
   * @default false
   */
  hideColorNames?: boolean;
  /**
   * Provide an array of teamIds to be highlighted blue on the table.
   * This is used on mobile to highlight the user's team.
   *
   * By default, no teams are highlighted.
   * @default []
   */
  highlightedTeamIds?: string[];
  /**
   * Hide the header row entirely. This is currently used on mobile, where
   * the header is rendered in isolation as a section list sticky header.
   *
   * @default false
   */
  hideTableHeader?: boolean;
  /**
   * Hide the table body entirely. This is currently used on mobile, where
   * the header is rendered in isolation as a section list sticky header.
   *
   * @default false
   */
  hideTableBody?: boolean;
};

/**
 * Base standings table component
 *
 * This can be used directly if custom query logic is needed (Admin standings).
 * Otherwise, player standings should use the `StandingsTable` component instead.
 */
const StandingsTableBase: React.FC<StandingsTableBaseProps> = ({
  data,
  view,
  isError,
  hideTableBody,
  hideTableHeader,
  hideColorNames,
  highlightedTeamIds = [],
  ...props
}) => {
  return (
    <Table
      skeletonRowSpacing={0.5}
      defaultCellTextProps={textStyle}
      defaultHeaderTextProps={textStyle}
      defaultHeaderCellProps={cellStyle}
      {...props}
    >
      {!hideTableHeader && <StandingsTableHeader view={view} />}
      {!hideTableBody && (
        <StandingsTableBody
          data={data}
          view={view}
          isError={isError}
          hideColorNames={hideColorNames}
          highlightedTeamIds={highlightedTeamIds}
        />
      )}
    </Table>
  );
};

export default StandingsTableBase;
