import React, { type ReactNode } from 'react';
import { type ViewStyle } from 'react-native';
import { Modal, type IModalProps } from '@rivallapp/native-base';

import styles from './styles';

type OverlayType = {
  show: boolean;
  children: ReactNode;
  close: () => void;
  showX?: boolean;
  size?: IModalProps['size'];
  modalStyle?: ViewStyle;
  contentStyle?: ViewStyle;
};

/** @deprecated Use the native-base modal directly instead */
const Overlay: React.FC<OverlayType & IModalProps> = ({
  show,
  children,
  close,
  showX = true,
  size = 'xl',
  modalStyle = {},
  contentStyle = {},
  ...rest
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Modal isOpen={show} style={[styles.modal, modalStyle]} onClose={close} size={size} {...rest}>
      <Modal.Content style={contentStyle}>
        {showX ? <Modal.CloseButton /> : null}
        <Modal.Body>{children}</Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default Overlay;
