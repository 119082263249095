import React, { useCallback } from 'react';
import classNames from 'classnames/bind';

import PlayerHeader from '../apps/Anon/HomepageV2/Header/Header';
import { Footer } from '../components';
import styles from '../styles/layout.module.scss';
import RFOHeader from './RFOHeader';

const cx = classNames.bind(styles);

type AppContainerProps = {
  isRfo: boolean;
  appName: string;
  pageName: string;
  children: React.ReactNode;
  noHeader?: boolean;
};

const AppContainer = (props: AppContainerProps) => {
  const { isRfo, appName, pageName, children, noHeader = false } = props;

  const Header = useCallback(() => {
    if (noHeader) return null;
    if (isRfo) return <RFOHeader />;
    return <PlayerHeader />;
  }, [noHeader, isRfo]);

  return (
    <div className={cx('App', appName)}>
      <Header />
      <div
        className={cx('Page', pageName, ['l', 'g', 'register'].includes(pageName) && 'overflow', {
          'no-header': noHeader,
        })}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default AppContainer;
