import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgDiscountedVoloEvents = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path fill="#103b5d" stroke="#103b5d" d="M38 23H21v17l26 26 17-17z" />
    <Path
      fill="#fff"
      d="m42.106 42.536.721.721q1.135-1.007 2.09-1.304.34-.107.466.17l.403.912q.16.328-.18.477-.933.404-1.782 1.251-.678.68-.838 1.263-.169.593.223.986.466.466 1.432.138.234-.085 1.241-.541.18-.075.414-.18.243-.117.297-.149.031-.01.275-.106t.414-.159q.498-.18.69-.202.487-.063.646-.074.351-.032.669.075.243.073.615.275.33.18.636.488.923.923.849 2.015-.065 1.082-.912 2.228l.785.785q.243.243 0 .488l-.446.445q-.255.255-.498.01l-.732-.731q-1.347 1.282-2.588 1.74-.35.137-.478-.16l-.434-.923q-.15-.339.18-.477a6.6 6.6 0 0 0 2.036-1.379q1.665-1.664.764-2.567-.075-.074-.223-.18a.46.46 0 0 0-.265-.095l-.244-.032q-.064 0-.307.032-.255.063-.287.074a1.3 1.3 0 0 0-.34.127q-.21.106-.328.138a.5.5 0 0 0-.085.042l-.127.064q-.096.032-.17.064a9 9 0 0 1-.382.19q-.7.34-.954.425a6 6 0 0 1-.902.308q-.583.16-.965.116-.372-.01-.891-.233-.456-.18-.912-.636-.87-.87-.806-1.973t1.029-2.26l-.743-.742q-.233-.233.021-.488l.446-.445q.244-.244.477-.011"
    />
    <Circle cx={29.485} cy={30.734} r={4} fill="#fff" transform="rotate(-45 29.485 30.734)" />
  </Svg>
);
export default SvgDiscountedVoloEvents;
