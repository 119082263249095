import { type FC, type ComponentProps } from 'react';
import { useQuery } from '@apollo/client';

import { MembershipEnum } from '../../constants';
import { Center, View, Text, Heading, Spinner } from '../../base-components';
import {
  useUserVoloPassMembershipStatus,
  useUserVoloFitnessMembershipStatus,
  useCurrentUser,
} from '../../hooks';
import GET_ORGANIZATION_MEMBERSHIPS from './gql/GET_ORGANIZATION_MEMBERSHIPS';
import { MembershipCard } from '../MembershipCard';

/** Formatted member cards that query organization offerings and current user status.
 * Container styling to happen in implementation (flex, spacing, etc)
 * Links to other sections are also platform-specific
 */

// These may be updated as sections are built out
interface PlayerMembershipProps extends ComponentProps<typeof View> {
  voloPassManageMembership: () => void;
  voloPassBillingHistory: () => void;
  voloPassLearnMore: () => void;
  voloPassUpgradeAnnual: () => void;
  voloFitnessManageMembership: () => void;
  voloFitnessBillingHistory: () => void;
  voloFitnessLearnMore: () => void;
}

const PlayerMembership: FC<PlayerMembershipProps> = ({
  voloPassManageMembership,
  voloPassBillingHistory,
  voloPassLearnMore,
  voloPassUpgradeAnnual,
  voloFitnessManageMembership,
  voloFitnessBillingHistory,
  voloFitnessLearnMore,
  ...props
}) => {
  const { currentUser, loading: currentUserLoading } = useCurrentUser();
  const { homeOrganization = '' } = currentUser || {};

  const { data, loading, error } = useQuery(GET_ORGANIZATION_MEMBERSHIPS, {
    skip: !homeOrganization,
    variables: {
      id: homeOrganization,
    },
  });

  const { organization } = data || {};
  const { voloPassTiers, voloFitnessTiers } = organization || {};
  const { isActive: vpIsActive = false } = voloPassTiers || {};
  // Volo Fitness has not been activated yet
  // card will only be visible when switched on at the organization level
  const { isActive: vfIsActive = false, monthlyDollarPrice } = voloFitnessTiers || {};

  const {
    voloPassMembershipLoading,
    voloPassMembershipError,
    hasVoloPassMemberHistory,
    voloPassMembershipStatus,
    voloPassMembershipStartDate,
    voloPassMembershipType,
  } = useUserVoloPassMembershipStatus();

  const {
    voloFitnessMembershipLoading,
    voloFitnessMembershipError,
    hasVoloFitnessMemberHistory,
    voloFitnessMembershipStatus,
    voloFitnessMembershipStartDate,
    voloFitnessMembershipType,
  } = useUserVoloFitnessMembershipStatus();

  const displayError = error || voloPassMembershipError || voloFitnessMembershipError;

  if (displayError)
    return (
      <View>
        <Text>An error occurred while loading your memberships.</Text>
        <Text type="caption">{displayError?.message}</Text>
      </View>
    );

  if (
    !data &&
    (loading || currentUserLoading || voloPassMembershipLoading || voloFitnessMembershipLoading)
  )
    return (
      <Center flex={1}>
        <Spinner size="lg" />
      </Center>
    );

  return (
    <>
      {vpIsActive && (
        <View {...props}>
          <Heading fontSize={18} marginBottom={2}>
            Volo Pass Membership
          </Heading>
          <MembershipCard
            variant={MembershipEnum.VOLO_PASS}
            learnMore={voloPassLearnMore}
            billingHistory={voloPassBillingHistory}
            manageMembership={voloPassManageMembership}
            upgradeAnnual={voloPassUpgradeAnnual}
            {...(hasVoloPassMemberHistory && voloPassMembershipStatus && voloPassMembershipType
              ? {
                  membershipDetails: {
                    membershipStatus: voloPassMembershipStatus,
                    membershipStartDate: voloPassMembershipStartDate,
                    membershipType: voloPassMembershipType,
                  },
                }
              : {})}
          />
        </View>
      )}
      {vfIsActive && (
        <View {...props}>
          <Heading fontSize={18} marginBottom={2}>
            Volo Fitness Membership
          </Heading>
          <MembershipCard
            variant={MembershipEnum.VOLO_FITNESS}
            membershipPrice={monthlyDollarPrice}
            learnMore={voloFitnessLearnMore}
            billingHistory={voloFitnessBillingHistory}
            manageMembership={voloFitnessManageMembership}
            {...(hasVoloFitnessMemberHistory &&
            voloFitnessMembershipStatus &&
            voloFitnessMembershipType
              ? {
                  membershipDetails: {
                    membershipStatus: voloFitnessMembershipStatus,
                    membershipStartDate: voloFitnessMembershipStartDate,
                    membershipType: voloFitnessMembershipType,
                  },
                }
              : {})}
          />
        </View>
      )}
    </>
  );
};

export default PlayerMembership;
