import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgLights = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path stroke="#103b5d" d="M16.5 18.5h47v32.231h-47z" />
    <Path fill="#103b5d" stroke="#103b5d" d="M20.192 22.192h39.615v24.846H20.192z" />
    <Circle cx={25.231} cy={27.231} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={25.231} cy={34.615} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={25.231} cy={42} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={32.615} cy={27.231} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={32.615} cy={34.615} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={32.615} cy={42} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={40} cy={27.231} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={40} cy={34.615} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={40} cy={42} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={47.385} cy={27.231} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={47.385} cy={34.615} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={47.385} cy={42} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={54.769} cy={27.231} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={54.769} cy={34.615} r={2.269} fill="#fff" stroke="#103b5d" />
    <Circle cx={54.769} cy={42} r={2.269} fill="#fff" stroke="#103b5d" />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M38.654 50.808h2.692V57.5h-2.692zM37.5 57.5h5v15h-5z"
    />
  </Svg>
);
export default SvgLights;
