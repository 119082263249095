import { useState, useCallback, useMemo } from 'react';
import {
  FlatList,
  type FlatListProps,
  type ListRenderItem,
  RefreshControl,
  Dimensions,
  Share,
} from 'react-native';
import { useQuery } from '@apollo/client';
import { useClipboard } from '@rivallapp/native-base';

import { Alert, View, Text, Modal, Skeleton, Heading, Button } from '../../base-components';
import { isWeb } from '../../utilities';
import { useActionStates, useCurrentUser } from '../../hooks';
import RosterPill, { type RosterPillProps } from '../PlayerPill/RosterPill/RosterPill';
import EmptyPill, { type EmptyPillProps } from '../PlayerPill/EmptyPill/EmptyPill';
import { PlayerContactInfo } from '../PlayerContactInfo';
import { ROSTER_ITEM_TYPE, type PopupType } from '../GameRoster/GameRoster';
import {
  GenderLabels,
  RentalRegistrantRoles,
  RentalRegistrantTypes,
  ErrorMessageEnum,
} from '../../constants';
import RESERVATION_RSVP_STATUS from './gql/RESERVATION_RSVP_STATUS';
import GET_RENTAL_RESERVATION from './gql/GET_RENTAL_RESERVATION';

const { width } = Dimensions.get('window');

const styles = {
  skeleton: {
    marginTop: 18,
    width: isWeb ? 345 : width - 30,
    height: 50,
    marginBottom: 10,
    borderRadius: 30,
  },
};

const SkeletonPill = () => <Skeleton style={styles.skeleton} />;

type PillType = (RosterPillProps | EmptyPillProps) & { kind: string; id: string };

const RentalRoster = ({
  reservationId,
  onContactBubblePress,
  flatListProps,
}: {
  reservationId: string;
  /**
   * Overrides the default chat bubble press action which opens a contact info popup
   * Used in player-app which has a custom contact info bottom sheet modal
   */
  onContactBubblePress?: (playerId: string, isDropin?: boolean) => void;
  flatListProps?: Omit<FlatListProps<PillType>, 'data' | 'renderItem'>;
}) => {
  // This is a string instead of true/false because this is how the RosterPill renders the PlayerContactInfo
  const [popup, setPopup] = useState<PopupType>('');
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('');
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { currentUser } = useCurrentUser();
  const { _id: userId } = currentUser || {};

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({
      withAlerts: true,
    });

  const { onCopy } = useClipboard();
  const handleShare = useCallback(
    async (link: string) => {
      try {
        await Share.share({
          message: link,
        }).then(({ action }) => {
          if (action !== Share.dismissedAction) setSuccess('Link copied to clipboard!');
        });
      } catch (e) {
        setError(ErrorMessageEnum.RENTAL_RESERVATION_SHARE_ERROR);
      }
    },
    [setSuccess, setError]
  );

  const handleCopy = async (link: string) => {
    try {
      await onCopy(link);
      setSuccess('Link copied to clipboard!');
    } catch (e) {
      setError(ErrorMessageEnum.RENTAL_RESERVATION_SHARE_ERROR);
    }
  };

  const copyLink = (link: string) => (isWeb ? handleCopy(link) : handleShare(link));

  const {
    data: rentalRsvpData,
    loading: rentalRsvpLoading,
    error: rentalRsvpError,
    refetch: rentalRsvpDataRefetch,
  } = useQuery(RESERVATION_RSVP_STATUS, {
    skip: !reservationId,
    variables: {
      input: { reservationId },
    },
  });
  const { reservationRsvpStatus } = rentalRsvpData || {};
  const { reservationRsvps = [] } = reservationRsvpStatus || {};

  const {
    data: rentalReservationData,
    loading: rentalReservationLoading,
    error: rentalReservationError,
    refetch: rentalReservationRefetch,
  } = useQuery(GET_RENTAL_RESERVATION, {
    skip: !reservationId,
    variables: {
      id: reservationId,
    },
  });

  const { rentalReservation } = rentalReservationData || {};
  const {
    reserving_user,
    num_guests_estimate = 1,
    deep_link = '#',
    end_time = '',
  } = rentalReservation || {};

  // V2 can have a stricter definition here
  // this is just so no one can be emailed if the entire rental time is over
  const isPast = new Date() > new Date(end_time);

  const isBookingCaptain = userId === reserving_user?._id;

  const rsvpRoles = reservationRsvps.map(rsvp => {
    const teamRole =
      rsvp.user._id === reserving_user?._id
        ? RentalRegistrantRoles[RentalRegistrantTypes.BOOKING_CAPTAIN].label
        : RentalRegistrantRoles[RentalRegistrantTypes.GUEST].label;
    return {
      id: rsvp._id,
      userId: rsvp.user._id,
      displayName: rsvp.displayNameTeams,
      gender: GenderLabels[rsvp.user.gender as keyof typeof GenderLabels],
      picture: rsvp.user.picture || '',
      teamRole,
      kind: ROSTER_ITEM_TYPE.TEAM,
    };
  });

  const captainRsvp = rsvpRoles?.filter(rsvp => rsvp.userId === reserving_user?._id) || [];
  const groupMemberRsvps = rsvpRoles?.filter(rsvp => rsvp.userId !== reserving_user?._id) || [];
  const rentalReservationMembers = [...captainRsvp, ...groupMemberRsvps];

  const numberOfFriends = useMemo(() => num_guests_estimate - 1, [num_guests_estimate]);

  let emptySpots: { id: string; kind: 'EMPTY'; pillLabel: 'Available Spot' }[] = [];
  if (num_guests_estimate - rentalReservationMembers.length > 0) {
    emptySpots = Array.from({
      length: num_guests_estimate - rentalReservationMembers.length,
    }).map((_spot, index) => ({
      id: `empty-${reservationId}-${index}`,
      kind: ROSTER_ITEM_TYPE.EMPTY,
      pillLabel: 'Available Spot',
    }));
  }

  const combinedRoster = [...rentalReservationMembers, ...emptySpots].map((item, index) => {
    const rosterNumber = index + 1;
    return {
      rosterNumber,
      ...item,
    };
  });

  const loading = rentalRsvpLoading || rentalReservationLoading;
  const error = rentalRsvpError || rentalReservationError;

  const handleRefresh = useCallback(async () => {
    try {
      setRefreshing(true);
      await Promise.allSettled([rentalRsvpDataRefetch(), rentalReservationRefetch()]);
    } catch (e) {
      setError('There was an error refreshing the rsvp data');
    } finally {
      setRefreshing(false);
    }
  }, [rentalRsvpDataRefetch, rentalReservationRefetch, setError]);

  const renderRoster: ListRenderItem<PillType> = ({ item }) => {
    switch (item.kind) {
      case ROSTER_ITEM_TYPE.TEAM:
        return (
          <RosterPill
            rosterNumber={item.rosterNumber}
            userId={(item as RosterPillProps).userId}
            gender={(item as RosterPillProps).gender}
            picture={(item as RosterPillProps).picture}
            displayName={(item as RosterPillProps).displayName}
            teamRole={(item as RosterPillProps).teamRole}
            showChatbubble
            setSelectedPlayerId={setSelectedPlayerId}
            setPopup={setPopup}
            onContactBubblePress={onContactBubblePress}
          />
        );
      case ROSTER_ITEM_TYPE.EMPTY:
        return (
          <EmptyPill
            pillLabel={(item as EmptyPillProps).pillLabel}
            rosterNumber={item.rosterNumber}
          />
        );
      default:
        return null;
    }
  };

  if (error)
    return (
      <View>
        <Text>An error occurred while retrieving the rental roster</Text>
      </View>
    );

  return (
    <>
      {showAlert ? (
        <Alert status={alertType} message={alertMessage} showAlert setShowAlert={setShowAlert} />
      ) : null}
      <FlatList
        data={combinedRoster}
        keyExtractor={(item: PillType) => item.id}
        renderItem={loading && !refreshing ? SkeletonPill : renderRoster}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <View alignItems="center">
            {isWeb ? (
              <Heading fontSize={18}>View Group{isBookingCaptain && ' & Invite Friends'}</Heading>
            ) : null}
            {isBookingCaptain ? (
              <Text marginY={2}>
                Bring up to {numberOfFriends} friends with you to your private rental! Click the
                button below to invite them!
              </Text>
            ) : (
              <Text marginY={2}>
                Up to {numberOfFriends} friends may join you for this private rental! View who has
                registered here!
              </Text>
            )}
          </View>
        }
        ListFooterComponent={
          isBookingCaptain && !isPast ? (
            <View marginTop={5} alignItems="center">
              <Button
                padding={0} // remove NB default for styling
                paddingLeft={10}
                paddingRight={10}
                width={250}
                height={8}
                isDisabled={loading}
                onPress={() => copyLink(deep_link)}
                borderRadius={5}
              >
                Invite friends
              </Button>
            </View>
          ) : null
        }
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
        {...flatListProps}
      />
      <Modal useRNModal isOpen={popup === 'selectedPlayer'} onClose={() => setPopup('')}>
        <Modal.Content>
          <Modal.CloseButton />
          <PlayerContactInfo userId={selectedPlayerId} reservationId={reservationId} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default RentalRoster;
