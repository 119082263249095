import HaloChip, {
  type HaloChipPressableProps,
} from '../../../../../custom-components/HaloChip/HaloChip';

const RentalEditChip = (props: Omit<HaloChipPressableProps, 'colorScheme'>) => (
  <HaloChip.Pressable colorScheme="rentalBlue" {...props}>
    Edit
  </HaloChip.Pressable>
);

export default RentalEditChip;
