// **** File copy/pasted from mobile ***
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CLOSE_SLOT_BY_ID } from '../graphql/MANAGE_DROPIN_SLOTS';
import { ErrorMessageEnum } from '../../../constants/enums';
import { useActionStates } from '../../../hooks';

const useCloseDropInSlot = () => {
  const [closeSlotById, { loading, error }] = useMutation(CLOSE_SLOT_BY_ID, {
    errorPolicy: 'none',
  });

  const { setSuccess, setError } = useActionStates({ withAlerts: true });

  const onCloseSlot = useCallback(
    async ({
      slotId = '',
      onCompleted,
      onError,
    }: {
      slotId: string;
      onCompleted?: (data: any) => void;
      onError?: (e: any) => void;
    }) => {
      const handleError = async (e: any) => {
        if (onError) {
          onError(e);
        } else {
          setError(ErrorMessageEnum.DROPIN_CLOSING);
        }
      };

      const handleCompleted = async (data: any) => {
        if (onCompleted) {
          onCompleted(data);
        } else {
          setSuccess('Slot closed successfully');
        }
      };

      try {
        await closeSlotById({
          variables: {
            id: slotId,
          },
          onCompleted: handleCompleted,
          onError: handleError,
        });
      } catch (e) {
        handleError(e);
      }
    },
    [closeSlotById, setError, setSuccess]
  );

  return {
    onCloseSlot,
    loading,
    error,
  };
};

export default useCloseDropInSlot;
