/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from '../../../custom-components';
import { NAlert, Text } from '../../../base-components';
import StandingsTableRow from './StandingsTableRow';
import { type StandingsTableBaseProps } from './StandingsTableBase';

type Props = Pick<
  StandingsTableBaseProps,
  'data' | 'view' | 'hideColorNames' | 'highlightedTeamIds' | 'isError'
>;

const StandingsTableBody: React.FC<Props> = ({
  data,
  view,
  isError,
  hideColorNames,
  highlightedTeamIds,
}) => {
  if (isError) {
    return (
      <Table.Row>
        <Table.Cell justifyContent="center">
          <NAlert status="error" flexDir="row" backgroundColor="white">
            <NAlert.Icon size="xs" mr={2} />
            <Text fontSize="sm">League standings unavailable</Text>
          </NAlert>
        </Table.Cell>
      </Table.Row>
    );
  }

  if (data?.length === 0) {
    return (
      <Table.Row>
        <Table.Cell pl={2} textProps={{ textAlign: 'left', fontSize: 'sm' }}>
          No teams to show
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table.RowGroup>
      {data?.map(row => (
        <StandingsTableRow
          data={row}
          view={view}
          key={row.team._id}
          hideColorNames={hideColorNames}
          highlightedTeamIds={highlightedTeamIds}
        />
      ))}
    </Table.RowGroup>
  );
};

export default StandingsTableBody;
