import { useCallback, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { ProgramTypeEnum } from '../../../constants';
import { GET_PROGRAM_ROSTER } from '../gql/GET_PROGRAM_ROSTER';
import { IS_IN_PROGRAM } from '../gql/IS_IN_PROGRAM';
import { GET_LEAGUE_INFO } from '../gql/GET_LEAGUE_INFO';
import { CURRENT_USER_STRIKES } from '../gql/CURRENT_USER_STRIKES';
import { MY_DROPIN_RSVPS } from '../gql/MY_DROPIN_RSVPS';
import { DROPIN_GAME_INFO } from '../gql/DROPIN_GAME_INFO';
import { TEAM_SCHEDULE } from '../gql/TEAM_SCHEDULE';
import { CURRENT_USER_SELF_WITHDRAW } from '../gql/CURRENT_USER_SELF_WITHDRAW';
import { UNRSVP_DROPIN } from '../gql/UNRSVP_DROPIN_MUTATION';

const usePlayerProgramInfo = ({
  gameId = '',
  leagueId = '',
  programTeamId,
  onCompleted = () => {},
  onError = () => {},
}: {
  gameId?: string;
  leagueId?: string;
  programTeamId?: string;
  onCompleted?: () => void;
  onError?: (arg: string) => unknown;
}) => {
  const [showPopup, setShowPopup] = useState('');

  const { data: userData, loading: userLoading, error: userError } = useQuery(CURRENT_USER_STRIKES);
  const { currentUser } = userData || {};

  const isMember = currentUser?.isVoloPassMember || false;
  const playerStrikes = currentUser?.player_strikes?.count || 0;

  const [unrsvpDropInMutation, { loading: unrsvpDropInLoading, error: unrsvpDropInError }] =
    useMutation(UNRSVP_DROPIN);
  const [selfWithdraw, { loading: selfWithdrawLoading, error: selfWithdrawError }] = useMutation(
    CURRENT_USER_SELF_WITHDRAW
  );

  const {
    loading: dropInRsvpLoading,
    error: dropInRsvpError,
    refetch: dropInRsvpRefetch,
  } = useQuery(MY_DROPIN_RSVPS, {
    skip: !gameId,
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        numPerPage: 100,
        pageNum: 1,
      },
    },
  });

  const {
    data: gameData,
    loading: gameLoading,
    error: gameError,
  } = useQuery(DROPIN_GAME_INFO, {
    skip: !gameId,
    fetchPolicy: 'cache-and-network',
    variables: { gameId },
  });

  const {
    data: leagueData,
    loading: leagueLoading,
    error: leagueError,
  } = useQuery(GET_LEAGUE_INFO, {
    skip: !leagueId,
    fetchPolicy: 'cache-and-network',
    variables: { id: leagueId },
  });

  const { loading: programRosterLoading, error: programRosterError } = useQuery(
    GET_PROGRAM_ROSTER,
    {
      fetchPolicy: 'cache-and-network',
      skip: !leagueId || !currentUser,
      variables: {
        leagueId,
      },
    }
  );

  const {
    data: teamScheduleData,
    loading: teamScheduleLoading,
    error: teamScheduleError,
  } = useQuery(TEAM_SCHEDULE, {
    skip: !programTeamId,
    fetchPolicy: 'cache-and-network',
    variables: { input: { teamId: programTeamId! } },
  });

  const { loading: enrollmentLoading, error: enrollmentError } = useQuery(IS_IN_PROGRAM, {
    skip: !currentUser?._id || !leagueId,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { leagueId },
    },
  });

  const { game } = gameData || {};
  const { start_time: dropInStartTime, end_time: dropInEndTime } = game || {};

  // returns an array of 1
  const { games } = teamScheduleData?.teamSchedule || {};
  const programGame = games?.length ? Object.assign({}, ...games) : {};

  const gameStartTime = dropInStartTime || programGame.start_time;
  const gameEndTime = dropInEndTime || programGame.end_time;

  const isPast = new Date() > new Date(gameEndTime);
  const hoursBeforeStartTime =
    (new Date(gameStartTime).getTime() - new Date().getTime()) / 3_600_000;

  const { league } = leagueData || {};

  const { programType } = league || {};

  const adjustedProgramType =
    !programType || programType === ProgramTypeEnum.LEAGUE ? ProgramTypeEnum.DROPIN : programType;

  const onUnrsvpDropIn = useCallback(async () => {
    try {
      await unrsvpDropInMutation({
        variables: { gameId },
      });
      await dropInRsvpRefetch();
      onCompleted();
    } catch (err) {
      onError(`${err}`);
    } finally {
      setShowPopup('');
    }
  }, [unrsvpDropInMutation, gameId, dropInRsvpRefetch, onCompleted, onError]);

  const onSelfWithdrawDaily = useCallback(
    async (registrantId: string) => {
      try {
        await selfWithdraw({
          variables: {
            input: {
              registrantId,
            },
          },
        });
        onCompleted();
      } catch (err) {
        onError(`${err}`);
      } finally {
        setShowPopup('');
      }
    },
    [selfWithdraw, onCompleted, onError]
  );

  const loading =
    leagueLoading ||
    userLoading ||
    dropInRsvpLoading ||
    programRosterLoading ||
    enrollmentLoading ||
    gameLoading ||
    unrsvpDropInLoading ||
    selfWithdrawLoading ||
    teamScheduleLoading;

  const error =
    leagueError ||
    userError ||
    dropInRsvpError ||
    programRosterError ||
    enrollmentError ||
    gameError ||
    selfWithdrawError ||
    unrsvpDropInError ||
    teamScheduleError;

  return {
    loading,
    error,
    programType: adjustedProgramType,
    isPast,
    hoursBeforeStartTime,
    onUnrsvpDropIn,
    onSelfWithdrawDaily,
    showPopup,
    setShowPopup,
    isMember,
    playerStrikes,
  };
};

export default usePlayerProgramInfo;
