import { useState, useMemo, useCallback } from 'react';
import {
  ActivityIndicator,
  FlatList,
  type FlatListProps,
  type ListRenderItem,
  RefreshControl,
} from 'react-native';
import { useQuery } from '@apollo/client';
import {
  Alert,
  Overlay,
  Pressable,
  View,
  Skeleton,
  Text,
  Button,
  type IViewProps,
} from '../../base-components';
import {
  DropInPill,
  EmptyPill,
  RosterPill,
  PlayerContactInfo,
  RosterRulesGrid,
  DottedSeparator,
  GameInfoBox,
  RSVPReminderModal,
} from '../index';
import { TEAM_RSVP_QUERY, GET_GAME_DROP_IN_SLOTS, GAME_DATA } from './graphql/GAME_STARTING_DATA';
import GET_TEAM from './graphql/GET_TEAM';
import GET_LEAGUE_REQUIREMENTS from './graphql/GET_LEAGUE_REQUIREMENTS';
import {
  teamRsvp,
  TeamRoleEnum,
  TeamRoles,
  GenderEnum,
  GenderLabels,
  DropInSlotGenderEnum,
  RSVPReminderType,
} from '../../constants/enums';
import { capitalize } from '../../utilities';
import { useActionStates } from '../../hooks';
import styles from './styles';
import { useGetTeamCaptainsAndUserStatus } from '../../hooks/teams';
import CHECK_CAN_SEND_RSVP_REMINDER from './graphql/CHECK_CAN_SEND_RSVP_REMINDER';

interface GameRosterProps {
  gameId: string;
  teamId: string;
  /**
   * If user is an admin viewing this component through admin dashboard
   */
  isAdmin?: boolean;
  leagueId: string;
  isPast?: boolean;
  updateGameRoster?: boolean;
  // needed for web parent component
  setUpdateGameRoster?: (arg: boolean) => unknown | undefined;
  setSelectedDropin?: (arg: string) => unknown | undefined;
  /**
   * Overrides the default chat bubble press action which opens a contact info popup
   * Used in player-app which has a custom contact info bottom sheet modal
   */
  onContactBubblePress?: (playerId: string, isDropin?: boolean) => void;
  flatListProps?: Omit<FlatListProps<any>, 'data' | 'renderItem'>;
  containerProps?: IViewProps;
}

export type PopupType = 'selectedPlayer' | 'showRosterRules' | 'changeDropInGender' | '';

export const ROSTER_ITEM_TYPE = Object.freeze({
  TEAM: 'TEAM',
  DROPIN: 'DROPIN',
  EMPTY: 'EMPTY',
  SEPARATOR: 'SEPARATOR',
});

const GameRoster = ({
  gameId,
  teamId,
  isAdmin = false,
  leagueId,
  isPast = false,
  updateGameRoster = false,
  setUpdateGameRoster,
  setSelectedDropin,
  onContactBubblePress,
  flatListProps,
  containerProps,
}: GameRosterProps) => {
  const [popup, setPopup] = useState<PopupType>('');
  const [selectedPlayerId, setSelectedPlayerId] = useState('');
  const [rsvpGenders, setRsvpGenders] = useState({ a: 0, f: 0 });
  const [openDropInGenders, setOpenDropInGenders] = useState({ a: 0, f: 0 });
  const [refreshing, setRefreshing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({ withAlerts: true });

  const { data: leagueData, loading: leagueLoading } = useQuery(GET_LEAGUE_REQUIREMENTS, {
    skip: !leagueId,
    fetchPolicy: 'cache-and-network',
    variables: { leagueId },
  });

  const { league } = leagueData || {};

  const {
    isDaily,
    gender,
    formatType,
    max_dropins_per_game,
    suggestedWomen,
    suggestedPlayers,
    minWomen,
    minPlayers,
    maxPlayersOnField,
  } = league || {};

  const { total: fullFieldTotal } = maxPlayersOnField || {};
  const hasFormat = formatType !== 'N/A';

  // workaround - default for suggested is still saved to league even if box is unchecked in Create
  const fullFieldWomenTotal = minWomen ? suggestedWomen : 0;
  const recommendedPlayers = minPlayers ? suggestedPlayers : 0;
  // cases where only women players numbers are used
  const recommendedPlayersTotal =
    minWomen && !minPlayers ? fullFieldWomenTotal : recommendedPlayers;

  // women-only league, using women requirements instead of players/totals
  const minimumCheck = minPlayers ?? minWomen;

  const {
    data: gameData,
    loading: gameLoading,
    refetch: gameDataRefetch,
  } = useQuery(GAME_DATA, {
    skip: !gameId,
    fetchPolicy: 'cache-and-network',
    variables: { id: gameId },
  });

  const { game } = gameData || {};
  const is_tournament = game?.is_tournament || false;

  const {
    data: teamData,
    loading: teamLoading,
    refetch: teamRefetch,
  } = useQuery(GET_TEAM, {
    skip: !teamId,
    fetchPolicy: 'cache-and-network',
    variables: { id: teamId },
  });

  const { team } = teamData || {};

  const {
    captainId,
    coCaptainIds,
    hasCaptainPermissions,
    loading: captainsLoading,
    refetch: captainsRefetch,
  } = useGetTeamCaptainsAndUserStatus(teamId);

  const rosterHeader = useMemo(() => {
    if (isAdmin) return team?.name;
    if (hasFormat) return `${capitalize(gender)} ${formatType}`;
    return 'Game Roster';
  }, [formatType, gender, hasFormat, isAdmin, team?.name]);

  const {
    data: teamRsvpData,
    loading: teamRsvpLoading,
    refetch: teamRsvpDataRefetch,
  } = useQuery(TEAM_RSVP_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !gameId || !teamId,
    variables: { input: { teamId, gameId } },
    onCompleted: ({ teamRsvpStatus }) => {
      const { teamRsvps: rsvps = [] } = teamRsvpStatus;
      const yesRsvps = rsvps.filter(rsvp => rsvp?.response === teamRsvp.YES);
      const genders = yesRsvps.reduce(
        (acc, rsvp) => {
          if (rsvp.gender === GenderEnum.FEMALE) {
            acc[DropInSlotGenderEnum.FEMALE] += 1;
          } else {
            // phase out male slots
            acc[DropInSlotGenderEnum.ANY] += 1;
          }
          return acc;
        },
        { [DropInSlotGenderEnum.ANY]: 0, [DropInSlotGenderEnum.FEMALE]: 0 }
      );
      setRsvpGenders(genders);
      if (setUpdateGameRoster) setUpdateGameRoster(false);
    },
  });

  if (updateGameRoster) teamRsvpDataRefetch();

  const { teamRsvps: rsvps = [], criteria } = teamRsvpData?.teamRsvpStatus || {};
  const { womenNeeded = 0, playersNeeded = 0 } = criteria || {};
  const anyNeeded = playersNeeded - womenNeeded > 0 ? playersNeeded - womenNeeded : 0;

  const minStart = minPlayers
    ? minimumCheck - anyNeeded - womenNeeded
    : minimumCheck - anyNeeded - womenNeeded + rsvpGenders.a;

  const teamRsvps = rsvps?.filter(rsvp => !rsvp?.isDropin && rsvp?.response === teamRsvp.YES) || [];
  const dropInRsvps = rsvps?.filter(rsvp => rsvp?.isDropin) || [];

  const isMinimumWomenMet = rsvpGenders?.f >= minWomen;
  const isMinimumMet = teamRsvps.length + dropInRsvps.length >= minPlayers;
  const isFullField = hasFormat ? teamRsvps.length + dropInRsvps.length >= fullFieldTotal : true;
  const isRecommendedMet = teamRsvps.length + dropInRsvps.length >= recommendedPlayersTotal;

  const generateTeamRole = (userId: string) => {
    if (userId === captainId) return TeamRoles[TeamRoleEnum.TEAM_CAPTAIN].label;
    if (coCaptainIds.includes(userId)) return TeamRoles[TeamRoleEnum.CO_CAPTAIN].label;

    return TeamRoles[TeamRoleEnum.TEAM_MEMBER].label;
  };

  const teamRoleRsvps = teamRsvps?.map(rsvp => {
    const teamRole = generateTeamRole(rsvp.userId);
    return {
      ...rsvp,
      gender: GenderLabels[rsvp.gender as keyof typeof GenderLabels],
      teamRole,
    };
  });

  const captainRsvp = teamRoleRsvps?.filter(rsvp => rsvp?.userId === captainId) || [];
  const coCaptainRsvp = teamRoleRsvps?.filter(rsvp => coCaptainIds.includes(rsvp?.userId)) || [];
  const teamMemberRsvps = teamRoleRsvps?.filter(
    rsvp => !coCaptainIds.includes(rsvp?.userId) && rsvp?.userId !== captainId
  );

  const teamMembers = [captainRsvp, coCaptainRsvp, teamMemberRsvps].flat();

  const dropIns = dropInRsvps?.map(rsvp => ({
    ...rsvp,
    gender: GenderLabels[rsvp.gender as keyof typeof GenderLabels],
  }));

  const {
    data: dropInGameData,
    loading: dropInGameLoading,
    refetch: dropInRefetch,
  } = useQuery(GET_GAME_DROP_IN_SLOTS, {
    skip: !gameId,
    variables: { gameId },
    onCompleted: ({ dropInSlotsByGameId }) => {
      const { slots: allSlots = [] } = dropInSlotsByGameId;
      const openSlotsForTeam = allSlots.filter(
        slot => slot?.teamId === teamId && !slot?.rsvpId && !slot?.closedBy
      );
      const genders = openSlotsForTeam.reduce(
        (acc, slot) => {
          if (slot.gender === DropInSlotGenderEnum.FEMALE) {
            acc[DropInSlotGenderEnum.FEMALE] += 1;
          } else {
            acc[DropInSlotGenderEnum.ANY] += 1;
          }
          return acc;
        },
        { [DropInSlotGenderEnum.ANY]: 0, [DropInSlotGenderEnum.FEMALE]: 0 }
      );
      setOpenDropInGenders(genders);
    },
  });

  const {
    data: rsvpData,
    loading: rsvpLoading,
    refetch: rsvpRefetch,
  } = useQuery(CHECK_CAN_SEND_RSVP_REMINDER, {
    variables: {
      input: {
        gameId,
        teamId,
        reminder_type: RSVPReminderType.TEAM,
      },
    },
  });

  const handleRefresh = useCallback(async () => {
    try {
      setRefreshing(true);
      await Promise.allSettled([
        teamRefetch(),
        teamRsvpDataRefetch(),
        dropInRefetch(),
        gameDataRefetch(),
        captainsRefetch(),
        rsvpRefetch(),
      ]);
    } catch (e) {
      setError('There was an error refreshing the game roster data');
    } finally {
      setRefreshing(false);
    }
  }, [
    teamRefetch,
    teamRsvpDataRefetch,
    dropInRefetch,
    gameDataRefetch,
    captainsRefetch,
    rsvpRefetch,
    setError,
  ]);

  interface PillType {
    key: string;
    kind: string;
    // DottedSeparator
    label?: string;
    thresholdReached?: boolean;
    // Pills - Shared
    rosterNumber?: number;
    gameId?: string;
    teamId?: string;
    hasCaptainPermissions?: boolean;
    isAdmin?: boolean;
    // DropIn
    open?: boolean;
    womenOnly?: boolean;
    id?: string;
    expirationDate?: string;
    note?: string;
    // Roster
    userId?: string;
    gender?: string;
    displayName?: string;
    teamRole?: string;
    isDropin?: boolean;
    registrantId?: string;
    showChatbubble?: boolean;
    picture?: string | undefined;
  }

  const GameRosterItem = useCallback(
    (item: PillType) => {
      const updateRoster = async () => {
        await teamRsvpDataRefetch();
        await dropInRefetch();
      };
      switch (item?.kind) {
        case ROSTER_ITEM_TYPE.SEPARATOR:
          return <DottedSeparator label={item.label} thresholdReached={item.thresholdReached} />;
        case ROSTER_ITEM_TYPE.DROPIN:
          return (
            <DropInPill
              open={item.open ?? false}
              womenOnly={item.womenOnly ?? false}
              id={item.id}
              expirationDate={item.expirationDate}
              gameId={gameId}
              teamId={teamId}
              hasCaptainPermissions={hasCaptainPermissions}
              isAdmin={isAdmin}
              rosterNumber={item.rosterNumber}
              note={item.note}
              setSuccess={setSuccess}
              setError={setError}
              setSelectedDropin={setSelectedDropin}
              dropInRefetch={dropInRefetch}
            />
          );
        case ROSTER_ITEM_TYPE.TEAM:
          return (
            <RosterPill
              userId={item.userId}
              gender={item.gender}
              displayName={item.displayName}
              isDropin={item.isDropin ?? false}
              picture={item.picture}
              teamRole={item.teamRole}
              rosterNumber={item.rosterNumber}
              registrantId={item.registrantId}
              hasCaptainPermissions={hasCaptainPermissions}
              isAdmin={isAdmin}
              showChatbubble={!isAdmin}
              gameId={gameId}
              teamId={teamId}
              setSelectedPlayerId={setSelectedPlayerId}
              setPopup={setPopup}
              setSuccess={setSuccess}
              setError={setError}
              updateRoster={updateRoster}
              onContactBubblePress={onContactBubblePress}
            />
          );
        case ROSTER_ITEM_TYPE.EMPTY:
          return (
            <EmptyPill
              pillLabel={item.womenOnly ? 'Women Only' : 'Any Gender'}
              rosterNumber={item.rosterNumber}
            />
          );
        default:
          return null;
      }
    },
    [
      teamRsvpDataRefetch,
      dropInRefetch,
      gameId,
      teamId,
      hasCaptainPermissions,
      isAdmin,
      setSuccess,
      setError,
      setSelectedDropin,
      onContactBubblePress,
    ]
  );

  // no drop-in slots if game is in the past or is a playoffs week
  const { slots = [] } = (!isPast && !is_tournament && dropInGameData?.dropInSlotsByGameId) || {};

  const teamOpenSlots =
    slots?.filter(slot => slot.teamId === teamId && !slot.rsvpId && !slot.closedBy) || [];

  const openWomenSlots =
    teamOpenSlots?.filter(slot => slot.gender === DropInSlotGenderEnum.FEMALE) || [];

  const openAnySlots =
    teamOpenSlots?.filter(slot => slot.gender === DropInSlotGenderEnum.ANY) || [];

  // split off what's needed to meet minimums
  const generateMinimums = () => {
    let minimumWomenRequirements: object[] = [];
    let minimumPlayerRequirements: object[] = [];

    // open drop-ins
    let openMinWomenSlots: object[] = [];
    let openMinAnySlots: object[] = [];
    // available drop-ins
    let minAvailableWomenDropIns: object[] = [];
    let minAvailableAnyDropIns: object[] = [];
    // empty slots
    let minEmptyWomenSlots: object[] = [];
    let minEmptyAnySlots: object[] = [];

    if (womenNeeded) {
      const openMinWomen = openWomenSlots?.slice(0, womenNeeded);
      openWomenSlots?.splice(0, womenNeeded);

      openMinWomenSlots = openMinWomen?.map(slot => ({
        kind: ROSTER_ITEM_TYPE.DROPIN,
        key: slot._id,
        open: true,
        womenOnly: true,
        id: slot._id,
        expirationDate: slot.holdExpiration,
        note: slot.note,
      }));

      if (
        !isPast &&
        !is_tournament &&
        openDropInGenders.f < womenNeeded &&
        teamOpenSlots.length < max_dropins_per_game
      ) {
        minAvailableWomenDropIns = Array.from({ length: womenNeeded - openDropInGenders.f }).map(
          (_spot, index) => ({
            womenOnly: true,
            key: `dropinMinW-${gameId}-${index}`,
            kind: ROSTER_ITEM_TYPE.DROPIN,
          })
        );
      }
      if (openDropInGenders.f + minAvailableWomenDropIns.length < womenNeeded) {
        minEmptyWomenSlots = Array.from({
          length: womenNeeded - openDropInGenders.f - minAvailableWomenDropIns.length,
        }).map((_spot, index) => ({
          key: `minW-${gameId}-${index}`,
          womenOnly: true,
          kind: ROSTER_ITEM_TYPE.EMPTY,
        }));
      }

      minimumWomenRequirements = [
        openMinWomenSlots.flat(),
        minAvailableWomenDropIns.flat(),
        minEmptyWomenSlots.flat(),
      ];
    }

    if (playersNeeded) {
      const openMinAny = openAnySlots?.slice(0, anyNeeded);
      openAnySlots?.splice(0, anyNeeded);

      openMinAnySlots = openMinAny?.map(slot => ({
        key: slot._id,
        open: true,
        id: slot._id,
        expirationDate: slot.holdExpiration,
        note: slot.note,
        kind: ROSTER_ITEM_TYPE.DROPIN,
      }));

      // this accounts for the two different situations we'll run into
      const minDropIns =
        anyNeeded < max_dropins_per_game
          ? anyNeeded - openDropInGenders.a
          : max_dropins_per_game - openDropInGenders.a - dropInRsvps.length;

      if (
        !isPast &&
        !is_tournament &&
        openDropInGenders.a < anyNeeded &&
        teamOpenSlots.length < max_dropins_per_game
      ) {
        minAvailableAnyDropIns = Array.from({
          length: minDropIns,
        }).map((_spot, index) => ({
          key: `dropinMinA-${gameId}-${index}`,
          kind: ROSTER_ITEM_TYPE.DROPIN,
        }));
      }

      if (openDropInGenders.a + minAvailableAnyDropIns.length < anyNeeded) {
        minEmptyAnySlots = Array.from({
          length: anyNeeded - openDropInGenders.a - minAvailableAnyDropIns.length,
        }).map((_spot, index) => ({
          key: `minA-${gameId}-${index}`,
          kind: ROSTER_ITEM_TYPE.EMPTY,
        }));
      }

      minimumPlayerRequirements = [
        openMinAnySlots.flat(),
        minAvailableAnyDropIns.flat(),
        minEmptyAnySlots.flat(),
      ];
    }

    return [minimumWomenRequirements.flat(), minimumPlayerRequirements.flat()];
  };

  const gameMinimums = generateMinimums();

  const fullFieldStart =
    fullFieldTotal > minPlayers ? fullFieldTotal - gameMinimums.flat().length + 2 : minStart + 2;
  const recommendedStart = recommendedPlayersTotal - gameMinimums.flat().length + 3;

  const generateAvailableDropIns = () => {
    // available drop-ins remaining after minimums
    const subtotalGenders = {
      // TODO: may want to do additional error checking here.
      a: rsvpGenders.a + openAnySlots.length + gameMinimums[1]!.length,
      f: rsvpGenders.f + openWomenSlots.length + gameMinimums[0]!.length,
    };

    // if rsvps are 0, gameMinimums will only be dropin and empty slots
    // empty slots will only trigger if dropin slots reach max
    // therefore if there are still available dropins after minimums we can assume that the minimums array is all open & available dropins

    const noRsvps = teamRsvps.length + dropInRsvps.length === 0;

    const remainingDropIns = noRsvps
      ? max_dropins_per_game - gameMinimums.flat().length
      : max_dropins_per_game;

    const remainingWomenDropIns =
      fullFieldWomenTotal < remainingDropIns ? fullFieldWomenTotal : remainingDropIns;

    let teamAvailableWomenDropIns: object[] = [];
    let teamAvailableAnyDropIns: object[] = [];

    if (teamOpenSlots.flat().length + dropInRsvps.length < max_dropins_per_game) {
      if (subtotalGenders.f < fullFieldWomenTotal) {
        teamAvailableWomenDropIns = Array.from({
          length: noRsvps ? remainingDropIns : remainingWomenDropIns - subtotalGenders.f,
        }).map((_spot, index) => ({
          womenOnly: true,
          key: `dropinW-${gameId}-${index}`,
          kind: ROSTER_ITEM_TYPE.DROPIN,
        }));
      }

      if (
        openAnySlots.length +
          dropInRsvps.length +
          openWomenSlots.length +
          gameMinimums.flat().length +
          teamAvailableWomenDropIns.length <
        max_dropins_per_game
      ) {
        teamAvailableAnyDropIns = Array.from({
          length:
            max_dropins_per_game -
            openAnySlots.length -
            openWomenSlots.length -
            dropInRsvps.length -
            gameMinimums.flat().length -
            teamAvailableWomenDropIns.length,
        }).map((_spot, index) => ({
          key: `dropinA-${gameId}-${index}`,
          kind: ROSTER_ITEM_TYPE.DROPIN,
        }));
      }
    }

    return [teamAvailableWomenDropIns, teamAvailableAnyDropIns];
  };

  const teamAvailableDropIns = isPast || is_tournament ? [[], []] : generateAvailableDropIns();

  const fullTeamRoster = [
    teamMembers?.map(member => ({
      key: member.userId,
      userId: member.userId,
      gender: member.gender,
      displayName: member.displayNameTeams,
      isDropin: member.isDropin,
      picture: member.picture,
      teamRole: member.teamRole,
      kind: ROSTER_ITEM_TYPE.TEAM,
    })),
  ];

  const fullLeagueRosterAssembly = () => {
    const subtotalGenders = {
      a:
        rsvpGenders.a +
        openAnySlots.length +
        gameMinimums[1]!.length +
        teamAvailableDropIns[1]!.length,
      f:
        rsvpGenders.f +
        openWomenSlots.length +
        gameMinimums[0]!.length +
        teamAvailableDropIns[0]!.length,
    };

    const fullRoster = [
      // yes RSVPs - team
      fullTeamRoster.flat(),
      // yes RSVPS - drop-ins
      dropIns?.map(member => ({
        key: member.userId,
        userId: member.userId,
        gender: member.gender,
        displayName: member.displayNameTeams,
        isDropin: member.isDropin,
        picture: member.picture,
        registrantId: member.registrantId,
        kind: ROSTER_ITEM_TYPE.TEAM,
      })),

      // minimums will be spliced in

      // open drop-in slots after minimums are met
      openWomenSlots?.map(slot => ({
        key: slot._id,
        open: true,
        womenOnly: true,
        id: slot._id,
        gameId,
        teamId,
        expirationDate: slot.holdExpiration,
        note: slot.note,
        kind: ROSTER_ITEM_TYPE.DROPIN,
      })),
      openAnySlots?.map(slot => ({
        key: slot._id,
        open: true,
        id: slot._id,
        gameId,
        teamId,
        expirationDate: slot.holdExpiration,
        note: slot.note,
        kind: ROSTER_ITEM_TYPE.DROPIN,
      })),

      // remaining drop-in slots
      teamAvailableDropIns[0]!.flat(),
      teamAvailableDropIns[1]!.flat(),
    ].flat();

    // splice in minimums
    if (minPlayers || minWomen) {
      fullRoster.splice(minStart, 0, gameMinimums.flat(), {
        key: `min-line-${gameId}`,
        label: 'Min to play',
        thresholdReached: isMinimumMet && isMinimumWomenMet,
        kind: ROSTER_ITEM_TYPE.SEPARATOR,
      });
    }

    // fill in full field & recommended with empty slots
    let fullFieldWomen: object[] = [];
    let fullFieldPlayers: object[] = [];

    if (subtotalGenders.f < fullFieldWomenTotal - minWomen) {
      fullFieldWomen = Array.from({
        length: fullFieldWomenTotal - subtotalGenders.f - minWomen,
        // eslint-disable-next-line react/no-array-index-key
      }).map((_spot, index) => ({
        key: `FFW-${gameId}-${index}`,
        womenOnly: true,
        kind: ROSTER_ITEM_TYPE.EMPTY,
      }));
    }

    if (
      subtotalGenders.a + subtotalGenders.f + fullFieldWomen.flat().length <
      recommendedPlayersTotal
    ) {
      fullFieldPlayers = Array.from({
        length:
          recommendedPlayersTotal -
          subtotalGenders.a -
          subtotalGenders.f -
          fullFieldWomen.flat().length,
        // eslint-disable-next-line react/no-array-index-key
      }).map((_spot, index) => ({
        key: `FFA-${gameId}-${index}`,
        kind: ROSTER_ITEM_TYPE.EMPTY,
      }));
    }

    const combinedRoster = fullRoster.concat(fullFieldWomen, fullFieldPlayers);

    if (hasFormat && fullFieldTotal > minPlayers) {
      combinedRoster.splice(fullFieldStart, 0, {
        key: `full-line-${gameId}`,
        label: 'Full field',
        thresholdReached: isFullField,
        kind: ROSTER_ITEM_TYPE.SEPARATOR,
      });
    }

    if (recommendedPlayersTotal) {
      combinedRoster.splice(recommendedStart, 0, {
        key: `rec-line-${gameId}`,
        label: 'Recommended',
        thresholdReached: isRecommendedMet,
        kind: ROSTER_ITEM_TYPE.SEPARATOR,
      });
    }

    return combinedRoster.flat().filter(item => item !== null);
  };

  const fullRoster = !isDaily ? fullLeagueRosterAssembly() : fullTeamRoster;

  const renderRoster: ListRenderItem<PillType> = ({ item, index }) => {
    const getRosterNumber = ({ index }: { index: number }) => {
      // if there is a minimum/suggested value but N/A format OR full field total is the same as minimum (ex 2v2)
      if ((minimumCheck && !hasFormat) || fullFieldTotal <= minimumCheck) {
        if (index > minimumCheck && index <= recommendedPlayersTotal) return index;
        if (index > recommendedPlayersTotal) return index - 1;
      }
      // if there is a format & minimums/suggested
      if (hasFormat && minimumCheck) {
        if (index > minimumCheck && index <= fullFieldTotal) return index;
        if (index > fullFieldTotal && index <= recommendedPlayersTotal + 1) return index - 1;
        if (index > recommendedPlayersTotal) return index - 2;
      }
      // if there's a format but no minimums/suggested
      if (hasFormat && !minimumCheck && index > fullFieldTotal) return index;
      // default
      return index + 1;
    };

    return (
      <GameRosterItem
        key={item.key}
        kind={item.kind}
        label={item.label}
        thresholdReached={item.thresholdReached}
        open={item.open ?? false}
        womenOnly={item.womenOnly ?? false}
        id={item.id}
        expirationDate={item.expirationDate}
        rosterNumber={getRosterNumber({ index })}
        gameId={gameId}
        teamId={teamId}
        hasCaptainPermissions={hasCaptainPermissions}
        isAdmin={isAdmin}
        userId={item.userId}
        gender={item.gender}
        displayName={item.displayName}
        isDropin={item.isDropin}
        note={item.note}
        picture={item.picture}
        teamRole={item.teamRole}
        registrantId={item.registrantId}
        showChatbubble={!isAdmin}
      />
    );
  };

  const loading =
    leagueLoading ||
    gameLoading ||
    teamLoading ||
    teamRsvpLoading ||
    dropInGameLoading ||
    captainsLoading ||
    rsvpLoading;

  const SkeletonRoster = useCallback(
    ({ index }: { index: number }) =>
      index === 0 ? (
        <View key={index} style={styles.skeletonContainer}>
          <Skeleton style={styles.skeleton} />
          <Skeleton style={styles.skeleton} />
          <Skeleton style={styles.skeleton} />
          <Skeleton style={styles.skeleton} />
          <Skeleton style={styles.skeleton} />
          <Skeleton style={styles.skeleton} />
          <Skeleton style={styles.skeleton} />
          <Skeleton style={styles.skeleton} />
          <Skeleton style={styles.skeleton} />
        </View>
      ) : null,
    []
  );

  return (
    <>
      <View {...containerProps}>
        {showAlert ? (
          <Alert status={alertType} message={alertMessage} showAlert setShowAlert={setShowAlert} />
        ) : null}
        <FlatList
          // TODO: We shouldn't be doing this, above option could use a refactor
          data={fullRoster as PillType[]}
          renderItem={loading && !refreshing ? SkeletonRoster : renderRoster}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={
            !leagueLoading ? (
              <>
                <Text style={styles.rosterHeader}>{rosterHeader}</Text>
                <Pressable onPress={() => setPopup('showRosterRules')}>
                  <Text isLink style={styles.infoLink}>
                    See roster rules
                  </Text>
                </Pressable>
                {!isAdmin && (
                  <GameInfoBox
                    isMinimumMet={isMinimumMet}
                    isMinimumWomenMet={isMinimumWomenMet}
                    isSuggestedMet={isRecommendedMet}
                  />
                )}
              </>
            ) : (
              <ActivityIndicator />
            )
          }
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
          {...flatListProps}
        />
        {!isPast && (isAdmin || hasCaptainPermissions) && (
          <Button
            margin={0}
            padding={0}
            onPress={() => setShowConfirmation(true)}
            isDisabled={!rsvpData?.checkCanSendRSVPReminder?.reminder_eligible}
          >
            Send RSVP Reminder to Team
          </Button>
        )}
      </View>
      <Overlay show={!!popup} close={() => setPopup('')} contentStyle={styles.overlay} useRNModal>
        {(() => {
          switch (popup) {
            case 'selectedPlayer':
              return (
                <PlayerContactInfo
                  userId={selectedPlayerId}
                  teamId={teamId}
                  isDropIn={dropIns.some(dropIn => dropIn.userId === selectedPlayerId)}
                />
              );
            case 'showRosterRules':
              return (
                <RosterRulesGrid
                  mins={{
                    players: minPlayers,
                    women: minWomen,
                  }}
                  fullField={{
                    total: hasFormat ? fullFieldTotal : 0,
                    women: fullFieldWomenTotal, // value used per business request
                  }}
                  recommended={{
                    players: recommendedPlayers,
                    women: 0, // see note above
                  }}
                  isTournament={is_tournament}
                />
              );
            default:
              return null;
          }
        })()}
      </Overlay>
      <RSVPReminderModal
        gameId={gameId}
        teamId={teamId}
        variant={RSVPReminderType.TEAM}
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      />
    </>
  );
};

export default GameRoster;
