import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { type LayoutChangeEvent, Animated, Easing } from 'react-native';

import { Colors, useTheme } from '../../theme';
import { Text, TouchableOpacity, View } from '../../base-components';
import { adjustColorOnDark, isMobile } from '../../utilities';
import { type IMinimalTabsProps } from './types';

const MinimalTabs = ({
  tabs,
  selectedIndex,
  onTabChange,
  LabelProps,
  IndicatorProps,
  ContainerProps,
}: IMinimalTabsProps) => {
  const { colors, dark } = useTheme();
  const [width, setWidth] = useState<number>(0);

  const indicatorWidth = tabs.length > 0 ? width / tabs.length : 0;

  const translateX = useRef(new Animated.Value(indicatorWidth * selectedIndex)).current;

  const handleLayout = useCallback((e: LayoutChangeEvent) => {
    setWidth(e.nativeEvent.layout.width);
  }, []);

  /* keep animations in sync with the selected index */
  useEffect(() => {
    Animated.timing(translateX, {
      toValue: indicatorWidth * selectedIndex,
      duration: 500,
      useNativeDriver: isMobile,
      easing: Easing.out(Easing.exp),
    }).start();
  }, [selectedIndex, indicatorWidth, translateX]);

  // warn if selectedIndex is -1, meaning no tab is selected (something went wrong)
  if (selectedIndex === -1 && tabs.length > 0) {
    console.warn('[MinimalTabs.tsx]: selectedIndex is -1');
  }

  if (!tabs.length) return null;

  return (
    <View
      flexDirection="row"
      position="relative"
      onLayout={handleLayout}
      borderBottomColor={colors.border}
      borderBottomWidth={1}
      py={1}
      {...ContainerProps}
    >
      {tabs.map((tab, index) => (
        <TouchableOpacity key={tab} onPress={() => onTabChange(index)} style={{ flex: 1 }}>
          <Text
            numberOfLines={1}
            fontWeight={selectedIndex === index ? 'bold' : 'normal'}
            textTransform="uppercase"
            fontSize={14}
            p={1}
            {...(selectedIndex !== index ? { color: adjustColorOnDark(dark, colors.border) } : {})}
            {...LabelProps}
          >
            {tab}
          </Text>
        </TouchableOpacity>
      ))}
      <Animated.View
        style={[
          {
            bottom: -1,
            zIndex: 1,
            position: 'absolute',
            transform: [{ translateX }],
          },
        ]}
      >
        <View
          height="1px"
          borderBottomWidth={2}
          borderBottomColor={Colors.blues.medium}
          borderTopWidth={1}
          borderTopColor={Colors.accents.yellow}
          backgroundColor="primary.600"
          {...IndicatorProps}
          style={{ width: indicatorWidth }}
        />
      </Animated.View>
    </View>
  );
};

/**
 * MinimalTabs is a component that renders a horizontal row of tabs with an indicator
 * that slides to the selected tab.
 * - does not have scrollable support - this component is designed to fit within the parent container
 */
export default memo(MinimalTabs);
