import { Divider as NBDivider, type IDividerProps } from '@rivallapp/native-base';

import { useTheme } from '../../theme';

const Divider = (props: IDividerProps) => {
  const { colors } = useTheme();
  return <NBDivider backgroundColor={colors.border} {...props} />;
};

export default Divider;
export { type IDividerProps };
