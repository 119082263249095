/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames/bind';
import qs from 'qs';
import { useState, type FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RoleEnum } from '@rivallapp/volosports-components';
import { ActionEnum, dropdownLinks, headerLinks } from './links';
import styles from './styles.module.scss';
import { OverflowMenu } from '../../../components';
import { useAuthStore } from '../../../zustand-stores';

const cx = classNames.bind(styles);

type HeaderLinkProps = {
  path: string;
  title: string;
  icon?: string;
  action?: ActionEnum;
};

const HeaderLink: FC<HeaderLinkProps> = props => {
  const { icon, path, title, action } = props;

  const location = useLocation();

  const roles = useAuthStore(store => store.roles);
  const isAdmin = roles.includes(RoleEnum.ADMIN);

  // only pass orgId instead of all params to each page
  const query = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
  const search = query.organization ? `?organization=${query.organization}` : '';

  const linkTo = `/rfo/${path}${search}`;
  const active = location.pathname === linkTo || location.pathname.includes(`${linkTo}/`);

  const dropdownOptions = dropdownLinks(isAdmin)
    .toSorted((a, b) => a.title.localeCompare(b.title))
    .map(({ pathname: to, title: label }) => ({
      label,
      to: `${to}${search}`,
    }));

  if (action === ActionEnum.DASHBOARD_DROPDOWN) {
    return (
      <>
        <Link
          to={linkTo}
          className={cx('header-link', path.split('/').pop(), 'pr-2', {
            active,
            'is-icon': !!icon,
          })}
        >
          {icon ? <img src={icon} alt={title} /> : title}
        </Link>
        <OverflowMenu buttonType="verticalDots" options={dropdownOptions} />
      </>
    );
  }

  return (
    <Link
      to={linkTo}
      className={cx('header-link', path.split('/').pop(), { active, 'is-icon': !!icon })}
    >
      {icon ? <img src={icon} alt={title} /> : title}
    </Link>
  );
};

const HeaderLinks: FC = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <div style={{ display: 'contents' }}>
      <div className={cx('right-menu-wrap')}>
        <div
          className={cx('header-links-container', { 'show-menu': showMobileMenu })}
          onBlur={() => showMobileMenu && setTimeout(() => setShowMobileMenu(false), 250)}
        >
          <div
            role="link"
            tabIndex={0}
            aria-label="mobile-trigger"
            className={cx('mobile-trigger')}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          />
          <div className={cx('header-links')}>
            {headerLinks.map(link => (
              <HeaderLink key={link.path} {...link} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLinks;
