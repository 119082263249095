import { RoleEnum } from '@rivallapp/volosports-components';
import { setContext } from '@apollo/client/link/context/index.js';
/**
 * Sets 'Authorization' header and 'x-hasura-role' based on "highest" role of user.
 */
const createAuthLink = ({ getRoles, getAuthToken, }) => setContext((_, { headers }) => {
    const roles = getRoles();
    const authToken = getAuthToken();
    let currentRole = '';
    if (roles.includes(RoleEnum.PLAYER))
        currentRole = RoleEnum.PLAYER;
    if (roles.includes(RoleEnum.STAFF))
        currentRole = RoleEnum.STAFF;
    if (roles.includes(RoleEnum.OWNER))
        currentRole = RoleEnum.OWNER;
    if (roles.includes(RoleEnum.ADMIN))
        currentRole = RoleEnum.ADMIN;
    const isLoggedIn = authToken && roles.length > 0;
    return {
        headers: {
            ...headers,
            ...(isLoggedIn ? { Authorization: authToken, 'x-hasura-role': currentRole } : {}),
        },
    };
});
export default createAuthLink;
