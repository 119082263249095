import { type FC, useState } from 'react';
import { TextArea as NBTextArea, FormControl, Text, Box } from '../../../base-components';
import { type CollapseFormInputProps } from './types';
import { useCollapseFormContext } from '../CollapseFormContext';

export interface TextAreaProps extends CollapseFormInputProps {
  response?: string;
  placeholder?: string;
  onChange?: (arg: string) => void;
}

const TextArea: FC<TextAreaProps> = ({
  isRequired,
  label,
  response = '',
  placeholder = "What's on your mind? Let us know!",
  onChange = () => {},
}) => {
  const { view } = useCollapseFormContext();
  const [currentValue, setCurrentValue] = useState(response);

  const handleChange = (newVal: string) => {
    setCurrentValue(newVal);
    onChange(newVal);
  };

  switch (view) {
    case 'write':
      return (
        <FormControl isRequired={isRequired}>
          <FormControl.Label>{label}</FormControl.Label>
          <NBTextArea
            placeholder={placeholder}
            value={currentValue}
            autoCompleteType="off"
            onChangeText={newVal => handleChange(newVal)}
          />
        </FormControl>
      );
    case 'read':
      return (
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Text fontWeight="bold">{label} </Text>
          <Text>{response}</Text>
        </Box>
      );
    default:
      return null;
  }
};

export default TextArea;
