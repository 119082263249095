import { StyleSheet } from 'react-native';
import { Colors } from '../../theme';

export default StyleSheet.create({
  // general
  button: {
    margin: 2,
  },
  buttonSelected: {
    backgroundColor: Colors.accent,
  },
  buttonSelectedText: {
    color: Colors.primary,
  },
  checkbox: {
    width: 28,
    height: 28,
  },
  container: {
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  fullWidth: {
    flex: 1,
    marginHorizontal: 15,
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    color: Colors.primary,
  },
  padding: {
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginHorizontal: 15,
  },
  titleContainer: {
    marginLeft: 10,
  },
  title: {
    fontSize: 18,
    marginBottom: 8,
  },
  link: {
    fontSize: 12,
    textDecorationLine: 'underline',
    padding: 4,
    marginHorizontal: 15,
  },

  // component specific
  calendarIcon: {
    width: 48,
  },
  dayButtonCircular: {
    width: 42,
    height: 42,
    borderRadius: 42,
    margin: 2,
  },
  dayButtonSquared: {
    borderRadius: 4,
    height: 28,
    flex: 1,
    marginHorizontal: 2,
  },
  cityContainer: {
    marginTop: 15,
  },
  cityRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginHorizontal: 10,
  },
  footerContainer: {
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginVertical: 10,
    paddingHorizontal: 10,
  },
});
