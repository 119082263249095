import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgNearPublicTransportation = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M24.729 22.377a4.5 4.5 0 0 1 4.457-3.877H52.09a4.5 4.5 0 0 1 4.46 3.91l3.457 26.115c.754 5.692-3.676 10.746-9.418 10.746H30.494c-5.77 0-10.207-5.1-9.408-10.814z"
    />
    <Circle cx={48} cy={51} r={2} fill="#fff" />
    <Circle cx={33} cy={51} r={2} fill="#fff" />
    <Path
      stroke="#103b5d"
      strokeLinecap="round"
      d="M31.593 59.464 23.3 67.143M49.407 59.464l8.293 7.679"
    />
    <Path fill="#fff" d="M57 28H24l-2 15h37z" />
  </Svg>
);
export default SvgNearPublicTransportation;
