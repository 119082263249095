import { useMemo } from 'react';
import { skipToken, useSuspenseQuery } from '@apollo/client';
import type { PlayerStrikesProps } from '../PlayerStrikes';
import { ADMIN_PLAYER_STRIKE_COUNT, PLAYER_STRIKE_COUNT } from '../gql/PLAYER_STRIKE_COUNT';

/**
 * Conditionally calls different queries based on input and returns the output in a unified result.
 */
const usePlayerStrikeCount = (props: PlayerStrikesProps) => {
  const { variant } = props;

  const { data: playerStrikeCountData } = useSuspenseQuery(
    PLAYER_STRIKE_COUNT,
    variant === 'player' ? {} : skipToken
  );

  const { data: adminPlayerStrikeCountData } = useSuspenseQuery(
    ADMIN_PLAYER_STRIKE_COUNT,
    variant === 'admin'
      ? {
          variables: {
            id: props.userId,
          },
        }
      : skipToken
  );

  return useMemo(() => {
    if (variant === 'player') {
      return {
        strikeCount: playerStrikeCountData!.currentUser.player_strikes.count,
        nextStrikeReset: playerStrikeCountData!.nextPlayerStrikeReset,
        nextStrikeWillTriggerFee:
          playerStrikeCountData!.currentUser.player_strikes.nextStrikeWillTriggerFee,
        isVoloPassMember: playerStrikeCountData!.currentUser.isVoloPassMember,
      };
    }

    return {
      strikeCount: adminPlayerStrikeCountData!.user.player_strikes.count,
      nextStrikeReset: adminPlayerStrikeCountData!.nextPlayerStrikeReset,
      nextStrikeWillTriggerFee:
        adminPlayerStrikeCountData!.user.player_strikes.nextStrikeWillTriggerFee,
      isVoloPassMember: adminPlayerStrikeCountData!.user.isVoloPassMember,
    };
  }, [variant, playerStrikeCountData, adminPlayerStrikeCountData]);
};

export default usePlayerStrikeCount;
