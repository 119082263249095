import { type TypedDocumentNode, gql } from '@apollo/client';

type UserDetailsInput = {
  input: {
    userId: string;
    groupId?: string;
    teamId?: string;
    isDropIn?: boolean;
  };
};

type GetUserNameData = {
  getUserDetails: {
    _id: string;
    firstName?: string;
    lastName?: string;
  };
};

export const GET_USER_NAME: TypedDocumentNode<GetUserNameData, UserDetailsInput> = gql`
  query getUserDetails($input: UserDetailsInput!) {
    getUserDetails(input: $input) {
      _id
      firstName
      lastName
    }
  }
`;

type GetUserDetailsData = {
  getUserDetails: {
    _id: string;
    firstName?: string;
    lastName?: string;
    displayNameTeams?: string;
    picture?: string;
    email?: string;
    phone?: string;
  };
};

const GET_USER_DETAILS: TypedDocumentNode<GetUserDetailsData, UserDetailsInput> = gql`
  query getUserDetails($input: UserDetailsInput!) {
    getUserDetails(input: $input) {
      _id
      firstName
      lastName
      displayNameTeams
      picture
      email
      phone
    }
  }
`;

export default GET_USER_DETAILS;
