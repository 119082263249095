import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface MiscProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  miscellaneous_response: boolean;
  miscComments?: string;
}

const Miscellaneous: React.FC<MiscProps> = ({
  miscellaneous_response = false,
  miscComments,
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Miscellaneous"
      view="read"
      toggleQuestion="Anything else to share?"
      toggleResponse={miscellaneous_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Comments/Notes:" response={miscComments} />
      </Box>
      {children}
    </CollapseForm>
  );
};

export default Miscellaneous;
