import { gql, type TypedDocumentNode } from '@apollo/client';
import type { RentalMessageInput } from '.';

interface SendRentalPushInput {
  input: RentalMessageInput;
}

interface SendRentalPushData {
  sendRentalPush: {
    _id: string;
  };
}

const SEND_RENTAL_PUSH: TypedDocumentNode<SendRentalPushData, SendRentalPushInput> = gql`
  mutation SendRentalPush($input: RentalMessageInput!) {
    sendRentalPush(input: $input) {
      _id
    }
  }
`;

export default SEND_RENTAL_PUSH;
