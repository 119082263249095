import { gql, type TypedDocumentNode } from '@apollo/client';
import type { RentalMessageInput } from '.';

interface RentalEmailCountInput {
  input: RentalMessageInput;
}

interface RentalEmailCountData {
  rentalEmailCount: {
    emailCount: number;
  };
}

const RENTAL_EMAIL_COUNT: TypedDocumentNode<RentalEmailCountData, RentalEmailCountInput> = gql`
  query rentalEmailCount($input: RentalMessageInput!) {
    rentalEmailCount(input: $input) {
      emailCount
    }
  }
`;

export default RENTAL_EMAIL_COUNT;
