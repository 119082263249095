/* eslint-disable react/jsx-props-no-spreading */
import { type ComponentProps, useMemo, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';

import { Box, FontAwesomeIcon, IconButton, Switch, Text } from '../../base-components';
import { type CollapseFormView, CollapseFormContext } from './CollapseFormContext';
import DateSelect from './components/DateSelect';
import DayOfWeekSelect from './components/DayOfWeekSelect';
import CompositeList from './components/CompositeList';
import FileUpload from './components/FileUpload';
import MultiSelect from './components/MultiSelect';
import SingleSelect from './components/SingleSelect';
import StarRating from './components/StarRating';
import TextArea from './components/TextArea';

interface CollapseFormProps extends ComponentProps<typeof Box> {
  title: string;
  view?: CollapseFormView;
  toggleQuestion?: string;
  toggleResponse?: Boolean;
  children?: React.ReactNode;
}

const CollapseForm = ({
  title,
  view = 'write',
  toggleQuestion,
  toggleResponse = false,
  children,
  ...props
}: CollapseFormProps) => {
  const [isCollapsed, setIsCollapsed] = useState(Boolean(toggleQuestion));
  const [isToggleActive, setIsToggleActive] = useState(!isCollapsed);

  // Setup for context
  const providerValue = useMemo(() => ({ view }), [view]);

  // Toggle Question exists and the current value of the toggle is false
  const shouldHideCollapse = toggleQuestion && !isToggleActive;
  const shouldHideToggle = toggleQuestion && isToggleActive && isCollapsed;
  switch (view) {
    case 'write':
      return (
        <CollapseFormContext.Provider value={providerValue}>
          <Box borderColor="primary.500" borderWidth={2} borderRadius="sm" p="4" {...props}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text type="h2" color="primary.500">
                {title}
              </Text>
              {!shouldHideCollapse && (
                <IconButton
                  colorScheme="primary"
                  borderRadius="full"
                  onPress={() => setIsCollapsed(!isCollapsed)}
                  icon={<FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />}
                />
              )}
            </Box>
            {toggleQuestion && !shouldHideToggle && (
              <Box display="flex" flexDirection="row">
                <Text type="caption" flexGrow="1">
                  {toggleQuestion}
                </Text>
                <Switch
                  colorScheme="primary"
                  value={isToggleActive}
                  onToggle={() => {
                    const futureValue = !isToggleActive;
                    setIsToggleActive(futureValue);
                    setIsCollapsed(!futureValue);
                  }}
                />
              </Box>
            )}
            <Box {...(isCollapsed ? { display: 'none' } : {})}>{children}</Box>
          </Box>
        </CollapseFormContext.Provider>
      );
    case 'read':
      return (
        <CollapseFormContext.Provider value={providerValue}>
          <Box borderColor="primary.500" borderWidth={2} borderRadius="sm" p="4" {...props}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text type="h2" color="primary.500">
                {title}
              </Text>
            </Box>
            {toggleResponse && (
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                <Text fontWeight="bold">{toggleQuestion} </Text>
                <Text>Yes</Text>
              </Box>
            )}
            {children}
          </Box>
        </CollapseFormContext.Provider>
      );
    default:
      return null;
  }
};

CollapseForm.DateSelect = DateSelect;
CollapseForm.DayOfWeekSelect = DayOfWeekSelect;
CollapseForm.FileUpload = FileUpload;
CollapseForm.MultiSelect = MultiSelect;
CollapseForm.SingleSelect = SingleSelect;
CollapseForm.StarRating = StarRating;
CollapseForm.TextArea = TextArea;
CollapseForm.CompositeList = CompositeList;

export default CollapseForm;
