import { gql, type TypedDocumentNode } from '@apollo/client';

type RentalReservationData = {
  rentalReservation: {
    _id: string;
    end_time: string;
    reserving_user: {
      _id: string;
    };
    num_guests_estimate: number;
    deep_link?: string;
  };
};

type RentalRservationInput = {
  id: string;
};

const GET_RENTAL_RESERVATION: TypedDocumentNode<RentalReservationData, RentalRservationInput> = gql`
  query RentalReservation($id: ID!) {
    rentalReservation(_id: $id) {
      _id
      end_time
      reserving_user {
        _id
      }
      num_guests_estimate
      deep_link
    }
  }
`;

export default GET_RENTAL_RESERVATION;
