import { useQuery } from '@apollo/client';
import { SportSurfaceEnum } from '../../../constants';
import GET_RENTAL_DETAILS_BY_SPORT from './graphql/GET_RENTAL_DETAILS_BY_SPORT';
import GET_RENTAL_DETAILS_BY_VENUE from './graphql/GET_RENTAL_DETAILS_BY_VENUE';

const useDiscoverRentalDetails = ({
  id,
  viewType,
}: {
  id: string;
  viewType: 'sportView' | 'venueView';
}) => {
  const {
    data: rentalSportData,
    loading: rentalSportLoading,
    error: rentalSportError,
  } = useQuery(GET_RENTAL_DETAILS_BY_SPORT, {
    skip: viewType !== 'sportView',
    variables: { id },
  });

  const sportRental = rentalSportData?.rental || undefined;
  const sportRentalImage = sportRental?.images?.length
    ? sportRental?.images[0]
    : sportRental?.venue?.image?.url;

  const {
    data: rentalVenueData,
    loading: rentalVenueLoading,
    error: rentalVenueError,
  } = useQuery(GET_RENTAL_DETAILS_BY_VENUE, {
    skip: viewType !== 'venueView',
    variables: { id },
  });

  const venue = rentalVenueData?.venue || undefined;

  const image = sportRentalImage || venue?.image?.url || '';
  const neighborhoodName = sportRental?.venue?.neighborhood || venue?.neighborhood || '';
  const venueName = sportRental?.venue?.shorthandName || venue?.shorthandName || '';
  const venueCoordinates = sportRental?.venue?.location || venue?.location || undefined;
  const placeId = sportRental?.venue?.place_id || venue?.place_id || '';
  const venueAddress = sportRental?.venue?.formatted_address || venue?.formatted_address || '';
  const lowPrice = sportRental?.standard_price_cents || venue?.lowest_overall_rental_price || 0;
  const highPrice = sportRental?.prime_price_cents || venue?.highest_overall_rental_price || null;
  const rentalActivity = sportRental?.activity_name || '';
  const guestCapacity = sportRental?.capacity || 0;
  const availableCourts = sportRental?.courts?.length || 0;
  // default for ts grumbling - we should never actually hit this fallback; not used in venueView
  const surfaceType = sportRental?.surface_type || SportSurfaceEnum.COURT;
  // banners are only displayed in sportView
  const bannerMessage = sportRental?.banner_message || '';
  const availableSportTypes = venue?.availableActivities || [];

  return {
    data: {
      image,
      neighborhoodName,
      venueName,
      venueCoordinates,
      venueAddress,
      placeId,
      lowPrice,
      highPrice,
      rentalActivity,
      guestCapacity,
      availableCourts,
      surfaceType,
      bannerMessage,
      availableSportTypes,
    },
    loading: rentalSportLoading || rentalVenueLoading,
    error: rentalSportError || rentalVenueError,
  };
};

export default useDiscoverRentalDetails;
