import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  captainContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginHorizontal: 5,
  },
});
