import React from 'react';
import { Divider as NativeBaseDivider } from '../../../base-components';
import { Colors } from '../../../theme';
import { usePaymentFormContext } from '../PaymentForm';

const Divider: React.FC = () => {
  const {
    theme: { dark },
  } = usePaymentFormContext();

  return (
    <NativeBaseDivider
      orientation="horizontal"
      width="95%"
      thickness={1}
      my={2}
      alignSelf="center"
      background={dark ? Colors.greys.veryLight : Colors.greys.medium}
    />
  );
};

export default Divider;
