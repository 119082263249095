import { useCallback, useState } from 'react';
import { FlatList, type FlatListProps, type ListRenderItem, RefreshControl } from 'react-native';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { useGetTeamCaptainsAndUserStatus } from '../../../hooks/teams';
import { PlayerContactInfo } from '../../index';
import { useActionStates } from '../../../hooks';
import { Alert, View, Skeleton, Modal } from '../../../base-components';
import { RosterItem, TeamRoleModal } from '../index';
import { TeamRoles, TeamRoleEnum } from '../../../constants/enums';
import TEAM_RSVP_QUERY from '../graphql/TEAM_RSVP_QUERY';
import GET_GAME_DATES from '../graphql/GET_GAME_DATES';
import styles from './styles';

interface TeamRosterProps {
  teamId: string;
  gameId: string;
  /**
   * Admins can change roles for anybody on the team (except for the captain) if
   * viewing the team roster from the admin dashboard
   */
  isAdmin?: boolean;
  /**
   * Overrides the default chat bubble press action which opens a contact info popup
   * Used in player-app which has a custom contact info bottom sheet modal
   */
  onContactBubblePress?: (playerId: string, isDropin?: boolean) => void;
  flatListProps?: Omit<FlatListProps<any>, 'data' | 'renderItem'>;
}

interface TeamMemberType {
  key: string;
  userId: string;
  displayNameTeams: string;
  teamRole: string;
  response?: string;
}

export type PopupType = 'selectedPlayer' | 'selectTeamRole' | '';

const SkeletonPill = () => <Skeleton style={styles.skeleton} />;

const TeamRoster = ({
  teamId,
  gameId,
  isAdmin = false,
  onContactBubblePress,
  ...flatListProps
}: TeamRosterProps) => {
  const [popup, setPopup] = useState<PopupType>('');
  const [selectedPlayer, setSelectedPlayer] = useState({ playerId: '', playerName: '' });
  const [selectedRole, setSelectedRole] = useState({ label: '', value: '' });
  const [refreshing, setRefreshing] = useState(false);

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({ withAlerts: true });

  const closePopup = () => setPopup('');

  const {
    data: teamRsvpData,
    loading: teamRsvpLoading,
    refetch: teamRsvpDataRefetch,
  } = useQuery(TEAM_RSVP_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { input: { teamId, gameId } },
  });

  const { teamRsvps: rsvps = [] } = teamRsvpData?.teamRsvpStatus || {};

  const {
    data: gameData,
    loading: gameLoading,
    refetch: gameDataRefetch,
  } = useQuery(GET_GAME_DATES, {
    skip: !gameId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: gameId,
    },
  });

  const {
    captainId,
    coCaptainIds,
    hasCaptainPermissions,
    isCoCaptain,
    loading: captainsLoading,
    refetch: captainsRefetch,
  } = useGetTeamCaptainsAndUserStatus(teamId);

  const generateTeamRole = (userId: string) => {
    if (userId === captainId) return TeamRoles[TeamRoleEnum.TEAM_CAPTAIN].label;
    if (coCaptainIds.includes(userId)) return TeamRoles[TeamRoleEnum.CO_CAPTAIN].label;

    return TeamRoles[TeamRoleEnum.TEAM_MEMBER].label;
  };

  // puts Captain & Co-Captain at top of array and remove drop-ins
  const captainRsvp = rsvps.filter(rsvp => rsvp?.userId === captainId) || [];
  const coCaptainRsvp = rsvps.filter(rsvp => coCaptainIds.includes(rsvp.userId)) || [];
  const teamMemberRsvps = rsvps.filter(
    rsvp => !coCaptainIds.includes(rsvp.userId) && rsvp?.userId !== captainId && !rsvp.isDropin
  );

  const teamMembers = [...captainRsvp, ...coCaptainRsvp, ...teamMemberRsvps].map(player => ({
    ...player,
    key: player.userId,
    teamRole: generateTeamRole(player.userId),
  }));

  const isPast = moment(gameData?.game.start_time).isBefore(moment());

  const renderRoster: ListRenderItem<TeamMemberType> = useCallback(
    ({ item }) => {
      return (
        <RosterItem
          key={item.key}
          playerId={item.userId}
          displayName={item.displayNameTeams}
          response={item.response}
          teamRole={item.teamRole}
          hasCaptainPermissions={hasCaptainPermissions || isAdmin}
          isCoCaptain={isCoCaptain}
          teamId={teamId}
          gameId={gameId}
          rsvpRefetch={teamRsvpDataRefetch}
          setSuccess={setSuccess}
          setError={setError}
          setSelectedPlayer={setSelectedPlayer}
          setSelectedRole={setSelectedRole}
          setPopup={setPopup}
          onContactBubblePress={onContactBubblePress}
          hideRSVPSelector={isPast}
        />
      );
    },
    [
      hasCaptainPermissions,
      isAdmin,
      isCoCaptain,
      teamId,
      gameId,
      isPast,
      teamRsvpDataRefetch,
      setSuccess,
      setError,
      onContactBubblePress,
    ]
  );

  const loading = captainsLoading || teamRsvpLoading || gameLoading;

  const handleRefresh = useCallback(async () => {
    try {
      setRefreshing(true);
      await Promise.allSettled([captainsRefetch(), teamRsvpDataRefetch(), gameDataRefetch()]);
    } catch (e) {
      setError('There was an error refreshing the team data');
    } finally {
      setRefreshing(false);
    }
  }, [captainsRefetch, teamRsvpDataRefetch, gameDataRefetch, setError]);

  return (
    <>
      <View flex={1}>
        {showAlert ? (
          <Alert status={alertType} message={alertMessage} showAlert setShowAlert={setShowAlert} />
        ) : null}
        <FlatList
          data={teamMembers}
          renderItem={loading && !refreshing ? SkeletonPill : renderRoster}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
          showsVerticalScrollIndicator={false}
          {...flatListProps}
        />
      </View>
      <Modal useRNModal isOpen={popup === 'selectedPlayer'} onClose={closePopup}>
        <Modal.Content>
          <Modal.CloseButton />
          <PlayerContactInfo userId={selectedPlayer?.playerId} teamId={teamId} isDropIn={false} />
        </Modal.Content>
      </Modal>
      <TeamRoleModal
        player={selectedPlayer}
        teamId={teamId}
        teamRole={selectedRole}
        onSuccessMessage={setSuccess}
        onErrorMessage={setError}
        onClose={closePopup}
        isOpen={popup === 'selectTeamRole'}
      />
    </>
  );
};

export default TeamRoster;
