import { useMutation } from '@apollo/client';
import type { InterfaceModalProps } from '@rivallapp/native-base/lib/typescript/components/composites/Modal/types';
import { useState, type FC } from 'react';
import { Alert, Button, Radio, Modal } from '../../base-components';
import { useActionStates } from '../../hooks';
import { ErrorCodes, ErrorMessageEnum } from '../../constants';
import {
  CLOSE_RECURRING_DROP_IN_SLOTS,
  CLOSE_SLOT_BY_ID,
} from '../GameRoster/graphql/MANAGE_DROPIN_SLOTS';

interface DeleteDropinProps extends InterfaceModalProps {
  dropInSlotId: string;
  dropInRefetch: () => unknown;
}

type DeletionType = 'single' | 'future';

const DeleteDropin: FC<DeleteDropinProps> = ({ dropInSlotId, dropInRefetch, ...rest }) => {
  const [deletionType, setDeletionType] = useState<DeletionType>('single');

  const [closeSingleDropInMutation] = useMutation(CLOSE_SLOT_BY_ID);
  const [closeRecurringDropInMutation] = useMutation(CLOSE_RECURRING_DROP_IN_SLOTS);

  const { setError, showAlert, setShowAlert, alertMessage, alertType } = useActionStates({
    withAlerts: true,
  });

  const onPressDelete = async () => {
    try {
      if (deletionType === 'single') {
        await closeSingleDropInMutation({
          variables: {
            id: dropInSlotId,
          },
        });
      } else {
        await closeRecurringDropInMutation({
          variables: {
            id: dropInSlotId,
          },
          update: cache => {
            cache.evict({ fieldName: 'dropInSlotsByGameId' });
            cache.gc();
          },
        });
      }
      await dropInRefetch();
      if (rest.onClose) {
        rest.onClose();
      }
    } catch (e) {
      setError(ErrorMessageEnum[ErrorCodes.DROPIN_UPDATE]);
    }
  };

  return (
    <Modal useRNModal size="md" {...rest}>
      <Modal.Content>
        <Modal.Header>Delete Recurring Spot</Modal.Header>
        <Modal.Body>
          {showAlert ? (
            <Alert
              status={alertType}
              message={alertMessage}
              showAlert
              setShowAlert={setShowAlert}
              marginBottom={2}
            />
          ) : null}
          <Radio.Group
            name="deletionType"
            value={deletionType}
            onChange={value => setDeletionType(value as DeletionType)}
            accessibilityLabel="Drop in deletion type"
          >
            <Radio value="single" marginBottom="2">
              Only this spot
            </Radio>
            <Radio value="future">This and future open spots</Radio>
          </Radio.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button flex={1} marginX="auto" maxWidth={165} onPress={onPressDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteDropin;
