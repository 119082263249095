import { useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { isEmail, isPostalCode } from 'validator';
import moment from 'moment-timezone';
import { useActionStates } from '../../../hooks';
import {
  ORGANIZATION_LIST_QUERY,
  GET_USER_PROFILE_DETAILS,
  UPDATE_CURRENT_USER,
} from '../graphql/ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import {
  Alert,
  Button,
  FormControl,
  Select,
  Skeleton,
  Text,
  TextInput,
  View,
} from '../../../base-components';
import { GenderNameEnum, SizingNameEnum, ErrorMessageEnum } from '../../../constants/enums';
import styles from '../styles';

const MIN_USERNAME_LENGTH = 3;

const PersonalInfo = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [homeOrganization, setHomeOrganization] = useState('');
  const [zip, setZip] = useState('');
  const [birthday, setBirthday] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  const [gender, setGender] = useState('');

  const [birthdayError, setBirthdayError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [zipError, setZipError] = useState('');

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({ withAlerts: true });

  const [updateUserMutation, { loading: userUpdating }] = useMutation(UPDATE_CURRENT_USER);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER_PROFILE_DETAILS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ currentUser }) => {
      setFirstName(currentUser?.firstName || '');
      setLastName(currentUser?.lastName || '');
      setEmail(currentUser?.email || '');
      setUsername(currentUser?.username || '');
      setZip(currentUser?.zip || '');
      setBirthday(moment(currentUser?.birthday).format('MM/DD/YYYY') || '');
      setGender(currentUser?.gender || '');
      setShirtSize(currentUser?.shirt_size || '');
      setAddress(currentUser?.address || '');
      setHomeOrganization(currentUser?.homeOrganization || '');
    },
  });

  const { data: orgListData } = useQuery(ORGANIZATION_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { currentUser } = userData || {};

  const orgOptions = useMemo(() => {
    const { organizationList } = orgListData || {};

    const organizationOptions = organizationList?.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));

    return organizationOptions?.map(option => (
      <Select.Item key={option.value} value={option.value} label={option.label} />
    ));
  }, [orgListData]);

  const genderOptions = useMemo(() => {
    const genderNameOptions = Object.entries(GenderNameEnum).map(([key, value]) => ({
      label: value,
      value: key,
    }));
    return genderNameOptions.map(option => (
      <Select.Item key={option.value} value={option.value} label={option.label} />
    ));
  }, []);

  const sizingOptions = useMemo(() => {
    const sizingNameOptions = Object.entries(SizingNameEnum).map(([key, value]) => ({
      label: key,
      value,
    }));

    return sizingNameOptions.map(option => (
      <Select.Item key={option.value} value={option.value} label={option.label} />
    ));
  }, []);

  // matches proto3 validation
  const validateUsername = (value: string) => {
    setUsername(value);
    if (value.length < MIN_USERNAME_LENGTH)
      setUsernameError(`username must be at least ${MIN_USERNAME_LENGTH} characters long`);
    else setUsernameError('');
  };

  // matches proto3 formatting & validation
  const validateBirthday = (date: string) => {
    setBirthday(date);
    if (!date || !moment(date, 'MM/DD/YYYY', true).isValid() || moment().diff(date, 'year') > 90)
      setBirthdayError('Please enter a valid birthdate');
    else if (moment().diff(date, 'year') < 18) setBirthdayError('You must be 18 or older');
    else setBirthdayError('');
  };

  const validateZip = (value: string) => {
    setZip(value);
    if (!isPostalCode(value, 'US')) setZipError('Please enter a valid zip code');
    else setZipError('');
  };

  const updateUser = async () => {
    try {
      await updateUserMutation({
        variables: {
          input: {
            _id: currentUser._id,
            firstName,
            lastName,
            email,
            username,
            address,
            homeOrganization,
            zip,
            birthday,
            shirt_size: shirtSize,
            gender,
          },
        },
      });
      setSuccess('Information updated!');
      if (currentUser.email.toLowerCase() !== email.toLowerCase()) {
        setSuccess(`Please check your new email (${email}) for a verification code.`);
      }
    } catch (error) {
      setError(ErrorMessageEnum.UPDATE_FAILED);
      console.error(error);
    }
  };

  const valid = () =>
    !!firstName &&
    lastName &&
    isEmail(email) &&
    !usernameError &&
    address &&
    homeOrganization &&
    !zipError &&
    !birthdayError &&
    shirtSize &&
    gender;

  if (userError) {
    setError(ErrorMessageEnum.USER_INFO_LOAD_FAILED);
    console.error(userError);
  }

  if (userLoading)
    return (
      <View style={styles.skeletonContainer}>
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.saveButton} />
      </View>
    );

  return (
    <>
      <FormControl isRequired isInvalid={!valid()}>
        {showAlert ? (
          <Alert status={alertType} message={alertMessage} showAlert setShowAlert={setShowAlert} />
        ) : null}
        <View style={styles.containerColumn}>
          <Text style={styles.label}>First Name</Text>
          <TextInput isSecureText={false} value={firstName} onChangeText={setFirstName} />
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>Last Name</Text>
          <TextInput isSecureText={false} value={lastName} onChangeText={setLastName} />
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>Email</Text>
          <TextInput isSecureText={false} value={email} onChangeText={setEmail} />
          {!isEmail(email) ? (
            <FormControl.ErrorMessage>Please enter a valid email address</FormControl.ErrorMessage>
          ) : null}
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>Username</Text>
          <TextInput isSecureText={false} value={username} onChangeText={validateUsername} />
          <FormControl.ErrorMessage>{usernameError}</FormControl.ErrorMessage>
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>Street Address</Text>
          <TextInput isSecureText={false} value={address} onChangeText={setAddress} />
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>Zip Code</Text>
          <TextInput
            isSecureText={false}
            keyboardType="number-pad"
            value={zip}
            onChangeText={validateZip}
          />
          <FormControl.ErrorMessage>{zipError}</FormControl.ErrorMessage>
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>Home Organization</Text>
          <Select flex={1} selectedValue={homeOrganization} onValueChange={setHomeOrganization}>
            {orgOptions}
          </Select>
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>Birthday</Text>
          <TextInput
            isSecureText={false}
            value={birthday}
            placeholder="MM/DD/YYYY"
            onChangeText={validateBirthday}
          />
          <FormControl.ErrorMessage>{birthdayError}</FormControl.ErrorMessage>
        </View>

        <View style={styles.containerRow}>
          <View style={styles.selectShortLeft}>
            <Text style={styles.label}>Shirt Size</Text>
            <Select flex={1} selectedValue={shirtSize} onValueChange={setShirtSize}>
              {sizingOptions}
            </Select>
          </View>

          <View style={styles.selectShortRight}>
            <Text style={styles.label}>Gender</Text>
            <Select flex={1} selectedValue={gender} onValueChange={setGender}>
              {genderOptions}
            </Select>
          </View>
        </View>
        <FormControl.ErrorMessage>All fields are required</FormControl.ErrorMessage>
      </FormControl>

      <Button
        style={styles.saveButton}
        onPress={updateUser}
        isDisabled={userUpdating || !valid()}
        isLoading={userUpdating}
      >
        Save
      </Button>
    </>
  );
};

export default PersonalInfo;
