import { Button as NButton } from '@rivallapp/native-base';
import type { IButtonGroupProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Button';

import { type ButtonProps } from './types';
import getButtonUtilityProps from './utils/getButtonUtilityProps';
import { useTheme } from '../../theme';

/**
 * Custom Button component that utilizes the NativeBase Button component
 * - `type` prop is used to easily add preset Utility Props to the Button component - see `getButtonUtilityProps` for more info
 * - `type` prop can be overwritten by any colorScheme prop passed to the Button component as well
 * - default `variant` prop is `solid` which will fallback and utilize `type` prop with preset utility props, if `variant` prop is anything other than `solid` or `link`, it will override the `type` prop and use the `variant` prop as is through native-base
 * @important @platform {mobile} - if looking to use this Button component as a link, try utilize Text component with `isLink` prop instead as most links are within Text components, this will allow for easier styling and more flexibility when it comes to links along side text
 */
const Button = ({ type = 'primary', ...rest }: ButtonProps) => {
  const finalProps = getButtonUtilityProps(type, rest);

  return <NButton {...finalProps} />;
};

/** Workaround for now until we get this type properly */
export const ButtonGroup = NButton.Group;

export default Button;

// easy access to ClearCancel and Apply buttons for use in registration modals
/**
 * Custom styled button for canceling or clearing (overrides default button styles)
 */
const ClearCancel = (props: ButtonProps) => {
  const { colors } = useTheme();

  return (
    <Button
      flex={1}
      {...props}
      variant="outline"
      colorScheme="primary"
      _dark={{ _text: { color: colors.text } }}
    />
  );
};

/**
 * Just default button with flex 1 to take up full width
 * - utilized in registration modals (credits, promo, etc.)
 * - default button styles
 */
const ApplyButton = (props: ButtonProps) => {
  return <Button flex={1} {...props} />;
};

/**
 * ButtonGroup that utilizes dynamic flex direction based on screen size
 * - utilized in registration modals (credits, promo, etc.)
 */
const FooterGroup = ({ children, ...rest }: IButtonGroupProps) => {
  return (
    <NButton.Group flexDir={['column', 'row']} space={2} {...rest}>
      {children}
    </NButton.Group>
  );
};

Button.ClearCancel = ClearCancel;
Button.Apply = ApplyButton;
Button.Group = NButton.Group;
Button.FooterGroup = FooterGroup;
