import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgIndoorSpace = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      d="M70 34c-22-4-38-4-60 0l.188.314A14.7 14.7 0 0 1 12 39l1 8h54l1-8a14.7 14.7 0 0 1 1.812-4.686z"
    />
    <Path
      fill="#103b5d"
      d="m10 34-.09-.492-.71.13.371.62zm60 0 .429.257.372-.62-.712-.129zM13 47l-.496.062.055.438H13zm54 0v.5h.441l.055-.438zM10.188 34.314l-.428.257zM12 39l.496-.062-.002-.018-.004-.018zm57.812-4.686.428.257zM68 39l-.49-.098-.004.018-.002.018zm-57.91-4.508c21.94-3.99 37.88-3.99 59.82 0l.18-.984c-22.06-4.01-38.12-4.01-60.18 0zM13 47.5h54v-1H13zM9.571 34.257l.189.314.857-.514-.188-.314zm1.933 4.805 1 8 .992-.124-1-8zm58.736-4.49.189-.315-.858-.514-.188.314zm-2.744 12.49 1-8-.992-.124-1 8zm1.887-13.005a15.2 15.2 0 0 0-1.873 4.845l.98.196c.32-1.598.912-3.13 1.75-4.527zM9.76 34.57a14.2 14.2 0 0 1 1.75 4.527l.98-.196a15.2 15.2 0 0 0-1.873-4.845z"
    />
    <Path
      fill="#fff"
      stroke="#fff"
      d="M38 40v7h4v-7c0-3-4-3-4 0ZM17.5 41.5 18 47h3.5l-.5-5.5c0-3-4-2.5-3.5 0ZM27.039 41l.5 6h4l-.5-6c0-3.5-4.5-3-4 0ZM62.039 41.5 61.5 47H58l.539-5.5c0-3 4-2.5 3.5 0ZM52.5 41l-.5 6h-4l.5-6c0-3.5 4.5-3 4 0Z"
    />
    <Path stroke="#103b5d" d="M19 32.5c6.19-3.773 24.857-9.055 42 0" />
  </Svg>
);
export default SvgIndoorSpace;
