import { useState, type FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faMegaphone } from '@fortawesome/pro-light-svg-icons';
import { TouchableOpacity } from '../../../base-components';
import { voloGreys } from '../../../theme';
import { ICON_SIZE } from '../RsvpSelector/RsvpSelector';
import styles from '../RsvpSelector/styles';
import { RSVPReminderModal } from '../..';
import { RSVPReminderType } from '../../../constants';

const ICON_COLOR = voloGreys.medium.toString();

type RSVPReminderProps = {
  playerId: string;
  gameId: string;
  teamId: string;
};

const RSVPReminder: FC<RSVPReminderProps> = ({ playerId, gameId, teamId }) => {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => setShowModal(false);

  return (
    <>
      <TouchableOpacity onPress={() => setShowModal(true)}>
        <FontAwesomeIcon
          icon={faMegaphone}
          color={ICON_COLOR}
          size={ICON_SIZE}
          style={styles.icon}
        />
      </TouchableOpacity>
      <RSVPReminderModal
        variant={RSVPReminderType.INDIVIDUAL}
        teamId={teamId}
        gameId={gameId}
        playerId={playerId}
        isOpen={showModal}
        onClose={onCloseModal}
      />
    </>
  );
};

export default RSVPReminder;
