import moment from 'moment-timezone';

import type { Day } from '../../types';

/**
 * Function to create an array of dates from a given start date to a given number of days
 */
export function createDatesArray(startDate: Date, numDays: number) {
  const dates: Array<Date> = [];
  for (let i = 0; i < numDays; i += 1) {
    const date = moment(startDate).add(i, 'days').toDate();
    dates.push(date);
  }
  return dates;
}

export function getDayOfWeekFromDate(date: Date, format: string = 'ddd') {
  return moment(date).format(format);
}

export function getDayOfMonthFromDate(date: Date, format: string = 'D') {
  return moment(date).format(format);
}

/**
 * Function to create a day object from the given date
 */
export function createDay(date: Date): Day {
  return {
    date,
    dayOfWeek: getDayOfWeekFromDate(date),
    dayOfMonth: getDayOfMonthFromDate(date),
  };
}

/**
 * Function to create an array of days from the given date
 * - `numDays` is the number of days to create including the given date
 * - default is 4 days
 */
export function createDays(date: Date, numDays: number = 4): Array<Day> {
  const dates = createDatesArray(date, numDays);
  return dates.map(createDay);
}
