import type { FC } from 'react';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';

import { FontAwesomeIcon, Image, Link, Row } from '../../base-components';

const vpLogoWhite = require('../../../assets/volo_logo_white.svg');

type BasicWebHeaderProps = {
  /** Email icon will be hidden if not provided */
  contactEmail?: string;
  /** Phone icon will be hidden if not provided */
  contactPhone?: string;
  /** Defaults to "https://www.volosports.com/" */
  logoLink?: string;
  /** Defaults to "https://www.volosports.com/contact-us" */
  contactUsLink?: string;
};

const BasicWebHeader: FC<BasicWebHeaderProps> = ({
  contactEmail,
  contactPhone,
  logoLink = 'https://www.volosports.com/',
  contactUsLink = 'https://www.volosports.com/contact-us',
}) => {
  return (
    <Row
      width="full"
      bg="primary.600"
      height="16"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Link href={logoLink}>
        <Image
          source={vpLogoWhite}
          alt="Volo Logo"
          resizeMode="contain"
          height="55px"
          width="114px"
          color="white.600"
        />
      </Link>
      <Row alignItems="center">
        {contactEmail && (
          <Link isExternal href={`mailto:${contactEmail}`}>
            <FontAwesomeIcon icon={faEnvelope} color="white.600" size="sm" />
          </Link>
        )}
        <Link
          marginX="2"
          isUnderlined={false}
          href={contactUsLink}
          _text={{ color: 'white.600', fontWeight: 'semibold' }}
        >
          CONTACT US
        </Link>
        {contactPhone && (
          <Link isExternal href={`tel:${contactPhone}`}>
            <FontAwesomeIcon icon={faPhone} color="white.600" size="sm" />
          </Link>
        )}
      </Row>
    </Row>
  );
};

export default BasicWebHeader;
