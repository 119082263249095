import React, { useCallback } from 'react';
import { Text, Row, type ITextProps, Column } from '../../../base-components';
import { Colors } from '../../../theme';
import { PriceFormatter } from '../../../utilities/formatters';
import { usePaymentFormContext } from '../PaymentForm';

export interface LineItemProps {
  /**
   * Name of the line item
   */
  name: string;

  /**
   * Number representing the cost of the item in cents.
   */
  priceInCents: number;

  /**
   * Optional caption placed under the line item. Can be a string, JSX, or anything that can be
   * rendered by React.
   */
  description?: React.ReactNode;

  /**
   * If provided, `priceInCents` will be treated as the sale price, while `originalPriceInCents` will
   * be displayed next to the sale price with strikethrough to show how much the user would save.
   */
  originalPriceInCents?: number;

  /**
   * Optionally specify props that are passed down to the underlying text.
   */
  _text?: ITextProps;
}

const LineItem: React.FC<LineItemProps> = ({
  name: item,
  priceInCents,
  description,
  originalPriceInCents,
  _text,
}) => {
  // Wrapper component to automatically pass down `_text` prop.
  const {
    theme: { colors, dark },
  } = usePaymentFormContext();

  const LineItemText = useCallback(
    (props: ITextProps) => <Text color={colors.text} fontSize="15" {..._text} {...props} />,
    [_text, colors]
  );

  return (
    <Column flex={1}>
      <Row alignItems="center" justifyContent="space-between">
        <LineItemText flex={1}>{item}</LineItemText>
        <Row space="1.5">
          {!!originalPriceInCents && originalPriceInCents !== priceInCents && (
            <LineItemText strikeThrough color={dark ? Colors.greys.light : Colors.greys.medium}>
              {PriceFormatter.format(originalPriceInCents / 100)}
            </LineItemText>
          )}
          <LineItemText>{PriceFormatter.format(priceInCents / 100)}</LineItemText>
        </Row>
      </Row>
      {typeof description === 'string' || typeof description === 'number' ? (
        // The usage of `<Text>` instead of `<LineItemText>` is intentional as it sits below the line item.
        <Text type="caption" color={dark ? Colors.greys.light : Colors.greys.medium}>
          {description}
        </Text>
      ) : (
        description
      )}
    </Column>
  );
};

export default LineItem;
