import type { ComponentProps } from 'react';

import { FormControl } from '../../../../../base-components';
import { TextTypography, useTheme } from '../../../../../theme';

interface RentalSectionLabelProps extends ComponentProps<typeof FormControl.Label> {
  isRequired?: boolean;
  _formControl?: ComponentProps<typeof FormControl>;
}

const RentalSectionLabel = ({
  children,
  isRequired,
  _text,
  _formControl,
  ...rest
}: RentalSectionLabelProps) => {
  const { colors } = useTheme();
  return (
    <FormControl isRequired={isRequired} width={undefined} {..._formControl}>
      <FormControl.Label
        _text={{ bold: true, color: colors.text, fontSize: TextTypography.p.fontSize, ..._text }}
        my="0.5"
        {...rest}
      >
        {children}
      </FormControl.Label>
    </FormControl>
  );
};

export default RentalSectionLabel;
