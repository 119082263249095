import { useSuspenseQuery } from '@apollo/client';
import type { IHStackProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Stack/HStack';
import { WholeDollarFormatter } from '../../../../utilities';
import { Row, Stack, Text } from '../../../../base-components';
import GET_VENUE_PRICING from './graphql/GET_VENUE_PRICING';

type Props = {
  venueId: string;
  containerStyle?: IHStackProps;
};

/**
 * Pricing information for `venueView`, aka all rentals at a venue.
 *
 * Only used on web.
 */
const PricingVenueView = ({ venueId, containerStyle }: Props) => {
  const { data } = useSuspenseQuery(GET_VENUE_PRICING, {
    variables: {
      venueId,
    },
  });

  const {
    lowest_vp_rental_price,
    lowest_non_vp_rental_price,
    highest_vp_rental_price,
    highest_non_vp_rental_price,
  } = data.venue;

  if (
    !lowest_vp_rental_price ||
    !lowest_non_vp_rental_price ||
    !highest_vp_rental_price ||
    !highest_non_vp_rental_price
  )
    throw new Error('No pricing data found for venue.');

  // Top range prices are displayed without the $ sign
  const lowestNonVPPrice = WholeDollarFormatter.format(lowest_non_vp_rental_price / 100);
  const highestNonVPPrice = Math.round(highest_non_vp_rental_price / 100);
  const lowestVPPrice = WholeDollarFormatter.format(lowest_vp_rental_price / 100);
  const highestVPPrice = Math.round(highest_vp_rental_price / 100);

  const showHighestNonVPPrice = highest_non_vp_rental_price !== lowest_non_vp_rental_price;
  const showHighestVPPrice = highest_vp_rental_price !== lowest_vp_rental_price;

  return (
    <Row justifyContent="space-between" {...containerStyle}>
      <Stack>
        <Row alignItems="baseline">
          <Text bold type="h3">
            {lowestNonVPPrice}
            {showHighestNonVPPrice && `-${highestNonVPPrice}`}
          </Text>
          <Text fontSize={12}>/HR</Text>
        </Row>
        <Text fontSize={12}>
          MEMBER PRICE: {lowestVPPrice}
          {showHighestVPPrice && `-${highestVPPrice}`}
          <Text fontSize={10}>/HR</Text>
        </Text>
      </Stack>
    </Row>
  );
};

export default PricingVenueView;
