import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';

interface BasicsProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  cityOptions: { value: string; label: string }[];
  shiftCity: { value: string; label: string };
  shiftDOW: string;
  venueOptions: { value: string; label: string }[];
  shiftVenue: { value: string; label: string };
  shiftSubVenue?: string;
  shiftDate: string;
  shiftSports: string[];
}

const Basics: React.FC<BasicsProps> = ({
  cityOptions = [],
  shiftCity,
  shiftDOW,
  venueOptions = [],
  shiftVenue,
  shiftSubVenue,
  shiftDate,
  shiftSports,
  ...props
}) => {
  return (
    <CollapseForm title="Basics" view="read" {...props}>
      <CollapseForm.SingleSelect
        isRequired
        label="What city are you in?"
        defaultValue={shiftCity}
        options={cityOptions}
      />
      <CollapseForm.DayOfWeekSelect isRequired label="Day of Week:" selectedDay={shiftDOW} />
      <CollapseForm.SingleSelect
        isRequired
        label="Venue:"
        defaultValue={shiftVenue}
        options={venueOptions}
      />
      <>
        <CollapseForm.TextArea label="Sub-venue (Field, Court, etc.):" response={shiftSubVenue} />
        <CollapseForm.DateSelect label="Date of Shift:" selectedDate={shiftDate} />
      </>
      <CollapseForm.MultiSelect label="Sport(s):" selectedResponses={shiftSports} />
    </CollapseForm>
  );
};

export default Basics;
