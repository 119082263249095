import { gql, type TypedDocumentNode } from '@apollo/client';
import type { MembershipEnum, ProgramTypeEnum } from '../../../constants';

type ProgramPricingInput = {
  leagueId: string;
  creditAmount?: number | null;
  donationAmount?: number | null;
  isPrepaidTeam?: boolean | null;
  membershipName?: MembershipEnum | null;
  promoCodeStr?: string | null;
  isDonatingPortionOfRegistration?: boolean | null;
};

export type PricingQueryInput = {
  leagueId: string;
  inputRegular: ProgramPricingInput;
  inputVoloPass: ProgramPricingInput;
  inputVoloFitness: ProgramPricingInput;
};

export type PricingBreakdown = {
  pricingBreakdown: {
    originalPrice: number | null;
    memberPrice: number | null;
    voloFitnessPrice: number | null;

    membershipDiscountApplied: MembershipEnum | null;
    creditAmount: number;
    donationAmount: number;
    promoDiscountCents: number;
    totalFeeCents: number;
    totalChargeCents: number;
  };
};

export type DropInPricingBreakdown = {
  programPriceCents: number;

  membershipDiscountApplied: MembershipEnum | null;
  creditAmount: number;
  donationAmount: number;
  promoDiscountCents: number;
  totalFeeCents: number;
  totalChargeCents: number;
};

export type PricingQueryResponse = {
  currentUser: {
    __typename: 'CurrentUser';
    _id: string;
    isVoloPassMember: boolean;
    isVoloFitnessMember: boolean;
  } | null;

  league: {
    __typename: 'League';
    _id: string;
    programType: ProgramTypeEnum;
    is_volo_pass_exclusive: boolean;
    organization: {
      isVoloPassTrialActive: boolean;
      voloFitnessTiers: {
        isActive: boolean;
        monthlyDollarPrice: number | null;
        monthlyFee: number | null;
      };
      voloPassTiers: {
        isActive: boolean;
        monthlyDollarPrice: number | null;
        monthlyFee: number | null;
      };
    };
  };

  regularPricing: PricingBreakdown;
  voloPassPricing: PricingBreakdown;
  voloFitnessPricing: PricingBreakdown;
};

const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserPricingQueryFragment on CurrentUser {
    _id
    isVoloPassMember
    isVoloFitnessMember
  }
`;

const LEAGUE_FRAGMENT = gql`
  fragment LeaguePricingQueryFragment on League {
    _id
    programType
    is_volo_pass_exclusive
    organization {
      isVoloPassTrialActive
      voloFitnessTiers {
        isActive
        monthlyDollarPrice
        monthlyFee
      }
      voloPassTiers {
        isActive
        monthlyDollarPrice
        monthlyFee
      }
    }
  }
`;

const PRICING_FRAGMENT = gql`
  fragment PricingBreakdownFragment on PricingBreakdown {
    # Used to compute the 3 registration options
    originalPrice
    memberPrice
    voloFitnessPrice

    membershipDiscountApplied
    creditAmount
    donationAmount
    promoDiscountCents
    totalFeeCents
    totalChargeCents
  }
`;

const DROP_IN_PRICING_FRAGMENT = gql`
  fragment DropInPricingBreakdownFragment on PricingBreakdown {
    programPriceCents

    membershipDiscountApplied
    creditAmount
    donationAmount
    promoDiscountCents
    totalFeeCents
    totalChargeCents
  }
`;

export const PRICING_QUERY: TypedDocumentNode<PricingQueryResponse, PricingQueryInput> = gql`
  query ProgramPricing(
    $inputRegular: ProgramPricingForRegistrationInput!
    $inputVoloPass: ProgramPricingForRegistrationInput!
    $inputVoloFitness: ProgramPricingForRegistrationInput!
    $leagueId: ID!
  ) {
    currentUser {
      ...CurrentUserPricingQueryFragment
    }

    league(_id: $leagueId) {
      ...LeaguePricingQueryFragment
    }

    regularPricing: programPricingForRegistration(input: $inputRegular) {
      pricingBreakdown {
        __typename
        ...PricingBreakdownFragment
      }
    }

    voloPassPricing: programPricingForRegistration(input: $inputVoloPass) {
      pricingBreakdown {
        __typename

        ...PricingBreakdownFragment
      }
    }

    voloFitnessPricing: programPricingForRegistration(input: $inputVoloFitness) {
      pricingBreakdown {
        __typename
        ...PricingBreakdownFragment
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
  ${LEAGUE_FRAGMENT}
  ${PRICING_FRAGMENT}
`;

export type DropInPricingInput = {
  leagueId: string;
  input: {
    leagueId: string;
    promoCodeStr?: string | null;
    creditCents?: number | null;
    donationCents?: number | null;
    isDonatingPortionOfRegistration?: boolean | null;
  };
};

export type DropInPricingResponse = {
  currentUser: PricingQueryResponse['currentUser'];
  league: PricingQueryResponse['league'];

  dropinPricingForRegistration: {
    currentUserPaymentCriteria: 'nonmemberBreakdown' | 'memberBreakdown' | 'voloFitnessBreakdown';
    nonmemberBreakdown: DropInPricingBreakdown | null;
    memberBreakdown: DropInPricingBreakdown | null;
    voloFitnessBreakdown: DropInPricingBreakdown | null;
  };
};

export const DROP_IN_PRICING_QUERY: TypedDocumentNode<DropInPricingResponse, DropInPricingInput> =
  gql`
    query DropInPricing($leagueId: ID!, $input: DropinPricingForRegistrationInput!) {
      currentUser {
        ...CurrentUserPricingQueryFragment
      }

      league(_id: $leagueId) {
        ...LeaguePricingQueryFragment
      }

      dropinPricingForRegistration(input: $input) {
        currentUserPaymentCriteria
        nonmemberBreakdown {
          __typename
          ...DropInPricingBreakdownFragment
        }
        memberBreakdown {
          __typename
          ...DropInPricingBreakdownFragment
        }
        voloFitnessBreakdown {
          __typename
          ...DropInPricingBreakdownFragment
        }
      }
    }
    ${CURRENT_USER_FRAGMENT}
    ${LEAGUE_FRAGMENT}
    ${DROP_IN_PRICING_FRAGMENT}
  `;
