import { useQuery } from '@apollo/client';
import { GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS } from './gql/GET_USER_MEMBERSHIP_DETAILS';
import {
  MembershipInterval,
  MembershipStatus,
  type MembershipIntervalType,
  type MembershipStatusType,
} from '../../constants';

const useUserVoloPassMembershipStatus = () => {
  const {
    data: membershipData,
    loading: membershipLoading,
    error: membershipError,
    refetch: membershipRefetch,
  } = useQuery(GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    userIsActiveVoloPassMember,
    userHasPastVoloPassMembership,
    activeVoloPassMembership,
    pastVoloPassMembership,
  } = membershipData || {};

  const hasMemberHistory = userIsActiveVoloPassMember || userHasPastVoloPassMembership;

  let membershipStatus: MembershipStatusType | undefined;
  let membershipStartDate = '';
  let membershipType: MembershipIntervalType | undefined;
  let membershipOrganizationId = '';

  if (userIsActiveVoloPassMember && !!activeVoloPassMembership) {
    const { plan, status, userHomeOrganization, metadata, planStartsAt } =
      activeVoloPassMembership || {};
    const { interval } = plan || {};
    membershipStatus = MembershipStatus[status as unknown as keyof typeof MembershipStatus].label;
    membershipStartDate = planStartsAt;
    membershipType =
      MembershipInterval[interval as unknown as keyof typeof MembershipInterval].label;
    membershipOrganizationId = metadata.organizationId || userHomeOrganization || '';
  } else if (userHasPastVoloPassMembership && !!pastVoloPassMembership) {
    const { plan, status, userHomeOrganization, metadata, planStartsAt } =
      pastVoloPassMembership || {};
    const { interval } = plan || {};
    membershipStatus = MembershipStatus[status as unknown as keyof typeof MembershipStatus].label;
    membershipStartDate = planStartsAt;
    membershipType =
      MembershipInterval[interval as unknown as keyof typeof MembershipInterval].label;
    membershipOrganizationId = metadata.organizationId || userHomeOrganization || '';
  }

  return {
    voloPassMembershipLoading: membershipLoading,
    voloPassMembershipError: membershipError,
    hasVoloPassMemberHistory: hasMemberHistory,
    voloPassMembershipStatus: membershipStatus,
    voloPassMembershipStartDate: membershipStartDate,
    voloPassMembershipType: membershipType,
    voloPassMembershipOrganizationId: membershipOrganizationId,
    voloPassMembershipRefetch: membershipRefetch,
  };
};

export default useUserVoloPassMembershipStatus;
