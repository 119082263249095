import { Radio as NBRadio, type IRadioProps, useColorMode } from '@rivallapp/native-base';
import { Platform } from 'react-native';

const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';

const Radio = (props: IRadioProps) => {
  const { colorMode } = useColorMode();

  // On mobile, we want the Radio component to match the theme for select components
  const mobileColorScheme = colorMode === 'light' ? 'lightSelect' : 'darkSelect';

  return <NBRadio colorScheme={isMobile ? mobileColorScheme : 'primary'} {...props} />;
};

Radio.Group = NBRadio.Group;
export default Radio;
