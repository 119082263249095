import Svg, { Circle, Path, Rect } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgDjOnSite = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Path stroke="#103b5d" strokeLinecap="round" d="m55.5 55.5 2 2M56 53h3m-6 3v3" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path stroke="#103b5d" d="M17.5 17.5h44v44h-44z" />
    <Circle cx={40} cy={40} r={17.5} fill="#103b5d" stroke="#103b5d" />
    <Circle cx={40} cy={40} r={2.5} stroke="#fff" />
    <Rect
      width={6}
      height={9}
      x={27.707}
      y={31.95}
      stroke="#fff"
      rx={3}
      transform="rotate(-45 27.707 31.95)"
    />
    <Path stroke="#103b5d" d="m27.5 27.5-10-10" />
    <Path stroke="#fff" d="m30 30-2.5-2.5" />
    <Path
      stroke="#fff"
      strokeLinecap="round"
      d="M40 26.5c7.5 0 13.5 6 13.5 13.5M26.5 40c0 7.5 6 13.5 13.5 13.5"
    />
    <Path
      stroke="#fff"
      strokeLinecap="round"
      d="M30.5 40c0 5 4 9.5 9.5 9.5M40 30.5c5.5 0 9.5 4.5 9.5 9.5"
    />
  </Svg>
);
export default SvgDjOnSite;
