import React from 'react';
import { Path, Polygon, G } from 'react-native-svg';

import { Icon } from '../../../base-components';
import { useTheme, type LogoColorVariant } from '../../../theme';
import { type VoloLogoProps, DEFAULT_COLOR } from '../types';

const VoloFitnessLogo: React.FC<VoloLogoProps> = ({ colorScheme, ...props }) => {
  const { colors } = useTheme();
  const logoColor = colorScheme
    ? (colors.logoColors[colorScheme as LogoColorVariant] ?? DEFAULT_COLOR)
    : DEFAULT_COLOR;
  return (
    <Icon {...props} color={logoColor} viewBox="0 0 481 236">
      <G>
        <Polygon points="153.7,33.9 141.6,59.6 169.8,59.6 181.9,33.9 	" />
        <Polygon points="197.4,0.8 169.2,0.8 157.1,26.5 185.3,26.5 	" />
        <Path d="M116.4,113.1V92.6V66.9H90.7H65c0,14.2,11.5,25.7,25.7,25.7h0.1v75.2h28.2l47.4-100.9h-28.2L116.4,113.1z" />
        <Path d="M116.4,26.5c0-14.2-12.8-25.7-26.9-25.7H1.3C1.3,15,12.8,26.5,27,26.5H116.4z" />
        <Path d="M116.4,33.9H32.5c0,14.2,11.5,25.7,25.7,25.7h58.2V33.9z" />
        <Path
          d="M249,66.9l-14.2,66.6c-0.9,4.5-5.3,8.1-9.8,8.1h-16.1c-4.5,0-7.3-3.6-6.4-8.1l14.2-66.6h-26.2l-16,75.2
		c-3,14.2,6,25.7,20.3,25.7h33.3c14.2,0,28.2-11.5,31.2-25.7l16-75.2H249z"
        />
        <Polygon points="276.8,59.6 282.3,33.9 256,33.9 250.6,59.6 	" />
        <Path d="M263.6,0.8h-33.3c-14.2,0-28.2,11.5-31.2,25.7h84.7C286.8,12.3,277.8,0.8,263.6,0.8z" />
        <Polygon points="218.3,59.6 223.7,33.9 197.5,33.9 192.1,59.6 	" />
        <Polygon points="334,0.8 307.1,0.8 301.6,26.5 328.5,26.5 	" />
        <Path
          d="M320,66.9H293l-16,75.2v0c-2.8,13.2,7.3,25.7,20.8,25.7h0.7h38.1l5.5-26.9h-29.2c-4.6,0-8-4.3-7.1-8.8
		L320,66.9z"
        />
        <Polygon points="327,33.9 300,33.9 294.6,59.6 321.5,59.6 	" />
        <Path
          d="M411.8,133.5c-0.9,4.5-5.3,8.1-9.8,8.1h-16.1c-4.5,0-7.3-3.6-6.4-8.1l14.2-66.6h-26.2l-16,75.2
		c-3,14.2,6,25.7,20.3,25.7H405c14.2,0,28.2-11.5,31.2-25.7l16-75.2h-26.2L411.8,133.5z"
        />
        <Polygon points="427.5,59.6 453.7,59.6 459.2,33.9 433,33.9 	" />
        <Polygon points="400.7,33.9 374.5,33.9 369,59.6 395.2,59.6 	" />
        <Path d="M440.5,0.8h-33.3c-14.2,0-28.2,11.5-31.2,25.7h84.7C463.8,12.3,454.7,0.8,440.5,0.8z" />
      </G>
      <G>
        <Path
          d="M194.1,212.9h-15.8l-2.8,15.6c-0.1,0.6-0.4,0.9-1,0.9h-2.7c-0.6,0-0.9-0.3-0.8-0.9l6.2-35.3
		c0.1-0.6,0.5-0.8,1.1-0.8h19.9c0.5,0,0.8,0.3,0.7,0.8l-0.4,2.4c-0.1,0.6-0.4,0.9-1,0.9h-16.2l-2.2,12.5h15.8c0.6,0,0.8,0.3,0.7,0.9
		l-0.4,2.3C195,212.7,194.7,212.9,194.1,212.9z"
        />
        <Path
          d="M210.4,228.5l6.2-35.3c0.1-0.6,0.4-0.8,1-0.8h2.8c0.6,0,0.8,0.3,0.7,0.8l-6.2,35.3c-0.1,0.6-0.4,0.9-1,0.9
		h-2.8C210.5,229.4,210.3,229.1,210.4,228.5z"
        />
        <Path
          d="M238,195.7l0.4-2.5c0.1-0.6,0.4-0.8,1-0.8h24c0.6,0,0.8,0.3,0.7,0.8l-0.4,2.5c-0.1,0.6-0.5,0.9-1.1,0.9H253
		l-5.7,31.9c-0.1,0.6-0.4,0.9-1,0.9h-2.7c-0.6,0-0.9-0.3-0.8-0.9l5.7-31.9h-9.7C238.2,196.6,237.9,196.3,238,195.7z"
        />
        <Path
          d="M309.3,193.1l-6.3,35.4c-0.1,0.6-0.4,0.9-1,0.9h-2.7c-0.5,0-0.9-0.2-1.1-0.7l-13.8-28.8l-5.1,28.6
		c-0.1,0.6-0.4,0.9-1,0.9h-2.7c-0.6,0-0.9-0.3-0.8-0.9l6.2-35.3c0.1-0.6,0.5-0.8,1.1-0.8h2.6c0.6,0,1,0.2,1.2,0.6l13.8,28.9l5-28.7
		c0.1-0.6,0.4-0.8,1-0.8h2.7C309.1,192.3,309.3,192.6,309.3,193.1z"
        />
        <Path
          d="M346,212.5h-15.7l-2.3,12.8h16.4c0.6,0,0.9,0.3,0.8,0.8l-0.4,2.4c-0.2,0.6-0.5,1-1.1,1h-20
		c-0.6,0-0.9-0.3-0.8-0.9l6.2-35.3c0.1-0.6,0.4-0.8,1-0.8h19.9c0.6,0,0.8,0.3,0.7,0.8l-0.4,2.4c-0.1,0.6-0.5,0.9-1.1,0.9h-16.3
		l-2.1,11.9h15.7c0.5,0,0.8,0.3,0.7,0.9l-0.4,2.2C346.9,212.2,346.5,212.5,346,212.5z"
        />
        <Path
          d="M387,218.4c0,2.6-0.7,4.8-2.2,6.5c-1.4,1.8-3.3,3-5.5,3.7s-4.7,1.1-7.5,1.1c-2.1,0-4.2-0.2-6.2-0.5
		c-2-0.3-3.5-0.7-4.4-1.2c-0.6-0.3-0.8-0.6-0.5-1l1.3-2.4c0.2-0.6,0.6-0.7,1.2-0.5c2.4,1,5.3,1.5,8.8,1.5c1.2,0,2.4-0.1,3.5-0.4
		c1-0.2,2.1-0.6,3.3-1.1c1.1-0.5,2-1.2,2.7-2.2c0.7-0.9,1-2,1-3.4c0-0.8-0.2-1.6-0.5-2.2c-0.4-0.7-1-1.3-1.9-1.8
		c-1-0.6-1.7-1-2.2-1.3c-0.2-0.1-1.2-0.5-3.1-1.3c-2.8-1.2-4.9-2.5-6.5-3.9c-1.5-1.3-2.3-3.1-2.3-5.4c0-3.7,1.3-6.5,3.9-8.4
		c2.6-1.8,6-2.7,10.2-2.7c3.9,0,7,0.5,9.3,1.5c0.5,0.2,0.7,0.6,0.5,1l-1.3,2.3c-0.3,0.5-0.7,0.7-1.2,0.5c-1.9-0.8-4.3-1.2-7.3-1.2
		c-6.4,0-9.6,2.2-9.6,6.5c0,0.5,0.1,1,0.2,1.3c0.1,0.4,0.3,0.7,0.6,1.1c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0.3,0.7,0.5,1.2,0.8
		c0.4,0.2,0.8,0.4,1.3,0.7c0.6,0.3,1.1,0.5,1.5,0.7c0.2,0.1,0.5,0.2,0.8,0.4s0.6,0.2,0.7,0.3c1.4,0.6,2.6,1.2,3.5,1.7
		c0.7,0.4,1.6,1,2.9,2c1.1,0.9,1.8,1.8,2.3,2.8C386.8,215.9,387,217.1,387,218.4z"
        />
        <Path
          d="M427.1,218.4c0,2.6-0.7,4.8-2.2,6.5c-1.4,1.8-3.3,3-5.5,3.7s-4.7,1.1-7.5,1.1c-2.1,0-4.2-0.2-6.2-0.5
		c-2-0.3-3.5-0.7-4.4-1.2c-0.6-0.3-0.8-0.6-0.5-1l1.3-2.4c0.2-0.6,0.6-0.7,1.2-0.5c2.4,1,5.3,1.5,8.8,1.5c1.2,0,2.4-0.1,3.5-0.4
		c1-0.2,2.1-0.6,3.3-1.1c1.1-0.5,2-1.2,2.7-2.2c0.7-0.9,1-2,1-3.4c0-0.8-0.2-1.6-0.5-2.2c-0.4-0.7-1-1.3-1.9-1.8
		c-1-0.6-1.7-1-2.2-1.3c-0.2-0.1-1.2-0.5-3.1-1.3c-2.8-1.2-4.9-2.5-6.5-3.9c-1.5-1.3-2.3-3.1-2.3-5.4c0-3.7,1.3-6.5,3.9-8.4
		c2.6-1.8,6-2.7,10.2-2.7c3.9,0,7,0.5,9.3,1.5c0.5,0.2,0.7,0.6,0.5,1l-1.3,2.3c-0.3,0.5-0.7,0.7-1.2,0.5c-1.9-0.8-4.3-1.2-7.3-1.2
		c-6.4,0-9.6,2.2-9.6,6.5c0,0.5,0.1,1,0.2,1.3c0.1,0.4,0.3,0.7,0.6,1.1c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0.3,0.7,0.5,1.2,0.8
		c0.4,0.2,0.8,0.4,1.3,0.7c0.6,0.3,1.1,0.5,1.5,0.7c0.2,0.1,0.5,0.2,0.8,0.4s0.6,0.2,0.7,0.3c1.4,0.6,2.6,1.2,3.5,1.7
		c0.7,0.4,1.6,1,2.9,2c1.1,0.9,1.8,1.8,2.3,2.8C426.8,215.9,427.1,217.1,427.1,218.4z"
        />
      </G>
      <G>
        <G>
          <Path d="M163.1,193.6H70.8c-2,0-3.5-1.1-3-2.3l0,0c0.3-0.9,1.6-1.5,3-1.5h93.7L163.1,193.6z" />
        </G>
        <G>
          <Path d="M153,229.4h-46c-1,0-1.7-1-1.5-2l0,0c0.2-0.8,0.8-1.3,1.5-1.3h46.7L153,229.4z" />
        </G>
        <G>
          <Path d="M156.8,211.5H95.5c-1.3,0-2.3-1.1-2-2.3l0,0c0.2-0.9,1-1.5,2-1.5h62.2L156.8,211.5z" />
        </G>
      </G>
    </Icon>
  );
};

export default VoloFitnessLogo;
