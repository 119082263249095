import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 57,
    paddingRight: 125,
    maxWidth: 524,
    flex: 1,
  },
  containerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 18,
  },
  containerColumn: {
    marginTop: 18,
    minWidth: 343,
  },
  heading: {
    fontSize: 16,
    lineHeight: 24,
    alignSelf: 'flex-start',
  },
  label: {
    fontSize: 12,
    lineHeight: 18,
    marginRight: 20,
    textAlign: 'left',
  },
  selectShortLeft: {
    flex: 1,
    marginRight: 8,
  },
  selectShortRight: {
    flex: 1,
    marginLeft: 8,
  },
  saveButton: {
    width: 157,
    height: 45,
    marginVertical: 18,
  },
  skeletonContainer: {
    flex: 1,
    alignItems: 'center',
  },
  skeleton: {
    marginTop: 18,
    width: 343,
    height: 30,
  },
});
