import { type StyleProp, type ViewStyle } from 'react-native';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { useTheme } from '../../theme';
import { Button, type ButtonProps, FontAwesomeIcon } from '../index';

// modified from host-app

interface Props extends Omit<ButtonProps, 'leftIcon' | 'rightIcon'> {
  label: string;
  selected?: boolean;
  onPress?: () => void;
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  containerStyle?: StyleProp<ViewStyle>;
}

const Chip = ({
  label,
  selected = false,
  leftIcon,
  rightIcon,
  onPress = () => null,
  containerStyle,
  ...props
}: Props) => {
  const { colors } = useTheme();

  return (
    <Button
      variant="outline"
      style={containerStyle}
      borderWidth={2}
      borderRadius="full"
      backgroundColor={selected ? colors.accents.select.bg : colors.background}
      paddingTop={1}
      paddingBottom={1}
      onPress={onPress}
      leftIcon={
        leftIcon ? (
          <FontAwesomeIcon
            icon={leftIcon}
            color={selected ? colors.accents.select.text : colors.text}
          />
        ) : undefined
      }
      rightIcon={rightIcon ? <FontAwesomeIcon icon={rightIcon} /> : undefined}
      _text={{ color: selected ? colors.accents.select.text : colors.text }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default Chip;
