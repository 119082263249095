import { gql, type TypedDocumentNode } from '@apollo/client';
import type { PlayerStrikeHistoryResponse } from './PLAYER_STRIKE_HISTORY';

export type EditPlayerStrikeInput = {
  input: {
    _id: string;

    name?: string | null;
    associated_game?: string | null;
    invalidated?: boolean | null;
  };
};

export type EditPlayerStrikeResponse = {
  editPlayerStrike: PlayerStrikeHistoryResponse['user']['player_strikes']['history'][number];
};

const EDIT_PLAYER_STRIKE: TypedDocumentNode<EditPlayerStrikeResponse, EditPlayerStrikeInput> = gql`
  mutation EditPlayerStrike($input: PlayerStrikeMutationInput!) {
    editPlayerStrike(input: $input) {
      _id
      createdAt
      name
      fee_applied
      invalidated
      invalidation_type
      associated_game {
        _id
        name
        leagueId
      }
    }
  }
`;

export default EDIT_PLAYER_STRIKE;
