import { useState, useMemo } from 'react';
import { View } from '../../base-components';
import { TabHeader } from '../../custom-components';
import { DonationFrequencyEnum } from '../../constants/enums';
import { DonationTiers, type DonationOptionType } from '../DonationTiers';

export interface DonationRecurringCTAProps {
  donationOptions?: Array<DonationOptionType>;
  selectedOptionId?: string;
  onPressOption: (arg: DonationOptionType) => void;
}

const RecurringDonationCTA = ({
  donationOptions,
  selectedOptionId,
  onPressOption,
}: DonationRecurringCTAProps) => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [tab, setTab] = useState('Donate Once');

  const tabs = ['Donate Once', 'Donate Monthly'];

  const onSwitchTab = (value: string) => {
    setTab(value);
    if (value === 'Donate Monthly') setIsMonthly(true);
    else setIsMonthly(false);
  };

  const donationSelections = useMemo(() => {
    return donationOptions?.map(option => ({
      ...option,
      frequency: isMonthly ? DonationFrequencyEnum.MONTHLY : DonationFrequencyEnum.ONCE,
    }));
  }, [donationOptions, isMonthly]);

  return (
    <View flex={1}>
      <TabHeader values={tabs} selectedValue={tab} onSwitch={onSwitchTab} />
      <DonationTiers
        donationOptions={donationSelections}
        onPressOption={onPressOption}
        selectedOptionId={selectedOptionId}
        showTag
        monthly={isMonthly}
      />
    </View>
  );
};

export default RecurringDonationCTA;
