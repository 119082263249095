import type { ComponentProps } from 'react';

import { useTheme } from '../../../theme';
import { Modal, Text, View } from '../../../base-components';

/** simple standard bullet point */
const BULLET_POINT = `\u2022`;

const CancellationNoShowPolicyModal = (props: ComponentProps<typeof Modal>) => {
  const { colors } = useTheme();

  return (
    <Modal useRNModal size="lg" {...props}>
      <Modal.Content>
        <Modal.Header
          borderBottomWidth={0}
          padding={0} // clears NB default
          paddingTop={4}
          paddingLeft={4}
          _text={{ fontSize: 20, color: colors.text }}
        >
          Cancellation and No-Show Policy
        </Modal.Header>
        <Modal.CloseButton />
        <Modal.Body>
          <Text marginBottom={4}>
            You may cancel your spot and receive credits to your Volo account for your registration
            fees if you cancel <Text fontWeight={700}> at least 6 hours before</Text> the program
            start time.
          </Text>
          <Text>
            If you cancel<Text fontWeight={700}> within 6 hours</Text> of the program start time:
          </Text>
          <View marginLeft={3}>
            <View flexDirection="row">
              <Text fontWeight={700}>{BULLET_POINT}</Text>
              <Text paddingLeft={2}>
                <Text fontWeight={700}>Volo Pass Member</Text> - On the first two offenses, the
                member will receive a warning. On and after the 3rd offense, a $10 penalty fee will
                be assessed.
              </Text>
            </View>
            <View flexDirection="row">
              <Text fontWeight={700}>{BULLET_POINT}</Text>
              <Text paddingLeft={2}>
                <Text fontWeight={700}>Non Volo Pass Member</Text> - The cost of the program will
                not be credited.
              </Text>
            </View>
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default CancellationNoShowPolicyModal;
