import moment from 'moment-timezone';
import React from 'react';
import type { IStackProps } from '@rivallapp/native-base';

import { View, Text, Stack, Row, Skeleton } from '../../../../../../base-components';
import { type RentalBooking, type RentalBookingState } from '../../../../store';
import {
  formatCourtNames,
  formatFullTimeRange,
  formatRentalActivityLabel,
} from '../../RentalBookingSummary/helpers';
import { RentalImageCarousel } from '../../../../../RentalDetails';
import RentalEditChip from '../../RentalEditChip';

export interface ConfirmedBookingItemProps {
  /**
   * Confirmed booking to display
   */
  booking: RentalBooking;
  /**
   * The user who created the reservation that the user is joining
   */
  joiningReservationDetails?: RentalBookingState['joiningReservationDetails'];
  /**
   * onEditPress callback to setting the bookingId to edit
   */
  onEditPress?: (bookingId: string) => void;
}

const ImageWrapperDims = (props: IStackProps) => (
  // helps a little bit on very tiny screens
  // screen size would need to be sub 280px for this to be a problem so this should be fine for now
  <Stack
    height={[61, 66, 71, 76]}
    width={[66, 71, 76, 81]}
    borderRadius={10}
    overflow="hidden"
    {...props}
  />
);

const ConfirmedBookingItem = ({
  booking,
  onEditPress,
  joiningReservationDetails,
}: ConfirmedBookingItemProps) => {
  const { bookingId, rentalId, rentalType, steps, price } = booking;
  const { courtsSelection, dateSelection } = steps;
  const timeFrame = formatFullTimeRange(dateSelection.timeSlots);
  const courtNames = formatCourtNames(courtsSelection.selectedCourts);
  const activityLabel = formatRentalActivityLabel(rentalType);
  const formattedSubtotal = price?.formattedSubtotal || '$ --';

  const label = joiningReservationDetails
    ? `Join ${joiningReservationDetails.createdBy.firstNameLastInitial}.'s ${activityLabel} Rental`
    : `${activityLabel} Rental`;

  return (
    <Row key={bookingId} flex={1} alignItems="center" space={3} padding={2}>
      <React.Suspense
        fallback={
          <ImageWrapperDims justifyContent="center" alignItems="center">
            <Skeleton flex={1} />
          </ImageWrapperDims>
        }
      >
        <ImageWrapperDims>
          <RentalImageCarousel rentalId={rentalId} resizeMode="cover" flex={1} />
        </ImageWrapperDims>
      </React.Suspense>

      <Stack flex={1} space={1}>
        <Text fontSize={16} bold lineHeight={18}>
          {label}
        </Text>
        <Stack pl={1}>
          <Text fontSize={14} lineHeight={16}>
            {moment(dateSelection.startDate).format('M/D/YY')} @ {timeFrame}
          </Text>
          <Text fontSize={14} lineHeight={16}>
            {courtNames}
          </Text>
          <Text fontSize={14} lineHeight={16} bold>
            {formattedSubtotal}
          </Text>
        </Stack>
      </Stack>
      {onEditPress && (
        <View position="absolute" right={2} top={2}>
          <RentalEditChip
            onPress={() => {
              onEditPress(bookingId);
            }}
          />
        </View>
      )}
    </Row>
  );
};

export default ConfirmedBookingItem;
