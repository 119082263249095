import { Divider, type IDividerProps } from '../../../../../base-components';

/**
 * Preset divider for rental registration sections
 * - Uses `horizontal` as default orientation
 */
const RentalDivider = (props: IDividerProps) => {
  return <Divider orientation="horizontal" my={2} {...props} />;
};

export default RentalDivider;
