import type { ComponentProps, FC } from 'react';
import { Column, Row, Text } from '../../../base-components';

type Props = {
  /**
   * The amount in cents that a user receives when they refer another user.
   * This should come from the organization's referral settings (`referral_reward_amount_giver`)
   * */
  amount: number;
  /** Props to pass to the wrapper view */
  _container?: ComponentProps<typeof Column>;
  /** Props to pass to the title text */
  _title?: ComponentProps<typeof Text>;
  /** Props to pass to the subtitle text */
  _subtitle?: ComponentProps<typeof Text>;
};

const ReferralGiverAmount: FC<Props> = ({ _container, _title, _subtitle, amount }) => {
  const titleProps: ComponentProps<typeof Text> = {
    fontSize: '64px',
    color: 'lightBlue.600',
    ..._title,
  };

  return (
    <Column alignItems="center" {..._container}>
      <Row alignItems="baseline">
        <Text {...titleProps}>$</Text>
        <Text bold {...titleProps}>
          {Math.round(amount / 100)}
        </Text>
      </Row>
      <Text bold fontSize="xs" textAlign="center" {..._subtitle}>
        You earn each time a friend joins!
      </Text>
    </Column>
  );
};

export default ReferralGiverAmount;
