import { type CSSProperties, type MouseEvent, type PropsWithChildren } from 'react';
import cx from 'classnames';
import { useId } from 'react';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';

const styleBooleans = [
  // sizes
  'wide',
  'small',
  'large',
  'block',

  // font weight
  'light',
  'bold',

  // colors
  'primary',
  'action',
  'success',
  'danger',
  'default',
  'white',
  'grey',
  'navy',
  'neon',
  'skyblue',

  // types
  'link',
  'square',
  'active',
  'inactive',
  'loading',
  'disabled',
  'ghost',
] as const;

type StyleBoolean = (typeof styleBooleans)[number];

type ButtonProps = {
  attrs?: Record<string, string>;
  className?: string[] | string | null;
  disabled?: boolean;
  href?: string | null;
  label?: string;
  loading?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
  styleName?: string;
  to?: string;
  type?: 'button' | 'reset' | 'submit';
  newWindow?: boolean;
  id?: string | null;
  isPlayerDashboard?: boolean;
} & Partial<Record<StyleBoolean, boolean>>;

const Button = ({
  attrs = {},
  children = null,
  className = null,
  disabled = false,
  href = null,
  label = 'Submit',
  loading,
  onClick = () => {},
  style = {},
  styleName = '',
  to,
  type = 'button',
  newWindow = false,
  id,
  isPlayerDashboard = false,
  ...styleProps
}: PropsWithChildren<ButtonProps>) => {
  const idForAnalytics = useId();

  const disabledProps = disabled
    ? {
        disabled: true,
        'aria-disabled': true,
      }
    : {};

  const classes = [
    styles.button,
    ...styleBooleans.map(op =>
      op in styleProps && styleProps[op as keyof typeof styleProps] ? styles[op] : ''
    ),
    styleName ? styles[styleName] : '',
    className,
  ];

  const buttonProps = {
    type,
    className: cx(classes, { disabled, loading }),
    style,
    id: id ?? idForAnalytics,
    ...disabledProps,
    ...attrs,
  };

  const buttonChild = children || label;

  if (to) {
    return (
      <Link {...buttonProps} to={to} target={newWindow ? '_blank' : undefined}>
        {buttonChild}
      </Link>
    );
  }
  if (href) {
    return (
      <a {...buttonProps} href={href}>
        {buttonChild}
      </a>
    );
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button data-player-dashboard={isPlayerDashboard} {...buttonProps} onClick={onClick}>
      {buttonChild}
    </button>
  );
};

export default Button;
