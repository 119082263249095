import { type FC } from 'react';
import { Text } from '../../../../base-components';
import { capitalize } from '../../../../utilities';
import type { SportDisplayNameModule } from '../../RentalCard/types';

const SportDisplayNameSection: FC<Omit<SportDisplayNameModule, 'type'>> = ({
  rentalActivity,
  venueName,
}) => (
  <Text type="h3" bold>
    {rentalActivity.name} {capitalize(rentalActivity.surface)}s at {venueName}
  </Text>
);

export default SportDisplayNameSection;
