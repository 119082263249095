import React, { createContext, useContext, useMemo } from 'react';

import { Colors, DarkTheme, LightTheme, type NavTheme, useTheme } from '../../theme';
import { Box } from '../../base-components';
import Divider from './form-sections/Divider';
import LineItem from './form-sections/LineItem';
import LineItemGroup from './form-sections/LineItemGroup';
import Checkbox from './form-sections/Checkbox';

interface PaymentFormProps {
  children: React.ReactNode;
  themeOverride?: 'light' | 'dark';
}

type PaymentFormContext = {
  theme: NavTheme;
};

const PaymentFormContext = createContext<PaymentFormContext | null>(null);

const useThemeFromOverride = (themeOverride?: 'light' | 'dark') => {
  const theme = useTheme();

  if (themeOverride === 'light') return LightTheme;
  if (themeOverride === 'dark') return DarkTheme;

  return theme;
};

export const usePaymentFormContext = () => {
  const ctx = useContext(PaymentFormContext);
  if (!ctx) throw new Error('Please use this hook inside a PaymentFormContext Provider!');
  return ctx;
};

const PaymentForm = ({ children, themeOverride }: PaymentFormProps) => {
  const theme = useThemeFromOverride(themeOverride);

  const paymentFormContextValue = useMemo(
    () => ({
      theme,
    }),
    [theme]
  );

  return (
    <PaymentFormContext.Provider value={paymentFormContextValue}>
      <Box
        borderWidth="1"
        borderRadius="sm"
        borderColor={theme.dark ? Colors.greys.veryLight : Colors.greys.medium}
        // BG is transparent in dark mode
        backgroundColor={theme.dark ? undefined : LightTheme.colors.background}
        p={2}
      >
        {children}
      </Box>
    </PaymentFormContext.Provider>
  );
};

PaymentForm.Divider = Divider;
PaymentForm.LineItem = LineItem;
PaymentForm.LineItemGroup = LineItemGroup;
PaymentForm.Checkbox = Checkbox;

export default PaymentForm;
