import { useCallback } from 'react';

import { type RentalDateSelection } from '../../RegistrationFlowSections';
import { dateStepSelector, type DateStepSelector } from '../rentalBookingStore.selectors';
import useRentalBookingStore from '../useRentalBookingStore';

interface RentalBookingTimeSlots extends DateStepSelector {
  updateTimeSlots: (
    newTimeSlots:
      | RentalDateSelection['timeSlots']
      | ((currentTimeSlots: RentalDateSelection['timeSlots']) => RentalDateSelection['timeSlots'])
  ) => void;
}

/**
 * Hook to interact with the time slots selection step only of the current booking.
 * - updates the time slots in the current booking
 * - resets the selected courts when the time slots change
 */
const useRentalBookingTimeSlots = (): RentalBookingTimeSlots => {
  const { startDate, timeSlots, isStepValid, rentalId } = useRentalBookingStore(dateStepSelector);

  const updateTimeSlots = useCallback<RentalBookingTimeSlots['updateTimeSlots']>(newTimeSlots => {
    useRentalBookingStore.setState(state => {
      const { currentBooking } = state;
      if (!currentBooking) {
        return state;
      }

      const { steps } = currentBooking;
      const { dateSelection: currentDateSelection } = steps;

      return {
        currentBooking: {
          ...currentBooking,
          steps: {
            ...steps,
            dateSelection: {
              ...currentDateSelection,
              timeSlots:
                typeof newTimeSlots === 'function'
                  ? newTimeSlots(currentDateSelection.timeSlots)
                  : newTimeSlots,
            },
            courtsSelection: {
              selectedCourts: [],
            },
          },
        },
      };
    });
  }, []);

  return {
    rentalId,
    startDate,
    timeSlots,
    isStepValid,
    updateTimeSlots,
  };
};

export default useRentalBookingTimeSlots;
