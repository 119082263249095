import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface RefereesProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  referees_response: boolean;
  gameManagementResponses?: string[];
  refereesComments?: string;
}

const Referees: React.FC<RefereesProps> = ({
  referees_response = false,
  gameManagementResponses = [],
  refereesComments,
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Referees"
      view="read"
      toggleQuestion="Any issues to report with referees during your shift?"
      toggleResponse={referees_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.MultiSelect
          label="Game Management Feedback:"
          selectedResponses={gameManagementResponses}
        />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Comments/Notes:" response={refereesComments} />
      </Box>
      {children}
    </CollapseForm>
  );
};

export default Referees;
