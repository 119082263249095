import tinycolor from 'tinycolor2';
import type { Palette, PaletteKey } from '../../theme';

/**
 * Ensures that the lighten/darken amount stays within 0 and 100
 */
const clampDiff = (diff: number): number => Math.min(100, Math.max(0, diff));

/**
 * @param {string} initialColor - The initial color to generate the palette from. Accepts any tinycolor input (hex, rgb, hsl, etc.)
 * @param {number} initialKey - The initial key to start the palette from (default: 600).
 * @param {number} diff - The difference between each color in the palette (default: 10).
 * @returns Palette with keys from 50 to 900
 */
const generatePalette = (
  initialColor: string,
  initialKey: PaletteKey = 600,
  diff: number = 10
): Palette => {
  if (!tinycolor(initialColor).isValid()) {
    throw new Error('Could not parse the color provided.');
  }

  // initialize palette object
  const palette = {
    50: '',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
    700: '',
    800: '',
    900: '',
  };

  palette[initialKey] = initialColor;

  // set colors for all higher keys
  let currentKey = initialKey === 50 ? 100 : initialKey + 100;
  let diffCounter = 1;

  while (currentKey <= 900) {
    // @ts-ignore
    palette[currentKey] = tinycolor(initialColor)
      .darken(clampDiff(diff * diffCounter))
      .toHexString();
    currentKey += 100;
    diffCounter += 1;
  }

  // early return if initialKey is 50
  if (initialKey === 50) return palette;

  // set colors for all lower keys
  currentKey = Math.max(50, initialKey - 100);
  diffCounter = 1;

  while (currentKey >= 50) {
    // @ts-ignore
    palette[currentKey] = tinycolor(initialColor)
      .lighten(clampDiff(diff * diffCounter))
      .toHexString();

    if (currentKey === 50) break;

    currentKey = Math.max(50, currentKey - 100);
    diffCounter += 1;
  }

  return palette;
};

export default generatePalette;
