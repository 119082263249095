// **** File copy/pasted from mobile ***
import { useQuery, useLazyQuery } from '@apollo/client';
import { DROP_IN_SLOT } from '../graphql/MANAGE_DROPIN_SLOTS';

const useDropInSlotDetails = ({ slotId }: { slotId?: string } = {}) => {
  const {
    data,
    loading: hookLoading,
    error: hookError,
  } = useQuery(DROP_IN_SLOT, {
    skip: !slotId,
    fetchPolicy: 'cache-and-network',
    variables: { id: slotId! },
  });
  const slot = data?.dropInSlot;

  const [getSlot, { loading: lazyLoading, error: lazyError }] = useLazyQuery(DROP_IN_SLOT);

  return {
    slot,
    getSlot,
    loading: hookLoading || lazyLoading,
    error: hookError || lazyError,
  };
};

export default useDropInSlotDetails;
