import React from 'react';

import CollapsibleBase from './CollapsibleBase';
import type { CollapsibleBaseProps } from './CollapsibleBase';

export type CollapsibleHeaderProps = Omit<CollapsibleBaseProps, 'children'>;

/** For use in situations where rendering children is handled somewhere else */
const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = ({ isCollapsed, title, onPress }) => {
  return <CollapsibleBase onPress={onPress} title={title} isCollapsed={isCollapsed} />;
};

export default CollapsibleHeader;
