import { gql, type TypedDocumentNode } from '@apollo/client';

type ProgramRosterInput = {
  leagueId: string;
};

export type ProgramRosterResults = {
  programRoster: { userId: string }[];
};

export const GET_PROGRAM_ROSTER: TypedDocumentNode<ProgramRosterResults, ProgramRosterInput> = gql`
  query programRoster($leagueId: ID!) {
    programRoster(leagueId: $leagueId) {
      userId
    }
  }
`;
