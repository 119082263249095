/* eslint-disable global-require */
import React from 'react';
import { type ImageSourcePropType } from 'react-native';
import { Image, Heading, View } from '../index';
import styles from './styles';

interface LoadingProps {
  kind?: 'inline' | 'white' | undefined;
  message?: string;
}

const Loading = ({ kind, message }: LoadingProps) => {
  let src: ImageSourcePropType;
  switch (kind) {
    case 'inline':
      src = require('../../../assets/loading/inline-small.gif');
      break;
    case 'white':
      src = require('../../../assets/loading/bball-white-small.gif');
      break;
    default:
      src = require('../../../assets/loading/bball.gif');
  }

  return (
    <View style={styles.loadingContainer}>
      <Image
        style={kind === 'inline' ? styles.inline : styles.loader}
        alt="Loading..."
        source={src}
        accessibilityRole="progressbar"
      />
      {message ? (
        <Heading style={[styles.loadingMessage, kind === 'white' ? styles.white : null]}>
          {message}
        </Heading>
      ) : null}
    </View>
  );
};

export default Loading;
