import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Text } from '../../../base-components';

export interface NothingToReportProps
  extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  sectionList?: string[];
}

const NothingToReport: React.FC<NothingToReportProps> = ({ sectionList = [], ...props }) => {
  return (
    <CollapseForm title="Nothing To Report" view="read" {...props}>
      {sectionList?.map(sectionName => <Text key={sectionName}>{sectionName}</Text>)}
    </CollapseForm>
  );
};

export default NothingToReport;
