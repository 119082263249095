import { useCallback } from 'react';
import { TouchableOpacity, ActivityIndicator } from 'react-native';
import { useMutation, type ApolloQueryResult } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-light-svg-icons/faSquareCheck';
import { faSquareX } from '@fortawesome/pro-light-svg-icons/faSquareX';
import { faSquareExclamation } from '@fortawesome/pro-light-svg-icons/faSquareExclamation';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { Colors, voloGreys } from '../../../theme';
import { rsvpEnum, ErrorMessageEnum } from '../../../constants/enums';
import RSVP_FOR_PLAYER from '../graphql/RSVP_FOR_PLAYER';
import { View, Text } from '../../../base-components';
import styles from './styles';

interface RsvpSelectorProps {
  /** if current user is captain/co-captain/admin */
  hasCaptainPermissions?: boolean;
  response?: string;
  playerId?: string;
  teamId?: string;
  gameId?: string;
  rsvpRefetch?: () => Promise<ApolloQueryResult<any>>;
  setSuccess?: (arg: string) => void;
  setError?: (arg: string) => void;
}

type RsvpEnum = (typeof rsvpEnum)[keyof typeof rsvpEnum];

export const ICON_SIZE = 20;
const { no, yes } = Colors.accents || {};

const playerResponseIcon = Object.freeze({
  [rsvpEnum.YES]: { icon: faSquareCheck, color: yes.toString() },
  [rsvpEnum.NO]: { icon: faSquareX, color: no.toString() },
  // treat MAYBE as no until it's removed from player UI
  [rsvpEnum.MAYBE]: { icon: faSquareX, color: no.toString() },
  NONE: { icon: faSquareExclamation, color: voloGreys.medium.toString() },
});

const RsvpSelector = ({
  hasCaptainPermissions = false,
  response = '',
  playerId = '',
  teamId = '',
  gameId = '',
  rsvpRefetch,
  setSuccess = () => {},
  setError = () => {},
}: RsvpSelectorProps) => {
  const [rsvpForPlayer, { loading, error }] = useMutation(RSVP_FOR_PLAYER);

  const rsvpIcon =
    response && response in playerResponseIcon
      ? playerResponseIcon[response as keyof typeof playerResponseIcon]
      : playerResponseIcon.NONE;

  const handleRsvpForPlayer = useCallback(
    async ({ captainResponse }: { captainResponse: RsvpEnum }) => {
      try {
        await rsvpForPlayer({
          variables: {
            input: {
              teamId,
              gameId,
              playerId,
              response: captainResponse,
            },
          },
        });
        if (rsvpRefetch) await rsvpRefetch();
        setSuccess('Player RSVP updated');
      } catch (error) {
        setError(ErrorMessageEnum.RSVP_FOR_PLAYER);
      }
    },
    [rsvpForPlayer, teamId, gameId, playerId, rsvpRefetch, setSuccess, setError]
  );

  if (error) {
    return (
      <View>
        <Text>{String(error)}</Text>
      </View>
    );
  }
  if (loading) return <ActivityIndicator />;

  return (
    <View>
      {hasCaptainPermissions && !response ? (
        <View style={styles.captainContainer}>
          <TouchableOpacity onPress={() => handleRsvpForPlayer({ captainResponse: rsvpEnum.YES })}>
            <FontAwesomeIcon
              icon={faCheck}
              color={voloGreys.medium.toString()}
              size={ICON_SIZE}
              style={styles.icon}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleRsvpForPlayer({ captainResponse: rsvpEnum.NO })}>
            <FontAwesomeIcon
              icon={faXmark}
              color={voloGreys.medium.toString()}
              size={ICON_SIZE}
              style={styles.icon}
            />
          </TouchableOpacity>
        </View>
      ) : (
        <FontAwesomeIcon
          icon={rsvpIcon.icon}
          color={rsvpIcon.color}
          size={ICON_SIZE}
          style={styles.icon}
        />
      )}
    </View>
  );
};

export default RsvpSelector;
