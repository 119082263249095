import { createContext, useContext } from 'react';
import { type IBoxProps, type IIconProps } from '@rivallapp/native-base';

export const defaultTextProps: IBoxProps['_text'] = {
  fontSize: 13,
  textAlign: 'center',
};

const HaloChipContext = createContext<{
  color: string;
  _textProps: IBoxProps['_text'];
  _iconProps?: IIconProps;
}>({
  color: '',
  _textProps: defaultTextProps,
  _iconProps: undefined,
});

export default HaloChipContext;

export const useHaloChipContext = () => {
  const context = useContext(HaloChipContext);
  if (!context) {
    throw new Error('useHaloChipContext must be used within a HaloChipProvider');
  }
  return context;
};
