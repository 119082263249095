import { type FC } from 'react';
import { faCalendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock';

import { Text, View, FontAwesomeIcon } from '../../../../base-components';
import type { DateModule } from '../../ProgramCard/types';
import useMobileSizing from '../../helpers/useMobileSizing';

const StartDateSection: FC<Omit<DateModule, 'type'>> = ({ start_date, num_weeks }) => {
  const mobileSizing = useMobileSizing();
  const date = new Date(start_date);
  const formattedDate = Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' }).format(
    date
  );

  return (
    <View
      flexDirection="row"
      flex={1}
      alignItems="center"
      maxWidth={mobileSizing ? 170 : 250}
      minHeight={mobileSizing ? 18 : 26}
    >
      {/* using half margin to center with location dot on league cards */}
      {!mobileSizing ? <FontAwesomeIcon icon={faCalendarClock} size={5} ml="0.5" /> : null}
      <View overflow="hidden">
        <Text
          marginLeft={2.5}
          numberOfLines={1}
          fontSize={14}
          letterSpacing={-0.5}
          maxWidth={mobileSizing ? 160 : 220}
        >
          {formattedDate} {num_weeks && num_weeks > 1 ? `(${num_weeks} weeks)` : null}
        </Text>
      </View>
    </View>
  );
};

export default StartDateSection;
