import { gql, type TypedDocumentNode } from '@apollo/client';

interface OrgEmailTemplatesInput {
  organizationId: string;
}

interface OrgEmailTemplatesData {
  getTemplateEmails: {
    emails: {
      _id: string;
      html: string;
      subject: string;
    }[];
  };
}

const ORG_EMAIL_TEMPLATES: TypedDocumentNode<OrgEmailTemplatesData, OrgEmailTemplatesInput> = gql`
  query getTemplateEmails($organizationId: ID!) {
    getTemplateEmails(_id: $organizationId) {
      emails {
        _id
        html
        subject
      }
    }
  }
`;

export default ORG_EMAIL_TEMPLATES;
