import { gql, type TypedDocumentNode } from '@apollo/client';
import type { RSVPReminderType } from '../../../constants';

type TeamInput = {
  gameId: string;
  teamId: string;
  reminder_type: RSVPReminderType.TEAM;
};

type IndividualInput = {
  gameId: string;
  teamId: string;
  reminder_type: RSVPReminderType.INDIVIDUAL;
  recipient_id: string;
};

interface CheckCanSendRSVPReminderInput {
  input: TeamInput | IndividualInput;
}

interface CheckCanSendRSVPReminderData {
  checkCanSendRSVPReminder: {
    reminder_eligible: boolean;
  };
}

const CHECK_CAN_SEND_RSVP_REMINDER: TypedDocumentNode<
  CheckCanSendRSVPReminderData,
  CheckCanSendRSVPReminderInput
> = gql`
  query CheckCanSendRSVPReminder($input: CheckCanSendRSVPReminderInput!) {
    checkCanSendRSVPReminder(input: $input) {
      reminder_eligible
    }
  }
`;

export default CHECK_CAN_SEND_RSVP_REMINDER;
