import { useCallback } from 'react';
import moment from 'moment-timezone';
import { NetworkStatus } from '@apollo/client';

import { useTheme } from '../../../../../theme';
import { Row, Spinner, View } from '../../../../../base-components';
import ModalAlertComponent, { ModalAlert } from '../../../../../custom-components/ModalAlert';
import { SlotContainer, SlotSelectorItem, SlotWrapper } from './components';
import { useRentalBookingTimeSlots } from '../../../store/hooks';
import useRentalTimeSlots from './hooks/useRentalTimeSlots/useRentalTimeSlots';
import { type RentalTimeSlot } from '../../steps.types';
import { timeSlotLabelFormat } from '../../steps.constants';
import { RentalErrorComponent } from '../../../common/components';

function compareMinuteStrings(a: string, b: string) {
  return a.split(':')[1] === b.split(':')[1];
}

/*
  Booking Time Selector should take a startDate that would then be used to fetch the available time slots
  - slotLength is the length of each time slot in minutes
  - should show all the slots for the given day by the slotLength
  - should disable if start time of slot has past already, or if a time slot is already booked
  - should be able to select a range of time slots for the booking (must be at least the slotLength long & has to be consecutive slots)
  - the first and last selected slot should be of color `selectedColor` the rest should be of color `inRangeColor`
  - slots should show a stack with the time (7:00) and then AM/PM underneath & prime time pricing if applicable
*/

/**
 * Time Selector component that shows the available time slots for the selected date in the rental flow.
 */
const BookingTimeSelector = () => {
  const { colors } = useTheme();

  const {
    startDate,
    timeSlots: selectedTimeSlots,
    updateTimeSlots,
    rentalId,
  } = useRentalBookingTimeSlots();
  const firstSelectedSlot = selectedTimeSlots[0];
  const lastSelectedSlot = selectedTimeSlots[selectedTimeSlots.length - 1];

  const { slots, defaultSlots, spacers, updateRowWidth, error, refetch, networkStatus } =
    useRentalTimeSlots(rentalId, startDate);
  const uiSlots = slots.length ? slots : defaultSlots;

  const firstSelectedIndex = slots.findIndex(s => s.id === firstSelectedSlot?.id);
  const lastSelectedIndex = slots.findIndex(s => s.id === lastSelectedSlot?.id);
  const findFirstIndexAfterSelectedSlotThatIsNotAvailableAsEndTimeInConnectedSlots = () => {
    const connectedSlotIds = firstSelectedSlot?.connectedSlotIds || [];
    const foundIdWithEndTimeNotAvailable = connectedSlotIds.find((id, index) =>
      index > firstSelectedIndex ? !slots.find(s => s._id === id)?.isEndTimeAvailable : false
    );

    const firstSlotInGroupThatIsNotAvailableEndTime = slots.find(
      s => s._id === foundIdWithEndTimeNotAvailable
    );

    return slots.findIndex(s => s._id === firstSlotInGroupThatIsNotAvailableEndTime?._id);
  };

  const handleSlotSelection = useCallback(
    (slot: RentalTimeSlot) => {
      updateTimeSlots(prev => {
        // if no slots are selected, then add the slot to the selected slots
        if (prev.length === 0) {
          return [slot];
        }

        // if there are already 2 slots selected, then clear the selected slots array
        if (prev.length >= 2) {
          return [];
        }

        // if the slot start time is before the first slot selected, then restart the selected slots array
        if (
          moment(slot.time_str, timeSlotLabelFormat).isBefore(
            moment(prev[0]!.time_str, timeSlotLabelFormat)
          )
        ) {
          return [slot];
        }

        // if there is one slot selected, then this slot would be considered the end slot
        // and we need to find the slots (from the timeSlots array) in between the first slot and this slot
        // and add them to the selected slots array
        if (prev.length === 1) {
          const startSlot = prev[0]!;

          // if the slots are the same, then we need to clear the selected slots array
          if (startSlot.id === slot.id) {
            return [];
          }

          const startIndex = slots.findIndex(s => s.id === startSlot.id);
          const endIndex = slots.findIndex(s => s.id === slot.id);
          // this logic allows for overlapped selected slots to not be added to the selected slots array.
          const slotsBetweenSelection = slots
            .slice(startIndex + 1, endIndex)
            .filter(s => compareMinuteStrings(s.time_str, startSlot.time_str));

          const isAnyBetweenSlotsBooked = slotsBetweenSelection.some(s => !s.isStartTimeAvailable);
          const isFirstSlotAvailable = startSlot.isStartTimeAvailable;
          const isLastSlotAvailable = slot.isEndTimeAvailable;
          const isNotValid =
            !isFirstSlotAvailable && !isLastSlotAvailable && isAnyBetweenSlotsBooked;

          if (isNotValid) {
            ModalAlert.alert({
              id: 'time-slots-alert',
              title: 'Oh no!',
              message:
                'This time range is not available as some slots are already booked. Please select another time range.',
              buttons: [
                {
                  text: 'Continue',
                  textColor: 'white.600',
                  onPress: () => updateTimeSlots([]),
                  utilProps: {
                    variant: 'solid',
                    colorScheme: 'rentalBlue',
                  },
                },
              ],
              modalProps: {
                onClose: () => updateTimeSlots([]),
              },
            });
          }

          const currentlySelectedSlots = [startSlot, ...slotsBetweenSelection, slot];
          return currentlySelectedSlots;
        }

        // default to return the slot if no other conditions are met
        return [slot];
      });
    },
    // updateTimeSlots is a stable reference - no need to add it to the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slots]
  );

  if (error) {
    return (
      <RentalErrorComponent
        error={error}
        fallBackMessage="There was an error fetching the available time slots. Please try again."
        refetch={refetch}
        isLoading={networkStatus === NetworkStatus.refetch}
      />
    );
  }

  const isLoading =
    (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables) &&
    !slots.length;

  return (
    <>
      <Row flexWrap="wrap" justifyContent="center" onLayout={updateRowWidth}>
        {isLoading && (
          <View
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            alignItems="center"
            justifyContent="center"
            backgroundColor={colors.border}
            opacity={0.2}
            borderRadius="lg"
            pointerEvents="none"
            zIndex={899}
          >
            <Spinner size="large" zIndex={999} />
          </View>
        )}
        {uiSlots.map((slot, index) => {
          const isSelected = selectedTimeSlots.some(s => s.id === slot.id);
          const isFirstSelectedSlot = slot.id === firstSelectedSlot?.id;
          const isLastSelectedSlot = slot.id === lastSelectedSlot?.id;

          const isFirstOrLastSlot = isFirstSelectedSlot || isLastSelectedSlot;
          const isBetweenRange = index > firstSelectedIndex && index < lastSelectedIndex;

          const isDisabledBeforeSelectedStartTime = firstSelectedSlot
            ? index < slots.findIndex(s => s.id === firstSelectedSlot.id)
            : false;

          const isDisabledAsNotInGrouping = firstSelectedSlot
            ? !firstSelectedSlot.connectedSlotIds.includes(slot._id)
            : !slot.isStartTimeAvailable;

          const firstIndexNotAvailableInConnectedSlots =
            findFirstIndexAfterSelectedSlotThatIsNotAvailableAsEndTimeInConnectedSlots();

          const isDisabledAsEndTimeNotAvailableInGrouping =
            firstSelectedSlot &&
            index !== firstSelectedIndex &&
            firstIndexNotAvailableInConnectedSlots > -1
              ? index >= firstIndexNotAvailableInConnectedSlots
              : false;

          const isDisabled =
            isDisabledBeforeSelectedStartTime ||
            isDisabledAsNotInGrouping ||
            isDisabledAsEndTimeNotAvailableInGrouping;

          return (
            <SlotContainer key={slot.id}>
              <SlotWrapper
                isFirstSelectedSlot={isFirstSelectedSlot}
                isLastSelectedSlot={isLastSelectedSlot}
                isSelected={isSelected || isBetweenRange}
              >
                <SlotSelectorItem
                  item={slot}
                  colors={colors}
                  isSelected={isSelected}
                  isDisabled={isDisabled}
                  isBetweenRange={isBetweenRange}
                  isFirstOrLastSlot={isFirstOrLastSlot}
                  handleSlotSelection={handleSlotSelection}
                />
              </SlotWrapper>
            </SlotContainer>
          );
        })}
        {spacers.map(spacer => (
          <SlotContainer key={spacer.id} />
        ))}
      </Row>
      {/*
        utilizing this as it fixes possible iOS issue of the root <ModalAlertComponent /> not rendering on top of a `Modal` that uses `useRNModal` as well
        - this is precautionary as mobiles reg flow will be within a `SheetNavigator` which does not use NB `Modal` component & does not have this issue
        */}
      <ModalAlertComponent id="time-slots-alert" />
    </>
  );
};

export default BookingTimeSelector;
