// May be deprecated in favor of storing donation tiers in Stripe

export const DonationValues = Object.freeze({
  ONE: 'one',
  FIVE: 'five',
  TWENTY: 'twenty',
  FIFTY: 'fifty',
  ONESEVENTYFIVE: 'one_seventy_five',
});

export const DonationOptions = Object.freeze({
  [DonationValues.ONE]: {
    amt: 1,
    tag: 'Share a smile',
    value: 100,
  },
  [DonationValues.FIVE]: {
    amt: 5,
    tag: 'Give a high five',
    value: 500,
  },
  [DonationValues.TWENTY]: {
    amt: 20,
    tag: 'Donate equipment',
    value: 2000,
  },
  [DonationValues.FIFTY]: {
    amt: 50,
    tag: 'Sponsor play for a day',
    value: 5000,
  },
  [DonationValues.ONESEVENTYFIVE]: {
    amt: 175,
    tag: 'Sponsor play for a season',
    value: 17500,
  },
});

export enum DonationFrequencyEnum {
  ONCE = 'once',
  MONTHLY = 'monthly',
}

export {
  /**
   * @deprecated Jul 29 2024 - Use the correct spelling please. This is kept for backwards compatibility.
   */
  DonationFrequencyEnum as DonationFrequncyEnum,
};
