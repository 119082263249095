import { Text, View } from '../../base-components';
import styles from './styles';

interface ItemSeparatorComponentProps {
  expanded?: boolean;
  style?: object | [];
  containerStyle?: object | [];
  titleStyle?: object | [];
  title?: string;
}

// from mobile

const ItemSeparatorComponent = ({
  expanded = false,
  style,
  containerStyle,
  titleStyle,
  title,
}: ItemSeparatorComponentProps) => (
  <View style={[styles.row, expanded ? styles.margin : null, containerStyle]}>
    {title ? (
      <>
        <View style={[styles.border, style]} />
        <Text style={[styles.title, titleStyle]}>{title}</Text>
      </>
    ) : null}
    <View style={[styles.border, style]} />
  </View>
);

export default ItemSeparatorComponent;
