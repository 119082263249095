import type { FC, MouseEvent } from 'react';
import { useMediaQuery } from '@rivallapp/native-base';
import { Link } from 'react-router-dom';

import { Column, Chip, Row, Text, Image, Pressable } from '../../base-components';
import { PriceFormatter, capitalize } from '../../utilities';
import { SportSurfaceEnum } from '../../constants';

type RentalData = {
  _id: string;
  name: string;
  updated_at: string;
  has_numeric_courts: boolean | null;
  future_revenue_cents: number | null;
  courts: {
    _id: string;
    name: string;
  }[];
  userByUpdatedBy: {
    _id: string;
    full_name: string | null;
  };
  sportBySport: {
    _id: string;
    name: string;
    surface_type: SportSurfaceEnum | string | null;
  };
  venueByVenue: {
    _id: string;
    shorthand_name: string;
  };
  first_timeslot_by_date: {
    _id: string;
    start_time: string;
  }[];
  last_timeslot_by_date: {
    _id: string;
    start_time: string;
  }[];
  slots_available: {
    aggregate: {
      count: number;
    } | null;
  };
  slots_taken: {
    aggregate: {
      count: number;
    } | null;
  };
  reservation_holders: {
    aggregate: {
      count: number;
    } | null;
  };
  attendee_count: {
    aggregate: {
      count: number;
    } | null;
  };
};

/**
 * @param _courts rental.courts[]
 * @param numeric_courts rental.has_numeric_courts
 * @param surface_type rental.sportBySport.surface_type
 */
export const formatRentalCourtsLabel = (
  _courts: RentalData['courts'],
  has_numeric_courts: boolean | null,
  surface_type: SportSurfaceEnum | string | null
) => {
  const courts = _courts
    .map(c => c.name)
    .toSorted((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));
  const surfaceCopy = `${capitalize(surface_type)}${courts.length > 1 ? 's' : ''}`;

  let courtsCopy: string;
  // if the rental uses a custom naming scheme
  if (!has_numeric_courts) courtsCopy = 'Misc';
  else if (courts.length === 1) courtsCopy = `${courts.at(0)!.split(' ').pop()}`;
  else courtsCopy = `${courts.at(0)!.split(' ').pop()} - ${courts.at(-1)!.split(' ').pop()}`;

  return `${surfaceCopy} ${courtsCopy}`;
};

// For the updated at section
const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'medium',
  timeStyle: 'short',
});

// For the date range section
const dateRangeFormatter = new Intl.DateTimeFormat('en-US', {
  month: '2-digit',
  day: '2-digit',
  year: '2-digit',
});

type Props = {
  rental: RentalData;
  onPress: VoidFunction;
  sportIcon: string;
};

const RentalDashboardCard: FC<Props> = ({ rental, onPress, sportIcon }) => {
  // The breakpoint at which this card switches to a portrait view
  const [portrait] = useMediaQuery({ maxWidth: 600 });

  const {
    name,
    courts,
    updated_at,
    has_numeric_courts,
    future_revenue_cents,
    last_timeslot_by_date: [{ start_time: endDate } = {}],
    first_timeslot_by_date: [{ start_time: startDate } = {}],
    userByUpdatedBy: { full_name },
    venueByVenue: { shorthand_name },
    sportBySport: { name: activity_name, surface_type },
  } = rental;

  const slots_taken = rental.slots_taken.aggregate?.count ?? 0;
  const slots_available = rental.slots_available.aggregate?.count ?? 0;
  const reservation_holder_count = rental.reservation_holders.aggregate?.count ?? 0;
  const attendee_count = rental.attendee_count.aggregate?.count ?? 0;

  // Displayed on the courts chip - shows 'Misc' if the courts use custom naming
  const courtsCopy = formatRentalCourtsLabel(courts, has_numeric_courts, surface_type);

  // Displayed as the upcoming date range
  const dateRangeCopy =
    !startDate || !endDate
      ? 'No upcoming timeslots scheduled'
      : `${dateRangeFormatter.format(new Date(startDate))} - ${dateRangeFormatter.format(
          new Date(endDate)
        )}`;

  const onLinkPress = (event: MouseEvent) => {
    if (!event.metaKey) {
      onPress();
    }
  };

  return (
    <Link onClick={onLinkPress} to={`/rfo/rental/${rental._id}`} component={Pressable}>
      <Column
        pt={8}
        p={4}
        space={6}
        pl={portrait ? 4 : 8}
        alignItems="center"
        flexDir={portrait ? 'column' : 'row'}
      >
        {/* activity icon, name, etc. */}
        <Row alignItems="center" flex={1} flexDir={portrait ? 'column' : 'row'}>
          <Image
            src={sportIcon}
            h={12}
            w={12}
            mb={portrait ? 4 : 0}
            alt={`${activity_name} icon`}
          />
          <Column flex={1} ml={portrait ? 0 : 6} alignItems={portrait ? 'center' : 'flex-start'}>
            <Text fontSize="md" textAlign={portrait ? 'center' : 'left'}>
              {name}
            </Text>
            <Row space={2} mt={2} flexWrap="wrap">
              <Chip
                size="xs"
                h={6}
                px={2}
                borderWidth={1}
                focusable={false}
                pointerEvents="none"
                label={courtsCopy}
              />
              <Chip
                size="xs"
                h={6}
                px={2}
                borderWidth={1}
                focusable={false}
                label={shorthand_name}
                pointerEvents="none"
              />
            </Row>
          </Column>
        </Row>

        {/* Rental stats */}
        <Row space={3} flex={1} flexWrap="wrap" textAlign={portrait ? 'center' : 'left'}>
          <Column>
            <Text fontSize="xs">Open Slots</Text>
            <Text fontSize="xs" fontWeight="medium">
              {slots_taken}/{slots_available + slots_taken}
            </Text>
          </Column>
          <Column>
            <Text fontSize="xs">Reserved Slots</Text>
            <Text fontSize="xs" fontWeight="medium">
              {reservation_holder_count}G | {attendee_count}M
            </Text>
          </Column>
          <Column>
            <Text fontSize="xs">Revenue</Text>
            <Text fontSize="xs" fontWeight="medium">
              {PriceFormatter.format(future_revenue_cents ?? 0 / 100)}
            </Text>
          </Column>
        </Row>

        {/* Date range */}
        <Row mr={2} mb={portrait ? 4 : 0}>
          <Text fontSize="xs">{dateRangeCopy}</Text>
        </Row>
      </Column>

      {/* last updated by */}
      <Row px={2} py={1} justifyContent="flex-end" alignItems="center">
        <Text fontSize="2xs">
          <Text fontSize="2xs" bold>
            Last updated:{' '}
          </Text>
          {dateFormatter.format(new Date(updated_at))} by {full_name}
        </Text>
      </Row>
    </Link>
  );
};

export default RentalDashboardCard;
