import type { FC } from 'react';

import { Text, Box, FormControl } from '../../../base-components';
import type { CollapseFormInputProps } from './types';
import { useCollapseFormContext } from '../CollapseFormContext';

export interface CompositeListProps extends CollapseFormInputProps {
  listValues?: (Record<string, string> & { _id: string })[];
  parentValue?: string;
  onChange?: () => void;
}

const CompositeList: FC<CompositeListProps> = ({
  isRequired,
  parentValue,
  listValues = [],
  onChange = () => {},
}) => {
  const { view } = useCollapseFormContext();

  switch (view) {
    case 'write':
      return <FormControl isRequired={isRequired}>{/* V2 */}</FormControl>;
    case 'read':
      return (
        <Box display="flex">
          {listValues.map(item => (
            <Box key={item._id}>
              {parentValue && <Text>{`\u2022 ${item[parentValue]}`}</Text>}
              {Object.keys(item)
                .filter(key => key !== parentValue && key !== '_id')
                .map(key => (
                  <Text key={`${item._id}${key}`} marginLeft={4}>
                    {`\u2022 ${key}: ${item[key]}`}
                  </Text>
                ))}
            </Box>
          ))}
        </Box>
      );
    default:
      return null;
  }
};

export default CompositeList;
