import { type ComponentProps, type FC } from 'react';
import { Image, Stack, Text } from '../../../base-components';
import HowItWorksListItem from './ReferralsHowItWorksListItem';

type Props = {
  /** Amount in cents the giver gets for a referral */
  giverAmount: number;
  /** Amount in cents the receiver gets for a referral */
  receiverAmount: number;
  /** Props to pass to the stack */
  _stack?: ComponentProps<typeof Stack>;
  /** Props for the coin image. This should include the image source for now. */
  _image?: ComponentProps<typeof Image>;
  /** Override the image being passed to this component. Used in mobile since the image is an SVG */
  ImageComponent?: React.ReactNode;
};

const ReferralsHowItWorks: FC<Props> = ({
  _stack,
  _image,
  giverAmount,
  receiverAmount,
  ImageComponent,
}) => {
  const giverAmountDollars = Math.round(giverAmount / 100);
  const receiverAmountDollars = Math.round(receiverAmount / 100);

  return (
    <Stack alignItems="center" space={8} mt={8} {..._stack}>
      <Stack px={5}>
        <Text type="h2" textAlign="center">
          Referrals: how it works.
        </Text>
        <Text type="p" textAlign="center">
          Invite friends. Score rewards!
        </Text>
      </Stack>
      <Stack flex={1}>
        {ImageComponent || (
          <Image
            alt="rewards"
            resizeMode="contain"
            style={{ height: 120, width: 180 }}
            src="https://rivall-public.s3.us-west-1.amazonaws.com/icons/offers-and-rewards-coin.svg"
            {..._image}
          />
        )}
      </Stack>
      <HowItWorksListItem number={1} title="Invite a Friend">
        Share your unique referral code with friends and family. It&apos;s always more fun to play
        with your crew!
      </HowItWorksListItem>
      <HowItWorksListItem number={2} title="Friends Join the Fun">
        When your friend registers for their first program, they will apply your code to receive{' '}
        <Text bold>${receiverAmountDollars} off </Text>
        registration for their first Volo program!
      </HowItWorksListItem>
      <HowItWorksListItem number={3} title="You'll Score Too">
        You&apos;ll get a <Text bold>${giverAmountDollars} credit</Text> as a reward.
      </HowItWorksListItem>
    </Stack>
  );
};

export default ReferralsHowItWorks;
