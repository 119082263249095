/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';
import {
  TouchableOpacity as RNTouchableOpacity,
  type TouchableOpacityProps as RNTouchableOpacityProps,
} from 'react-native';

export interface TouchableOpacityProps extends RNTouchableOpacityProps {}

/**
 * Wrapper around RN TouchableOpacity for easy UI feedback on mobile & web. (Pressable from NB doesnt seem to give feedback on mobile - should also address that as well)
 * - defaults `activeOpacity` to 0.6
 */
export const TouchableOpacity = forwardRef<RNTouchableOpacity, TouchableOpacityProps>(
  ({ activeOpacity = 0.6, children, ...rest }, ref) => {
    return (
      <RNTouchableOpacity ref={ref} activeOpacity={activeOpacity} {...rest}>
        {children}
      </RNTouchableOpacity>
    );
  }
);

export default TouchableOpacity;
