import React, { useCallback, useState, type ComponentProps } from 'react';
import { FlatList, type FlatListProps, type ListRenderItem } from 'react-native';
import { ApolloError, NetworkStatus, useQuery } from '@apollo/client';
import { useGetTeamCaptainsAndUserStatus } from '../../../hooks/teams';
import { TeamRoleEnum, TeamRoles } from '../../../constants/enums';
import { Text } from '../../../base-components';
import RosterItem from '../RosterItem/RosterItem';
import GET_TEAM_PLAYERS from '../graphql/GET_TEAM_PLAYERS';
import LeagueTeamRosterLoading from './LeagueTeamRosterLoading';
import { TeamRoleModal, type TeamRoleModalProps } from '../TeamRoleModal/TeamRoleModal';

type PlayerState = TeamRoleModalProps['player'] | null;
type TeamRoleState = TeamRoleModalProps['teamRole'] | null;
type PlayerData = { _id: string; displayNameTeams: string; teamRole: TeamRoleEnum };

export interface LeagueTeamRosterProps extends Omit<FlatListProps<any>, 'data' | 'renderItem'> {
  /**
   * Team to query a roster for.
   */
  teamId: string;
  /**
   * Callback to execute when pull-to-refresh is triggered.
   */
  onRefresh?: () => any | Promise<any>;
  /**
   * Callback triggered with an error message when a role update fails.
   */
  onErrorMessage?: (message: string) => void;
  /**
   * Callback triggered with a success message when a role update succeeds.
   */
  onSuccessMessage?: (message: string) => void;
  /**
   * Callback triggered with the player's ID when the contact icon is pressed
   */
  onContactBubblePress?: (playerId: string) => void;
  /**
   * Number of skeleton rows to render when loading.
   * If `LoadingComponent` is provided, this prop will be ignored.
   */
  numberOfSkeletonRows?: ComponentProps<typeof LeagueTeamRosterLoading>['numberOfSkeletonRows'];
  /**
   * Override the default loading state by passing an element
   * @default `LeagueTeamRosterLoading`
   */
  LoadingComponent?: React.ReactElement;
  /**
   * Since player-app has a custom error component that's not in the SCL, pass a render component
   * directly through props here. Once apollo client is updated, this _could_ be removed in favor
   * of suspense + an error boundary.
   */
  ErrorComponent?: React.FunctionComponent<{ onRetry: () => Promise<any>; error: ApolloError }>;
}

/** This is a variant of the TeamRoster that doesn't use the RSVP selector */
const LeagueTeamRoster: React.FC<LeagueTeamRosterProps> = ({
  teamId,
  onRefresh,
  ErrorComponent,
  LoadingComponent,
  onContactBubblePress,
  onErrorMessage = () => {},
  onSuccessMessage = () => {},
  ...props
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState<TeamRoleState>(null);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerState>(null);

  const {
    data,
    error,
    networkStatus,
    refetch: teamRefetch,
  } = useQuery(GET_TEAM_PLAYERS, {
    variables: { id: teamId },
    fetchPolicy: 'cache-and-network',
  });

  const { captainId, coCaptainIds, isCoCaptain, hasCaptainPermissions } =
    useGetTeamCaptainsAndUserStatus(teamId);

  const players = data?.team?.players ?? [];

  const generateTeamRole = (playerId: string) => {
    if (playerId === captainId) return TeamRoles[TeamRoleEnum.TEAM_CAPTAIN].label;
    if (coCaptainIds.includes(playerId)) return TeamRoles[TeamRoleEnum.CO_CAPTAIN].label;

    return TeamRoles[TeamRoleEnum.TEAM_MEMBER].label;
  };

  // captain, coCaptains, ...everyone else
  const captain = players.filter(p => p._id === captainId) || [];
  const coCaptains = players.filter(p => coCaptainIds.includes(p._id)) || [];
  const teamMembers =
    players.filter(p => p._id !== captainId && !coCaptainIds.includes(p._id)) || [];

  const sortedPlayers = [...captain, ...coCaptains, ...teamMembers].map(player => ({
    ...player,
    teamRole: generateTeamRole(player._id) as TeamRoleEnum,
  }));

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    try {
      await teamRefetch();
      if (onRefresh) await onRefresh();
    } catch (e) {
      onErrorMessage('There was an error refreshing the team data');
    } finally {
      setRefreshing(false);
    }
  }, [onErrorMessage, onRefresh, teamRefetch]);

  const renderItem = useCallback<ListRenderItem<PlayerData>>(
    ({ item }) => {
      const playerId = item._id;

      return (
        <RosterItem
          teamId={teamId}
          hideRSVPSelector
          playerId={playerId}
          displayName={item.displayNameTeams}
          teamRole={item.teamRole}
          hasCaptainPermissions={hasCaptainPermissions}
          isCoCaptain={isCoCaptain}
          setError={onErrorMessage}
          setSuccess={onSuccessMessage}
          setSelectedRole={setSelectedRole}
          setSelectedPlayer={setSelectedPlayer}
          setPopup={() => setPopupVisible(true)}
          onContactBubblePress={onContactBubblePress}
        />
      );
    },
    [
      hasCaptainPermissions,
      isCoCaptain,
      onContactBubblePress,
      onErrorMessage,
      onSuccessMessage,
      teamId,
    ]
  );

  if (networkStatus === NetworkStatus.loading) {
    if (LoadingComponent) return LoadingComponent;
    return <LeagueTeamRosterLoading {...props} />;
  }

  if (error) {
    if (ErrorComponent) return <ErrorComponent onRetry={teamRefetch} error={error} />;
    return (
      <Text textAlign="center" color="error.500" mt={2}>
        {error?.message ?? 'Could not load team roster.'}
      </Text>
    );
  }

  return (
    <>
      <FlatList
        data={sortedPlayers}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        refreshing={refreshing}
        onRefresh={handleRefresh}
        {...props}
      />
      <TeamRoleModal
        teamId={teamId}
        teamRole={selectedRole}
        player={selectedPlayer}
        isOpen={popupVisible}
        onErrorMessage={onErrorMessage}
        onSuccessMessage={onSuccessMessage}
        onClose={() => setPopupVisible(false)}
      />
    </>
  );
};

export default LeagueTeamRoster;
