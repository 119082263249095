import { StyleSheet } from 'react-native';
import { Colors, voloGreys } from '../../theme';

export default StyleSheet.create({
  pillNumberWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pillContainer: {
    display: 'flex',
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    marginTop: 10,
    height: 50,
  },
  dropInWrapper: {
    borderColor: voloGreys.medium,
    borderStyle: 'dotted',
  },
  rosterWrapper: {
    borderColor: Colors.accents.rsvpGreen,
    paddingVertical: 5,
  },
  gameRosterNumber: {
    fontWeight: 'bold',
    marginRight: 15,
    minWidth: 18,
    paddingVertical: 5,
    marginTop: 10,
  },
  rosterPictureContainer: {
    borderRadius: 50,
    marginLeft: 10,
    width: 30,
    height: 30,
    backgroundColor: voloGreys.medium,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  rosterPicture: {
    width: 30,
    height: 30,
  },
  userInitials: {
    color: Colors.primary,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 1,
  },
  label: {
    fontSize: 18,
  },
  labelContainer: {
    justifyContent: 'center',
    paddingLeft: 10,
  },
  subLabel: {
    fontSize: 12,
    lineHeight: 14,
  },
  dropInLabel: {
    color: voloGreys.medium,
  },
  sideLabelBackground: {
    minWidth: 80,
  },
  sideLabelContainer: {
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    minWidth: 50,
  },
  shareIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    width: 30,
    height: 30,
  },
  editDropInContainer: {
    flexDirection: 'row',
    paddingHorizontal: 15,
  },
  textLabel: {
    paddingHorizontal: 15,
    fontSize: 12,
    lineHeight: 16,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
  },
  timerSkeleton: {
    height: 13,
    width: 50,
  },
  holdText: {
    fontSize: 10,
    textAlign: 'center',
    lineHeight: 10,
  },
  overlay: {
    padding: 15,
    maxWidth: 800,
  },
});
