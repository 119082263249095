import {
  gql,
  useQuery,
  useSuspenseQuery,
  type QueryHookOptions,
  type SuspenseQueryHookOptions,
  type TypedDocumentNode,
} from '@apollo/client';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import type { GenderEnum_TS } from '@rivallapp/volosports-components';
import { RoleEnum } from '../shared/role-enum';

/**
 * This hook returns the current user signed in as well as the current organizationId
 * that is being used (from the admin drop down or url params)
 * It also does organization validation to make sure the user has authorization to view the
 * selected organization
 */

type CurrentUserData = {
  currentUser: {
    _id: string;
    roles: RoleEnum[];
    email: string;
    firstName: string;
    lastName: string;
    gender: GenderEnum_TS;
    homeOrganization: string | null;
    shouldSeePricesWithFees: boolean;
    organizationId: string | null | undefined;
    organizations: { _id: string; name: string }[];
    player_strikes: { count: number };
  };
};

const CURRENT_USER_QUERY: TypedDocumentNode<CurrentUserData> = gql`
  query currentUser {
    currentUser {
      _id
      roles
      email
      firstName
      lastName
      gender
      homeOrganization
      shouldSeePricesWithFees
      organizationId
      organizations {
        _id
        name
      }
      player_strikes {
        count
      }
    }
  }
`;

const useCurrentUserData = (data: CurrentUserData | undefined) => {
  const { location } = useHistory() ?? {};

  if (!data || !data?.currentUser) return { error: 'No logged in user found.' };

  const query = qs.parse(location?.search, { ignoreQueryPrefix: true });
  const { currentUser } = data ?? {};
  const { roles, organizationId: curUserOrganizationId, organizations = [] } = currentUser ?? {};

  let organizationId = curUserOrganizationId || organizations?.[0]?._id;
  const queryOrgId = (query?.organization || organizationId) as string | null;

  if (!queryOrgId || queryOrgId === organizationId) {
    return {
      currentUser: {
        ...currentUser,
        organizationId,
      },
      organizationId,
      isAuthenticated: !!currentUser,
      roles: currentUser.roles,
    };
  }

  // will add more checks as permissions grow
  const orgIds = organizations.map(({ _id }) => _id);
  // city admin
  if (roles.includes(RoleEnum.OWNER) && orgIds.includes(queryOrgId)) {
    organizationId = queryOrgId;
  }
  // super admin
  if (roles.includes(RoleEnum.ADMIN)) {
    organizationId = queryOrgId;
  }

  return {
    currentUser: {
      ...currentUser,
      organizationId,
    },
    isAuthenticated: !!currentUser,
    organizationId,
    roles,
  };
};

/**
 * @deprecated
 * Use `useCurrentUserV2` instead to query the current user against the hasura backend.
 * */
export const useCurrentUser = (options?: QueryHookOptions<CurrentUserData>) => {
  const { data, ...rest } = useQuery(CURRENT_USER_QUERY, options);

  const currentUserData = useCurrentUserData(data);

  return { ...rest, ...currentUserData };
};

/**
 * @deprecated
 * Use `useCurrentUserV2` instead to query the current user against the hasura backend.
 */
export const useSuspenseCurrentUser = (options?: SuspenseQueryHookOptions<CurrentUserData>) => {
  const { data, ...rest } = useSuspenseQuery(CURRENT_USER_QUERY, options);

  const currentUserData = useCurrentUserData(data);

  return { ...rest, ...currentUserData };
};

export default useCurrentUser;
