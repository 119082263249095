import GET_LEAGUE from './gql/GET_LEAGUE';
import { ProgramTypeEnum } from '../constants';

/**
 * @description Global location for League query. Variations can be created to test different types of programs.
 * Try to add fields to existing variants before creating a brand new one.
 * Adding fields shouldn't affect existing uses, but don't delete unless you're **sure** the fields are unused.
 * Add notes when updating this doc.
 */

/** Returns details for league id:'66678de1dc46767304de76d3'
 * Fields align with GET_PROGRAM_DETAILS
 */
export const getLeague = [
  {
    request: {
      query: GET_LEAGUE,
      variables: {
        id: '66678de1dc46767304de76d3',
      },
    },
    result: {
      data: {
        league: {
          _id: '66678de1dc46767304de76d3',
          displayName: 'Monday, Tuesday, Wednesday, Thursday - Food - Locust Point',
          programType: ProgramTypeEnum.LEAGUE,
          start_date: '2024-08-10T16:00:00.000Z',
          price_phase: 'regular',
          banner_text: 'Nom nom nom',
          deepLink: 'https://links.volosports.com/iEymRmk2NahGGPwKA',
          header:
            'https://s3.amazonaws.com/rivall-user-uploads/stagingUploads/leagueHeader/66678de1dc46767304de76d3_icon.jpeg.jpeg',
          photoUrl:
            'https://s3.amazonaws.com/rivall-user-uploads/stagingUploads/leagueHeader/66678de1dc46767304de76d3_icon.jpeg.jpeg',
          start_time_estimate: '18:00',
          end_time_estimate: '21:00',
          days_of_week_estimate: ['M', 'T', 'W', 'Th'],
          num_weeks_estimate: 8,
          num_playoff_weeks_estimate: 0,
          registrantCount: 0,
          is_premier: false,
          is_volo_pass_exclusive: false,
          registration: {
            _id: '66678de1dc46767304de76d0',
            minSize: 6,
            maxSize: 20,
          },
          neighborhood: {
            _id: '64bff3f4122e40d71656d770',
            name: 'Locust Point',
          },
          venue: {
            _id: '5f29b2bc815592005f2b94d1',
            name: 'McHenry Row',
            formatted_address: 'McHenry Row, Baltimore, MD 21230, USA',
            timezone: 'America/New_York',
          },
          organization: {
            _id: '5cd91a7033db650050758e2f',
            voloPassTiers: {
              isActive: true,
            },
            voloFitnessTiers: {
              isActive: true,
            },
          },
        },
      },
    },
  },
];

/** Returns details for series id:'669580f363ace57f0226b4e1'
 * Fields align with GET_PROGRAM_DETAILS
 * Volo Fitness enabled
 */
export const getSeries = [
  {
    request: {
      query: GET_LEAGUE,
      variables: {
        id: '669580f363ace57f0226b4e1',
      },
    },
    result: {
      data: {
        league: {
          _id: '669580f363ace57f0226b4e1',
          displayName: 'Thursday - Yoga - National Mall',
          programType: ProgramTypeEnum.SERIES,
          start_date: '2024-10-31T16:00:00.000Z',
          price_phase: 'regular',
          banner_text: null,
          deepLink: 'https://links.volosports.com/GowmCjonycM7B7L38',
          header:
            'https://s3.amazonaws.com/rivall-user-uploads/stagingUploads/leagueHeader/669580f363ace57f0226b4e1_icon.jpeg.jpeg',
          photoUrl:
            'https://s3.amazonaws.com/rivall-user-uploads/stagingUploads/leagueHeader/669580f363ace57f0226b4e1_icon.jpeg.jpeg',
          start_time_estimate: '18:00',
          end_time_estimate: '21:00',
          days_of_week_estimate: ['Th'],
          num_weeks_estimate: 8,
          num_playoff_weeks_estimate: 0,
          registrantCount: 0,
          is_premier: false,
          is_volo_pass_exclusive: false,
          registration: {
            _id: '669580f363ace57f0226b4de',
            minSize: 6,
            maxSize: 20,
          },
          neighborhood: {
            _id: '60da53052e620b00543df5b6',
            name: 'National Mall',
          },
          venue: {
            _id: '5f1855182447cb005f79b3c9',
            name: 'National Mall - North',
            formatted_address: '400 E St SW, Washington, DC 20024, USA',
            timezone: 'America/New_York',
          },
          organization: {
            _id: '5cd91a7033db650050758e2f',
            voloPassTiers: {
              isActive: true,
            },
            voloFitnessTiers: {
              isActive: true,
            },
          },
        },
      },
    },
  },
];

/** Returns details for pickup id:'663d83350f484b9741081baa'
 * Fields align with GET_PROGRAM_DETAILS
 */
export const getPickup = [
  {
    request: {
      query: GET_LEAGUE,
      variables: {
        id: '663d83350f484b9741081baa',
      },
    },
    result: {
      data: {
        league: {
          _id: '663d83350f484b9741081baa',
          displayName: 'Soccer Pickup - Open',
          programType: ProgramTypeEnum.PICKUP,
          start_date: '2024-07-26T19:00:00.000Z',
          price_phase: 'regular',
          banner_text: null,
          deepLink: 'https://links.volosports.com/bRVcahoT9jUEaRug9',
          header:
            'https://s3.amazonaws.com/rivall-user-uploads/stagingUploads/leagueHeader/663d82710f484b9741081a7f_icon.jpeg.jpeg',
          photoUrl:
            'https://s3.amazonaws.com/rivall-user-uploads/stagingUploads/leagueHeader/663d82710f484b9741081a7f_icon.jpeg.jpeg',
          start_time_estimate: '17:00',
          end_time_estimate: '18:00',
          days_of_week_estimate: ['F'],
          num_weeks_estimate: 1,
          num_playoff_weeks_estimate: 0,
          registrantCount: 2,
          is_premier: false,
          is_volo_pass_exclusive: false,
          neighborhood: {
            _id: '60804745dbc0a70032f470ac',
            name: 'North Beach',
          },
          venue: {
            _id: '6080bb4080e3520032f80a7f',
            name: 'Joe DiMaggio Playground',
            formatted_address: '651 Lombard St, San Francisco, CA 94133, USA',
            timezone: 'America/Los_Angeles',
          },
          organization: {
            _id: '5cd91a7033db650050758e2f',
            voloPassTiers: {
              isActive: true,
            },
            voloFitnessTiers: {
              isActive: true,
            },
          },
          registration: {
            _id: '663d83350f484b9741081b8a',
            minSize: 6,
            maxSize: 30,
          },
        },
      },
    },
  },
];

/** Returns details for class id:'6697e4dfc7ff057d8dc1ebf2'
 * Fields align with GET_PROGRAM_DETAILS
 * Volo Fitness enabled
 */
export const getClass = [
  {
    request: {
      query: GET_LEAGUE,
      variables: {
        id: '6697e4dfc7ff057d8dc1ebf2',
      },
    },
    result: {
      data: {
        league: {
          _id: '6697e4dfc7ff057d8dc1ebf2',
          displayName: 'Bootcamp - Class',
          programType: ProgramTypeEnum.CLASS,
          start_date: '2024-10-27T16:00:00.000Z',
          price_phase: 'regular',
          banner_text: null,
          deepLink: 'https://links.volosports.com/tpmNZRGXPMzzPuye9',
          header:
            'https://s3.amazonaws.com/rivall-user-uploads/stagingUploads/leagueHeader/6697e4dfc7ff057d8dc1ebf2_icon.gif.gif',
          photoUrl:
            'https://s3.amazonaws.com/rivall-user-uploads/stagingUploads/leagueHeader/6697e4dfc7ff057d8dc1ebf2_icon.gif.gif',
          start_time_estimate: '17:30',
          end_time_estimate: '19:00',
          days_of_week_estimate: ['Su'],
          num_weeks_estimate: 1,
          num_playoff_weeks_estimate: 0,
          registrantCount: 0,
          is_premier: false,
          is_volo_pass_exclusive: false,
          neighborhood: {
            _id: '60da53052e620b00543df5b6',
            name: 'National Mall',
          },
          venue: {
            _id: '5f1854d12447cb005f79b3b5',
            name: 'National Mall South',
            formatted_address: 'Washington, DC, USA',
            timezone: 'America/New_York',
          },
          organization: {
            _id: '5cd91a7033db650050758e2f',
            voloPassTiers: {
              isActive: true,
            },
            voloFitnessTiers: {
              isActive: true,
            },
          },
          registration: {
            _id: '6697e4dfc7ff057d8dc1ebef',
            minSize: 4,
            maxSize: 15,
          },
        },
      },
    },
  },
];
