import { type Theme } from 'react-native-calendars/src/types';

import { useTheme } from '../../theme';

const useCalendarTheme = (): Theme => {
  const { colors } = useTheme();

  return {
    calendarBackground: String(colors.card),
    monthTextColor: String(colors.text),
    selectedDayTextColor: String(colors.text),
    dayTextColor: String(colors.text),
    textDisabledColor: String(colors.border),
    textSectionTitleColor: String(colors.text),
  };
};

export default useCalendarTheme;
