import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface VenueProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  venue_response: boolean;
  venueOverviewResponses?: string[];
  lightsOverviewResponses?: string[];
  venueComments?: string;
  venueAttachments?: { fileName: string; onPress: () => void }[]; // may change when we add an uploader
}

const Venue: React.FC<VenueProps> = ({
  venue_response = false,
  venueOverviewResponses = [],
  lightsOverviewResponses = [],
  venueComments,
  venueAttachments = [],
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Venue"
      view="read"
      toggleQuestion="Venue, Field, or Court Issues?"
      toggleResponse={venue_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.MultiSelect
          label="Venue Overview:"
          selectedResponses={venueOverviewResponses}
        />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.MultiSelect
          label="Lights Overview:"
          selectedResponses={lightsOverviewResponses}
        />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Comments/Notes:" response={venueComments} />
      </Box>
      {venueAttachments.length ? (
        <Box paddingTop={5}>
          {venueAttachments.map(attachment => (
            <CollapseForm.FileUpload
              key={attachment.fileName}
              fileName={attachment.fileName}
              onPress={attachment.onPress}
            />
          ))}
        </Box>
      ) : null}
      {children}
    </CollapseForm>
  );
};

export default Venue;
