import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgSponsoredPerks = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path fill="#103b5d" stroke="#fff" d="M18.5 30.5h42v11h-42zM20.5 41.5h38v20h-38z" />
    <Path stroke="#fff" d="M39.5 30v31" />
    <Path
      stroke="#103b5d"
      d="M44.783 19.24C41.594 21.559 40.266 27.38 40 30c3.188-.414 9.074-1.178 11.16-4.552 1.54-2.492.627-4.609-.093-5.73-.906-1.411-3.096-2.795-6.284-.477ZM34.217 19.24C37.406 21.559 38.734 27.38 39 30c-3.188-.414-9.074-1.178-11.16-4.552-1.54-2.492-.627-4.609.093-5.73.906-1.411 3.096-2.795 6.284-.477Z"
    />
  </Svg>
);
export default SvgSponsoredPerks;
