import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgBogoPitchers = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      d="M43.8 40.62h-2.415v2.43q0 .345-.33.345H39.93q-.345 0-.345-.345v-2.43h-2.4q-.345 0-.345-.33v-.93q0-.345.345-.345h2.4v-2.4q0-.345.345-.345h1.125q.33 0 .33.345v2.4H43.8q.345 0 .345.345v.93q0 .33-.345.33"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M47.407 28.5h19.858V56a4.5 4.5 0 0 1-4.5 4.5H51.906a4.5 4.5 0 0 1-4.5-4.5z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="M48.807 21.69c-3.99 1.6-3.071 6.31-1.257 6.81h4.342v7c0 3 3.02 3.5 3.108.5v-7.5h12.5c2.721-3 .454-7.31-2.823-7.31-.363-1.6-2.225-2-3.174-1.5-2.176-2.8-5.139-1.5-6.348-.5-3.627-1.6-5.744 1-6.348 2.5Z"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M67.547 32.5h2.662a4.5 4.5 0 0 1 4.5 4.5v6a4.5 4.5 0 0 1-4.5 4.5h-2.662z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="M67.547 34.5h2.848a2.5 2.5 0 0 1 2.5 2.5v6a2.5 2.5 0 0 1-2.5 2.5h-2.848z"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M33.802 28.5H13.945V56a4.5 4.5 0 0 0 4.5 4.5h10.857a4.5 4.5 0 0 0 4.5-4.5z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="M32.402 21.69c3.99 1.6 3.071 6.31 1.257 6.81h-4.342v7c0 3-3.228 3.5-3.317.5v-7.5H13.708c-2.72-3-.453-7.31 2.824-7.31.363-1.6 2.225-2 3.174-1.5 2.177-2.8 5.139-1.5 6.348-.5 3.628-1.6 5.744 1 6.348 2.5Z"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M13.662 32.5H11A4.5 4.5 0 0 0 6.5 37v6a4.5 4.5 0 0 0 4.5 4.5h2.662z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="M13.662 34.5h-2.848a2.5 2.5 0 0 0-2.5 2.5v6a2.5 2.5 0 0 0 2.5 2.5h2.848z"
    />
  </Svg>
);
export default SvgBogoPitchers;
