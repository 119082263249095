import openLink from '../../../../utilities/openLink';
import { CollapsibleBase } from '../../../../custom-components';
import { CustomChevron } from '../RentalCollapsible/RentalCollapsible';

const FAQS_URL = 'https://www.volosports.com/faq';

/**
 * This is designed to look like the other collapsibles on the Rental Details page,
 * but it's just a link to the FAQs.
 */
const RentalFAQs = () => {
  return (
    <CollapsibleBase
      title="FAQs"
      onPress={() => openLink(FAQS_URL)}
      isCollapsed
      CustomChevron={CustomChevron}
      _pressableProps={{ marginLeft: 0 }}
    />
  );
};
export default RentalFAQs;
