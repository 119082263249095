import { useColorMode, useTheme as useNBTheme } from '@rivallapp/native-base';

import { LightTheme, DarkTheme } from './Colors';
import { type NavTheme } from './types';

// todo: update settings to allow for theme switching without matching the device color mode
// - will come soon when settings page is ported over

/**
 * Custom hook that listens for the colorMode changes from native-base and updates the theme accordingly for react-navigation & app
 * - This works in conjunction with the `ThemeChangeListener` component that listens to the `colorScheme` changes our `useColorScheme` hook.
 * - Now all we have to do is listen to NB's colorMode changes and update the theme accordingly
 * @returns {NavTheme}
 */
const useTheme = (): NavTheme => {
  const { colorMode } = useColorMode();

  const {
    config: { enableTheming },
  } = useNBTheme();

  const Theme = colorMode === 'dark' ? DarkTheme : LightTheme;

  return enableTheming ? Theme : LightTheme;
};

export default useTheme;
