import { gql, useQuery, type TypedDocumentNode } from '@apollo/client';
import { useEffect, useState } from 'react';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons/faCirclePlus';

import {
  Button,
  FontAwesomeIcon,
  Modal,
  Pressable,
  Spinner,
  Stack,
  Text,
  TextInput,
  View,
} from '../../base-components';
import { Colors, useTheme } from '../../theme';
import { centsToDollarsFormatter, removeNonNumerics, toInt } from './helpers';
import { isMobile } from '../../utilities';

export interface UserCreditsData {
  currentUser: {
    _id: string;
    availableCredits?: {
      amount: number;
      used: number;
    }[];
  };
}

export const GET_USER_CREDITS: TypedDocumentNode<UserCreditsData> = gql`
  query CurrentUser {
    currentUser {
      _id
      availableCredits {
        amount
        used
      }
    }
  }
`;

const UserCreditsModal = ({
  maxCreditCents,
  appliedCreditCents,
  onApplyCredits,
}: {
  maxCreditCents: number;
  appliedCreditCents: number;
  onApplyCredits: (v: number) => void;
}) => {
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [innerCreditCents, setInnerCreditCents] = useState(appliedCreditCents.toString());

  // if applied credits change from external source, sync the inner credit state
  // mainly used for clearing applied credits in the parent component
  useEffect(
    function syncExternalCredits() {
      setInnerCreditCents(appliedCreditCents.toString());
    },
    [appliedCreditCents]
  );

  const { data, loading, error } = useQuery(GET_USER_CREDITS);
  const userCreditCentsAvailable =
    data?.currentUser?.availableCredits?.reduce((acc, { amount }) => acc + amount, 0) || 0;
  const maxUserCreditCentsAvailable = Math.min(maxCreditCents, userCreditCentsAvailable);

  const _creditsDisabled = Boolean(
    maxCreditCents <= 0 || !userCreditCentsAvailable || loading || error
  );
  const getAppliedCreditsLabel = () =>
    appliedCreditCents
      ? `Edit Applied Credit - ${centsToDollarsFormatter(appliedCreditCents)}`
      : 'Apply Credit';

  const disableApplyButton = () => {
    const creditCents = toInt(innerCreditCents);

    return (
      userCreditCentsAvailable <= 0 ||
      creditCents <= 0 ||
      creditCents > maxCreditCents ||
      creditCents > userCreditCentsAvailable ||
      creditCents === appliedCreditCents
    );
  };

  return (
    <>
      <Pressable
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        onPress={() => setIsOpen(true)}
        isDisabled={_creditsDisabled}
        px={2}
        py={1}
      >
        <Text isLink isDisabled={_creditsDisabled} fontSize={13}>
          {error ? "Error - Can't apply credits at this time" : getAppliedCreditsLabel()}
        </Text>
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <FontAwesomeIcon
            icon={faCirclePlus}
            size={4}
            color={_creditsDisabled ? colors.border : Colors.link}
          />
        )}
      </Pressable>
      <Modal
        useRNModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={isMobile ? undefined : 'lg'}
      >
        <Modal.KeyboardAwareContent>
          <Modal.Header>Apply Credits</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <Stack space={2} mb={2}>
              <Text fontSize="sm">
                You have <Text bold>{centsToDollarsFormatter(userCreditCentsAvailable)}</Text>{' '}
                available in Volo credit.
              </Text>
              <Text fontSize="sm">
                Volo credits are only applied to the program price and cannot be used to pay for any
                additional add-ons.
              </Text>
              <Text fontSize="sm">
                You may apply up to{' '}
                <Text bold>{centsToDollarsFormatter(maxUserCreditCentsAvailable)}</Text> towards the
                remaining <Text bold>{centsToDollarsFormatter(maxCreditCents)}</Text> program price.
              </Text>
            </Stack>
            <Stack space={2}>
              <View style={{ height: 45 }}>
                <TextInput
                  placeholder="Enter credit amount"
                  value={toInt(innerCreditCents) ? centsToDollarsFormatter(innerCreditCents) : ''}
                  onChangeText={v => setInnerCreditCents(removeNonNumerics(v))}
                  keyboardType="numeric"
                />
              </View>
              <Button.FooterGroup>
                <Button.ClearCancel
                  onPress={() => {
                    if (appliedCreditCents) {
                      // clear applied credits if there are any
                      onApplyCredits(0);
                      return;
                    }

                    // just close the modal if cancel is pressed
                    setIsOpen(false);
                  }}
                >
                  {appliedCreditCents ? 'Clear' : 'Cancel'}
                </Button.ClearCancel>
                <Button.Apply
                  onPress={() => {
                    onApplyCredits(toInt(innerCreditCents));
                    setIsOpen(false);
                  }}
                  isDisabled={disableApplyButton()}
                >
                  Apply
                </Button.Apply>
              </Button.FooterGroup>
            </Stack>
          </Modal.Body>
        </Modal.KeyboardAwareContent>
      </Modal>
    </>
  );
};

export default UserCreditsModal;
