import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgVoloExclusiveBarDeals = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#000" />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M26.821 31.431h24.143v24.015a4.5 4.5 0 0 1-4.5 4.5H31.32a4.5 4.5 0 0 1-4.5-4.5z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="M28.384 24.034c-4.81 1.749-3.702 6.897-1.516 7.444H32V39c0 3.28 3.893 3.28 4 0v-7.522h14.917c3.28-3.28.547-7.99-3.404-7.99-.437-1.75-2.681-2.187-3.825-1.64-2.624-3.061-6.195-1.64-7.653-.547-4.372-1.749-6.923 1.093-7.651 2.733Z"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M50.87 35.804h4.339a4.5 4.5 0 0 1 4.5 4.5v7.49a4.5 4.5 0 0 1-4.5 4.5H50.87z"
    />
    <Path
      fill="#fff"
      stroke="#103b5d"
      d="M50.87 37.99h4.153a2.5 2.5 0 0 1 2.5 2.5v7.118a2.5 2.5 0 0 1-2.5 2.5H50.87z"
    />
    <Path
      fill="#fff"
      d="m45.147 44.21-4.18-.355-1.66-3.534c-.116-.248-.498-.248-.615 0l-1.66 3.534-4.18.355c-.292.025-.41.358-.19.536l3.154 2.54-.923 3.753c-.064.263.245.47.498.332L39 49.406l3.609 1.965c.253.137.562-.07.498-.332l-.923-3.753 3.153-2.54c.221-.178.103-.511-.19-.536"
    />
  </Svg>
);
export default SvgVoloExclusiveBarDeals;
