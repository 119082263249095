import { type IIconProps } from '@rivallapp/native-base';
import { type LogoColorVariant } from '../../theme';

/** Props used to standardize Volo logos */
export type VoloLogoProps = Omit<IIconProps, 'colorScheme' | 'color'> & {
  /**
   * The color variant to use for the icon. In order to enforce known colors for the logo, only
   * "white" and "primary" are provided. The default value is `'primary'`. If a different value is
   * provided, the icon will fall back to the `primary` color.
   */
  colorScheme?: LogoColorVariant | ({} & string);
};

export const DEFAULT_COLOR = '#0E3355';
