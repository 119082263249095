import { gql, type TypedDocumentNode } from '@apollo/client';
import type { DropInSlotGenderEnum_TS } from '../../../constants';

type DropInSlotData = {
  dropInSlot: {
    _id: string;
    gameId: string;
    teamId: string;
    gender: DropInSlotGenderEnum_TS;
    holdExpiration?: Date;
    note?: string;
    isRecurring: boolean;
    isRecurringEntireSeason: boolean;
    upcomingGameCount: number;
    recurringCount: number;
  };
};

type DropInSlotVars = {
  id: string;
};

export const DROP_IN_SLOT: TypedDocumentNode<DropInSlotData, DropInSlotVars> = gql`
  query DropInSlot($id: ID!) {
    dropInSlot(_id: $id) {
      _id
      gameId
      teamId
      gender
      holdExpiration
      note
      isRecurring
      isRecurringEntireSeason
      upcomingGameCount
      recurringCount
    }
  }
`;

export const OPEN_SLOT_BY_GENDER = gql`
  mutation OpenDropInSlot($input: OpenDropInSlotInput!) {
    openDropInSlot(input: $input) {
      _id
      holdExpiration
      gender
      note
    }
  }
`;

interface CloseDropInData {
  closeDropInSlotById: {
    _id: string;
  };
}

interface CloseDropInVars {
  id: string;
}

export const CLOSE_SLOT_BY_ID: TypedDocumentNode<CloseDropInData, CloseDropInVars> = gql`
  mutation CloseDropInSlotById($id: ID!) {
    closeDropInSlotById(_id: $id) {
      _id
    }
  }
`;

interface CloseRecurringDropInData {
  closeRecurringDropInSlots: {
    _id: string;
  };
}

export const CLOSE_RECURRING_DROP_IN_SLOTS: TypedDocumentNode<
  CloseRecurringDropInData,
  CloseDropInVars
> = gql`
  mutation CloseRecurringDropInSlots($id: ID!) {
    closeRecurringDropInSlots(dropInSlotId: $id) {
      _id
    }
  }
`;

interface UpdateDropInSlotData {
  updateDropInSlot: {
    _id: string;
    holdExpiration?: Date;
    gender: DropInSlotGenderEnum_TS;
    note?: string;
  };
}

interface UpdateDropInSlotVars {
  input: {
    _id: string;
    gender?: DropInSlotGenderEnum_TS;
    note?: string;
    isRecurring?: boolean;
    gameCount?: number;
    entireSeason?: boolean;
  };
}

export const UPDATE_DROP_IN_SLOT: TypedDocumentNode<UpdateDropInSlotData, UpdateDropInSlotVars> =
  gql`
    mutation UpdateDropInSlot($input: UpdateDropInSlotInput!) {
      updateDropInSlot(input: $input) {
        _id
        holdExpiration
        gender
        note
      }
    }
  `;
