import { gql, type TypedDocumentNode } from '@apollo/client';
import { ProgramTypeEnum } from '../../../../constants';

export type ProgramDetailsResponse = {
  league: {
    _id: string;
    displayName: string;
    programType: ProgramTypeEnum;
    start_date: Date;
    banner_text: string;
    photoUrl: string;
    start_time_estimate: string; // HH:mm
    end_time_estimate: string; // HH:mm
    num_weeks_estimate: number;
    num_playoff_weeks_estimate: number;
    registrantCount: number;
    is_volo_pass_exclusive: boolean;
    is_premier: boolean;
    registration: {
      _id: string;
      minSize: number;
      maxSize: number;
    };
    neighborhood: {
      _id: string;
      name: string;
    };
    venue: {
      _id: string;
      name: string;
      formatted_address: string;
    };
    organization: {
      _id: string;
      voloPassTiers: {
        isActive: boolean;
      };
      voloFitnessTiers: {
        isActive: boolean;
      };
    };
  };
};

export type ProgramDetailsInput = {
  id: string;
};

const GET_PROGRAM_DETAILS: TypedDocumentNode<ProgramDetailsResponse, ProgramDetailsInput> = gql`
  query League($id: ID!) {
    league(_id: $id) {
      _id
      displayName
      programType
      start_date
      banner_text
      photoUrl
      start_time_estimate
      end_time_estimate
      num_weeks_estimate
      num_playoff_weeks_estimate
      registrantCount
      is_volo_pass_exclusive
      is_premier
      registration {
        _id
        minSize
        maxSize
      }
      neighborhood {
        _id
        name
      }
      venue {
        _id
        name
        formatted_address
      }
      organization {
        _id
        voloPassTiers {
          isActive
        }
        voloFitnessTiers {
          isActive
        }
      }
    }
  }
`;

export default GET_PROGRAM_DETAILS;
