import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';

import { useRentalBookingStore } from '../../../store';
import { HaloChip, ModalAlert, ModalAlertComponent } from '../../../../../custom-components';
import type { HaloChipPressableProps } from '../../../../../custom-components/HaloChip/HaloChip';

interface RentalDeleteChipProps {
  /**
   * bookingId to delete
   */
  bookingId?: string;
  /**
   * Callback to run after deleting the booking
   * - can close the modal or update the UI, etc
   */
  onDeleteBookingCompleted?: () => void;
  /**
   * Callback to run if there is an error deleting the booking
   * - if not provided, a default error alert will be shown
   */
  onDeleteBookingError?: () => void;
}

const RentalDeleteChip = ({
  bookingId,
  onDeleteBookingCompleted,
  onDeleteBookingError,
  ...props
}: RentalDeleteChipProps & Omit<HaloChipPressableProps, 'onPress' | 'variant'>) => {
  const { deleteBooking } = useRentalBookingStore.getState().actions;

  return (
    <>
      <HaloChip.Pressable
        {...props}
        variant="error"
        onPress={() => {
          ModalAlert.alert({
            id: 'delete-booking-alert',
            title: 'Delete Booking',
            message: 'Are you sure you want to delete this booking? This action cannot be undone.',
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                onPress: () => {
                  deleteBooking({
                    bookingId,
                    onCompleted: onDeleteBookingCompleted,
                    onError: () => {
                      if (onDeleteBookingError) {
                        onDeleteBookingError();
                      } else {
                        ModalAlert.alert({
                          id: 'no-booking-to-delete-alert',
                          title: 'Error Deleting Booking',
                          message: 'No booking was found to delete.',
                          buttons: [{ text: 'OK' }],
                        });
                      }
                    },
                  });
                },
              },
            ],
          });
        }}
      >
        <HaloChip.Icon icon={faTrash} />
      </HaloChip.Pressable>
      <ModalAlertComponent id="delete-booking-alert" />
      <ModalAlertComponent id="no-booking-to-delete-alert" />
    </>
  );
};

export default RentalDeleteChip;
