import { type ComponentProps, type ReactNode } from 'react';

import { useTheme, Colors } from '../../theme';
import { Modal, Button, View } from '../../base-components';

/** Standardized format for our Confirm/Cancel, Yes/No user prompts */

export interface ConfirmCancelModalProps extends ComponentProps<typeof Modal> {
  headerText: string;
  /** can be just a string or a fully formatted component */
  children: ReactNode;
  onConfirm?: () => void;
  onClose: () => void;
  /** @default false */
  isLoading?: boolean;
  /** @default 'Confirm' */
  confirmText?: string;
  /** @default 'Cancel' */
  cancelText?: string;
  /** @default false */
  isVertical?: boolean;
  /** @default false */
  hideConfirm?: boolean;
  /** @default false */
  hideCancel?: boolean;
  /** @default false */
  confirmDisabled?: boolean;
}

const ConfirmCancelModal = ({
  headerText,
  children,
  onConfirm,
  onClose,
  isLoading,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  isVertical = false,
  hideConfirm = false,
  hideCancel = false,
  confirmDisabled = false,
  ...props
}: ConfirmCancelModalProps) => {
  const { colors } = useTheme();

  return (
    <Modal useRNModal size="lg" onClose={onClose} {...props}>
      <Modal.Content>
        <Modal.Header borderBottomWidth={0}>{headerText}</Modal.Header>
        {!hideCancel && <Modal.CloseButton />}
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer borderTopWidth={0}>
          {/* This View is to fix some weird mobile defaults */}
          <View
            flex={1}
            minHeight={isVertical ? 100 : 1}
            flexDirection={isVertical ? 'column' : 'row'}
          >
            {!hideConfirm && onConfirm && (
              <Button
                padding={0} // remove NB default
                margin={0} // remove NB default
                flex={1}
                marginLeft={isVertical ? 6 : 0}
                marginRight={isVertical ? 6 : 12}
                marginBottom={isVertical ? 1 : 0}
                {...(hideCancel ? { marginX: 'auto' } : {})}
                paddingTop={2}
                paddingBottom={2}
                onPress={onConfirm}
                isLoading={isLoading}
                isDisabled={confirmDisabled}
              >
                {confirmText}
              </Button>
            )}
            {!hideCancel && (
              <Button
                padding={0} // remove NB default
                margin={0} // remove NB default
                flex={1}
                variant="outline"
                borderColor={colors.primary}
                borderRadius={8}
                _text={{
                  fontWeight: 700,
                  fontSize: 18,
                }}
                _dark={{
                  _text: {
                    color: Colors.link,
                  },
                }}
                paddingTop={2}
                paddingBottom={2}
                marginLeft={isVertical ? 6 : 12}
                marginRight={isVertical ? 6 : 0}
                marginTop={isVertical ? 1 : 0}
                {...(hideConfirm ? { marginX: 'auto' } : {})}
                onPress={onClose}
                isDisabled={isLoading}
              >
                {cancelText}
              </Button>
            )}
          </View>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmCancelModal;
