import { type ExpandableCheckBoxTreeProps } from '../ExpandableCheckboxTree';

const getMainTabsMap = (tabsArray: ExpandableCheckBoxTreeProps[]) => {
  // TODO: A more specific type here might be better
  const tabMap: Record<string, boolean> = {};
  tabsArray.forEach(tab => {
    if (tab.isMain) {
      // adds to map
      tabMap[tab.value] = false;
    }
  });
  return tabMap;
};

export default getMainTabsMap;
