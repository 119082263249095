import { type IIconProps } from '@rivallapp/native-base';
import { Path } from 'react-native-svg';
import { Icon } from '../../../base-components';

type VoloPassYellowBadgeProps = Omit<IIconProps, 'colorScheme' | 'color'>;

const VoloPassYellowBadge: React.FC<VoloPassYellowBadgeProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 48 45">
      <Path
        fill="#EAFF70"
        d="M30.82 15.44 35.44 2.16h-7.76l-2.24 6.44 2.38 6.842h3ZM44.6 16.48H28.16l1.12 3.241.28.8.06.14h1.16v1.04H17.18v-1.04h2.44l-1.46-4.18H3.38c-1.3 0-2.38 1.06-2.38 2.38v22.384c0 1.3 1.06 2.38 2.38 2.38h41.16c1.3 0 2.38-1.06 2.38-2.38V18.86c.06-1.32-1-2.38-2.32-2.38Z"
      />
      <Path
        fill="#EAFF70"
        d="m19.14 15.958.18.52 1.14 3.241.26.78.06.16H28.54l-1.46-4.18-.18-.52-.18-.52-1.84-5.262-.28-.8-.26-.78-2.64-7.561h-7.76l5.02 14.402.18.52Z"
      />
      <Path
        fill="#0A3355"
        d="M44.6 15.443H31.92l5-14.323h-9.98l-2.06 5.881L22.44 0h-9.98l5.38 15.423H3.42c-1.88 0-3.42 1.54-3.42 3.42v22.384c0 1.88 1.54 3.42 3.42 3.42h41.16c1.88 0 3.42-1.54 3.42-3.42V18.843c.02-1.88-1.52-3.4-3.4-3.4Zm2.38 25.804c0 1.3-1.06 2.38-2.38 2.38H3.44c-1.3 0-2.38-1.06-2.38-2.38V18.863c0-1.3 1.06-2.38 2.38-2.38h14.78l1.46 4.18h-2.44v1.04h13.6v-1.04h-1.16l-.06-.14-.28-.8-1.12-3.24h16.44c1.3 0 2.38 1.06 2.38 2.38v22.384h-.06ZM21.7 1.04l2.64 7.541.28.8.28.8 1.84 5.262.18.52.18.52 1.46 4.18h-7.78l-.06-.14-.28-.8-1.12-3.24-.18-.52-.18-.52L13.94 1.06h7.76v-.02Zm13.74 1.12L30.8 15.423h-2.98L25.44 8.58l2.24-6.42h7.76Z"
      />
      <Path
        fill="#0A3355"
        d="M19.88 26.28c-.26-.321-.54-.621-.84-.921-.14-.14-.28-.26-.42-.38a9.323 9.323 0 0 0-6.08-2.24c-1.3 0-2.52.26-3.66.74a9.409 9.409 0 0 0-5.74 8.661c0 5.18 4.22 9.402 9.4 9.402 1.92 0 3.7-.58 5.18-1.56 2.54-1.68 4.22-4.561 4.22-7.822 0-1.98-.62-3.82-1.66-5.34-.14-.2-.28-.38-.4-.54Zm-7.34 7.42c-1.44 0-2.6-1.16-2.6-2.6 0-1.44 1.18-2.6 2.6-2.6 1.42 0 2.6 1.16 2.6 2.6.02 1.42-1.16 2.6-2.6 2.6Zm2.88 6.261c-.9.34-1.86.52-2.88.52-1.02 0-1.98-.2-2.88-.52-.26-.1-.52-.2-.78-.34a8.49 8.49 0 0 1-1.98-1.36l-.1-.1c.54-.92 1.26-1.7 2.08-2.28 1.08-.74 2.34-1.16 3.66-1.16 2.34 0 4.5 1.32 5.74 3.44l-.1.1c-.8.74-1.72 1.32-2.76 1.7Zm5.46-7.84c0 2-.7 3.82-1.88 5.26-1.04-1.64-2.58-2.82-4.32-3.36.9-.66 1.5-1.72 1.5-2.941 0-2.02-1.64-3.66-3.66-3.66-2.02 0-3.66 1.64-3.66 3.66 0 1.2.6 2.26 1.5 2.94-.52.16-1.02.38-1.5.66-1.12.64-2.1 1.56-2.82 2.7-1.16-1.44-1.88-3.26-1.88-5.26 0-3.3 1.92-6.14 4.7-7.501 1.1-.54 2.34-.86 3.66-.86 2.2 0 4.2.86 5.7 2.26.26.24.5.48.72.76a8.294 8.294 0 0 1 1.94 5.341ZM32.2 33.265l2.52-7.92c.04-.16.04-.301-.02-.381-.06-.1-.18-.14-.32-.14h-1.92c-.3 0-.48.14-.56.42l-.92 3.64-.42 2.001h-.04l-.42-2-.8-3.16-.12-.46c0-.021-.02-.041-.04-.061-.08-.22-.24-.34-.5-.36H26.7c-.14 0-.24.04-.32.14-.04.06-.06.12-.06.2 0 .06 0 .12.02.18l.1.34.02.08.16.52.16.52 2.06 6.481c.1.26.28.4.54.4h2.22c.32-.04.5-.16.6-.44ZM41.98 30.564c.62-.5.94-1.34.94-2.5s-.32-1.98-.94-2.5c-.64-.5-1.58-.76-2.82-.76h-3.14c-.3 0-.46.14-.46.46v7.96c0 .3.14.46.46.46h1.8c.3 0 .46-.16.46-.46v-1.9h.88c1.24 0 2.18-.26 2.82-.76Zm-3.72-1.5v-2h.86c.38 0 .66.08.82.22.16.16.26.42.26.78 0 .36-.08.62-.26.76-.16.16-.44.24-.82.24h-.86ZM28.34 37.223l-.82-1.66-.82 1.66-1.84.26 1.32 1.3-.2 1.18-.1.64 1.24-.64.4-.22.4.22 1.24.64-.12-.64-.2-1.18 1.32-1.3-1.82-.26ZM35.44 37.223l-.82-1.66-.82 1.66-1.84.26 1.34 1.3-.2 1.18-.12.64 1.24-.64.4-.22.4.22 1.24.64-.1-.64-.2-1.18 1.1-1.08.22-.22-.22-.02-1.62-.24ZM42.56 37.223l-.82-1.66-.82 1.66-1.84.26 1.32 1.3-.3 1.82 1.64-.86 1.64.86-.32-1.82 1.34-1.3-1.84-.26Z"
      />
    </Icon>
  );
};

export default VoloPassYellowBadge;
