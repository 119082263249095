import React, { useCallback } from 'react';

import { View } from '../../../base-components';
import { CustomButton } from '../../../custom-components';
import { ProgramGenders } from '../../../constants/enums/gender-enum';
import { upperFirst } from '../../../utilities';
import styles from '../styles';

export type GenderFilterType = {
  onPress: (id: string) => void | undefined;
  selectedGenders: string[];
};

const programGenders = Object.keys(ProgramGenders);

const GenderFilter = ({ onPress, selectedGenders = [] }: GenderFilterType) => {
  const onPressGender = useCallback(
    (newValue: string) => {
      if (onPress) onPress(newValue);
    },
    [onPress]
  );
  const genderOptions = programGenders.map(gender => (
    <CustomButton
      key={gender}
      value={gender}
      label={upperFirst(gender)}
      variant="outline"
      style={styles.button}
      pressed={selectedGenders.includes(gender)}
      onPress={onPressGender}
    />
  ));

  return (
    <View style={styles.container}>
      <View style={styles.row}>{genderOptions}</View>
    </View>
  );
};

export default GenderFilter;
