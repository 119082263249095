import { useSuspenseQuery } from '@apollo/client';
import moment from 'moment-timezone';
import type { FC } from 'react';
import { Box, Heading, Text, TextArea } from '../../../../base-components';
import { GET_RENTAL_EMAILS } from '../../gql';
import { useMessageCenterStore } from '../../store';

const AlternateMethod: FC = () => {
  const { filters, rentalId } = useMessageCenterStore(store => ({
    filters: store.filters,
    rentalId: store.rentalId,
  }));

  const { selectedDates, ...remainingFilters } = filters;

  const {
    data: { getRentalEmails: emails },
  } = useSuspenseQuery(GET_RENTAL_EMAILS, {
    variables: {
      input: {
        rentalId,
        filters: {
          ...remainingFilters,
          ...(filters.dateSelectionType === 'manual'
            ? {
                selectedDates: (filters.selectedDates ?? []).map(date =>
                  moment(date).format('YYYY-MM-DD')
                ) as string[],
              }
            : {}),
        },
      },
    },
  });

  return (
    <Box bg="white.600" rounded="sm" p="4">
      <Heading fontSize="md" color="gray.700">
        Alternate Method
      </Heading>
      <Text fontSize="sm" my="2">
        To email players using another method, you can copy the addresses here.
      </Text>
      <TextArea isReadOnly autoCompleteType="off" value={emails.join(', ')} bg="gray.50" />
    </Box>
  );
};

export default AlternateMethod;
