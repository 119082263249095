import { useEffect } from 'react';
import { useSuspenseQuery, skipToken } from '@apollo/client';
import { MembershipEnum } from '../../../constants';
import { PRICING_QUERY, DROP_IN_PRICING_QUERY } from '../gql/PRICING_QUERY';
import type usePaymentSummary from './usePaymentSummary';
import canDonateDollarFromReg from '../utils/canDonateDollarFromReg';
import normalizePricingQueryData from '../utils/normalizePricingQueryData';
import { PaymentSummaryVPExclusiveError } from '../PaymentSummaryError';
import type { LeagueRegistrationVariant } from '../PaymentSummary';

type UseProgramPricingInput = (
  | { isDropIn: true }
  | { isDropIn: false; registrationVariant?: LeagueRegistrationVariant }
) & {
  leagueId: string;
  isDropIn: boolean;
  usePaymentSummaryProps: ReturnType<typeof usePaymentSummary>;
  promoCodeStr?: string;
  creditsAppliedInCents?: number;
  donationsAppliedInCents?: number;
};

/**
 * Custom hook for determining the price of a program, returning the price breakdown as well as some
 * helpful variables.
 */
const useProgramPricing = (props: UseProgramPricingInput) => {
  const {
    isDropIn,
    leagueId,
    creditsAppliedInCents,
    donationsAppliedInCents,
    promoCodeStr,
    usePaymentSummaryProps: {
      pricingSelected,
      setPricingSelected,
      userIsDonatingRegistrationCost,
      setUserIsDonatingRegistrationCost,
    },
  } = props;

  const isPrepaidTeam =
    'registrationVariant' in props
      ? props.registrationVariant === 'PREPAID_TEAM_CAPTAIN'
      : undefined;

  const { data: leagueData } = useSuspenseQuery(
    PRICING_QUERY,
    !isDropIn
      ? {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
          variables: {
            leagueId,
            inputRegular: {
              leagueId,
              creditAmount: creditsAppliedInCents,
              donationAmount: donationsAppliedInCents,
              membershipName: undefined,
              promoCodeStr,
              isDonatingPortionOfRegistration: userIsDonatingRegistrationCost,
              isPrepaidTeam,
            },
            inputVoloPass: {
              leagueId,
              creditAmount: creditsAppliedInCents,
              donationAmount: donationsAppliedInCents,
              membershipName: MembershipEnum.VOLO_PASS,
              promoCodeStr,
              isDonatingPortionOfRegistration: userIsDonatingRegistrationCost,
              isPrepaidTeam,
            },
            inputVoloFitness: {
              leagueId,
              creditAmount: creditsAppliedInCents,
              donationAmount: donationsAppliedInCents,
              membershipName: MembershipEnum.VOLO_FITNESS,
              promoCodeStr,
              isDonatingPortionOfRegistration: userIsDonatingRegistrationCost,
              isPrepaidTeam,
            },
          },
        }
      : skipToken
  );

  const { data: dropInData } = useSuspenseQuery(
    DROP_IN_PRICING_QUERY,
    isDropIn
      ? {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
          variables: {
            leagueId,
            input: {
              leagueId,
              creditCents: creditsAppliedInCents,
              donationCents: donationsAppliedInCents,
              promoCodeStr,
              isDonatingPortionOfRegistration: userIsDonatingRegistrationCost,
            },
          },
        }
      : skipToken
  );

  const data = leagueData ?? dropInData;
  // Both queries failed, but didn't throw an exception for whatever reason
  if (!data) {
    throw new Error('[useProgramPricing]: Unable to fetch pricing for this program');
  }

  const {
    currentUser,
    league: {
      programType,
      is_volo_pass_exclusive: isVoloPassExclusive,
      organization: { isVoloPassTrialActive, voloFitnessTiers, voloPassTiers },
    },
  } = data;

  if (isVoloPassExclusive && !voloPassTiers.isActive) {
    // PaymentSummaryError handles the appropriate copy for this error
    throw new PaymentSummaryVPExclusiveError();
  }
  // Reformat the data into a convenient type
  const breakdownMap = normalizePricingQueryData(data);

  const pricingBreakdown = breakdownMap[pricingSelected] ?? breakdownMap.REGULAR!;

  const canDonateAtNoCost =
    'registrationVariant' in props &&
    props.registrationVariant !== 'PREPAID_TEAM_MEMBER' &&
    canDonateDollarFromReg(pricingBreakdown, userIsDonatingRegistrationCost);

  const { memberPrice, voloFitnessPrice, membershipDiscountApplied } = pricingBreakdown;

  // `useSuspenseQuery` doesn't have an `onCompleted` like `useQuery` does, so this is the next best
  // option. It's not really great practice to keep Apollo data in state, but in this case, the
  // pricingSelected variable needs to be exposed to the outside world via `usePaymentSummary`, so
  // there's really not much we can do for the moment.
  useEffect(() => {
    // Guard against invalid memberships for this component.
    if (membershipDiscountApplied === MembershipEnum.VOLO_KIDS_RECURRING) {
      throw new Error('Unsupported membership type!');
    }

    if (isVoloPassExclusive) {
      setPricingSelected(MembershipEnum.VOLO_PASS);
    } else {
      setPricingSelected(membershipDiscountApplied ?? 'REGULAR');
    }

    // We want this to only be done on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Same reasoning as above
  useEffect(() => {
    if (!canDonateAtNoCost) {
      setUserIsDonatingRegistrationCost(false);
    }
  }, [setUserIsDonatingRegistrationCost, canDonateAtNoCost]);

  const isVoloPassMember = currentUser?.isVoloPassMember ?? false;
  const isVoloFitnessMember = currentUser?.isVoloFitnessMember ?? false;

  const programHasVoloPassPricing = voloPassTiers.isActive && typeof memberPrice === 'number';
  const programHasFitnessPricing =
    voloFitnessTiers.isActive && typeof voloFitnessPrice === 'number';

  const programHasMemberPricing = programHasVoloPassPricing || programHasFitnessPricing;

  const voloPassInCart = pricingSelected === MembershipEnum.VOLO_PASS && !isVoloPassMember;

  // This should really be computed on the backend and returned as part of the pricing breakdown
  const monthlyCostOfVoloPassInCents =
    programHasVoloPassPricing && voloPassTiers.monthlyDollarPrice
      ? voloPassTiers.monthlyDollarPrice * 100
      : undefined;

  const voloFitnessInCart = pricingSelected === MembershipEnum.VOLO_FITNESS && !isVoloFitnessMember;
  const monthlyCostOfVoloFitnessInCents =
    programHasFitnessPricing && voloFitnessTiers.monthlyDollarPrice
      ? voloFitnessTiers.monthlyDollarPrice * 100
      : undefined;

  return {
    programHasMemberPricing,

    isVoloPassTrialActive,
    isVoloPassMember,
    voloPassInCart,
    monthlyCostOfVoloPassInCents,
    voloPassFeesInCents: (voloFitnessTiers.monthlyFee ?? 0) * 100,
    programHasVoloPassPricing,

    isVoloFitnessMember,
    voloFitnessInCart,
    monthlyCostOfVoloFitnessInCents,
    voloFitnessFeesInCents: (voloFitnessTiers.monthlyFee ?? 0) * 100,
    programHasFitnessPricing,

    programType,
    isVoloPassExclusive,
    canDonateAtNoCost,

    appliedPricingBreakdown: pricingBreakdown,
  };
};

export default useProgramPricing;
