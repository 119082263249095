import { type ComponentProps } from 'react';
import { isWeb } from '../../utilities';
import { ConfirmCancelModal } from '../../custom-components';

const ConfirmCancelDropin = (
  props: Omit<ComponentProps<typeof ConfirmCancelModal>, 'headerText' | 'children'>
) => {
  return (
    <ConfirmCancelModal
      size={isWeb ? 'lg' : 'xl'}
      headerText="Cancel Drop-in Spot"
      confirmText="Remove Drop-in"
      cancelText="Go back"
      isVertical
      {...props}
    >
      This drop-in spot has already been taken. The drop-in player will be informed and the spot
      will reopen.
    </ConfirmCancelModal>
  );
};

export default ConfirmCancelDropin;
