export const snakeToStartCase = (input: string): string => {
  return input
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const upperFirst = (string: string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';

export const camelCase = (str = '') => {
  const splitStringArray = str?.split(/[^A-Za-z0-9]/g) || [];
  const splitStringsArrayMapped = splitStringArray?.map((word, index) =>
    index === 0 ? word?.toLowerCase() : upperFirst(word)
  );

  return splitStringsArrayMapped?.join('');
};

export const pluralize = (str: string, num: number) => {
  if (!str) return '';
  if (num === 1) return str;
  return `${str}s`;
};
