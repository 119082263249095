import { memo, useMemo } from 'react';
import { Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import {
  NativeBaseProvider,
  type NativeBaseProviderProps,
  extendTheme,
  type Theme,
  type ColorMode,
} from '@rivallapp/native-base';

import { IconTheme } from '../../base-components/Icon';
import { ModalAlertComponent } from '../../custom-components';
import { RootModalId } from '../../custom-components/ModalAlert/store/modalAlert.types';
import {
  DarkTheme,
  LightTheme,
  VoloNativeBaseShades,
  VoloSansProFontConfig,
  VoloFonts,
  Spacing,
  Sizes,
} from '../../theme';

const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';

interface IVoloTheme extends Theme {
  LightTheme: typeof LightTheme;
  DarkTheme: typeof DarkTheme;
  config: {
    useSystemColorMode: boolean;
    /**
     * If true, enables the device theme (light/dark) for the app to match the device's color mode
     * - enableTheming: `themeEnabled && isMobile` check in ThemeProvider
     * - must opt in for now through the ThemeProvider component: `themeEnabled`
     * - must be a mobile device: `Platform.OS  'ios' | 'android'`;
     */
    enableTheming: boolean;
    /**
     * Prop passed to NativeBaseProvider to match the device's color mode
     * - this plus `themeEnabled` prop allows the device to `useSystemColorMode`
     */
    matchOSColorMode?: boolean;
    initialColorMode?: ColorMode;
  };
}

// Extending the internal NativeBase Theme
declare module '@rivallapp/native-base' {
  interface ICustomTheme extends IVoloTheme {}
}

interface ThemeProviderProps extends Omit<NativeBaseProviderProps, 'theme'> {
  /**
   * If true, enables the device to be able to switch between light and dark mode
   * - defaulted to false as only one repo at this time utilizes this feature
   * @default false
   */
  themeEnabled?: boolean;
  /**
   * If true, match the device's color mode
   * - defaulted to undefined as it was overriding setting the colorMode on app launch in the mobile app
   * @info on mobile, was utilizing this and updating the colorMode using the `useSystemColorMode` prop on the NativeBaseProvider which proved to be a bad idea and didn't work as expected. Use with caution
   * @default undefined
   */
  matchOSColorMode?: boolean;
  /**
   * Sets the initial color mode for the app
   * - defaulted to undefined to allow per device settings to take precedence
   * @default undefined
   */
  initialColorMode?: ColorMode;
}

/* colorModeManager is passed through props from mobile */
const ThemeProvider = ({
  children,
  themeEnabled = false,
  matchOSColorMode = undefined,
  initialColorMode = undefined,
  ...rest
}: ThemeProviderProps) => {
  /**
   * If true, enables the device theme (light/dark) for the app to match the device's color mode - needs to have enabledDeviceColorMode prop passed to ThemeProvider and platform check must be a mobile device
   */
  const enableTheming = themeEnabled && isMobile;

  /* Add Volo colors + font to extend native base theme */
  const theme = useMemo(
    () =>
      extendTheme({
        colors: {
          // override text colors for each mode
          darkText: DarkTheme.colors.text,
          lightText: LightTheme.colors.text,
          // ...
          // utilize Volo color palette
          /**
           * keep brandPrimary, brandSecondary, and brandTertiary for backwards compatibility
           * @deprecated use TBrandKeys instead 'primary', 'secondary', 'tertiary'
           */
          brandPrimary: VoloNativeBaseShades.primary,
          brandSecondary: VoloNativeBaseShades.secondary,
          brandTertiary: VoloNativeBaseShades.tertiary,
          ...VoloNativeBaseShades,
        },
        // Web is currently using the system default
        ...(isMobile
          ? {
              // map the VoloSansPro font config to the theme values
              fontConfig: VoloSansProFontConfig,
              // attach the VoloSansPro font family to the theme
              fonts: VoloFonts,
            }
          : {}),
        /*
          adds some breakpoints here for more control over responsive design on smaller screens
          - the newly added breakpoints can be used in the components with the `array` syntax for responsive design: https://docs.nativebase.io/responsive#h2-the-array-syntax
        */
        breakpoints: {
          base: 0, // default
          xxsm: 320, // custom
          xsm: 390, // custom
          sm: 480, // default
          md: 768, // default
          lg: 992, // default
          xl: 1280, // default
        },
        components: {
          Spinner: {
            baseStyle: {
              _dark: {
                color: 'white.600',
              },
              _light: {
                color: 'primary.600',
              },
            },
          },
          Text: {
            defaultProps: {
              allowFontScaling: false, // biz requirement
            },
          },
          Button: {
            defaultProps: {
              variant: 'solid',
            },
          },
          Icon: IconTheme,
          Heading: {
            defaultProps: {
              size: '2xl',
            },
          },
          Checkbox: {
            variants: {
              thin: {
                borderWidth: 1,
              },
            },
          },
          // these colors fit & match much nicer with the current Dark Mode
          Modal: {
            baseStyle: {
              _backdrop: {
                // lighter on backdrop when it comes to dark mode
                _dark: {
                  bg: 'coolGray.600',
                },
              },
            },
            sizes: {
              // xl is the same width but has a max-width. This just removes the max-width
              '2xl': {
                contentSize: {
                  width: '90%',
                },
              },
              '3xl': {
                contentSize: {
                  width: '95%',
                },
              },
            },
          },
          ModalContent: {
            baseStyle: {
              _dark: {
                backgroundColor: DarkTheme.colors.card,
              },
            },
          },
          ModalHeader: {
            baseStyle: {
              _dark: {
                backgroundColor: DarkTheme.colors.card,
              },
            },
          },
          ModalBody: {
            baseStyle: {
              _dark: {
                backgroundColor: DarkTheme.colors.card,
              },
            },
          },
          ModalFooter: {
            baseStyle: {
              _dark: {
                backgroundColor: DarkTheme.colors.card,
              },
            },
          },
        },
        sizes: Sizes,
        space: Spacing,
        // add custom theme values here for mobile app (utilizes the react navigation theme object) in case we need it through native base
        LightTheme,
        DarkTheme,
        config: {
          initialColorMode,
          useSystemColorMode: Boolean(enableTheming && matchOSColorMode),
          enableTheming,
          matchOSColorMode,
        },
      }),
    [initialColorMode, enableTheming, matchOSColorMode]
  );

  const config = {
    dependencies: {
      'linear-gradient': LinearGradient,
    },
  };

  return (
    <NativeBaseProvider config={config} theme={theme} {...rest}>
      {children}
      <ModalAlertComponent id={RootModalId} />
    </NativeBaseProvider>
  );
};

export default memo(ThemeProvider);
