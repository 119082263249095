import {
  RentalRegistrationSteps,
  type RentalStepStoreKeyNames,
  type StepStateMap,
} from '../../RegistrationFlowSections';
import useRentalBookingStore from '../useRentalBookingStore';

// Define a generic handler that respects TypeScript's static types.
// this is needed as if we just deconstruct the state from the currentBooking and currentStep
// typescript will not be able to infer the correct type for the state (inferring as a 'Union Type' from the TS errors) since it needs to be inferred
// at runtime, and not at compile time.
// - This function should check if the given step and its validation match based on generic types passed
// to the function from the current step and step state within the booking store below.
function isValidStep<K extends RentalStepStoreKeyNames>(
  currentStep: K,
  steps: StepStateMap
): boolean {
  const stepValidation = RentalRegistrationSteps[currentStep]?.validation;

  // safe guard against missing validation
  if (!stepValidation) {
    return false;
  }

  const currentStepInBooking = steps[currentStep];

  return stepValidation(currentStepInBooking);
}

/**
 * Hook to validate the current step of the booking that can be used anywhere in the app for the UI
 * - i.e to easily disable the next button if the current step is not valid
 */
const useRentalBookingCurrentStepValidation = () => {
  const currentBooking = useRentalBookingStore(state => state.currentBooking);
  if (!currentBooking) {
    return false;
  }

  const { currentStep, steps } = currentBooking;

  return isValidStep(currentStep, steps);
};

export default useRentalBookingCurrentStepValidation;
