/**
 * a simple component styled the same way as Collapsible, but intentionally without Icons
 */
import React from 'react';

import { View, Text } from '../../../base-components';
import styles from '../styles';

export type DiscoverFilterTitleProps = {
  title?: string;
};

const DiscoverFilterTitle = ({ title }: DiscoverFilterTitleProps) => {
  return (
    <View style={styles.titleContainer}>
      <Text fontSize={18} bold>
        {title || 'Item'}
      </Text>
    </View>
  );
};

export default DiscoverFilterTitle;
