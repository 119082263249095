import { ProgramTypeEnum, ProgramType } from '../../../constants/enums';
import { WholeDollarFormatter } from '../../../utilities/formatters';

type UseDynamicSaleCopyProps = {
  programType: ProgramTypeEnum;
  memberPriceInCents?: number;
  nonmemberPriceInCents: number;
};

type DynamicSaleCopyPart = {
  text: string;
  isHighlighted: boolean;
};

/**
 * Generates an array of sale copy pieces that can be combined to form the full copy displayed on
 * the `VoloPassSaleBanner`.
 */
const useDynamicSaleCopy = ({
  programType,
  memberPriceInCents,
  nonmemberPriceInCents,
}: UseDynamicSaleCopyProps): DynamicSaleCopyPart[] => {
  const copy: DynamicSaleCopyPart[] = [{ text: 'Become a member and', isHighlighted: false }];

  const savings = nonmemberPriceInCents - (memberPriceInCents ?? nonmemberPriceInCents);

  // default to empty strings if the program type is not found
  // related to this issue: https://github.com/rivallapp/volosports-tech-platform/issues/1106
  const programTypeLabel = ProgramType?.[programType]?.name?.toLowerCase() ?? 'program';

  // The program is free!
  if (memberPriceInCents === 0) {
    switch (programType) {
      case ProgramTypeEnum.DROPIN:
        copy.push({ text: `take this spot`, isHighlighted: false });
        break;
      case ProgramTypeEnum.CLASS:
      case ProgramTypeEnum.CLINIC:
      case ProgramTypeEnum.EVENT:
        copy.push({ text: `attend this ${programTypeLabel}`, isHighlighted: false });
        break;
      case ProgramTypeEnum.LEAGUE:
      case ProgramTypeEnum.PICKUP:
      case ProgramTypeEnum.TOURNAMENT:
      default:
        copy.push({ text: `play in this ${programTypeLabel}`, isHighlighted: false });
        break;
    }

    copy.push({ text: 'for free!', isHighlighted: true });
  }

  // If the savings are zero, just display generic copy so that the user isn't being mocked 🥲
  else if (savings === 0 || savings < 0) {
    copy.push(
      { text: 'get', isHighlighted: false },
      { text: 'unlimited sports on demand!', isHighlighted: true }
    );
  }

  // Show the user how much they would save on this program if they were a Volo Pass member
  else {
    copy.push(
      { text: `save ${WholeDollarFormatter.format(savings / 100)}`, isHighlighted: true },
      { text: `on this ${programTypeLabel}!`, isHighlighted: false }
    );
  }

  return copy;
};
export default useDynamicSaleCopy;
