/* eslint-disable react/prop-types */
import React, { createContext, useContext, useMemo } from 'react';
import { type TableCellProps, type TableCellTextProps } from '../components/TableCell';

type ITableContext = {
  /**
   * Pass this option as true to disable all default table styling.
   *
   * @default false
   */
  unstyled?: boolean;
  /**
   * Default props passed to all cell components.
   *
   * Whatever is passed here will be merged with the existing
   * default props object unless you also pass in `unstyled: true`
   */
  defaultCellProps?: TableCellProps;
  /**
   * Default props passed to all cell text.
   *
   * Whatever is passed here will be merged with the existing
   * default props object unless you also pass in `unstyled: true`
   */
  defaultCellTextProps?: TableCellTextProps;
  /**
   * Default props passed to only `Table.Header` cell components.
   *
   * Anything provided here will overwrite `defaultCellProps` for header cells
   *
   * Whatever is passed here will be merged with the existing
   * default props object unless you also pass in `unstyled: true`
   */
  defaultHeaderCellProps?: TableCellProps;
  /**
   * Default props passed to all cell header text.
   *
   * Whatever is passed here will be merged with the existing
   * default props object unless you also pass in `unstyled: true`
   */
  defaultHeaderTextProps?: TableCellTextProps;
};

export type TableProviderProps = React.PropsWithChildren<ITableContext>;

const sharedProps: TableCellProps = {
  flex: 1,
  fontSize: 'xs',
};

const _defaultCellProps: TableCellProps = {
  ...sharedProps,
  borderWidth: 1,
  borderColor: 'gray.100',
  alignItems: 'center',
  overflowX: 'hidden',
};

const _defaultCellTextProps: TableCellProps = {
  ...sharedProps,
  p: 2,
};

const _defaultHeaderTextProps: TableCellProps = {
  ...sharedProps,
  p: 2,
  fontWeight: 'bold',
};

export const TableContext = createContext<ITableContext>({
  defaultCellProps: _defaultCellProps,
  defaultCellTextProps: _defaultCellTextProps,
  defaultHeaderCellProps: _defaultCellProps,
  defaultHeaderTextProps: _defaultHeaderTextProps,
});

/**
 * Provides access to the TableContext, which is primarily used to
 * store default props for all table items.
 *
 * Must be used within a table component.
 * */
export const useTableContext = () => useContext(TableContext);

const TableProvider: React.FC<TableProviderProps> = ({
  children,
  unstyled,
  defaultCellProps,
  defaultCellTextProps,
  defaultHeaderCellProps,
  defaultHeaderTextProps,
}) => {
  const value = useMemo(() => {
    if (unstyled) {
      return {
        defaultCellProps,
        defaultCellTextProps,
        defaultHeaderCellProps,
        defaultHeaderTextProps,
      };
    }

    return {
      defaultCellProps: {
        ..._defaultCellProps,
        ...defaultCellProps,
      },
      defaultCellTextProps: {
        ..._defaultCellTextProps,
        ...defaultCellTextProps,
      },
      defaultHeaderTextProps: {
        ..._defaultHeaderTextProps,
        ...defaultHeaderTextProps,
      },
      defaultHeaderCellProps: {
        ..._defaultCellProps,
        ...defaultHeaderCellProps,
      },
    };
  }, [
    defaultCellProps,
    defaultCellTextProps,
    defaultHeaderCellProps,
    defaultHeaderTextProps,
    unstyled,
  ]);
  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

export default TableProvider;
