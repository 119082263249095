import { ProgramTypeEnum } from '../constants/enums';

import generatePalette from '../utilities/color';
import type {
  BlueColors,
  Color,
  GlobalColors,
  GreyColors,
  ISelectColors,
  NavTheme,
  TBrandColors,
  AccentBaseColors,
} from './types';
import colorManipulation from '../utilities/tinyColor';

// todo: dark theme on brand primary buttons, etc

/*
Ideally we would want to keep the hook that deals with the NavTheme (mobile nav package + any colors that are different in light and dark mode)
- Right, we are hooking into the theme to get the current color of the Theme based on light and dark, so we do not need to add colors to the theme that are static

* For static colors we wouldn't need to add them to the colors object that gets returned from the useTheme hook
* as that muddles the return colors as they are static and not changing

* colors that are returned from LightTheme and DarkTheme should by dynamic in the sense that they change based on the current theme
* Everything else we can add to the Colors object that gets the straight export as those are the static colors that aren't changing.
*/

/* Matching the primaryShades (old values in comments) */
export const voloBlues: BlueColors = {
  veryDark: '#0A3355',
  dark: '#183F6E',
  medium: '#0171b9', // #3e85d7
  light: '#428AC9',
  veryLight: '#96c9ea', // rgb(162, 202, 232)
} as const;

export const voloGreys: GreyColors = {
  extraLight: 'rgb(249, 249, 249)',
  veryLight: 'rgb(245, 245, 245)',
  light: '#d6d6d6',
  medium: '#A0A0A0',
  dark: '#474747',
  veryDark: '#111',
} as const;

// HEX FOR DIGITAL SCREENS
/**
 * - 100 is full primary color
 * - 0 is lightest shade
 * - brand colors updated from Megan's design (7/19/23)
 * - Confirmed by marketing 5/31/2024
 */
export const primaryShades = {
  100: '#0a3355',
  75: '#183f6e',
  50: '#0171b9',
  25: '#428ac9',
  0: '#96c9ea',
} as const;

// accents colors, updated from Megan's design (7/19/23)
export const accents = {
  teal: '#4ce0d2', // confirmed by marketing 5/31/2024
  lightTeal: '#C0F4EF',
  accentBlue: '#2fa3f5', // added by marketing 5/31/2024
  complimentaryYellow: '#eaff70', // added by marketing 5/31/2024
  yellow: '#f9ff00',
  lightYellow: '#F9FFD6',
  purple: '#C084FC',
  rsvpGreen: 'rgba(7, 81, 5, 1)',
  yes: '#92b753',
  maybe: '#eac143',
  no: '#d4484c',
} as const satisfies AccentBaseColors;

// normal colors
const white = '#fff';
const black = '#000';

// brand colors updated from Megan's design (7/19/23)
const primary = voloBlues.veryDark;
const secondary = accents.accentBlue; // updated July 2024
const accent = accents.teal;
// ...

export const rentalBlueHex = '#0d8ed9';
export const lightRentalBlueRgb = {
  r: 0,
  g: 149,
  b: 238,
};
export const darkRentalBlueRgb = {
  r: 0,
  g: 187,
  b: 246,
};

/**
 * Export the brand colors to map over the native-base theme
 * - updated from Megan's design (7/19/23) which now follow the correct colors
 */
export const VoloNativeBaseShades: TBrandColors = {
  primary: {
    50: '#e3f4ff',
    100: '#bbdcf7',
    200: '#90c4ef',
    300: '#66ace8',
    400: '#2a7bc8',
    500: '#0d4472',
    600: primary,
    700: '#082944',
    800: '#062035',
    900: '#000f1b',
  },
  secondary: {
    50: '#ffffff',
    100: '#ffffff',
    200: '#f2f9fe',
    300: '#c1e4fc',
    400: '#90cefa',
    500: '#60b9f7',
    600: secondary,
    700: '#0b8be6',
    800: '#096eb5',
    900: '#065085',
  },
  tertiary: {
    50: '#dbfefd',
    100: '#b6f6f0',
    200: '#8dede4',
    300: '#64e5d9',
    400: '#3bddce',
    500: '#22c4b4',
    600: accent,
    700: '#046e64',
    800: '#00423c',
    900: '#001815',
  },
  white: {
    50: '#f7f7f7',
    100: '#f4f4f4',
    200: '#f2f2f2',
    300: '#efefef',
    400: '#ebebeb',
    500: '#e8e8e8',
    600: white,
    700: '#f9f9f9',
    800: '#f5f5f5',
    900: '#f1f1f1',
  },
  gray: {
    50: '#F9F9F9',
    100: '#E1E0E0',
    200: '#d6d6d6',
    300: '#B8B5B5',
    400: '#A0A0A0',
    500: '#737373',
    600: '#474747',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
  /*
    specific to feature around rentals
    - was using 'lightBlue' but it was just different enough they wanted their own color palette for it
  */
  rentalBlue: {
    50: '#dcf7ff',
    100: '#b0e3ff',
    200: '#83cffa',
    300: '#54bbf6',
    400: '#26a8f2',
    500: '#0095ee',
    600: rentalBlueHex, // base color from figma for rentals
    700: '#095F91',
    800: '#084f7e',
    900: '#063b61',
  },
  // TODO: error palette
  // TODO: success palette
  // TODO: warning palette
  // TODO: info palette
  // TODO: purple palette
  /**
   * Custom palettes used for Radio
   * Radio light mode icon uses colorScheme.600, whereas dark mode uses colorScheme.500
   */
  lightSelect: generatePalette(accent),
  darkSelect: generatePalette(accents.lightTeal, 500),
};

/**
 * select colors for any select components, updated from Megan's design (7/19/23)
 * has a light and dark mode for easy use and update in the future
 */
export const selectColors: Record<string, ISelectColors> = {
  light: {
    bg: accent,
    text: black,
  },
  dark: {
    bg: accents.lightTeal,
    text: black,
  },
} as const;

// Color accents for each of the Program Types
export const programColors: Record<ProgramTypeEnum, Color> = {
  [ProgramTypeEnum.DROPIN]: '#553E73',
  [ProgramTypeEnum.PICKUP]: '#4A71AE',
  [ProgramTypeEnum.LEAGUE]: '#6153B7',
  [ProgramTypeEnum.CLINIC]: '#4A71AE',
  [ProgramTypeEnum.PRACTICE]: '#4A71AE',
  [ProgramTypeEnum.TOURNAMENT]: '#1B1156',
  [ProgramTypeEnum.EVENT]: '#191793',
  [ProgramTypeEnum.CLASS]: '#53AE8E',
  [ProgramTypeEnum.SERIES]: '#093022',
  [ProgramTypeEnum.VOLUNTEER_EVENT]: '#406A77',
  [ProgramTypeEnum.VOLUNTEER_LEAGUE]: '#406A77',
  // Not used for color border
  [ProgramTypeEnum.RENTAL]: rentalBlueHex,

  // The following programs should not be discoverable
  [ProgramTypeEnum.PLAYOFFS]: 'rgba(61, 115, 179, 1)',
  [ProgramTypeEnum.VIRTUAL]: 'rgba(61, 115, 179, 1)',
} as const;

// try not to use this, i left this for reference of the way we used to do it in mobile4
// utilize the Colors object below instead
/**
 * @deprecated - use Colors in src/theme/Colors.ts instead
 */
export const globalColors: Record<string, Color> = {
  brandPrimary: '#0a3355',
  brandSecondary: '#e9ff70',
  brandTertiary: voloBlues.light,
  lightYellow: '#eefd85',
  accentYellow: '#F9FFD6',
  accentLightYellow: '#FCFCF4',
  lightBlue: voloBlues.medium,
  accentBlue: '#C0F4EF',
  accentLightBlue: '#F6FDFD',
  accentLightBlueBorder: '#4CE0D2',
  veryDarkBlue: voloBlues.veryDark,
  accentGreen: '#B1D76A',
  black: '#000',
  white: 'white',
  error: '#e63946',
  opaqueWhite: 'rgba(255, 255, 255, 0.5)',
  lightGrey: voloGreys.light,
  mediumGrey: voloGreys.medium,
  darkGrey: voloGreys.dark,
  yes: '#92b753',
  maybe: '#eac143',
  no: '#d4484c',
} as const;

export const Colors: GlobalColors = {
  primary,
  secondary,
  accent,
  shades: VoloNativeBaseShades,
  error: '#e63946',
  warning: '#ffdc33',
  success: '#29bf12',
  link: '#60A5FA', // same as 'blue.400' in NB
  greys: voloGreys,
  blues: voloBlues,
  white,
  black,
  select: selectColors,
  hover: voloGreys.veryLight,
  banner: '#C0F4EF',
  programColors,
  accents,
  logoColors: {
    primary,
    white,
  },
} as const;

export const LightTheme: NavTheme = {
  dark: false,
  colors: {
    primary: Colors.primary,
    secondary: Colors.secondary,
    tertiary: Colors.accent,
    background: white,
    card: colorManipulation.darken(white, 3),
    text: black,
    border: voloGreys.light,
    notification: 'rgb(255, 69, 58)',
    accents: {
      ...accents,
      select: selectColors.light!, // TODO: This shouldn't need an assertion but the type for `selectColors` needs a rework
      hover: Colors.hover,
    },
    programColors,
    logoColors: Colors.logoColors,
  },
} as const;

export const DarkTheme: NavTheme = {
  dark: true,
  colors: {
    primary: VoloNativeBaseShades.primary[400],
    secondary: Colors.secondary,
    tertiary: Colors.accent,
    background: colorManipulation.darken(voloGreys.veryDark, 4),
    card: voloGreys.veryDark,
    text: white,
    border: voloGreys.dark,
    notification: 'rgb(255, 69, 58)',
    accents: {
      ...accents,
      select: selectColors.dark!, // TODO: This shouldn't need an assertion but the type for `selectColors` needs a rework
      hover: Colors.hover,
    },
    programColors,
    logoColors: Colors.logoColors,
  },
} as const;
