export enum RentalRegistrantTypes {
  BOOKING_CAPTAIN = 'booking_captain',
  GUEST = 'guest',
}

type RentalRegistrantRolesType = Readonly<{
  [Role in RentalRegistrantTypes]: {
    value: Role;
    label: string;
    isCaptain?: boolean;
    toCamel: string;
  };
}>;

export const RentalRegistrantRoles: RentalRegistrantRolesType = Object.freeze({
  [RentalRegistrantTypes.BOOKING_CAPTAIN]: {
    value: RentalRegistrantTypes.BOOKING_CAPTAIN,
    label: 'Rental Booking Captain',
    isCaptain: true,
    toCamel: 'bookingCaptain',
  },
  [RentalRegistrantTypes.GUEST]: {
    value: RentalRegistrantTypes.GUEST,
    label: 'Rental Group Member',
    isCaptain: false,
    toCamel: 'rentalGroupMember',
  },
});
