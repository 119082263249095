import { type IHeadingProps, Heading as NHeading } from '@rivallapp/native-base';
import { HeadingTypography, type THeading } from '../../theme';

export interface HeadingProps extends IHeadingProps {
  /**
   * The type of heading text to render:
   * this adds preset fontSizes, fontWeights to make sure the styling is consistent
   *
   * `screen` Screen titles (mobile tab screen titles, web page headings, etc) "size: 2xl"
   *
   * `section` Section titles (section headers, etc) "size: xlg"
   *
   * `subsection` Subsection titles (subsection headers, etc) "size: md"
   * @default "screen"
   * @see HeadingTypography
   */
  type?: THeading;
}

const Heading = ({ type = 'screen', ...rest }: HeadingProps) => {
  const typographyProps = HeadingTypography[type] || {};
  return <NHeading {...typographyProps} {...rest} />;
};

export default Heading;
