import { gql, type TypedDocumentNode } from '@apollo/client';

type PendingReservation = {
  bookingId: string;
  rentalId: string;
  selectedTimeSlotIds: string[];
  numGuestsEstimate: number;
  password?: string;
};

export type PriceModifiers = {
  donationCents?: number;
  promoCodeStr?: string;
  creditCents?: number;
};

export type Agreements = {
  policyAgreed: boolean;
  waiverAgreed: boolean;
};

export type BookRentalsInput = {
  pendingReservations: Array<PendingReservation>;
} & PriceModifiers &
  Agreements;

interface BookRentalsData {
  bookRentals: {
    rentalReservations: Array<{
      _id: string;
      deep_link: string;
    }>;
  };
}

interface BookRentalsVariables {
  input: BookRentalsInput;
}

export const BOOK_RENTALS: TypedDocumentNode<BookRentalsData, BookRentalsVariables> = gql`
  mutation BookRentals($input: BookRentalsInput!) {
    bookRentals(input: $input) {
      rentalReservations {
        _id
        deep_link
      }
    }
  }
`;

export type JoinReservationInput = {
  reservationId: string;
  password?: string;
} & Pick<PriceModifiers, 'donationCents'> &
  Agreements;

interface JoinReservationVariables {
  input: JoinReservationInput;
}
interface JoinReservationData {
  joinReservation: {
    _id: string;
  };
}

export const JOIN_RESERVATION: TypedDocumentNode<JoinReservationData, JoinReservationVariables> =
  gql`
    mutation JoinReservation($input: JoinReservationInput!) {
      joinReservation(input: $input) {
        _id
      }
    }
  `;
