/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ItemSeparatorComponent, Text, View } from '../../base-components';
import styles from './styles';

interface RosterRulesGridProps {
  mins: {
    players: number;
    women: number;
  };
  fullField: {
    total: number;
    women: number;
  };
  recommended: {
    players: number;
    women: number;
  };
  isTournament?: boolean;
}

// from mobile
const formatNumber = (num: number) => num || ' ';

const RosterRuleGrid = ({
  mins,
  fullField,
  recommended,
  isTournament = false,
}: RosterRulesGridProps) => {
  const columns = ['', 'Total', 'Women'];
  const rowData = [
    {
      label: 'Min required to play',
      values: [formatNumber(mins.players), formatNumber(mins.women)],
      id: 0,
    },
    {
      label: 'Full Field',
      values: [formatNumber(fullField.total), formatNumber(fullField.women)],
      id: 1,
    },
    {
      label: 'Recommended',
      values: [formatNumber(recommended.players), formatNumber(recommended.women)],
      id: 2,
    },
  ];

  // As of May 2023, values are calculated as follows (from business):
  // minimum players: minPlayers
  // minimum women: minWomen
  // full field players: maxPlayersOnField
  // full field women: suggestedWomen
  // recommended players: suggestedPlayers
  // recommended women: N/A

  return (
    <View style={styles.container}>
      <Text style={styles.rosterRulesHeading}>Roster Rules</Text>
      <View style={styles.row}>
        {columns.map((col, index) => (
          <View key={index} style={[styles.flex, index === 0 ? styles.rowLabel : null]}>
            <Text style={styles.label}>{col}</Text>
          </View>
        ))}
      </View>
      {rowData.map((row, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <View style={styles.row}>
            <View style={[styles.rowLabel, rowIndex !== 0 ? styles.midBorder : null]}>
              <Text style={styles.label}>{row.label}</Text>
            </View>
            {row.values.map((value, colIndex) => (
              <View
                key={colIndex}
                style={[styles.flex, row.id === 0 ? styles.topRow : styles.midBorder]}
              >
                <Text>{value}</Text>
              </View>
            ))}
          </View>
          {rowIndex < rowData.length - 1 && (
            <ItemSeparatorComponent style={{ marginHorizontal: 0 }} />
          )}
        </React.Fragment>
      ))}
      {isTournament ? (
        <Text style={styles.note}>Drop-ins are not allowed in playoff games</Text>
      ) : null}
    </View>
  );
};

export default RosterRuleGrid;
