export enum NotificationTypeEnum {
  RATING_PROMPT = 'RatingPrompt',
  MEMBER_RATING_PROMPT = 'MemberRatingPrompt',
  RENTAL_RATING_PROMPT = 'RentalRatingPrompt',
  COVID_PROMPT = 'CovidPrompt',
  LEAGUE_NOTIFICATION = 'LeagueNotification',
  RENTAL_NOTIFICATION = 'RentalNotification',
  GENERIC_NOTIFICATION = 'GenericNotification',
  DROP_IN_NOTIFICATION = 'DropInNotification',
  UNSIGNED_WAIVER_NOTIFICATION = 'UnsignedWaiverNotification',
  UNSIGNED_RENTAL_WAIVER_NOTIFICATION = 'UnsignedRentalWaiverNotification',
}

export enum NotificationStatusEnum {
  UNREAD = 'unread', // notification has not been seen
  VIEWED = 'viewed', // notification has been seen but not submitted
  SUBMITTED = 'submitted', // notification has been seen and submitted
}

/** Used primarily for sending RSVP reminders to a whole team */
export enum TeamNotificationStatus {
  /** All players (who haven't rsvped) have one (or more) of the selected notification types muted */
  ALL = 'all',
  /** Some players (who haven't rsvped) have one (or more) of the selected notification types muted */
  SOME = 'some',
  /** No players (who haven't rsvped) have the selected notification types muted */
  NONE = 'none',
}

export default NotificationTypeEnum;
