import { useCallback, useEffect, useMemo, useState, type ComponentProps } from 'react';
// import { FlatList } from 'react-native-gesture-handler';
import moment from 'moment-timezone';
import { gql, type TypedDocumentNode, useQuery } from '@apollo/client';
import { type ListRenderItem, useWindowDimensions, Platform, FlatList } from 'react-native';

import { View, Spinner, Text } from '../../../../base-components';
import {
  RentalSubtext,
  RentalSectionTitle,
  RentalSectionContainer,
  RentalDivider,
  RentalErrorComponent,
} from '../../common/components';
import { useRentalBookingCourts, useRentalBookingTimeSlots } from '../../store';
import { type Court } from '../steps.types';
import { SelectorItem } from '../components';
import { horizGap, numColumns, platformAwareFlexBasis, platformAwareGap } from './helpers';
import { ModalAlert, ModalAlertComponent } from '../../../../custom-components';
import { startDateApiFormat } from '../steps.constants';
import { RentalRegistrationRouteNames } from '../steps';
import { RentalVenueMap } from '../../..';

export interface CourtsByTimeRangeInput {
  input: {
    rentalId: string;
    /**
     * MM/DD/YYYY
     */
    startDate: string;
    /**
     * HH:mm
     * - 24 hour format
     */
    startTime: string;
    /**
     * HH:mm
     * - 24 hour format
     */
    endTime: string;
  };
}

export interface CourtsByTimeRangeResponse {
  courtsByTimeRange: {
    _id: string;
    no_courts_available: boolean;
    courts: Court[];
  };
}

export const GET_COURTS: TypedDocumentNode<CourtsByTimeRangeResponse, CourtsByTimeRangeInput> = gql`
  query CourtsByTimeRange($input: CourtsByTimeRangeInput!) {
    courtsByTimeRange(input: $input) {
      _id
      no_courts_available
      courts {
        _id
        is_available
        name
        timeSlotIds
      }
    }
  }
`;

interface UICourtSelector extends Court {
  /**
   * If the court is pending in another confirmed booking
   */
  isPending: boolean;
}

interface CourtsSectionProps {
  onNoCourtsAvailable: (dateTimeRoute: 'date-selection') => void;
  /**
   * Optional ImageComponent needed to render RentalVenueMap on a per-platform basis.
   * See RentalVenueMap for more details.
   */
  ImageComponent?: ComponentProps<typeof RentalVenueMap>['ImageComponent'];
}

const CourtsSection = ({ onNoCourtsAvailable, ImageComponent }: CourtsSectionProps) => {
  const { width } = useWindowDimensions();
  const isOneColumn = width <= 280;

  const [showMap, setShowMap] = useState(false);

  const { timeSlots, startDate } = useRentalBookingTimeSlots();
  const {
    rentalId,
    selectedCourts,
    updateSelectedCourts,
    findOverlappingCourts,
    unSelectBookedCourts,
  } = useRentalBookingCourts();

  const { data, loading, error, refetch } = useQuery(GET_COURTS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
    skip: timeSlots.length < 2 || !startDate || !rentalId,
    variables: {
      input: {
        rentalId,
        // can assert here as we are skipping the query if these are not available
        startDate: moment(startDate!).format(startDateApiFormat),
        startTime: timeSlots[0]?.time_str!,
        endTime: timeSlots[timeSlots.length - 1]?.time_str!,
      },
    },
  });

  const updatedCourtsForOverlap = useMemo<UICourtSelector[]>(() => {
    if (data?.courtsByTimeRange.no_courts_available) {
      ModalAlert.alert({
        id: 'courts-section-modal-alert',
        title: 'No courts available',
        message:
          'There are no courts available for the selected time frame, please try adjusting your time range',
        buttons: [
          {
            text: 'OK',
            onPress: () => onNoCourtsAvailable(RentalRegistrationRouteNames.dateSelection),
          },
        ],
      });
    }

    const overlappedCourts = findOverlappingCourts();
    const courts = data?.courtsByTimeRange.courts || [];

    return courts.map<UICourtSelector>(court => {
      const overlappedCourt = overlappedCourts.find(overlappedCourt => {
        return court._id === overlappedCourt._id;
      });

      return {
        ...court,
        isPending: !!overlappedCourt,
      };
    });
  }, [
    data?.courtsByTimeRange.no_courts_available,
    data?.courtsByTimeRange.courts,
    findOverlappingCourts,
    onNoCourtsAvailable,
  ]);

  // effect is needed to sync the polling data with the selected courts - so on any step
  // the ModalAlert can be shown if the courts are no longer available
  useEffect(
    function syncSelectedCourtsWithAvailableCourts() {
      if (!data?.courtsByTimeRange.courts) return;
      unSelectBookedCourts(data?.courtsByTimeRange.courts, () =>
        ModalAlert.alert({
          // mobile uses the modal alert that is rendered along side the `RentalRegistrationSheet` to make sure its always visible
          id: Platform.select({
            native: 'rentals-registration-sheet-modal-alert',
            web: 'courts-section-modal-alert',
          }),
          title: 'Courts Booked',
          message:
            'Some of the courts you have selected have become unavailable and have been removed from your cart. Please update your court selection if needed to proceed.',
        })
      );
    },
    [data?.courtsByTimeRange.courts, unSelectBookedCourts]
  );

  const renderCourtItems = useCallback<ListRenderItem<UICourtSelector>>(
    ({ item, index }) => {
      const isLastIndex = index === updatedCourtsForOverlap.length - 1;
      const needsSpacer =
        !isOneColumn && isLastIndex && updatedCourtsForOverlap.length % numColumns !== 0;

      return (
        <>
          <SelectorItem
            flexBasis={platformAwareFlexBasis}
            isSelected={selectedCourts.some(court => court._id === item._id)}
            isDisabled={!item.is_available || item.isPending}
            onPress={() => {
              updateSelectedCourts(currentCourts => {
                if (currentCourts.some(court => court._id === item._id)) {
                  return currentCourts.filter(court => court._id !== item._id);
                }

                return [...currentCourts, item];
              });
            }}
            flexDir="column"
          >
            {item.name}
            {item.isPending && (
              <SelectorItem.Text textTransform="uppercase" fontSize={8} strikeThrough={false}>
                pending in another booking
              </SelectorItem.Text>
            )}
          </SelectorItem>
          {needsSpacer ? <View flexBasis={platformAwareFlexBasis} height={0} key="spacer" /> : null}
        </>
      );
    },
    [updatedCourtsForOverlap.length, isOneColumn, selectedCourts, updateSelectedCourts]
  );

  const renderListTitle = useCallback(
    () => (
      <RentalSectionContainer>
        <Text textAlign="center" isLink underline onPress={() => setShowMap(true)}>
          View venue map
        </Text>
      </RentalSectionContainer>
    ),
    []
  );

  /*
    with how light these components are this implementation works just fine
    - may not be the best to replicate on heavier components in other features though
  */
  const renderCourtList = () => {
    return isOneColumn ? (
      <FlatList
        key="one-column-list"
        numColumns={1}
        // @ts-ignore - gap valid style prop
        contentContainerStyle={{ gap: horizGap }}
        ListHeaderComponent={renderListTitle}
        data={updatedCourtsForOverlap}
        renderItem={renderCourtItems}
        keyExtractor={item => item._id}
      />
    ) : (
      <FlatList
        key="two-column-list"
        numColumns={numColumns}
        // @ts-ignore - gap valid style prop
        columnWrapperStyle={{ gap: platformAwareGap }}
        // @ts-ignore - gap valid style prop
        contentContainerStyle={{ gap: horizGap }}
        ListHeaderComponent={renderListTitle}
        data={updatedCourtsForOverlap}
        renderItem={renderCourtItems}
        keyExtractor={item => item._id}
      />
    );
  };

  const renderComponent = () => {
    if (!data?.courtsByTimeRange.courts.length && loading) {
      return (
        <View mt={5} flex={1} alignItems="center" justifyItems="center">
          <Spinner />
        </View>
      );
    }

    return renderCourtList();
  };

  return (
    <View>
      <RentalSectionContainer>
        <RentalSectionTitle>Select court</RentalSectionTitle>
        <RentalSubtext>
          Choose one or more available courts in your selected time frame!
        </RentalSubtext>
      </RentalSectionContainer>
      <RentalDivider />
      <RentalSectionContainer>
        {error ? (
          <RentalErrorComponent
            error={error}
            fallBackMessage="There was a problem getting the available courts, please try again"
            refetch={refetch}
          />
        ) : (
          renderComponent()
        )}
      </RentalSectionContainer>
      <ModalAlertComponent id="courts-section-modal-alert" />
      <RentalVenueMap
        ImageComponent={ImageComponent}
        rentalId={rentalId}
        variant="modal"
        isOpen={showMap}
        onClose={() => setShowMap(false)}
      />
    </View>
  );
};

export default CourtsSection;
