import { gql, type TypedDocumentNode } from '@apollo/client';

type SelfWithdrawInput = {
  input: {
    registrantId: string;
  };
};

export type SelfWithdrawResult = {
  currentUserSelfWithdraw: {
    registrant: {
      _id: string;
    };
  };
};

export const CURRENT_USER_SELF_WITHDRAW: TypedDocumentNode<SelfWithdrawResult, SelfWithdrawInput> =
  gql`
    mutation currentUserSelfWithdraw($input: CurrentUserSelfWithdrawInput!) {
      currentUserSelfWithdraw(input: $input) {
        registrant {
          _id
        }
      }
    }
  `;
