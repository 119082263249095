import { gql } from '@apollo/client';

const TeamPlayersFragment = gql`
  fragment TeamPlayersFragment on Team {
    _id
    players {
      _id
      displayNameTeams
    }
  }
`;

export default TeamPlayersFragment;
