import { StripeSubscriptionStatusEnum, StripeMembershipIntervalEnum } from '../constants';
import { GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS } from './gql/GET_USER_MEMBERSHIP_DETAILS';

/**
 * @description Global location for user Volo Pass detail queries.
 *
 * Try to add fields to existing variants before creating a brand new one.
 *
 * Adding fields shouldn't affect existing uses, but don't delete unless you're **sure** the fields are unused.
 *
 * Add notes when updating this doc.
 */

// #region Active Memberships
export const getActiveMonthlyVoloPassMember = [
  {
    request: {
      query: GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: true,
        userHasPastVoloPassMembership: true,
        activeVoloPassMembership: {
          stripeSubscriptionId: 'sub_1Mhe6sCyzbeSckFnB2BBpC0j',
          stripePlanId: 'prod_F8QWiJBlmvvEl7',
          planStartsAt: '2023-03-03T19:17:41.000Z',
          planExpiresAt: '2024-08-03T19:17:41.000Z',
          status: StripeSubscriptionStatusEnum.ACTIVE,
          billingCycleAnchor: '2023-03-03T19:17:41.000Z',
          plan: {
            id: 'plan_F8QXZqAR9IgHsf',
            interval: StripeMembershipIntervalEnum.MONTH,
          },
          metadata: {
            organization: 'Super Sportz',
            organizationId: '5cd91a7033db650050758e2f',
          },
        },
        pastVoloPassMembership: null,
      },
    },
  },
];

export const getActiveAnnualVoloPassMember = [
  {
    request: {
      query: GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: true,
        userHasPastVoloPassMembership: true,
        activeVoloPassMembership: {
          stripeSubscriptionId: 'sub_1Pn1JJCyzbeSckFnq2Ep0nAt',
          stripePlanId: 'prod_F8QWiJBlmvvEl7',
          planStartsAt: '2024-08-12T16:41:33.000Z',
          planExpiresAt: '2025-09-16T17:53:56.000Z',
          status: StripeSubscriptionStatusEnum.ACTIVE,
          billingCycleAnchor: '2024-09-16T17:53:56.000Z',
          plan: {
            id: 'price_1KbsKWCyzbeSckFnBVjsjgIk',
            interval: StripeMembershipIntervalEnum.YEAR,
          },
          metadata: {
            organization: 'Super Sportz',
            organizationId: '5cd91a7033db650050758e2f',
          },
        },
        pastVoloPassMembership: null,
      },
    },
  },
];
// #endregion

// #region Cancelled memberships
export const getCancelledMonthlyVoloPassMember = [
  {
    request: {
      query: GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: true,
        activeVoloPassMembership: null,
        pastVoloPassMembership: {
          stripeSubscriptionId: 'sub_1Mhe6sCyzbeSckFnB2BBpC0j',
          stripePlanId: 'prod_F8QWiJBlmvvEl7',
          planStartsAt: '2023-03-03T19:17:41.000Z',
          planExpiresAt: '2024-08-03T19:17:41.000Z',
          status: StripeSubscriptionStatusEnum.CANCELED,
          billingCycleAnchor: '2023-03-03T19:17:41.000Z',
          plan: {
            id: 'plan_F8QXZqAR9IgHsf',
            interval: StripeMembershipIntervalEnum.MONTH,
          },
          metadata: {
            organization: 'Super Sportz',
            organizationId: '5cd91a7033db650050758e2f',
          },
        },
      },
    },
  },
];

export const getCancelledAnnualVoloPassMember = [
  {
    request: {
      query: GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: true,
        activeVoloPassMembership: null,
        pastVoloPassMembership: {
          stripeSubscriptionId: 'sub_1Pn1JJCyzbeSckFnq2Ep0nAt',
          stripePlanId: 'prod_F8QWiJBlmvvEl7',
          planStartsAt: '2024-08-12T16:41:33.000Z',
          planExpiresAt: '2025-08-12T16:41:33.000Z',
          status: StripeSubscriptionStatusEnum.CANCELED,
          billingCycleAnchor: '2024-08-12T16:41:33.000Z',
          plan: {
            id: 'price_1KbsKWCyzbeSckFnBVjsjgIk',
            interval: StripeMembershipIntervalEnum.YEAR,
          },
          metadata: {
            organization: 'Super Sportz',
            organizationId: '5cd91a7033db650050758e2f',
          },
        },
      },
    },
  },
];
// #endregion

// #region Never Member
export const getNeverVoloPassMember = [
  {
    request: {
      query: GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: false,
        activeVoloPassMembership: null,
        pastVoloPassMembership: null,
      },
    },
  },
];
// #endregion
