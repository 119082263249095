import { useQuery, type QueryHookOptions } from '@apollo/client';
import GET_CAPTAINS_AND_USER, {
  type GetCaptainAndUserData,
  type GetCaptainAndUserInput,
} from './graphql/GET_CAPTAINS_AND_USER';

/**
 * @param {string} teamId - The ID of the team to check captain status for.
 * @returns {Object} An object containing the following properties:
 * - currentUserId: the ID of the current user.
 * - captainId: the ID of the team's captain.
 * - coCaptainIds: an array of the IDs of the team's co-captains.
 * - isCaptain: whether the current user is the captain of the team.
 * - isCoCaptain: whether the current user is a co-captain of the team.
 * - hasCaptainPermissions: whether the current user is a captain, co-captain
 * - loading, error, fetch: useQuery returns
 */
const useGetTeamCaptainsAndUserStatus = (
  teamId?: string,
  options: QueryHookOptions<GetCaptainAndUserData, GetCaptainAndUserInput> = {}
) => {
  const {
    data: { team, currentUser } = {},
    loading,
    error,
    refetch,
  } = useQuery(GET_CAPTAINS_AND_USER, {
    skip: !teamId,
    variables: {
      id: teamId!,
    },
    ...options,
  });

  const captainId = team?.captainId || null;
  const coCaptainIds = team?.coCaptainIds || [];

  const currentUserId = currentUser?._id;

  const isCaptain = currentUserId ? captainId === currentUserId : false;
  const isCoCaptain = currentUserId ? coCaptainIds.includes(currentUserId) : false;

  return {
    currentUserId,
    captainId,
    coCaptainIds,
    isCaptain,
    isCoCaptain,
    hasCaptainPermissions: isCaptain || isCoCaptain,
    loading,
    error,
    refetch,
  };
};

export default useGetTeamCaptainsAndUserStatus;
