/**
 * A formatter which accepts a number representing the price of an item in dollars into the correctly
 * formatted string.
 *
 * @example
 * ```ts
 * console.log(WholeDollarFormatter.format(5)) // '$5.00'
 * console.log(WholeDollarFormatter.format(5.00)) // '$5.00'
 * console.log(WholeDollarFormatter.format(5.49)) // '$5.49'
 * console.log(WholeDollarFormatter.format(5.5)) // '$5.50'
 * console.log(WholeDollarFormatter.format(5.99)) // '$5.99'
 * ```
 */
export const PriceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
});

/**
 * A formatter that formats a number representing the price of an item in dollars into a whole dollar
 * amount string.
 *
 * @example
 * ```ts
 * console.log(WholeDollarFormatter.format(5)) // '$5'
 * console.log(WholeDollarFormatter.format(5.00)) // '$5'
 * console.log(WholeDollarFormatter.format(5.49)) // '$5'
 * console.log(WholeDollarFormatter.format(5.5)) // '$6'
 * console.log(WholeDollarFormatter.format(5.99)) // '$6'
 * ```
 */
export const WholeDollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
