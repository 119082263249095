import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface ContentCaptureProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  contentCapture_response: boolean;
  contentCaptureAttachments?: { fileName: string; onPress: () => void }[]; // may change when we add an uploader
}

const ContentCapture: React.FC<ContentCaptureProps> = ({
  contentCapture_response = false,
  contentCaptureAttachments = [],
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Content Capture"
      view="read"
      toggleQuestion="Do you have player photos/videos to upload?"
      toggleResponse={contentCapture_response}
      {...props}
    >
      {contentCaptureAttachments.length ? (
        <Box paddingTop={3} flexDirection="row" flexWrap="wrap">
          {contentCaptureAttachments.map(attachment => (
            <CollapseForm.FileUpload
              key={attachment.fileName}
              fileName={attachment.fileName}
              onPress={attachment.onPress}
            />
          ))}
        </Box>
      ) : null}
      {children}
    </CollapseForm>
  );
};

export default ContentCapture;
