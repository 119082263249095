/* eslint-disable react/no-array-index-key */
import GridRow from '../components/GridRow';
import type { IGrid } from '../types';

const MapGrid = ({ data, RenderItem }: Omit<IGrid, 'RenderGrid' | 'scrollEnabled'>) => (
  <>
    {data.map(row => (
      <GridRow key={row.rowId} items={row.items} RenderItem={RenderItem} />
    ))}
  </>
);

export default MapGrid;
