export enum SearchUserQueryEnum {
  EMAIL = 'email',
  USERNAME = 'username',
}

export const SearchUserQueryTypes: Record<
  SearchUserQueryEnum,
  { value: SearchUserQueryEnum; label: string }
> = {
  [SearchUserQueryEnum.EMAIL]: {
    value: SearchUserQueryEnum.EMAIL,
    label: 'Email',
  },
  [SearchUserQueryEnum.USERNAME]: {
    value: SearchUserQueryEnum.USERNAME,
    label: 'Username',
  },
};
