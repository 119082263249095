import { Animated, Platform } from 'react-native';

const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';

type AnimationConfig = {
  scale: Animated.Value;
  opacity: Animated.Value;
  startScale?: number;
  startOpacity?: number;
  endOpacity?: number;
  endScale?: number;
};

const MASS = 1.5;
const STIFFNESS = 150;

/**
 * Animations for Pressable component to give a touchable effect (to get feedback on mobile)
 * - `opacity` press animation
 * @platform android, ios - `scale` animation
 */
export const startAnimation = ({
  scale,
  opacity,
  endOpacity = 0.7,
  endScale = 0.98,
}: AnimationConfig) => {
  Animated.parallel([
    ...(isMobile
      ? [
          Animated.spring(scale, {
            toValue: endScale,
            mass: MASS,
            stiffness: STIFFNESS,
            useNativeDriver: true,
          }),
        ]
      : []),
    Animated.timing(opacity, {
      toValue: endOpacity,
      duration: 100,
      useNativeDriver: true,
    }),
  ]).start();
};

/**
 * Animations for Pressable component to give a touchable effect (to get feedback on mobile)
 * - `opacity` press animation
 * @platform android, ios - `scale` animation
 */
export const endAnimation = ({
  scale,
  opacity,
  startOpacity = 1,
  startScale = 1,
}: AnimationConfig) => {
  Animated.parallel([
    ...(isMobile
      ? [
          Animated.spring(scale, {
            toValue: startScale,
            mass: MASS,
            stiffness: STIFFNESS,
            useNativeDriver: true,
          }),
        ]
      : []),
    Animated.timing(opacity, {
      toValue: startOpacity,
      duration: 100,
      useNativeDriver: true,
    }),
  ]).start();
};
