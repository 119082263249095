import { Text, View } from '../../../base-components';
import styles from '../styles';

export interface EmptyPillProps {
  rosterNumber: number | undefined;
  pillLabel: string;
}

const EmptyPill = ({ rosterNumber, pillLabel }: EmptyPillProps) => (
  <View style={styles.pillNumberWrapper}>
    <Text style={styles.gameRosterNumber}>{rosterNumber}</Text>
    <View style={[styles.pillContainer, styles.dropInWrapper]}>
      <Text style={[styles.textLabel, styles.label, styles.dropInLabel]}>{pillLabel}</Text>
    </View>
  </View>
);

export default EmptyPill;
