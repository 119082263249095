import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabLabel: {
    paddingHorizontal: 25,
    paddingVertical: 3,
    minWidth: 100,
  },
});
