import { useTheme } from '../../../../../theme';
import { Row, Skeleton, Text, type ITextProps } from '../../../../../base-components';

/**
 * Styled Row component that displays a label and a value.
 * - If loading is true, a Skeleton component is displayed.
 * - If onRemove is provided, a remove link is displayed.
 */
const LineItem = ({
  label,
  labelStyle,
  value,
  valueStyle,
  loading,
  onRemove,
  removeLabelStyle,
}: {
  label: string;
  labelStyle?: ITextProps;
  value?: string;
  valueStyle?: ITextProps;
  loading?: boolean;
  onRemove?: () => void;
  removeLabelStyle?: ITextProps;
}) => {
  const { colors } = useTheme();

  return (
    <Row justifyContent="space-between" alignItems="center" flex={1}>
      <Text flex={1} fontSize={14} {...labelStyle}>
        {label}
        {'   '}
        {onRemove ? (
          <Text
            colorScheme="error"
            fontSize="xs"
            isLink
            onPress={onRemove}
            underline
            {...removeLabelStyle}
          >
            Remove
          </Text>
        ) : (
          ''
        )}
      </Text>
      {loading ? (
        <Skeleton
          startColor={colors.border}
          endColor={colors.card}
          height={4}
          width={50}
          borderRadius="md"
        />
      ) : (
        <Text fontSize={14} {...valueStyle}>
          {value || '--'}
        </Text>
      )}
    </Row>
  );
};
export default LineItem;
