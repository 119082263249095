import { StyleSheet } from 'react-native';
import { Colors, voloGreys } from '../../theme';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 15,
    marginHorizontal: 5,
  },
  content: {
    marginTop: 15,
    minHeight: 125,
  },
  name: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 10,
  },
  rosterPictureContainer: {
    borderRadius: 50,
    width: 45,
    height: 45,
    marginRight: 10,
    backgroundColor: voloGreys.medium,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  rosterPicture: {
    width: 45,
    height: 45,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    flexGrow: 1,
    maxWidth: 125,
    marginRight: 10,
    marginBottom: 10,
  },
  buttonText: {
    flex: 1,
    fontWeight: 'bold',
  },
  hiddenText: {
    textAlign: 'left',
    fontSize: 12,
    lineHeight: 14,
  },
  userInitials: {
    color: Colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 1,
  },
});
