import { type AlertStoreActions } from './store/modalAlert.types';
import useModalAlertStore from './store/useModalAlertStore';

/**
 * Methods to call the `ModalAlert` component imperatively
 * - `ModalAlertComponent` is placed as child in `ThemeProvider` component to be used globally
 * ---
 * @important
 * - ios seems to have issues if you are calling the `ModalAlert` component within another `Modal` component that utilizes the `useRNModal` prop as well
 *    - consider refactoring to not need the use for the `useRNModal` prop in the per `Modal` component that is causing the issue
 * @see https://github.com/rivallapp/volosports-components/pull/177#discussion_r1525256220
 */
const ModalAlert: AlertStoreActions = Object.freeze({
  alert: useModalAlertStore.getState().alert,
  close: useModalAlertStore.getState().close,
});

export default ModalAlert;
