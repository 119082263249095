import * as Sentry from '@sentry/react';
import Braze from '../../../utils/braze';

type SyncUserWithServicesArgs = {
  userId: string;
  email: string;
  externalId?: string;
};

/**
 * Currently responsible for:
 * 1. Setting the current user with Braze
 *   - This uses the external_id when available to maintain compatibility with old mongo users.
 * 2. Providing user scope to Sentry
 *
 * TODO [MIGRATION]: Remove userId as a parameter once we are fully migrated to Hasura.
 */
const syncUserWithServices = ({ userId, email, externalId }: SyncUserWithServicesArgs) => {
  const braze = new Braze();
  braze.changeUser(externalId ?? userId);
  Sentry.configureScope(scope => {
    scope.setUser({
      id: userId,
      email,
    });
  });
};

export default syncUserWithServices;
