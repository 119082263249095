import React, { useCallback, useState } from 'react';
import type { IModalProps } from '@rivallapp/native-base';
import { ErrorBoundary } from 'react-error-boundary';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { Platform } from 'react-native';
import {
  FontAwesomeIcon,
  IconButton,
  Popover,
  Text,
  Stack,
  Modal,
  type ITextProps,
  type IFontAwesomeIconProps,
  Pressable,
} from '../../base-components';

const BULLET = '\u2022';

type TextProps = {
  variant: 'text';
  textProps?: Partial<ITextProps>;
};

type IconProps = {
  variant: 'icon';
  iconProps?: Partial<IFontAwesomeIconProps>;
};

type WhatsADropInProps = (TextProps | IconProps) & {
  popupBehavior?: 'popover' | 'modal';
};

const WhatsADropIn: React.FC<WhatsADropInProps> = props => {
  const { variant = 'icon', popupBehavior = 'popover' } = props;

  // Only used for modal
  const [isOpen, setIsOpen] = useState(false);

  const ModalOrPopover = popupBehavior === 'popover' ? Popover : Modal;

  // Build the component that will be rendered, either an icon or text
  const ChildComponent = useCallback(
    (triggerProps: any) => {
      if (variant === 'text') {
        if (Platform.OS === 'android') {
          return (
            /**
             * Workaround for Android where the link isn't aligned properly due to the Pressable container.
             * It works fine on iOS and web but for whatever reason refuses to align properly on Android.
             */
            // eslint-disable-next-line react/destructuring-assignment
            <Text
              isLink
              {...('textProps' in props ? (props.textProps ?? {}) : {})}
              {...triggerProps}
            >
              What&apos;s a drop-in?
            </Text>
          );
        }

        return (
          <Pressable alignSelf="flex-start" {...triggerProps}>
            {/* eslint-disable-next-line react/destructuring-assignment */}
            <Text isLink {...('textProps' in props ? (props.textProps ?? {}) : {})}>
              What&apos;s a Drop-in?
            </Text>
          </Pressable>
        );
      }

      return (
        <IconButton
          icon={<FontAwesomeIcon icon={faCircleInfo} />}
          p="0"
          marginLeft="2"
          variant="link"
          {...triggerProps}
          // eslint-disable-next-line react/destructuring-assignment
          {...('iconProps' in props ? props.iconProps : {})}
        />
      );
    },
    [variant, props]
  );

  const modalProps: IModalProps =
    popupBehavior === 'modal'
      ? {
          isOpen,
          onClose: () => setIsOpen(false),
          size: 'xl',
          useRNModal: true,
        }
      : {};

  return (
    <>
      {popupBehavior === 'modal' && <ChildComponent onPress={() => setIsOpen(true)} />}
      <ModalOrPopover trigger={ChildComponent} {...modalProps} useRNModal>
        <ModalOrPopover.Content>
          {popupBehavior === 'popover' && <Popover.Arrow />}
          <ModalOrPopover.CloseButton />
          <ModalOrPopover.Header>What&apos;s a Drop-In?</ModalOrPopover.Header>
          <ModalOrPopover.Body>
            <Stack space="2">
              <Text color="text.500">
                A drop-in is a player who participates as a substitute for team members who are
                needed for a game. Drop-in players help teams avoid forfeit.
              </Text>
              <Text marginLeft="2">
                {BULLET} Playing Time Expectations:{' '}
                <Text color="text.500">
                  For league drop-ins expect to play about half of the game. However, playing time
                  can always vary.
                </Text>
              </Text>
              <Text marginLeft="2">
                {BULLET} Play With Friends:{' '}
                <Text color="text.500">
                  Sign up to play with your friends on the same team by searching for teams with
                  multiple drop-in spots available.
                </Text>
              </Text>
              <Text marginLeft="2">
                {BULLET} Competition Level:{' '}
                <Text color="text.500">
                  Only drop-in to a competitive league if you know your skill set and are
                  comfortable playing at a high level.
                </Text>
              </Text>
              <Text>
                Pro-tips:{' '}
                <Text color="text.500">
                  Be early!! Check if there are any notes! Use Chat to communicate with your team!!
                </Text>
              </Text>
            </Stack>
          </ModalOrPopover.Body>
        </ModalOrPopover.Content>
      </ModalOrPopover>
    </>
  );
};

// There's no data loading in this component, but if this throws an error for whatever reason it's
// best just not to render anything than show a broken screen.
const WhatsADropInWrapper = (props: WhatsADropInProps) => (
  <ErrorBoundary fallback={null}>
    <WhatsADropIn {...props} />
  </ErrorBoundary>
);

export default WhatsADropInWrapper;
