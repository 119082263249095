import { HaloChip } from '../../../../../custom-components';
import { Pressable, type PressableProps } from '../../../../../base-components';
import { type HaloChipProps } from '../../../../../custom-components/HaloChip/HaloChip';

interface SelectorItemProps extends HaloChipProps {
  isSelected: boolean;
  isDisabled: boolean;
  onPress: PressableProps['onPress'];
}

/**
 * Base SelectorItem component needed for the rental flow
 * - utilizes the pre styled `HaloChip` component for easy themed styling
 */
const SelectorItem = ({
  children,
  isSelected = false,
  isDisabled = false,
  onPress,
  ...rest
}: SelectorItemProps) => {
  return (
    <Pressable height={50} onPress={onPress} isDisabled={isDisabled} flex={1}>
      <HaloChip
        flex={1}
        height={50}
        borderWidth={isDisabled ? 0 : 1}
        alignSelf="stretch"
        justifyContent="center"
        alignItems="center"
        colorScheme={isSelected ? 'rentalBlue' : 'warmGray'}
        _text={{
          fontSize: 16,
          bold: false,
          ...(!isSelected
            ? {
                _light: {
                  color: 'lightText',
                },
                _dark: {
                  color: 'darkText',
                },
              }
            : {}),
          ...(isDisabled ? { color: 'gray.400', strikeThrough: true } : {}),
        }}
        {...(isDisabled ? { opacity: 0 } : {})}
        {...rest}
      >
        {children}
      </HaloChip>
    </Pressable>
  );
};

export default SelectorItem;

SelectorItem.Text = HaloChip.Text;
SelectorItem.Icon = HaloChip.Icon;
