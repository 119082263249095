import { useCallback } from 'react';

import { MembershipEnum } from '../../../constants';
import { Colors } from '../../../theme';
import { View, Text, Button } from '../../../base-components';
import { type MembershipCardProps } from '../MembershipCard';

const NonMemberSection = ({
  variant,
  membershipPrice,
  learnMore,
}: Pick<MembershipCardProps, 'membershipPrice' | 'variant' | 'learnMore'>) => {
  const renderJoinText = useCallback(() => {
    switch (variant) {
      case MembershipEnum.VOLO_PASS:
        return (
          <Text textAlign="center" color={Colors.white} fontSize={16}>
            Get unlimited <Text color="lightBlue.200">sports</Text> on demand
          </Text>
        );
      case MembershipEnum.VOLO_FITNESS:
        return (
          <Text textAlign="center" color={Colors.white} fontSize={16}>
            Join Volo Fitness for ${membershipPrice} a month and get unlimited{' '}
            <Text color="gray.600">fitness</Text> classes
          </Text>
        );
      default:
        return null;
    }
  }, [membershipPrice, variant]);

  const joinText = renderJoinText();

  return (
    <View alignItems="center" flex={1} marginBottom={3}>
      {joinText}
      <Button
        onPress={learnMore}
        variant="outline"
        borderColor={Colors.white}
        padding={0} // clear NB default
        margin={0} // clear NB default
        marginTop={1.5}
        marginBottom={1.5}
        width={320}
        height={39}
        borderRadius={15}
        _text={{ color: Colors.white, fontSize: 15, fontWeight: 400, bold: false }}
      >
        Learn More
      </Button>
    </View>
  );
};

export default NonMemberSection;
