import type { FallbackProps } from 'react-error-boundary';
import { PaymentForm } from '../../custom-components';
import { Box, Text, Button, Link } from '../../base-components';

export class PaymentSummaryVPExclusiveError extends Error {}

const PaymentSummaryError = ({ resetErrorBoundary, error }: FallbackProps) => {
  return (
    <PaymentForm>
      <Box minH="20">
        <Text textAlign="center">
          {error instanceof PaymentSummaryVPExclusiveError ? (
            <>
              Unable to register for this program. This program is exclusive to Volo Pass members,
              but your city does not have Volo Pass yet! Please reach out to{' '}
              <Link href="mailto:volopass@volosports.com">volopass@volosports.com</Link> with any
              questions or concerns!
            </>
          ) : (
            'An error occurred while fetching the pricing for this program. Please try again later.'
          )}
        </Text>
        <Button marginTop="3" onPress={resetErrorBoundary}>
          Retry
        </Button>
      </Box>
    </PaymentForm>
  );
};

export default PaymentSummaryError;
