import { useSuspenseQuery } from '@apollo/client';
import moment from 'moment-timezone';
import type { IStackProps } from '@rivallapp/native-base';
import { Suspense } from 'react';
import { useRentalBookingStore, getCompositeKey } from '../../../RentalsRegistration';
import { getValueFromActivityName, type TRentalActivityName } from '../../../../constants';
import { DiscoverRentalCard } from '../../../Discover';
import { Stack } from '../../../../base-components';
import GET_OPEN_RENTALS from './graphql/GET_OPEN_RENTALS';
import { RentalHeading } from '../RentalCommonText';
import { isMobile } from '../../../../utilities';
import { DiscoverLoading } from '../../../Discover/components';

type Props = {
  venueId: string;
  /**
   * Starts booking process for the given rental. See `initBookingFlow`
   */
  startBooking: (rentalId: string, activity_name: TRentalActivityName) => void;
  containerStyle?: IStackProps;
};

/**
 * List of open rentals component found on rentals venue view. Uses `discoverRentals` query.
 */
const OpenRentals = ({ venueId, startBooking, containerStyle = {} }: Props) => {
  const { data } = useSuspenseQuery(GET_OPEN_RENTALS, {
    variables: {
      input: {
        venueIds: [venueId],
        dateLow: moment().startOf('day').toISOString(),
      },
    },
  });

  const inProgressMap = useRentalBookingStore(store => store.inProgressMap);

  return (
    <Stack space={2} {...containerStyle}>
      <RentalHeading>Open Rentals</RentalHeading>
      {data.discoverRentals.rentals.map(({ _id, activity_name }) => {
        const booking =
          inProgressMap[
            getCompositeKey({ _id, activity_name: getValueFromActivityName(activity_name) })
          ];

        return (
          <DiscoverRentalCard
            key={_id}
            id={_id}
            viewType="sportView"
            onCardPress={() => startBooking(_id, activity_name)}
            overrideBannerMessage={booking ? 'In Progress' : undefined}
          />
        );
      })}
    </Stack>
  );
};

const Loading = () => (
  <Stack space={2}>
    <RentalHeading>Open Rentals</RentalHeading>
    <DiscoverLoading width="60%" height={isMobile ? 130 : 300} marginX="auto" />
    <DiscoverLoading width="60%" height={isMobile ? 130 : 300} marginX="auto" />
  </Stack>
);

const SuspendedOpenRentals = (props: Props) => (
  <Suspense fallback={<Loading />}>
    <OpenRentals {...props} />
  </Suspense>
);

export default SuspendedOpenRentals;
