import { useMemo } from 'react';
import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons/faBadgeCheck';

import { MembershipEnum, StripeSubscriptionStatusEnum, MembershipStatus } from '../../../constants';
import { VoloNativeBaseShades } from '../../../theme';
import { View, Text, FontAwesomeIcon } from '../../../base-components';
import { type MemberSectionProps } from './MemberSection';
import { type MembershipCardProps } from '../MembershipCard';

const MemberPerks = ({
  membershipStatus,
  variant,
}: Pick<MemberSectionProps, 'membershipStatus'> & Pick<MembershipCardProps, 'variant'>) => {
  const { lightSelect, secondary } = VoloNativeBaseShades;

  const variantProps = useMemo(() => {
    switch (variant) {
      case MembershipEnum.VOLO_PASS:
        return {
          memberPerks: [
            'Access unlimited daily sports programs including pickups, practices, and clinics',
            'Play as a drop-in sub for active leagues and series',
            'Exclusive member pricing on leagues, fitness classes and tournaments',
            'Access to weekly social hours + VIP events and experiences',
            'Travel the country and access your member perks in our 9 cities',
          ],
          iconColor: secondary[600],
        };
      case MembershipEnum.VOLO_FITNESS:
        return {
          memberPerks: ['Unlimited fitness classes and series'],
          iconColor: lightSelect[600],
        };
      default:
        return {
          memberPerks: [],
          iconColor: 'gray.400',
        };
    }
  }, [secondary, lightSelect, variant]);

  return (
    <>
      {variantProps.memberPerks.map(perk => (
        <View flexDirection="row" alignItems="center" marginTop={2} key={`${variant}-${perk}`}>
          <FontAwesomeIcon
            icon={faBadgeCheck}
            size={4}
            color={
              membershipStatus === MembershipStatus[StripeSubscriptionStatusEnum.ACTIVE].label
                ? variantProps.iconColor
                : 'gray.400'
            }
            marginRight={2}
          />
          <Text fontSize={12} flex={1}>
            {perk}
          </Text>
        </View>
      ))}
    </>
  );
};

export default MemberPerks;
