import { gql, type TypedDocumentNode } from '@apollo/client';
import { ProgramTypeEnum } from '../../../../constants';

export type GameDetailsResponse = {
  game: {
    _id: string;
    start_time: Date;
    end_time: Date;
    location: {
      _id: string;
      neighborhood: string;
      name: string;
      formatted_address: string;
    };
    dropInCapacity: {
      f: number;
      a: number;
      m: number;
    };
    league: {
      _id: string;
      programType: ProgramTypeEnum;
      sport: {
        _id: string;
        name: string;
      };
      registration: {
        _id: string;
        voloFitnessDropinPrice?: number;
      };
    };
  };
};

export type GameDetailsInput = {
  id: string;
};

const GET_GAME_DETAILS: TypedDocumentNode<GameDetailsResponse, GameDetailsInput> = gql`
  query Game($id: ID!) {
    game(_id: $id) {
      _id
      start_time
      end_time
      location {
        _id
        neighborhood
        name
        formatted_address
      }
      dropInCapacity {
        f
        a
        m
      }
      league {
        _id
        programType
        sport {
          _id
          name
        }
        registration {
          _id
          voloFitnessDropinPrice
        }
      }
    }
  }
`;

export default GET_GAME_DETAILS;
