import { StyleSheet } from 'react-native';
import tinycolor from 'tinycolor2';
import { Colors } from '../../theme';

const minimumNotMetColor = Colors.accents.no;
const suggestedNotMetColor = Colors.accents.maybe;
const checksPassedColor = Colors.accents.yes;

export default StyleSheet.create({
  container: {
    marginVertical: 15,
    height: 100,
    borderRadius: 10,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  gameMessage: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: 21,
    alignItems: 'center',
  },
  minimumNotMet: {
    borderColor: minimumNotMetColor,
    // @ts-ignore
    backgroundColor: tinycolor(minimumNotMetColor).lighten(35).toRgbString(),
  },
  suggestedNotMet: {
    borderColor: suggestedNotMetColor,
    // @ts-ignore
    backgroundColor: tinycolor(suggestedNotMetColor).lighten(35).toRgbString(),
  },
  checksPassed: {
    borderColor: checksPassedColor,
    // @ts-ignore
    backgroundColor: tinycolor(checksPassedColor).lighten(35).toRgbString(),
  },
  infoLink: {
    fontSize: 12,
    textAlign: 'left',
    fontWeight: 'normal',
  },
});
