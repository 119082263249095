import { useSuspenseQuery } from '@apollo/client';
import type { IHStackProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Stack/HStack';
import { type TRentalActivityName } from '../../../../constants';
import { WholeDollarFormatter } from '../../../../utilities';
import { Row, Stack, Button, Text, type ButtonProps } from '../../../../base-components';
import GET_RENTAL_PRICING from './graphql/GET_RENTAL_PRICING';
import { useRentalBookingStore } from '../../../RentalsRegistration';

type Props = {
  rentalId: string;
  onPress: (rentalId: string, activity_name: TRentalActivityName) => void;
  containerStyle?: IHStackProps;
  buttonStyle?: ButtonProps;
};

/**
 * Pricing information for `sportView`, aka a specific rental.
 * Rentals may or may not have prime pricing.
 *
 * Used on both web and mobile.
 */
const PricingSportView = ({ rentalId, onPress, containerStyle, buttonStyle }: Props) => {
  const { data } = useSuspenseQuery(GET_RENTAL_PRICING, {
    variables: {
      rentalId,
    },
  });

  const {
    _id,
    activity_name,
    standard_price_cents,
    vp_standard_price_cents,
    prime_price_cents = 0,
    vp_prime_price_cents = 0,
  } = data.rental;

  const formattedPrimePrice = Math.round(prime_price_cents / 100);
  const formattedVpPrimePriceCents = Math.round(vp_prime_price_cents / 100);

  // Top range prices are displayed without the $ sign
  const standardPrice = WholeDollarFormatter.format(standard_price_cents / 100);
  const primePrice = prime_price_cents ? formattedPrimePrice : '';
  const vpStandardPrice = WholeDollarFormatter.format(vp_standard_price_cents / 100);
  const vpPrimePrice = vp_prime_price_cents ? formattedVpPrimePriceCents : '';

  const showPrimePriceNonVP = prime_price_cents && prime_price_cents !== standard_price_cents;
  const showPrimePriceVP = vp_prime_price_cents && vp_prime_price_cents !== vp_standard_price_cents;

  const { hasAConfirmedBooking, hasCurrentOrInProgressBooking } = useRentalBookingStore(store => ({
    hasAConfirmedBooking: Object.values(store.confirmedBookings).length > 0,
    hasCurrentOrInProgressBooking:
      store.currentBooking !== null || Object.values(store.inProgressBookings).length > 0,
  }));

  function getButtonText() {
    if (hasCurrentOrInProgressBooking || !hasAConfirmedBooking) {
      return 'Continue';
    }

    return 'Book more time';
  }

  return (
    <Row justifyContent="space-between" alignItems="center" {...containerStyle}>
      <Stack>
        <Row alignItems="baseline">
          <Text bold type="h3">
            {standardPrice}
            {showPrimePriceNonVP && `-${primePrice}`}
          </Text>
          <Text fontSize={12}>/HR</Text>
        </Row>
        <Text fontSize={12}>
          MEMBER PRICE: {vpStandardPrice}
          {showPrimePriceVP && `-${vpPrimePrice}`}
          <Text fontSize={10}>/HR</Text>
        </Text>
      </Stack>
      <Button
        height="min-content"
        px={3}
        py={1}
        colorScheme="rentalBlue"
        onPress={() => onPress(_id, activity_name)}
        {...buttonStyle}
      >
        {getButtonText()}
      </Button>
    </Row>
  );
};

export default PricingSportView;
