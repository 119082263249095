import { useCallback, useState } from 'react';
import { ActivityIndicator, Share, Platform } from 'react-native';
import { useClipboard } from '@rivallapp/native-base';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/pro-light-svg-icons/faArrowUpFromBracket';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';

import { Colors, primaryShades, useTheme } from '../../../theme';
import { Text, View, TouchableOpacity, Modal, Badge } from '../../../base-components';
import { EditDropin } from '../../EditDropin';
import { DeleteDropin } from '../../DeleteDropin';
import HoldTimer from '../HoldTimer';
import PillXButton from '../PillXButton';
import {
  useShareDropInSlot,
  useCloseDropInSlot,
  useDropInSlotDetails,
  useOpenDropInSlot,
} from '../../GameRoster/hooks';
import { getErrorMessage } from '../../../utilities';
import { DropInSlotGenderEnum_TS, ErrorMessageEnum } from '../../../constants/enums';
import styles from '../styles';

interface DropInPillProps {
  rosterNumber: number | undefined;
  open?: boolean;
  womenOnly?: boolean;
  /**
   * If current user is a captain or co-captain of the team
   */
  hasCaptainPermissions?: boolean;
  /**
   * If current user is an admin
   * Only admins can assign players to drop-ins, otherwise admins have the same permissions as captains
   */
  isAdmin?: boolean;
  id?: string;
  gameId?: string;
  teamId?: string;
  expirationDate?: string;
  note?: string;
  setSuccess: (arg: string) => unknown | undefined;
  setError: (arg: string) => unknown | undefined;
  setSelectedDropin?: (arg: string) => unknown | undefined;
  dropInRefetch: () => unknown | undefined;
}

const DropInPill = ({
  rosterNumber,
  open = false,
  womenOnly = false,
  hasCaptainPermissions = false,
  isAdmin = false,
  id = '',
  gameId,
  teamId,
  expirationDate,
  note,
  setSuccess,
  setError,
  setSelectedDropin,
  dropInRefetch,
}: DropInPillProps) => {
  const { colors } = useTheme();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const { onShareSlot } = useShareDropInSlot();
  const { onOpenSlot, loading: openLoading } = useOpenDropInSlot({ gameId, teamId });
  const { onCloseSlot, loading: closeLoading } = useCloseDropInSlot();
  const { getSlot, slot } = useDropInSlotDetails({ slotId: id });
  const { onCopy } = useClipboard();
  const handleShare = useCallback(
    async (link: string) => {
      try {
        await Share.share({
          message: link,
        }).then(({ action }) => {
          if (action !== Share.dismissedAction) setSuccess('Link copied to clipboard!');
        });
      } catch (e) {
        setError(ErrorMessageEnum.DROPIN_COPY);
      }
    },
    [setSuccess, setError]
  );

  const handleCopy = async (link: string) => {
    try {
      await onCopy(link);
      setSuccess('Link copied to clipboard!');
    } catch (e) {
      setError(ErrorMessageEnum.DROPIN_COPY);
    }
  };

  const copyLink = (link: string) => (Platform.OS === 'web' ? handleCopy(link) : handleShare(link));

  const shareSlotById = async (slotId: string) => {
    try {
      await onShareSlot({
        slotId,
        onCompleted: async ({ shareDropInSlot }) => {
          await copyLink(shareDropInSlot?.deepLink);
          await dropInRefetch();
        },
        onError: async error => {
          const errorMessage = getErrorMessage(error);
          if (errorMessage?.toLowerCase()?.includes('taken')) {
            setError(ErrorMessageEnum.DROPIN_CLAIMED);
            await dropInRefetch();
          }
          setError(ErrorMessageEnum.DROPIN_SHARE);
          return null;
        },
      });
    } catch (error) {
      setError(ErrorMessageEnum.DROPIN_SHARE);
    }
  };

  const openSlotByGender = async (gender: string) => {
    try {
      await onOpenSlot({
        gender,
        onCompleted: async () => dropInRefetch(),
        onError: async error => {
          const errorMessage = getErrorMessage(error);
          if (errorMessage.toLowerCase()?.includes('max')) {
            setError(ErrorMessageEnum.DROPIN_MAX);
            await dropInRefetch();
          }
          setError(ErrorMessageEnum.DROPIN_OPENING);
          return null;
        },
      });
    } catch (error) {
      setError(ErrorMessageEnum.DROPIN_OPENING);
    }
  };

  const closeSlotById = async (slotId: string) => {
    try {
      const { data } = await getSlot({ variables: { id: slotId } });
      const { dropInSlot } = data!;
      if (dropInSlot?.holdExpiration) {
        setError(ErrorMessageEnum.DROPIN_HOLD);
        return;
      }
      if (dropInSlot.isRecurring) {
        setShowDeletePopup(true);
      } else {
        await onCloseSlot({
          slotId,
          onCompleted: async () => dropInRefetch(),
        });
      }
    } catch (error) {
      setError(ErrorMessageEnum.DROPIN_CLOSING);
    }
  };

  const onCloseEditDropin = async () => {
    await dropInRefetch?.();
    setShowEditPopup(false);
  };

  const handleShareSlot = async (slotId: string) => shareSlotById(slotId);
  const handleOpenSlot = async () =>
    openSlotByGender(womenOnly ? DropInSlotGenderEnum_TS.FEMALE : DropInSlotGenderEnum_TS.ANY);
  const handleCloseSlot = async (slotId: string) => closeSlotById(slotId);

  const handleEditSlot = () => setShowEditPopup(true);
  const handleShowNote = () => setShowNote(true);

  const renderIcon = () => {
    if (hasCaptainPermissions || isAdmin) {
      return (
        <View style={styles.editDropInContainer}>
          <TouchableOpacity
            onPress={handleEditSlot}
            style={{ marginRight: 10 }}
            disabled={!!expirationDate}
          >
            {note && (
              <Badge
                padding={0}
                height={2}
                width={2}
                rounded="full"
                variant="solid"
                bg={primaryShades[50]}
                zIndex={1}
                mb={-1.5}
                ml={-1}
                alignSelf="flex-start"
              />
            )}
            <FontAwesomeIcon
              icon={faPenToSquare}
              size={20}
              color={expirationDate ? Colors.blues.veryLight.toString() : colors.primary.toString()}
            />
          </TouchableOpacity>
          <PillXButton
            onPress={() => handleCloseSlot(id)}
            disabled={closeLoading || !!expirationDate}
            loading={closeLoading}
          />
        </View>
      );
    }
    if (note) {
      return (
        <TouchableOpacity onPress={handleShowNote}>
          <Badge
            padding={0}
            height={2}
            width={2}
            rounded="full"
            variant="solid"
            bg={primaryShades[50]}
            zIndex={1}
            mb={-1.5}
            ml={-1}
            alignSelf="flex-start"
          />
          <FontAwesomeIcon icon={faCircleInfo} color={colors.text.toString()} />
        </TouchableOpacity>
      );
    }
    return null;
  };

  const openDropInLabel = (
    <View
      style={[
        styles.sideLabelContainer,
        styles.sideLabelBackground,
        { backgroundColor: colors.border },
      ]}
    >
      {openLoading ? (
        <ActivityIndicator style={styles.textLabel} />
      ) : (
        <TouchableOpacity onPress={handleOpenSlot} disabled={openLoading}>
          <Text isLink style={styles.textLabel}>
            Open for
          </Text>
          <Text isLink style={styles.textLabel}>
            Drop-in
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );

  if (open) {
    return (
      <>
        <View style={styles.pillNumberWrapper}>
          <Text style={styles.gameRosterNumber}>{rosterNumber}</Text>
          <View style={[styles.pillContainer, styles.dropInWrapper]}>
            {isAdmin ? (
              <View
                style={[
                  styles.sideLabelBackground,
                  styles.sideLabelContainer,
                  { backgroundColor: colors.border },
                ]}
              >
                <TouchableOpacity
                  onPress={() => (setSelectedDropin ? setSelectedDropin(id) : null)}
                >
                  <Text isLink style={styles.textLabel}>
                    Assign Player
                  </Text>
                  <Text isLink style={styles.textLabel}>
                    to Drop-in
                  </Text>
                </TouchableOpacity>
              </View>
            ) : (
              <TouchableOpacity
                onPress={() => handleShareSlot(id)}
                style={styles.shareIconContainer}
              >
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  size={20}
                  color={colors.primary.toString()}
                />
              </TouchableOpacity>
            )}
            <View style={styles.labelContainer} flex={1}>
              <Text style={[styles.label, styles.dropInLabel]}>Open Drop-in</Text>
              <Text style={[styles.subLabel, styles.dropInLabel]}>
                {womenOnly ? 'Women Only' : 'Any Gender'}
              </Text>
            </View>
            {expirationDate ? (
              <View>
                <HoldTimer expirationDate={expirationDate} />
              </View>
            ) : null}
            <View style={styles.sideLabelContainer}>{renderIcon()}</View>
          </View>
        </View>
        {showEditPopup && (
          <EditDropin
            dropInSlotId={id}
            dropInGender={womenOnly ? DropInSlotGenderEnum_TS.FEMALE : DropInSlotGenderEnum_TS.ANY}
            note={note}
            isOpen={showEditPopup}
            onClose={onCloseEditDropin}
            isRecurring={slot?.isRecurring ?? false}
            maxGames={slot?.upcomingGameCount ?? 1}
            recurringCount={slot?.recurringCount}
            isRecurringEntireSeason={slot?.isRecurringEntireSeason ?? false}
          />
        )}
        {showDeletePopup && (
          <DeleteDropin
            dropInSlotId={id}
            isOpen={showDeletePopup}
            onClose={() => setShowDeletePopup(false)}
            dropInRefetch={dropInRefetch}
          />
        )}
        <Modal useRNModal size="sm" onClose={() => setShowNote(false)} isOpen={showNote}>
          <Modal.Content>
            <Modal.Body alignItems="center">{`"${note}"`}</Modal.Body>
          </Modal.Content>
        </Modal>
      </>
    );
  }

  return (
    <View style={styles.pillNumberWrapper}>
      <Text style={styles.gameRosterNumber}>{rosterNumber}</Text>
      <View style={[styles.pillContainer, styles.dropInWrapper]}>
        <Text style={[styles.textLabel, styles.label, styles.dropInLabel]}>
          {womenOnly ? 'Women Only' : 'Any Gender'}
        </Text>
        {hasCaptainPermissions || isAdmin ? openDropInLabel : null}
      </View>
    </View>
  );
};

export default DropInPill;
