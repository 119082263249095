import { type IStackProps } from '@rivallapp/native-base';
import { type ITextProps, Row, Text } from '../../../../../base-components';

// ascii code for bullet point
const BULLET_POINT = '\u2022';

interface BulletedItemProps extends IStackProps {
  _text?: ITextProps;
  bulletProps?: ITextProps;
}

const BulletedItem = ({ children, _text, bulletProps }: BulletedItemProps) => (
  <Row alignItems="center" space={2}>
    <Text {...bulletProps}>{BULLET_POINT}</Text>
    {typeof children === 'string' ? <Text {..._text}>{children}</Text> : children}
  </Row>
);

export default BulletedItem;
