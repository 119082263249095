import { gql, type TypedDocumentNode } from '@apollo/client';
import type { DiscoverFilterVariables } from './types';

type DiscoverSportsData = {
  discoverTopSports: {
    sportNames: string[];
  };
};

export const DISCOVER_ACTIVITIES: TypedDocumentNode<DiscoverSportsData, DiscoverFilterVariables> =
  gql`
    query DiscoverTopSports($input: DiscoverInput!) {
      discoverTopSports(input: $input) {
        sportNames
      }
    }
  `;

type DiscoverRentalSportsData = {
  discoverRentalSports: {
    sportNames: string[];
  };
};

export const DISCOVER_RENTAL_ACTIVITIES: TypedDocumentNode<
  DiscoverRentalSportsData,
  DiscoverFilterVariables
> = gql`
  query DiscoverRentalSports($input: DiscoverRentalsInput!) {
    discoverRentalSports(input: $input) {
      sportNames
    }
  }
`;
