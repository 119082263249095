import React, { type ComponentProps } from 'react';
import { Button } from '../../base-components';
import { usePrintWaiver, type UsePrintWaiverArgs } from '../../hooks';
import { ModalAlert, ModalAlertComponent } from '../../custom-components';

type ButtonProps = ComponentProps<typeof Button>;

const defaultProps: ButtonProps = {
  variant: 'link',
  _text: {
    color: 'primary.400',
    fontSize: 'sm',
  },
};

type Props = Omit<ButtonProps, 'onPress'> & UsePrintWaiverArgs;

const PrintWaiverButton: React.FC<Props> = ({ waiverId, fullName, dateSigned, ...props }) => {
  const displayError = (value: string | null | undefined) => {
    ModalAlert.alert({
      title: 'Error',
      message: String(value),
      buttons: [{ text: 'Close', textColor: 'error.400' }],
    });
  };

  const [NODE, print, printing] = usePrintWaiver({
    waiverId,
    fullName,
    dateSigned,
    setError: displayError,
  });

  return (
    <>
      <Button
        {...defaultProps}
        {...props}
        onPress={print}
        isLoading={printing}
        isLoadingText="Printing"
      />
      {NODE}
      <ModalAlertComponent id="story-alert" />
    </>
  );
};

export default PrintWaiverButton;
