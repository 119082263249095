import { gql, type TypedDocumentNode } from '@apollo/client';

type GameInput = {
  id: string;
};

type GameData = {
  game: {
    _id: string;
    start_time: string;
    end_time: string;
  };
};

const GET_GAME_DATES: TypedDocumentNode<GameData, GameInput> = gql`
  query Game($id: ID!) {
    game(_id: $id) {
      _id
      start_time
      end_time
    }
  }
`;

export default GET_GAME_DATES;
