import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  textContainer: {
    paddingHorizontal: 15,
  },
  titleMargin: {
    marginBottom: 10,
  },
  textMargin: {
    marginBottom: 20,
  },
});
