import { type MembershipEnum, Memberships } from '../../../constants';
import { PriceFormatter } from '../../../utilities';

type MembershipConfirmationMessageProps = {
  membership: MembershipEnum.VOLO_PASS | MembershipEnum.VOLO_FITNESS;
  monthlyPriceInCents: number;
  termsAndConditionsComponent: React.ReactNode;
  enableTrial?: boolean;
};

/**
 * Displays message asking the user to agree to the terms and conditions for their agreement
 */
const MembershipConfirmationMessage = ({
  membership,
  monthlyPriceInCents,
  termsAndConditionsComponent,
  enableTrial = false,
}: MembershipConfirmationMessageProps) => {
  const membershipName = Memberships[membership]?.name;
  const formattedPricing = PriceFormatter.format(monthlyPriceInCents / 100);

  return (
    <>
      By adding {membershipName} you agree to pay {formattedPricing} per month for membership,
      accept the {termsAndConditionsComponent ?? 'Terms and Conditions'}, and acknowledge the
      initial 3-month commitment
      {enableTrial ? ' if you do not opt out after your 2-week trial period ends' : ''}.
    </>
  );
};

export default MembershipConfirmationMessage;
