import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCircle as faCircleFilled } from '@fortawesome/pro-solid-svg-icons/faCircle';
import type { IVStackProps } from '@rivallapp/native-base/lib/typescript/components/primitives/Stack/VStack';
import type { FC } from 'react';
import { StyleSheet } from 'react-native';
import { Link } from 'react-router-dom';
import {
  Column,
  FontAwesomeIcon,
  Image,
  Link as NBLink,
  Row,
  Text,
} from '../../../base-components';

const styles = StyleSheet.create({
  touchableOpacity: {
    marginHorizontal: 16,
  },
});

/** V2 Extend this interface to support league items (currently just schedule is special) */
export interface NavItem {
  key: string;
  /**
   * Route (or full url) for item destination
   * @example "/rfo/rental/:rentalId/reservations"
   * @example "https://staging.volosports.com"
   */
  path: string;
  /** Text that gets displayed */
  title: string;
}

interface ProgramHeaderProps {
  /** Base64 data url */
  icon: string;
  iconAltText?: string;
  programName: string;
  navItems: NavItem[];
  activePath: string;
  onPress: (path: string) => void;
  _container?: IVStackProps;
}

/**
 * Shared Program Header
 *
 * Created for use with Private Rentals feature, with intention to use for future program page reworking.
 * NOTE: This is a _controlled_ component, so its parent will need to manage state.
 */
const ProgramHeader: FC<ProgramHeaderProps> = ({
  iconAltText,
  icon,
  programName,
  navItems,
  activePath,
  onPress,
  _container,
}) => {
  return (
    <Column alignItems="center" bg="white.600" paddingY="5" {..._container}>
      <Row marginBottom="5">
        <Image marginRight="2" size="7" source={{ uri: icon }} alt={iconAltText ?? ''} />
        <Text fontSize="lg">{programName}</Text>
      </Row>
      <Row>
        {navItems.map(({ key, path, title }) => (
          <Link
            key={path}
            // @ts-expect-error - Don't think this prop exists on this component, but leaving here in case
            container={{ style: styles.touchableOpacity }}
            to={path}
            onPress={() => onPress(path)}
            component={NBLink}
          >
            <Column alignItems="center">
              <FontAwesomeIcon
                size="xs"
                icon={activePath === key ? faCircleFilled : faCircle}
                color="primary.400"
              />
              <Text fontSize="sm" variant="link">
                {title}
              </Text>
            </Column>
          </Link>
        ))}
      </Row>
    </Column>
  );
};

export default ProgramHeader;
