import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface PlayersProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  players_response: boolean;
  playerIssuesResponses?: string[];
  playersComments?: string;
}

const Players: React.FC<PlayersProps> = ({
  players_response = false,
  playerIssuesResponses = [],
  playersComments,
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Players"
      view="read"
      toggleQuestion="Any issues to report regarding players during your shift?"
      toggleResponse={players_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.MultiSelect
          label="Player Issues:"
          selectedResponses={playerIssuesResponses}
        />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Player Notes (Tell us more!):" response={playersComments} />
      </Box>
      {children}
    </CollapseForm>
  );
};

export default Players;
