import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';

import {
  FontAwesomeIcon,
  Row,
  Stack,
  Text,
  type IStackProps,
  type ITextProps,
} from '../../../base-components';
import { Colors } from '../../../theme';

const PERKS = [
  'UNLIMITED PICKUPS AND DROP-INS',
  'DISCOUNTS ON LEAGUES ($10+OFF)',
  'ACCESS TO EXCLUSIVE EVENTS',
] as const;

type PerkTextProps = { perkText: string; size: 'sm' | 'md' | 'lg' } & Omit<ITextProps, 'size'>;

const PerkText = ({ perkText, size }: PerkTextProps) => {
  const [firstWord, ...restText] = perkText.split(' ');

  const iconSize = {
    sm: 6,
    md: 7,
    lg: 8,
  };

  return (
    <Row alignItems="center">
      <FontAwesomeIcon
        icon={faCircleCheck}
        size={iconSize[size]}
        color={Colors.secondary}
        marginRight={3}
        flexShrink={0}
      />
      <Row flex={1} flexWrap="wrap">
        <Text bold color={Colors.white} fontSize={size} textTransform="uppercase">
          {firstWord}{' '}
        </Text>
        <Text fontSize={size} color={Colors.white} textTransform="uppercase">
          {restText.join(' ')}
        </Text>
      </Row>
    </Row>
  );
};

type Props = {
  containerStyle?: IStackProps;
  size?: 'sm' | 'md' | 'lg';
};

const VoloPassPerksText = ({ containerStyle, size = 'lg' }: Props) => (
  <Stack space={5} {...containerStyle}>
    {PERKS.map(perk => (
      <PerkText key={perk} perkText={perk} size={size} />
    ))}
  </Stack>
);

export default VoloPassPerksText;
