import { isCurrentTimeWithinGracePeriod } from './components/BookingTimeSelector/hooks/useRentalTimeSlots/helpers';
import {
  minCourtsSelected,
  minGroupPasswordLength,
  minGroupSize,
  minTimeSlotsSelected,
  gracePeriod,
} from './steps.constants';
import {
  type RentalRegistrationRouteNameTyping,
  type RentalStepStoreKeyNames,
  type StepStateMap,
  type RentalRegistrationStepsType,
} from './steps.types';

// ====================================
// * How to add new screens (steps) to the rental registration flow:
// ====================================
// 1. Add a new route name to the RentalRegistrationRouteNames object
// 2. Add a new step to the RentalRegistrationSteps object
// 3. Update `steps.types.ts` to include the new step & its typings
// 4. (optional) Update `steps.constants.ts` to include any new constants that are needed for the new step
// 5. Update & integrate the new step into the `useRentalBookingStore` (selectors, actions, etc.)
// ====================================

// MOBILE
// ====================================
// 1. Update the `RentalRegistrationScreens` object in `routes.ts` to include the newly added step with its component for the screen
// 2. Update any typings for the new routes (`routes.types.ts`) that are needed for the new step (if applicable - type the ParamsList, etc.)

/**
 * Route names are hyphenated and in lower case to match Expo Router scheme (url naming convention)
 * - this also uses string manipulation to get the desired 'back button text' for each step in the header navigation (mobile)
 */
export const RentalRegistrationRouteNames = Object.freeze({
  dateSelection: 'date-selection',
  courtsSelection: 'courts-selection',
  groupSettings: 'group-settings',
  confirmSelections: 'confirm-selections',
});

export const RentalRegistrationSteps = Object.freeze<RentalRegistrationStepsType>({
  dateSelection: {
    index: 0,
    routeName: RentalRegistrationRouteNames.dateSelection,
    storeKey: 'dateSelection',
    initialState: {
      startDate: null,
      timeSlots: [],
    },
    validation: ({ startDate, timeSlots }) => {
      const startTimeWithinGracePeriod = isCurrentTimeWithinGracePeriod({
        selectedDate: startDate,
        startTimeStr: timeSlots[0]?.time_str!, // TODO: This assertion may not be correct
        gracePeriod,
      });

      return (
        startDate !== null && timeSlots.length >= minTimeSlotsSelected && startTimeWithinGracePeriod
      );
    },
  },
  courtsSelection: {
    index: 1,
    routeName: RentalRegistrationRouteNames.courtsSelection,
    storeKey: 'courtsSelection',
    initialState: {
      selectedCourts: [],
    },
    validation: ({ selectedCourts }) => {
      return (
        selectedCourts.length >= minCourtsSelected &&
        selectedCourts.every(court => court.is_available)
      );
    },
  },
  groupSettings: {
    index: 2,
    routeName: RentalRegistrationRouteNames.groupSettings,
    storeKey: 'groupSettings',
    initialState: {
      groupSize: 0,
      groupPasswordEnabled: false,
    },
    validation: ({ groupPasswordEnabled, groupSize, groupPassword }) => {
      const hasGroupMembers = (groupSize || 0) >= minGroupSize;

      if (groupPasswordEnabled) {
        const isPasswordLongEnough = groupPassword.trim().length >= minGroupPasswordLength;
        // password cannot have spaces
        const hasSpaces = groupPassword.includes(' ');

        return hasGroupMembers && isPasswordLongEnough && !hasSpaces;
      }

      return hasGroupMembers;
    },
  },
  confirmSelections: {
    index: 3,
    routeName: RentalRegistrationRouteNames.confirmSelections,
    storeKey: 'confirmSelections',
    initialState: undefined,
    validation: () => true,
  },
});

// ====================================
// ! Initial State creation for store
// ====================================

/**
 * Initial state for the rental registration flow
 * - loops through the `RentalRegistrationSteps` object and attaches the initial state for each step
 */
export const initialStepsState = Object.entries(RentalRegistrationSteps).reduce(
  (acc, [stepName, step]) => {
    return { ...acc, [stepName]: step.initialState };
  },
  {} as StepStateMap
);

// ====================================
// ! Step Navigation
// ====================================

/**
 * Sorted steps are used to make sure that the steps are always in the correct order for the rental registration flow
 * - this is used to create the screens in the correct order (mobile)
 */
export const SortedRentalRegistrationSteps = Object.freeze(
  Object.values(RentalRegistrationSteps).sort((a, b) => a.index - b.index)
);

/**
 * First step in the rental registration flow
 * - based off the `index` property in the `RentalRegistrationSteps` object
 * ---
 * - this is used to set the initial state of the rental registration flow & default to the first step
 */
export const RentalRegistrationFirstStep = SortedRentalRegistrationSteps[0]!;

/**
 * Function to take either the route name or the store key name and find the index of the route in the rental registration flow
 */
export function findRentalRouteIndex(
  key: RentalRegistrationRouteNameTyping | RentalStepStoreKeyNames
) {
  const currentIndex = SortedRentalRegistrationSteps.findIndex(
    step => step.routeName === key || step.storeKey === key
  );

  return currentIndex;
}

/**
 * Function to take either the route name or the store key name and find the next route name in the rental registration flow
 */
export function findNextStep(key: RentalRegistrationRouteNameTyping | RentalStepStoreKeyNames) {
  const currentIndex = findRentalRouteIndex(key);
  const nextStep = SortedRentalRegistrationSteps[currentIndex + 1];

  if (!nextStep) {
    return undefined;
  }

  return nextStep;
}

/**
 * Function to take either the route name or the store key name and find the previous route name in the rental registration flow
 */
export function findPreviousStep(key: RentalRegistrationRouteNameTyping | RentalStepStoreKeyNames) {
  const currentIndex = findRentalRouteIndex(key);
  const previousStep = SortedRentalRegistrationSteps[currentIndex - 1];

  if (!previousStep) {
    return undefined;
  }

  return previousStep;
}

export function isFirstStepInRentalFlow(
  key: RentalRegistrationRouteNameTyping | RentalStepStoreKeyNames
) {
  const currentIndex = findRentalRouteIndex(key);
  const isFirstStep = currentIndex === 0;

  return isFirstStep;
}

export function isLastStepInRentalFlow(
  key: RentalRegistrationRouteNameTyping | RentalStepStoreKeyNames
) {
  const currentIndex = findRentalRouteIndex(key);
  const isLastStep = currentIndex === SortedRentalRegistrationSteps.length - 1;

  return isLastStep;
}
