import { type ComponentProps } from 'react';
import { type IModalProps } from '@rivallapp/native-base';
import { type CalendarProps } from 'react-native-calendars';
import { Platform } from 'react-native';

import { useTheme } from '../../theme';
import { Modal } from '../../base-components';
import ThemedCalendar from '../ThemedCalendar';

const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';

export interface CalendarModalProps extends CalendarProps {
  /**
   * Boolean to determine if the modal is open
   */
  isOpen: boolean;
  /**
   * Callback for when the `Modal.CloseButton` is pressed
   * - should set value of `isOpen` prop to `false`
   */
  onCloseModal: () => void;
  /**
   * Extends the `Modal` component
   */
  modalProps?: Omit<IModalProps, 'isOpen' | 'onClose'>;
  /**
   * Extends the `Modal.Content` component
   */
  modalContentProps?: ComponentProps<typeof Modal.Content>;
  /**
   * Extends the `Modal.Header` component
   */
  modalHeaderProps?: ComponentProps<typeof Modal.Header>;
  /**
   * Extends the `Modal.Body` component
   */
  modalBodyProps?: ComponentProps<typeof Modal.Body>;
  /**
   * Extends the `Modal.CloseButton` component
   */
  modalCloseProps?: ComponentProps<typeof Modal.CloseButton>;
}

/**
 * A default implementation of the `CalendarModal`
 * - basic calendar utility within a Native Base `Modal`
 *
 * All props of the `Modal` component that are in use are available to be passed in:
 * - `modalProps`
 * - `modalContentProps`
 * - `modalHeaderProps`
 * - `modalBodyProps`
 * - `modalCloseProps`
 *
 * Passing any of these props will `override` the default props set in the component
 */
const CalendarModal = ({
  isOpen,
  onCloseModal,
  modalProps,
  modalContentProps,
  modalHeaderProps,
  modalBodyProps,
  modalCloseProps,
  ...calendarProps
}: CalendarModalProps) => {
  const { colors } = useTheme();

  return (
    /*
    utilize a modal inside another modal for the smooth slide in animation while having the backdrop animate opacity
     - fixes ugly animation of 'slide' as the backdrop slides up instead of fading in and out
    */
    <Modal
      // size is set to 'xl' for mobile to take up more of the screen compared to web
      // this makes it so that it utilizes almost the entire screen
      size={isMobile ? 'xl' : undefined}
      isOpen={isOpen}
      onClose={onCloseModal}
      _backdrop={{
        _dark: {
          bg: 'coolGray.600',
        },
      }}
      {...modalProps}
    >
      <Modal.Content backgroundColor={colors.card} {...modalContentProps}>
        {/* a set height is used here to give space for the `CloseButton` - else it would just overlap UI on the CalendarPicker below */}
        <Modal.Header
          height={45}
          borderBottomWidth={0}
          backgroundColor={colors.card}
          {...modalHeaderProps}
        >
          <Modal.CloseButton {...modalCloseProps} />
        </Modal.Header>
        {/* remove preset padding from modal components for a better fit of the calendar & to visually match header space */}
        <Modal.Body
          style={{ paddingHorizontal: 0, paddingTop: 0, paddingBottom: 35 }}
          {...modalBodyProps}
        >
          <ThemedCalendar {...calendarProps} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default CalendarModal;
