import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import { useState, type FC } from 'react';
import { Button, FormControl, Heading, Text, TextInput, VStack } from '../../../../base-components';
import { ConfirmCancelModal, ModalAlert } from '../../../../custom-components';
import { useActionStates } from '../../../../hooks';
import { SEND_RENTAL_PUSH } from '../../gql';
import { useMessageCenterStore } from '../../store';

const PushNotifications: FC = () => {
  const [notification, setNotification] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { rentalId, filters } = useMessageCenterStore(store => ({
    rentalId: store.rentalId,
    filters: store.filters,
  }));

  const { selectedDates, ...remainingFilters } = filters;

  const { updating, setUpdating } = useActionStates({
    withAlerts: true,
  });

  const [sendPush] = useMutation(SEND_RENTAL_PUSH);

  const onConfirm = async () => {
    try {
      setUpdating(true);
      if (!notification) {
        throw new Error("Can't send empty push notification");
      }
      await sendPush({
        variables: {
          input: {
            rentalId,
            message: notification,
            filters: {
              ...remainingFilters,
              ...(filters.dateSelectionType === 'manual'
                ? {
                    selectedDates: (filters.selectedDates ?? []).map(date =>
                      moment(date).format('YYYY-MM-DD')
                    ) as string[],
                  }
                : {}),
            },
          },
        },
      });
    } catch (e: any) {
      let message = '';
      if ('message' in e) {
        message = e.message;
      } else {
        message = 'Error sending push notification. If the issue persists, please contact support.';
      }
      ModalAlert.alert({
        title: 'Whoops!',
        message,
        buttons: [{ text: 'Continue', textColor: 'white.600', utilProps: { bg: 'primary.400' } }],
      });
    } finally {
      setUpdating(false);
      setShowConfirmModal(false);
    }
  };

  return (
    <>
      <VStack bg="white.600" rounded="sm" p="4" marginBottom="4">
        <Heading fontSize="md" color="gray.700">
          Push Notifications
        </Heading>
        <Text fontSize="sm">
          Warning: this will send a push notification to all players who have reservations on the
          selected dates (all selected filters will apply). Use it sparingly.
        </Text>
        <FormControl marginY="2">
          <TextInput
            accessibilityLabel="push notification input"
            placeholder="Enter your notification here"
            value={notification}
            onChangeText={value => setNotification(value)}
          />
        </FormControl>
        <Button
          isDisabled={!notification || updating || showConfirmModal}
          onPress={() => setShowConfirmModal(true)}
          alignSelf="flex-start"
        >
          Send Push Notification
        </Button>
      </VStack>
      <ConfirmCancelModal
        headerText="Are you sure?"
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onConfirm}
      >
        <Text>
          Warning: This will send a push notification to all players who have reservations on the
          selected dates (all selected filters will apply). Use it sparingly.
        </Text>
      </ConfirmCancelModal>
    </>
  );
};

export default PushNotifications;
