/**
 * Converts a number value to string and inserts commas using regex.
 * @param value number or string
 * @returns {string} formatted number as string
 */
export const insertCommas = (value: number | string): string => {
  let number = value;
  if (typeof number === 'number') number = number.toString();
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Converts a value to a dollar amount with two decimal places
 * @example
 * insertCommas(123.45) -> "$123.45"
 * insertCommas(123) -> "$123.00"
 * insertCommas("123a") -> ""
 * @param price number or string
 * @returns {string} formatted price as string
 */
export const formatPrice = (price: number | string): string => {
  let value = price;
  if (typeof value === 'string') {
    value = parseInt(value, 10);
    if (Number.isNaN(value)) return '';
  }
  const withCommas = insertCommas(value.toFixed(2));
  return `$${withCommas.replace(/(\d)(?=(\d{3})+\.)/g, '$1')}`;
};

/**
 * Converts cents to a price string
 * @param cents
 * @returns {string}
 * @example
 * formatCents(12345) -> "$123.45"
 * formatCents("12300") -> "$123.00"
 * formatCents("123a") -> ""
 * formatCents(null) -> ""
 */
export const formatCents = (cents: number | string | unknown): string => {
  if (typeof cents === 'number') {
    return formatPrice(cents / 100);
  } else if (typeof cents === 'string') {
    const value = parseInt(cents);
    if (Number.isNaN(value)) return '';
    return formatPrice(value / 100);
  } else {
    return '';
  }
};

/**
 * Converts dollars to cents. Uses regex to remove decimal place (does NOT round like toFixed(2))
 * @param {string} dollars
 * @returns {number} cents
 * @example
 * dollarsToCents("$1") -> 100
 * dollarsToCents("$19.99") -> 1999
 */
export const dollarsToCents = (dollars: string): number => {
  const parsed = parseFloat(dollars.replace(/[$,]/g, ''));
  if (Number.isNaN(parsed)) return 0;
  return Math.round(100 * parsed);
};
