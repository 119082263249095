import React, { type ComponentProps } from 'react';
import StarRating from 'react-native-star-rating-widget';
import { StarRatingComponent } from '../../../custom-components';
import { type ITextProps, Text } from '../../../base-components';

export type StaffAverageRatingProps = {
  title?: string;
  rating: number;
  totalLogs: number;

  /**
   * Custom text styling for the three text sections
   */
  _titleText?: ITextProps;
  _ratingText?: ITextProps;
  _totalLogsText?: ITextProps;

  /**
   * `StarRating` props for further customization
   */
  _star?: Omit<ComponentProps<typeof StarRating>, 'rating' | 'onChange'>;
};

const StaffAverageRating: React.FC<StaffAverageRatingProps> = ({
  title = 'Average pulse rating',
  rating,
  totalLogs,
  _titleText = {},
  _ratingText = {},
  _totalLogsText = {},
  _star = {},
}) => {
  return (
    <>
      <Text bold {..._titleText}>
        {title}
      </Text>
      <Text fontSize="2xl" {..._ratingText} bold>
        {rating.toFixed(2)}
      </Text>
      <StarRatingComponent
        {..._star}
        rating={rating}
        variant="yellow"
        readOnly
        // This is not needed when `readOnly` is true.
        onChange={() => {}}
      />
      <Text mt={4} bold {..._totalLogsText}>
        # shift logs selected: {totalLogs.toLocaleString()}
      </Text>
    </>
  );
};
export default StaffAverageRating;
