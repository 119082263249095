import React, { type ComponentProps } from 'react';
import { Link as NativeBaseLink, type ILinkProps } from '@rivallapp/native-base';
import { TouchableOpacity } from 'react-native';

export type LinkProps = ILinkProps & {
  container?: ComponentProps<typeof TouchableOpacity>;
};

// TODO: <TouchableOpacity> is does not give the intended opacity animation on mobile - need to fix
const Link: React.FC<LinkProps> = ({ container, ...props }) => {
  return (
    <TouchableOpacity {...container}>
      <NativeBaseLink {...props} />
    </TouchableOpacity>
  );
};

export default Link;
