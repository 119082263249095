import React, { Fragment } from 'react';
import { ImageBackground } from 'react-native';
import { faDollar } from '@fortawesome/pro-light-svg-icons/faDollar';
import { faAddressCard } from '@fortawesome/pro-light-svg-icons/faAddressCard';

import { ProgramTypeEnum } from '../../constants/enums';
import { useTheme } from '../../theme';
import { Row, Box, Divider } from '../../base-components';
import { VoloPassLogo } from '../../custom-components';
import VoloPassPerk from './components/VoloPassPerk';
import PersonKickingBall from './components/PersonKickingBall';
import SaleCopyText from './components/SaleCopyText';
import useDynamicSaleCopy from './hooks/useDynamicSaleCopy';

const VpAdBackground = require('../../../assets/vp-ad-background.png');

export interface VoloPassSaleBannerProps {
  /**
   * The program type is used for dynamic language displaying how much the user would save on the
   * program they are currently viewing.
   */
  programType: ProgramTypeEnum;

  /**
   * Controls whether language related to happy hours is displayed.
   * @default {false}
   */
  isDryMarket?: boolean;

  /**
   * The pricing of the program if the user were a Volo Pass member. The number must be in cents and
   * must represent the price BEFORE any taxes and fees are applied.
   */
  memberPriceInCents?: number;

  /**
   * The pricing of the program if the user was not a Volo Pass member. The number must be in cents
   * and must represent the price BEFORE any taxes and fees are applied.
   */
  nonmemberPriceInCents: number;

  children?: React.ReactNode;
}

/**
 * An advertisement for Volo Pass that utilizes a dynamic sales pitch based on program pricing and
 * organization information.
 */
const VoloPassSaleBanner: React.FC<VoloPassSaleBannerProps> = ({
  programType,
  isDryMarket = false,
  memberPriceInCents,
  nonmemberPriceInCents,
  children,
}) => {
  const { colors } = useTheme();
  const saleCopy = useDynamicSaleCopy({ programType, memberPriceInCents, nonmemberPriceInCents });

  return (
    // The background here is important as the background image is semi-transparent.
    <Box bg="#FFFFFF" borderRadius="xl" overflow="hidden">
      <ImageBackground source={VpAdBackground} style={{ flex: 1, width: '100%', height: '100%' }}>
        <Box paddingTop="2" paddingBottom="7" px="4">
          <Row alignItems="center" justifyContent="center" space="4" alignSelf="center" px={4}>
            <Box flex="1" alignItems="center">
              <VoloPassLogo colorScheme="white" size={110} />
            </Box>

            <Divider orientation="vertical" bg="white.50" thickness="2" h="60px" />

            <SaleCopyText>
              {saleCopy.map(({ text, isHighlighted }, index) =>
                isHighlighted ? (
                  <SaleCopyText
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color={colors.accents.lightTeal}
                    fontWeight="semibold"
                  >{`${text} `}</SaleCopyText>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>{`${text} `}</Fragment>
                )
              )}
            </SaleCopyText>
          </Row>

          <Divider
            alignSelf="center"
            orientation="horizontal"
            bg="white.50"
            thickness="2"
            width="95%"
            marginBottom="2"
            marginTop={-1}
          />

          <VoloPassPerk
            iconComponent={PersonKickingBall}
            title="Unlimited Play"
            description="pickups and drop-ins to live leagues"
          />
          <VoloPassPerk icon={faDollar} title="Discounts" description="on leagues ($10-50 off)" />
          <VoloPassPerk
            icon={faAddressCard}
            title="Access"
            description={
              isDryMarket ? 'to weekly member events' : 'to weekly happy hours and member events'
            }
          />
          {children}
        </Box>
      </ImageBackground>
    </Box>
  );
};

export default VoloPassSaleBanner;
