import { faChevronDown, faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { useTheme } from '../../../../theme';
import { Collapsible } from '../../../../custom-components';
import { Divider, FontAwesomeIcon, View } from '../../../../base-components';
import type { CollapsibleProps } from '../../../../custom-components/Collapsible';

type RentalCollapsibleProps = {
  hideDivider?: boolean;
} & CollapsibleProps;

export const CustomChevron = ({ clicked = false }: { clicked?: boolean }) => {
  const { colors } = useTheme();

  if (clicked) {
    return <FontAwesomeIcon icon={faChevronDown} size="sm" color={colors.border} />;
  }

  return <FontAwesomeIcon icon={faChevronLeft} size="sm" color={colors.border} />;
};

/**
 * Wrapper around `Collapsible` component since chevrons on the rental page look and
 * behave differently than the default chevrons.
 */
const RentalCollapsible = ({ hideDivider = false, children, ...props }: RentalCollapsibleProps) => {
  return (
    <>
      <Collapsible CustomChevron={CustomChevron} _pressableProps={{ marginLeft: 0 }} {...props}>
        <View marginLeft={1}>{children}</View>
      </Collapsible>

      {!hideDivider && <Divider mt={3} thickness="0.5" />}
    </>
  );
};
export default RentalCollapsible;
