import { StyleSheet } from 'react-native';
import { Colors } from '../../theme';

export default StyleSheet.create({
  vertical: {
    height: 40,
    borderWidth: 1,
    paddingHorizontal: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  horizontal: {
    borderWidth: 1,
    paddingVertical: 5,
    minWidth: 65,
    justifyContent: 'center',
    alignItems: 'center',
  },
  circular: {
    borderRadius: 50,
    margin: 5,
    padding: 5,
    height: 100,
    width: 100,
  },
  blueBackground: {
    backgroundColor: Colors.blues.medium,
  },
  amount: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  tag: {
    textAlign: 'center',
    paddingHorizontal: 2,
    fontWeight: 'bold',
    fontSize: 12,
  },
});
