import useRentalBookingStore from '../useRentalBookingStore';
import { groupStepSelector } from '../rentalBookingStore.selectors';

function stripLeadingZeros(input: string) {
  return input.replace(/^0+/, '');
}

/**
 * extractDigits
 * - helper function to extract only the digits from a string
 */
function extractDigits(input: string) {
  const onlyDigits = input.replace(/\D/g, '');
  return onlyDigits;
}

const toggleGroupPasswordEnabled = () => {
  useRentalBookingStore.setState(state => {
    const { currentBooking } = state;
    if (!currentBooking) {
      return state;
    }

    const { steps } = currentBooking;
    const { groupSettings: currentGroupSettings } = steps;

    const isNextPassEnabled = !currentGroupSettings.groupPasswordEnabled;

    if (isNextPassEnabled) {
      return {
        currentBooking: {
          ...currentBooking,
          steps: {
            ...steps,
            groupSettings: {
              ...currentGroupSettings,
              groupPasswordEnabled: true,
              // default to empty string when enabling
              groupPassword: '',
            },
          },
        },
      };
    }

    return {
      currentBooking: {
        ...currentBooking,
        steps: {
          ...steps,
          groupSettings: {
            ...currentGroupSettings,
            groupPasswordEnabled: false,
            // reset password when disabling
            groupPassword: undefined,
          },
        },
      },
    };
  });
};

const updateGroupSize = (
  newGroupSize: string | 'increment' | 'decrement',
  max_allowed_guests: number
) => {
  useRentalBookingStore.setState(state => {
    const { currentBooking } = state;
    if (!currentBooking) {
      return state;
    }

    const { steps } = currentBooking;
    const { groupSettings: currentGroupSettings } = steps;

    function handleGroupSizeChange() {
      if (newGroupSize === 'increment') {
        return Math.min(max_allowed_guests, (currentGroupSettings.groupSize || 0) + 1);
      }

      if (newGroupSize === 'decrement') {
        return Math.max(0, (currentGroupSettings.groupSize || 0) - 1);
      }

      // handle the case with keyboard interaction deleting the input all the way
      // which would result in an empty string
      if (newGroupSize === '') {
        return null;
      }

      return Math.min(max_allowed_guests, +extractDigits(stripLeadingZeros(newGroupSize)));
    }

    return {
      currentBooking: {
        ...currentBooking,
        steps: {
          ...steps,
          groupSettings: {
            ...currentGroupSettings,
            groupSize: handleGroupSizeChange(),
          },
        },
      },
    };
  });
};

const updateGroupPassword = (newGroupPassword: string) => {
  useRentalBookingStore.setState(state => {
    const { currentBooking } = state;
    if (!currentBooking) {
      return state;
    }

    const { steps } = currentBooking;
    const { groupSettings: currentGroupSettings } = steps;

    if (!currentGroupSettings.groupPasswordEnabled) {
      return state;
    }

    return {
      currentBooking: {
        ...currentBooking,
        steps: {
          ...steps,
          groupSettings: {
            ...currentGroupSettings,
            groupPassword: newGroupPassword,
          },
        },
      },
    };
  });
};

/**
 * Hook to interact with the group settings step only of the current booking.
 * - updates the group size in the current booking
 * - updates the group password in the current booking
 * - toggles the group password enabled state
 *
 * ---
 * - if the group password is enabled, guests will need to enter the password to continue
 * - if the group password is disabled, guests will not need to enter a password
 */
const useRentalBookingGuests = () => {
  const { groupSize, groupPassword, groupPasswordEnabled, isStepValid, rentalId } =
    useRentalBookingStore(groupStepSelector);

  return {
    rentalId,
    groupSize,
    groupPassword,
    groupPasswordEnabled,
    updateGroupSize,
    toggleGroupPasswordEnabled,
    updateGroupPassword,
    isStepValid,
  };
};

export default useRentalBookingGuests;
