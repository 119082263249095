import { skipToken, useSuspenseQuery } from '@apollo/client';
import { type RentalVenueExclusiveProps } from '../../types';
import { Row, View, type IViewProps } from '../../../../base-components';
import GET_RENTAL_PERKS from './graphql/GET_RENTAL_PERKS';
import GET_RENTAL_PERKS_FROM_VENUE from './graphql/GET_RENTAL_PERKS_FROM_VENUE';
import { VenuePerks } from '../../../../constants/enums/venue-features-enum';
import RentalCollapsible from '../RentalCollapsible';
import { SvgNewPerk } from '../../../../constants/perkComponents';
import { RentalText } from '../RentalCommonText';

type Props = {
  containerStyle?: IViewProps;
} & RentalVenueExclusiveProps;

const RentalPerks = ({ rentalId, venueId, containerStyle = {} }: Props) => {
  const { data: rentalData } = useSuspenseQuery(
    GET_RENTAL_PERKS,
    rentalId ? { variables: { rentalId } } : skipToken
  );

  const { data: venueData } = useSuspenseQuery(
    GET_RENTAL_PERKS_FROM_VENUE,
    venueId ? { variables: { venueId } } : skipToken
  );

  const perks = rentalData?.rental?.perks || venueData?.venue?.rentals[0]?.perks || [];
  const customPerk = rentalData?.rental?.custom_perks || venueData?.venue?.rentals[0]?.custom_perks;

  if (!perks.length && !customPerk) return null;

  return (
    <View {...containerStyle}>
      <RentalCollapsible isCollapsed={false} title="Perks">
        {perks.map(perk => {
          const { key, featureName, Icon } = VenuePerks[perk];

          return (
            <Row key={key} mt={2} ml={3} alignItems="center">
              <Icon viewBox="0 0 90 90" width="25px" height="100%" />
              <RentalText ml={3}>{featureName}</RentalText>
            </Row>
          );
        })}

        {customPerk && (
          <Row key={customPerk} mt={2} ml={3} alignItems="center">
            <SvgNewPerk viewBox="0 0 90 90" width="25px" height="100%" />
            <RentalText ml={3}>{customPerk}</RentalText>
          </Row>
        )}
      </RentalCollapsible>
    </View>
  );
};
export default RentalPerks;
