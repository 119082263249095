import { View } from '../../../../base-components';
import {
  RentalBookingSummary,
  RentalDivider,
  RentalSectionContainer,
  RentalSectionTitle,
  RentalSubtext,
} from '../../common/components';
import { useRentalBookingStore } from '../../store';
import { type RentalRegistrationRouteNameTyping } from '../steps.types';

export interface ConfirmSectionProps {
  /**
   * Callback to handle navigation when the edit button is pressed for each section
   * - returns the route name to navigate to within the callback
   */
  onEditPress?: (route: RentalRegistrationRouteNameTyping) => void;
}

const ConfirmSection = ({ onEditPress }: ConfirmSectionProps) => {
  const currentBooking = useRentalBookingStore(store => store.currentBooking);
  const isEditMode = currentBooking?.isEditMode || false;

  return (
    <View>
      <RentalSectionContainer>
        <RentalSectionTitle>
          {isEditMode ? 'Update cart' : 'Confirm and add to cart'}
        </RentalSectionTitle>
        <RentalSubtext>Fees added at checkout.</RentalSubtext>
      </RentalSectionContainer>
      <RentalDivider />
      <RentalSectionContainer px={4}>
        {!currentBooking ? (
          <RentalSubtext>Hmm, no booking found, please close and start over</RentalSubtext>
        ) : (
          <RentalBookingSummary booking={currentBooking} onEditPress={onEditPress} />
        )}
      </RentalSectionContainer>
    </View>
  );
};

export default ConfirmSection;
