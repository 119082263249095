import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { type IIconProps } from '@rivallapp/native-base';

import FontAwesomeIcon from '../Icon/Icon';

interface ChevronProps extends IIconProps {
  clicked?: boolean;
}

const Chevron = ({ clicked = false, ...rest }: ChevronProps) => {
  return <FontAwesomeIcon icon={clicked ? faChevronUp : faChevronDown} {...rest} />;
};

export default Chevron;
