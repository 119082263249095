import { gql, type TypedDocumentNode } from '@apollo/client';
import type { RentalMessageInput } from '.';

const GET_RENTAL_EMAILS: TypedDocumentNode<
  { getRentalEmails: string[] },
  { input: RentalMessageInput }
> = gql`
  query GetRentalEmails($input: RentalMessageInput!) {
    getRentalEmails(input: $input)
  }
`;

export default GET_RENTAL_EMAILS;
