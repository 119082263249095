import { useState, type ComponentProps } from 'react';
import { useMutation } from '@apollo/client';

import { useTheme } from '../../theme';
import { DropInSlotGenderEnum_TS, ErrorCodes, ErrorMessageEnum } from '../../constants/enums';
import { useActionStates } from '../../hooks';
import { Button, Radio, Alert, Modal, View, TextInput, Text, Row } from '../../base-components';
import { UPDATE_DROP_IN_SLOT } from '../GameRoster/graphql/MANAGE_DROPIN_SLOTS';

type RecurringMode = 'count' | 'full_season';

interface EditDropinProps extends ComponentProps<typeof Modal> {
  dropInSlotId: string;
  dropInGender: DropInSlotGenderEnum_TS;
  isRecurring: boolean;
  isRecurringEntireSeason: boolean;
  /** Maximum number of upcoming games */
  maxGames: number;
  /** Current recurring count */
  recurringCount?: number;
  note?: string;
}

const EditDropin = ({
  dropInSlotId,
  dropInGender,
  note = '',
  isRecurring,
  isRecurringEntireSeason,
  recurringCount = 1,
  maxGames,
  ...props
}: EditDropinProps) => {
  const { colors } = useTheme();

  const [genderValue, setGenderValue] = useState<DropInSlotGenderEnum_TS>(dropInGender);
  const [noteContents, setNoteContents] = useState(note);

  const [isRecurringState, setIsRecurringState] = useState(isRecurring);
  const [recurringMode, setRecurringMode] = useState<RecurringMode>(
    isRecurringEntireSeason ? 'full_season' : 'count'
  );
  const [recurringCountState, setRecurringCountState] = useState(String(recurringCount));
  const [recurringError, setRecurringError] = useState('');

  const { setError, showAlert, setShowAlert, alertMessage, alertType } = useActionStates({
    withAlerts: true,
  });

  const [updateDropInSlot, { loading }] = useMutation(UPDATE_DROP_IN_SLOT);

  const updateSlot = async () => {
    try {
      await updateDropInSlot({
        variables: {
          input: {
            _id: dropInSlotId,
            isRecurring: isRecurringState,
            ...(dropInGender !== genderValue ? { gender: genderValue } : {}),
            ...(note !== noteContents ? { note: noteContents } : {}),
            ...(isRecurringState && recurringMode === 'full_season'
              ? { entireSeason: true }
              : { entireSeason: false }),
            ...(isRecurringState && recurringMode === 'count'
              ? { gameCount: parseInt(recurringCountState, 10) }
              : {}),
          },
        },
        update: cache => {
          cache.evict({ fieldName: 'dropInSlotsByGameId' });
          cache.gc();
        },
      });
      props?.onClose();
    } catch (e) {
      setError(ErrorMessageEnum[ErrorCodes.DROPIN_UPDATE]);
    }
  };

  const onRecurringCountChange = (value: string) => {
    setRecurringCountState(value);
    const parsedValue = parseInt(value, 10);
    if (Number.isNaN(parsedValue)) {
      setRecurringError('Please enter a valid number.');
    } else if (parsedValue < 1) {
      setRecurringError('Please enter a value greater than 0.');
    } else if (parsedValue > maxGames) {
      setRecurringError(`The maximum number of remaining games is ${maxGames}.`);
    } else {
      setRecurringError('');
    }
  };

  return (
    <Modal useRNModal size="xl" {...props}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header borderBottomWidth={0}>Edit Drop-in Spot</Modal.Header>
        <Modal.Body>
          {showAlert ? (
            <Alert
              status={alertType}
              message={alertMessage}
              showAlert
              setShowAlert={setShowAlert}
              marginBottom={2}
            />
          ) : null}
          <Radio.Group
            name="dropInGenders"
            value={genderValue}
            onChange={value => setGenderValue(value as DropInSlotGenderEnum_TS)}
            flexDirection={['column', 'column', 'row']}
            justifyContent="flex-start"
            accessibilityLabel="Change the gender of this drop-in slot"
          >
            <Radio value={DropInSlotGenderEnum_TS.FEMALE} _text={{ paddingRight: 10 }}>
              Women Only
            </Radio>
            <Radio value={DropInSlotGenderEnum_TS.ANY}>Any Gender</Radio>
          </Radio.Group>
          <View marginY={5}>
            <Text type="caption" color={colors.text}>
              Notes (ask for goalie, ask for social player, etc)
            </Text>
            <TextInput
              value={noteContents}
              onChangeText={setNoteContents}
              maxLength={40}
              accessibilityLabel="Add a note about this drop-in slot"
            />
          </View>
          {!isRecurringState ? (
            <Button
              variant="link"
              alignSelf="flex-start"
              paddingLeft="0"
              _text={{ fontSize: 'xs' }}
              onPress={() => setIsRecurringState(true)}
            >
              + Open for future weeks
            </Button>
          ) : (
            <>
              <Radio.Group
                name="recurringMode"
                value={recurringMode}
                marginTop="2"
                onChange={value => {
                  // This check is necessary as on mobile devices, the `onChange` is triggered when the nested `TextInput` is updated
                  // At that point, `value` is not a string like we expect but instead the regular synthetic event from React
                  // This was causing the radio button to deselect and act unexpectedly - adding this check prevents that case from happening
                  if (typeof value === 'string') {
                    setRecurringMode(value as RecurringMode);
                  }
                }}
                accessibilityLabel="Pick how many weeks for drop in to reoccur"
              >
                <Radio value="count" alignItems="center">
                  <Row alignItems="center" flexWrap="wrap">
                    <Text fontSize="xs">This spot is open for the next </Text>
                    <TextInput
                      placeholder="1"
                      keyboardType="numeric"
                      maxWidth="8"
                      width="8"
                      height="7"
                      padding={2}
                      textAlign="center"
                      _input={{
                        maxWidth: 8,
                        height: 7,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                      accessibilityLabel="Recurring Game Count"
                      onChangeText={onRecurringCountChange}
                      value={String(recurringCountState)}
                      isDisabled={recurringMode === 'full_season'}
                    />
                    <Text fontSize="xs"> game(s).</Text>
                  </Row>
                </Radio>
                <Radio value="full_season" alignItems="center">
                  <Text fontSize="xs" flexWrap="wrap">
                    This spot is open for all future regular season game(s).
                  </Text>
                </Radio>
              </Radio.Group>
              {recurringMode === 'count' && recurringError && (
                <Text fontSize="xs" color="red.400">
                  {recurringError}
                </Text>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer borderTopWidth={0} justifyContent="center">
          <Button
            isDisabled={loading || (recurringMode === 'count' && !!recurringError)}
            onPress={updateSlot}
            flex={1}
            margin={0}
            padding={0}
            paddingTop={2}
            paddingBottom={2}
            maxWidth={165}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default EditDropin;
