import { createContext, useContext } from 'react';

export type CollapseFormView = 'read' | 'write' | 'admin';

export interface CollapseFormContext {
  view: CollapseFormView;
}

export const CollapseFormContext = createContext<CollapseFormContext | null>(null);

export const useCollapseFormContext = () => {
  const ctx = useContext(CollapseFormContext);
  if (ctx === null) {
    throw new Error('Please use this component inside a CollapseForm!');
  }
  return ctx;
};
