import moment from 'moment-timezone';

import {
  type TRentalActivityValue,
  getActivityNameFromValue,
} from '../../../../../constants/enums';
import { snakeToStartCase } from '../../../../../utilities';
import {
  type RentalCourtSelection,
  type RentalTimeRange,
} from '../../../RegistrationFlowSections/steps.types';
import {
  timeSlotApiFormat,
  timeSlotFormat,
} from '../../../RegistrationFlowSections/steps.constants';

export function formatStartDate(startDate: Date | null) {
  // showing Invalid Date if startDate is null is okay here
  // so we know if that is showing, then there is something wrong with the data
  return moment(startDate).format('dddd MMMM Do, YYYY');
}

export function formatRentalActivityLabel(rentalType: TRentalActivityValue) {
  return getActivityNameFromValue(rentalType) || snakeToStartCase(rentalType);
}

export function formatFullTimeRange(timeSlots: RentalTimeRange) {
  if (!timeSlots.length) return '';

  /* need to assert.. TS is saying Object is possibly undefined which if time slots is empty, hits the early return above */
  const start = moment(timeSlots[0]!.time_str, timeSlotFormat).format('h:mma');
  const end = moment(timeSlots[timeSlots.length - 1]!.time_str, timeSlotFormat).format('h:mma');
  return `${start}-${end}`;
}

/**
 * Add comma separated court names
 */
export function formatCourtNames(selectedCourts: RentalCourtSelection['selectedCourts']) {
  const courts = [...selectedCourts];
  return courts
    .sort((a, b) =>
      /* sort by name & numeric to handle different court names & numbers attached */
      a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
    )
    .map(court => court.name)
    .join(', ');
}

export function createPasswordMask(password: string) {
  return password.replace(/./g, '*');
}

/**
 * formatPricingTimeRanges
 * - groups and formats the time slots into prime and standard time ranges
 */
export function groupPricingRanges(timeSlots: RentalTimeRange): {
  primeTimes: string;
  standardTimes: string;
} {
  let currentPrimeRangeStart: string | null = null;
  let currentStandardRangeStart: string | null = null;
  const primeTimes: string[] = [];
  const standardTimes: string[] = [];

  /**
   * Format the time slots from api to desired format
   * - 'HH:mm' -> 'h:mma'
   * - '17:00' -> '5:00pm'
   */
  function formatTime(timeStr: string) {
    return moment(timeStr, timeSlotApiFormat).format('h:mma');
  }

  function formatTimeRangeGroup(start: string, end: string) {
    return `${formatTime(start)}-${formatTime(end)}`;
  }

  /** Helper to check if two times are contiguous (i.e. one ends when the other starts) */
  function isContiguous(currentEndTime: string, nextStartTime: string): boolean {
    return currentEndTime === nextStartTime;
  }

  timeSlots.forEach((slot, index) => {
    const isLastSlot = index === timeSlots.length - 1;
    const nextSlot = timeSlots[index + 1];
    const start = slot.rentalBlock.start_time_str;
    const end = isLastSlot ? slot.rentalBlock.start_time_str : slot.rentalBlock.end_time_str;

    const isNotContiguousWithNextSlot =
      nextSlot && !isContiguous(end, nextSlot.rentalBlock.start_time_str);

    const shouldProcessPrimeTimeSlot =
      !nextSlot || !nextSlot.isPrimeTime || isNotContiguousWithNextSlot;

    // ===== prime time formatting

    if (slot.isPrimeTime) {
      if (currentPrimeRangeStart === null) {
        currentPrimeRangeStart = start;
      }

      if (currentPrimeRangeStart === end || !shouldProcessPrimeTimeSlot) {
        return;
      }

      primeTimes.push(`${formatTimeRangeGroup(currentPrimeRangeStart, end)}`);
      currentPrimeRangeStart = null;

      return;
    }

    // ===== standard time formatting

    const shouldProcessStandardTimeSlot =
      !nextSlot || nextSlot.isPrimeTime || isNotContiguousWithNextSlot;

    if (currentStandardRangeStart === null) {
      currentStandardRangeStart = start;
    }

    if (currentStandardRangeStart === end || !shouldProcessStandardTimeSlot) {
      return;
    }

    standardTimes.push(`${formatTimeRangeGroup(currentStandardRangeStart, end)}`);
    currentStandardRangeStart = null;
  });

  return {
    primeTimes: primeTimes.join(', '),
    standardTimes: standardTimes.join(', '),
  };
}
