import { ProgramModuleTypes, RentalModuleTypes } from '../../../constants/enums';
import {
  CapacitySection,
  NeighborhoodSection,
  PricingSection,
  StartDateSection,
  StartTimeSection,
  VenueSection,
} from './programModules';
import {
  RentalPricingSection,
  SportDisplayNameSection,
  VenueDisplayNameSection,
  NeighborhoodVenueSection,
  CapacityCourtsSection,
  RentalSportsSection,
} from './rentalModules';
import type {
  RentalCardTypeModule,
  RentalPricingModule,
  NeighborhoodVenueModule,
  SportDisplayNameModule,
  VenueDisplayNameModule,
  CapacityCourtNumberModule,
  RentalSportsModule,
} from '../RentalCard';
import type {
  ProgramCardTypeModule,
  NeighborhoodModule,
  VenueModule,
  TimeModule,
  DateModule,
  CapacityModule,
  PricingModule,
} from '../ProgramCard';

type DiscoverModuleType = ProgramCardTypeModule | RentalCardTypeModule;

const generateModule = <T extends DiscoverModuleType>(module: T) => {
  const { type } = module || '';

  switch (type) {
    case ProgramModuleTypes.NEIGHBORHOOD:
      return <NeighborhoodSection neighborhood={(module as NeighborhoodModule).neighborhood} />;
    case ProgramModuleTypes.VENUE:
      return <VenueSection venue={(module as VenueModule).venue} />;
    case ProgramModuleTypes.TIME:
      return (
        <StartTimeSection
          startTimeDate={(module as TimeModule).startTimeDate}
          endTimeDate={(module as TimeModule).endTimeDate}
        />
      );
    case ProgramModuleTypes.DATE:
      return (
        <StartDateSection
          start_date={(module as DateModule).start_date}
          num_weeks={(module as DateModule).num_weeks}
        />
      );
    case ProgramModuleTypes.CAPACITY:
      return <CapacitySection capacity={(module as CapacityModule).capacity} />;
    case ProgramModuleTypes.PRICING:
      return (
        <PricingSection
          price={(module as PricingModule).price}
          memberPrice={(module as PricingModule).memberPrice}
          isVoloPassMember={(module as PricingModule).isVoloPassMember}
          isVoloFitnessMember={(module as PricingModule).isVoloFitnessMember}
          voloFitnessPrice={(module as PricingModule).voloFitnessPrice}
        />
      );
    case RentalModuleTypes.RENTAL_PRICING:
      return (
        <RentalPricingSection
          lowPrice={(module as RentalPricingModule).lowPrice}
          highPrice={(module as RentalPricingModule).highPrice}
        />
      );
    case RentalModuleTypes.SPORT_DISPLAY_NAME:
      return (
        <SportDisplayNameSection
          rentalActivity={(module as SportDisplayNameModule).rentalActivity}
          venueName={(module as SportDisplayNameModule).venueName}
        />
      );
    case RentalModuleTypes.VENUE_DISPLAY_NAME:
      return <VenueDisplayNameSection venueName={(module as VenueDisplayNameModule).venueName} />;
    case RentalModuleTypes.NEIGHBORHOOD_VENUE:
      return (
        <NeighborhoodVenueSection
          neighborhood={(module as NeighborhoodVenueModule).neighborhood}
          venue={(module as NeighborhoodVenueModule).venue}
        />
      );
    case RentalModuleTypes.CAPACITY_COURTS_NUMBER:
      return (
        <CapacityCourtsSection
          guestCapacity={(module as CapacityCourtNumberModule).guestCapacity}
          surfaceType={(module as CapacityCourtNumberModule).surfaceType}
          availableCourts={(module as CapacityCourtNumberModule).availableCourts}
        />
      );
    case RentalModuleTypes.RENTAL_SPORTS:
      return (
        <RentalSportsSection
          availableSportTypes={(module as RentalSportsModule).availableSportTypes}
        />
      );
    default:
      return null;
  }
};

export default generateModule;
