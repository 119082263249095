import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgCityWideTournamentFeeder = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={91} height={91} fill="none" {...props}>
    <Path
      fill="#eaff70"
      d="M50.5 90.5c22.091 0 40-17.909 40-40s-17.909-40-40-40-40 17.909-40 40 17.909 40 40 40"
    />
    <Path
      stroke="#103b5d"
      d="M40.5 80C62.315 80 80 62.315 80 40.5S62.315 1 40.5 1 1 18.685 1 40.5 18.685 80 40.5 80Z"
    />
    <Path fill="#103b5d" stroke="#103b5d" d="M35 16H20v7h15z" />
    <Path
      stroke="#103b5d"
      d="M45.5 19.5H35M45.5 31v5m.5 10.5V51m0 7v5.5m16.5-36v10m0 7.5v9.5M46 36H35m0 10.5h11.5m6.5-19h10m0 27H53M46.5 63.5h-11M45.5 23.5V19"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M35 43H20v7h15zM35 32H20v7h15zM35 59H20v7h15zM53 24H38v7h15zM70 38H55v7h15zM53 51H38v7h15z"
    />
  </Svg>
);
export default SvgCityWideTournamentFeeder;
