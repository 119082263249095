import { Animated } from 'react-native';

import { type ExpandableCheckBoxTreeProps } from '../ExpandableCheckboxTree';

export type FlattenedMapData = {
  [value: ExpandableCheckBoxTreeProps['value']]: Animated.Value;
};

const flattenMapData = (
  data: ExpandableCheckBoxTreeProps[] = [],
  value: number
): FlattenedMapData => {
  return data.reduce(
    (acc, mainTree) => ({
      ...acc,
      [mainTree.value]: new Animated.Value(value),
    }),
    {}
  );
};

export default flattenMapData;
