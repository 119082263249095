import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface MissingShirtsProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  shirts_response: boolean;
  missingShirts: {
    _id: string;
    team_name: string;
    color: string;
    style: string;
    small?: number;
    medium?: number;
    large?: number;
    xlarge?: number;
    xxlarge?: number;
    xxxlarge?: number;
  }[];
  shirtsComments?: string;
}

const Shirts: React.FC<MissingShirtsProps> = ({
  shirts_response = false,
  missingShirts = [],
  shirtsComments,
  children,
  ...props
}) => {
  const formattedList = missingShirts?.map(shirtList => ({
    _id: shirtList._id,
    team_name: shirtList.team_name,
    Color: shirtList.color,
    Style: shirtList.style,
    Shirts:
      `S: ${shirtList.small || 0} M: ${shirtList.medium || 0} ` +
      `L: ${shirtList.large || 0} XL: ${shirtList.xlarge || 0} ` +
      `2XL: ${shirtList.xxlarge || 0} 3XL: ${shirtList.xxxlarge || 0}`,
  }));

  return (
    <CollapseForm
      title="Shirts"
      view="read"
      toggleQuestion="Missing Shirts?"
      toggleResponse={shirts_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.CompositeList listValues={formattedList} parentValue="team_name" />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Additional Notes:" response={shirtsComments} />
      </Box>
      {children}
    </CollapseForm>
  );
};

export default Shirts;
