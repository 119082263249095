/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import throttle from 'lodash.throttle';
import { useCallback, useEffect, useState } from 'react';
import { Appearance } from 'react-native';

/**
 * Custom hook that returns the current color scheme from React Native. Bug in Expo sdk49 that causes the color scheme to be 'light' on app foreground sometimes when it should be 'dark'
 * - this hook should be used for now until issue is resolved
 * @param delay - Delay in ms to throttle the color scheme change event
 * @default 350
 * @returns The current color scheme
 * @see https://github.com/expo/expo/issues/10815
 */
const useColorScheme = (delay = 350) => {
  const [colorScheme, setColorScheme] = useState(Appearance.getColorScheme());

  const onColorSchemeChange = useCallback(
    throttle(
      ({ colorScheme }) => {
        setColorScheme(colorScheme);
      },
      delay,
      {
        leading: false,
      }
    ),
    [delay]
  );

  useEffect(
    function appearanceListener() {
      const listener = Appearance.addChangeListener(onColorSchemeChange);

      return () => {
        onColorSchemeChange.cancel();
        listener.remove();
      };
    },
    [onColorSchemeChange]
  );

  return colorScheme;
};

export default useColorScheme;
