import { gql, type TypedDocumentNode } from '@apollo/client';

type UnRsvpInput = {
  gameId: string;
};

export type UnRsvpResult = {
  unrsvp: {
    _id: string;
  };
};

export const UNRSVP_DROPIN: TypedDocumentNode<UnRsvpResult, UnRsvpInput> = gql`
  mutation unrsvp($gameId: ID!) {
    unrsvp(gameId: $gameId) {
      _id
    }
  }
`;
