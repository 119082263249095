import type { ComponentProps } from 'react';
import { ApolloError } from '@apollo/client';

import { View, Text } from '../../../base-components';
import useMobileSizing from '../helpers/useMobileSizing';

interface DiscoverErrorProps extends ComponentProps<typeof View> {
  error: ApolloError | string;
}

const DiscoverError = ({ error, ...props }: DiscoverErrorProps) => {
  const mobileSizing = useMobileSizing();
  return (
    <View
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      _web={{ shadow: 2 }}
      borderRadius={mobileSizing ? 13 : 4}
      marginY={mobileSizing ? 2.5 : 15}
      marginX={mobileSizing ? 0 : 15}
      minHeight={mobileSizing ? 70 : 100}
      {...props}
    >
      <Text paddingX={2} fontSize={mobileSizing ? 12 : 14}>
        {String(error)}
      </Text>
    </View>
  );
};
export default DiscoverError;
