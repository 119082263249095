import { useState } from 'react';
import { Card, Heading, Stack, Text } from '../../base-components';
import {
  RentalBookingSummary,
  RentalSectionContainer,
  RentalSectionLabel,
  useRentalBookingStore,
} from '../RentalsRegistration';
import {
  RentalLocation,
  RentalDonation,
  RentalPaymentSummary,
  RentalWaivers,
  RentalCheckoutButton,
} from './components';
import RentalConfirmationModal from './components/RentalConfirmationModal';
import type { RentalConfirmationModalProps } from './components/RentalConfirmationModal/RentalConfirmationModal';
import type { RentalWaiversProps } from './components/RentalWaivers/RentalWaivers';
import type { RentalCheckoutButtonProps } from './components/RentalCheckoutButton/RentalCheckoutButton';

interface RentalCheckoutProps {
  /**
   * (Per Platform Implementation)
   * PaymentMethodComponent
   * - follow suits to mobile `DailyRegistration` component
   * - should update the `defaultPaymentSource` on the current user
   */
  renderPaymentMethodComponent: () => JSX.Element;
  _checkoutButtonComponent: {
    onBookingNotAvailable: RentalCheckoutButtonProps['onBookingNotAvailable'];
  };
  /**
   * (Per Platform Implementation)
   * Props to be passed to the `RentalConfirmationModal` component
   */
  _confirmationModalComponent: {
    renderBlurViewComponent?: RentalConfirmationModalProps['renderBlurViewComponent'];
    /**
     * callback to handle when the dashboard button is pressed
     */
    onDashboardBtnPress: RentalConfirmationModalProps['onDashboardPress'];
    /**
     * deepLink should be available only when there is a single reservation
     * - when multiple reservations - we do not show the invite button
     */
    onInviteBtnPress: RentalConfirmationModalProps['onInvitePress'];
    onInviteLoading?: RentalConfirmationModalProps['onInviteLoading'];
  };
  /**
   * (Per Platform Implementation)
   * Props to be passed to the `RentalWaivers` component
   */
  _waiversComponent: {
    /**
     * callback to handle when the liability waiver link is pressed
     * - should show the liability waiver per platform
     */
    onLiabilityLinkPressed: RentalWaiversProps['onLiabilityLinkPressed'];
    /**
     * callback to handle when the rental policy link is pressed
     * - should show the rental policy per platform
     */
    onRentalPolicyLinkPressed: RentalWaiversProps['onRentalPolicyLinkPressed'];
  };
}

/**
 * RentalCheckout component
 * - Payment method needs to be handled per platform as SCL is not setup to handle payments or anything related to it just yet
 */
const RentalCheckout = ({
  renderPaymentMethodComponent,
  _waiversComponent: { onLiabilityLinkPressed, onRentalPolicyLinkPressed },
  _checkoutButtonComponent: { onBookingNotAvailable },
  _confirmationModalComponent: {
    renderBlurViewComponent,
    onDashboardBtnPress,
    onInviteBtnPress,
    onInviteLoading,
  },
}: RentalCheckoutProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [reservationIds, setReservationIds] = useState<Array<string>>([]);
  const [deepLink, setDeepLink] = useState<string | undefined>();
  const [disableCheckout, setDisableCheckout] = useState(false);

  const confirmedBookings = useRentalBookingStore(store => Object.values(store.confirmedBookings));
  // only need 1 rentalId to get the location
  // as all rentals in a booking cart must be at the same location (venue)
  const rentalId = confirmedBookings[0]?.rentalId;

  return (
    <Stack space={2}>
      <Heading type="subsection">Rental Registration</Heading>
      {!confirmedBookings.length || !rentalId ? (
        <Text textAlign="center">No confirmed bookings found, go back and add some!</Text>
      ) : (
        <>
          <RentalLocation rentalId={rentalId} />
          <RentalSectionContainer>
            <RentalSectionLabel>Booking Summary</RentalSectionLabel>
            {confirmedBookings.map(booking => (
              <Card key={booking.bookingId} my={2} padding={2}>
                <RentalBookingSummary booking={booking} />
              </Card>
            ))}
          </RentalSectionContainer>
          <RentalSectionContainer>
            <RentalDonation />
          </RentalSectionContainer>
          <RentalSectionContainer>
            <RentalPaymentSummary
              confirmedBookings={confirmedBookings}
              onPriceLoading={setDisableCheckout}
            />
          </RentalSectionContainer>
          <RentalSectionContainer>{renderPaymentMethodComponent()}</RentalSectionContainer>
          <RentalSectionContainer>
            <RentalWaivers
              onLiabilityLinkPressed={onLiabilityLinkPressed}
              onRentalPolicyLinkPressed={onRentalPolicyLinkPressed}
            />
          </RentalSectionContainer>
          <RentalSectionContainer>
            <RentalCheckoutButton
              isDisabled={disableCheckout}
              onCompleteBookingMutation={({ reservationIds, deepLink }) => {
                setReservationIds(reservationIds);
                setDeepLink(deepLink);
                setShowConfirmationModal(true);
              }}
              onBookingNotAvailable={onBookingNotAvailable}
            />
          </RentalSectionContainer>
          <RentalConfirmationModal
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            onDashboardPress={onDashboardBtnPress}
            onInvitePress={onInviteBtnPress}
            onInviteLoading={onInviteLoading}
            reservationIds={reservationIds}
            deepLink={deepLink}
            renderBlurViewComponent={renderBlurViewComponent}
          />
        </>
      )}
    </Stack>
  );
};

export default RentalCheckout;
