import { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

import { createDays } from './helpers';
import { type Day } from '../../types';

interface UseCreateDays {
  /**
   * The new selected date to create the days array from
   */
  newSelectedDate: Date | null;
  /**
   * The initial start date for the creation of the days (should be today)
   * - this is used to initialize the days array as a selected date may not be available yet
   */
  initialStartDate: Date;
  /**
   * The number of days to create
   * - default is 4 days
   */
  numDays: number;
}

/**
 * Hook to create an array of days from the given date
 * - `numDays` is the number of days to create including the given date
 * - default is 4 days
 *
 * runs an effect to updates the days array when the startDate changes (only when the startDate is not in the days array already)
 */
const useCreateDays = ({ newSelectedDate, initialStartDate, numDays }: UseCreateDays) => {
  const initialDaysRef = useRef<Day[]>([]);
  if (!initialDaysRef.current.length) {
    initialDaysRef.current = createDays(initialStartDate, numDays);
  }

  const [days, setDays] = useState<Day[]>(() =>
    newSelectedDate ? createDays(initialStartDate, numDays) : initialDaysRef.current
  );

  useEffect(
    function updateDaysIfNeeded() {
      const hasStartDateInDays = (day: Day) => moment(newSelectedDate).isSame(day.date, 'day');

      // if no new selected date, then use the initial days array
      if (!newSelectedDate) {
        setDays(initialDaysRef.current);
        return;
      }

      // if the selected date is already in the initial days array, then use that
      const newDateInInitialDays = initialDaysRef.current.find(hasStartDateInDays);
      if (newDateInInitialDays) {
        setDays(initialDaysRef.current);
        return;
      }

      // if the new date is not in the days array, then we need to create the new days array
      const newDateNotInDaysArray = !days.find(hasStartDateInDays);
      if (newDateNotInDaysArray) {
        setDays(createDays(newSelectedDate, numDays));
      }
    },
    [newSelectedDate, days, numDays]
  );

  return days;
};

export default useCreateDays;
