import { gql } from '@apollo/client';

export default gql`
  mutation ShareDropInSlot($id: ID!) {
    shareDropInSlot(_id: $id) {
      _id
      deepLink
    }
  }
`;
