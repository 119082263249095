import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  separatorContainer: {
    display: 'flex',
    flex: 1,
    marginVertical: 5,
  },
  separatorText: {
    alignSelf: 'flex-end',
    fontWeight: 'bold',
  },
  dottedLine: {
    borderStyle: 'dotted',
    borderWidth: 1,
  },
});
