import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import DISCOVER_SKILL_LEVELS from '../graphql/DISCOVER_SKILL_LEVELS';
import { View, Text } from '../../../base-components';
import { CustomButton } from '../../../custom-components';

import styles from '../styles';

export type SkillLevelFilterType = {
  cityName?: string;
  onPress: (param?: string) => void | undefined;
  selectedSkillLevels: string[];
};

const SkillLevelFilter = ({
  onPress,
  cityName,
  selectedSkillLevels = [],
}: SkillLevelFilterType) => {
  const { data, error } = useQuery(DISCOVER_SKILL_LEVELS, {
    skip: !cityName,
    fetchPolicy: 'cache-and-network',
    variables: { input: { cityName } },
  });

  const skillLevelOptions = useMemo(() => {
    const skillLevels: string[] = data?.discoverSkillLevels?.skillLevels || [];

    const onPressSkillLevel = (newValue: string) => {
      if (onPress) onPress(newValue);
    };
    return skillLevels.map(level => (
      <CustomButton
        key={level}
        value={level}
        variant="outline"
        onPress={onPressSkillLevel}
        pressed={selectedSkillLevels.includes(level)}
        style={styles.button}
      />
    ));
  }, [data, onPress, selectedSkillLevels]);

  if (error) {
    return (
      <View>
        <Text>{error.message}</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.row}>{skillLevelOptions}</View>
    </View>
  );
};

export default SkillLevelFilter;
