import { createWithEqualityFn, shallow } from '../../../utilities/zustand';
import { RootModalId, type ModalAlertStore, type ModalState } from './modalAlert.types';

export const initialModalState: ModalState = {
  isOpen: false,
  title: '',
  message: '',
  buttons: [],
  modalProps: {},
};

/**
 * Current implementation of the `ModalAlert` store
 * - allows for one `ModalAlert` to be open at a time (if called sequentially without closing, will just replace the current `ModalAlert`)
 */
const useModalAlertStore = createWithEqualityFn<ModalAlertStore>(
  (set, get) => ({
    alerts: {
      [RootModalId]: initialModalState,
    },
    alert: ({ id = RootModalId, title, message, buttons = [], modalProps = {} }) => {
      const alertExists = get().alerts[id];
      if (!alertExists) {
        console.error(
          `ModalAlert with id: ${id} does not exist, make sure that the id is correct and that the alert has been mounted & initialized.`
        );

        return;
      }

      set(state => ({
        ...state,
        alerts: {
          ...state.alerts,
          [id]: {
            isOpen: true,
            title,
            message,
            buttons,
            modalProps,
          },
        },
      }));
    },
    close: (id = RootModalId) => {
      set(state => ({
        ...state,
        alerts: {
          ...state.alerts,
          [id]: initialModalState,
        },
      }));
    },
  }),
  shallow
);

export default useModalAlertStore;
