import { gql, type TypedDocumentNode } from '@apollo/client';

type GetTeamData = {
  team: {
    _id: string;
    name: string;
    players: {
      displayNameTeams?: string;
      fullName?: string;
      gender?: string;
      email: string;
      phone_number?: string;
      picture?: string;
      player_details?: {
        email: string;
      };
      _id: string;
    }[];
  };
};

type GetTeamInput = {
  id: string;
};

const GET_TEAM: TypedDocumentNode<GetTeamData, GetTeamInput> = gql`
  query Team($id: ID!) {
    team(_id: $id) {
      _id
      name
      players {
        displayNameTeams
        fullName
        gender
        email
        phone_number
        picture
        _id
        player_details {
          email
        }
      }
    }
  }
`;

export default GET_TEAM;
