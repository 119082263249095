import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgAllTeamsMakePlayoffs = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M28.598 61a1.5 1.5 0 0 1 1.5-1.5h19a1.5 1.5 0 0 1 1.5 1.5v4.5h-22zM32.598 56a1.5 1.5 0 0 1 1.5-1.5h11a1.5 1.5 0 0 1 1.5 1.5v3.5h-14zM37.598 45.5h4v9h-4z"
    />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M26.05 17.285a1.5 1.5 0 0 1 1.472-1.785h24.151a1.5 1.5 0 0 1 1.473 1.785L49.222 37.56a9.803 9.803 0 0 1-19.249 0z"
    />
    <Path
      stroke="#103b5d"
      d="M19.895 21.863a1.134 1.134 0 0 1 1.11-1.363h5.585l2.785 13.98a9.19 9.19 0 0 1-8.384-7.309zM49.817 34.48 52.51 20.5h5.605c.71 0 1.242.65 1.103 1.345l-1.064 5.32a9.125 9.125 0 0 1-8.339 7.315Z"
    />
  </Svg>
);
export default SvgAllTeamsMakePlayoffs;
