export enum ProgramTypeEnum {
  /** recurring program, typically 5-8 weeks with possible playoffs; teams compete */
  LEAGUE = 'LEAGUE',
  /** not recurring; teams compete */
  TOURNAMENT = 'TOURNAMENT',
  /** not recurring; social events, no competition */
  EVENT = 'EVENT',
  /** not recurring; designed to help players improve their game */
  PRACTICE = 'PRACTICE',
  /** Daily, but multiple can be created at once; no pre-constucted teams */
  PICKUP = 'PICKUP',
  /** Not often used as program type; Drop-ins are one-off open spots on League teams */
  DROPIN = 'DROPIN',
  /** Currently used for Zoom info sessions */
  VOLUNTEER_EVENT = 'VOLUNTEER_EVENT',
  /** Currently used for Zoom info sessions */
  VOLUNTEER_LEAGUE = 'VOLUNTEER_LEAGUE',
  /** one-time classes */
  CLASS = 'CLASS',
  /** not recurring; focus on teaching and skillset of a sport */
  CLINIC = 'CLINIC',
  /** recurring classes */
  SERIES = 'SERIES',
  /** Court rentals */
  RENTAL = 'RENTAL',
  /**
   * @deprecated
   * use TOURNAMENT for stand-alone tournaments
   * or create playoff weeks on a LEAGUE
   * */
  PLAYOFFS = 'PLAYOFFS',
  /**
   * @deprecated
   * currently not offered
   * */
  VIRTUAL = 'VIRTUAL',
}

export const ProgramType: Record<
  ProgramTypeEnum,
  { value: ProgramTypeEnum; name: string; isDaily: boolean }
> = Object.freeze({
  [ProgramTypeEnum.LEAGUE]: {
    value: ProgramTypeEnum.LEAGUE,
    name: 'League',
    isDaily: false,
  },
  [ProgramTypeEnum.SERIES]: {
    value: ProgramTypeEnum.SERIES,
    name: 'Series',
    isDaily: false,
  },
  [ProgramTypeEnum.TOURNAMENT]: {
    value: ProgramTypeEnum.TOURNAMENT,
    name: 'Tournament',
    isDaily: false,
  },
  [ProgramTypeEnum.VOLUNTEER_LEAGUE]: {
    value: ProgramTypeEnum.VOLUNTEER_LEAGUE,
    name: 'Volunteer - League',
    isDaily: false,
  },
  // DAILY
  [ProgramTypeEnum.EVENT]: {
    value: ProgramTypeEnum.EVENT,
    name: 'Event',
    isDaily: true,
  },
  [ProgramTypeEnum.PRACTICE]: {
    value: ProgramTypeEnum.PRACTICE,
    name: 'Practice',
    isDaily: true,
  },
  [ProgramTypeEnum.PICKUP]: {
    value: ProgramTypeEnum.PICKUP,
    name: 'Pickup',
    isDaily: true,
  },
  [ProgramTypeEnum.VOLUNTEER_EVENT]: {
    value: ProgramTypeEnum.VOLUNTEER_EVENT,
    name: 'Volunteer - Event',
    isDaily: true,
  },
  [ProgramTypeEnum.CLASS]: {
    value: ProgramTypeEnum.CLASS,
    name: 'Class',
    isDaily: true,
  },
  [ProgramTypeEnum.CLINIC]: {
    value: ProgramTypeEnum.CLINIC,
    name: 'Clinic',
    isDaily: true,
  },
  [ProgramTypeEnum.DROPIN]: {
    value: ProgramTypeEnum.DROPIN,
    name: 'Drop-In',
    isDaily: true,
  },
  // RENTALS
  [ProgramTypeEnum.RENTAL]: {
    value: ProgramTypeEnum.RENTAL,
    name: 'Rental',
    isDaily: false,
  },
  /** @deprecated */
  [ProgramTypeEnum.PLAYOFFS]: {
    value: ProgramTypeEnum.PLAYOFFS,
    name: 'Playoffs',
    isDaily: false,
  },
  /** @deprecated */
  [ProgramTypeEnum.VIRTUAL]: {
    value: ProgramTypeEnum.VIRTUAL,
    name: 'Virtual',
    isDaily: false,
  },
});

export const LeagueProgramTypes = Object.freeze([
  'LEAGUE',
  'TOURNAMENT',
  'SERIES',
  'VOLUNTEER_LEAGUE',
]);
export const DailyProgramTypes = Object.freeze([
  'EVENT',
  'PRACTICE',
  'PICKUP',
  'VOLUNTEER_EVENT',
  'CLASS',
  'CLINIC',
  'DROPIN',
]);

export const DailyProgramsList = Object.freeze([
  ProgramTypeEnum.CLASS,
  ProgramTypeEnum.CLINIC,
  ProgramTypeEnum.DROPIN,
  ProgramTypeEnum.EVENT,
  ProgramTypeEnum.PICKUP,
  ProgramTypeEnum.PRACTICE,
]);

export const SportsList = Object.freeze([
  ProgramTypeEnum.VOLUNTEER_EVENT,
  ProgramTypeEnum.VOLUNTEER_LEAGUE,
  ProgramTypeEnum.LEAGUE,
  ProgramTypeEnum.TOURNAMENT,
  ProgramTypeEnum.SERIES,
]);

export const HomeDailyProgramsList = Object.freeze([
  ProgramTypeEnum.CLASS,
  ProgramTypeEnum.CLINIC,
  ProgramTypeEnum.DROPIN,
  ProgramTypeEnum.PICKUP,
  ProgramTypeEnum.PRACTICE,
]);

export const VoloFitnessProgramsList = Object.freeze([
  ProgramTypeEnum.CLASS,
  ProgramTypeEnum.SERIES,
]);

export const EventsList = Object.freeze([ProgramTypeEnum.EVENT]);

export const findDailyProgramTypeKeyByName = (programTypeName: string) => {
  const cleanProgramType = (programTypeName || '').trim().toLowerCase();
  const programTypeFound = Object.entries(ProgramType).find(
    ([key, item]) =>
      key !== ProgramTypeEnum.LEAGUE &&
      key !== ProgramTypeEnum.VOLUNTEER_LEAGUE &&
      item.name.toLowerCase() === cleanProgramType
  );
  return programTypeFound?.[0];
};
