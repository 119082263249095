/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps } from 'react';
import { type AccessibilityRole, Platform } from 'react-native';
import { type ISkeletonProps, Skeleton } from '@rivallapp/native-base';
import { Column } from '../../base-components';
import TableRow from './components/TableRow';
import TableCell from './components/TableCell';
import TableHeader from './components/TableHeader';
import TableRowGroup from './components/TableRowGroup';
import TableProvider, { type TableProviderProps } from './context/TableProvider';

export type TableProps = TableProviderProps &
  ComponentProps<typeof Column> & {
    /**
     * Renders a skeleton loading state when `true`
     *
     * @default false
     */
    isLoading?: boolean;
    /**
     * Number of skeleton rows to render when `isLoading` is `true`
     *
     * @default 10
     */
    numberOfSkeletonRows?: number;
    /**
     * Height of each skeleton row using the native-base responsive values
     *
     * @default 8
     */
    skeletonRowHeight?: ISkeletonProps['height'];
    /**
     * Space between each skeleton row using the native-base responsive values
     *
     * @default 1
     */
    skeletonRowSpacing?: ISkeletonProps['mb'];
  };

type TableSubComponents = {
  Row: typeof TableRow;
  Cell: typeof TableCell;
  Header: typeof TableHeader;
  RowGroup: typeof TableRowGroup;
};

/**
 * Custom accessibility role will be passed to the `div` on web, but throws on mobile.
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/table_role
 * */
const role = Platform.OS === 'web' ? ('table' as AccessibilityRole) : undefined;

/**
 * A basic component for accessible cross-platform tables.
 *
 * Loosely follows the WAI-ARIA guidelines outlined here:
 * https://www.w3.org/WAI/ARIA/apg/patterns/table/examples/table/#rps_label
 *
 * This is _not_ a drop in replacement for an HTML table.
 * Feature-rich tables such as this: https://www.ag-grid.com/example/
 * should use a library designed with complicated data-grids in mind.
 *
 * See:
 * https://tanstack.com/table
 * https://www.material-react-table.com/
 *
 * `Table` contains four sub-components that can be used to build out a table
 *
 * - `Table.Row`
 *   - The core component for building out table rows
 *   - Use this for header, footer, and data rows
 *
 *
 * - `Table.RowGroup`
 *   - Semantic component used to define row groups
 *   - Best used to separate data rows from header/footer rows
 *   - Also useful for grouping together header/footer rows in more complicated layouts
 *
 *
 * - `Table.Cell`
 *   - The core component for building out row cells
 *   - Directly pass text or components as children
 *
 *
 * - `Table.Header`
 *   - Semantic component used to define column headers
 *   - Same functionality as `Table.Cell`
 *   - Has default text styling that is different than `Cell`
 *
 * @example
 * <Table>
 *  <Table.RowGroup>
 *    <Table.Header>My Table</Table.Header>
 *    <Table.Row>
 *      <Table.Header>Name</Table.Header>
 *      <Table.Header>City</Table.Header>
 *      <Table.Header>State</Table.Header>
 *    </Table.Row>
 *  </Table.RowGroup>
 *  <Table.RowGroup>
 *    <Table.Row>
 *      <Table.Cell>Tom</Table.Cell>
 *      <Table.Cell>Denver</Table.Cell>
 *      <Table.Cell>Colorado</Table.Cell>
 *    </Table.Row>
 *    <Table.Row>
 *      <Table.Cell>Sally</Table.Cell>
 *      <Table.Cell>Providence</Table.Cell>
 *      <Table.Cell>Rhode Island</Table.Cell>
 *    </Table.Row>
 *  </Table.RowGroup>
 * </Table>
 * */
const Table: React.FC<TableProps> & TableSubComponents = ({
  children,
  unstyled,
  defaultCellProps,
  defaultCellTextProps,
  defaultHeaderCellProps,
  defaultHeaderTextProps,
  isLoading,
  skeletonRowHeight = 8,
  skeletonRowSpacing = 1,
  numberOfSkeletonRows = 10,
  ...props
}) => (
  <TableProvider
    unstyled={unstyled}
    defaultCellProps={defaultCellProps}
    defaultCellTextProps={defaultCellTextProps}
    defaultHeaderCellProps={defaultHeaderCellProps}
    defaultHeaderTextProps={defaultHeaderTextProps}
  >
    <Column accessibilityRole={role} {...props}>
      {isLoading
        ? Array.from({ length: numberOfSkeletonRows }).map((__, i) => (
            <Skeleton key={i} height={skeletonRowHeight} mb={skeletonRowSpacing} />
          ))
        : children}
    </Column>
  </TableProvider>
);

Table.Row = TableRow;
Table.Cell = TableCell;
Table.Header = TableHeader;
Table.RowGroup = TableRowGroup;

export default Table;
