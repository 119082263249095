import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import type { InterfaceModalProps } from '@rivallapp/native-base/lib/typescript/components/composites/Modal/types';

import {
  Button,
  FontAwesomeIcon,
  Heading,
  Modal,
  Row,
  Stack,
  Text,
  type ButtonProps,
} from '../../../../base-components';
import { isMobile } from '../../../../utilities';

export interface RentalConfirmationModalProps extends InterfaceModalProps {
  onClose: () => void;
  reservationIds: Array<string>;
  deepLink?: string;
  renderBlurViewComponent?: () => JSX.Element;
  onDashboardPress: (reservationIds: Array<string>) => void;
  /**
   * deepLink should be available only when there is a single reservation
   * - when multiple reservations - we do not show the invite button
   */
  onInvitePress: (deepLink: string | undefined) => Promise<void> | void;
  onInviteLoading?: boolean;
}

interface Button extends ButtonProps {
  key: string;
  visible: boolean;
}

const multipleText =
  'Go to your dashboard to invite your friends to join you for your private rentals!';

function getSingleText(deepLink?: string) {
  /* invite btn showing */
  if (deepLink) {
    return 'Invite your friends to join you for your private rental!';
  }

  /* no deep link - only seeing dashboard btn */
  return 'Go to your dashboard to view your private rental reservation!';
}

const RentalConfirmationModal = ({
  renderBlurViewComponent,
  reservationIds,
  deepLink,
  onDashboardPress,
  onInvitePress,
  onInviteLoading,
  isOpen,
  onClose,
  ...rest
}: RentalConfirmationModalProps) => {
  const reservationCount = reservationIds.length;
  const rentalText = reservationCount === 1 ? 'rental' : 'rentals';
  const confirmationSubText = reservationCount === 1 ? getSingleText(deepLink) : multipleText;
  const inviteBtnVisible = reservationCount === 1 && deepLink !== undefined;

  const buttons: Array<Button> = [
    {
      key: 'dashboard',
      children: 'DASHBOARD',
      onPress: () => {
        onClose();
        onDashboardPress?.(reservationIds);
      },
      visible: true,
      type: 'primary',
    },
    {
      key: 'invite',
      children: 'INVITE',
      onPress: async () => {
        await onInvitePress?.(deepLink);
      },
      variant: 'outline',
      visible: inviteBtnVisible,
      _text: {
        bold: true,
        fontSize: 'lg',
        _dark: {
          color: 'white.600',
        },
      },
      borderRadius: 'md',
      isLoading: onInviteLoading,
    },
  ];

  return (
    <Modal useRNModal size={isMobile ? 'xl' : undefined} isOpen={isOpen} {...rest}>
      <Modal.Content zIndex={10} shadow={5}>
        <Modal.Header alignItems="center" borderBottomWidth={0}>
          <FontAwesomeIcon icon={faCheckCircle} size={70} colorScheme="success" />
        </Modal.Header>
        <Modal.Body>
          <Stack space={4}>
            <Heading textAlign="center" type="subsection">
              Your {rentalText} {reservationCount === 1 ? 'has' : 'have'} been confirmed
            </Heading>
            <Text>{confirmationSubText}</Text>
            <Row alignItems="center" justifyContent="space-between" space={2}>
              {buttons
                .filter(button => button.visible)
                .map(button => (
                  <Button {...button} flex={1} height={45} padding={0} />
                ))}
            </Row>
          </Stack>
        </Modal.Body>
      </Modal.Content>
      {/* mobile can render blur view like in mocks, web would just be regular overlay */}
      {renderBlurViewComponent?.()}
    </Modal>
  );
};

export default RentalConfirmationModal;
