import { type TypedDocumentNode, gql } from '@apollo/client';

type GetRentalPoliciesFromVenueData = {
  venue: {
    _id: string;
    rentals: {
      _id: string;
      venue_policies: string;
    }[];
  };
};

type GetRentalPoliciesFromVenueInput = {
  venueId: string;
};

const GET_RENTAL_POLICIES_FROM_VENUE: TypedDocumentNode<
  GetRentalPoliciesFromVenueData,
  GetRentalPoliciesFromVenueInput
> = gql`
  query venue($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      rentals {
        _id
        venue_policies
      }
    }
  }
`;

export default GET_RENTAL_POLICIES_FROM_VENUE;
