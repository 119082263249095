import { ImageBackground } from 'react-native';
import { type ComponentProps } from 'react';

import { useMediaQuery } from '@rivallapp/native-base';
import { Box, Button, Heading, Modal, Row, Stack, Text } from '../../base-components';
import VoloPassPerksText from '../MembershipSignupAds/components';
import { Colors } from '../../theme';

type VoloPassPaywallProps = {
  onViewOtherPrograms: () => void;
  onSignUp: () => void;
  scale?: number;
} & ComponentProps<typeof Modal>;

const VoloPassPhoneHorizontal = require('../../../assets/volopass/volopass-phone-horizontal.jpg');
const VoloPassPhoneVertical = require('../../../assets/volopass/volopass-phone-vertical.jpg');

const VoloPassPaywallHorizontal = ({
  onViewOtherPrograms,
  onSignUp,
  scale = 1,
  ...modalProps
}: VoloPassPaywallProps) => {
  return (
    <Modal useRNModal size="full" isOpen closeOnOverlayClick={false} {...modalProps}>
      <Modal.Content width="1000px" height="442px" style={{ transform: [{ scale }] }}>
        <ImageBackground source={VoloPassPhoneHorizontal} style={{ width: '100%', height: '100%' }}>
          <Stack width="70%" space={5} paddingY={10} paddingLeft={20}>
            <Box>
              <Heading fontWeight="extrabold" color={Colors.white} fontSize={36}>
                BECOME A MEMBER
              </Heading>
              <Heading fontWeight="extrabold" color={Colors.secondary} fontSize={36}>
                TO ACCESS THIS PROGRAM
              </Heading>
            </Box>
            <Text color={Colors.secondary} fontSize={24}>
              GET UNLIMITED SPORTS ON DEMAND
            </Text>
            <VoloPassPerksText />
            <Row space={3} width="80%">
              <Button
                variant="unstyled"
                flex={2}
                height="40px"
                borderColor={Colors.greys.light}
                borderWidth={1}
                borderRadius="full"
                _text={{ color: Colors.greys.light }}
                onPress={onViewOtherPrograms}
              >
                View other programs
              </Button>
              <Button
                flex={1}
                height="40px"
                borderRadius="full"
                backgroundColor={Colors.secondary}
                onPress={onSignUp}
              >
                Sign up
              </Button>
            </Row>
          </Stack>
        </ImageBackground>
      </Modal.Content>
    </Modal>
  );
};

const VoloPassPaywallVertical = ({
  onViewOtherPrograms,
  onSignUp,
  scale = 1,
  ...modalProps
}: VoloPassPaywallProps) => {
  return (
    <Modal useRNModal size="full" isOpen closeOnOverlayClick={false} {...modalProps}>
      <Modal.Content
        minWidth="300px"
        maxWidth="343px"
        maxHeight="650px"
        style={{ aspectRatio: 343 / 669, transform: [{ scale }] }}
      >
        <ImageBackground source={VoloPassPhoneVertical} style={{ width: '100%', height: '100%' }}>
          <Box flex={1} />
          <Stack flex={2.2} paddingX={3} paddingY={8}>
            <Heading textAlign="center" fontWeight="extrabold" color={Colors.white} fontSize={26}>
              BECOME A MEMBER
            </Heading>
            <Heading
              textAlign="center"
              fontWeight="extrabold"
              color={Colors.secondary}
              fontSize={22}
            >
              TO ACCESS THIS PROGRAM
            </Heading>

            <Text marginTop={3} textAlign="center" color={Colors.secondary} fontSize={20}>
              GET UNLIMITED SPORTS ON DEMAND
            </Text>
            <VoloPassPerksText
              containerStyle={{ marginX: 'auto', marginY: 5, space: 3 }}
              size="sm"
            />

            <Stack alignSelf="center" space={4} width="85%">
              <Button borderRadius="full" backgroundColor={Colors.secondary} onPress={onSignUp}>
                Sign up
              </Button>
              <Button
                variant="unstyled"
                borderColor={Colors.greys.light}
                height="50px"
                borderWidth={1}
                borderRadius="full"
                _text={{ color: Colors.greys.light }}
                onPress={onViewOtherPrograms}
              >
                View other programs
              </Button>
            </Stack>
          </Stack>
        </ImageBackground>
      </Modal.Content>
    </Modal>
  );
};

/**
 * Returns a paywall modal depending on screen size.
 * Lots of hard-coding here unfortunately due to the background image and having
 * to work around the photo in the background. There's a `scale` prop for emergencies.
 */
const VoloPassPaywall = (props: VoloPassPaywallProps) => {
  const [vertical] = useMediaQuery({ maxWidth: 1000 });

  return vertical ? (
    <VoloPassPaywallVertical {...props} />
  ) : (
    <VoloPassPaywallHorizontal {...props} />
  );
};

export default VoloPassPaywall;
