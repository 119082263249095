import { StyleSheet } from 'react-native';
import { Colors } from '../../theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 3,
    marginHorizontal: 15,
  },
  checkbox: {
    width: 28,
    height: 28,
    marginRight: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  isHovered: {
    backgroundColor: Colors.hover,
  },
  pressable: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkIcon: {
    width: 20,
    height: 20,
  },
  label: {
    fontSize: 18,
    alignItems: 'center',
    marginRight: 8,
  },
  icon: {
    height: 15,
    width: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
