import { gql, type TypedDocumentNode } from '@apollo/client';
import type { SportSurfaceEnum, TRentalActivityName } from '../../constants';

interface RentalTimeslot {
  _id: string;
  start_time: Date;
  end_time: Date;
  start_time_str: string;
  end_time_str: string;
}

interface Rental {
  _id: string;
  activity_name: TRentalActivityName;
  timezone: string;
  name: string;
  venue: {
    _id: string;
    shorthandName: string;
    name: string;
    maps_url_query?: string;
    formatted_address?: string;
  };
  courts: string[];
  numeric_courts: boolean;
  surface_type: SportSurfaceEnum;
  first_timeslot_by_date: Pick<RentalTimeslot, '_id' | 'start_time'>;
  last_timeslot_by_date: Pick<RentalTimeslot, '_id' | 'end_time'>;
  first_timeslot_by_time: Pick<RentalTimeslot, '_id' | 'start_time_str'>;
  last_timeslot_by_time: Pick<RentalTimeslot, '_id' | 'end_time_str'>;
  waiver: {
    _id: string;
    content: string;
  };
  is_discoverable: boolean;
  banner_message?: string;
  standard_price_cents: number;
  prime_price_cents?: number;
  vp_standard_price_cents: number;
  vp_prime_price_cents?: number;
  has_available_timeslots: boolean;
  featured_order?: number;
  deep_link: string;
  player_app_link: string;
}

export interface RentalData {
  rental: Rental;
}

export interface RentalVars {
  id: string;
}

export const RENTAL_DETAILS: TypedDocumentNode<RentalData, RentalVars> = gql`
  query RentalDetails($id: ID!) {
    rental(_id: $id) {
      _id
      activity_name
      timezone
      name
      venue {
        _id
        shorthandName
        name
        maps_url_query
        formatted_address
      }
      courts
      numeric_courts
      surface_type
      first_timeslot_by_date {
        _id
        start_time
      }
      last_timeslot_by_date {
        _id
        end_time
      }
      first_timeslot_by_time {
        _id
        start_time_str
      }
      last_timeslot_by_time {
        _id
        end_time_str
      }
      waiver {
        _id
        content
      }
      is_discoverable
      banner_message
      standard_price_cents
      prime_price_cents
      vp_standard_price_cents
      vp_prime_price_cents
      has_available_timeslots
      featured_order
      deep_link
      player_app_link
    }
  }
`;

interface RentalDiscoverabilityData {
  _id: string;
  is_discoverable: boolean;
}

interface RentalDiscoverabilityVars {
  input: {
    rental_id: string;
    is_discoverable: boolean;
  };
}

export const UPDATE_RENTAL_DISCOVERABILITY: TypedDocumentNode<
  RentalDiscoverabilityData,
  RentalDiscoverabilityVars
> = gql`
  mutation UpdateRentalDiscoverability($input: UpdateRentalDiscoverabilityInput!) {
    updateRentalDiscoverability(input: $input) {
      _id
      is_discoverable
    }
  }
`;

interface RentalBannerMessageData {
  _id: string;
  banner_message: string;
}

interface RentalBannerMessageVars {
  input: {
    rental_id: string;
    banner_message: string;
  };
}

export const UPDATE_RENTAL_BANNER_MESSAGE: TypedDocumentNode<
  RentalBannerMessageData,
  RentalBannerMessageVars
> = gql`
  mutation UpdateRentalBannerMessage($input: UpdateRentalBannerMessageInput!) {
    updateBannerMessage(input: $input) {
      _id
      banner_message
    }
  }
`;

interface RentalOverviewStatsData {
  rentalOverviewStats: {
    reservation_holder_count: number;
    attendee_count: number;
    slots_available: number;
    slots_taken: number;
    slots_total: number;
    revenue_cents: number;
  };
}

interface RentalOverviewStatsVars {
  _id: string;
}

export const RENTAL_OVERVIEW_STATS: TypedDocumentNode<
  RentalOverviewStatsData,
  RentalOverviewStatsVars
> = gql`
  query RentalOverviewStats($_id: ID!) {
    rentalOverviewStats(_id: $_id) {
      reservation_holder_count
      attendee_count
      slots_available
      slots_taken
      slots_total
      revenue_cents
    }
  }
`;

interface RentalFeaturedOrderData {
  _id: string;
  featured_order: number;
}

interface RentalFeaturedOrderVars {
  input: {
    rental_id: string;
    featured_order: number;
  };
}

export const UPDATE_RENTAL_FEATURED_ORDER: TypedDocumentNode<
  RentalFeaturedOrderData,
  RentalFeaturedOrderVars
> = gql`
  mutation UpdateRentalFeaturedOrder($input: UpdateRentalFeaturedOrderInput!) {
    updateFeaturedOrder(input: $input) {
      _id
      featured_order
    }
  }
`;
