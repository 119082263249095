import { type TypedDocumentNode, gql } from '@apollo/client';
import { VenuePerksEnum } from '../../../../../constants/enums/venue-features-enum';

type GetRentalPerksData = {
  rental: {
    _id: string;
    perks: VenuePerksEnum[];
    custom_perks?: string;
  };
};

type GetRentalPerksInput = {
  rentalId: string;
};

const GET_RENTAL_PERKS: TypedDocumentNode<GetRentalPerksData, GetRentalPerksInput> = gql`
  query rental($rentalId: ID!) {
    rental(_id: $rentalId) {
      _id
      perks
      custom_perks
    }
  }
`;

export default GET_RENTAL_PERKS;
