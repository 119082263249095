import { useQuery } from '@apollo/client';
import { GET_USER_VOLO_FITNESS_MEMBERSHIP_DETAILS } from './gql/GET_USER_MEMBERSHIP_DETAILS';
import {
  MembershipInterval,
  MembershipStatus,
  type MembershipIntervalType,
  type MembershipStatusType,
} from '../../constants';

const useUserVoloFitnessMembershipStatus = () => {
  const {
    data: membershipData,
    loading: membershipLoading,
    error: membershipError,
  } = useQuery(GET_USER_VOLO_FITNESS_MEMBERSHIP_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    userIsActiveVoloFitnessMember,
    userHasPastVoloFitnessMembership,
    activeVoloFitnessMembership,
    pastVoloFitnessMembership,
  } = membershipData || {};

  const hasMemberHistory = userIsActiveVoloFitnessMember || userHasPastVoloFitnessMembership;

  let membershipStatus: MembershipStatusType | undefined;
  let membershipStartDate = '';
  let membershipType: MembershipIntervalType | undefined;

  if (userIsActiveVoloFitnessMember && !!activeVoloFitnessMembership) {
    const { planStartsAt, plan, status } = activeVoloFitnessMembership || {};
    const { interval } = plan || {};
    membershipStatus = MembershipStatus[status as unknown as keyof typeof MembershipStatus].label;
    membershipStartDate = planStartsAt;
    membershipType =
      MembershipInterval[interval as unknown as keyof typeof MembershipInterval].label;
  } else if (userHasPastVoloFitnessMembership && !!pastVoloFitnessMembership) {
    const { planStartsAt, plan, status } = pastVoloFitnessMembership || {};
    const { interval } = plan || {};
    membershipStatus = MembershipStatus[status as unknown as keyof typeof MembershipStatus].label;
    membershipStartDate = planStartsAt;
    membershipType =
      MembershipInterval[interval as unknown as keyof typeof MembershipInterval].label;
  }

  return {
    voloFitnessMembershipLoading: membershipLoading,
    voloFitnessMembershipError: membershipError,
    hasVoloFitnessMemberHistory: hasMemberHistory,
    voloFitnessMembershipStatus: membershipStatus,
    voloFitnessMembershipStartDate: membershipStartDate,
    voloFitnessMembershipType: membershipType,
  };
};

export default useUserVoloFitnessMembershipStatus;
