import { useRentalBookingStore, type RentalBooking } from '../../../RentalsRegistration';
import type {
  Agreements,
  BookRentalsInput,
  JoinReservationInput,
  PriceModifiers,
} from './gql/mutations';

export function formatCartForReservations(
  bookings: RentalBooking[],
  {
    donationCents,
    promoCodeStr,
    creditCents,
    waiverAgreed,
    policyAgreed,
  }: PriceModifiers & Agreements
): BookRentalsInput {
  const pendingReservations = bookings.map(booking => ({
    bookingId: booking.bookingId,
    rentalId: booking.rentalId,
    selectedTimeSlotIds: booking.steps.courtsSelection.selectedCourts.flatMap(
      court => court.timeSlotIds
    ),
    numGuestsEstimate: booking.steps.groupSettings.groupSize!,
    password: booking.steps.groupSettings.groupPassword,
  }));

  return {
    pendingReservations,
    donationCents,
    promoCodeStr,
    creditCents,
    policyAgreed,
    waiverAgreed,
  };
}

export function getBookingMutationInput() {
  const {
    confirmedBookings: confirmedBookingsDict,
    creditCents,
    donation,
    promoCodeString,
    liabilityTermsAgreed,
    rentalPolicyAgreed,
  } = useRentalBookingStore.getState();
  const confirmedBookings = Object.values(confirmedBookingsDict);

  return formatCartForReservations(confirmedBookings, {
    donationCents: donation?.value ?? 0,
    promoCodeStr: promoCodeString,
    creditCents,
    waiverAgreed: liabilityTermsAgreed,
    policyAgreed: rentalPolicyAgreed,
  });
}

export function getReservationMutationInput(reservationId: string) {
  const { donation, liabilityTermsAgreed, rentalPolicyAgreed, joiningReservationDetails } =
    useRentalBookingStore.getState();

  const input: JoinReservationInput = {
    reservationId,
    password: joiningReservationDetails?.userInputGroupPassword,
    donationCents: donation?.value,
    policyAgreed: rentalPolicyAgreed,
    waiverAgreed: liabilityTermsAgreed,
  };

  return input;
}
