import { useState } from 'react';

import { ModalAlert, ModalAlertComponent } from '../../../../../custom-components';
import { Text, Stack, Button, Modal, FormControl } from '../../../../../base-components';
import { useRentalBookingStore } from '../../../store';
import type { ConfirmedBookingItemProps } from './components/ConfirmedBookingItem';
import ConfirmedBookingItem from './components/ConfirmedBookingItem';
import RentalSubtext from '../RentalSubtext';
import RentalTextInput from '../RentalTextInput';
import RentalSectionLabel from '../RentalSectionLabel';
import RentalSectionTitle from '../RentalSectionTitle';

export interface RentalBookingsDisplayProps {
  /**
   * onEditPress callback to setting the bookingId to edit
   */
  onEditPress: ConfirmedBookingItemProps['onEditPress'];
  /**
   * Callback to run when the user presses the proceed to checkout button
   * - A `route` is present when the user is joining a reservation that has a group password to be able to navigate to the `JoiningGroupSection` of checkout flow to confirm the password
   */
  onProceedToCheckoutPress: () => void;
}

function setUserInputGroupPassword(password: string) {
  useRentalBookingStore.setState(store =>
    store.joiningReservationDetails
      ? {
          joiningReservationDetails: {
            ...store.joiningReservationDetails,
            userInputGroupPassword: password,
          },
        }
      : store
  );
}

const RentalBookingsDisplay = ({
  onEditPress,
  onProceedToCheckoutPress,
}: RentalBookingsDisplayProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [touched, setTouched] = useState(false);

  const { confirmedBookings, joiningReservationDetails } = useRentalBookingStore(store => ({
    confirmedBookings: Object.values(store.confirmedBookings),
    joiningReservationDetails: store.joiningReservationDetails,
  }));
  const arePasswordsEqual =
    joiningReservationDetails?.groupPassword === joiningReservationDetails?.userInputGroupPassword;

  return (
    <Stack space={1}>
      <Text fontSize={18} bold>
        Bookings
      </Text>
      <Stack>
        {!confirmedBookings.length ? (
          <Text type="caption">No bookings added to your cart yet.</Text>
        ) : (
          confirmedBookings.map(booking => (
            <ConfirmedBookingItem
              key={booking.bookingId}
              booking={booking}
              {...(joiningReservationDetails ? { joiningReservationDetails } : { onEditPress })}
            />
          ))
        )}
      </Stack>
      {!!confirmedBookings.length && (
        <Stack justifyContent="center" alignItems="center" mt={2}>
          <Button
            style={{ width: 255, height: 35 }}
            p={0}
            onPress={() => {
              if (joiningReservationDetails?.groupPassword && !arePasswordsEqual) {
                setIsOpen(true);
                return;
              }

              if (Object.values(useRentalBookingStore.getState().inProgressBookings).length) {
                ModalAlert.alert({
                  id: 'in-progress-bookings-alert',
                  title: 'In Progress Bookings',
                  message:
                    'You have bookings that are still in progress! Would you like to still proceed to checkout?',
                  buttons: [
                    { text: 'Cancel' },
                    { text: 'Continue', onPress: onProceedToCheckoutPress },
                  ],
                });

                return;
              }

              onProceedToCheckoutPress();
            }}
          >
            {joiningReservationDetails ? 'Complete registration' : 'Proceed to checkout'}
          </Button>
        </Stack>
      )}
      <ModalAlertComponent id="in-progress-bookings-alert" />
      {/* Modal for joining a reservation with a group password */}
      {joiningReservationDetails && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} useRNModal>
          <Modal.KeyboardAwareContent>
            <Modal.Header>
              <RentalSectionTitle>
                {`Join ${joiningReservationDetails.createdBy.firstNameLastInitial}!`}
              </RentalSectionTitle>
              <RentalSubtext mt={1}>
                Enter your group password to complete registration for this private rental.
              </RentalSubtext>
            </Modal.Header>
            <Modal.Body>
              <Stack space={1}>
                <RentalSectionLabel>Group Password</RentalSectionLabel>
                <FormControl isRequired isInvalid={touched && !arePasswordsEqual}>
                  <RentalTextInput
                    isSecureText
                    textContentType="oneTimeCode"
                    placeholder="Password"
                    value={joiningReservationDetails.userInputGroupPassword}
                    onChangeText={setUserInputGroupPassword}
                    onBlur={() => setTouched(true)}
                  />
                </FormControl>
              </Stack>
            </Modal.Body>
            <Modal.Footer>
              <Button
                colorScheme="rentalBlue"
                isDisabled={!arePasswordsEqual}
                onPress={() => {
                  if (!arePasswordsEqual) return;
                  setIsOpen(false);
                  onProceedToCheckoutPress();
                }}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal.KeyboardAwareContent>
        </Modal>
      )}
    </Stack>
  );
};

export default RentalBookingsDisplay;
