import { type TypedDocumentNode, gql } from '@apollo/client';

type Data = {
  rental: {
    _id: string;
    images: string[];
  };
};

type Input = {
  rentalId: string;
};

const GET_RENTAL_IMAGES: TypedDocumentNode<Data, Input> = gql`
  query rental($rentalId: ID!) {
    rental(_id: $rentalId) {
      _id
      images
    }
  }
`;

export default GET_RENTAL_IMAGES;
