import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { Suspense, useEffect, useState, type FC, type ReactNode } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Column,
  FormControl,
  Heading,
  Loading,
  Row,
  Text,
  TextInput,
  View,
} from '../../base-components';
import { ConfirmCancelModal, ModalAlert, RenderHTML } from '../../custom-components';
import {
  AlternateMethod,
  DateFilter,
  EmailDetails,
  Filters,
  PushNotifications,
  type EmailDetailsProps,
} from './components';
import { useMessageCenterStore } from './store';
import {
  ADD_EMAIL_TEMPLATES,
  RENTAL_EMAIL_COUNT,
  SEND_RENTAL_EMAILS,
  type RentalMessageInput,
} from './gql';

interface MessageCenterProps extends Pick<EmailDetailsProps, 'TextEditor' | 'organizationId'> {
  rentalId: string;
}

const fallbackConfirmationFunc = () => console.log('How did you get here?');

const MessageCenter: FC<Omit<MessageCenterProps, 'rentalId'>> = ({
  organizationId,
  TextEditor,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationModalContent, setConfirmationModalContent] = useState<ReactNode>(null);
  const [confirmFunc, setConfirmFunc] = useState<VoidFunction>(
    () => () => fallbackConfirmationFunc()
  );
  const [hideConfirm, setHideConfirm] = useState(false);

  const { filters, messageContent, subject, from, manualEmails, rentalId } = useMessageCenterStore(
    store => ({
      filters: store.filters,
      messageContent: store.messageContent,
      subject: store.subject,
      from: store.from,
      manualEmails: store.manualEmails,
      rentalId: store.rentalId,
    })
  );

  const { selectedDates, ...remainingFilters } = filters;

  const { data: rentalEmailCountData } = useQuery(RENTAL_EMAIL_COUNT, {
    skip: !rentalId,
    variables: {
      input: {
        rentalId,
        filters: {
          ...remainingFilters,
          ...(filters.dateSelectionType === 'manual'
            ? {
                selectedDates: (filters.selectedDates ?? []).map(date =>
                  moment(date).format('YYYY-MM-DD')
                ) as string[],
              }
            : {}),
        },
      },
    },
  });

  const [addEmailTemplate, { loading: addTemplateLoading }] = useMutation(ADD_EMAIL_TEMPLATES);
  const [sendRentalEmails, { loading: sendEmailsLoading }] = useMutation(SEND_RENTAL_EMAILS);

  const onConfirmAddTemplate = async () => {
    try {
      await addEmailTemplate({
        variables: {
          input: {
            template: {
              html: messageContent,
              subject,
            },
            organizationId,
          },
        },
      });
    } catch (e: any) {
      let message = '';
      if ('message' in e) {
        message = e.message;
      } else {
        message = 'Adding a template failed. If the issue persists please contact support.';
      }
      ModalAlert.alert({
        title: 'Whoops!',
        message,
        buttons: [{ text: 'Continue', textColor: 'white.600', utilProps: { bg: 'primary.400' } }],
      });
    } finally {
      setShowConfirmation(false);
    }
  };

  const onPressAddTemplate = () => {
    setConfirmationModalContent(
      <>
        <FormControl>
          <FormControl.Label>Title of Template:</FormControl.Label>
          <TextInput
            value={subject}
            placeholder="Title"
            onChangeText={value => useMessageCenterStore.setState(() => ({ subject: value }))}
          />
        </FormControl>
        <FormControl.Label>Content:</FormControl.Label>
        <Box p="2" borderWidth="1" rounded="sm" borderColor="gray.400">
          <RenderHTML html={messageContent} />
        </Box>
      </>
    );
    setConfirmFunc(() => () => onConfirmAddTemplate());
    setHideConfirm(false);
    setShowConfirmation(true);
  };

  const onPressTestEmail = () => {
    setConfirmationModalContent(
      <>
        <FormControl isReadOnly>
          <FormControl.Label>Subject:</FormControl.Label>
          <TextInput
            value={subject}
            placeholder="Title"
            onChangeText={value => useMessageCenterStore.setState(() => ({ subject: value }))}
          />
        </FormControl>
        <FormControl.Label>Content:</FormControl.Label>
        <Box p="2" borderWidth="1" rounded="sm" borderColor="gray.400">
          <RenderHTML html={messageContent} />
        </Box>
      </>
    );
    setConfirmFunc(() => () => fallbackConfirmationFunc());
    setHideConfirm(true);
    setShowConfirmation(true);
  };

  const onConfirmSendEmail = async () => {
    try {
      await sendRentalEmails({
        variables: {
          input: {
            rentalId,
            message: messageContent,
            filters: {
              ...filters,
              selectedDates: (filters.selectedDates ?? []).map(date =>
                moment(date).format('YYYY-MM-DD')
              ),
            },
            emailInput: {
              organizationId,
              from,
              subject,
              manualEmails,
            },
          } satisfies RentalMessageInput,
        },
      });
    } catch (e: any) {
      let message = '';
      if ('message' in e) {
        message = e.message;
      } else {
        message = 'Sending email failed. If the issue persists please contact support.';
      }
      ModalAlert.alert({
        title: 'Whoops!',
        message,
        buttons: [{ text: 'Continue', textColor: 'white.600', utilProps: { bg: 'primary.400' } }],
      });
    } finally {
      setShowConfirmation(false);
    }
  };

  const onPressSendEmail = () => {
    setConfirmationModalContent(
      <>
        <Heading fontSize="md">Are you sure you want to send this email?</Heading>
        <Text my="2">
          It will be sent to <Text bold>{rentalEmailCountData?.rentalEmailCount.emailCount}</Text>{' '}
          people.
        </Text>
        <Text fontSize="sm" color="gray.400">
          Note: Emails sent to large # of players can take 10+ seconds to send.
        </Text>
      </>
    );
    setConfirmFunc(() => () => onConfirmSendEmail());
    setHideConfirm(false);
    setShowConfirmation(true);
  };

  if (!rentalId) {
    return <Loading />;
  }

  return (
    <View>
      <Heading color="gray.700">Compose Message</Heading>
      <Text color="gray.700">Send an email to players according to the filters selected.</Text>
      <Row my="4">
        <Column w="1/3" paddingRight="4">
          <Filters />
        </Column>
        <Column w="2/3">
          <PushNotifications />
          <DateFilter />
        </Column>
      </Row>
      {rentalEmailCountData && (
        <Suspense>
          <EmailDetails
            organizationId={organizationId}
            TextEditor={TextEditor}
            rentalEmailCount={rentalEmailCountData.rentalEmailCount.emailCount}
          />
        </Suspense>
      )}
      <Row justifyContent="space-between" alignItems="center" my="4">
        <FormControl w="1/3">
          <Checkbox
            isChecked={filters.isMarketing}
            value="marketing"
            onChange={isSelected =>
              useMessageCenterStore.setState(partial => ({
                filters: {
                  ...partial.filters,
                  isMarketing: isSelected,
                },
              }))
            }
            _text={{ color: 'gray.700' }}
          >
            This message is a Marketing Email
          </Checkbox>
        </FormControl>
        <Row space="2">
          <Button
            isDisabled={showConfirmation}
            isLoading={addTemplateLoading}
            onPress={onPressAddTemplate}
            bg="gray.600"
            _hover={{ bg: 'gray.500' }}
          >
            Save Email as Template
          </Button>
          <Button onPress={onPressTestEmail} bg="gray.600" _hover={{ bg: 'gray.500' }}>
            Test Email
          </Button>
          <Button
            isDisabled={showConfirmation}
            isLoading={sendEmailsLoading}
            onPress={onPressSendEmail}
          >
            Send Email
          </Button>
        </Row>
      </Row>
      <Suspense>
        <AlternateMethod />
      </Suspense>
      <ConfirmCancelModal
        isOpen={showConfirmation}
        headerText=""
        cancelText="Close"
        onClose={() => setShowConfirmation(false)}
        onConfirm={confirmFunc}
        hideConfirm={hideConfirm}
        size="xl"
        isLoading={addTemplateLoading || sendEmailsLoading}
      >
        {confirmationModalContent}
      </ConfirmCancelModal>
    </View>
  );
};

const MessageCenterWrapper: FC<MessageCenterProps> = ({ rentalId, organizationId, TextEditor }) => {
  useEffect(() => {
    useMessageCenterStore.setState(() => ({ rentalId, organizationId }));
  }, [organizationId, rentalId]);
  return <MessageCenter organizationId={organizationId} TextEditor={TextEditor} />;
};

export default MessageCenterWrapper;
