import type { IBoxProps } from '@rivallapp/native-base';
import { Box, RadialGradient, View, Stack, Text } from '../../base-components';
import { voloFitnessColorList } from '../../base-components/RadialGradient/colorLists';
import { VoloFitnessLogo } from '../../custom-components';
import { Colors } from '../../theme';
import { PriceFormatter } from '../../utilities';

type Props = {
  /** Will be formatted to dollar, ex. 4999 -> $49.99 */
  monthlyPriceInCents: number;
} & IBoxProps;

const VoloFitnessSignupAd = ({ monthlyPriceInCents, ...boxProps }: Props) => {
  return (
    <Box overflow="hidden" borderRadius="lg" {...boxProps}>
      <View position="absolute" width="100%" height="100%" overflow="hidden">
        <RadialGradient x="50%" y="50%" rx="50%" ry="50%" colorList={voloFitnessColorList} />
      </View>

      <Stack marginY={4} marginX={6} alignItems="center">
        <VoloFitnessLogo colorScheme="white" size={110} style={{ height: 61 }} />

        <Text
          mt={2}
          lineHeight="xs"
          fontWeight="semibold"
          color={Colors.white}
          fontSize="xl"
          textAlign="center"
        >
          Join Volo Fitness for {PriceFormatter.format(monthlyPriceInCents / 100)} a month* and get{' '}
          <Text color="#B3FFF8" bold fontSize="xl">
            unlimited
          </Text>{' '}
          fitness classes.
        </Text>

        <Text lineHeight="sm" marginY={4} bold color={Colors.white} textAlign="center">
          Membership deals are only available in select cities that offer Volo Fitness.
        </Text>

        <Text lineHeight="sm" color={Colors.white} textAlign="center">
          *3 month minimum required for monthly membership.{' '}
        </Text>
      </Stack>
    </Box>
  );
};
export default VoloFitnessSignupAd;
