import { StyleSheet, Dimensions, Platform } from 'react-native';

const isWeb = Platform.OS === 'web';
const { width } = Dimensions.get('window');

export default StyleSheet.create({
  rosterHeader: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 2,
  },
  infoLink: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 10,
  },
  overlay: {
    padding: 15,
    maxWidth: 800,
  },
  dropInSharePopup: {
    display: 'flex',
    flexDirection: 'column',
  },
  dropInLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  dropInLink: {
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    overflow: 'hidden',
    padding: 5,
  },
  copyUrlButton: {
    textAlign: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 10,
    cursor: 'pointer',
  },
  dropInHeader: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  dropInBody: {
    textAlign: 'left',
    lineHeight: 21,
  },
  skeletonContainer: {
    flex: 1,
    alignItems: 'center',
  },
  skeleton: {
    marginTop: 18,
    width: isWeb ? 345 : width - 30,
    height: 50,
    marginBottom: 10,
    borderRadius: 30,
  },
});
