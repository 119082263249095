/**
 * This was brought over from web and needs refactoring.
 * The needs of the components using it were distinct that the standard native-base Button wasn't sufficient
 * A future version of this button would ideally branch from or be combined with our base-component
 */

import { useState } from 'react';
import type { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { useTheme } from '../../theme';
import { isMobile } from '../../utilities';
import { Pressable, Text } from '../../base-components';
import buttonStyles, { type ButtonVariant } from './styles';

interface ButtonProps {
  thin?: boolean;
  value?: string;
  label?: string;
  fullWidth?: boolean;
  circular?: boolean;
  pressed?: boolean;
  variant?: ButtonVariant;
  onPress: (arg: string) => void | undefined;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

/**
 * @deprecated April 03 2024. Developers should instead try using `base-components/button` if possible.
 */
const CustomButton = ({
  thin = false,
  value = '',
  label = '',
  fullWidth = false,
  circular = false,
  pressed = false,
  variant,
  onPress,
  style,
  textStyle,
}: ButtonProps) => {
  const { colors } = useTheme(); // theme colors only applied to mobile
  const [hovered, setHovered] = useState(false);

  // pressable styles accepts callback
  const pressableStyles = [
    buttonStyles.base.baseStyles,
    ...(variant
      ? [
          buttonStyles[variant].baseStyles,
          ...(hovered && 'hover' in buttonStyles[variant] ? [buttonStyles[variant].hover] : []),
          ...(pressed && 'pressed' in buttonStyles[variant] ? [buttonStyles[variant].pressed] : []),
        ]
      : [
          ...(hovered ? [buttonStyles.base.hover] : []),
          ...(pressed ? [buttonStyles.base.pressed] : []),
        ]),

    // specific styling
    ...(circular ? [buttonStyles.base.circular] : []),
    ...(thin ? [buttonStyles.base.thin] : []),
    ...(fullWidth ? [buttonStyles.base.full] : []),
  ] as ViewStyle;

  // text doesn't have access to pressed/hovers params
  const textStyles = [
    buttonStyles.base.text,
    ...(variant
      ? [
          buttonStyles[variant].text,
          ...(hovered && 'textHover' in buttonStyles[variant]
            ? [buttonStyles[variant].textHover]
            : []),

          ...(pressed && 'textPressed' in buttonStyles[variant]
            ? [buttonStyles[variant].textPressed]
            : []),
        ]
      : [
          ...(hovered ? [buttonStyles.base.textHover] : []),
          ...(pressed ? [buttonStyles.base.textPressed] : []),
        ]),
  ] as StyleProp<TextStyle>;

  return (
    <Pressable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      style={[
        pressableStyles,
        isMobile && {
          borderColor: colors.border,
          backgroundColor: pressed ? colors.accents.select.bg : colors.background,
        },
        style,
      ]}
      onPress={() => onPress(value)}
    >
      <Text
        style={[
          textStyles,
          isMobile && {
            color: pressed ? colors.accents.select.text : colors.text,
          },
          textStyle,
        ]}
        selectable={false}
      >
        {label || value}
      </Text>
    </Pressable>
  );
};

export default CustomButton;
