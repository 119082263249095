import { type FC } from 'react';
import { Text, View } from '../../../../base-components';
import type { NeighborhoodVenueModule } from '../../RentalCard/types';

const NeighborhoodVenueSection: FC<Omit<NeighborhoodVenueModule, 'type'>> = ({
  neighborhood,
  venue,
}) => {
  const { venueName, onPressVenue } = venue;

  return (
    <View overflow="hidden" flexDirection="row" alignItems="center">
      <Text numberOfLines={1} fontSize={14}>
        <Text
          isLink
          fontSize={14}
          onPress={e => {
            e?.stopPropagation();
            onPressVenue(e);
          }}
        >
          {venueName}
        </Text>{' '}
        ({neighborhood})
      </Text>
    </View>
  );
};

export default NeighborhoodVenueSection;
