import { useEffect, useState, type TransitionStartFunction } from 'react';

const useDebounce = <T>(
  /**
   * Value to debounce.
   */
  value: T,
  /**
   * Debounce delay in milliseconds.
   */
  delay: number = 300,
  /**
   * Pass a transition start function to make this hook play nicely with suspense.
   */
  startTransition?: TransitionStartFunction
) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (startTransition) {
        startTransition(() => {
          setDebouncedValue(value);
        });
      } else {
        setDebouncedValue(value);
      }
    }, delay);
    return () => clearTimeout(timer);
  }, [value, delay, startTransition]);

  return debouncedValue;
};

export default useDebounce;
