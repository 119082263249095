import { useCallback } from 'react';
import { FlatList, type ListRenderItem } from 'react-native';

import { GridRow } from '../components';
import type { GridData, IGrid } from '../types';

export const FlatListGrid = ({
  data = [],
  RenderItem,
  scrollEnabled = false,
}: Omit<IGrid, 'RenderGrid'>) => {
  const keyExtractor = useCallback((item: GridData) => item.rowId, []);
  const _renderItem: ListRenderItem<GridData> = useCallback(
    ({ item: rowItems }) => <GridRow items={rowItems.items} RenderItem={RenderItem} />,
    [RenderItem]
  );

  return (
    <FlatList
      scrollEnabled={scrollEnabled}
      keyExtractor={keyExtractor}
      data={data}
      renderItem={_renderItem}
    />
  );
};

export default FlatListGrid;
