import { GET_USER_MEMBERSHIP_STATUS } from './gql/GET_USER_MEMBERSHIP_DETAILS';

/**
 * @description Global location for user membership status queries.
 * All permutations of current and past memberships.
 * Try to add fields to existing variants before creating a brand new one.
 * Adding fields shouldn't affect existing uses, but don't delete unless you're **sure** the fields are unused.
 * Add notes when updating this doc.
 */

// #region All Memberships - all or nothing
export const getUserMembershipStatusNeverMember = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: false,
        userIsActiveVoloFitnessMember: false,
        userHasPastVoloFitnessMembership: false,
      },
    },
  },
];

export const getUserMembershipStatusCurrentAllMemberships = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: true,
        userHasPastVoloPassMembership: true,
        userIsActiveVoloFitnessMember: true,
        userHasPastVoloFitnessMembership: true,
      },
    },
  },
];

export const getUserMembershipStatusPastAllMemberships = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: true,
        userIsActiveVoloFitnessMember: false,
        userHasPastVoloFitnessMembership: true,
      },
    },
  },
];
// #endregion

// #region Volo Pass
export const getUserMembershipStatusCurrentVoloPassMember = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: true,
        userHasPastVoloPassMembership: true,
        userIsActiveVoloFitnessMember: false,
        userHasPastVoloFitnessMembership: false,
      },
    },
  },
];

export const getUserMembershipStatusPastVoloPassMember = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: true,
        userIsActiveVoloFitnessMember: false,
        userHasPastVoloFitnessMembership: false,
      },
    },
  },
];

export const getUserMembershipStatusCurrentVoloPassPastVoloFitness = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: true,
        userHasPastVoloPassMembership: true,
        userIsActiveVoloFitnessMember: false,
        userHasPastVoloFitnessMembership: true,
      },
    },
  },
];
// #endregion

// #region Volo Fitness
export const getUserMembershipStatusCurrentVoloFitnessMember = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: false,
        userIsActiveVoloFitnessMember: true,
        userHasPastVoloFitnessMembership: true,
      },
    },
  },
];

export const getUserMembershipStatusPastVoloFitnessMember = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: false,
        userIsActiveVoloFitnessMember: false,
        userHasPastVoloFitnessMembership: true,
      },
    },
  },
];

export const getUserMembershipStatusCurrentVoloFitnessPastVoloPassMember = [
  {
    request: {
      query: GET_USER_MEMBERSHIP_STATUS,
    },
    result: {
      data: {
        userIsActiveVoloPassMember: false,
        userHasPastVoloPassMembership: true,
        userIsActiveVoloFitnessMember: true,
        userHasPastVoloFitnessMembership: true,
      },
    },
  },
];
// #endregion
