export const LONG_DATE_FORMAT = Intl.DateTimeFormat('default', {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
});

type FormattableDate = string | Date;

export const formattedDateToISOString = ({
  date = '',
  time = '00:00:00:000',
}: {
  date: FormattableDate;
  time?: string;
}) => {
  if (!date) return new Date().toDateString();
  if (typeof date !== 'string') return date.toISOString();
  let isoDate = new Date();
  if (date.match(/^\d\d\/(0?[1-9]|[1][0-2])\/(0?[1-9]|[12][0-9]|3[01])$/)) {
    // 'YY/MM/DD' format
    const [year, month, day] = date.split('/');
    const fullYear = 2000 + parseInt(year!, 10); // update in year 3000
    const lastMonth = parseInt(month!, 10) - 1;
    const [hour, min, sec, ms] = time.split(':');
    isoDate.setFullYear(fullYear, lastMonth, parseInt(day!, 10));
    isoDate.setHours(
      parseInt(hour!, 10),
      parseInt(min!, 10),
      parseInt(sec!, 10),
      parseInt(ms!, 10)
    );
  } else {
    const inputDate = !Number.isNaN(new Date(date)) ? new Date(date) : new Date();
    const [hour, min, sec, ms] = time.split(':');
    inputDate.setHours(
      parseInt(hour!, 10),
      parseInt(min!, 10),
      parseInt(sec!, 10),
      parseInt(ms!, 10)
    );
    isoDate = inputDate;
  }
  return isoDate.toISOString();
};

export const getRegistrationDateRange = ({
  start,
  end,
}: {
  start: FormattableDate;
  end: FormattableDate;
}) => {
  const startDate = formattedDateToISOString({ date: start });
  const endDate = formattedDateToISOString({ date: end });

  const formattedStartDate = Intl.DateTimeFormat('default', {
    month: 'short',
    day: '2-digit',
  }).format(new Date(startDate));
  const formattedEndDate = Intl.DateTimeFormat('default', {
    month: 'short',
    day: '2-digit',
  }).format(new Date(endDate));

  return formattedStartDate !== formattedEndDate
    ? `${formattedStartDate} - ${formattedEndDate}`
    : `${formattedStartDate} (All Day)`;
};
