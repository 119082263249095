import { Linking, Platform } from 'react-native';

/**
 * Open native settings app
 *
 * - `iOS`: app-settings://notification/Volo
 * - `Android`: Linking.openSettings()
 * - `default`: noop
 * @todo update this for new Player-App, was taken from mobile4
 */
const openNativeSettingsApp = () =>
  Platform.select({
    ios: async () => Linking.openURL('app-settings://notification/Volo'),
    android: async () => Linking.openSettings(),
    default: async () => undefined,
  })();

export default openNativeSettingsApp;
