import { gql, type TypedDocumentNode } from '@apollo/client';
import { TeamRsvpEnum, GenderEnum_TS } from '../../../constants';

type ReservationRsvpStatusData = {
  reservationRsvpStatus: {
    reservationRsvps: {
      _id: string;
      displayNameTeams: string;
      response: TeamRsvpEnum;
      reservation: {
        _id: string;
        reserving_user: {
          _id: string;
        };
      };
      user: {
        _id: string;
        gender: GenderEnum_TS;
        picture: string;
      };
    }[];
  };
};

type ReservationRsvpStatusInput = {
  input: {
    reservationId: string;
  };
};

const RESERVATION_RSVP_STATUS: TypedDocumentNode<
  ReservationRsvpStatusData,
  ReservationRsvpStatusInput
> = gql`
  query ReservationRsvpStatus($input: ReservationRsvpStatusInput!) {
    reservationRsvpStatus(input: $input) {
      reservationRsvps {
        _id
        displayNameTeams
        response
        reservation {
          _id
          reserving_user {
            _id
          }
        }
        user {
          _id
          gender
          picture
        }
      }
    }
  }
`;

export default RESERVATION_RSVP_STATUS;
