import type { IBoxProps } from '@rivallapp/native-base';

import { Box } from '../../base-components';
import { Colors } from '../../theme';
import { VoloPassLogo } from '../../custom-components';
import VoloPassPerksText from './components';

const VoloPassSignupAd = ({ ...boxProps }: IBoxProps) => {
  return (
    <Box paddingY={6} paddingX={3} backgroundColor={Colors.primary} borderRadius="md" {...boxProps}>
      <VoloPassLogo alignSelf="center" colorScheme="white" size={110} style={{ height: 61 }} />
      <VoloPassPerksText containerStyle={{ mt: 5 }} />
    </Box>
  );
};

export default VoloPassSignupAd;
