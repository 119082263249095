import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgReferee = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Path
      stroke="#103b5d"
      d="M40.752 24.185c0 5.541-3.723 9.87-8.134 9.87-4.41 0-8.134-4.329-8.134-9.87s3.724-9.87 8.134-9.87 8.134 4.33 8.134 9.87ZM10.1 55.796c.265-9.31 7.897-16.777 17.272-16.777h11.452c9.375 0 17.006 7.466 17.271 16.777z"
    />
    <Path
      stroke="#103b5d"
      strokeWidth={2}
      d="M32.659 39.506v15.803M37.456 39.506v15.803M42.252 39.506v15.803M27.862 39.506v15.803M23.065 39.506v15.803M18.269 41.481v13.828"
    />
    <Path
      stroke="#103b5d"
      d="M13.931 45.432v9.877M47.509 41.481v13.828M52.305 45.432v9.877M12.972 46.42v8.889M46.549 40.494v14.815M51.346 44.444v10.865"
    />
    <Path
      fill="#103b5d"
      d="m44.162 43.592 8.078-4.712 1.076 1.663 3.77-2.218-1.077-1.663 13.463-8.038 2.692 4.989-14.27 13.028 1.346 3.881v3.327l-1.346 3.603-2.423 3.05-3.231 1.385-3.5.555-3.77-1.11-2.693-1.94-2.154-3.326-.538-3.05.538-3.88 1.346-3.05z"
    />
    <Path
      fill="#fff"
      d="M46.076 46.23c-3.225 1.916-4.33 6.177-2.469 9.497s6.002 4.462 9.224 2.548c3.224-1.917 4.33-6.178 2.468-9.498-1.864-3.324-6.002-4.462-9.223-2.547m6.22 11.09c-2.72 1.616-6.205.651-7.772-2.143-1.57-2.8-.636-6.385 2.082-8.001s6.204-.651 7.772 2.144c1.57 2.798.632 6.387-2.083 8"
    />
    <Path
      fill="#103b5d"
      d="M69.874 28.401a.524.524 0 0 0-.725-.2l-13.43 7.982a.556.556 0 0 0-.194.746l.787 1.404-2.722 1.618-.787-1.403a.523.523 0 0 0-.725-.2l-7.817 4.646c-4.954 2.944-6.652 9.492-3.79 14.596a10.34 10.34 0 0 0 6.314 4.952c2.695.74 5.476.375 7.84-1.03l.016-.01c2.393-1.427 4.148-3.871 4.816-6.7.65-2.766.255-5.6-1.113-8.032l14.226-12.7a.56.56 0 0 0 .113-.688zM57.306 46.243a.56.56 0 0 0-.102.707c1.405 2.266 1.836 4.96 1.218 7.59-.61 2.58-2.143 4.716-4.318 6.014l-.015.01c-2.12 1.26-4.615 1.587-7.035.92a9.27 9.27 0 0 1-5.667-4.437c-2.57-4.584-1.04-10.46 3.403-13.1l7.354-4.371.787 1.403c.146.26.471.35.725.2l3.645-2.167a.556.556 0 0 0 .194-.746l-.787-1.404 12.514-7.438 2.317 4.114zM49.153 29.827 52.2 35.26c.146.26.472.35.725.2a.556.556 0 0 0 .194-.747l-3.047-5.433a.523.523 0 0 0-.725-.2.55.55 0 0 0-.194.747M45.188 33.36a.55.55 0 0 0 .195.745l5.264 3.136a.52.52 0 0 0 .724-.2.55.55 0 0 0-.195-.745l-5.264-3.137a.524.524 0 0 0-.724.2M54.502 28.059l-.009 6.27c-.002.302.24.548.53.547a.5.5 0 0 0 .266-.073.55.55 0 0 0 .264-.472l.01-6.272a.537.537 0 0 0-.53-.546.535.535 0 0 0-.53.545"
    />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
  </Svg>
);
export default SvgReferee;
