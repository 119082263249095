import { Platform } from 'react-native';
import { Colors, useTheme } from '../../../theme';
import { Table } from '../../../custom-components';
import { Text } from '../../../base-components';

const isWeb = Platform.OS === 'web';

// HACK - intentional until we have web theming
const webFonts = {
  heading: 12,
  label: 10.5,
  sublabel: 10,
  voloPass: 14,
};

type LeaguePricingCellProps = {
  content: string;
  noBorder?: boolean;
  grayText?: boolean;
};

const LeaguePricingCell = ({ content, grayText, noBorder }: LeaguePricingCellProps) => {
  const { dark } = useTheme();

  const textColor = dark ? Colors.black : Colors.white;

  return (
    <Table.Cell
      borderRightWidth={noBorder ? 0 : 1}
      borderTopWidth={0}
      borderBottomWidth={0}
      borderLeftWidth={0}
    >
      <Text
        bold
        marginLeft={2}
        fontSize={isWeb ? webFonts.label : null}
        color={grayText ? Colors.greys.medium : textColor}
      >
        {content}
      </Text>
    </Table.Cell>
  );
};

export default LeaguePricingCell;
