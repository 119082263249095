import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

const useFadeIn = () => {
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 1,
      duration: 350,
      useNativeDriver: true,
    }).start();
    // we don't need the ref here since the value won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return opacity;
};

export default useFadeIn;
