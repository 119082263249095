import { gql, useQuery, type QueryHookOptions } from '@apollo/client';

export type TeamStandings = {
  WIN: number;
  LOSE: number;
  TIE: number;
  FORFEIT: number;
  team: {
    _id: string;
    name: string;
    color: {
      hex: string;
      name: string;
    };
  };
  rank: number;
  points_for: number;
  points_against: number;
  points_differential: number;
};

type LeagueStandingsData = {
  leagueStandings: TeamStandings[];
};

type LeagueStandingsVars = {
  input: {
    leagueId: string;
    divisionId?: string;
  };
};

const GET_LEAGUE_STANDINGS = gql`
  query LeagueStandings($input: LeagueStandingsInput!) {
    leagueStandings(input: $input) {
      rank
      points_for
      points_against
      points_differential
      WIN
      LOSE
      FORFEIT
      TIE
      team {
        _id
        name
        color {
          hex
          name
        }
      }
    }
  }
`;

export const useLeagueStandingsQuery = (
  variables: LeagueStandingsVars['input'],
  options: QueryHookOptions<LeagueStandingsData, LeagueStandingsVars> = {}
) => {
  const { leagueId, divisionId } = variables;

  return useQuery<LeagueStandingsData, LeagueStandingsVars>(GET_LEAGUE_STANDINGS, {
    skip: !leagueId,
    variables: {
      input: {
        leagueId: leagueId as string,
        ...(divisionId ? { divisionId } : {}),
      },
    },
    ...options,
  });
};
