import type { IHeadingProps, ITextProps, ITheme } from '@rivallapp/native-base';
import { voloGreys } from '.';

export type THeading = 'screen' | 'section' | 'subsection';

type THeadingType = {
  [key in THeading]: IHeadingProps;
};

/* basic heading styling for titles */
// Define HeadingTypography and TextTypography as before
export const HeadingTypography: THeadingType = {
  screen: {
    size: '2xl',
  },
  section: {
    size: 'xl',
  },
  subsection: {
    size: 'md',
  },
};

export type TText = 'h1' | 'h2' | 'h3' | 'p' | 'caption';

type TTextType = {
  [key in TText]: ITextProps;
};

/* basic font styling for Text component */
export const TextTypography: TTextType = {
  h1: {
    fontSize: 30,
    bold: true,
  },
  h2: {
    fontSize: 24,
    bold: true,
  },
  h3: {
    fontSize: 20,
  },
  p: {
    fontSize: 16,
  },
  caption: {
    fontSize: 12,
    color: voloGreys.medium,
  },
};

/**
 * Font Config
 * - Map font weights to font files through native-base theme
 * - Font files are located in docs/fonts
 */
export const VoloSansProFontConfig: ITheme['fontConfig'] = {
  VoloSansPro: {
    100: {
      normal: 'VoloSansPro-Regular',
      italic: 'VoloSansPro-RegularItalic',
    },
    200: {
      normal: 'VoloSansPro-Regular',
      italic: 'VoloSansPro-RegularItalic',
    },
    300: {
      normal: 'VoloSansPro-Regular',
      italic: 'VoloSansPro-RegularItalic',
    },
    400: {
      normal: 'VoloSansPro-Regular',
      italic: 'VoloSansPro-RegularItalic',
    },
    500: {
      normal: 'VoloSansPro-Regular',
      italic: 'VoloSansPro-RegularItalic',
    },
    600: {
      normal: 'VoloSansPro-Bold',
      italic: 'VoloSansPro-BoldItalic',
    },
    700: {
      normal: 'VoloSansPro-Bold',
      italic: 'VoloSansPro-BoldItalic',
    },
    800: {
      normal: 'VoloSansPro-Ultra',
      italic: 'VoloSansPro-UltraItalic',
    },
    900: {
      normal: 'VoloSansPro-Ultra',
      italic: 'VoloSansPro-UltraItalic',
    },
  },
};

export const VoloFonts = {
  heading: 'VoloSansPro',
  body: 'VoloSansPro',
  mono: 'VoloSansPro',
};
