import { useQuery } from '@apollo/client';
import { ProgramTypeEnum, VoloFitnessProgramsList } from '../../../../constants';
import { getDayOfWeekFromDate } from '../../../../utilities';

import GET_PROGRAM_DETAILS, {
  type ProgramDetailsResponse,
  type ProgramDetailsInput,
} from '../graphql/GET_PROGRAM_DETAILS';

import GET_GAME_DETAILS, {
  type GameDetailsInput,
  type GameDetailsResponse,
} from '../graphql/GET_GAME_DETAILS';

const useProgramDetails = ({
  programId = '',
  gameId = '',
}: {
  programId?: string;
  gameId?: string;
}) => {
  const {
    data: gameData,
    loading: gameLoading,
    error: gameError,
  } = useQuery<GameDetailsResponse, GameDetailsInput>(GET_GAME_DETAILS, {
    skip: !gameId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: gameId,
    },
  });

  const { game } = gameData || {};
  const { start_time = '', league: gameLeague } = game || {};
  const {
    _id: gameLeagueId = '',
    sport,
    programType: gameLeagueProgramType = ProgramTypeEnum.LEAGUE,
    registration: gameLeagueRegistration,
  } = gameLeague || {};
  const voloFitnessDropinPrice = gameLeagueRegistration?.voloFitnessDropinPrice;
  const sportName = sport?.name || '';

  const isVoloFitnessDropin =
    typeof voloFitnessDropinPrice === 'number' &&
    VoloFitnessProgramsList.includes(gameLeagueProgramType);

  // Format start_time the same as start_time_estimate ('HH:mm')
  const gameStartTime = new Date(start_time).toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  const {
    data: programData,
    loading: programLoading,
    error: programError,
  } = useQuery<ProgramDetailsResponse, ProgramDetailsInput>(GET_PROGRAM_DETAILS, {
    skip: !programId && !gameLeagueId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: programId || gameLeagueId,
    },
  });

  const { league } = programData || {};

  const {
    displayName,
    programType,
    start_date,
    photoUrl,
    banner_text,
    neighborhood,
    venue,
    num_weeks_estimate = 0,
    num_playoff_weeks_estimate = 0,
    start_time_estimate = '',
    end_time_estimate = '',
    registrantCount = 0,
    registration,
    is_premier: isPremier = false,
    is_volo_pass_exclusive: isVoloPassExclusive = false,
  } = league || {};

  // drop-in labeling is inconsistent
  const displayProgramType = gameId ? ProgramTypeEnum.DROPIN : programType;

  const { dropInCapacity = { f: 0, a: 0, m: 0 } } = game || {};
  const getDropInTotal = () =>
    Object.values(dropInCapacity)
      .filter(v => Number.isInteger(v))
      .reduce((acc, val) => acc + val, 0);

  const availableDropInSpots = getDropInTotal();

  // Drop-ins and volunteer events are formatted differently
  const isDailyProgram =
    programType === ProgramTypeEnum.CLASS ||
    programType === ProgramTypeEnum.CLINIC ||
    programType === ProgramTypeEnum.EVENT ||
    programType === ProgramTypeEnum.PICKUP ||
    programType === ProgramTypeEnum.PRACTICE;

  const neighborhoodName = game?.location?.neighborhood || neighborhood?.name;

  const venueName = game?.location?.name || venue?.name || '';
  const venueAddress = game?.location?.formatted_address || venue?.formatted_address || '';

  const { minSize: registrationMin = 0, maxSize: registrationMax = 0 } = registration || {};

  const numWeeks = num_weeks_estimate - num_playoff_weeks_estimate;

  // Drop-in naming convention [7/18/2024]: '<DAY> - <SPORT> - <NEIGHBORHOOD> - Drop-In'
  const gameDayOfWeek = start_time ? getDayOfWeekFromDate(start_time) : '';
  const gameDisplayName = `${gameDayOfWeek} - ${sportName} - ${neighborhoodName} - Drop-In`;

  return {
    programError,
    programLoading,
    gameLoading,
    gameError,
    displayName: gameId ? gameDisplayName : displayName,
    programType: displayProgramType,
    start_date,
    photoUrl,
    banner_text,
    startTime: gameId ? gameStartTime : start_time_estimate,
    end_time_estimate,
    neighborhoodName,
    venueName,
    venueAddress,
    registrantCount,
    registrationMin,
    registrationMax,
    numWeeks,
    isDailyProgram,
    availableDropInSpots,
    isVoloFitnessDropin,
    isPremier,
    isVoloPassExclusive,
  };
};

export default useProgramDetails;
