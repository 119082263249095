import { type FC, useState } from 'react';
import { FormControl } from '@rivallapp/native-base';

import { Text, Box } from '../../../base-components';
import { StarRatingComponent } from '../../index';
import { type CollapseFormInputProps } from './types';
import { useCollapseFormContext } from '../CollapseFormContext';

export interface StarRatingProps extends CollapseFormInputProps {
  defaultValue: number;
  onChange?: (arg: number) => void;
}

const StarRating: FC<StarRatingProps> = ({
  isRequired,
  label,
  onChange = () => {},
  defaultValue,
}) => {
  const { view } = useCollapseFormContext();
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const handleChange = (newVal: number) => {
    setCurrentValue(newVal);
    onChange(newVal);
  };

  switch (view) {
    case 'write':
      return (
        <FormControl isRequired={isRequired}>
          <FormControl.Label>{label}</FormControl.Label>
          <StarRatingComponent rating={currentValue} onChange={handleChange} variant="gray" />
        </FormControl>
      );
    case 'read':
      return (
        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
          <Text fontWeight="bold">{label} </Text>
          {/* @ts-ignore - this doesn't need an onChange for readOnly */}
          <StarRatingComponent rating={currentValue} readOnly variant="yellow" />
        </Box>
      );
    default:
      return null;
  }
};

export default StarRating;
