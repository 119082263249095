import { useState, type FC, useEffect } from 'react';
import { type IButtonProps } from '@rivallapp/native-base';
import { Box, Button, Tooltip } from '../../base-components';
import { useClipboard } from '../../hooks';

interface CopyLinkProps {
  deepLink: string;
  labelText: string;
  linkText: string;
  _button?: IButtonProps;
}

const CopyLink: FC<CopyLinkProps> = ({ deepLink, labelText, linkText, _button }) => {
  const [hasCopiedLink, setHasCopiedLink] = useState(false);
  const { onCopy } = useClipboard();

  useEffect(() => {
    if (hasCopiedLink) {
      const timer = setTimeout(() => {
        setHasCopiedLink(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
    return () => {};
  }, [hasCopiedLink]);

  const handleCopy = () => {
    onCopy(deepLink);
    setHasCopiedLink(true);
  };

  return (
    // There's an issue with the `Tooltip` component where specifically link variant buttons don't work
    // wrapping the button with `Box` seems to solve this problem 🤷🏻‍♂️
    <Tooltip label={labelText} placement="top">
      <Box>
        <Button
          variant="link"
          px="0"
          height="8"
          _text={{ fontSize: 'sm', color: 'primary.400' }}
          onPress={handleCopy}
          {..._button}
        >
          {hasCopiedLink ? 'Link copied!' : linkText}
        </Button>
      </Box>
    </Tooltip>
  );
};

export default CopyLink;
