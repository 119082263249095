import { type TypedDocumentNode, gql } from '@apollo/client';

type GetRentalGistFromVenueData = {
  venue: {
    _id: string;
    shorthandName: string;
    rentals: {
      _id: string;
      gist: string;
    }[];
  };
};

type GetRentalGistFromVenueInput = {
  venueId: string;
};

const GET_RENTAL_GIST_FROM_VENUE: TypedDocumentNode<
  GetRentalGistFromVenueData,
  GetRentalGistFromVenueInput
> = gql`
  query venue($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      shorthandName
      rentals {
        _id
        gist
      }
    }
  }
`;

export default GET_RENTAL_GIST_FROM_VENUE;
