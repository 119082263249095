import {
  RentalSectionLabel,
  RentalSectionTitle,
  useRentalBookingStore,
} from '../../../RentalsRegistration';
import { Text, Stack, Row, Checkbox } from '../../../../base-components';

const setRentalPolicyAccepted = (isSelected: boolean) => {
  useRentalBookingStore.setState({
    rentalPolicyAgreed: isSelected,
  });
};
const setLiabilityTermsAccepted = (isSelected: boolean) => {
  useRentalBookingStore.setState({
    liabilityTermsAgreed: isSelected,
  });
};

interface WaiverCheckBoxComponent {
  key: string;
  label: string;
  value: string;
  isChecked: boolean;
  onChange: (isSelected: boolean) => void;
  accessibilityLabel: string;
  text: string;
  linkText: string;
  onLinkTextPressed: () => void;
}

export interface RentalWaiversProps {
  onLiabilityLinkPressed: () => void;
  onRentalPolicyLinkPressed: () => void;
}

const RentalWaivers = ({
  onLiabilityLinkPressed,
  onRentalPolicyLinkPressed,
}: RentalWaiversProps) => {
  const { liabilityTermsAgreed, rentalPolicyAgreed } = useRentalBookingStore(store => ({
    liabilityTermsAgreed: store.liabilityTermsAgreed,
    rentalPolicyAgreed: store.rentalPolicyAgreed,
  }));

  const waiverCheckboxes: WaiverCheckBoxComponent[] = [
    {
      key: 'liability',
      label: 'Liability',
      value: 'liabilityTermsAgreed',
      isChecked: liabilityTermsAgreed,
      onChange: isSelected => {
        setLiabilityTermsAccepted(isSelected);
      },
      accessibilityLabel: 'Liability terms accepted',
      text: "By joining, you agree to this program's liability waiver. ",
      linkText: 'Read More.',
      onLinkTextPressed: onLiabilityLinkPressed,
    },
    {
      key: 'rentalPolicy',
      label: 'Policies',
      value: 'rentalPolicyAgreed',
      isChecked: rentalPolicyAgreed,
      onChange: isSelected => {
        setRentalPolicyAccepted(isSelected);
      },
      accessibilityLabel: 'Rental policy accepted',
      text: "By joining, you agree to Volo's ",
      linkText: 'Private Rental Policies.',
      onLinkTextPressed: onRentalPolicyLinkPressed,
    },
  ];

  return (
    <>
      <RentalSectionTitle pb={2}>Waiver(s)</RentalSectionTitle>
      <Stack space={2} pl={2}>
        {waiverCheckboxes.map(waiver => (
          <Stack key={waiver.key}>
            <RentalSectionLabel isRequired>{waiver.label}</RentalSectionLabel>
            <Row alignItems="center" space={2} key={waiver.label}>
              <Checkbox
                value={waiver.value}
                isChecked={waiver.isChecked}
                onChange={waiver.onChange}
                accessibilityLabel={waiver.accessibilityLabel}
                /* need this as well or warning is thrown about Checkbox needing it - even tho its not a valid prop through their types */
                aria-label={waiver.accessibilityLabel}
              />
              <Text flex={1} fontSize={14} lineHeight={18}>
                {waiver.text}
                <Text isLink fontSize={14} onPress={() => waiver.onLinkTextPressed()}>
                  {waiver.linkText}
                </Text>
              </Text>
            </Row>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default RentalWaivers;
