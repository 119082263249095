import { useState, useCallback } from 'react';
import { type IBoxProps } from '@rivallapp/native-base';

import {
  MembershipEnum,
  type MembershipIntervalType,
  type MembershipStatusType,
} from '../../constants';
import { MemberHeader, MemberSection, NonMemberSection, MemberPerksModal } from './components';
import { View, Box, RadialGradient } from '../../base-components';
import { VoloFitnessLogo, VoloPassLogo } from '../../custom-components';
import {
  voloFitnessColorList,
  voloPassColorList,
} from '../../base-components/RadialGradient/colorLists';
import { isMobile } from '../../utilities';

export interface MembershipCardProps extends IBoxProps {
  variant: MembershipEnum;
  /** Monthly cost of VF in dollars. Not used in VP card */
  membershipPrice?: number;
  membershipDetails?: {
    membershipStatus: MembershipStatusType;
    membershipType: MembershipIntervalType;
    membershipStartDate: Date | string;
  };
  // Placeholders - may be updated as sections are built out
  billingHistory?: () => void;
  learnMore?: () => void;
  manageMembership?: () => void;
  /** Presently only Volo Pass has the option to upgrade from Monthly to Annual */
  upgradeAnnual?: () => void;
}

const MembershipCard = ({
  variant,
  membershipPrice,
  membershipDetails,
  billingHistory,
  learnMore,
  manageMembership,
  upgradeAnnual,
  ...props
}: MembershipCardProps) => {
  const [showPerks, setShowPerks] = useState(false);

  // for these purposes, isMember should only be false if the user has never had a membership
  const isMember = !!membershipDetails;
  const { membershipStatus = '', membershipType = '' } = membershipDetails || {};

  // allow for future membership types
  const variantProps = useCallback(() => {
    switch (variant) {
      case MembershipEnum.VOLO_FITNESS:
        return {
          id: MembershipEnum.VOLO_FITNESS,
          backgroundProps: voloFitnessColorList,
          logo: <VoloFitnessLogo colorScheme="white" size={110} style={{ height: 61 }} />,
        };
      case MembershipEnum.VOLO_PASS:
      default:
        return {
          id: MembershipEnum.VOLO_PASS,
          backgroundProps: voloPassColorList,
          logo: <VoloPassLogo colorScheme="white" size={110} style={{ height: 61 }} />,
        };
    }
  }, [variant]);

  const variantObject = variantProps();

  return (
    <>
      <Box
        overflow="hidden"
        minHeight={isMember ? 211 : 166}
        // Allow for card to take up full width on mobile
        width={isMobile ? undefined : 361}
        borderRadius={10}
        {...props}
      >
        <View position="absolute" width="100%" height="100%" overflow="hidden">
          <RadialGradient
            id={variantObject.id}
            x="50%"
            y="50%"
            rx="50%"
            ry="50%"
            colorList={variantObject.backgroundProps}
          />
        </View>
        <View
          marginTop={5}
          marginBottom={isMember ? 4 : 2}
          marginX={3}
          flexDirection="row"
          justifyContent={isMember ? 'flex-start' : 'center'}
        >
          {variantObject.logo}
          {isMember && <MemberHeader variant={variant} membershipDetails={membershipDetails} />}
        </View>
        {isMember ? (
          <MemberSection
            setShowPerks={setShowPerks}
            membershipStatus={membershipStatus}
            membershipType={membershipType}
            billingHistory={billingHistory}
            manageMembership={manageMembership}
            upgradeAnnual={upgradeAnnual}
          />
        ) : (
          <NonMemberSection
            variant={variant}
            membershipPrice={membershipPrice}
            learnMore={learnMore}
          />
        )}
      </Box>

      <MemberPerksModal
        variant={variant}
        onClose={() => setShowPerks(false)}
        isOpen={showPerks}
        membershipStatus={membershipStatus}
      />
    </>
  );
};

export default MembershipCard;
