import { WholeDollarFormatter } from '../../../../../utilities';
import { type GetRentalGeneralInfoData } from '../graphql/GET_RENTAL_GENERAL_INFO';
import { type GetVenueGeneralInfoData } from '../graphql/GET_VENUE_GENERAL_INFO';

/**
 * - if rentalData exists, return single standard and prime values
 * - if venueData exists, return a range similar to RentalPricing
 */
const getInfoGridPricing = (
  rentalData?: GetRentalGeneralInfoData,
  venueData?: GetVenueGeneralInfoData
) => {
  let standardPriceText = '';
  let primePriceText = '';

  if (rentalData) {
    const standardPrice = rentalData.rental.standard_price_cents;
    const primePrice = rentalData.rental.prime_price_cents;

    standardPriceText = `Standard: ${WholeDollarFormatter.format(standardPrice / 100)}/hr`;

    primePriceText =
      primePrice === undefined
        ? 'Prime Time: --'
        : `Prime Time: ${WholeDollarFormatter.format(primePrice / 100)}/hr`;
  } else {
    const {
      lowest_standard_rental_price,
      highest_standard_rental_price,
      lowest_prime_rental_price,
      highest_prime_rental_price,
    } = venueData!.venue; // venueData exists if rentalData does not

    // Top range prices are displayed without the $ sign
    const lowestStandardPrice = WholeDollarFormatter.format(lowest_standard_rental_price / 100);
    const highestStandardPrice = Math.round(highest_standard_rental_price / 100);
    const lowestPrimePrice = lowest_prime_rental_price
      ? WholeDollarFormatter.format(lowest_prime_rental_price / 100)
      : undefined;
    const highestPrimePrice = highest_prime_rental_price
      ? Math.round(highest_prime_rental_price / 100)
      : undefined;

    const showHighestStandardPrice = lowest_standard_rental_price !== highest_standard_rental_price;
    const showHighestPrimePrice = lowest_prime_rental_price !== highest_prime_rental_price;

    standardPriceText = `Standard: ${lowestStandardPrice}${
      showHighestStandardPrice && `-${highestStandardPrice}/hr`
    }`;
    primePriceText = lowestPrimePrice
      ? `Prime Time: ${lowestPrimePrice}${showHighestPrimePrice && `-${highestPrimePrice}`}/hr`
      : 'Prime Time: --';
  }

  return { standardPriceText, primePriceText };
};

export default getInfoGridPricing;
