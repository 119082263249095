import { AvailableBetas } from '../../../apps/RFO/BetaLanding/BetaLanding';

export enum ActionEnum {
  CREATE_MENU = 'CREATE_MENU',
  DASHBOARD_DROPDOWN = 'DASHBOARD_DROPDOWN',
}

export const headerLinks = [
  {
    path: 'create/start',
    title: 'Create',
  },
  {
    path: 'leagues',
    title: 'Programs',
  },
  {
    path: 'players',
    title: 'Players',
  },
  {
    path: 'ratings',
    title: 'Ratings',
  },
  {
    path: 'messages/compose',
    title: 'Messages',
  },
  {
    path: 'dashboard',
    title: 'Dashboard',
    action: ActionEnum.DASHBOARD_DROPDOWN,
  },
];

export const dropdownLinks = (isAdmin?: boolean) => [
  {
    pathname: '/rfo/promo-codes',
    title: 'Promos',
  },
  {
    pathname: '/rfo/staff',
    title: 'Staff',
  },
  {
    pathname: '/rfo/settings/general',
    title: 'Settings',
  },
  {
    pathname: '/rfo/neighborhoods',
    title: 'Neighborhoods',
  },
  {
    pathname: '/rfo/approved-venues',
    title: 'Venues',
  },
  {
    pathname: '/rfo/city-page-details',
    title: 'City Homepage',
  },
  {
    pathname: '/rfo/deep-links',
    title: 'Deep Links',
  },
  {
    pathname: '/rfo/incident-reports',
    title: 'Incident Reports',
  },
  {
    pathname: '/rfo/shift-logs',
    title: 'Shift Logs',
  },
  ...(isAdmin
    ? [
        {
          pathname: '/rfo/national-page-details',
          title: 'National Homepage',
        },
        {
          pathname: '/rfo/volo-feedback',
          title: 'Volo Feedback',
        },
        {
          pathname: '/rfo/expenses',
          title: 'Global Expenses',
        },
        {
          pathname: '/rfo/membership-settings/volo-pass',
          title: 'National Membership Settings',
        },
      ]
    : []),
  {
    pathname: '/rfo/settings/stripe',
    title: 'Stripe',
  },
  ...(AvailableBetas.length > 0
    ? [
        {
          pathname: '/rfo/beta',
          title: 'Beta Features',
        },
      ]
    : []),
];
