import { useSuspenseQuery } from '@apollo/client';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faCourtSport } from '@fortawesome/pro-regular-svg-icons/faCourtSport';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot';
import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';
import type { IBoxProps } from '@rivallapp/native-base';
import moment from 'moment-timezone';
import type { ComponentProps, FC } from 'react';
import { Box, Column, Divider, FontAwesomeIcon, Link, Row, Text } from '../../../base-components';
// The `feature-components` alias isn't playing nice (theory is that it's because it would introduce circular deps)
import { CopyLink, PrintWaiverButton } from '../..';
import { RENTAL_DETAILS } from '../gql';
import { formatRentalCourtsLabel } from '../../RentalDashboardCard';

const StyledRow: FC<ComponentProps<typeof Row>> = props => <Row marginBottom="2" {...props} />;
const StyledText: FC<ComponentProps<typeof Text>> = props => (
  <Text fontSize="sm" marginLeft="2" {...props} />
);

interface ProgramBasicsProps {
  rental_id: string;
  program_link: string;
  _container?: IBoxProps;
}

const ProgramBasics: FC<ProgramBasicsProps> = ({ rental_id, program_link, _container }) => {
  const { data } = useSuspenseQuery(RENTAL_DETAILS, {
    variables: {
      id: rental_id,
    },
  });

  const { rental } = data;
  const {
    activity_name,
    timezone,
    venue,
    courts,
    surface_type,
    numeric_courts,
    first_timeslot_by_date,
    last_timeslot_by_date,
    first_timeslot_by_time,
    last_timeslot_by_time,
    waiver: { _id: waiverId },
    deep_link,
    player_app_link,
  } = rental;
  const { maps_url_query, formatted_address, name, shorthandName } = venue;

  return (
    <Box bg="white.600" borderRadius="3" p="4" {..._container}>
      <Row justifyContent="space-between">
        <Column>
          <StyledRow>
            <FontAwesomeIcon icon={faTrophy} />
            <StyledText>{activity_name}</StyledText>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon icon={faLocationDot} />
            <Link
              isExternal
              href={
                `https://www.google.com/maps/dir//${
                  maps_url_query ??
                  (formatted_address && typeof formatted_address === 'string'
                    ? formatted_address.replace(/,/g, '').replace(/ /g, '+')
                    : name.replace(/ /g, '+'))
                }` || '#'
              }
              fontSize="sm"
              marginLeft="2"
              _text={{ color: 'primary.400' }}
            >
              {shorthandName}
            </Link>
          </StyledRow>
        </Column>
        <Column>
          <StyledRow>
            <FontAwesomeIcon icon={faCourtSport} />
            {/* @ts-ignore [MIGRATION] `courts` should be an object */}
            <StyledText>{formatRentalCourtsLabel(courts, numeric_courts, surface_type)}</StyledText>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon icon={faClock} />
            <StyledText>
              {moment(first_timeslot_by_time.start_time_str, 'HH:mm').format('h:mmA')} -{' '}
              {moment(last_timeslot_by_time.end_time_str, 'HH:mm').format('h:mmA')}
            </StyledText>
          </StyledRow>
        </Column>
        <Column>
          <StyledRow>
            <FontAwesomeIcon icon={faCalendarDay} />
            <StyledText>
              Starts {moment.tz(first_timeslot_by_date.start_time, timezone).format('M/DD')}
            </StyledText>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon icon={faCalendarDay} />
            <StyledText>
              Ends {moment.tz(last_timeslot_by_date.end_time, timezone).format('M/DD')}
            </StyledText>
          </StyledRow>
        </Column>
      </Row>
      <Divider my="2" />
      <Row w="full" justifyContent="space-between" alignItems="center">
        <Link
          isExternal
          href={program_link}
          _text={{ color: 'primary.400', textDecoration: 'none' }}
          _hover={{ color: 'primary.400', textDecoration: 'underline' }}
        >
          View full program page
        </Link>
        <PrintWaiverButton waiverId={waiverId}>Download program waiver</PrintWaiverButton>
        <CopyLink
          deepLink={player_app_link}
          labelText="For mobile deep links! NOT for use in emails or SMS communications. Click to copy"
          linkText="Mobile Deep Link"
        />
        <CopyLink deepLink={deep_link} labelText="Click to copy" linkText="Deep Link" />
      </Row>
    </Box>
  );
};

export default ProgramBasics;
