import { type FC } from 'react';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot';

import { Text, View, FontAwesomeIcon } from '../../../../base-components';
import type { VenueModule } from '../../ProgramCard/types';
import useMobileSizing from '../../helpers/useMobileSizing';

const VenueSection: FC<Omit<VenueModule, 'type'>> = ({ venue }) => {
  const { venueName, onPressVenue } = venue;

  const mobileSizing = useMobileSizing();

  return (
    <View
      flexDirection="row"
      flex={1}
      alignItems="center"
      maxWidth={mobileSizing ? 170 : 250}
      minHeight={mobileSizing ? 18 : 26}
    >
      {!mobileSizing ? <FontAwesomeIcon icon={faLocationDot} size={5} /> : null}
      <View>
        <Text
          marginLeft={2.5}
          numberOfLines={1}
          fontSize={14}
          letterSpacing={-0.5}
          maxWidth={mobileSizing ? 160 : 220}
          isLink
          onPress={e => {
            e?.stopPropagation();
            onPressVenue(e);
          }}
        >
          {venueName}
        </Text>
      </View>
    </View>
  );
};

export default VenueSection;
