/* eslint-disable react/react-in-jsx-scope */
import { memo } from 'react';

import { View } from '../../../base-components';
import type { GridItemProps, RenderGridItem } from '../types';
import GridItem from './GridItem';

interface IGridRow {
  items: GridItemProps[];
  RenderItem: RenderGridItem;
}

const GridRow = ({ items = [], RenderItem }: IGridRow) => (
  <View flexDirection="row" justifyContent="center" alignItems="center">
    {items.map(item =>
      RenderItem ? (
        <GridItem key={item.id} {...item}>
          <RenderItem {...item} />
        </GridItem>
      ) : null
    )}
  </View>
);

export default memo(GridRow);
