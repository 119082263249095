import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgOutdoorSpace = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path fill="#103b5d" stroke="#fff" d="M14.5 21.5h50v36h-50z" />
    <Circle cx={22.5} cy={39.5} r={4} stroke="#fff" />
    <Circle cx={56.5} cy={39.5} r={4} stroke="#fff" />
    <Path fill="#103b5d" stroke="#fff" d="M14.5 30.5h8v18h-8zM56.5 30.5h8v18h-8z" />
    <Path stroke="#fff" d="M60.5 35.5h4v8h-4zM14.5 35.5h4v8h-4zM39.5 22v35" />
    <Circle cx={39.5} cy={39.5} r={5} stroke="#fff" />
  </Svg>
);
export default SvgOutdoorSpace;
