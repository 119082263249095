import React, { useState } from 'react';

import CollapsibleBase from './CollapsibleBase';
import type { CollapsibleBaseProps } from './CollapsibleBase';

export type CollapsibleProps = Omit<CollapsibleBaseProps, 'onPress'>;

/** If you need to directly control collapsed state, use `CollapsibleBase`. */
const Collapsible: React.FC<CollapsibleProps> = ({
  isCollapsed: initialCollapsedState = true,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsedState);

  const handlePress = () => setCollapsed(prev => !prev);

  return <CollapsibleBase onPress={handlePress} isCollapsed={collapsed} {...props} />;
};

export default Collapsible;
