import { ApolloError } from '@apollo/client';
import { ActivityIndicator, Platform } from 'react-native';

import { Colors, VoloNativeBaseShades, useTheme } from '../../theme';
import { useActionStates } from '../../hooks';
import { getPricePhaseName, PricePhaseEnum, ErrorCodes, ErrorMessageEnum } from '../../constants';
import {
  formatCents,
  camelCase,
  getRegistrationDateRange,
  formattedDateToISOString,
  LONG_DATE_FORMAT,
} from '../../utilities';
import { View, Image, Text, Alert } from '../../base-components';
import { Table, VoloPassBadgeIcon } from '../../custom-components';
import { LeaguePricingHeader, LeaguePricingCell, MembershipPricingInfo } from './components';
import type { LeagueProps } from './types';

const earlyBirdImg = require('../../../assets/early-bird.png');
const registrationCloseImg = require('../../../assets/registration-close.png');

const isWeb = Platform.OS === 'web';

// HACK - intentional until we have web theming
const webFonts = {
  heading: 13,
  label: 10.5,
  subLabel: 10,
  voloPass: 12,
};

interface LeaguePricingGridProps {
  league: LeagueProps;
  showPricesWithFees?: boolean;
  loading?: boolean;
  error?: ApolloError | string;
}

const LeaguePricingGrid = ({
  league,
  showPricesWithFees = false,
  loading = false,
  error = undefined,
}: LeaguePricingGridProps) => {
  const {
    registration,
    organization: {
      voloPassTiers: { isActive },
    },
  } = league || {};

  const {
    phases,
    registration_close,
    currentPhase,
    memberPrice,
    voloPassPricingAllowed,
    allowPrepaidTeams,
  } = registration || {};

  let memberPriceWithFees: number | null = memberPrice;

  switch (currentPhase) {
    case PricePhaseEnum.EARLY_BIRD:
      memberPriceWithFees = phases.earlyBird.individualVoloPassMemberPriceWithFees;
      break;
    case PricePhaseEnum.LAST_CHANCE:
      memberPriceWithFees = phases.lastChance.individualVoloPassMemberPriceWithFees;
      break;
    case PricePhaseEnum.REGULAR:
    default:
      memberPriceWithFees = phases.regular.individualVoloPassMemberPriceWithFees;
      break;
  }

  const { setShowAlert } = useActionStates({
    withAlerts: true,
  });

  const { colors, dark } = useTheme();

  const earlyBirdCloseDate = formattedDateToISOString({
    date: phases?.earlyBird?.endDateStr,
    time: '23:59:59:999',
  });

  const phasePricing = Object.entries(phases)
    .map(([key, value]) => ({
      ...value,
      phase: key,
    }))
    .filter(phase => phase.allow === true);

  if (loading) return <ActivityIndicator />;
  if (error) {
    return (
      <Alert
        status="error"
        message={ErrorMessageEnum[ErrorCodes.UPDATE_FAILED]}
        showAlert
        setShowAlert={setShowAlert}
      />
    );
  }

  return (
    <View padding={2}>
      <View
        borderRadius={8}
        borderWidth={1}
        borderColor={Colors.greys.veryLight}
        backgroundColor={VoloNativeBaseShades.secondary[100]}
        paddingTop={2}
        paddingBottom={2}
      >
        {phases?.earlyBird?.allow === true ? (
          <View
            borderLeftWidth={currentPhase === PricePhaseEnum.EARLY_BIRD ? 2 : 0}
            borderLeftColor={
              currentPhase === PricePhaseEnum.EARLY_BIRD ? colors.accents.select.bg : null
            }
          >
            <View
              flexDirection="row"
              marginLeft={3}
              marginTop={1}
              marginBottom={1}
              alignItems="center"
            >
              <View height={6} width={6} marginRight={2} justifyContent="center">
                <Image flex={1} resizeMode="contain" source={earlyBirdImg} alt="early bird" />
              </View>
              <Text
                bold
                fontSize={isWeb ? webFonts.heading : null}
                color={
                  currentPhase !== PricePhaseEnum.EARLY_BIRD ? Colors.greys.medium : Colors.black
                }
              >
                Early Bird closes {LONG_DATE_FORMAT.format(new Date(earlyBirdCloseDate))}
              </Text>
            </View>
          </View>
        ) : null}
        <View
          borderLeftWidth={currentPhase !== PricePhaseEnum.EARLY_BIRD ? 2 : 0}
          borderLeftColor={
            currentPhase !== PricePhaseEnum.EARLY_BIRD ? colors.accents.select.bg : null
          }
        >
          <View
            flexDirection="row"
            marginLeft={3}
            marginTop={1}
            marginBottom={1}
            alignItems="center"
          >
            <View height={6} width={6} marginRight={2} justifyContent="center">
              <Image
                flex={1}
                resizeMode="contain"
                source={registrationCloseImg}
                alt="registration close"
              />
            </View>
            <Text
              bold
              fontSize={isWeb ? webFonts.heading : null}
              color={
                currentPhase === PricePhaseEnum.EARLY_BIRD ? Colors.greys.medium : Colors.black
              }
            >
              Registration closes {LONG_DATE_FORMAT.format(new Date(registration_close))}
            </Text>
          </View>
        </View>
      </View>
      <Table marginTop={10}>
        <Table.Row>
          <LeaguePricingHeader isEmpty />
          <LeaguePricingHeader
            isColumnLabel
            label="Individual"
            sublabel="+ fees"
            position="topLeft"
          />
          <LeaguePricingHeader
            isColumnLabel
            label="Pre-paid team"
            sublabel="incl. fees"
            position="topRight"
          />
        </Table.Row>
        {phasePricing?.map(
          (
            {
              endDateStr,
              phase,
              startDateStr,
              individualPrice,
              individualPriceWithFees: playerPriceWithFees,
              prepaidTeamPriceWithFees,
            },
            index
          ) => {
            const playerPrice = showPricesWithFees ? playerPriceWithFees : individualPrice;

            const key = `${phase}-row`;
            const teamPrice = allowPrepaidTeams ? prepaidTeamPriceWithFees : 0;
            const isCurrentPhase = phase === camelCase(currentPhase);
            return (
              <Table.Row
                key={key}
                borderRightWidth={1}
                borderLeftWidth={1}
                borderRightColor={colors.accents.select.bg}
                borderLeftColor={colors.accents.select.bg}
                borderTopLeftRadius={index === 0 ? 5 : 0}
                borderTopWidth={index === 0 ? 1 : 0}
                borderTopColor={index === 0 || isCurrentPhase ? colors.accents.select.bg : null}
                borderColor={
                  index === phasePricing.length - 1 && memberPrice === null
                    ? colors.accents.select.bg
                    : null
                }
                borderWidth={index === phasePricing.length - 1 && memberPrice === null ? 1 : 0}
                borderBottomLeftRadius={
                  index === phasePricing.length - 1 && memberPrice === null ? 20 : 0
                }
                borderBottomRightRadius={
                  index === phasePricing.length - 1 && memberPrice === null ? 20 : 0
                }
                backgroundColor={isCurrentPhase ? colors.accents.select.bg : null}
              >
                <LeaguePricingHeader
                  noBorder
                  grayText={!isCurrentPhase}
                  label={(getPricePhaseName(phase) || '').toUpperCase()}
                  sublabel={getRegistrationDateRange({
                    start: startDateStr,
                    end: endDateStr,
                  })}
                />
                <LeaguePricingCell content={formatCents(playerPrice)} grayText={!isCurrentPhase} />
                <LeaguePricingCell
                  content={teamPrice ? formatCents(teamPrice) : 'N/A'}
                  grayText={!isCurrentPhase}
                  noBorder
                />
              </Table.Row>
            );
          }
        )}
      </Table>
      {isActive && voloPassPricingAllowed ? (
        <View
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          paddingLeft={5}
          paddingRight={5}
          paddingTop={2}
          paddingBottom={2}
          borderColor={colors.accents.select.bg}
          borderWidth={1}
          borderBottomLeftRadius={20}
          borderBottomRightRadius={20}
        >
          <VoloPassBadgeIcon size={8} mr={10} colorScheme={dark ? 'white' : 'primary'} />
          <>
            <Text bold fontSize={isWeb ? webFonts.voloPass : null}>
              Member Pricing
            </Text>
            <View flexDirection="row">
              <Text bold fontSize={isWeb ? webFonts.voloPass : null}>
                {formatCents(showPricesWithFees ? memberPriceWithFees : memberPrice)}
              </Text>
              {phasePricing?.length > 1 ? (
                <MembershipPricingInfo
                  iconProps={
                    isWeb
                      ? {
                          height: 15,
                          width: 15,
                        }
                      : {}
                  }
                  phasePricing={phasePricing}
                  showPricesWithFees={showPricesWithFees}
                />
              ) : null}
            </View>
          </>
        </View>
      ) : null}
    </View>
  );
};

export default LeaguePricingGrid;
