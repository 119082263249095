import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import type { MessageCenterStore } from '.';

export const getInitialState = (): MessageCenterStore => ({
  rentalId: '',
  organizationId: '',
  filters: {
    dateSelectionType: 'range',
    genders: [],
    playerTypes: [],
    voloPassOnly: false,
    isMarketing: false,
  },
  messageContent: '',
  from: '',
  subject: '',
  manualEmails: [],
});

export const useMessageCenterStore = createWithEqualityFn(
  devtools(getInitialState, { enabled: false }),
  shallow
);
