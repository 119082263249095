import { Text, type ITextProps } from '../../../../../base-components';

/**
 * Preset Text component for subtitles in the rental registration flow
 * - Uses the `caption` type as default
 * - Uses the `14` font size as default
 * - Uses the `15` line height as default
 */
const RentalSubtext = (props: ITextProps) => {
  return <Text type="caption" fontSize={14} lineHeight={15} {...props} />;
};

export default RentalSubtext;
