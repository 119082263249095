import React from 'react';
import { Colors } from '../../theme';
import { Text, View } from '../../base-components';
import styles from './styles';

interface DottedSeparatorProps {
  label: string | undefined;
  thresholdReached: boolean | undefined;
}

const DottedSeparator = ({ label, thresholdReached }: DottedSeparatorProps) => {
  const color = !thresholdReached ? Colors.accents.no : Colors.accents.yes;

  return (
    <View style={styles.separatorContainer}>
      <View style={[styles.dottedLine, { borderColor: color }]} />
      <Text style={[styles.separatorText, { color }]}>{label}</Text>
    </View>
  );
};

export default DottedSeparator;
