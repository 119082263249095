// **** File copy/pasted from mobile ***
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { OPEN_SLOT_BY_GENDER } from '../graphql/MANAGE_DROPIN_SLOTS';
import { ErrorMessageEnum } from '../../../constants/enums';
import { useActionStates } from '../../../hooks';

const useOpenDropInSlot = ({ gameId = '', teamId = '' }) => {
  /* pass gender to the onOpenSlot */
  const [openSlotByGender, { loading, error }] = useMutation(OPEN_SLOT_BY_GENDER, {
    errorPolicy: 'none',
  });

  const { setSuccess, setError } = useActionStates({ withAlerts: true });

  /**
   * Asynchronously opens a new slot in the game based on the specified gender.
   * Allows for optional callback functions to handle completion and error scenarios.
   * This function is memoized using `useCallback` and should be used as a
   * stable reference in components.
   *
   * @typedef {Object} OnOpenSlotOptions
   * @property {string} gender - string gender to open a new slot for. ['f', 'm', 'a']
   * @property {function(data: any) => void} onCompleted - Optional callback function to execute after successfully opening a slot. Receives the returned data from the operation as a parameter.
   * @property {function(error: ApolloError) => void} onError - Optional callback function to execute when an error occurs while opening a slot. Receives the error as a parameter.

   * @param {OnOpenSlotOptions}
   */
  const onOpenSlot = useCallback(
    async ({
      gender,
      onCompleted,
      onError,
    }: {
      gender: string;
      onCompleted?: (data: any) => void;
      onError?: (e: any) => void;
    }) => {
      const variables = {
        input: { gameId, teamId, gender },
      };

      const handleError = async (e: any) => {
        if (onError) {
          onError(e);
        } else {
          setError(ErrorMessageEnum.DROPIN_OPENING);
        }
      };

      const handleCompleted = async (data: any) => {
        if (onCompleted) {
          onCompleted(data);
        } else {
          setSuccess('Slot opened successfully');
        }
      };

      try {
        await openSlotByGender({
          variables,
          onCompleted: handleCompleted,
          onError: handleError,
        });
      } catch (e) {
        setError(ErrorMessageEnum.DROPIN_OPENING);
      }
    },
    [gameId, teamId, openSlotByGender, setError, setSuccess]
  );

  return {
    onOpenSlot,
    loading,
    error,
  };
};

export default useOpenDropInSlot;
