import { Alert, Platform, Linking } from 'react-native';

interface MapLinkParams {
  /**
   * Formatted Address of the venue
   *  - this actual may seem correct in production at least (since mobile has maps setup to use the formatted address - and we have not had any issues when just the address is utilized)
   */
  venueAddress: string;
  /**
   * Coordinates of the venue
   * - used across all platforms
   * - @deprecated - was told to use the formatted address & coordinates seem to pull up different places in  maps for some of the venues when used compared to the formatted address
   */
  venueCoordinates?:
    | {
        lat: number;
        lng: number;
      }
    | undefined
    | null;
  /**
   * place_id that is stored on the venue object
   * - used to search for the venue on web
   * @see https://developers.google.com/maps/documentation/urls/get-started#search-parameters
   */
  placeId: string;
  /**
   * Will determine the type of map link to generate
   * - `destination`: will generate a link to navigate to the venue
   * - `query`: will generate a link to search for the venue & allow the user to navigate
   * @default 'query'
   * @see https://developers.google.com/maps/documentation/urls/get-started#directions-action
   */
  type?: 'destination' | 'query';
}

// Note to dev: leaving venue coordinates in case they are needed in the future
// as concerns about the formatted address were raised but told that is what the admins go off of

export const mapLink = ({
  venueAddress,
  // venueCoordinates,
  placeId,
  type = 'query',
}: MapLinkParams): string => {
  if (!venueAddress) {
    throw new Error('Missing venueAddress!');
  }

  // const { lat = 0, lng = 0 } = venueCoordinates;
  // const latlong = `${lat},${lng}`;

  const formattedAddress = encodeURIComponent(venueAddress || '');

  const queryType = type === 'destination' ? 'dir' : 'search';

  return Platform.select({
    ios: `maps://0,0?q=${formattedAddress}`,
    android: `geo:0,0?q=${formattedAddress}`,

    // web fallback to google maps with coords for now
    default: `https://www.google.com/maps/${queryType}/?api=1&${type}=${formattedAddress}&${type}_place_id=${placeId}`,
  });
};

export const openMap = async ({
  venueAddress,
  // venueCoordinates,
  placeId,
  type = 'query',
}: MapLinkParams): Promise<void> => {
  try {
    const link = mapLink({ venueAddress, placeId, type });
    const supported = await Linking.canOpenURL(link);

    if (supported) {
      await Linking.openURL(link);
    } else {
      Alert.alert('Error', 'The provided map link is not available');
    }
  } catch (error: any) {
    // fallback error catching in case anything fails above
    console.error(`[openMap]Error => ${error?.message}`);
    Alert.alert('Error', error?.message);
  }
};
