import { useMemo } from 'react';
import { Platform, type StyleProp, StyleSheet } from 'react-native';
import {
  type IBoxProps,
  type IIconProps,
  type ITextProps,
  Text as NBText,
} from '@rivallapp/native-base';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';

import {
  View,
  Box,
  FontAwesomeIcon,
  Pressable as BasePressable,
  type PressableProps,
} from '../../base-components';
import { useHaloChipColors, useShadowStyles } from './hooks';
import HaloChipContext, { defaultTextProps, useHaloChipContext } from './context';

export type HaloChipVariant = 'success' | 'warning' | 'error' | 'info';

export interface HaloChipProps extends Omit<IBoxProps, 'variant' | 'shadow' | 'style'> {
  /**
   * Overrides the default color of the HaloChip
   * - if not provided, the color is determined by the variant
   */
  color?: IBoxProps['color'];
  /**
   * Overrides the default color scheme of the HaloChip
   * - if not provided, the color scheme is determined by the variant
   */
  colorScheme?: IBoxProps['colorScheme'];
  /**
   * The variant of the HaloChip
   * - determines the color of the opaque background
   * - default is 'info'
   */
  variant?: HaloChipVariant;
  /**
   * Full shadow support
   * - if true, the HaloChip will have a shadow
   * - if an object, the object will be spread onto the shadow style for full customization
   * @web only (mobile shadowing does not work well with transparent Views)
   */
  shadow?:
    | boolean
    | StyleProp<{
        shadowColor?: IBoxProps['colorScheme'];
        shadowOffset?: { width: number; height: number };
        shadowOpacity?: number;
        shadowRadius?: number;
        elevation?: number;
      }>;

  _icon?: IIconProps;

  /**
   * Opacity of the opaque background
   */
  opacity?: number;
  /**
   * Icon to display on the left side of the HaloChip
   * - color integration to match HaloChip
   */
  leftIcon?: IconDefinition;
  /**
   * Icon to display on the right side of the HaloChip
   * - color integration to match HaloChip
   */
  rightIcon?: IconDefinition;
}

const align_self_default = 'flex-start';

const HaloChipComponent = ({
  color,
  colorScheme,
  children,
  _text,
  _icon,
  variant = 'info',
  shadow = false,
  leftIcon,
  rightIcon,
  opacity = 0.15,
  ...props
}: HaloChipProps) => {
  const shadowStyle = useShadowStyles(shadow);
  const { borderColor, textColor, absoluteViewProps } = useHaloChipColors({
    color,
    colorScheme,
    variant,
    opacity,
  });

  const providerValue = useMemo(
    () => ({
      color: textColor as string,
      _textProps: {
        ...defaultTextProps,
        ..._text,
      },
      _iconProps: _icon,
    }),
    [textColor, _text, _icon]
  );

  return (
    <HaloChipContext.Provider value={providerValue}>
      <Box
        nativeID="halo-chip"
        borderColor={borderColor}
        borderRadius="md"
        overflow="hidden"
        flexDir="row"
        alignItems="center"
        // important! keep component from stretching to fill parent
        alignSelf={align_self_default}
        py={1}
        px={2}
        _text={{
          color: textColor,
          ...defaultTextProps,
          ..._text,
        }}
        // mobile shadowing does not work well with transparent Views
        {...(Platform.OS === 'web' && shadowStyle)}
        {...props}
      >
        {leftIcon ? (
          <Box mr={1}>
            <FontAwesomeIcon icon={leftIcon} color={textColor as string} {..._icon} />
          </Box>
        ) : null}

        {children}

        {rightIcon ? (
          <Box ml={1}>
            <FontAwesomeIcon icon={rightIcon} color={textColor as string} {..._icon} />
          </Box>
        ) : null}
        <View {...StyleSheet.absoluteFillObject} {...absoluteViewProps} />
      </Box>
    </HaloChipContext.Provider>
  );
};

// =============================================
// =========== COMPOSABLE COMPONENTS ===========
// =============================================

// Icon component that inherits the color from the HaloChip
export interface HaloChipIconProps extends IIconProps {
  icon: IconDefinition;
}
const HaloChipIcon = ({ icon, ...props }: HaloChipIconProps) => {
  const { color, _iconProps } = useHaloChipContext();
  return <FontAwesomeIcon icon={icon} color={color} {..._iconProps} {...props} />;
};

// Text component that inherits the color from the HaloChip
export interface HaloChipTextProps extends ITextProps {}
const HaloChipText = (props: HaloChipTextProps) => {
  const { color, _textProps } = useHaloChipContext();
  return <NBText color={color} {..._textProps} {...props} />;
};

// =============================================
// =========== PRESSABLE COMPONENT =============
// =============================================
export interface HaloChipPressableProps extends Pick<PressableProps, 'onPress'>, HaloChipProps {
  isDisabled?: boolean;
  alignSelf?: IBoxProps['alignSelf'];
}
const HaloChipPressable = ({
  children,
  onPress,
  isDisabled,
  alignSelf = align_self_default,
  ...chipProps
}: HaloChipPressableProps) => {
  return (
    <BasePressable onPress={onPress} isDisabled={isDisabled} alignSelf={alignSelf}>
      <HaloChipComponent alignSelf={align_self_default} {...chipProps}>
        {children}
      </HaloChipComponent>
    </BasePressable>
  );
};

// =============================================
// =========== NAMESPACE EXPORTS ===============
// =============================================
namespace HaloChipNamespace {
  export const Pressable = HaloChipPressable;
  export const Icon = HaloChipIcon;
  export const Text = HaloChipText;
}

/**
 * `HaloChip`
 * - a `chip` component with a colored border and an opaque background to match (almost like a halo effect)
 * - full shadow support
 * - left and right icon support
 * - direct child FontAwesomeIcon (base-component) color support

 * `HaloChip.Pressable`
 * - a Pressable component that wraps the HaloChip and gives the ability for onPress functionality
 * - all props from Pressable are passed down to the Pressable component
 * - all props from HaloChip are passed down to the HaloChip component through the `chipProps` prop
 *
 * `HaloChip.Icon`
 * - a composable component that inherits the color from the HaloChip & any _icon props
 * - overwritten by per-instance props
 *
 * `HaloChip.Text`
 * - a composable component that inherits the color from the HaloChip & any _text props
 * - overwritten by per-instance props
 *
 * ---
 * Color precedence:
 *
 * `color` - overrides the default color of the HaloChip, takes #1 priority
 *
 * `colorScheme` - overrides the default color scheme of the HaloChip, takes #2 priority
 *
 * `variant` - the variant of the HaloChip, preset variants are 'success', 'warning', 'error', 'info', takes #3 priority (if color and colorScheme are not provided, default color is determined by the variant prop. Default is 'info')
 */
const HaloChip = Object.assign(HaloChipComponent, HaloChipNamespace);

export default HaloChip;
