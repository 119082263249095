export const PricePhaseEnum = {
  EARLY_BIRD: 'early_bird',
  REGULAR: 'regular',
  LAST_CHANCE: 'last_chance',
} as const;

export const getPricePhaseName = (phase: string) => {
  switch (phase) {
    case 'earlyBird':
    case PricePhaseEnum.EARLY_BIRD:
      return 'Early Bird';
    case 'lastChance':
    case PricePhaseEnum.LAST_CHANCE:
      return 'Last Chance';
    default:
      return 'Regular';
  }
};

// this checks the current phase passed in and returns the phase object
// this is assuming since it's in the client that it is graphql formatted
// ex: so earlyBird NOT early_bird
export const getPricePhaseItem = <T, V, U>(
  phase: string,
  { earlyBird, regular, lastChance }: { earlyBird: T; regular: V; lastChance: U }
) => {
  switch (phase) {
    case PricePhaseEnum.EARLY_BIRD:
      return earlyBird;
    case PricePhaseEnum.LAST_CHANCE:
      return lastChance;
    default:
      return regular;
  }
};

export const FormattedPricePhaseEnum = {
  [PricePhaseEnum.EARLY_BIRD]: {
    label: 'Early Bird',
    value: PricePhaseEnum.EARLY_BIRD,
  },
  [PricePhaseEnum.REGULAR]: {
    label: 'Regular',
    value: PricePhaseEnum.REGULAR,
  },
  [PricePhaseEnum.LAST_CHANCE]: {
    label: 'Last Chance',
    value: PricePhaseEnum.LAST_CHANCE,
  },
} as const;

export default PricePhaseEnum;
