import { MembershipEnum, StripeSubscriptionStatusEnum, MembershipStatus } from '../../../constants';
import { Colors } from '../../../theme';
import { View, Text } from '../../../base-components';
import { capitalize } from '../../../utilities';
import { type MembershipCardProps } from '../MembershipCard';

const CIRCLED_BULLET_POINT = `\u29Bf`;

interface MemberHeaderProps extends Omit<MembershipCardProps, 'membershipPrice'> {}

const MemberHeader = ({ variant, membershipDetails }: MemberHeaderProps) => {
  const { membershipStatus, membershipType, membershipStartDate = '' } = membershipDetails || {};

  const membershipDateString = new Date(membershipStartDate).toLocaleDateString('en-EN', {
    month: 'long',
    year: 'numeric',
  });

  // this can be updated to a switch or a broader ternary if we end up with more greens or card variants
  const activeGreen = variant === MembershipEnum.VOLO_FITNESS ? 'success.600' : 'success.300';
  const statusColor =
    membershipStatus === MembershipStatus[StripeSubscriptionStatusEnum.ACTIVE].label
      ? activeGreen
      : 'gray.600';

  return (
    <View flex={1} marginLeft={6} flexDirection="row" justifyContent="space-between">
      <View>
        <Text>
          <Text color={statusColor}>{CIRCLED_BULLET_POINT} </Text>
          <Text color={statusColor} fontSize={11} alignContent="center" bold>
            {membershipStatus && capitalize(membershipStatus)}
          </Text>
        </Text>
        <Text fontSize={11} color={Colors.black}>
          Type: {membershipType && capitalize(membershipType)}
        </Text>
        {membershipStatus === MembershipStatus[StripeSubscriptionStatusEnum.ACTIVE].label && (
          <Text fontSize={11} color={Colors.black}>
            Member Since {membershipDateString}
          </Text>
        )}
      </View>
    </View>
  );
};

export default MemberHeader;
