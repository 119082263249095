import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgPopularVenue = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
    <Path
      fill="#103b5d"
      stroke="#103b5d"
      d="M17 33.429 40 17l23 16.429v4.517h-6.953V63H23.954V37.946H17z"
    />
    <Path
      fill="#fff"
      d="m49.591 38.425-6.379-.542-2.533-5.393c-.178-.378-.762-.378-.94 0l-2.533 5.393-6.379.542c-.446.038-.627.547-.29.819l4.813 3.875-1.409 5.729c-.098.401.374.716.76.506l5.508-2.998 5.508 2.998c.386.21.859-.105.76-.506l-1.408-5.729 4.812-3.875c.337-.272.157-.78-.29-.819"
    />
    <Path
      fill="#fff"
      d="M33.689 32.304c.325.414 1.243 2.192 1.767 3.228.083.163-.14.31-.282.187-.906-.785-2.454-2.147-2.78-2.561-.257-.328-.176-.784.182-1.02.357-.236.855-.162 1.113.166M46.73 32.304c-.325.414-1.243 2.192-1.767 3.228-.083.163.14.31.282.187.906-.785 2.454-2.147 2.78-2.561.257-.328.176-.784-.182-1.02-.357-.236-.855-.162-1.113.166M51.869 44.445c-.53-.158-2.656-.42-3.893-.563-.194-.022-.28.217-.108.304 1.091.552 2.977 1.49 3.506 1.649.419.125.869-.085 1.006-.468.136-.384-.093-.797-.511-.922M28.55 44.445c.53-.158 2.656-.42 3.893-.563.194-.022.28.217.108.304-1.091.552-2.977 1.49-3.506 1.649-.419.125-.869-.085-1.006-.468-.136-.384.093-.797.511-.922M39.437 52.694c0-.51.388-2.445.623-3.567.036-.176.311-.176.348 0 .235 1.122.623 3.057.623 3.567 0 .404-.357.731-.797.731s-.797-.327-.797-.73"
    />
  </Svg>
);
export default SvgPopularVenue;
