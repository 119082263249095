import { screenWidth } from '../../../../constants/globalStyles';
import { isMobile } from '../../../../utilities';

function toPercent(value: string) {
  return `${value}%`;
}

function stripPercent(value: string) {
  return parseFloat(value.replace('%', ''));
}

export const numColumns = 2;
export const horizGap = 15;

const verticalGapPercentage = '6%';
// looks like we cant use a percentage value for gap in React Native (works for web implementation)
// we need to convert the gap percentage to a pixel value
const verticalPixelGap = screenWidth * (stripPercent(verticalGapPercentage) / 100);
const totalGaps = numColumns - 1;
const totalGapPixels = totalGaps * verticalPixelGap;

const flexBasisWeb = `calc((100% - ${totalGaps} * ${verticalGapPercentage}) / ${numColumns})`;
const flexBasisMobile = ((screenWidth - totalGapPixels) / numColumns / screenWidth) * 100;

/**
 * Gap on mobile is not supported with percentage value
 * ---
 * @web uses percentage value
 * @mobile uses pixel value
 */
export const platformAwareGap = isMobile ? verticalPixelGap : verticalGapPercentage;
/**
 * This is a workaround for the gap issue on mobile
 * - percentage values for gap are not supported in React Native (mobile)
 * - we need to convert the percentage value to a pixel value
 * ---
 * @web uses percentage value through `calc` function
 * @mobile uses pixel value
 */
export const platformAwareFlexBasis = isMobile
  ? toPercent(flexBasisMobile.toFixed(2))
  : flexBasisWeb;
