import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useActionStates } from '../../../hooks';
import {
  GET_CURRENT_USER,
  UPDATE_PASSWORD_MUTATION,
} from '../graphql/ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import {
  Alert,
  Button,
  FormControl,
  Skeleton,
  TextInput,
  Text,
  View,
} from '../../../base-components';
import { ErrorMessageEnum } from '../../../constants/enums';
import styles from '../styles';

const MIN_PASSWORD_LENGTH = 6;

const UpdatePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [errors, setErrors] = useState({
    current: '',
    length: '',
    match: '',
  });

  const clearPasswordFields = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({ withAlerts: true });

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'cache-and-network',
  });

  const { currentUser } = userData || {};

  const [updatePasswordMutation, { loading: passwordUpdating }] =
    useMutation(UPDATE_PASSWORD_MUTATION);

  const updatePassword = async () => {
    try {
      const res = await updatePasswordMutation({
        variables: {
          input: {
            _id: currentUser._id,
            currentPassword,
            newPassword,
          },
        },
      });
      if (res.errors && res.errors[0]) return setError(res.errors[0].message); // for mobile
      setSuccess('Password changed!');
    } catch (e) {
      setError(ErrorMessageEnum.UPDATE_FAILED);
      console.error(e);
    }
    return clearPasswordFields();
  };

  const checkInvalid = () => {
    const currentError = errors.current.length;
    const lengthError = errors.length.length;
    const matchError = errors.match.length;
    if (currentError || lengthError || matchError || passwordUpdating) return true;
    return false;
  };

  const checkValidation = (key: 'current' | 'new' | 'confirm' | 'typing', value = '') => {
    switch (key) {
      case 'current':
        if (!currentPassword.length)
          setErrors({ ...errors, current: 'Current password is required' });
        else setErrors({ ...errors, current: '' });
        break;
      case 'new':
        if (newPassword.length < MIN_PASSWORD_LENGTH)
          setErrors({
            ...errors,
            length: `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
          });
        else setErrors({ ...errors, length: '' });
        break;
      case 'confirm':
        if (confirmPassword !== newPassword)
          setErrors({ ...errors, match: 'New password fields do not match' });
        else setErrors({ ...errors, match: '' });
        break;
      case 'typing':
        if (value !== newPassword)
          setErrors({ ...errors, match: 'New password fields do not match' });
        else setErrors({ ...errors, match: '' });
        break;
      default:
        console.error('Key is not supported');
    }
  };

  const handleConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    checkValidation('typing', value);
  };

  if (userError) {
    setError(ErrorMessageEnum.USER_INFO_LOAD_FAILED);
    console.error(userError);
  }

  if (userLoading)
    return (
      <View style={styles.skeletonContainer}>
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.saveButton} />
      </View>
    );

  return (
    <>
      <FormControl isRequired isInvalid={checkInvalid()}>
        {showAlert ? (
          <Alert status={alertType} message={alertMessage} showAlert setShowAlert={setShowAlert} />
        ) : null}
        <View style={styles.containerColumn}>
          <Text style={styles.label}>Current Password</Text>
          <TextInput
            onBlur={() => checkValidation('current')}
            value={currentPassword}
            onChangeText={setCurrentPassword}
            isSecureText
          />
          <FormControl.ErrorMessage>{errors.current}</FormControl.ErrorMessage>
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>New Password</Text>
          <TextInput
            onBlur={() => checkValidation('new')}
            value={newPassword}
            onChangeText={setNewPassword}
            isSecureText
          />
          <FormControl.ErrorMessage>{errors.length}</FormControl.ErrorMessage>
        </View>

        <View style={styles.containerColumn}>
          <Text style={styles.label}>Retype New Password</Text>
          <TextInput
            onBlur={() => checkValidation('confirm')}
            value={confirmPassword}
            onChangeText={handleConfirmPassword}
            isSecureText
          />
          <FormControl.ErrorMessage>{errors.match}</FormControl.ErrorMessage>
        </View>
      </FormControl>

      <Button
        style={styles.saveButton}
        onPress={updatePassword}
        isDisabled={checkInvalid()}
        isLoading={passwordUpdating}
      >
        Save
      </Button>
    </>
  );
};

export default UpdatePassword;
