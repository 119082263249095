import isEqual from 'lodash.isequal';

import { createWithEqualityFn, shallow } from '../../../utilities/zustand';
import generateRandomStr from '../../../utilities/generateRandomStr';
import {
  initialStepsState,
  RentalRegistrationSteps,
  findNextStep,
  findPreviousStep,
  RentalRegistrationFirstStep,
  findRentalRouteIndex,
  SortedRentalRegistrationSteps,
  isFirstStepInRentalFlow,
  isLastStepInRentalFlow,
} from '../RegistrationFlowSections/steps';
import { type RentalStepStoreKeyNames } from '../RegistrationFlowSections/steps.types';
import {
  type RentalBooking,
  type RentalActionMethodArgs,
  type RentalBookingState,
  type RentalBookingStore,
  type RentalNavigationCallback,
} from './rentalBookingStore.types';

// ============================================================
// * Flow of how rental booking store is used
// ============================================================
// - first we check if a booking is in progress by calling `initBookingFlow` with the `_id` and `activity_name`
// - if there is a `currentBooking` in the store, we save (move) the booking progress to `inProgressBookings`
// - then we check if the current `_id` and `activity_name` (rental) is in progress by calling `findInProgressBooking` with the `_id` and `activity_name`
// - if a booking is found, we resume the booking by calling `resumeInProgressBooking` with the `currentBooking` returned
// - if no booking is found, we create a new booking by calling `createInProgressBooking` with the `_id` and `activity_name`
// - this will create a new booking and set the `currentBooking` to this new booking & update the `inProgressBookings` and `inProgressMap`
// TODO: - the booking is then updated throughout the flow by calling selectors on the `currentBooking` and updating the store
// - [x] Date Selection
// - [x] Time Slots Selection
// - [x] Court Selection
// - [x] Group Size
// - [x] (optional) Group Password
// - [x] Confirmation
// ============================================================
// TODO: Confirm Flow:
// - [x] when the booking is confirmed, the booking is moved from `inProgressBookings` to `confirmedBookings`
// - [x] the booking is then available for checkout
// ============================================================
// TODO: Edit Flow:
// - [x] if the confirmed booking is edited
// ============================================================
// TODO: Remove Flow:
// - [x] if the booking is removed, the booking is deleted from the store
// ============================================================
// TODO: Navigation Flow:
// - [x] step navigation is handled by the `navigateNextStep` and `navigatePreviousStep` methods
// - [x] cb for per platform usage

// ============================================================
// ! -------------- INITIAL STATES --------------
// ============================================================

const initialRentalBookingState: RentalBookingState = {
  reservationId: undefined,
  joiningReservationDetails: undefined,
  cancelResetOnUnmount: false,
  currentBooking: null,
  cartTotalAfterDiscountCents: 0,
  creditCents: 0,
  promoCodeString: '',
  donation: null,
  liabilityTermsAgreed: false,
  rentalPolicyAgreed: false,
  cardSourceId: null,
  inProgressBookings: {},
  confirmedBookings: {},
  inProgressMap: {},
};

// ============================================================
// ! -------------- STORE --------------
// ============================================================

/**
 * Helper function to generate an ID used to map rentals to bookingIds
 */
export function getCompositeKey({ _id, activity_name }: RentalActionMethodArgs) {
  return `${_id}-${activity_name}`;
}

const useRentalBookingStore = createWithEqualityFn<RentalBookingStore>((set, get) => {
  /**
   * Helper function too move the current booking to in progress
   * - effectively `saving` the current booking
   */
  function moveBookingToInProgress(booking: RentalBooking) {
    set(state => {
      const { bookingId, rentalId, rentalType } = booking;
      const key = getCompositeKey({ _id: rentalId, activity_name: rentalType });

      return {
        ...state,
        currentBooking: null,
        inProgressMap: { ...state.inProgressMap, [key]: bookingId },
        inProgressBookings: { ...state.inProgressBookings, [bookingId]: booking },
      };
    });
  }

  /**
   * Helper function to navigate to the next or previous step in the rental registration flow
   * - callback is used to then navigate to the correct screen in the flow (per platform - web or mobile)
   */
  function navigateStep(
    findStepFunction: typeof findNextStep | typeof findPreviousStep,
    navigationCallback: RentalNavigationCallback
  ) {
    const currentBooking = get().actions.getCurrentBooking();
    if (!currentBooking) {
      // console.warn('[RentalBookingStore][navigateStep]: No current booking found.');
      return undefined;
    }

    const { currentStep } = currentBooking;
    const step = findStepFunction(currentStep);
    if (!step) {
      return undefined;
    }

    if (step.storeKey !== currentStep) {
      const updatedBooking = {
        ...currentBooking,
        currentStep: step.storeKey,
      };
      set({ currentBooking: updatedBooking });
    }

    if (typeof navigationCallback === 'function' && step.routeName) {
      navigationCallback(step.routeName);
    }

    return {
      currentStep: step.storeKey,
      currentRouteName: step.routeName,
      isFirstStep: isFirstStepInRentalFlow(step.storeKey),
      isLastStep: isLastStepInRentalFlow(step.storeKey),
      index: findRentalRouteIndex(step.storeKey),
    };
  }

  return {
    ...initialRentalBookingState,
    actions: {
      // ---
      resetRentalBookingStore: () =>
        get().cancelResetOnUnmount ? null : set(initialRentalBookingState),
      // ---
      initBookingFlow: args => {
        const { currentBooking, actions } = get();
        if (currentBooking) {
          // if we have a current booking when this method is called, we move the current booking to in progress to 'save'
          // any changes we may have on the current booking - then we look at what booking we need to resume
          // ---
          // this works because we can only ever display one booking at a time, so if we swipe away sheet (mobile) or close modal (web)
          // and select a booking again - this method is called and will handle the current booking accordingly
          moveBookingToInProgress(currentBooking);
        }

        const { findInProgressBooking, resumeInProgressBooking, createInProgressBooking } = actions;
        const bookingInProgress = findInProgressBooking(args);

        if (bookingInProgress) {
          resumeInProgressBooking(bookingInProgress);
        } else {
          createInProgressBooking(args);
        }
      },
      // ---
      // ---
      findInProgressBooking: args => {
        const { inProgressMap, inProgressBookings } = get();

        const key = getCompositeKey(args);
        const inProgressBookingUUID = inProgressMap[key];

        if (inProgressBookingUUID) {
          // console.log(
          //   `[RentalBookingStore][findInProgressBooking]: Found in progress booking for ${key}, booking id: ${inProgressBookingUUID}`
          // );
          return inProgressBookings[inProgressBookingUUID];
        }

        // console.warn(
        //   `[RentalBookingStore][findInProgressBooking]: No in progress booking found for ${key}`
        // );
        return undefined;
      },
      // ---
      // ---
      createInProgressBooking: args => {
        set(state => {
          const key = getCompositeKey(args);
          const inProgressBookingUUID = generateRandomStr();

          const booking: RentalBooking = {
            bookingId: inProgressBookingUUID,
            rentalId: args._id,
            rentalType: args.activity_name,
            currentStep: RentalRegistrationFirstStep.storeKey,
            steps: initialStepsState,
            price: {
              subtotal: 0,
              formattedSubtotal: '',
            },
            isEditMode: false,
          };

          // console.log(
          //   `[RentalBookingStore][createInProgressBooking]: Creating new booking with id: ${booking.bookingId}`
          // );

          return {
            ...state,
            currentBooking: booking,
            inProgressBookings: {
              ...state.inProgressBookings,
              [inProgressBookingUUID]: booking,
            },
            inProgressMap: { ...state.inProgressMap, [key]: inProgressBookingUUID },
          };
        });
      },
      // ---
      // ---
      resumeInProgressBooking: booking => {
        if (booking) {
          // console.log(
          //   `[RentalBookingStore][resumeInProgressBooking]: Resuming booking: ${booking.bookingId}`,
          //   booking
          // );

          set(state => ({
            ...state,
            currentBooking: booking,
          }));
        }
      },
      // ---
      // ---
      confirmCurrentBooking: () => {
        const { getCurrentBooking, validateBooking } = get().actions;

        const booking = getCurrentBooking();
        if (!booking) {
          return undefined;
        }

        const { bookingId } = booking;
        const confirmedBooking = validateBooking(booking);
        if (!confirmedBooking) {
          return undefined;
        }

        set(state => {
          const newConfirmedBookings = {
            ...state.confirmedBookings,
            [bookingId]: { ...confirmedBooking, isEditMode: false },
          };

          // remove the booking from inProgressBookings
          const newInProgressBookings = { ...state.inProgressBookings };

          // remove the booking from inProgressMap
          const newInProgressMap = { ...state.inProgressMap };

          // if the booking is in edit mode, we don't want to remove delete it from the inProgressMap
          // as we could have another booking in progress for the same rental
          // so we can only safely remove the booking here if its not in edit mode
          if (!confirmedBooking.isEditMode) {
            delete newInProgressBookings[bookingId];
            delete newInProgressMap[
              getCompositeKey({
                _id: confirmedBooking.rentalId,
                activity_name: confirmedBooking.rentalType,
              })
            ];
          }

          return {
            ...state,
            currentBooking: null,
            inProgressMap: newInProgressMap,
            inProgressBookings: newInProgressBookings,
            confirmedBookings: newConfirmedBookings,
          };
        });

        return confirmedBooking;
      },
      // ---
      // ---
      validateBooking: booking => {
        const { steps } = booking;
        const rentalSteps = Object.entries(steps);

        const isValid = rentalSteps.every(([stepName, stepValues]) => {
          return RentalRegistrationSteps[
            stepName as keyof typeof RentalRegistrationSteps
            // @ts-ignore - The type for RentalRegistrationSteps.validation is a bit complicated. Should consider a refactor
          ]?.validation(stepValues);
        });

        if (!isValid) {
          // console.warn(
          //   `[RentalBookingStore][validateBooking]: Booking is not valid: ${booking.bookingId}`
          // );
          return undefined;
        }

        return booking;
      },
      // ---
      // ---
      findConfirmedBooking: bookingId => {
        const confirmedBooking = get().confirmedBookings[bookingId];
        // if (!confirmedBooking) {
        //   console.warn(
        //     `[RentalBookingStore][findConfirmedBooking]: No confirmed booking found for ${bookingId}`
        //   );
        // }

        return confirmedBooking;
      },
      // ---
      // ---
      getCurrentBooking: () => {
        const { currentBooking } = get();
        if (!currentBooking) {
          // console.warn(`[RentalBookingStore][getCurrentBooking]: No current booking found`);
          return undefined;
        }

        return currentBooking;
      },
      // ---
      // ---
      moveConfirmedBookingToEdit: bookingId => {
        const { actions, confirmedBookings } = get();
        const confirmedBooking = actions.findConfirmedBooking(bookingId);

        if (!confirmedBooking) {
          // console.warn(
          //   `[RentalBookingStore][moveConfirmedBookingToEdit]: No confirmed booking found for ${bookingId}`
          // );
          return;
        }

        const updatedBooking = {
          ...confirmedBooking,
          isEditMode: true,
        };

        const updatedConfirmedBookings = {
          ...confirmedBookings,
          [bookingId]: updatedBooking,
        };

        set(state => ({
          ...state,
          currentBooking: updatedBooking,
          confirmedBookings: updatedConfirmedBookings,
        }));
      },
      // ---
      // ---
      handleCloseCurrentBookingModal: ({ inEditMode, notInEditMode }) => {
        const { currentBooking, actions } = get();
        if (!currentBooking) {
          // console.warn(
          //   `[RentalBookingStore][checkIfBookingIsInEditMode]: No current booking found`
          // );
          return;
        }

        if (currentBooking.isEditMode) {
          const confirmedBooking = actions.findConfirmedBooking(currentBooking.bookingId);
          if (!confirmedBooking) {
            // console.warn(
            //   `[RentalBookingStore][checkIfBookingIsInEditMode]: No confirmed booking found for ${currentBooking.bookingId}`
            // );
            return;
          }

          const { steps } = currentBooking;
          const rentalSteps = Object.entries(steps);

          const hasUnsavedChanges = !isEqual(rentalSteps, Object.entries(confirmedBooking.steps));

          inEditMode(hasUnsavedChanges, function clearCurrentEditMode() {
            set(state => {
              const newConfirmedBookings = { ...state.confirmedBookings };
              const currentConfirmedBooking = newConfirmedBookings[currentBooking.bookingId];
              if (!currentConfirmedBooking) {
                return { currentBooking: null };
              }

              newConfirmedBookings[currentBooking.bookingId] = {
                ...currentConfirmedBooking,
                isEditMode: false,
              };

              return { currentBooking: null, confirmedBookings: newConfirmedBookings };
            });
          });
          return;
        }

        // if the booking is not in edit mode, no unsaved changes
        moveBookingToInProgress(currentBooking);
        notInEditMode();
      },
      // ---
      // ---
      deleteBooking: ({ bookingId, onCompleted, onError }) => {
        const { currentBooking, confirmedBookings, inProgressBookings } = get();

        // either use the bookingId that is selected (could be confirmed or in progress) or the current booking (if it exists)
        const bookingIdToUse = bookingId || currentBooking?.bookingId;

        // if no id criteria is provided or found, we cannot delete a booking
        if (!bookingIdToUse) {
          onError?.();
          return;
        }

        // find the booking to use for deletion
        // either confirmed booking, in progress booking or current booking
        const bookingToUse =
          confirmedBookings[bookingIdToUse] ||
          inProgressBookings[bookingIdToUse] ||
          bookingIdToUse === currentBooking?.bookingId
            ? currentBooking
            : undefined;

        // at this point if there is no booking to use, we cannot delete a booking
        if (!bookingIdToUse || !bookingToUse) {
          onError?.();
          return;
        }

        set(state => {
          const newInProgressBookings = { ...state.inProgressBookings };
          const newInProgressMap = { ...state.inProgressMap };
          if (newInProgressBookings[bookingIdToUse]) {
            delete newInProgressBookings[bookingIdToUse];
            delete newInProgressMap[
              getCompositeKey({
                _id: bookingIdToUse,
                activity_name: bookingToUse.rentalType,
              })
            ];
          }

          const newConfirmedBookings = { ...state.confirmedBookings };
          delete newConfirmedBookings[bookingIdToUse];

          return {
            ...state,
            // if the bookingId is the same as the current booking, set the current booking to null
            // else we leave the current booking as is
            currentBooking:
              state.currentBooking?.bookingId === bookingIdToUse ? null : state.currentBooking,
            confirmedBookings: newConfirmedBookings,
            inProgressBookings: newInProgressBookings,
            inProgressMap: newInProgressMap,
          };
        });
        onCompleted?.();
      },
      // ---
      // ---
      navigateNextStep: navigationCallback => navigateStep(findNextStep, navigationCallback),
      // ---
      // ---
      navigatePreviousStep: navigationCallback =>
        navigateStep(findPreviousStep, navigationCallback),
      // ---
      // ---
      updateCurrentStepByRouteName: routeName => {
        if (routeName) {
          const { getCurrentBooking } = get().actions;
          const currentBooking = getCurrentBooking();

          if (!currentBooking) {
            // console.warn(
            //   `[RentalBookingStore][updateCurrentStepDirectlyByRouteName]: No current booking found, cannot update step directly to: ${routeName}`
            // );
            return;
          }

          const currentIndex = findRentalRouteIndex(routeName);
          if (currentIndex === -1) {
            // console.warn(
            //   `[RentalBookingStore][updateCurrentStepDirectlyByRouteName]: No route index found for: ${routeName}`
            // );
            return;
          }

          const currentStep = SortedRentalRegistrationSteps[currentIndex];

          if (currentStep!.storeKey !== currentBooking.currentStep) {
            const updatedBooking = {
              ...currentBooking,
              currentStep: currentStep!.storeKey,
            };

            set({ currentBooking: updatedBooking });
          }
        }
        // else {
        //   console.error(
        //     `[RentalBookingStore][updateCurrentStepDirectlyByRouteName]: No route name found, cannot update step directly`
        //   );
        // }
      },
    },
  };
}, shallow);

export default useRentalBookingStore;

// ============================================================
// -------------- UPDATERS & VALIDATERS --------------
// ============================================================

/**
 * Helper function to update the current booking steps
 * - this is used to update each step of the booking as the user progresses through the flow without having to duplicate the logic
 */
export function currentBookingStepUpdater<T>(
  key: RentalStepStoreKeyNames,
  value: T
): RentalBookingStore['currentBooking'] {
  const { currentBooking } = useRentalBookingStore.getState();
  if (!currentBooking) {
    console.warn(
      `[currentBookingStepUpdater]: No current booking found, cannot update step: ${key}`
    );
    return currentBooking;
  }

  return {
    ...currentBooking,
    steps: {
      ...currentBooking.steps,
      [key]: {
        ...currentBooking.steps[key],
        ...value,
      },
    },
  };
}
