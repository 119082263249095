/* eslint-disable max-len */
// **** File copy/pasted from mobile ***
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import SHARE_DROPIN_SLOT from '../graphql/SHARE_DROPIN_SLOT';
import { ErrorMessageEnum } from '../../../constants/enums';
import { useActionStates } from '../../../hooks';

const useShareDropInSlot = () => {
  const [shareSlotById, { loading, error }] = useMutation(SHARE_DROPIN_SLOT, {
    errorPolicy: 'none',
  });

  type ShareDropInSlotType = {
    shareDropInSlot: {
      _id: string;
      deepLink: string;
    };
  };

  const { setSuccess, setError } = useActionStates({ withAlerts: true });

  /**
   * Asynchronously shares a slot in the game based on the specified slotId.
   * Allows for optional callback functions to handle completion and error scenarios.
   * This function is memoized using `useCallback` and should be used as a
   * stable reference in components.
   *
   * @typedef {Object} OnShareSlotOptions
   * @property {string} slotId - string slotId to share a slot for.
   * @property {function(data: any) => void} onCompleted - Optional callback function to execute after successfully sharing a slot. Receives the returned data from the operation as a parameter.
   * @property {function(error: ApolloError) => void} onError - Optional callback function to execute when an error occurs while sharing a slot. Receives the error as a parameter.
   *
   * @param {OnShareSlotOptions}
   */
  const onShareSlot = useCallback(
    async ({
      slotId = '',
      onCompleted,
      onError,
    }: {
      slotId: string;
      onCompleted?: (data: ShareDropInSlotType) => void;
      onError?: (e: object) => void;
    }) => {
      const variables = {
        id: slotId,
      };

      const handleError = async (e: any) => {
        if (onError) {
          onError(e);
        } else {
          setError(ErrorMessageEnum.DEEPLINK_ERROR);
        }
      };

      const handleCompleted = async (data: any) => {
        if (onCompleted) {
          onCompleted(data);
        } else {
          setSuccess('Deep link created successfully');
        }
      };

      try {
        await shareSlotById({
          variables,
          onCompleted: handleCompleted,
          onError: handleError,
        });
      } catch (e) {
        handleError(e);
      }
    },
    [setError, setSuccess, shareSlotById]
  );

  return {
    onShareSlot,
    loading,
    error,
  };
};

export default useShareDropInSlot;
