import { type TypedDocumentNode, gql } from '@apollo/client';
import { SportSurfaceEnum, type TRentalActivityName } from '../../../../../constants';

type GetRentalTitleInfoData = {
  rental: {
    _id: string;
    activity_name: TRentalActivityName;
    capacity: number;
    venue: {
      _id: string;
      neighborhood: string;
      shorthandName: string;
    };
    surface_type: SportSurfaceEnum;
  };
};

type GetRentalTitleInfoInput = {
  rentalId: string;
};

const GET_RENTAL_TITLE_INFO: TypedDocumentNode<GetRentalTitleInfoData, GetRentalTitleInfoInput> =
  gql`
    query rental($rentalId: ID!) {
      rental(_id: $rentalId) {
        _id
        activity_name
        capacity
        venue {
          _id
          neighborhood
          shorthandName
        }
        surface_type
      }
    }
  `;

export default GET_RENTAL_TITLE_INFO;
