import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  alertContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  alertMessage: {
    marginLeft: 5,
  },
});
