import { useSuspenseQuery } from '@apollo/client';
import type { IStackProps } from '@rivallapp/native-base';
import { Stack } from '../../../../base-components';
import { capitalize } from '../../../../utilities';
import GET_RENTAL_TITLE_INFO from './graphql/GET_RENTAL_TITLE_INFO';
import { RentalHeading, RentalText } from '../RentalCommonText';

type Props = {
  rentalId: string;
  containerStyle?: IStackProps;
};

/**
 * Fetches rental information to populate the title and caption.
 * @important Make sure wrap this component with Suspense and ErrorBoundary!
 */
const RentalTitleWithCaption = ({ rentalId, containerStyle = {} }: Props) => {
  const { data } = useSuspenseQuery(GET_RENTAL_TITLE_INFO, {
    variables: {
      rentalId,
    },
  });

  const {
    activity_name,
    venue: { shorthandName, neighborhood },
    capacity,
    surface_type,
  } = data.rental;

  return (
    <Stack {...containerStyle}>
      <RentalHeading>
        {activity_name} {capitalize(surface_type)}s at {shorthandName}
      </RentalHeading>

      <RentalText>
        {shorthandName} ({neighborhood}) · {capacity} guests per {surface_type}
      </RentalText>
    </Stack>
  );
};

export default RentalTitleWithCaption;
