/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useImperativeHandle } from 'react';

import { useLeagueStandingsQuery } from './graphql/useLeagueStandingsQuery';
import StandingsTableBase, { type StandingsTableBaseProps } from './components/StandingsTableBase';

export type StandingsTableProps = Omit<StandingsTableBaseProps, 'data'> & {
  /**
   * The league ID to query standings for
   */
  leagueId: string;
  /**
   * The division ID to filter standings by
   *
   * Default behavior is to return standings for the entire league.
   */
  divisionId?: string;
};

export type StandingsTableRef = {
  refetch: ReturnType<typeof useLeagueStandingsQuery>['refetch'];
};

/**
 * Standings table (smart) component
 *
 * This handles all of it's own data fetching provided a `leagueId` (and optionally `divisionId`).
 * It also provides a refresh callback through a forwardRef that can be used to to refresh the
 * standings query without the consuming component needing to perform its own query logic.
 */
const StandingsTable = React.forwardRef<StandingsTableRef, StandingsTableProps>(
  ({ leagueId, divisionId, ...props }, ref) => {
    const { data, error, loading, refetch } = useLeagueStandingsQuery({ leagueId, divisionId });

    // For mobile pull to refresh
    useImperativeHandle(
      ref,
      () => ({
        refetch: async (vars: Parameters<typeof refetch>[0]) => {
          const response = await refetch(vars);
          return response;
        },
      }),
      [refetch]
    );

    const standings = data?.leagueStandings || [];

    return <StandingsTableBase data={standings} isLoading={loading} isError={!!error} {...props} />;
  }
);

export default memo(StandingsTable);
