import { useState } from 'react';
import { ErrorMessageEnum } from '../constants/enums';

const useActionStates = ({ withAlerts = false, fallbackErrorMsg = '' } = {}) => {
  const [actionState, setActionState] = useState<{
    updating: boolean;
    error: string | object;
    success: string;
  }>({
    updating: false,
    error: '',
    success: '',
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const errorMessage = (error: string | null | undefined) => {
    if (typeof error === 'string') return error;
    if (!error) return fallbackErrorMsg || ErrorMessageEnum.UNKNOWN_ERROR;
    return error || fallbackErrorMsg || ErrorMessageEnum.UNKNOWN_ERROR;
  };

  const setError = (value: string | null | undefined) => {
    const error = value;
    const message = errorMessage(error) || '';
    if (error && withAlerts) {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage(message);
    }
    setActionState({ updating: false, success: '', error: message });
  };

  const setSuccess = (value: string) => {
    if (typeof value === 'string' && withAlerts) {
      setShowAlert(true);
      setAlertType('success');
      setAlertMessage(value);
    }
    setActionState({ updating: false, error: '', success: value });
  };

  const setUpdating = (value: boolean) =>
    setActionState({
      error: '',
      success: '',
      updating: Boolean(value),
    });

  return {
    setError,
    setSuccess,
    setUpdating,
    showAlert,
    setShowAlert,
    alertMessage,
    alertType,
    ...actionState,
  };
};

export default useActionStates;
