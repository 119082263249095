import { PaymentForm } from '../../custom-components';
import { type PaymentSummaryProps } from './PaymentSummary';
import { Box, Skeleton } from '../../base-components';

const PaymentSummaryLoading = ({
  themeOverride,
}: {
  themeOverride?: PaymentSummaryProps['themeOverride'];
}) => (
  <PaymentForm themeOverride={themeOverride}>
    <Box minH="20">
      <Skeleton h="16" />
      <Skeleton.Text my="1.5" lines={1} />
      <Skeleton.Text my="1.5" lines={1} />
      <PaymentForm.Divider />
      <Skeleton.Text my="1.5" lines={1} />
      <PaymentForm.Divider />
      <Skeleton.Text my="1.5" lines={1} />
    </Box>
  </PaymentForm>
);

export default PaymentSummaryLoading;
