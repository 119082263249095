/* eslint-disable import/prefer-default-export */
const capitalizeFirstLetter = (v: string) =>
  v.length ? `${v[0]!.toUpperCase()}${v.slice(1)}` : '';

export const capitalize = (v: string | null | undefined) => {
  if (!v) return '';

  return v
    .split(' ')
    .map(word => {
      if (
        ['the', 'of', 'is', 'and'].some(exception => exception.toLowerCase() === word.toLowerCase())
      ) {
        return word.toLowerCase();
      }

      return capitalizeFirstLetter(word);
    })
    .join(' ');
};
