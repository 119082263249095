import React from 'react';
import { isMobile } from '../../../utilities';
import { MembershipEnum, MembershipStatus, StripeSubscriptionStatusEnum } from '../../../constants';
import { Modal } from '../../../base-components';
import MemberPerks from './MemberPerks';

export interface MemberPerksModalProps {
  variant: MembershipEnum;
  membershipStatus: string;
  onClose: VoidFunction;
  isOpen: boolean;
}

const MemberPerksModal: React.FC<MemberPerksModalProps> = ({
  variant,
  membershipStatus,
  onClose,
  isOpen,
}) => {
  return (
    <Modal useRNModal size={isMobile ? 'xl' : 'lg'} onClose={onClose} isOpen={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header
          borderBottomWidth={0}
          padding={0} // clear NB default
          paddingLeft={5}
          paddingTop={5}
        >
          {membershipStatus === MembershipStatus[StripeSubscriptionStatusEnum.ACTIVE].label
            ? 'Exclusive Perks'
            : "You're Missing Out On These Perks"}
        </Modal.Header>
        <Modal.Body paddingBottom={6} paddingLeft={5} paddingRight={5}>
          <MemberPerks variant={variant} membershipStatus={membershipStatus} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default MemberPerksModal;
