/* eslint-disable react/no-array-index-key */
import { ScrollView } from 'react-native';

import GridRow from '../components/GridRow';
import type { IGrid } from '../types';

const ScrollViewGrid = ({ data, RenderItem, ...rest }: Omit<IGrid, 'RenderGrid'>) => (
  <ScrollView {...rest}>
    {data.map(row => (
      <GridRow key={row.rowId} items={row.items} RenderItem={RenderItem} />
    ))}
  </ScrollView>
);

export default ScrollViewGrid;
