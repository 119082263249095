import { memo } from 'react';
import { Platform, FlatList } from 'react-native';
// import { FlatList } from 'react-native-gesture-handler';
import moment from 'moment-timezone';

import useCreateDays from './hooks';
import { CalendarSelectorItem, DaySelectorItem, DaySpacer } from './components';
import { useRentalBookingDate } from '../../../store/hooks';

/*
Component Flow:
- Show 4 days + UI Calendar Icon to open modal to select any date
- That selected date would then update the 3 days (first of the 4 is the selected date)

- 4 days are shown in a horizontal scroll view
- Each day is a button that can be selected
- The selected day is highlighted

the 4 days are only updated when a date is selected from the calendar modal
*/

const BookingDateSelector = () => {
  const { startDate, updateDateSelection } = useRentalBookingDate();

  const days = useCreateDays({
    newSelectedDate: startDate,
    initialStartDate: new Date(),
    numDays: Platform.OS === 'web' ? 5 : 4,
  });

  const onSelectDay = (date: Date) => {
    if (moment(startDate).isSame(date, 'day')) {
      updateDateSelection(null);
      return;
    }

    updateDateSelection(date);
  };

  const isSelected = (date: Date) => {
    if (!startDate) return false;
    return moment(startDate).isSame(date, 'day');
  };

  return (
    <FlatList
      horizontal
      showsHorizontalScrollIndicator={Platform.OS === 'web'}
      data={days}
      renderItem={({ item }) => (
        <DaySelectorItem {...item} onPress={onSelectDay} isSelected={isSelected(item.date)} />
      )}
      ItemSeparatorComponent={DaySpacer}
      ListFooterComponent={<CalendarSelectorItem startDate={startDate} onSelectDay={onSelectDay} />}
      keyExtractor={item => item.date.toString()}
    />
  );
};

export default memo(BookingDateSelector);
