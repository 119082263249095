import Svg, { Circle, Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgPetsWelcome = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Circle cx={50} cy={50} r={40} fill="#eaff70" />
    <Path
      fill="#103b5d"
      d="M33.4 31.343c.268 0 .536 0 .858-.053 3.11-.59 5.04-4.236 4.29-8.15-.751-3.915-3.861-6.65-6.971-6.06-1.556.268-2.842 1.287-3.647 2.896-.804 1.501-1.018 3.378-.697 5.255.697 3.539 3.325 6.112 6.167 6.112M26 31.612c-.59-1.716-1.716-3.218-3.11-4.13-1.448-.91-3.003-1.179-4.451-.696-2.896 1.018-4.236 4.826-2.95 8.472.59 1.716 1.717 3.217 3.11 4.129.966.643 1.985.965 2.95.965.483 0 1.019-.107 1.502-.268 2.841-1.019 4.182-4.826 2.949-8.472M46.751 31.29c.268.053.537.053.858.053 2.789 0 5.47-2.573 6.113-6.112.375-1.877.107-3.754-.697-5.255-.804-1.555-2.091-2.574-3.646-2.896-3.11-.59-6.22 2.145-6.971 6.06-.697 3.914 1.233 7.56 4.343 8.15M62.516 26.84c-2.895-.966-6.274 1.179-7.507 4.825s.054 7.4 3.003 8.419c.483.16.965.268 1.501.268.965 0 1.984-.322 2.95-.965 1.394-.912 2.466-2.36 3.11-4.13 1.18-3.645-.161-7.453-3.057-8.418M47.77 36.33c-4.236-3.431-10.349-3.431-14.638 0-5.148 4.13-11.047 10.135-11.047 15.711 0 13.46 36.731 13.46 36.731 0 .054-5.576-5.898-11.582-11.046-15.71"
    />
    <Circle cx={40} cy={40} r={39.5} stroke="#103b5d" />
  </Svg>
);
export default SvgPetsWelcome;
