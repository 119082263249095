import { ActivityEnum, Activities } from './activity-name-enum';

export const EventsFilterList = Object.freeze([
  Activities[ActivityEnum.HAPPY_HOUR].name,
  Activities[ActivityEnum.TRIP].name,
]);

export const ClassesFilterList = Object.freeze([
  Activities[ActivityEnum.YOGA].name,
  Activities[ActivityEnum.BOOTCAMP].name,
  Activities[ActivityEnum.SPIN].name,
]);

export const EventsAndClasses = Object.freeze([
  Activities[ActivityEnum.HAPPY_HOUR].name,
  Activities[ActivityEnum.TRIP].name,
  Activities[ActivityEnum.YOGA].name,
  Activities[ActivityEnum.BOOTCAMP].name,
  Activities[ActivityEnum.SPIN].name,
]);

export type TEventClass = (typeof EventsAndClasses)[number];
