import moment from 'moment-timezone';

import { outerSlotContainerWidth } from '../../../DateSelectorItem/DateSelector.constants';
import { type RentalBlock, type RentalTimeSlot } from '../../../../steps.types';
import {
  gracePeriod,
  timeSlotApiFormat,
  timeSlotFormat,
  timeSlotLabelFormat,
} from '../../../../steps.constants';
import type { GroupedRentalBlocksWithFake, RentalBlockApiObject } from './useRentalTimeSlots';

/**
 * Helper function to clamp the min and max values to be within the 24 hour range
 */
function clampSlotTimeRanges(min: number, max: number) {
  return {
    min: Math.max(0, min),
    max: Math.min(24, max),
  };
}

/**
 * Helper function to check if the selected time is within the grace period
 * - if the selected time is within the grace period, it should be available
 */
export function isCurrentTimeWithinGracePeriod({
  selectedDate,
  startTimeStr,
  gracePeriod,
}: {
  selectedDate: Date | null;
  /**
   * formatted like 'HH:mm'
   */
  startTimeStr: string;
  /**
   * in minutes
   */
  gracePeriod: number;
}): boolean {
  if (!selectedDate || !startTimeStr) return false;

  const startTime = moment(selectedDate)
    .hour(parseInt(startTimeStr.split(':')[0]!, 10))
    .minute(parseInt(startTimeStr.split(':')[1]!, 10));

  // Adjust the start time by adding the grace period
  const startTimeWithGracePeriod = startTime.clone().add(gracePeriod, 'minutes');

  return moment().isBefore(startTimeWithGracePeriod);
}

export interface SlotSpacer {
  id: string;
  isSpacer: boolean;
}

/**
 * Helper function to add spacers to the last row of time slots
 */
export function addSpacers({
  totalSlots,
  rowWidth,
}: {
  totalSlots: number;
  rowWidth: number | undefined;
}) {
  if (!rowWidth) return [];

  const spacers: Array<SlotSpacer> = [];

  const slotsPerRow = Math.floor(rowWidth / outerSlotContainerWidth);
  const lastRowSlots = totalSlots % slotsPerRow;
  const spacersNeeded = lastRowSlots > 0 ? slotsPerRow - lastRowSlots : 0;

  for (let i = 0; i < spacersNeeded; i += 1) {
    spacers.push({
      id: `spacer-${i}`,
      isSpacer: true,
    });
  }

  return spacers;
}
interface BlocksToSlotsConfig {
  /**
   * Amt of time allowed past the start time to keep the start time available
   * - if the current time is within the grace period, then the start time should be available
   */
  gracePeriod: number;
  /**
   * Selected date to show the time slots for
   * - used in conjunction with the grace period to determine if the start time is available
   */
  startDate: Date | null;
}

/**
 * Transforms an array of RentalBlock objects from the API into RentalTimeSlot objects
 * for easier handling client side (UI/store).
 */
export function transformRentalBlocksToTimeSlots(
  rentalBlocks: RentalBlock[],
  config: BlocksToSlotsConfig
): RentalTimeSlot[] {
  const { gracePeriod, startDate } = config;

  return rentalBlocks.map((block, index, self) => {
    // Determine the start time availability based on the current block's availability.
    // - If the current block is available, check if the start time is within the grace period to make sure
    // the start time has not passed yet.
    const isStartTimeAvailable =
      block.has_open_reservations &&
      startDate !== null &&
      isCurrentTimeWithinGracePeriod({
        selectedDate: startDate,
        startTimeStr: block.start_time_str,
        gracePeriod,
      });

    // Determine the end time availability:
    // - if the end time is in the past, then it should not be available.
    const isToday = moment().isSame(startDate, 'day');
    const isEndTimeInFuture = isToday
      ? moment().isBefore(moment(block.start_time_str, timeSlotApiFormat).add(1, 'minutes'))
      : true;

    let isEndTimeAvailable = false;
    const previousBlock = self[index - 1];

    // here are the checks we need to do:
    // we need to look at the previous block, in this scenario:
    // 1-2pm is available, 2-3pm is not available, then 1pm is selected, 2pm should be available to select as the end time, but 3pm should not be available.
    // so if 1pm is showing isStartTimeAvailable, then 2pm should be available as the end time.
    // 3pm should look at 2pm to see if it is available, since in this scenario 2pm is not available, then 3pm should not be available.

    if (block.has_open_reservations && previousBlock?.has_open_reservations) {
      isEndTimeAvailable = isEndTimeInFuture;
    } else if (!block.has_open_reservations && previousBlock?.has_open_reservations) {
      isEndTimeAvailable = isEndTimeInFuture;
    } else if (!block.has_open_reservations && !previousBlock?.has_open_reservations) {
      isEndTimeAvailable = false;
    }

    const [label, ampm] = moment(block.start_time_str, timeSlotApiFormat)
      .format(timeSlotFormat)
      .split(' ');

    const timeSlot: RentalTimeSlot = {
      id: `timeslot=${block.start_time_str}`,
      isPrimeTime: Boolean(block.is_prime_time),
      slotLength: block.timeslot_length,
      time_str: block.start_time_str,
      isStartTimeAvailable,
      isEndTimeAvailable,
      label: label!,
      ampm: ampm!,
      rentalBlock: block,
      connectedSlotIds: [],
      _id: block._id,
    };

    return timeSlot;
  });
}

interface DefaultRentalBlocks {
  startHour: number;
  endHour: number;
  timeSlotLength: number;
  /**
   * Selected date to show the time slots for
   */
  selectedDate?: Date | null;
  /**
   * Condition to determine if the block is prime time
   */
  configurePrimeTime: (block: RentalBlock) => boolean;
  /**
   * Condition to determine if the block is available
   */
  configureAvailability: (block: RentalBlock) => boolean;
}

/**
 * Function to create default rental blocks based on the start hour, end hour, and time slot length
 * - would create _mock_ api data based on the config passed in
 */
export function createDefaultRentalBlocks(config: DefaultRentalBlocks) {
  const { startHour, endHour, timeSlotLength, selectedDate } = config;
  const defaultBlocks: RentalBlock[] = [];

  const clamp = clampSlotTimeRanges(startHour, endHour);
  let currentTime = clamp.min * 60; // Convert start hour to minutes

  function formatTimeString(hour: number, minute: number) {
    return `${hour}:${minute.toString().padStart(2, '0')}`;
  }

  function createBlock({
    startHourPart,
    startMinutePart,
    endHourPart,
    endMinutePart,
  }: {
    startHourPart: number;
    startMinutePart: number;
    endHourPart: number;
    endMinutePart: number;
  }): RentalBlock {
    const startTimeStr = formatTimeString(startHourPart, startMinutePart);
    const endTimeStr = formatTimeString(endHourPart, endMinutePart);

    const block: RentalBlock = {
      _id: `block=${startTimeStr}-${endTimeStr}`,
      capacity: 0,
      start_time_str: startTimeStr,
      end_time_str: endTimeStr,
      timeslot_length: timeSlotLength,
      is_prime_time: false,
      has_open_reservations: true,
      // default to today if no date is selected
      start_date: selectedDate || new Date(),
    };

    return {
      ...block,
      is_prime_time: config.configurePrimeTime(block),
      has_open_reservations: config.configureAvailability(block),
    };
  }

  while (currentTime / 60 <= clamp.max) {
    const startHourPart = Math.floor(currentTime / 60);
    const startMinutePart = currentTime % 60;

    if (startHourPart >= clamp.max) {
      defaultBlocks.push(
        createBlock({ startHourPart, startMinutePart, endHourPart: clamp.max, endMinutePart: 0 })
      );
      break;
    }

    const endTime = currentTime + timeSlotLength;
    const endHourPart = Math.floor(endTime / 60);
    const endMinutePart = endTime % 60;

    defaultBlocks.push(createBlock({ startHourPart, startMinutePart, endHourPart, endMinutePart }));

    currentTime += timeSlotLength;
  }

  return defaultBlocks;
}

/**
 * Transform rental Blocks from the api to rental slots for the UI & rental store
 */
export function rentalBlocksToSlots(
  rentalBlocks: RentalBlockApiObject[] | undefined,
  startDate: Date | null
) {
  if (rentalBlocks?.length === 0 || !rentalBlocks || !startDate) {
    return [];
  }

  // ===============================
  // group slots together based on consecutive times
  // ===============================

  const groupedSlots: Array<RentalBlockApiObject[]> = [];
  const visited = new Set<string>();

  rentalBlocks.forEach((block, index) => {
    if (visited.has(block._id)) return;

    const currentGroup = [block];
    visited.add(block._id);
    let lastEndTime = block.end_time_str;

    // Check for consecutive blocks and group them together
    // meaning 8-9, 9-10, 10-11, etc would be grouped together as one group
    // with overlapping times being in another group: 9:30-10:30, 10:30-11:30, etc
    for (let i = index + 1; i < rentalBlocks.length; i += 1) {
      const nextBlock = rentalBlocks[i]!;
      if (nextBlock.start_time_str === lastEndTime && !visited.has(nextBlock._id)) {
        currentGroup.push(nextBlock);
        visited.add(nextBlock._id);
        lastEndTime = nextBlock.end_time_str;
      }
    }

    groupedSlots.push(currentGroup);
  });

  // ===============================
  // add a 'fake block' to the end of each group to allow the correct end time to be selected
  // ===============================

  const addFakeBlockToEachLastGroup: GroupedRentalBlocksWithFake[][] = groupedSlots.map(group => {
    const lastBlock = group[group.length - 1]!;
    const newBlockStartTime = lastBlock.end_time_str;

    // match API format
    const fakeBlockId = `${lastBlock._id.split('-').slice(0, 2).join('-')}-${newBlockStartTime}`;

    const block: RentalBlockApiObject & { isFakeBlock: true } = {
      ...lastBlock,
      _id: fakeBlockId,
      start_time_str: newBlockStartTime,
      end_time_str: newBlockStartTime,
      has_open_reservations: false,
      isFakeBlock: true,
    };

    return [...group, block];
  });

  // ===============================
  // attach connectedSlotIds to each slot within a group
  // - every slot in the group knows all other slots in the same group
  // - important for handling overlapping slot logic
  // ===============================

  const updatedGroupedSlots = addFakeBlockToEachLastGroup.map(group => {
    const groupIDs = group.map(slot => slot._id);
    return group.map(slot => ({
      ...slot,
      connectedSlotIds: groupIDs,
    }));
  });

  // ===============================
  // flatten the grouped slots and sort them by start time to make sure they are all in order
  // - since the grouped slots can be out of order due to overlapping slots
  // ===============================

  const flattenedSlots = updatedGroupedSlots
    .flat()
    .sort((a, b) =>
      moment(a.start_time_str, timeSlotApiFormat).diff(moment(b.start_time_str, timeSlotApiFormat))
    );

  const rentalSlots: Array<RentalTimeSlot> = [];

  // ===============================
  // create the UI time slots from the now grouped and sorted rental blocks
  // ===============================

  flattenedSlots.forEach(currentBlock => {
    const { start_time_str, end_time_str, has_open_reservations } = currentBlock;

    function createSlot({ time_str = '', isStartTimeAvailable = has_open_reservations }) {
      // end time availability needs to be based on the previous block within the connected slots
      // not just the previous block in the list as we allow overlapping time slots to be created
      function _isEndTimeAvailable() {
        const currentBlockIndexInConnectedSlots = currentBlock.connectedSlotIds.indexOf(
          currentBlock._id
        );
        const previousBlockId =
          currentBlock.connectedSlotIds[currentBlockIndexInConnectedSlots - 1];
        const previousBlock = flattenedSlots.find(slot => slot._id === previousBlockId);

        if (!previousBlock) {
          return false;
        }

        return previousBlock.has_open_reservations;
      }

      function _isStartTimeAvailable() {
        return (
          isStartTimeAvailable &&
          isCurrentTimeWithinGracePeriod({
            selectedDate: startDate,
            startTimeStr: time_str,
            gracePeriod,
          })
        );
      }

      return {
        id: `timeslot=${time_str}`,
        time_str,
        label: moment(time_str, timeSlotApiFormat).format(timeSlotLabelFormat),
        ampm: moment(time_str, timeSlotApiFormat).format('A'),
        isStartTimeAvailable: _isStartTimeAvailable(),
        isEndTimeAvailable: _isEndTimeAvailable(),
        isPrimeTime: Boolean(currentBlock.is_prime_time),
        slotLength: currentBlock.timeslot_length,
        rentalBlock: currentBlock,
        ...currentBlock,
      };
    }

    if (currentBlock?.isFakeBlock) {
      // isStartTimeAvailable is always false for fake blocks - as they are just for UI purposes (ending ranges only)
      rentalSlots.push(createSlot({ time_str: end_time_str, isStartTimeAvailable: false }));
      return;
    }

    // create the start time slot for the current block
    rentalSlots.push(createSlot({ time_str: start_time_str }));
  });

  return rentalSlots;
}
