import { type FC } from 'react';
import { ApolloError } from '@apollo/client';
import { Platform } from 'react-native';

import { ProgramType, ProgramTypeEnum } from '../../../constants/enums';
import { useTheme, Colors } from '../../../theme';
import {
  Banner,
  HaloChip,
  VoloPassBadgeIcon,
  VoloPassYellowBadge,
  VoloFitnessLogoBarbell,
} from '../../../custom-components';
import { View, Text, Image, Row } from '../../../base-components';
import type { TopLeftModule, TopRightModule, BottomLeftModule, BottomRightModule } from './types';
import generateModule from '../modules/generateModule';
import { DiscoverCardWrapper, DiscoverLoading, DiscoverError } from '../components';
import useMobileSizing from '../helpers/useMobileSizing';
import { WhatsADropIn } from '../../index';

/* Assets */
const leagueImage = require('../../../../assets/leagues.jpg');

export type CardPressArgs = {
  programId?: string;
  gameId?: string;
};

type ProgramCardProps = {
  /**
   * only used for drop-ins
   */
  gameId?: string;
  /**
   * used for all non-drop-in programs
   */
  programId?: string;
  /**
   * Program name for header. Saved during Create flow
   */
  displayName: string;
  /**
   * image url for BorderedImage component
   */
  imageUrl: string;
  /**
   * From enum. String value of program type
   */
  programType: ProgramTypeEnum;
  /**
   * league & volunteer program types do not show icon; all other types do
   */
  showVoloPassIcon: boolean;
  /**
   * The Volo Fitness icon is used on class and series programs in organizations that have Volo Fitness
   * Should only appear to users with an active Volo Fitness subscription; otherwise default to Volo Pass rules
   */
  showVoloFitnessIcon: boolean;
  /**
   *
   */
  isVoloPassExclusive?: boolean;
  /**
   *
   */
  isPremier?: boolean;
  /**
   * @param ids gameId and programId
   * @returns takes user to program description/registration
   */
  onCardPress: (ids: CardPressArgs) => void;
  /**
   * Available for all programs except Drop-Ins
   */
  banner_text: string;
  /**
   * neighborhood or venue
   */
  topLeftModule: TopLeftModule;
  /**
   * date or time; null for Volunteer
   */
  topRightModule?: TopRightModule;
  /**
   * date, time, or venue
   */
  bottomLeftModule: BottomLeftModule;
  /**
   * capacity, price, date, or time
   */
  bottomRightModule: BottomRightModule;

  loading?: boolean;
  error?: ApolloError | string;
};

const ProgramCard: FC<ProgramCardProps> = ({
  gameId,
  programId,
  imageUrl,
  showVoloPassIcon = false,
  showVoloFitnessIcon = false,
  isPremier,
  isVoloPassExclusive,
  displayName,
  programType,
  onCardPress,
  banner_text,
  topLeftModule,
  bottomLeftModule,
  topRightModule = undefined,
  bottomRightModule,
  loading = false,
  error = undefined,
}) => {
  const { colors, dark } = useTheme();
  const mobileSizing = useMobileSizing();

  if (loading) {
    return <DiscoverLoading />;
  }

  if (error) {
    return <DiscoverError error={error} />;
  }

  let programLabel: string;

  // label formatting for Volunteer programs
  switch (programType) {
    case ProgramTypeEnum.VOLUNTEER_EVENT:
    case ProgramTypeEnum.VOLUNTEER_LEAGUE:
      programLabel = 'Volunteer';
      break;
    default:
      programLabel = ProgramType[programType].name;
  }

  // for Volunteer programs with empty topRightModule
  const emptyModule = (
    <View flex={1} maxWidth={mobileSizing ? 170 : 250} minHeight={mobileSizing ? 18 : 26} />
  );

  /* module generation */
  const topLeft = generateModule(topLeftModule);
  const bottomLeft = generateModule(bottomLeftModule);
  const topRight = topRightModule ? generateModule(topRightModule) : emptyModule;
  const bottomRight = generateModule(bottomRightModule);

  return (
    <DiscoverCardWrapper
      gameId={gameId}
      programId={programId}
      onCardPress={() => onCardPress({ gameId, programId })}
      flexDirection="row"
    >
      <View
        borderColor={colors.programColors[programType]}
        borderWidth={2}
        overflow="hidden"
        alignItems="stretch"
        marginRight={mobileSizing ? 0 : 15}
        width={mobileSizing ? 75 : 125}
        borderRadius={mobileSizing ? 13 : 4}
        height={mobileSizing ? 70 : 100} // leagues without images get stretched out otherwise
        backgroundColor={colors.programColors[programType]}
      >
        <Image
          resizeMode="cover"
          source={imageUrl ? { uri: imageUrl } : leagueImage}
          alt="Program Cover Image"
          flex={1}
        />
        <Text
          bold={!mobileSizing}
          color={Colors.white}
          paddingY={1}
          numberOfLines={1}
          fontSize={mobileSizing ? 12 : 14}
          paddingLeft={mobileSizing ? 1 : 15}
        >
          {programLabel}
        </Text>
        {showVoloFitnessIcon ? (
          <View
            borderColor={colors.programColors[programType]}
            borderWidth={2}
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            backgroundColor={Colors.white}
            position="absolute"
            right={1}
            overflow="hidden"
            bottom={mobileSizing ? 3 : 2.5}
            width={mobileSizing ? 18 : 30}
            height={mobileSizing ? 18 : 30}
          >
            <VoloFitnessLogoBarbell size={mobileSizing ? 4 : 5} />
          </View>
        ) : null}
      </View>

      <View flex={1} paddingRight={4}>
        <Row justifyContent="space-between">
          <Text
            bold
            marginLeft={2.5}
            fontSize={mobileSizing ? 16 : 18}
            lineHeight={mobileSizing ? 16 : null}
            alignItems="center"
          >
            {displayName}
          </Text>
          {programType === ProgramTypeEnum.DROPIN && (
            <WhatsADropIn
              variant="icon"
              popupBehavior="popover"
              iconProps={{
                colorScheme: 'gray',
                _dark: { colorScheme: 'white' },
                size: mobileSizing ? 4 : 18,
                style: {
                  // This is a little magic number-y. Had trouble getting alignment to cooperate using the typical flex methods
                  transform: [{ translateY: Platform.select({ web: 4, ios: 4, android: 4 }) ?? 0 }],
                },
              }}
            />
          )}
        </Row>

        <View marginTop={1} justifyContent="space-between" flexDirection="row">
          <View
            alignItems="flex-start"
            marginLeft={mobileSizing ? 0 : 2.5}
            marginBottom={mobileSizing ? 0 : 2.5}
          >
            {topLeft}
            {bottomLeft}
          </View>
          <View alignItems="flex-end" marginBottom={mobileSizing ? 0 : 1.5}>
            {topRight}
            {bottomRight}
          </View>
        </View>

        {/* Bottom section with banner & tags */}
        <Row pl={2.5} space={2.5} mt={mobileSizing ? 2.5 : 0} alignItems="center" flexWrap="wrap">
          {showVoloPassIcon && !showVoloFitnessIcon && (
            <View alignItems="center" justifyContent="center">
              <VoloPassBadgeIcon
                colorScheme={dark ? 'white' : 'primary'}
                size={mobileSizing ? 5 : 6}
                mb={1.5}
              />
            </View>
          )}
          {isVoloPassExclusive && (
            <HaloChip mb={1.5} py={1} borderRadius={4} colorScheme="gray">
              <VoloPassYellowBadge size={3.5} mr={1} />
              <HaloChip.Text
                fontSize={mobileSizing ? 10 : 12}
                color={dark ? 'purple.300' : 'purple.900'}
              >
                Member Exclusive
              </HaloChip.Text>
            </HaloChip>
          )}
          {isPremier && (
            <HaloChip
              py={1}
              mb={1.5}
              borderRadius={4}
              backgroundColor={{
                linearGradient: {
                  colors: ['#FAF001', '#FDF885', '#FFFCB2', '#FCF777', '#FFFCB2', '#FAF006'],
                  locations: [0.3, 0.45, 0.57, 0.64, 0.67, 0.84],
                  start: [0, 0],
                  end: [1, 1],
                },
              }}
            >
              <HaloChip.Text fontSize={mobileSizing ? 10 : 12} color="purple.900">
                Premier
              </HaloChip.Text>
            </HaloChip>
          )}
          {banner_text && programType !== ProgramTypeEnum.DROPIN ? (
            <Banner
              mb={1.5}
              py={1}
              paddingX={3}
              borderRadius={4}
              overflow="hidden"
              fontSize={mobileSizing ? 10 : 12}
            >
              {banner_text}
            </Banner>
          ) : null}
        </Row>
      </View>
    </DiscoverCardWrapper>
  );
};

export default ProgramCard;
