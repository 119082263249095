import React from 'react';

/**
 * Utility to check if the provided react node is directly renderable.
 * If this returns `false`, children will need to be wrapped in `Text`.
 *
 * @examples
 * Using the children passed to `Component` as the examples:
 *
 * ```
 * <Component><Button /></Component>
 * <Component><Button /><Select /></Component>
 *
 * childrenAreRenderable(children) -> true
 *
 * <Component>{null}</Component>
 * <Component>{undefined}</Component>
 * <Component>{1 + 1 === 3}</Component>
 *
 * childrenAreRenderable(children) -> true but react doesn't render null/undefined/boolean
 *
 * <Component>Hello World</Component>
 * <Component>{'Hello'} {'World'}</Component>
 * <Component>Hello <Button>World</Button></Component>
 *
 * childrenAreRenderable(children) -> false
 * ```
 */
const childrenAreRenderable = (children: React.ReactNode) =>
  React.Children.toArray(children).every(child => {
    switch (typeof child) {
      case 'object':
      case 'undefined':
      case 'boolean':
        return true;
      default:
        return false;
    }
  });

export default childrenAreRenderable;
