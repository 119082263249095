import { DowNameEnum, DowValueEnum } from '../constants';

/** formatter for the 'HH:mm' strings we are so fond of */
export const militaryToStandardTime = (time: string, showLabel: boolean) => {
  if (!time) return 'error';
  const [hour, min, sec] = time.split(':');
  const parsedHour = parseInt(hour!, 10);
  const formatHour = parsedHour - 12 > 0 ? parsedHour - 12 : parsedHour;
  const period = parsedHour - 12 >= 0 ? 'pm' : 'am';

  const formattedTime = `${formatHour}:${min}${sec ? `:${sec}` : ''}`;

  const final = showLabel ? `${formattedTime}${period}` : formattedTime;
  return final;
};

/** return DOW name from a date value */
export const getDayOfWeekFromDate = (date: Date | string) => {
  // value of 0 (Sunday) to 6 (Saturday)
  const dayValue = new Date(date).getDay();

  const [dayName] = Object.entries(DowValueEnum).find(([, val]) => val === dayValue) ?? [undefined];

  if (!dayName) {
    throw new Error('Unexpected input');
  }

  return DowNameEnum[dayName as keyof typeof DowNameEnum];
};
