import { gql, type TypedDocumentNode } from '@apollo/client';

type MyRsvpsInput = {
  pagination: {
    numPerPage: number;
    pageNum: number;
  };
};

export type MyRsvpsResults = {
  myRsvps: {
    rsvps: {
      dropInSlotId: string;
    }[];
  };
};

export const MY_DROPIN_RSVPS: TypedDocumentNode<MyRsvpsResults, MyRsvpsInput> = gql`
  query myRsvps($pagination: PaginationInput!) {
    myRsvps(pagination: $pagination) {
      rsvps {
        dropInSlotId
      }
    }
  }
`;
