/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { Pressable as NBPressable, type IPressableProps } from '@rivallapp/native-base';
import { Animated, type GestureResponderEvent, type StyleProp, type ViewStyle } from 'react-native';

import { endAnimation, startAnimation } from './animations/touchableAnimations';

const AnimatedPressable = Animated.createAnimatedComponent(NBPressable);

export interface PressableProps extends IPressableProps {
  /**
   * Override the style prop for Pressable which took a cb function that exposed hovered/pressed params
   * - those params are also exposed in util props that we can continue to use if needed
   */
  style?: StyleProp<ViewStyle>;
  /**
   * @platform ios | android
   * @default 1
   */
  startScale?: number;
  /**
   * @platform ios | android
   * @default 0.97
   */
  endScale?: number;
  /**
   * @default 1
   */
  startOpacity?: number;
  /**
   * @default 0.7
   */
  endOpacity?: number;
}

const getDisabledStyles = (isDisabled: boolean) => {
  if (isDisabled) {
    return {
      opacity: 0.5,
    };
  }

  return {};
};

/**
 * Wrapper around NB Pressable for easy UI feedback on mobile & web. (Pressable from NB doesn't seem to give feedback on mobile)
 * - Created `AnimatedPressable` with simple `onPressIn` and `onPressOut` animations to show some feedback on mobile & web together
 * - Override the style prop for Pressable which took a cb function that exposed hovered/pressed params, those params are also exposed in util props that we can continue to use if needed
 * @see {PressableProps}
 */
const Pressable = forwardRef<typeof AnimatedPressable, PressableProps>(
  (
    {
      onPressIn,
      onPressOut,
      style,
      startOpacity,
      endOpacity,
      startScale,
      endScale,
      isDisabled,
      ...rest
    }: PressableProps,
    ref
  ) => {
    const scale = useRef(new Animated.Value(1)).current;
    const opacity = useRef(new Animated.Value(1)).current;

    const handlePressIn = useCallback(
      (e: GestureResponderEvent) => {
        startAnimation({ scale, opacity, endOpacity, endScale });

        if (onPressIn) {
          onPressIn(e);
        }
      },
      [onPressIn, startOpacity, startScale]
    );
    const handlePressOut = useCallback(
      (e: GestureResponderEvent) => {
        endAnimation({ scale, opacity, startOpacity, endOpacity });

        if (onPressOut) {
          onPressOut(e);
        }
      },
      [onPressOut, endOpacity, endScale]
    );

    const animatedStyle = useMemo(
      () => ({
        transform: [{ scale }],
        opacity,
      }),
      []
    );

    return (
      <AnimatedPressable
        {...rest}
        // @ts-ignore - NB is doing something extremely wacky with the ref prop of its pressable
        ref={ref}
        style={[style, animatedStyle, getDisabledStyles(Boolean(isDisabled))]}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        isDisabled={isDisabled}
      />
    );
  }
);

export default Pressable;
