import React from 'react';
import { Path } from 'react-native-svg';

import { Icon } from '../../../base-components';
import { useTheme, type LogoColorVariant } from '../../../theme';
import { type VoloLogoProps, DEFAULT_COLOR } from '../types';

/**
 * Custom icon for displaying the Volo Pass logo. Utilizes `react-native-svg` to construct the image
 * and accepts Native Base theming props. Note that only two `colorscheme`s are provided: `primary`
 * and `white`. Note that this component renders an SVG and not a rasterized image, so be mindful
 * of potential performance considerations where applicable.
 */
const VoloPassLogo: React.FC<VoloLogoProps> = ({ colorScheme, ...props }) => {
  const { colors } = useTheme();
  const logoColor = colorScheme
    ? (colors.logoColors[colorScheme as LogoColorVariant] ?? DEFAULT_COLOR)
    : DEFAULT_COLOR;

  return (
    <Icon {...props} color={logoColor} viewBox="0 0 266 149">
      <Path d="M104.57 19.63H88.34L81.39 34.42H97.62L104.57 19.63Z" />
      <Path d="M113.5 0.599998H97.27L90.33 15.39H106.56L113.5 0.599998Z" />
      <Path d="M66.91 53.45V38.66H52.12H37.3C37.3 46.83 43.92 53.45 52.09 53.45H52.12V96.74H68.35L95.63 38.66H79.4L66.91 65.25V53.45Z" />
      <Path d="M51.41 0.599998H0.630005C0.630005 8.77 7.25001 15.39 15.42 15.39H66.91C66.91 7.22 59.57 0.599998 51.41 0.599998Z" />
      <Path d="M66.91 34.42V19.63H18.61C18.61 27.8 25.23 34.42 33.4 34.42H66.91Z" />
      <Path d="M124.63 38.66H109.53L100.33 81.93C98.59 90.11 103.81 96.73 111.99 96.73H131.15C139.33 96.73 147.36 90.1 149.1 81.93L158.3 38.66H143.2L135.05 76.99C134.5 79.56 131.98 81.64 129.41 81.64H120.13C117.56 81.64 115.92 79.56 116.47 76.99L124.63 38.66Z" />
      <Path d="M162.35 19.63H147.25L144.11 34.42H159.2L162.35 19.63Z" />
      <Path d="M151.59 0.599998H132.43C124.26 0.599998 116.23 7.22 114.48 15.39H163.25C164.98 7.22 159.76 0.599998 151.59 0.599998Z" />
      <Path d="M125.53 34.42L128.67 19.63H113.58L110.43 34.42H125.53Z" />
      <Path d="M192.13 0.599998H176.62L173.48 15.39H188.99L192.13 0.599998Z" />
      <Path d="M196.79 81.23H180C177.35 81.23 175.37 78.78 175.93 76.19L184.04 38.66H168.53L159.33 81.95C157.71 89.57 163.52 96.74 171.31 96.74H171.7H193.65L196.79 81.23Z" />
      <Path d="M188.09 19.63H172.58L169.43 34.42H184.94L188.09 19.63Z" />
      <Path d="M236.9 76.99C236.35 79.56 233.83 81.64 231.26 81.64H221.98C219.41 81.64 217.77 79.56 218.32 76.99L226.47 38.66H211.37L202.17 81.93C200.43 90.11 205.65 96.73 213.83 96.73H233C241.18 96.73 249.21 90.1 250.95 81.93L260.15 38.66H245.05L236.9 76.99Z" />
      <Path d="M245.95 34.42H261.05L264.19 19.63H249.09L245.95 34.42Z" />
      <Path d="M230.52 19.63H215.42L212.27 34.42H227.37L230.52 19.63Z" />
      <Path d="M253.43 0.599998H234.27C226.1 0.599998 218.07 7.22 216.32 15.39H265.09C266.82 7.22 261.6 0.599998 253.43 0.599998Z" />
      <Path d="M31.63 113.4C31.63 114.39 32.43 115.19 33.42 115.19H84.09L84.89 111.61H33.42C32.43 111.61 31.63 112.41 31.63 113.4Z" />
      <Path d="M28.14 129.98C28.14 130.97 28.94 131.77 29.93 131.77H80.6L81.4 128.19H29.93C28.94 128.19 28.14 128.99 28.14 129.98Z" />
      <Path d="M24.65 146.55C24.65 147.54 25.45 148.34 26.44 148.34H77.11L77.91 144.76H26.44C25.45 144.76 24.65 145.56 24.65 146.55Z" />
      <Path d="M116.16 111.89H102.04L94.75 148.09H98.42L101.31 134.58L112.27 134.59C118.99 134.59 123.65 129.06 124.73 123.06C125.78 117.12 122.94 111.89 116.16 111.89ZM120.71 123.01C119.93 127.41 116.62 131.28 112.07 131.28H102.09L105.45 115.2H114.81C119.37 115.2 121.49 118.56 120.71 123.01Z" />
      <Path d="M191.05 114.63C195.14 114.62 198.08 117.51 198.24 120.52L201.76 118.82C201.44 115.62 197.63 111.01 191.47 111.01C185.37 111.01 179.26 114.99 178.18 121.09C177.61 124.19 177.72 127.76 184.13 130.04L190.23 132.31C193.13 133.29 195.56 135.36 194.99 138.67C194.37 142.24 190.95 145.03 185.68 145.03C177.61 145.03 176.63 140.95 176.27 138L172.6 139.6C173.12 144.15 175.63 148.65 184.8 148.65C192.99 148.65 197.78 144.1 198.82 138.1C199.54 133.96 197.42 130.81 192.4 129.15L185.99 126.77C181.65 125.12 181.7 122.79 182.01 120.98C182.72 117.17 185.99 114.65 191.05 114.63Z" />
      <Path d="M230.1 114.63C234.19 114.62 237.13 117.51 237.29 120.52L240.81 118.82C240.49 115.62 236.68 111.01 230.52 111.01C224.42 111.01 218.31 114.99 217.23 121.09C216.66 124.19 216.77 127.76 223.18 130.04L229.28 132.31C232.18 133.29 234.61 135.36 234.04 138.67C233.42 142.24 230 145.03 224.73 145.03C216.66 145.03 215.68 140.95 215.32 138L211.65 139.6C212.17 144.15 214.68 148.65 223.85 148.65C232.04 148.65 236.83 144.1 237.87 138.1C238.59 133.96 236.47 130.81 231.45 129.15L225.04 126.77C220.7 125.12 220.75 122.79 221.06 120.98C221.78 117.17 225.04 114.65 230.1 114.63Z" />
      <Path d="M148.95 111.89L127.39 148.09H131.27L136.49 139.21H153.52L155.01 148.09H158.84L152.78 111.89H148.95ZM138.55 135.72L149.74 116.7L152.94 135.72H138.55Z" />
    </Icon>
  );
};

export default VoloPassLogo;
