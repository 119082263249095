import { gql, type TypedDocumentNode } from '@apollo/client';

export type CurrentUserStrikeResults = {
  currentUser: {
    _id: string;
    isVoloPassMember: boolean;
    player_strikes: {
      count: number;
    };
  };
};

export const CURRENT_USER_STRIKES: TypedDocumentNode<CurrentUserStrikeResults> = gql`
  query currentUser {
    currentUser {
      _id
      roles
      isVoloPassMember
      player_strikes {
        count
      }
    }
  }
`;
