import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {},
  subItemRow: {
    marginLeft: 25,
  },
});

export default styles;
