/* eslint-disable react/destructuring-assignment */
import React, { Suspense, useState } from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import usePlayerStrikeCount from './hooks/usePlayerStrikeCount';
import { isMobile } from '../../utilities';
import {
  Box,
  Button,
  FontAwesomeIcon,
  IconButton,
  Modal,
  Row,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '../../base-components';
import EditStrikes from './components/EditStrikes';
import StrikeHistory from './components/StrikeHistory';

const DateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'long',
});

export type PlayerStrikesProps =
  | {
      // Admin Variant props
      variant: 'admin';
      userId: string;
    }
  | {
      // Player variant props
      variant: 'player';
    };

const PlayerStrikes: React.FC<PlayerStrikesProps> = props => {
  const { variant } = props;
  const [modalType, setModalType] = useState<'edit-strikes' | 'see-history' | 'tooltip-warning'>();

  const { nextStrikeReset, strikeCount, isVoloPassMember, nextStrikeWillTriggerFee } =
    usePlayerStrikeCount(props);

  return (
    <Box>
      <Row alignItems="center" space="2">
        <Text fontWeight={700} fontSize="xl">
          {variant === 'player' ? 'Current ' : ''}Late Cancellations/No Shows
          {isVoloPassMember ? ' Strikes' : ''}: {strikeCount}
        </Text>
        {nextStrikeWillTriggerFee && (
          // The markup looks slightly cleaner with the fragment
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {isMobile ? (
              <IconButton
                size="md"
                padding="0"
                icon={<FontAwesomeIcon icon={faCircleExclamation} />}
                colorScheme="red"
                borderRadius="full"
                onPress={() => setModalType('tooltip-warning')}
              />
            ) : (
              <Tooltip
                label={`Since ${
                  variant === 'player' ? 'you have' : 'this player has'
                } cancelled 2 or more times, ${
                  variant === 'player' ? 'you' : 'they'
                } will be charged $10 on ${
                  variant === 'player' ? 'your' : 'their'
                } next late cancellation.`}
              >
                {/* The tooltip doesn't render when hovering over an SVG, but wrapping it around an empty div seems to work */}
                <Box>
                  <FontAwesomeIcon icon={faCircleExclamation} colorScheme="red" />
                </Box>
              </Tooltip>
            )}
          </>
        )}
      </Row>
      <Text fontWeight={400} fontSize="sm" lineHeight="xs">
        Note: Strike count will automatically reset on{' '}
        {DateFormatter.format(new Date(nextStrikeReset))}
      </Text>

      {isMobile && (
        <Modal
          isOpen={modalType === 'tooltip-warning'}
          onClose={() => setModalType(undefined)}
          useRNModal
        >
          <Modal.Content>
            <Modal.Header>Active Strikes</Modal.Header>
            <Modal.CloseButton />
            <Modal.Body>
              <Text>
                Since {variant === 'player' ? 'you have' : 'this player has'} cancelled 2 or more
                times, {variant === 'player' ? 'you' : 'they'} will be charged $10 on{' '}
                {variant === 'player' ? 'your' : 'their'} next late cancellation.
              </Text>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}

      {variant === 'admin' && (
        <>
          <Stack
            direction={{
              // @ts-expect-error - This is a valid breakpoint
              xsm: 'column',
              sm: 'row',
            }}
            space={2}
            marginY="3"
          >
            <Button colorScheme="lightBlue" flex={1} onPress={() => setModalType('edit-strikes')}>
              Edit Strikes
            </Button>
            <Button
              colorScheme="lightBlue"
              variant="outline"
              borderWidth="2"
              borderColor="lightBlue.600"
              borderRadius="lg"
              _text={{
                fontWeight: 700,
                fontSize: 'lg',
              }}
              flex={1}
              onPress={() => setModalType('see-history')}
            >
              See History
            </Button>
          </Stack>

          {/* Edit Strikes */}
          <Modal
            isOpen={modalType === 'edit-strikes'}
            onClose={() => setModalType(undefined)}
            useRNModal
            zIndex={-1}
          >
            <Modal.Content zIndex={-1}>
              <Modal.Header>Edit Active Strikes</Modal.Header>
              <Modal.CloseButton />
              <Modal.Body zIndex={-1}>
                <EditStrikes
                  userId={props.userId}
                  currentStrikeCount={strikeCount}
                  onSave={() => setModalType(undefined)}
                />
              </Modal.Body>
            </Modal.Content>
          </Modal>

          {/* Strike History */}
          <Modal
            isOpen={modalType === 'see-history'}
            onClose={() => setModalType(undefined)}
            size="2xl"
            useRNModal
          >
            <Modal.Content maxH="90%">
              <Modal.Header>Late Cancellations + No Show History</Modal.Header>
              <Modal.CloseButton />
              <Modal.Body overflowX="scroll">
                <StrikeHistory userId={props.userId} />
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </>
      )}
    </Box>
  );
};

export const PlayerStrikesLoading: React.FC<PlayerStrikesProps> = ({ variant }) => (
  <Box>
    <Skeleton.Text lines={1} _line={{ h: '5' }} my="2" />
    <Skeleton.Text lines={1} />
    {variant === 'admin' && (
      <Stack
        direction={{
          // @ts-expect-error - This is a valid breakpoint
          xsm: 'column',
          sm: 'row',
        }}
        space={2}
        marginY="3"
      >
        <Box flex={1}>
          <Skeleton borderRadius="md" />
        </Box>
        <Box flex={1}>
          <Skeleton borderRadius="md" />
        </Box>
      </Stack>
    )}
  </Box>
);

export const PlayerStrikesError: React.FC<FallbackProps> = ({ resetErrorBoundary }) => (
  <Box>
    <Text fontWeight={700} fontSize="xl">
      There was an error fetching late cancellation and no-show player strikes.
    </Text>
    <Text fontWeight={400} fontSize="sm" lineHeight="xs">
      Try again later.
    </Text>
    <Button marginY="3" colorScheme="lightBlue" onPress={() => resetErrorBoundary()}>
      Retry
    </Button>
  </Box>
);

const WrappedPlayerStrikes: React.FC<PlayerStrikesProps> = props => (
  <ErrorBoundary FallbackComponent={PlayerStrikesError}>
    <Suspense fallback={<PlayerStrikesLoading {...props} />}>
      <PlayerStrikes {...props} />
    </Suspense>
  </ErrorBoundary>
);

export default WrappedPlayerStrikes;
