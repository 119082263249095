import { StyleSheet, Platform, Dimensions } from 'react-native';

const isWeb = Platform.OS === 'web';
const { width } = Dimensions.get('window');

export default StyleSheet.create({
  overlay: {
    padding: 15,
    maxWidth: 800,
  },
  modal: {
    justifyContent: isWeb ? 'flex-start' : 'center',
    marginTop: isWeb ? 100 : 0,
  },
  skeleton: {
    marginTop: 18,
    height: 60,
    marginBottom: 10,
    width: isWeb ? 345 : width - 30,
  },
});
