import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgWalkingDistanceToBar = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none" {...props}>
    <Path
      fill="#eaff70"
      d="M50 90c22.091 0 40-17.909 40-40S72.091 10 50 10 10 27.909 10 50s17.909 40 40 40"
    />
    <Path
      stroke="#0a3355"
      d="M40 79.5c21.815 0 39.5-17.685 39.5-39.5S61.815.5 40 .5.5 18.185.5 40 18.185 79.5 40 79.5Z"
    />
    <Path
      fill="#0a3355"
      stroke="#0a3355"
      d="M56.5 25a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0ZM51.63 41.296v.002a.93.93 0 0 0 .3 1.258c.142.088.298.13.45.13a.87.87 0 0 0 .752-.438l4.324-7.33.693-1.175.23 1.344.093.543.002.007v.007l.566 3.984.227 1.596c.098.699-.085 1.336-.324 1.9-.12.281-.26.556-.392.816l-.037.07c-.122.238-.237.463-.339.688-.752 1.661-1.428 3.324-2.109 4.997l-.002.005c-.678 1.67-1.36 3.348-2.122 5.028-.22.488-.203.861-.102 1.12.104.263.316.464.588.57.529.208 1.284.05 1.681-.828l.639-1.41q.496-1.098.993-2.195l3.522-7.784.682-1.508.267 1.633c.096.587.165 1.218.233 1.85l.008.069c.072.66.145 1.325.252 1.973.214 1.306.553 2.478 1.219 3.346l3.141 4.096c.613.799 1.458.822 1.964.514a.94.94 0 0 0 .46-.655c.042-.267-.025-.646-.38-1.11a45 45 0 0 1-1.645-2.332l-.071-.106c-.494-.735-.98-1.46-1.527-2.19-.145-.192-.245-.451-.323-.707a9 9 0 0 1-.211-.92 35 35 0 0 1-.307-2.263c-.408-3.667-.81-5.885-1.234-8.227v-.004c-.186-1.02-.375-2.065-.57-3.263l-.216-1.32 1.028.855 1.886 1.566.144.12.03.183c.13.787.283 1.488.438 2.202v.005c.155.71.312 1.433.447 2.246.047.284.127.598.252.868.127.274.28.456.445.548.416.23.757.19.947.069.173-.111.348-.371.263-.887l-.455-2.742-.209-1.264a14 14 0 0 1-.09-.712l-.021-.179c-.035-.31-.075-.62-.136-.913-.128-.608-.326-1.02-.63-1.221-.66-.439-1.423-1.051-2.19-1.666l-.005-.004a68 68 0 0 0-1.306-1.03c-.598-.455-1.17-.858-1.696-1.15q-.802-.444-1.298-.466c-1.831-.08-2.8.811-3.258 1.631l-.005.01z"
    />
    <Path
      stroke="#0a3355"
      d="M24.583 32.862a2.181 2.181 0 1 0 0-4.362 2.181 2.181 0 0 0 0 4.362Z"
    />
    <Path fill="#fff" d="M14 30.822h10.442v1.412l-1.074 1.074-4.147 4.147-4.147-4.147L14 32.233z" />
    <Path
      stroke="#0a3355"
      d="M15.074 33.308h8.294m-4.147 4.147v4.374zm0 0-4.147-4.147zm0 0 4.147-4.147zm3.669 4.374H15.41zm-7.816-8.521L14 32.233v-1.41h10.442v1.41l-1.074 1.075z"
    />
    <Path
      stroke="#0a3355"
      d="M20 46.5c6.351 0 11.5-5.149 11.5-11.5S26.351 23.5 20 23.5 8.5 28.649 8.5 35 13.649 46.5 20 46.5Z"
    />
    <Path fill="#0a3355" stroke="#0a3355" d="M10.5 41.5 20 56l9.5-14.5C24 48 16 48 10.5 41.5Z" />
    <Path stroke="#0a3355" strokeDasharray="2 2" d="M20 56.5h35" />
  </Svg>
);
export default SvgWalkingDistanceToBar;
