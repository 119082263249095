import { memo } from 'react';
import { type StyledProps, View as NView } from '@rivallapp/native-base';
import { type ViewProps } from 'react-native';

import { useTheme } from '../../theme';

/**
 * A container component that applies the background color of the current theme.
 * - this can be overridden by passing a style prop, but this is not recommended, as it will break the usage of the theme.
 * - utilize the `Card` component if you need a container with a background color that is different from the current theme.
 * @defaults `bgColor={background}` | `padding={2}` | `overflow="hidden"`
 */
const Container = (props: ViewProps & StyledProps) => {
  const {
    colors: { background },
  } = useTheme();

  return <NView bgColor={background} overflow="hidden" padding={2} {...props} />;
};

export default memo(Container);
