import { useTransition, useState } from 'react';
import type { Pricing } from '../PaymentSummary';

/**
 * Helper function to handle complexity with most of the registration logic being on multiple
 * platforms. Returns props to be passed into `PaymentSummary`, but the data can be accessed
 * outside the component as well.
 *
 * If more of the Registration logic is ported to SCL, this hook wouldn't really need to exist, so
 * this should be thought of as temporary as we move things over.
 */
const usePaymentSummary = () => {
  const [, startTransition] = useTransition();
  const [pricingSelected, setPricingSelected] = useState<Pricing>('REGULAR');
  const [areMemberTermsAgreed, setAreMemberTermsAgreed] = useState(false);
  const [userIsDonatingRegistrationCost, setUserIsDonatingRegistrationCost] = useState(true);

  return {
    pricingSelected,
    setPricingSelected: (...args: Parameters<typeof setPricingSelected>) =>
      startTransition(() => setPricingSelected(...args)),

    areMemberTermsAgreed,
    setAreMemberTermsAgreed: (...args: Parameters<typeof setAreMemberTermsAgreed>) =>
      startTransition(() => setAreMemberTermsAgreed(...args)),

    userIsDonatingRegistrationCost,
    setUserIsDonatingRegistrationCost: (
      ...args: Parameters<typeof setUserIsDonatingRegistrationCost>
    ) => startTransition(() => setUserIsDonatingRegistrationCost(...args)),
  };
};

export default usePaymentSummary;
