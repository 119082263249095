import { useQuery } from '@apollo/client';
import GET_PROGRAM_PRICING, {
  type ProgramPricingInput,
  type ProgramPricingResponse,
} from '../graphql/GET_PROGRAM_PRICING';
import type { DiscoverProgramCardProps } from '../DiscoverProgramCard';

const useProgramPricing = ({ programId }: Pick<DiscoverProgramCardProps, 'programId'>) => {
  const {
    data: pricingData,
    loading: pricingLoading,
    error: pricingError,
  } = useQuery<ProgramPricingResponse, ProgramPricingInput>(GET_PROGRAM_PRICING, {
    skip: !programId,
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        leagueId: programId!,
      },
    },
  });

  const { programPricingForRegistration } = pricingData || {};
  const pricingBreakdown = programPricingForRegistration?.pricingBreakdown;
  const {
    originalPrice = 0,
    originalPriceWithFees = 0,
    memberPrice,
    voloPassPriceWithFees,
    voloFitnessPrice,
    voloFitnessPriceWithFees,
  } = pricingBreakdown ?? {};

  return {
    pricingLoading,
    pricingError,
    originalPrice,
    originalPriceWithFees,
    memberPrice,
    voloPassPriceWithFees,
    voloFitnessPrice,
    voloFitnessPriceWithFees,
  };
};

export default useProgramPricing;
