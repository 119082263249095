import { useCallback } from 'react';
import { useTheme } from '../../theme';
import { adjustColorOnDark } from '../../utilities';
import { Text, Pressable, View } from '../../base-components';
import styles from './styles';

type TabHeaderType = {
  values: Array<string>;
  selectedValue?: string;
  onSwitch: (arg: string) => void | undefined;
};

const TabHeader = ({ values = [], selectedValue, onSwitch }: TabHeaderType) => {
  const { colors, dark } = useTheme();

  const handlePress = useCallback(
    (index: number) => {
      // TODO: There might be a better default for this
      onSwitch(values[index] ?? '');
    },
    [values, onSwitch]
  );

  const isSelected = (v: string) => v === selectedValue;

  const getTextProps = (v: string) => {
    return isSelected(v)
      ? { color: colors.accents.select.text, fontWeight: 'bold' }
      : { color: adjustColorOnDark(dark, colors.border) };
  };

  const getBackground = (v: string) => ({
    backgroundColor: isSelected(v) ? colors.accents.select.bg : colors.border,
  });

  // border radius only on first and last

  const radioValues = values.map((v, index) => {
    const isFirstValue = index === 0;
    const isLastValue = index === values.length - 1;
    const borderRadiusLeft = isFirstValue ? 3 : 0;
    const borderRadiusRight = isLastValue ? 3 : 0;

    return (
      <Pressable
        key={v}
        onPress={() => handlePress(index)}
        style={[
          styles.tabLabel,
          getBackground(v),
          {
            borderTopLeftRadius: borderRadiusLeft,
            borderBottomLeftRadius: borderRadiusLeft,
            borderTopRightRadius: borderRadiusRight,
            borderBottomRightRadius: borderRadiusRight,
          },
        ]}
      >
        <Text {...getTextProps(v)}>{v}</Text>
      </Pressable>
    );
  });

  return <View style={styles.tabContainer}>{radioValues}</View>;
};

export default TabHeader;
