import { skipToken, useSuspenseQuery } from '@apollo/client';
import { Suspense, useCallback, useState } from 'react';
import {
  View,
  type IViewProps,
  Skeleton,
  TouchableOpacity,
  Modal,
  type TouchableOpacityProps,
} from '../../../../base-components';
import { type RentalVenueExclusiveProps } from '../../types';
import { RenderHTML } from '../../../../custom-components';
import GET_RENTAL_POLICIES from './graphql/GET_RENTAL_POLICIES';
import GET_RENTAL_POLICIES_FROM_VENUE from './graphql/GET_RENTAL_POLICIES_FROM_VENUE';
import RentalCollapsible from '../RentalCollapsible';
import { RentalHeading, RentalTextSm } from '../RentalCommonText';

type Props = {
  /**
   * Text to display as the title of the policies section.
   * Only valid for `collapsible` and `modal` variants.
   */
  title?: string;
  /**
   * The type of display for the policies.
   * - `collapsible`: Policies are displayed in a collapsible section
   * - `modal`: Policies are displayed in a modal
   * - `default`: Policies are displayed as is
   */
  variant: 'collapsible' | 'modal' | 'default';
  /**
   * Style for the container of the policies.
   * Not valid for `modal` variant.
   */
  containerStyle?: IViewProps;
  /**
   * Style for the touchable opacity that opens the modal.
   * Only valid for `modal` variant.
   */
  modalTouchableOpacityStyle?: TouchableOpacityProps['style'];
} & RentalVenueExclusiveProps;

const RentalPolicies = ({
  rentalId,
  venueId,
  title = 'Venue Policies',
  variant = 'default',
  containerStyle = {},
  modalTouchableOpacityStyle = {},
}: Props) => {
  const { data: rentalData } = useSuspenseQuery(
    GET_RENTAL_POLICIES,
    rentalId
      ? {
          variables: {
            rentalId,
          },
        }
      : skipToken
  );

  const { data: venueData } = useSuspenseQuery(
    GET_RENTAL_POLICIES_FROM_VENUE,
    venueId
      ? {
          variables: {
            venueId,
          },
        }
      : skipToken
  );

  const [showPolicies, setShowPolicies] = useState(false);

  // all rentals in a venue are meant to have the same `venue_policies`
  // it is possible for an admin to create a rental for an existing venue with different policies but that's frowned upon
  // in any case, policies are required which is why we have the assertion
  const venuePolicies = (rentalData?.rental.venue_policies ||
    venueData?.venue.rentals?.[0]?.venue_policies)!;

  switch (variant) {
    case 'collapsible':
      return (
        <RentalCollapsible isCollapsed={false} title={title}>
          <View {...containerStyle}>
            <RenderHTML html={venuePolicies} />
          </View>
        </RentalCollapsible>
      );
    case 'modal':
      return (
        <>
          <TouchableOpacity
            style={modalTouchableOpacityStyle}
            onPress={() => setShowPolicies(true)}
          >
            <RentalTextSm isLink>{title}</RentalTextSm>
          </TouchableOpacity>
          <Modal size="lg" isOpen={showPolicies} onClose={() => setShowPolicies(false)} useRNModal>
            <Modal.Content>
              <Modal.Header>
                <RentalHeading>{title}</RentalHeading>
              </Modal.Header>
              <Modal.CloseButton />
              <Modal.Body>
                <RenderHTML html={venuePolicies} />
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </>
      );
    default:
      return (
        <View {...containerStyle}>
          <RenderHTML html={venuePolicies} />
        </View>
      );
  }
};

/**
 * Wrapping this one in Suspense since it's called in `RentalInfoGrid`
 */
const SuspenseRentalPolicies = (props: Props) => {
  const { variant } = props;

  const SkeletonVariant = useCallback(() => {
    switch (variant) {
      case 'collapsible':
      case 'modal':
        return <Skeleton.Text lines={1} />;
      default:
        return <Skeleton.Text lines={5} />;
    }
  }, [variant]);

  return (
    <Suspense fallback={<SkeletonVariant />}>
      <RentalPolicies {...props} />
    </Suspense>
  );
};

export default SuspenseRentalPolicies;
