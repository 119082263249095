import { type IBoxProps, useColorModeValue } from '@rivallapp/native-base';

import { type HaloChipVariant } from '../HaloChip';

/**
 * Hook to adjust the colors of the HaloChip based on the backgroundColor prop
 * - lightens the backgroundColor for the opaque background
 */
const useHaloChipColors = ({
  color,
  colorScheme,
  variant,
  opacity,
}: {
  color?: IBoxProps['color'];
  colorScheme?: IBoxProps['colorScheme'];
  variant: HaloChipVariant;
  opacity: IBoxProps['opacity'];
}) => {
  // default color that is lighter/darker based on the colorMode
  const defaultColor: IBoxProps['color'] = useColorModeValue(
    `${colorScheme || variant}.500`,
    `${colorScheme || variant}.300`
  );

  // if color is passed, use it directly as it was specifically set
  if (color) {
    return {
      borderColor: color,
      textColor: color,
      absoluteViewProps: {
        backgroundColor: color,
        opacity,
      },
    };
  }

  // if no color is passed in, use the default color based on the colorScheme or variant
  return {
    borderColor: defaultColor,
    textColor: defaultColor,
    absoluteViewProps: {
      backgroundColor: defaultColor,
      opacity,
    },
  };
};

export default useHaloChipColors;
