import { useMutation, useSuspenseQuery } from '@apollo/client';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import type { IBoxProps } from '@rivallapp/native-base';
import { type FC, useState } from 'react';
import {
  Box,
  Divider,
  FontAwesomeIcon,
  FormControl,
  Heading,
  Row,
  Switch,
  TextInput,
  Tooltip,
} from '../../../base-components';
import {
  RENTAL_DETAILS,
  UPDATE_RENTAL_BANNER_MESSAGE,
  UPDATE_RENTAL_DISCOVERABILITY,
  UPDATE_RENTAL_FEATURED_ORDER,
} from '../gql';

interface ProgramRegistrationProps {
  rental_id: string;
  _container?: IBoxProps;
}

const ProgramRegistration: FC<ProgramRegistrationProps> = ({ rental_id, _container }) => {
  const { data } = useSuspenseQuery(RENTAL_DETAILS, {
    variables: {
      id: rental_id,
    },
  });

  const [updateRentalDiscoverability] = useMutation(UPDATE_RENTAL_DISCOVERABILITY);
  const [updateRentalBannerMessage] = useMutation(UPDATE_RENTAL_BANNER_MESSAGE);
  const [updateRentalFeaturedOrder] = useMutation(UPDATE_RENTAL_FEATURED_ORDER);

  const { rental } = data;
  const { is_discoverable, banner_message, has_available_timeslots, featured_order } = rental;

  const [message, setMessage] = useState(banner_message);
  const [order, setOrder] = useState(String(featured_order ?? ''));
  const [error, setError] = useState('');

  const onToggle = async (value: boolean) => {
    await updateRentalDiscoverability({
      variables: {
        input: {
          rental_id,
          is_discoverable: value,
        },
      },
    });
  };

  const onChangeBannerMessage = (value: string) => {
    setMessage(value);
  };

  const onBlurBannerMessage = async () => {
    await updateRentalBannerMessage({
      variables: {
        input: {
          rental_id,
          banner_message: message!,
        },
      },
    });
  };

  const onChangeFeaturedOrder = (value: string) => {
    setOrder(value);
    setError('');
  };

  const onBlurFeaturedOrder = async () => {
    const parsedFeaturedOrder = parseFloat(order);
    if (parsedFeaturedOrder <= 0) {
      setError('Value must be greater than zero');
    } else if (!Number.isInteger(parsedFeaturedOrder) && order !== '') {
      setError('Value must be a whole number');
    } else if (Number.isNaN(parsedFeaturedOrder) && order !== '') {
      setError('Value must be a number');
    } else if (!Number.isNaN(parsedFeaturedOrder) || order === '') {
      await updateRentalFeaturedOrder({
        variables: {
          input: {
            rental_id,
            featured_order: parsedFeaturedOrder,
          },
        },
      });
    }
  };

  return (
    <Box bg="white.600" borderRadius="3" p="4" {..._container}>
      <Heading fontSize="lg" fontWeight="normal">
        {has_available_timeslots ? 'Rental slots available' : 'All rental slots booked'}
      </Heading>
      <Divider my="4" />
      <Row justifyContent="space-between" alignItems="center">
        <FormControl maxW="1/2">
          <Row alignItems="center" minW="56">
            <Switch value={is_discoverable} onToggle={onToggle} />
            <FormControl.Label mx="2">Is discoverable</FormControl.Label>
            {/* Issue with NB tooltip, it won't show unless there's another component wrapping FontAwesomeIcon ☹️ */}
            <Tooltip
              label={
                is_discoverable
                  ? 'Program is listed on the discover page.'
                  : 'Program is not on the discover page. This does not impact ability to register.'
              }
              placement="top"
            >
              <Box>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Box>
            </Tooltip>
          </Row>
        </FormControl>
        <FormControl maxW="1/2" isInvalid={!!error}>
          <FormControl.Label>Featured League Order</FormControl.Label>
          <TextInput
            placeholder="position #"
            onChangeText={onChangeFeaturedOrder}
            onBlur={onBlurFeaturedOrder}
            value={order}
          />
          <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>
        </FormControl>
      </Row>
      <Divider my="4" />
      <FormControl>
        <TextInput
          placeholder="Banner message on league"
          maxLength={100}
          onChangeText={onChangeBannerMessage}
          onBlur={onBlurBannerMessage}
          value={message}
        />
      </FormControl>
      <Divider my="4" />
    </Box>
  );
};

export default ProgramRegistration;
