export enum ProgramModuleTypes {
  NEIGHBORHOOD = 'NEIGHBORHOOD',
  VENUE = 'VENUE',
  TIME = 'TIME',
  DATE = 'DATE',
  CAPACITY = 'CAPACITY',
  PRICING = 'PRICING',
}

export enum RentalModuleTypes {
  NEIGHBORHOOD_VENUE = 'NEIGHBORHOOD_VENUE',
  CAPACITY_COURTS_NUMBER = 'CAPACITY_COURTS_NUMBER',
  RENTAL_PRICING = 'RENTAL_PRICING',
  RENTAL_SPORTS = 'RENTAL_SPORTS',
  SPORT_DISPLAY_NAME = 'SPORT_DISPLAY_NAME',
  VENUE_DISPLAY_NAME = 'VENUE_DISPLAY_NAME',
}
