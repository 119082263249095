import { Row, Spinner, Stack } from '../../../../../base-components';
import { RentalErrorComponent, RentalSubtext } from '../../../common/components';
import useRentalPricing from '../../../hooks/useRentalPricing';
import { useRentalBookingDate } from '../../../store';

/**
 * Component that displays the default pricing on the Rental.
 * - Fetches the default pricing for the rental
 * - Displays the standard and prime time pricing
 */
const DefaultRentalPricing = () => {
  const { rentalId, startDate } = useRentalBookingDate();

  const {
    estimatedPricing: {
      formattedCurrentUserPricingString,
      formattedCurrentUserPrimeTimePricingString,
    },
    loading,
    error,
    refetch,
  } = useRentalPricing('estimated-rental-pricing', {
    rentalId,
    selectedDate: startDate,
    formatterType: 'whole_dollar',
    // biz requirement to always show non member pricing for the default pricing
    pricingMemberType: 'non_member',
  });

  if (error) {
    return (
      <RentalErrorComponent
        error={error}
        fallBackMessage="Unable to fetch pricing at this time. Please try again."
        refetch={refetch}
      />
    );
  }

  return (
    <Row justifyContent="space-between" alignItems="center">
      <Stack>
        <RentalSubtext type={undefined}>{`Standard Pricing: ${
          formattedCurrentUserPricingString || '--'
        } per hour`}</RentalSubtext>
        <RentalSubtext type={undefined}>{`Prime Time Pricing: ${
          formattedCurrentUserPrimeTimePricingString || '--'
        } per hour`}</RentalSubtext>
      </Stack>
      {loading ? <Spinner /> : null}
    </Row>
  );
};

export default DefaultRentalPricing;
