import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  View,
  Easing,
  FlatList,
  type ListRenderItem,
  Animated,
  ActivityIndicator,
} from 'react-native';

import CustomCheckbox from '../CustomCheckbox';
import { flattenMapData, getMainTabsMap, toggleArrayItem } from './helpers';

import styles from './styles';
import type { FlattenedMapData } from './helpers/flattenMapData';

type DataItemType = {
  value: string;
  label: string;
};

export type ExpandableCheckBoxTreeProps = {
  label: string;
  value: string;
  isMain?: boolean;
  subTree?: DataItemType[];
};

type Props = {
  data: ExpandableCheckBoxTreeProps[];
  loading?: boolean;
  mainItemsChecked?: string[];
  subItemsChecked?: string[];
  onPressItem?: (toggledMainArray: string[], toggledSubArray: string[]) => void;
};

const ExpandableCheckBoxTree: React.FC<Props> = ({
  data = [],
  loading = false,
  mainItemsChecked = [],
  subItemsChecked = [],
  onPressItem,
}) => {
  const [selectedMainItems, setMainItems] = useState(mainItemsChecked);
  const [selectedSubItems, setSubItems] = useState(subItemsChecked);

  /** Ensure main items update if state is handled externally */
  useEffect(() => {
    if (mainItemsChecked.length !== selectedMainItems.length) {
      setMainItems(mainItemsChecked);
    }
  }, [mainItemsChecked, selectedMainItems.length]);

  /** Ensure sub items update if state is handled externally */
  useEffect(() => {
    if (subItemsChecked.length !== selectedSubItems.length) {
      setSubItems(subItemsChecked);
    }
  }, [selectedSubItems.length, subItemsChecked]);

  const updateItems = useCallback((main: string[], sub: string[]) => {
    setMainItems(main);
    setSubItems(sub);
  }, []);

  const subListRefs = useRef<FlattenedMapData>();

  useEffect(() => {
    subListRefs.current = flattenMapData(data, 0);
  }, [subListRefs, data]);

  const [currentlyExpanded, setExpanded] = useState(() => getMainTabsMap(data));

  const handleCheckPress = useCallback(
    (value: string, isMain: boolean) => {
      if (isMain) {
        const mainId = value;
        const subIds =
          data
            ?.find(({ value: mainItemValue }) => mainItemValue === mainId)
            ?.subTree?.map(({ value: subTreeItemValue }) => subTreeItemValue) || [];

        const toggledMainArray = toggleArrayItem(selectedMainItems, mainId);
        const toggledSubArray = selectedMainItems.includes(mainId)
          ? toggleArrayItem(selectedSubItems, subIds)
          : [...selectedSubItems, ...subIds];

        updateItems(toggledMainArray, toggledSubArray);
        if (onPressItem) onPressItem(toggledMainArray, toggledSubArray);
      } else {
        const toggledSubArray = toggleArrayItem(selectedSubItems, value);
        updateItems(selectedMainItems, toggledSubArray);
        if (onPressItem) onPressItem(selectedMainItems, toggledSubArray);
      }
    },
    [onPressItem, data, updateItems, selectedSubItems, selectedMainItems]
  );

  const handleLabelPress = useCallback(
    (value: string, isMain: boolean) => {
      if (!isMain) {
        handleCheckPress(value, isMain);
        return;
      }
      // animations only occur when element is mounted,
      // so the moment we collapse a section,
      // the animation is skipped and subsequent clicks
      // will not show the animation
      if (!currentlyExpanded[value]) {
        setExpanded(current => ({ ...current, [value]: !current[value] }));
      }

      const subListRef = subListRefs.current?.[value];
      if (!subListRef) return;

      Animated.timing(subListRef, {
        easing: Easing.linear,
        toValue: currentlyExpanded[value] ? 0 : 1,
        useNativeDriver: true,
      }).start(() => {
        // callback called at the end of animation,
        // so unmounting the collapsable section only occurs
        // after user experiences fade out
        if (currentlyExpanded[value]) {
          setExpanded(current => ({ ...current, [value]: !current[value] }));
        }
      });
    },
    [subListRefs, currentlyExpanded, handleCheckPress]
  );

  const renderItem: ListRenderItem<ExpandableCheckBoxTreeProps> = useCallback(
    ({ item }) => {
      const { label, value, subTree, isMain } = item;
      return (
        <Animated.View>
          <CustomCheckbox
            label={label}
            value={value}
            checked={selectedMainItems.includes(value) || selectedSubItems.includes(value)}
            expanded={currentlyExpanded[value]}
            onPress={value => handleCheckPress(value, !!isMain)}
            onLabelPress={value => handleLabelPress(value, !!isMain)}
          />
          {isMain && item?.subTree?.length && currentlyExpanded[value] ? (
            <Animated.FlatList
              data={subTree}
              style={[styles.subItemRow, { opacity: subListRefs.current?.[value] }]}
              renderItem={renderItem}
              keyExtractor={item => item.value}
            />
          ) : null}
        </Animated.View>
      );
    },
    [
      selectedMainItems,
      selectedSubItems,
      subListRefs,
      handleLabelPress,
      handleCheckPress,
      currentlyExpanded,
    ]
  );

  return (
    <View>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <FlatList data={data} keyExtractor={item => item.value} renderItem={renderItem} />
      )}
    </View>
  );
};

export default ExpandableCheckBoxTree;
