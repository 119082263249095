import { useCallback } from 'react';
import { skipToken, useSuspenseQuery } from '@apollo/client';

import discoverHelpers from '../helpers';
import { DISCOVER_ACTIVITIES, DISCOVER_RENTAL_ACTIVITIES } from '../graphql/DISCOVER_ACTIVITIES';
import { View, Text } from '../../../base-components';
import { CustomCheckbox } from '../../../custom-components';

import styles from '../styles';
import DiscoverFilterBoundary from '../DiscoverFilterBoundary/DiscoverFilterBoundary';

const { applyViewFilters, applySportsFilter } = discoverHelpers;

export type ActivityFilterProps = {
  view: string;
  onPress?: (id: string) => void;
  cityName?: string;
  selectedActivities?: string[];
  isRental?: boolean;
};

const ActivityFilter = ({
  view,
  onPress,
  cityName,
  isRental,
  selectedActivities = [],
}: ActivityFilterProps) => {
  const { data: rentalActivitiesData } = useSuspenseQuery(
    DISCOVER_RENTAL_ACTIVITIES,
    cityName && isRental
      ? {
          fetchPolicy: 'cache-and-network',
          variables: { input: { cityName } },
        }
      : skipToken
  );

  const { data: programActivitiesData } = useSuspenseQuery(
    DISCOVER_ACTIVITIES,
    cityName && !isRental
      ? {
          fetchPolicy: 'cache-and-network',
          variables: { input: { cityName } },
        }
      : skipToken
  );

  const onPressActivity = useCallback(
    (newValue: string) => {
      if (onPress) onPress(newValue);
    },
    [onPress]
  );

  const activities = isRental
    ? (rentalActivitiesData?.discoverRentalSports.sportNames ?? [])
    : (programActivitiesData?.discoverTopSports.sportNames ?? []);

  const filteredActivities = applyViewFilters(view, applySportsFilter(activities));

  if (!cityName)
    return (
      <View>
        <Text>Please select a city</Text>
      </View>
    );

  return (
    <View style={styles.container}>
      {filteredActivities.map((activity: string) => (
        <CustomCheckbox
          key={activity}
          value={activity}
          label={activity}
          onPress={onPressActivity}
          checked={selectedActivities.includes(activity)}
        />
      ))}
    </View>
  );
};

export default (props: ActivityFilterProps) => (
  <DiscoverFilterBoundary>
    <ActivityFilter {...props} />
  </DiscoverFilterBoundary>
);
