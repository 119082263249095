import { gql, type TypedDocumentNode } from '@apollo/client';

type OrganizationData = {
  organization: {
    _id: string;
    voloPassTiers: {
      isActive: boolean;
      monthlyPlanId: string;
      monthlyDollarPrice: number;
      monthlyFee: number;
      annualPlanId: string;
      annualDollarPrice: number;
      annualFee: number;
      annualSavings: number;
    };
  };
};

type OrganiationInput = {
  id: string;
};

const GET_ORGANIZATION_VP_TIERS: TypedDocumentNode<OrganizationData, OrganiationInput> = gql`
  query Organization($id: ID) {
    organization(_id: $id) {
      _id
      voloPassTiers {
        isActive
        monthlyPlanId
        monthlyDollarPrice
        monthlyFee
        annualPlanId
        annualDollarPrice
        annualFee
        annualSavings
      }
    }
  }
`;

export default GET_ORGANIZATION_VP_TIERS;
