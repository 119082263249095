import { type FC } from 'react';
import { Text } from '../../../../base-components';
import { type VenueDisplayNameModule } from '../../RentalCard/types';

const VenueDisplayNameSection: FC<Omit<VenueDisplayNameModule, 'type'>> = ({ venueName }) => (
  <Text type="h3" bold>
    Rentals at {venueName}
  </Text>
);

export default VenueDisplayNameSection;
