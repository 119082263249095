import { StyleSheet } from 'react-native';
import { borderRadius, colors } from '../../constants/globalStyles';

const baseButtonStyles = {
  borderRadius,
  borderWidth: 1,
  borderColor: colors.brandPrimary,
  backgroundColor: colors.brandPrimary,
  color: colors.white,
  paddingHorizontal: 12,
  paddingVertical: 12,
};

const base = StyleSheet.create({
  baseStyles: {
    ...baseButtonStyles,
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
    color: colors.white,
  },

  // pressed states
  pressed: {
    backgroundColor: colors.accentLightBlueBorder,
  },
  textPressed: {
    color: colors.brandPrimary,
    fontWeight: '500',
  },

  hover: {
    backgroundColor: colors.hover,
  },
  textHover: {
    color: colors.brandPrimary,
  },

  circular: {
    width: 36,
    height: 36,
    borderRadius: 36,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  thin: {
    height: 18,
  },
  full: {
    flex: 1,
  },
});

const outline = StyleSheet.create({
  // base
  baseStyles: {
    ...baseButtonStyles,
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color: colors.brandPrimary,
  },

  // hover states
  hover: {
    backgroundColor: colors.hover,
    borderColor: colors.brandPrimary,
  },

  // pressed states
  pressed: {
    backgroundColor: colors.accentLightBlueBorder,
  },
  textPressed: {
    color: colors.brandPrimary,
    fontWeight: '500',
  },
});

const link = StyleSheet.create({
  baseStyles: {
    ...baseButtonStyles,
    display: 'flex',
    justifyContent: 'center',
    paddingHorizontal: 0,
    borderWidth: 0,
    backgroundColor: 'transparent',
  },
  text: {
    color: colors.brandPrimary,
  },

  hover: {
    borderWidth: 0,
    // @ts-expect-error
    boxShadow: 0,
  },
  textHover: {
    color: colors.lightBlue,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: colors.lightBlue,
  },
});

const disabled = StyleSheet.create({
  baseStyles: {
    ...baseButtonStyles,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.lightGrey,
    borderWidth: 0,
  },
  text: {
    color: colors.mediumGrey,
  },
});

const transparent = StyleSheet.create({
  baseStyles: {
    ...baseButtonStyles,
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
  text: {
    color: colors.brandPrimary,
  },
  textPressed: {
    fontWeight: '700',
  },
});

const styles = {
  base,
  link,
  outline,
  disabled,
  transparent,
} as const;

export type ButtonVariant = keyof typeof styles;

export default styles;
